export interface costEstimateState {
  isLoading: Boolean
  costEstimateSuccess: Boolean
  costEstimateError: Boolean
  getSuccess: boolean
  isFlag: boolean
  costEstimation: costEstimateData
}

export interface costEstimateData {
  id: object
  adminFee: number
  payPalFee: number
  payPalFixedPrice: number
  payPalFeeOther: number
  estimatedCostNote: String
  bankAccountDetail: String
  sitePayPalEmail: String
  payPalUserName: String
  payPalPassword: String
  payPalSignature: String
  surveyorReportSubmissionDays: number
  stopSalesEngineDays: number
  daysOfAutoDeleteBoat: number
  daysCounterForInspection: number
  daysCounterInactiveBuyers: number
  daysCounterSurveyorRespond: number
}

export const GET_COST_ESTIMATE = 'GET_COST_ESTIMATE'
export const GET_COST_ESTIMATE_SUCCESS = 'GET_COST_ESTIMATE_SUCCESS'
export const GET_COST_ESTIMATE_FAILURE = 'GET_COST_ESTIMATE_FAILURE'
export const CLEAR_COST_ESTIMATE = 'CLEAR_COST_ESTIMATE'

export const CREATE_OR_UPDATE_COST_ESTIMATE = 'CREATE_OR_UPDATE_COST_ESTIMATE'
export const CREATE_OR_UPDATE_COST_ESTIMATE_SUCCESS = 'CREATE_OR_UPDATE_COST_ESTIMATE_SUCCESS'
export const CREATE_OR_UPDATE_COST_ESTIMATE_FAILURE = 'CREATE_OR_UPDATE_COST_ESTIMATE_FAILURE'

interface createOrUpdateCostEstimate {
  type: typeof CREATE_OR_UPDATE_COST_ESTIMATE
  payload: costEstimateData
}

interface createOrUpdateCostEstimateSuccess {
  type: typeof CREATE_OR_UPDATE_COST_ESTIMATE_SUCCESS
  payload: any
}

interface createOrUpdateCostEstimateFailure {
  type: typeof CREATE_OR_UPDATE_COST_ESTIMATE_FAILURE
  payload: any
}

interface getCostEstimate {
  type: typeof GET_COST_ESTIMATE
  payload: any
}

interface getCostEstimateSuccess {
  type: typeof GET_COST_ESTIMATE_SUCCESS
  payload: any
}

interface clearCostEstimate {
  type: typeof CLEAR_COST_ESTIMATE
  payload: any
}

interface getCostEstimateFailure {
  type: typeof GET_COST_ESTIMATE_FAILURE
  payload: any
}

export type ActionType =
  | createOrUpdateCostEstimate
  | createOrUpdateCostEstimateSuccess
  | createOrUpdateCostEstimateFailure
  | getCostEstimate
  | getCostEstimateSuccess
  | getCostEstimateFailure
  | clearCostEstimate
