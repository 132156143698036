import * as React from 'react'
import FormTable from '../forms/FormTable'
import { tableRecords } from '../../component/ManageBoatTable'
import { displayDefaultValue, dateStringFormate } from '../../helpers/helper'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getFlagComplaint } from '../../store/adminUserRole/Action'
import { getAllBoatPagination, moduleType } from '../../enums/enums'

export interface ManageReviewsProps {
  usersReview: any[]
  getFlagComplaint: typeof getFlagComplaint
  totalUsersReviewRow: number
}

export interface ManageReviewsState {}

class ManageReviews extends React.Component<ManageReviewsProps, ManageReviewsState> {
  componentDidMount() {
    const { getFlagComplaint } = this.props
    getFlagComplaint(getAllBoatPagination)
  }

  columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: any) => {
        return <div>{displayDefaultValue(text)}</div>
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'user',
      key: 'user',
      render: (text: any) => {
        return (
          <div>
            {text && text.firstName} {text && text.lastName}
          </div>
        )
      },
    },
    {
      title: 'Module',
      dataIndex: 'moduleType',
      key: 'moduleType',
      render: (text: any) => {
        return <div>{moduleType[text]}</div>
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <> {dateStringFormate(text)} </>,
    },
    {
      title: 'Stars',
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: 'Report Type',
      dataIndex: 'reportType',
      key: 'reportType',
    },
  ]

  render() {
    const { usersReview, getFlagComplaint, totalUsersReviewRow } = this.props
    console.log(usersReview, 'posses')

    return (
      <FormTable
        tableTitle="Manage Complain"
        tableList={usersReview}
        renderColumn={this.columns}
        getListAction={getFlagComplaint}
        totalUsersRow={totalUsersReviewRow}
      />
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    usersReview: state.adminUserRoleReducer.usersReview,
    totalUsersReviewRow: state.adminUserRoleReducer.totalUsersReviewRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getFlagComplaint: (data: any) => dispatch(getFlagComplaint(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageReviews)
