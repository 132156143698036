import gql from 'graphql-tag'

export const createSeoQuery = gql`
  mutation createSeoMetaTags(
    $title: String!
    $metaTitle: String!
    $active: Boolean!
    $keywords: [String!]!
    $description: String!
    $page: String!
    $canonicalLink: String!
  ) {
    createSeoMetaTags(
      input: {
        title: $title
        metaTitle: $metaTitle
        keywords: $keywords
        active: $active
        description: $description
        page: $page
        canonicalLink: $canonicalLink
      }
    ) {
      title
      active
      metaTitle
      keywords
      id
      description
      page
      canonicalLink
    }
  }
`

export const updateSeoQuery = gql`
  mutation updateSeoMetaTags(
    $id: String!
    $title: String!
    $metaTitle: String!
    $page: String!
    $keywords: [String!]!
    $description: String!
    $active: Boolean!
    $canonicalLink: String
  ) {
    updateSeoMetaTags(
      input: {
        id: $id
        title: $title
        metaTitle: $metaTitle
        keywords: $keywords
        page: $page
        description: $description
        active: $active
        canonicalLink: $canonicalLink
      }
    ) {
      title
      active
      metaTitle
      keywords
      id
      description
      page
      canonicalLink
    }
  }
`

export const deleteSeoQuery = gql`
  mutation deleteSecoMetaTagById($id: String!) {
    deleteSecoMetaTagById(id: $id) {
      title
      active
      metaTitle
      keywords
      id
      description
      page
    }
  }
`

export const getAllSeoQuery = gql`
  query get($limit: Int, $page: Int, $active: String!) {
    getAllSeoMetaTags(page: $page, limit: $limit, active: $active, isAdmin: true) {
      total
      items {
        title
        active
        metaTitle
        keywords
        id
        description
        page
        canonicalLink
      }
    }
  }
`

export const searchSeoQuery = gql`
  query get($limit: Int, $page: Int, $value: String!) {
    searchSeoTags(page: $page, limit: $limit, value: $value) {
      total
      items {
        title
        active
        metaTitle
        keywords
        id
        description
        page
      }
    }
  }
`
