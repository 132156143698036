import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { clearRentPaymentsReceiversFlag, getRentPaymentsReceivers } from '../../store/pendingAds/Action'
import ExpandTable from '../../component/ExpandTable'
import { Button, Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import { formatPriceinUSD, getSearchedData, Sort } from '../../helpers/helper'

const RentPaymentsReceivers = ({ history, getReceivers, receivers, clearReceiversFlag }: any) => {
  const [tableData, setTableData] = useState([] as any[])
  const [pagination, setPagination] = useState({ page: 1, limit: 10 })
  const [searchedValue, setSearchedValue] = useState('')

  useEffect(() => {
    getReceivers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (receivers.success) setTableData(receivers.data)
    if (receivers.success || receivers.failure) clearReceiversFlag()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivers])

  const getPaginated = (data: any[]) => {
    return data.slice((pagination.page - 1) * pagination.limit, pagination.page * pagination.limit)
  }

  const viewBoats = (receiverId: string, data: any) => {
    history.push({
      pathname: '/view-rent-payments-receiver-boats/' + receiverId,
      state: data,
    })
  }

  const tableCols = () => [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.companyName, b.companyName),
    },
    {
      title: 'Contact Name',
      dataIndex: 'name',
      key: 'name',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.name, b.name),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.country, b.country),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.city, b.city),
    },
    {
      title: 'Stripe Account ID',
      dataIndex: 'stripeAccountId',
      key: 'stripeAccountId',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.stripeAccountId, b.stripeAccountId),
    },
    {
      title: 'Total Amount',
      dataIndex: 'baseAmount',
      key: 'baseAmount',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.totalAmount, b.totalAmount),
    },
    {
      title: 'Service Fees',
      dataIndex: 'serviceFees',
      key: 'serviceFees',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.serviceFees, b.serviceFees),
    },
    {
      title: 'Stripe Fees',
      dataIndex: 'stripeFees',
      key: 'stripeFees',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.stripeFees, b.stripeFees),
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (v: number) => formatPriceinUSD(v),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.commission, b.commission),
    },
    {
      title: `Amount to Release`,
      dataIndex: 'amountToRelease',
      key: 'amountToRelease',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.amountToRelease, b.amountToRelease),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (id: any, row: any) => (
        <Button className={row.amountToRelease ? 'btn-danger' : 'btn-view'} onClick={() => viewBoats(id, row)}>
          View Boats
        </Button>
      ),
      sorter: (a: any, b: any) => Sort.Boolean(a.amountToRelease, b.amountToRelease),
    },
  ]

  return (
    <Card title="Rent & Charter Users" extra={<TitleSearch onSearch={setSearchedValue} />}>
      {(() => {
        const data = getSearchedData(tableData, searchedValue)

        return (
          <ExpandTable
            columns={tableCols()}
            tableData={getPaginated(data)}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={data.length}
            tableLoading={receivers.loading}
            pageAction={setPagination}
          />
        )
      })()}
    </Card>
  )
}

const mapStateToProps = (state: any) => {
  return {
    salesEnginCommision: state.salesEngineReducer.salesEnginCommision,
    receivers: state.pendingAdsState.rentPaymentsReceivers,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getReceivers: () => dispatch(getRentPaymentsReceivers()),
  clearReceiversFlag: () => dispatch(clearRentPaymentsReceiversFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentPaymentsReceivers)
