import React, { useEffect, useRef } from 'react';
import NotificationSound from './notification.mp3';

function Notification({ notify }) {
    const audioPlayer = useRef(null);

    function playAudio() {
        audioPlayer.current.play();
    }

    useEffect(() => {
        if (notify) {
            playAudio();
        }
    }, [notify]);

    return (
        <>
            <audio ref={audioPlayer} src={NotificationSound} />
        </>
    );
}

export default Notification;
