import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { countryData } from '../../../store/country/manageCountry/Type'
import { createCountry, clearCountryFlag, updateCountry, getCountries } from '../../../store/country/manageCountry/Action'
import History from '../../../History'
import { handleSingleFileUpload, handleSingleFileDelete } from '../../../helpers/S3FileUpload'
import './countries.scss'
import UploadImage from '../../../component/UploadImage'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { MEDIA_NAME, MEDIA_TYPE, imageExtensions } from '../../../enums/enums'
import NotificationWithIcon from '../../../component/notification/Notification'
import { splitUrlFromUnderscore, getAddMedia, trimmedValue } from '../../../helpers/helper'

const { Text } = Typography

export interface Props {
  createCountry: typeof createCountry
  countrySuccess: boolean
  clearCountryFlag: typeof clearCountryFlag
  history: typeof History
  location: any
  updateCountry: typeof updateCountry
  updateSuccess: boolean
}

export interface State {
  countryDetail: {
    addMedia: any[]
    name: string
    ISOCode: string
    countryPhoneCode: string
    images: any[]
  }
  loading: boolean
  isEdit: boolean
}

class AddCountry extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      countryDetail:
        props.location.state && props.location.state.country
          ? props.location.state.country
          : {
              addMedia: [],
              name: '',
              ISOCode: '',
              countryPhoneCode: '',
              images: [],
            },
      loading: false,
      isEdit: props.location.state && props.location.state.isEdit && props.location.state.isEdit,
    }
  }

  formSubmitHandler = (values: countryData) => {
    const { isEdit } = this.state
    const { createCountry, updateCountry } = this.props
    if (isEdit) {
      const { images, addMedia, ...noImage } = values
      updateCountry({ ...noImage, addMedia: getAddMedia(addMedia) })
    } else {
      const { images, addMedia, ...noImage } = values

      createCountry({ ...noImage, addMedia: getAddMedia(addMedia) })
    }
  }

  handleFileUpload = async (file: any, setFieldValue: any, values: any, acceptType: string) => {
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(file, 'images', setFieldValue, values.addMedia, MEDIA_NAME.COUNTRYIMAGE, MEDIA_TYPE.IMAGE)
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleVideoRemove = (file: any, setFieldValue: any, values: any) => {
    const { addMedia } = values
    handleSingleFileDelete(file, 'images', setFieldValue, addMedia || [], values.images, 'filter', values)
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { countrySuccess, clearCountryFlag, history, updateSuccess } = props

    if (countrySuccess || updateSuccess) {
      clearCountryFlag()
      history.push('/manage-country')
    }

    return null
  }

  render() {
    const { countryDetail, loading } = this.state
    const { history } = this.props
    return (
      <Fragment>
        <Formik
          initialValues={{ ...countryDetail, addMedia: [], removeMedia: [] }}
          onSubmit={(values: countryData) => {
            this.formSubmitHandler(values)
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Country name field is required.'),
            ISOCode: Yup.string().required('Country ISO field is required.'),
            countryPhoneCode: Yup.string().required('Country phone code field is required.'),
            // images: Yup.string().required("Image field is required."),
          })}
        >
          {props => {
            const { handleSubmit, setFieldValue, values } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      Add Country Detail
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      {!loading ? (
                        <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                          Save
                        </button>
                      ) : (
                        <Button loading={loading} className="ml-15 ant-btn ant-btn-primary">
                          Save
                        </Button>
                      )}
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Country Name">
                        Country Name
                      </span>
                      <Field
                        className="ant-input"
                        name="name"
                        placeholder="Country Name"
                        onBlur={(e: { target: { value: string } }) => setFieldValue('name', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`name`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Country ISO">
                        Country ISO
                      </span>

                      <Field
                        className="ant-input"
                        name="ISOCode"
                        placeholder="Country ISO"
                        onBlur={(e: { target: { value: string } }) => setFieldValue('ISOCode', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`ISOCode`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Country Phone Code">
                        Country Phone Code
                      </span>

                      <Field className="ant-input" name="countryPhoneCode" placeholder="Country Phone Code" type="number" />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`countryPhoneCode`}
                          component="span"
                        />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={12}>
                      <span title="country-flg">Country Flag</span>
                      <br />

                      {values?.images?.length ? (
                        <UploadImage
                          defaultFileList={values.images.map((item: any) => {
                            return {
                              uid: item.id,
                              name: item.name || 'Uploaded Flag',
                              status: 'done',
                              url: item.url,
                            }
                          })}
                          accept={imageExtensions}
                          buttonText="Uploaded Flag"
                          withIcon
                          openFileDialogOnClick={!values?.images?.length}
                          onRemove={(rem: any) => this.handleVideoRemove(rem, setFieldValue, values)}
                          selectSiteIcon={(file: any) => this.handleFileUpload(file, setFieldValue, values, 'image')}
                        />
                      ) : (
                        <UploadImage
                          accept={imageExtensions}
                          withIcon
                          buttonText="Country Flag"
                          selectSiteIcon={(file: any) => this.handleFileUpload(file, setFieldValue, values, 'image')}
                        />
                      )}
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="images" component="span" />
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  countrySuccess: state.countryReducer && state.countryReducer.countrySuccess,
  updateSuccess: state.countryReducer && state.countryReducer.updateSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCountries: (data: any) => dispatch(getCountries(data)),
  createCountry: (data: countryData) => dispatch(createCountry(data)),
  clearCountryFlag: () => dispatch(clearCountryFlag()),
  updateCountry: (data: countryData) => dispatch(updateCountry(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddCountry)
