import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { pagination, getAllBoatPagination, paginationPage } from '../../../enums/enums'
import { getAllDownloads, deleteDownloads, clearDownloadsFlag } from '../../../store/staticContent/manageDownloads/Action'
import { getSearchedData } from '../../../helpers/helper'
import { TitleSearch } from '../../../component/SearchBar'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'

interface Props {
  getAllDownloads: typeof getAllDownloads
  downloads: []
  history: typeof History
  deleteDownloads: typeof deleteDownloads
  clearDownloadsFlag: typeof clearDownloadsFlag
  downloadsSuccess: boolean
  deleteSuccess: boolean
  downloadsTotal: number
}

interface State {
  searchedValue: string
}

interface DownloadState {
  downloadsReducer: downloadsReducer
}

interface downloadsReducer {
  downloadsTotal: any
  downloads: []
  downloadsSuccess: boolean
  deleteSuccess: boolean
}

class ManageDownloads extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = props => {
    const { downloadsSuccess, clearDownloadsFlag, getAllDownloads, deleteSuccess } = props
    if (downloadsSuccess) {
      clearDownloadsFlag()
    }
    if (deleteSuccess) {
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getAllDownloads(input)
      clearDownloadsFlag()
    }
    return null
  }

  // componentDidMount() {
  //   const { getAllDownloads } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getAllDownloads(input)
  // }

  editDownload = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-downloads', {
      input: { record: record, isEdit: true },
    })
  }

  state = {
    searchedValue: '',
    columns: [
      {
        title: 'Download Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a: any, b: any) => a.title.length - b.title.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteDownloads } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editDownload(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteDownloads, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  onSearch = (value: any) => {
    const { getAllDownloads } = this.props
    if (value) {
      this.setState({searchedValue: value})
      getAllDownloads({ ...getAllBoatPagination, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getAllDownloads({ ...getAllBoatPagination, })
    }
  }

  render() {
    const { columns, searchedValue } = this.state
    const { downloads, getAllDownloads, downloadsTotal } = this.props

    return (
      <>
        <Card
          title="Manage Downloads"
          extra={
            <div className="d-flex flex-wrap">
              <div className="mr-10 mt-8">
                <TitleSearch onSearch={this.onSearch} />
              </div>
              <Button className="btn-add mt-8">
                <Link to="/create-downloads">
                  <WSIcon type={<PlusOutlined />} /> Add Downloads
                </Link>
              </Button>
            </div>
          }
        >
          <ExpandTable
            columns={columns}
            expandHtml={false}
            isExpand={false}
            tableData={downloads}
            totalUsersRow={downloadsTotal}
            pageAction={getAllDownloads}
            page={paginationPage.boat}
            extraInput={{ searchTerm: searchedValue }}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: DownloadState) => ({
  downloads: state.downloadsReducer.downloads,
  downloadsTotal: state.downloadsReducer.downloadsTotal,
  downloadsSuccess: state.downloadsReducer && state.downloadsReducer.downloadsSuccess,
  deleteSuccess: state.downloadsReducer && state.downloadsReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllDownloads: (data: any) => dispatch(getAllDownloads(data)),
  deleteDownloads: (data: string) => dispatch(deleteDownloads(data)),
  clearDownloadsFlag: () => dispatch(clearDownloadsFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDownloads)
