export interface agreementState {
  agreements: agreement[]
  agreementCreateSuccess: boolean
  agreementCreateError: boolean
  agreementDeleteSuccess: boolean
  agreementDeleteError: boolean
  agreementUpdateSuccess: boolean
  agreementUpdateError: boolean
  agreementContentsTotalRow: number
  agreementContent: agreementContent
  agreementContents: agreementContent[]
  agreementContentSuccess: boolean
  agreementContentError: boolean
  agreementContentDeleteSuccess: boolean
  agreementContentDeleteError: boolean
  pendingAgreements: any[]
  approveSuccess: boolean
  approveError: boolean
  totalAgreement: number
  agreementStatusSuccess: boolean
  pendingAgreementsTotal: number
}

export enum AgreementUserType {
  builder = 'builder',
  surveyor = 'surveyor',
  shipment = 'shipment',
}

export interface agreement {
  items: any[]
  id: string
  country: string
  companyName: string
  agreementId: string
  firstName: string
  lastName: string
  email: string
  companyWebsite: string
  mobileNumber: string
  userType: string
  status: boolean
  createdAt: Date
}

export interface agreementInput {
  country: any
  companyName: string
  firstName: string
  lastName: string
  email: string
  companyWebsite: string
  mobileNumber: string
  userType: AgreementUserType
}

export interface agreementContent {
  id: string
  title: string
  titleSlug: string
  fileTitle: string
  body: string
  createdAt: Date
  updatedAt: Date
}

export interface agreementContentInput {
  title: string
  fileTitle: string
  body: string
}

export const GET_ALL_AGREEMENTS = 'GET_ALL_AGREEMENTS'
export const GET_ALL_AGREEMENTS_SUCCESS = 'GET_ALL_AGREEMENTS_SUCCESS'
export const GET_ALL_AGREEMENTS_FAILURE = 'GET_ALL_AGREEMENTS_FAILURE'

export const CREATE_AGREEMENT = 'CREATE_AGREEMENT'
export const CREATE_AGREEMENT_SUCCESS = 'CREATE_AGREEMENT_SUCCESS'
export const CREATE_AGREEMENT_FAILURE = 'CREATE_AGREEMENT_FAILURE'

export const UPDATE_AGREEMENT = 'UPDATE_AGREEMENT'
export const UPDATE_AGREEMENT_SUCCESS = 'UPDATE_AGREEMENT_SUCCESS'
export const UPDATE_AGREEMENT_FAILURE = 'UPDATE_AGREEMENT_FAILURE'

export const DELETE_AGREEMENT = 'DELETE_AGREEMENT'
export const DELETE_AGREEMENT_SUCCESS = 'DELETE_AGREEMENT_SUCCESS'
export const DELETE_AGREEMENT_FAILURE = 'DELETE_AGREEMENT_FAILURE'

export const CHANGE_AGREEMENT_STATUS = 'CHANGE_AGREEMENT_STATUS'
export const CHANGE_AGREEMENT_STATUS_SUCCESS = 'CHANGE_AGREEMENT_STATUS_SUCCESS'
export const CHANGE_AGREEMENT_STATUS_FAILURE = 'CHANGE_AGREEMENT_STATUS_FAILURE'

export const CLEAR_AGREEMENT_FLAG = 'CLEAR_AGREEMENT_FLAG'

export const GET_ALL_AGREEMENT_CONTENTS = 'GET_ALL_AGREEMENT_CONTENTS'
export const GET_ALL_AGREEMENT_CONTENTS_SUCCESS = 'GET_ALL_AGREEMENT_CONTENTS_SUCCESS'
export const GET_ALL_AGREEMENT_CONTENTS_FAILURE = 'GET_ALL_AGREEMENT_CONTENTS_FAILURE'

export const GET_SINGLE_AGREEMENT_CONTENT = 'GET_SINGLE_AGREEMENT_CONTENT'
export const GET_SINGLE_AGREEMENT_CONTENT_SUCCESS = 'GET_SINGLE_AGREEMENT_CONTENT_SUCCESS'
export const GET_SINGLE_AGREEMENT_CONTENT_FAILURE = 'GET_SINGLE_AGREEMENT_CONTENT_FAILURE'

export const CREATE_AGREEMENT_CONTENT = 'CREATE_AGREEMENT_CONTENT'
export const CREATE_AGREEMENT_CONTENT_SUCCESS = 'CREATE_AGREEMENT_CONTENT_SUCCESS'
export const CREATE_AGREEMENT_CONTENT_FAILURE = 'CREATE_AGREEMENT_CONTENT_FAILURE'

export const UPDATE_AGREEMENT_CONTENT = 'UPDATE_AGREEMENT_CONTENT'
export const UPDATE_AGREEMENT_CONTENT_SUCCESS = 'UPDATE_AGREEMENT_CONTENT_SUCCESS'
export const UPDATE_AGREEMENT_CONTENT_FAILURE = 'UPDATE_AGREEMENT_CONTENT_FAILURE'

export const DELETE_AGREEMENT_CONTENT = 'DELETE_AGREEMENT_CONTENT'
export const DELETE_AGREEMENT_CONTENT_SUCCESS = 'DELETE_AGREEMENT_CONTENT_SUCCESS'
export const DELETE_AGREEMENT_CONTENT_FAILURE = 'DELETE_AGREEMENT_CONTENT_FAILURE'

export const CLEAR_AGREEMENT_CONTENT_FLAG = 'CLEAR_AGREEMENT_CONTENT_FLAG'

export const GET_ALL_PENDING_AGREEMENT_REQUESTS = 'GET_ALL_PENDING_AGREEMENT_REQUESTS'
export const GET_ALL_PENDING_AGREEMENT_REQUESTS_SUCCESS = 'GET_ALL_PENDING_AGREEMENT_REQUESTS_SUCCESS'
export const GET_ALL_PENDING_AGREEMENT_REQUESTS_FAILURE = 'GET_ALL_PENDING_AGREEMENT_REQUESTS_FAILURE'

export const APPROVE_PENDING_AGREEMENT = 'APPROVE_PENDING_AGREEMENT'
export const APPROVE_PENDING_AGREEMENT_SUCCESS = 'APPROVE_PENDING_AGREEMENT_SUCCESS'
export const APPROVE_PENDING_AGREEMENT_FAILURE = 'APPROVE_PENDING_AGREEMENT_FAILURE'

interface getAllAgreements {
  type: typeof GET_ALL_AGREEMENTS
  payload: any
}

interface getAllAgreementsSuccess {
  type: typeof GET_ALL_AGREEMENTS_SUCCESS
  payload: {
    items: agreement[]
    total: number
  }
}
interface getAllAgreementsFailure {
  type: typeof GET_ALL_AGREEMENTS_FAILURE
  payload: any
}

interface createAgreement {
  type: typeof CREATE_AGREEMENT
  payload: agreementInput
}
interface createAgreementSuccess {
  type: typeof CREATE_AGREEMENT_SUCCESS
  payload: agreement
}
interface createAgreementFailure {
  error: any
  type: typeof CREATE_AGREEMENT_FAILURE
  payload: any
}

interface updateAgreement {
  type: typeof UPDATE_AGREEMENT
  payload: agreementInput
}
interface updateAgreementSuccess {
  type: typeof UPDATE_AGREEMENT_SUCCESS
  payload: agreement
}
interface updateAgreementFailure {
  error: any
  type: typeof UPDATE_AGREEMENT_FAILURE
  payload: any
}

interface deleteAgreement {
  type: typeof DELETE_AGREEMENT
  payload: string
}
interface deleteAgreementSuccess {
  type: typeof DELETE_AGREEMENT_SUCCESS
  payload: agreement
}
interface deleteAgreementFailure {
  type: typeof DELETE_AGREEMENT_FAILURE
  payload: any
}

interface changeAgreementStatus {
  type: typeof CHANGE_AGREEMENT_STATUS
  payload: any
}
interface changeAgreementStatusSuccess {
  column: string
  type: typeof CHANGE_AGREEMENT_STATUS_SUCCESS
  payload: agreement
}
interface changeAgreementStatusFailure {
  type: typeof CHANGE_AGREEMENT_STATUS_FAILURE
  payload: any
  error?: any
}

interface getAllAgreementContents {
  type: typeof GET_ALL_AGREEMENT_CONTENTS
  payload: any
}
interface getAllAgreementContentsSuccess {
  type: typeof GET_ALL_AGREEMENT_CONTENTS_SUCCESS
  payload: {
    items: agreementContent[]
    total: number
  }
}
interface getAllAgreementContentsFailure {
  type: typeof GET_ALL_AGREEMENT_CONTENTS_FAILURE
  payload: any
}

interface createAgreementContent {
  type: typeof CREATE_AGREEMENT_CONTENT
  payload: agreementContentInput
}
interface createAgreementContentSuccess {
  type: typeof CREATE_AGREEMENT_CONTENT_SUCCESS
  payload: agreementContent
}
interface createAgreementContentFailure {
  type: typeof CREATE_AGREEMENT_CONTENT_FAILURE
  payload: any
}

interface updateAgreementContent {
  type: typeof UPDATE_AGREEMENT_CONTENT
  payload: agreementContentInput
}
interface updateAgreementContentSuccess {
  type: typeof UPDATE_AGREEMENT_CONTENT_SUCCESS
  payload: agreementContent
}
interface updateAgreementContentFailure {
  type: typeof UPDATE_AGREEMENT_CONTENT_FAILURE
  payload: any
}

interface getSingleAgreementContent {
  type: typeof GET_SINGLE_AGREEMENT_CONTENT
  payload: string
}
interface getSingleAgreementContentSuccess {
  type: typeof GET_SINGLE_AGREEMENT_CONTENT_SUCCESS
  payload: agreementContent
}
interface getSingleAgreementContentFailure {
  type: typeof GET_SINGLE_AGREEMENT_CONTENT_FAILURE
  payload: any
}

interface deleteAgreementContent {
  type: typeof DELETE_AGREEMENT_CONTENT
  payload: string
}
interface deleteAgreementContentSuccess {
  type: typeof DELETE_AGREEMENT_CONTENT_SUCCESS
  payload: agreementContent
}
interface deleteAgreementContentFailure {
  type: typeof DELETE_AGREEMENT_CONTENT_FAILURE
  payload: any
}

interface clearAgreementFlag {
  type: typeof CLEAR_AGREEMENT_CONTENT_FLAG
  payload: any
}

interface getAllPendingAgreementRequest {
  type: typeof GET_ALL_PENDING_AGREEMENT_REQUESTS
  payload: any
}

interface getAllPendingAgreementRequestSuccess {
  type: typeof GET_ALL_PENDING_AGREEMENT_REQUESTS_SUCCESS
  payload: {
    items: agreement[]
    total: number
  }
}

interface getAllPendingAgreementRequestFailure {
  type: typeof GET_ALL_PENDING_AGREEMENT_REQUESTS_FAILURE
  payload: string
}

interface approvePendingAgreement {
  type: typeof APPROVE_PENDING_AGREEMENT
  payload: agreement
}

interface approvePendingAgreementSuccess {
  type: typeof APPROVE_PENDING_AGREEMENT_SUCCESS
  payload: agreement
}

interface approvePendingAgreementFailure {
  error: any
  type: typeof APPROVE_PENDING_AGREEMENT_FAILURE
  payload: agreement
}

export type ActionType =
  | getAllAgreements
  | getAllAgreementsSuccess
  | getAllAgreementsFailure
  | createAgreement
  | createAgreementSuccess
  | createAgreementFailure
  | deleteAgreement
  | deleteAgreementSuccess
  | deleteAgreementFailure
  | changeAgreementStatus
  | changeAgreementStatusSuccess
  | changeAgreementStatusFailure
  | getAllAgreementContents
  | getAllAgreementContentsSuccess
  | getAllAgreementContentsFailure
  | createAgreementContent
  | createAgreementContentSuccess
  | createAgreementContentFailure
  | updateAgreementContent
  | updateAgreementContentSuccess
  | updateAgreementContentFailure
  | getSingleAgreementContent
  | getSingleAgreementContentSuccess
  | getSingleAgreementContentFailure
  | deleteAgreementContent
  | deleteAgreementContentSuccess
  | deleteAgreementContentFailure
  | updateAgreement
  | updateAgreementSuccess
  | updateAgreementFailure
  | clearAgreementFlag
  | getAllPendingAgreementRequest
  | getAllPendingAgreementRequestSuccess
  | getAllPendingAgreementRequestFailure
  | approvePendingAgreement
  | approvePendingAgreementSuccess
  | approvePendingAgreementFailure
