import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { client } from '../..'
import { createSeoQuery, updateSeoQuery, deleteSeoQuery, searchSeoQuery, getAllSeoQuery } from './Schema'
import {
  GET_SEO_TAGS,
  GET_SEO_TAGS_FAILURE,
  GET_SEO_TAGS_SUCCESS,
  DELETE_SEO_TAG,
  DELETE_SEO_TAG_SUCCESS,
  DELETE_SEO_TAG_FAILURE,
  CREATE_SEO_TAG,
  CREATE_SEO_TAG_FAILURE,
  CREATE_SEO_TAG_SUCCESS,
  UPDATE_SEO_TAG,
  UPDATE_SEO_TAG_FAILURE,
  UPDATE_SEO_TAG_SUCCESS,
  SEARCH_SEO_TAG,
  SEARCH_SEO_TAG_FAILURE,
  SEARCH_SEO_TAG_SUCCESS,
  ResponseGenerator,
} from './Types'

function getAllSeoTags(data: any) {
  return client
    .query({
      fetchPolicy: 'network-only',
      query: getAllSeoQuery,
      variables: { ...data.payload },
    })
}

function deleteSeoTag(data: any) {
  return client
    .mutate({
      mutation: deleteSeoQuery,
      variables: {
        id: data.payload,
      },
    })
}

function createSeoTag(data: any) {
  return client
    .mutate({
      mutation: createSeoQuery,
      variables: { ...data.payload },
    })
}

function updateSeoTag(data: any) {
  return client
    .mutate({
      mutation: updateSeoQuery,
      variables: { ...data.payload },
    })
}

function searchSeoTag(data: any) {
  return client
    .mutate({
      mutation: searchSeoQuery,
      variables: { ...data.payload },
    })
}

function* getAllSeoTagsService({ payload }: AnyAction) {
  try {
    const res: ResponseGenerator = yield getAllSeoTags({ payload })
    yield put({
      type: GET_SEO_TAGS_SUCCESS,
      payload: res.data?.getAllSeoMetaTags,
    })
  } catch (error) {
    yield put({ type: GET_SEO_TAGS_FAILURE, error })
  }
}

function* getAllSeoTagsSaga() {
  yield takeLatest(GET_SEO_TAGS, getAllSeoTagsService)
}

// delete generators
function* deleteSeoTagsService({ payload }: AnyAction) {
  try {
    const res: ResponseGenerator = yield deleteSeoTag({ payload })
    yield put({
      type: DELETE_SEO_TAG_SUCCESS,
      payload,
    })
  } catch (error) {
    yield put({ type: DELETE_SEO_TAG_FAILURE, error })
  }
}

function* deleteSeoTagsSaga() {
  yield takeLatest(DELETE_SEO_TAG, deleteSeoTagsService)
}

// create generators
function* createSeoTagsService({ payload }: AnyAction) {
  try {
    const res: ResponseGenerator = yield createSeoTag({ payload })
    yield put({
      type: CREATE_SEO_TAG_SUCCESS,
    })
  } catch (error) {
    yield put({ type: CREATE_SEO_TAG_FAILURE, error })
  }
}

function* createSeoTagsSaga() {
  yield takeLatest(CREATE_SEO_TAG, createSeoTagsService)
}

// update generators
function* updateSeoTagsService({ payload }: AnyAction) {
  try {
    const res: ResponseGenerator = yield updateSeoTag({ payload })
    yield put({
      type: UPDATE_SEO_TAG_SUCCESS,
    })
  } catch (error) {
    yield put({ type: UPDATE_SEO_TAG_FAILURE, error })
  }
}

function* updateSeoTagsSaga() {
  yield takeLatest(UPDATE_SEO_TAG, updateSeoTagsService)
}

//search seo tags
function* searchAllSeoTagsService({ payload }: AnyAction) {
  try {
    const res: ResponseGenerator = yield searchSeoTag({ payload })
    yield put({
      type: SEARCH_SEO_TAG_SUCCESS,
      payload: res.data?.searchSeoTags,
    })
  } catch (error) {
    yield put({ type: SEARCH_SEO_TAG_FAILURE, error })
  }
}

function* searchAllSeoTagsSaga() {
  yield takeLatest(SEARCH_SEO_TAG, searchAllSeoTagsService)
}

export default function* adminUserRoleSaga() {
  yield all([
    fork(getAllSeoTagsSaga),
    fork(deleteSeoTagsSaga),
    fork(createSeoTagsSaga),
    fork(updateSeoTagsSaga),
    fork(searchAllSeoTagsSaga),
  ])
}
