import { imageSchema } from './../../schemaTypes'
import gql from 'graphql-tag'

export const createBoatTypes = gql`
  mutation createBoatType($input: ManageBoatTypeInput!) {
    createBoatType(input: $input) {
      id
      name
      icon ${imageSchema}
      thumbnailIcon ${imageSchema}
    }
  }
`

export const getAllBoatTypes = gql`
  query getAllBoatTypes($page: Int, $limit: Int, $searchTerm: String) {
    getAllBoatTypes(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        id
        name
        thumbnailIcon ${imageSchema}
        icon ${imageSchema}
        createdAt
        updatedAt
      }
      total
    }
  }
`

export const updateBoatTypes = gql`
  mutation updateBoatType($input: ManageBoatTypeInput!) {
    updateBoatType(input: $input) {
      id
      name
      thumbnailIcon ${imageSchema}
      icon ${imageSchema}
    }
  }
`

export const deleteBoatTypes = gql`
  mutation removeBoatType($id: String!) {
    removeBoatType(id: $id) {
      id
    }
  }
`
