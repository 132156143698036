import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { formateDate } from '../../helpers/helper'
import ManageBoatTable, { tableRecords } from '../../component/ManageBoatTable'
import { getAllMarinaBoatsAction, changeMarinaStatus, deleteMarinaBoats, clearBoatFlag } from '../../store/pendingAds/Action'
import { getAllBoatPagination, statusValues } from '../../enums/enums'
import ThreeToggleSwitch from '../../component/ThreeToggleSwitch'
import ApproveButton from '../../component/ApproveButton'

interface Props {
  getAllMarinaBoatsAction: typeof getAllMarinaBoatsAction
  allMarina: any[]
  totalMarinaRow: number
  changeMarinaStatus: typeof changeMarinaStatus
  deleteMarinaBoats: typeof deleteMarinaBoats
  deleteMarinaSuccess: boolean
  clearBoatFlag: typeof clearBoatFlag
}

class LiveExperienceMarina extends Component<Props> {
  changeMarinaStatus = (columnName: any, record: any, changedValue: any) => {
    const { changeMarinaStatus, allMarina } = this.props
    changeMarinaStatus({
      id: record.id,
      columnName,
      value: changedValue,
      fieldName: 'allMarina',
      fieldArray: allMarina,
    })
  }

  columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text: any, record: tableRecords) => <div>{`${text.firstName} ${text.lastName}`}</div>,
    },
    {
      title: 'Ad ID',
      dataIndex: 'adId',
      key: 'adId',
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <> {formateDate(text)} </>,
    },
    {
      title: 'Live experience Marina',
      dataIndex: 'liveExperience',
      key: 'liveExperience',
      render: (text: string, record: tableRecords) => (
        <ThreeToggleSwitch
          toggleMarketStatus={(e: any) => this.changeMarinaStatus('liveExperience', record, e.target.value)}
          status={text}
        />
      ),
    },
    {
      title: 'Approval Status',
      dataIndex: 'adStatus',
      key: 'adStatus',
      render: (text: string, record: tableRecords) => {
        const { changeMarinaStatus, allMarina } = this.props
        return (
          <ApproveButton
            action={changeMarinaStatus}
            input={{
              id: record.id,
              columnName: 'adStatus',
              value: record.adStatus,
              fieldName: 'allMarina',
              fieldArray: allMarina,
            }}
            buttonTitle="Decline"
            className="btn-delete"
          />
        )
      },
    },
  ]

  componentDidMount() {
    const { getAllMarinaBoatsAction } = this.props

    getAllMarinaBoatsAction({
      ...getAllBoatPagination,
      liveExperience: statusValues.APPROVEORCRITERIA,
      adStatus: true,
    })
  }

  render() {
    const { allMarina, totalMarinaRow, getAllMarinaBoatsAction, deleteMarinaBoats, deleteMarinaSuccess, clearBoatFlag } =
      this.props
    return (
      <ManageBoatTable
        tableTitle="Manage Live Experience By Country"
        renderColumn={this.columns}
        tableList={allMarina}
        totalUsersRow={totalMarinaRow}
        getListAction={getAllMarinaBoatsAction}
        view="marina"
        deleteAction={deleteMarinaBoats}
        deleteSuccess={deleteMarinaSuccess}
        clearAction={clearBoatFlag}
        searchAction={getAllMarinaBoatsAction}
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    allMarina: state.pendingAdsState.allMarina,
    totalMarinaRow: state.pendingAdsState.totalMarinaRow,
    deleteMarinaSuccess: state.pendingAdsState.deleteMarinaSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    deleteMarinaBoats: (data: any) => dispatch(deleteMarinaBoats(data)),
    clearBoatFlag: () => dispatch(clearBoatFlag()),
    getAllMarinaBoatsAction: (data: any) => dispatch(getAllMarinaBoatsAction(data)),
    changeMarinaStatus: (data: any) => dispatch(changeMarinaStatus(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveExperienceMarina)
