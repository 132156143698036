import { put, takeLatest, all } from 'redux-saga/effects'
import {
  CREATE_BOAT_TYPES,
  ActionType,
  CREATE_BOAT_TYPES_SUCCESS,
  CREATE_BOAT_TYPES_FAILURE,
  GET_ALL_BOAT_TYPES,
  GET_ALL_BOAT_TYPES_SUCCESS,
  GET_ALL_BOAT_TYPES_FAILURE,
  UPDATE_BOAT_TYPES,
  UPDATE_BOAT_TYPES_SUCCESS,
  UPDATE_BOAT_TYPES_FAILURE,
  DELETE_BOAT_TYPES,
  DELETE_BOAT_TYPES_SUCCESS,
  DELETE_BOAT_TYPES_FAILURE,
  BoatTypesData,
  EDIT_BOAT_TYPES_SUCCESS,
  EDIT_BOAT_TYPES_FAILURE,
  EDIT_BOAT_TYPES,
} from './Types'
import { client } from '../../../'
import { createBoatTypes, getAllBoatTypes, updateBoatTypes, deleteBoatTypes } from './Schema'
import { editBoatTypes } from '../manageMarinaTypes/Schema'

// create boat type

export function createBoatTypesApi(input: BoatTypesData) {
  return client
    .mutate({
      mutation: createBoatTypes,
      variables: { input },
    })
}

export function* createBoatTypesData(action: ActionType) {
  try {
    const data = yield createBoatTypesApi(action.payload)
    yield put({ type: CREATE_BOAT_TYPES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: CREATE_BOAT_TYPES_FAILURE, error })
  }
}

export function* createBoatTypesSage() {
  yield takeLatest(CREATE_BOAT_TYPES, createBoatTypesData)
}

// get boat type

export function getAllBoatTypesApi(payload: any) {
  return client
    .query({
      query: getAllBoatTypes,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

export function* getAllBoatTypesData(action: ActionType) {
  try {
    const data = yield getAllBoatTypesApi(action.payload)
    yield put({ type: GET_ALL_BOAT_TYPES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ALL_BOAT_TYPES_FAILURE, error })
  }
}

export function* getAllBoatTypesSaga() {
  yield takeLatest(GET_ALL_BOAT_TYPES, getAllBoatTypesData)
}

// delete boat type

function deleteBoatTypesApi(data: string) {
  return client
    .mutate({
      mutation: deleteBoatTypes,
      variables: { id: data },
    })
}

function* deleteBoatTypesData(action: ActionType) {
  try {
    const res = yield deleteBoatTypesApi(action.payload)
    if (res && res.networkError && res.networkError.result && res.networkError.result.errors) {
      yield put({
        type: DELETE_BOAT_TYPES_FAILURE,
        payload: res.networkError.result.errors,
      })
    } else {
      yield put({ type: DELETE_BOAT_TYPES_SUCCESS, payload: res })
    }
  } catch (error) {
    yield put({
      type: DELETE_BOAT_TYPES_FAILURE,
      payload: error.networkError.result.errors,
    })
  }
}

function* deleteBoatTypesSaga() {
  yield takeLatest(DELETE_BOAT_TYPES, deleteBoatTypesData)
}

// edit boat type

function editBoatTypesApi(data: string) {
  return client
    .query({
      query: editBoatTypes,
      variables: { id: data },
    })
}

function* editBoatTypesData(action: ActionType) {
  try {
    const res = yield editBoatTypesApi(action.payload)
    yield put({ type: EDIT_BOAT_TYPES_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: EDIT_BOAT_TYPES_FAILURE, error })
  }
}

function* editBoatTypesSaga() {
  yield takeLatest(EDIT_BOAT_TYPES, editBoatTypesData)
}

// update boat type

export function updateBoatTypesApi(data: any) {
  return client
    .mutate({
      mutation: updateBoatTypes,
      variables: { input: data },
    })
}

export function* updateBoatTypesData(action: ActionType) {
  try {
    const data = yield updateBoatTypesApi(action.payload)
    yield put({ type: UPDATE_BOAT_TYPES_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_BOAT_TYPES_FAILURE, error })
  }
}

export function* updateBoatTypesSaga() {
  yield takeLatest(UPDATE_BOAT_TYPES, updateBoatTypesData)
}

export default function* boatTypeSaga() {
  yield all([createBoatTypesSage(), getAllBoatTypesSaga(), updateBoatTypesSaga(), deleteBoatTypesSaga(), editBoatTypesSaga()])
}
