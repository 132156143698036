import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { deleteSmtp, createSmtp, getAllSmtp, updateSmtp } from './Schema'
import {
  ActionType,
  DELETE_SMTP_SUCCESS,
  DELETE_SMTP_FAILURE,
  CREATE_SMTP_SUCCESS,
  CREATE_SMTP_FAILURE,
  GET_ALL_SMTP_SUCCESS,
  GET_ALL_SMTP_FAILURE,
  UPDATE_SMTP_SUCCESS,
  UPDATE_SMTP_FAILURE,
  DELETE_SMTP,
  CREATE_SMTP,
  GET_ALL_SMTP,
  UPDATE_SMTP,
} from './Type'

function deleteSmtpApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteSmtp,
      variables: { id },
    })
}

function createSmtpApi(input: any) {
  return client
    .mutate({
      mutation: createSmtp,
      variables: { input },
    })
}

function getAllSmtpApi(payload: any) {
  return client
    .query({
      query: getAllSmtp,
      variables: {
        ...payload,
      },
      fetchPolicy: 'no-cache',
    })
}

function updateSmtpApi(data: any) {
  const input = data
  return client
    .mutate({
      mutation: updateSmtp,
      variables: { input },
    })
}

function* deleteSmtpData(action: ActionType) {
  try {
    const res = yield deleteSmtpApi(action)
    yield put({ type: DELETE_SMTP_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_SMTP_FAILURE, error })
  }
}

function* createSmtpData(action: ActionType) {
  try {
    const res = yield createSmtpApi(action.payload)
    yield put({ type: CREATE_SMTP_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_SMTP_FAILURE, error })
  }
}

function* getAllSmtpData(action: any) {
  try {
    const res = yield getAllSmtpApi(action.payload)
    yield put({ type: GET_ALL_SMTP_SUCCESS, payload: res.data.getAllSmtp })
  } catch (error) {
    yield put({ type: GET_ALL_SMTP_FAILURE, error })
  }
}

export function* updateSmtpData(action: ActionType) {
  try {
    const data = yield updateSmtpApi(action.payload)
    yield put({ type: UPDATE_SMTP_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_SMTP_FAILURE, error })
  }
}

function* deleteSmtpSaga() {
  yield takeLatest(DELETE_SMTP, deleteSmtpData)
}

function* createSmtpSaga() {
  yield takeLatest(CREATE_SMTP, createSmtpData)
}

function* getAllSmtpSaga() {
  yield takeLatest(GET_ALL_SMTP, getAllSmtpData)
}

function* updateSmtpSaga() {
  yield takeLatest(UPDATE_SMTP, updateSmtpData)
}

export default function* smtpSaga() {
  yield all([deleteSmtpSaga(), createSmtpSaga(), getAllSmtpSaga(), updateSmtpSaga()])
}
