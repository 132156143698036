import {
  ActionType,
  userGuideState,
  GET_ALL_USER_GUIDE_LIST,
  GET_ALL_USER_GUIDE_LIST_SUCCESS,
  GET_ALL_USER_GUIDE_LIST_FAILURE,
  CREATE_USER_GUIDE_DETAIL,
  CREATE_USER_GUIDE_DETAIL_SUCCESS,
  CREATE_USER_GUIDE_DETAIL_FAILURE,
  UPDATE_USER_GUIDE_DETAIL,
  UPDATE_USER_GUIDE_DETAIL_SUCCESS,
  UPDATE_USER_GUIDE_DETAIL_FAILURE,
  DELETE_USER_GUIDE_DETAIL_SUCCESS,
  DELETE_USER_GUIDE_DETAIL_FAILURE,
  CLEAR_USER_GUIDE_FLAG,
  CHANGE_USER_GUIDE_STATUS,
  CHANGE_USER_GUIDE_STATUS_SUCCESS,
  CHANGE_USER_GUIDE_STATUS_FAILURE,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: userGuideState = {
  userGuides: [],
  userGuideSuccess: false,
  userGuideError: false,
  deleteSuccess: false,
  userGuidesTotalRow: 0,
  deleteError: false,
}

export const userGuideReducer = (state = initialState, action: ActionType): userGuideState => {
  switch (action.type) {
    case GET_ALL_USER_GUIDE_LIST:
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: false,
      }
    case GET_ALL_USER_GUIDE_LIST_SUCCESS:
      return {
        ...state,
        userGuideSuccess: true,
        userGuideError: false,
        userGuides: action.payload.items,
        userGuidesTotalRow: action.payload.total,
      }
    case GET_ALL_USER_GUIDE_LIST_FAILURE:
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: true,
      }

    case CREATE_USER_GUIDE_DETAIL:
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: false,
      }
    case CREATE_USER_GUIDE_DETAIL_SUCCESS:
      return {
        ...state,
        userGuideSuccess: true,
        userGuideError: false,
      }
    case CREATE_USER_GUIDE_DETAIL_FAILURE:
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: true,
      }

    case UPDATE_USER_GUIDE_DETAIL:
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: false,
      }
    case UPDATE_USER_GUIDE_DETAIL_SUCCESS:
      return {
        ...state,
        userGuideSuccess: true,
        userGuideError: false,
      }
    case UPDATE_USER_GUIDE_DETAIL_FAILURE:
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: true,
      }

    case DELETE_USER_GUIDE_DETAIL_SUCCESS:
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }
    case DELETE_USER_GUIDE_DETAIL_FAILURE:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case CLEAR_USER_GUIDE_FLAG:
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: false,
        deleteSuccess: false,
        deleteError: false,
      }

    case CHANGE_USER_GUIDE_STATUS:
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: false,
      }
    case CHANGE_USER_GUIDE_STATUS_SUCCESS:
      NotificationWithIcon('success', 'Status Changed Successfully')
      return {
        ...state,
        userGuideSuccess: true,
        userGuideError: false,
      }
    case CHANGE_USER_GUIDE_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        userGuideSuccess: false,
        userGuideError: true,
      }

    default:
      return state
  }
}
