/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { clear_getAdReports, getAdReports } from '../../store/pendingAds/Action'
import ExpandTable from '../../component/ExpandTable'
import { Button, Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData, Sort } from '../../helpers/helper'
import { get } from 'lodash'
import moment from 'moment'

const ManageAdComplaintsIndividual = ({ history, match, getAdReports, adReports, clear_getAdReports }: any) => {
  const [moduleId, setModuleId] = useState(get(match, 'params.moduleId', ''))
  const [tableData, setTableData] = useState([] as any[])
  const [totalRows, setTotalRows] = useState(0)
  const [searchedValue, setSearchedValue] = useState('')

  useEffect(() => {
    setModuleId(get(match, 'params.moduleId', ''))
  }, [match])

  useEffect(() => {
    if (adReports.success) {
      setTableData(adReports.data.items)
      setTotalRows(adReports.data.total)
    }

    if (adReports.success || adReports.failure) clear_getAdReports()
  }, [adReports])

  const viewReports = (moduleId: string) => {
    history.push({ pathname: '/manage-ad-complaints/' + moduleId })
  }

  const tableCols = () => [
    {
      title: 'User Type',
      dataIndex: 'user',
      key: 'userType',
      render: (v: any) => get(v, 'role.role', '') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(get(a, 'user.role.role', ''), get(b, 'user.role.role', '')),
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'userName',
      render: (v: any) => get(v, 'firstName', '') + ' ' + get(v, 'lastName', '') || <>&#8212;</>,
      sorter: (a: any, b: any) =>
        Sort.StrOrNum(
          get(a, 'user.firstName', '') + ' ' + get(a, 'user.lastName', ''),
          get(b, 'user.firstName', '') + ' ' + get(b, 'user.lastName', '')
        ),
    },
    {
      title: 'User Country',
      dataIndex: 'user',
      key: 'userCountry',
      render: (v: any) => get(v, 'address[0].country', '') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(get(a, 'user.address[0].country', ''), get(b, 'user.address[0].country', '')),
    },
    {
      title: 'Complaint Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (v: any) => moment(v).format('DD-MM-YYYY') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(get(a, 'createdAt', ''), get(b, 'createdAt', '')),
    },
    {
      title: 'Email',
      dataIndex: 'user',
      key: 'userEmail',
      render: (v: any) => get(v, 'email', '') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(get(a, 'user.email', ''), get(b, 'user.email', '')),
    },
    {
      title: 'Mobile Number',
      dataIndex: 'user',
      key: 'userPhone',
      render: (v: any) => get(v, 'mobileNumber', '') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(get(a, 'user.mobileNumber', ''), get(b, 'user.mobileNumber', '')),
    },
    {
      title: 'Why is this ad being reported?',
      dataIndex: 'reportType',
      key: 'reportType',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(get(a, 'reportType', ''), get(b, 'reportType', '')),
    },
    {
      title: 'Tell Us More',
      dataIndex: 'message',
      key: 'message',
      render: (v: any) => (v ? <ReportsMessage msg={v} /> : <>&#8212;</>),
      sorter: (a: any, b: any) => Sort.StrOrNum(get(a, 'message', ''), get(b, 'message', '')),
    },
  ]

  return (
    <Card
      title="Manage Ad Complaints"
      extra={
        <div className="d-flex align-items-center">
          <TitleSearch onSearch={setSearchedValue} />
          <Button className="ml-3" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
      }
    >
      {(() => {
        const data = getSearchedData(tableData, searchedValue)

        return (
          <ExpandTable
            columns={tableCols()}
            tableData={data}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={totalRows}
            tableLoading={adReports.loading}
            pageAction={getAdReports}
            extraInput={{ moduleId }}
          />
        )
      })()}
    </Card>
  )
}

const mapStateToProps = (state: any) => {
  return {
    adReports: state.pendingAdsState.adReports,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdReports: (payload: any) => dispatch(getAdReports(payload)),
  clear_getAdReports: () => dispatch(clear_getAdReports()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdComplaintsIndividual)

const ReportsMessage = ({ msg }: { msg: string }) => {
  const [expandable, setExpandable] = useState(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setExpanded(msg.length < 100)
    setExpandable(msg.length >= 100)
  }, [msg])

  return (
    <div style={{ display: 'inline-block', width: 300, whiteSpace: 'pre-wrap' }}>
      {expandable ? (
        expanded ? (
          <>
            {msg}{' '}
            <div className="reports-more" onClick={() => setExpanded(false)}>
              Read less
            </div>
          </>
        ) : (
          <>
            {msg.substring(0, 99)}
            {'... '}
            <span className="reports-more" onClick={() => setExpanded(true)}>
              Read more
            </span>
          </>
        )
      ) : (
        msg
      )}
    </div>
  )
}
