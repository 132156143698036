import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import {
  clearRentPaymentsBoatSlotBookingsFlag,
  clearRentPaymentsRefundIntentFlag,
  clearRentPaymentsReleaseIntentFlag,
  getRentPaymentsBoatSlotBookings,
  rentPaymentsRefundIntent,
  rentPaymentsReleaseIntent,
} from '../../store/pendingAds/Action'
import ExpandTable from '../../component/ExpandTable'
import { Button, Card, Tag } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import { classNames, formatPriceinUSD, getRentStatusMod, getSearchedData, Sort } from '../../helpers/helper'
import moment from 'moment'
import {
  RentBookingStatus,
  RentBookingStatusType,
  RentTripStatusFilterArray,
  RentTripStatusLabels,
  StatusColors,
} from '../../enums/enums'
import NotificationWithIcon from '../../component/notification/Notification'
import FilterButton from '../filters/FilterButton'

const RentPaymentsBoatSlotBookings = ({
  history,
  location: {
    pathname,
    search,
    state: { receiver, boat, slot, timeStatus = 'upcoming' },
  },
  getBookings,
  bookings,
  clearBookingsFlag,
  release,
  released,
  clearReleaseFlag,
  refund,
  refunded,
  clearRefundFlag,
}: any) => {
  const [tableData, setTableData] = useState([] as any[])
  const [pagination, setPagination] = useState({ page: 1, limit: 10 })
  const [searchedValue, setSearchedValue] = useState('')
  const [timeStatusFilteredSlots, setTimeStatusFilteredSlots] = useState([] as any[])

  useEffect(() => {
    getBookings({ slotId: slot.id })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (bookings.success || bookings.failure) clearBookingsFlag()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings])

  useEffect(() => {
    const filters =
      timeStatus === 'upcoming'
        ? [null, RentBookingStatus.Pending, RentBookingStatus.Started, RentBookingStatus.Ongoing]
        : [RentBookingStatus.Expired, RentBookingStatus.Settled]

    const filtered = bookings.data?.filter((s: any) => filters.includes(s.status)) || []

    setTimeStatusFilteredSlots(filtered)
    setTableData(filtered)
  }, [bookings, timeStatus])

  useEffect(() => {
    if (released.success) {
      NotificationWithIcon('success', 'Released payment successfully!')
      getBookings({ slotId: slot.id })
    }

    if (released.failure) NotificationWithIcon('error', released.data || 'Failed to release payment!')
    if (released.success || released.failure) clearReleaseFlag()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [released])

  useEffect(() => {
    if (refunded.success) {
      NotificationWithIcon('success', 'Refunded payment successfully!')
      getBookings({ slotId: slot.id })
    }

    if (refunded.failure) NotificationWithIcon('error', refunded.data || 'Failed to refund payment!')
    if (refunded.success || refunded.failure) clearRefundFlag()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refunded])

  const getPaginated = (data: any[]) => {
    return data.slice((pagination.page - 1) * pagination.limit, pagination.page * pagination.limit)
  }

  const filterSlots = ({ status }: any) => {
    if (status) {
      setTableData(timeStatusFilteredSlots.filter((s: any) => (status === 'Cancelled' ? s.isCancelled : s.status === status)))
    } else {
      setTableData(timeStatusFilteredSlots)
    }
  }

  const tableCols = () =>
    [
      {
        title: 'Trip Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (v: string) => moment.utc(v).format('MMM DD, YYYY') || <>&#8212;</>,
        sorter: (a: any, b: any) => Sort.DateOnly(moment.utc(a.startDate), moment.utc(b.startDate)),
      },
      {
        title: 'Start Time',
        dataIndex: 'startTime',
        key: 'startTime',
        render: (v: string) => moment.utc(v).format('hh:mm A') || <>&#8212;</>,
        sorter: (a: any, b: any) => Sort.TimeOnly(moment.utc(a.startTime), moment.utc(b.startTime)),
      },
      {
        title: 'Trip End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (v: string) => moment.utc(v).format('MMM DD, YYYY') || <>&#8212;</>,
        sorter: (a: any, b: any) => Sort.DateOnly(moment.utc(a.endDate), moment.utc(b.endDate)),
      },
      {
        title: 'End Time',
        dataIndex: 'endTime',
        key: 'endTime',
        render: (v: string) => moment.utc(v).format('hh:mm A') || <>&#8212;</>,
        sorter: (a: any, b: any) => Sort.TimeOnly(moment.utc(a.endTime), moment.utc(b.endTime)),
      },
      {
        title: 'Ticket Id',
        dataIndex: 'ticketId',
        key: 'ticketId',
        sorter: (a: any, b: any) => Sort.StrOrNum(a.ticketId, b.ticketId),
      },
      {
        title: 'Stripe Transaction Id',
        dataIndex: 'paymentIntentId',
        key: 'paymentIntentId',
        sorter: (a: any, b: any) => Sort.StrOrNum(a.paymentIntentId, b.paymentIntentId),
      },
      {
        title: 'Booked by',
        dataIndex: 'bookedBy',
        key: 'bookedBy',
        sorter: (a: any, b: any) => Sort.StrOrNum(a.bookedBy, b.bookedBy),
      },
      /shared/i.test(boat.trip) && {
        title: 'Passengers',
        dataIndex: 'units',
        key: 'units',
        sorter: (a: any, b: any) => Sort.StrOrNum(a.units, b.units),
      },
      {
        title: 'Trip Status',
        dataIndex: 'status',
        key: 'status',
        render: (status: RentBookingStatusType, row: any) => {
          const statusMod = getRentStatusMod(status, row.isQRScanned, row.isCancelled)
          return <Tag color={StatusColors[statusMod]}>{RentTripStatusLabels[statusMod]}</Tag>
        },
        sorter: (a: any, b: any) => {
          const aStatusMod = getRentStatusMod(a.status, a.isQRScanned, a.isCancelled)
          const bStatusMod = getRentStatusMod(b.status, b.isQRScanned, b.isCancelled)
          return Sort.StrOrNum(aStatusMod, bStatusMod)
        },
      },
      boat.trip === 'Rent Per Hour' && {
        title: 'Rented Boats',
        dataIndex: 'units',
        key: 'units',
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (a: any) => formatPriceinUSD(a),
        sorter: (a: any, b: any) => Sort.StrOrNum(a.totalAmount, b.totalAmount),
      },
      {
        title: 'Service Fee',
        dataIndex: 'serviceFee',
        key: 'serviceFee',
        render: (a: any) => formatPriceinUSD(a),
        sorter: (a: any, b: any) => Sort.StrOrNum(a.serviceFee, b.serviceFee),
      },
      {
        title: 'Stripe Fee',
        dataIndex: 'stripeFee',
        key: 'stripeFee',
        render: (a: any) => formatPriceinUSD(a),
        sorter: (a: any, b: any) => Sort.StrOrNum(a.stripeFee, b.stripeFee),
      },
      {
        title: 'Commission',
        dataIndex: 'commission',
        key: 'commission',
        render: (v: number) => formatPriceinUSD(v),
        sorter: (a: any, b: any) => Sort.StrOrNum(a.commission, b.commission),
      },
      {
        title: `Amount to Release`,
        dataIndex: 'amountToRelease',
        key: 'amountToRelease',
        render: (v: number) => formatPriceinUSD(v),
        sorter: (a: any, b: any) => Sort.StrOrNum(a.amountToRelease, b.amountToRelease),
      },
      {
        title: 'Transaction ID Payout',
        dataIndex: 'releaseId',
        key: 'releaseId',
        render: (s: string) => s || '-',
        sorter: (a: any, b: any) => Sort.StrOrNum(a.releaseId, b.releaseId),
      },
      {
        title: 'Actions',
        dataIndex: 'paymentIntentId',
        key: 'actions',
        render: (v: string, row: any) => {
          const canTransactStatus = [RentBookingStatus.Settled, RentBookingStatus.Expired].includes(row.status)

          if (!canTransactStatus) {
            return (
              <Button className="btn-black" disabled>
                {row.isCancelled
                  ? RentTripStatusLabels.RefundTransferred
                  : RentTripStatusLabels[row.status as RentBookingStatusType]}
              </Button>
            )
          }

          const canTransact = !row.isReleased && !row.isRefunded
          const refundable = canTransact && row.status === RentBookingStatus.Expired

          return canTransact ? (
            <>
              <Button
                className={canTransact ? 'btn-danger' : 'btn-view'}
                disabled={released.loading || refunded.loading}
                onClick={() => release({ paymentIntentId: v })}
              >
                Release
              </Button>

              {refundable && (
                <Button
                  className={canTransact ? 'btn-yellow' : 'btn-view'}
                  style={{ marginLeft: '1em' }}
                  disabled={released.loading || refunded.loading}
                  onClick={() => refund({ paymentIntentId: v })}
                >
                  Refund
                </Button>
              )}
            </>
          ) : (
            <Button className="btn-black" disabled={true}>
              {row.isReleased ? 'Released' : 'Refund Transferred'}
            </Button>
          )
        },
        sorter: (a: any, b: any) => Sort.Boolean(a.amountToRelease, b.amountToRelease),
      },
    ].filter(Boolean) as any[]

  const statusFilterArray = [
    {
      title: 'Trip Status',
      filedArray: RentTripStatusFilterArray,
      listOptions: 'label',
      passed: 'value',
      actionField: 'status',
    },
  ]

  return (
    <>
      <Card title="Rent & Charter Details" extra={<Button onClick={() => history.goBack()}>Back</Button>} className="mb-10">
        {boat && (
          <>
            <h3>
              <strong>Rent & Charter Name -</strong> {receiver.name}
            </h3>
            <h3>
              <strong>Company Name -</strong> {receiver.companyName}
            </h3>
            <h3>
              <strong>Stripe Account Id -</strong> {receiver.stripeAccountId || 'N/A'}
            </h3>
            <h3>
              <strong>Ad Id -</strong> {boat.adId || 'N/A'}
            </h3>
            <h3>
              <strong>Trip -</strong> {boat.trip || 'N/A'}
            </h3>
            <h3>
              <strong>Trip Type -</strong> {boat.tripType || 'N/A'}
            </h3>
          </>
        )}
      </Card>

      <Card
        title="View Boats"
        extra={
          <div className="d-flex align-items-center">
            <TitleSearch onSearch={setSearchedValue} />
            <FilterButton filterArray={statusFilterArray} triggerAction={filterSlots} hideDivider />
          </div>
        }
      >
        <div className="mt-20 mb-20 text-center">
          <Button
            className={classNames('btn-clickable', timeStatus === 'upcoming' && 'active')}
            onClick={() => history.replace({ pathname, search, state: { receiver, boat, slot, timeStatus: 'upcoming' } })}
          >
            Upcoming Trips
          </Button>
          <Button
            className={classNames('btn-clickable', timeStatus === 'past' && 'active')}
            onClick={() => history.replace({ pathname, search, state: { receiver, boat, slot, timeStatus: 'past' } })}
          >
            Past Trips
          </Button>
        </div>

        {(() => {
          const data = getSearchedData(tableData, searchedValue)

          return (
            <ExpandTable
              columns={tableCols()}
              tableData={getPaginated(data)}
              expandHtml={false}
              isExpand={false}
              totalUsersRow={data.length}
              tableLoading={bookings.loading}
              pageAction={setPagination}
            />
          )
        })()}
      </Card>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    salesEnginCommision: state.salesEngineReducer.salesEnginCommision,
    bookings: state.pendingAdsState.rentPaymentsBoatSlotBookings,
    released: state.pendingAdsState.rentPaymentsReleaseIntent,
    refunded: state.pendingAdsState.rentPaymentsRefundIntent,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBookings: (data: any) => dispatch(getRentPaymentsBoatSlotBookings(data)),
  clearBookingsFlag: () => dispatch(clearRentPaymentsBoatSlotBookingsFlag()),
  release: (data: any) => dispatch(rentPaymentsReleaseIntent(data)),
  clearReleaseFlag: () => dispatch(clearRentPaymentsReleaseIntentFlag()),
  refund: (data: any) => dispatch(rentPaymentsRefundIntent(data)),
  clearRefundFlag: () => dispatch(clearRentPaymentsRefundIntentFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentPaymentsBoatSlotBookings)
