export interface adminOtherCountState {
  otherCounts: any[]
  otherCountSuccess: boolean
  otherCountFailure: boolean
  currencyRates: currencyRates
  alertCount: dashboardAlertCountType
}

export interface adminOtherCountData {
  roleCounts: [string]
  total: number
}
export interface currencyRates {
  id?: string
  data?: any
}

export interface dashboardAlertCountType {
  pendingVerification: number
  pendingBoatAdsCount: number
  pendingBrokerAndDealerCount: number
  pendingMfgAdsCount: number
  pendingServiceAds : number
  pendingMarinaAds: number
  pendingRentAds: number,
  pendingAgreementReq: number,
  pendingSurveyorAgreement: number,
  pendingShipperAgreement: number,
  pendingBuilderAgreement: number,
}

export const GET_ADMIN_OTHER_COUNT = 'GET_ADMIN_OTHER_COUNT'
export const GET_ADMIN_OTHER_COUNT_SUCCESS = 'GET_ADMIN_OTHER_COUNT_SUCCESS'
export const GET_ADMIN_OTHER_COUNT_FAILURE = 'GET_ADMIN_OTHER_COUNT_FAILURE'

export const GET_CURRENCY_RATES = 'GET_CURRENCY_RATES'
export const GET_CURRENCY_RATES_SUCCESS = 'GET_CURRENCY_RATES_SUCCESS'
export const GET_CURRENCY_RATES_FAILURE = 'GET_CURRENCY_RATES_FAILURE'

export const GET_DASHBOAD_VERIFICATION_ALERT = 'GET_DASHBOAD_VERIFICATION_ALERT'
export const GET_DASHBOAD_VERIFICATION_ALERT_SUCCESS = 'GET_DASHBOAD_VERIFICATION_ALERT_SUCCESS'
export const GET_DASHBOAD_VERIFICATION_ALERT_FAILURE = 'GET_DASHBOAD_VERIFICATION_ALERT_FAILURE'

interface getAdminOtherCount {
  type: typeof GET_ADMIN_OTHER_COUNT
  payload: any
}

interface getAdminOtherCountSuccess {
  type: typeof GET_ADMIN_OTHER_COUNT_SUCCESS
  payload: any
}

interface getAdminOtherCountFailure {
  type: typeof GET_ADMIN_OTHER_COUNT_FAILURE
  payload: any
}

interface getCurrencyRates {
  type: typeof GET_CURRENCY_RATES
  payload?: any
}

interface getCurrencyRatesSuccess {
  type: typeof GET_CURRENCY_RATES_SUCCESS
  payload: currencyRates
}

interface getCurrencyRatesFailure {
  type: typeof GET_CURRENCY_RATES_FAILURE
  payload: any
}

interface getDashboardVerificationAlertCount {
  type: typeof GET_DASHBOAD_VERIFICATION_ALERT
  payload: any
}

interface getDashboardVerificationAlertCountSuccess {
  type: typeof GET_DASHBOAD_VERIFICATION_ALERT_SUCCESS
  payload: any
}

interface getDashboardVerificationAlertCountFailure{
  type: typeof GET_DASHBOAD_VERIFICATION_ALERT_FAILURE
  payload: any
}

export type ActionType =
  | getAdminOtherCount
  | getAdminOtherCountSuccess
  | getAdminOtherCountFailure
  | getCurrencyRates
  | getCurrencyRatesSuccess
  | getCurrencyRatesFailure
  | getDashboardVerificationAlertCount
  | getDashboardVerificationAlertCountSuccess
  | getDashboardVerificationAlertCountFailure
