import React from 'react'

import { Modal, Row, Col, List, Tag } from 'antd'

import { tableRecords } from '../ExpandTable'
export interface Props {
  viewData: tableRecords
  isFlag: boolean
  title: string
  handleOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  handleCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}
export interface State {
  visible: boolean
}
class ManageAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      visible: false,
    }
  }
  componentDidMount() {
    this.setState({ visible: this.props.isFlag })
  }
  render() {
    const { visible } = this.state
    const { viewData, title } = this.props
    const countries = []
    countries.push(viewData.country)
    return (
      <div>
        <Modal title={title} visible={visible} onOk={this.props.handleOk} onCancel={this.props.handleCancel}>
          <Row gutter={16}>
            <Col span={24}>
              <List>
                <List.Item>
                  Country :{' '}
                  {viewData &&
                    viewData.countries &&
                    viewData.countries.map((item: string) => {
                      return <Tag key={item}> {item} </Tag>
                    })}
                </List.Item>
                <List.Item>First Name : {viewData.firstName}</List.Item>
                <List.Item>Last Name : {viewData.lastName}</List.Item>
                <List.Item>Contact Number : {viewData.ContactNumber}</List.Item>
              </List>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default ManageAccount
