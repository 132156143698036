import {
  GET_MEMBER_FEEDBACK,
  GET_MEMBER_CONTACT,
  DELETE_MEMBER_CONTACT,
  CLEAR_FORMS_FLAG,
  GET_NEWS_MULTIMEDIA,
  GET_PARTNER_RELATION,
  GET_CAREER,
  GET_COMPLAINT,
  GET_SUGGESTIONS,
  GET_CUSTOMER_SERVICES,
  DELETE_NEWS_MULTI,
  DELETE_PARTNER_RELATIONS,
  DELETE_CAREER,
  DELETE_SUGGESTIONS,
  DELETE_CUSTOMER_SERVICES,
} from './Types'
import { DELETE_COMPLAINT } from '../adminUserRole/Type'

export function getFeedback(data: any) {
  return {
    type: GET_MEMBER_FEEDBACK,
    payload: data,
  }
}

export function getContact(data: any) {
  return {
    type: GET_MEMBER_CONTACT,
    payload: data,
  }
}

export function getNewsMultimediaAction(data: any) {
  return {
    type: GET_NEWS_MULTIMEDIA,
    payload: data,
  }
}

export function getPartnerRelationAction(data: any) {
  return {
    type: GET_PARTNER_RELATION,
    payload: data,
  }
}

export function getCareerAction(data: any) {
  return {
    type: GET_CAREER,
    payload: data,
  }
}

export function getComplaintAction(data: any) {
  return {
    type: GET_COMPLAINT,
    payload: data,
  }
}

export function getSuggestionsAction(data: any) {
  return {
    type: GET_SUGGESTIONS,
    payload: data,
  }
}

export function getCustomerServiceAction(data: any) {
  return {
    type: GET_CUSTOMER_SERVICES,
    payload: data,
  }
}

export function deleteContactAction(data: any) {
  return {
    type: DELETE_MEMBER_CONTACT,
    payload: data,
  }
}

export function deleteMultiMediaAction(data: any) {
  return {
    type: DELETE_NEWS_MULTI,
    payload: data,
  }
}

export function deletePartnerRelationsAction(data: any) {
  return {
    type: DELETE_PARTNER_RELATIONS,
    payload: data,
  }
}

export function deleteCareerAction(data: any) {
  return {
    type: DELETE_CAREER,
    payload: data,
  }
}

export function deleteComplaintAction(data: any) {
  return {
    type: DELETE_COMPLAINT,
    payload: data,
  }
}

export function deleteSuggestAction(data: any) {
  return {
    type: DELETE_SUGGESTIONS,
    payload: data,
  }
}

export function deleteCustomerServicesAction(data: any) {
  return {
    type: DELETE_CUSTOMER_SERVICES,
    payload: data,
  }
}

export function clearFormsFlagAction() {
  return {
    type: CLEAR_FORMS_FLAG,
  }
}
