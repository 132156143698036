import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch } from 'antd'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import {
  getManageAllSurveyors,
  deleteSurveyors,
  clearBranchesFlag,
  changeSurveyorStatus,
} from '../../../store/manageSurveyorBranch/allBranches/Action'
import { getAllBoatPagination, defaultImage } from '../../../enums/enums'

interface Props {
  getManageAllSurveyors: typeof getManageAllSurveyors
  allBranches: []
  deleteSurveyors: typeof deleteSurveyors
  clearBranchesFlag: typeof clearBranchesFlag
  allBranchesSuccess: boolean
  deleteSurveyorSuccess: boolean
  changeSurveyorStatus: typeof changeSurveyorStatus
  allBranchesTotal: number
}

export interface State {}

class ManageAllBranches extends Component<Props, State> {
  static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { allBranchesSuccess, clearBranchesFlag, getManageAllSurveyors, deleteSurveyorSuccess } = props

    if (allBranchesSuccess) {
      clearBranchesFlag()
    }

    return null
  }

  // componentDidMount() {
  //   const { getManageAllSurveyors } = this.props
  //   getManageAllSurveyors(getAllBoatPagination)
  // }

  columns = [
    {
      title: 'Branch Image',
      dataIndex: 'image',
      key: 'image',
      render: (text: any) => {
        return text.url ? <img src={text.url || defaultImage} alt="data" width={100} height={100} /> : '-'
      },
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      render: (text: any) => {
        return <span>{text && text.alias}</span>
      },
    },
    {
      title: 'Branch Name',
      dataIndex: 'branchName',
      key: 'branchName',
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'E-mail',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
    },
    {
      title: 'Price Per Ft',
      dataIndex: 'pricePerFt',
      key: 'pricePerFt',
    },
    {
      title: 'Surveyor',
      dataIndex: 'contactName',
      key: 'contactName',
    },
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'address',
      render: (text: string, record: any, index: number) => {
        const [{ country }] = record.address
        return <span>{country}</span>
      },
    },
    {
      title: 'City',
      dataIndex: 'address',
      key: 'address',
      render: (text: string, record: any, index: number) => {
        const [{ city }] = record.address
        return <span>{city}</span>
      },
    },
    {
      title: 'Branch Status',
      dataIndex: 'branchStatus',
      key: 'branchStatus',
      render: (text: string, record: tableRecords, index: number) => {
        const { changeSurveyorStatus } = this.props

        return (
          <Switch
            checked={record.branchStatus}
            onChange={() =>
              changeSurveyorStatus({
                value: record.branchStatus,
                column: 'branchStatus',
                id: record.id,
              })
            }
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        )
      },
    },
    {
      title: 'Branch Verification Status',
      dataIndex: 'branchVerificationStatus',
      key: 'branchVerificationStatus',
      render: (text: string, record: tableRecords, index: number) => {
        const { changeSurveyorStatus } = this.props

        return (
          <Switch
            checked={record.branchVerificationStatus}
            onChange={() =>
              changeSurveyorStatus({
                value: record.branchVerificationStatus,
                column: 'branchVerificationStatus',
                id: record.id,
              })
            }
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      render: (text: string, record: tableRecords, index: number) => {
        const { deleteSurveyors } = this.props
        return (
          <>
            <Button
              className="btn-delete"
              onClick={() => {
                record && record.id && confirmSubmitHandler(deleteSurveyors, record.id)
              }}
            >
              Delete
            </Button>
          </>
        )
      },
    },
  ]

  render() {
    const { allBranches, allBranchesTotal, getManageAllSurveyors } = this.props

    return (
      <>
        <Card title="Manage All Surveyor's Branches">
          <ExpandTable
            columns={this.columns}
            tableData={allBranches}
            page="boat"
            pageAction={getManageAllSurveyors}
            expandHtml={false}
            totalUsersRow={allBranchesTotal}
            isExpand={false}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  allBranches: state.allBranchesReducer && state.allBranchesReducer.allBranches,
  deleteSurveyorSuccess: state.allBranchesReducer && state.allBranchesReducer.deleteSurveyorSuccess,
  allBranchesTotal: state.allBranchesReducer && state.allBranchesReducer.allBranchesTotal,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getManageAllSurveyors: (data: any) => dispatch(getManageAllSurveyors(data)),
  deleteSurveyors: (data: string) => dispatch(deleteSurveyors(data)),
  clearBranchesFlag: () => dispatch(clearBranchesFlag()),
  changeSurveyorStatus: (data: any) => dispatch(changeSurveyorStatus(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAllBranches)
