import React, { GetDerivedStateFromProps } from 'react'
import { Card, Button, Row, Col, Typography } from 'antd'
import History from '../../../History'
import WSField from '../../../component/Field'
import { getForgotPassword, createForgotPassword, clearPasswordFlag } from '../../../store/staticContent/forgotPassword/Action'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import UploadImage from '../../../component/UploadImage'
import { Formik, ErrorMessage } from 'formik'
import { handleSingleFileDelete, handleSingleFileUpload } from '../../../helpers/S3FileUpload'
import NotificationWithIcon from '../../../component/notification/Notification'
import { splitUrlFromUnderscore, removePTag, getAddMedia, trimmedValue } from '../../../helpers/helper'
import { passCreateData, CLEAR_FORGOT_PASSWORD_FLAG } from '../../../store/staticContent/forgotPassword/Type'
import { MEDIA_NAME, MEDIA_TYPE, imageExtensions } from '../../../enums/enums'
import * as Yup from 'yup'
import Loader from '../../../component/Loading'

const { Text } = Typography

interface ForgotPassState {
  createPassField: {
    id?: string
    title: string
    content: string
    buttonText: string
    otherDescription: string
    addMedia: any[]
    removeMedia?: [string]
    icon?: any
    __typename?: any
  }
  loading: boolean
}
interface ForgotPassProps {
  history: typeof History
  getForgotPasswordContent: typeof getForgotPassword
  createForgotPassword: typeof createForgotPassword
  clearPasswordFlag: typeof clearPasswordFlag
  isLoading: boolean
  passwordContent: {
    id?: string
    title: string
    content: string
    buttonText: string
    otherDescription: string
    addMedia: any[]
    removeMedia?: [string]
    icon: any
    __typename: any
  }
  addSuccess: boolean
}

class ForgotPassword extends React.Component<ForgotPassProps, ForgotPassState> {
  constructor(props: ForgotPassProps) {
    super(props)
    this.state = {
      createPassField: {
        id: '',
        title: '',
        content: '',
        buttonText: '',
        otherDescription: '',
        addMedia: [],
      },
      loading: false,
    }
  }
  componentDidMount() {
    const { getForgotPasswordContent } = this.props
    getForgotPasswordContent()
    clearPasswordFlag()
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<ForgotPassProps, ForgotPassState> = (props, state) => {
    const { addSuccess, clearPasswordFlag } = props
    console.log(addSuccess)
    if (addSuccess) {
      NotificationWithIcon('success', 'Content Updated')
      clearPasswordFlag()
    }
    return null
  }

  selectSiteIcon = async (file: any, setFieldValue: any, values: any, acceptType: string) => {
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(
        file,
        'addMedia',
        setFieldValue,
        values.addMedia,
        MEDIA_NAME.PASSWORDCONTENTICON,
        MEDIA_TYPE.IMAGE
      )
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }
  handleRemove = (file: any, setFieldValue: any, values: any) => {
    const { addMedia } = values

    handleSingleFileDelete(file, 'addMedia', setFieldValue, addMedia, values.addMedia, 'filter')
  }

  render() {
    const { createPassField } = this.state
    const { history, createForgotPassword, passwordContent, isLoading } = this.props
    return (
      <>
        {' '}
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={
              passwordContent.id
                ? {
                    ...passwordContent,
                    addMedia: passwordContent.icon,
                    removeMedia: [],
                  }
                : createPassField
            }
            onSubmit={values => {
              const { icon, __typename, ...submitValues } = values

              createForgotPassword({
                ...submitValues,
                addMedia: getAddMedia(values.addMedia),
                content: removePTag(values.content),
                otherDescription: removePTag(values.otherDescription),
              })
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Title field is required.'),
              content: Yup.string().required('Content field is required.'),
              otherDescription: Yup.string().required('Button description field is required.'),
              buttonText: Yup.string().required('Button Text field is required.'),
              addMedia: Yup.string().required('Icon field is require'),
            })}
          >
            {props => {
              const { handleSubmit, setFieldValue, values, errors } = props
              return (
                <form onSubmit={handleSubmit}>
                  {console.log(errors, 'errors')}
                  {console.log(values, 'values')}

                  <Card
                    title="Forgot Password"
                    bordered={false}
                    extra={
                      <div>
                        <Button onClick={() => history.goBack()}>Back</Button>
                        <Button type="primary" onClick={() => handleSubmit()} className="ml-15">
                          Save
                        </Button>
                      </div>
                    }
                  >
                    <Row gutter={16}>
                      <Col className="gutter-box" xs={24} sm={24} md={24} lg={24} xl={24}>
                        {values.addMedia && values.addMedia.length ? (
                          <UploadImage
                            defaultFileList={values.addMedia.map((item: any) => {
                              return {
                                uid: item.id,
                                name: 'addMedia',
                                status: 'done',
                                url: item.url,
                              }
                            })}
                            accept={imageExtensions}
                            buttonText="Uploaded addMedia"
                            withIcon
                            openFileDialogOnClick={values.addMedia && !values.addMedia.length}
                            selectSiteIcon={(file: any) => this.selectSiteIcon(file, setFieldValue, values, 'image')}
                            onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                          />
                        ) : (
                          <UploadImage
                            accept={imageExtensions}
                            withIcon
                            buttonText="Password Image"
                            openFileDialogOnClick={values.addMedia && !values.addMedia.length}
                            selectSiteIcon={(file: any) => this.selectSiteIcon(file, setFieldValue, values, 'image')}
                            onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                          />
                        )}
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name="addMedia" component="span" />
                        </Text>
                      </Col>
                      <Col className="gutter-box" xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="ant-form-item-required">Title</div>
                        <WSField
                          type="textbox"
                          name="title"
                          value={values.title}
                          defaultValue={values.title}
                          placeholder="Title"
                          onChange={(e: any) => setFieldValue('title', e.target.value)}
                          onBlur={(e: any) => setFieldValue('title', trimmedValue(e.target.value))}
                        />
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name="title" component="span" />
                        </Text>
                      </Col>
                      {/* <Col className="gutter-box" xs={24} sm={24} md={12} lg={12} xl={12}>
                        <div className="ant-form-item-required">Button Text</div>
                        <WSField
                          type="textbox"
                          defaultValue={values.buttonText}
                          value={values.buttonText}
                          name="buttonText"
                          placeholder="Button Text"
                          onChange={(e: any) => setFieldValue('buttonText', e.target.value)}
                          onBlur={(e: any) => setFieldValue('buttonText', trimmedValue(e.target.value))}
                        />
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name="buttonText" component="span" />
                        </Text>
                      </Col> */}
                      <Col className="gutter-box" xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="ant-form-item-required">Content</div>
                        <WSField
                          type="textArea"
                          name="content"
                          placeholder="Content"
                          defaultValue={values.content && values.content}
                          onChangeText={(evt: any) => setFieldValue('content', evt.editor.getData())}
                          onBlur={(evt: { editor: { getData: () => string } }) =>
                            setFieldValue('content', trimmedValue(removePTag(evt.editor.getData())))
                          }
                        />
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name="content" component="span" />
                        </Text>
                      </Col>

                      <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-5">
                        <div className="ant-form-item-required">Button Description</div>
                        <WSField
                          type="textArea"
                          name="otherDescription"
                          defaultValue={values.otherDescription && values.otherDescription}
                          placeholder="Button Description"
                          onChangeText={(evt: any) => setFieldValue('otherDescription', evt.editor.getData())}
                          onBlur={(evt: { editor: { getData: () => string } }) =>
                            setFieldValue('otherDescription', trimmedValue(removePTag(evt.editor.getData())))
                          }
                        />
                        <Text type="danger">
                          <ErrorMessage
                            className="invalid-feedback ant-typography-danger"
                            name="otherDescription"
                            component="span"
                          />
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </form>
              )
            }}
          </Formik>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  passwordContent: state.homeAdvertisementsReducer?.passwordContent,
  isLoading: state.homeAdvertisementsReducer?.isLoading,
  addSuccess: state.homeAdvertisementsReducer?.addSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getForgotPasswordContent: () => dispatch(getForgotPassword()),
    createForgotPassword: (data: passCreateData) => dispatch(createForgotPassword(data)),
    clearPasswordFlag: () => dispatch(clearPasswordFlag()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
