import { put, takeLatest, takeEvery, all } from 'redux-saga/effects'
import { client } from '../../'
import {
  CREATE_AGREEMENT_CONTENT,
  GET_ALL_AGREEMENT_CONTENTS,
  GET_SINGLE_AGREEMENT_CONTENT,
  UPDATE_AGREEMENT_CONTENT,
  DELETE_AGREEMENT_CONTENT,
  CREATE_AGREEMENT,
  GET_ALL_AGREEMENTS,
  DELETE_AGREEMENT,
  ActionType,
  CREATE_AGREEMENT_SUCCESS,
  CREATE_AGREEMENT_FAILURE,
  GET_ALL_AGREEMENTS_SUCCESS,
  GET_ALL_AGREEMENTS_FAILURE,
  DELETE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT_FAILURE,
  CREATE_AGREEMENT_CONTENT_SUCCESS,
  CREATE_AGREEMENT_CONTENT_FAILURE,
  GET_ALL_AGREEMENT_CONTENTS_SUCCESS,
  GET_ALL_AGREEMENT_CONTENTS_FAILURE,
  GET_SINGLE_AGREEMENT_CONTENT_SUCCESS,
  GET_SINGLE_AGREEMENT_CONTENT_FAILURE,
  UPDATE_AGREEMENT_CONTENT_SUCCESS,
  UPDATE_AGREEMENT_CONTENT_FAILURE,
  DELETE_AGREEMENT_CONTENT_SUCCESS,
  DELETE_AGREEMENT_CONTENT_FAILURE,
  agreementInput,
  agreementContentInput,
  CHANGE_AGREEMENT_STATUS,
  CHANGE_AGREEMENT_STATUS_SUCCESS,
  CHANGE_AGREEMENT_STATUS_FAILURE,
  UPDATE_AGREEMENT_SUCCESS,
  UPDATE_AGREEMENT_FAILURE,
  UPDATE_AGREEMENT,
  GET_ALL_PENDING_AGREEMENT_REQUESTS,
  GET_ALL_PENDING_AGREEMENT_REQUESTS_SUCCESS,
  GET_ALL_PENDING_AGREEMENT_REQUESTS_FAILURE,
  APPROVE_PENDING_AGREEMENT,
  APPROVE_PENDING_AGREEMENT_SUCCESS,
  APPROVE_PENDING_AGREEMENT_FAILURE,
  CLEAR_AGREEMENT_CONTENT_FLAG,
} from './Types'
import {
  createAgreement,
  getAllAgreements,
  createAgreementContent,
  updateAgreementContent,
  getAllAgreementContents,
  getSingleAgreementContent,
  deleteAgreement,
  deleteAgreementContent,
  changeStatusAgreement,
  updateAgreement,
  getAllPendingAgreementRequests,
  approvePendingAgreements,
} from './Schema'

function addAgreementApi(input: agreementInput) {
  return client
    .mutate({
      mutation: createAgreement,
      variables: { input },
    })
}

function updateAgreementApi(data: any) {
  const input = {
    id: data.id,
    country: data.country,
    companyName: data.companyName,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    companyWebsite: data.companyWebsite,
    mobileNumber: data.mobileNumber,
    userType: data.userType,
    role: data.role,
  }

  return client
    .mutate({
      mutation: updateAgreement,
      variables: { input },
    })
}

function getAgreementsApi(input: any) {
  return client
    .query({
      query: getAllAgreements,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function deleteAgreementsApi(input: string) {
  return client
    .mutate({
      mutation: deleteAgreement,
      variables: { id: input },
    })
}

function toggleAgreementStatusApi(input: any) {
  return client
    .mutate({
      mutation: changeStatusAgreement,
      variables: input,
    })
}

function addAgreementContentApi(input: agreementContentInput) {
  return client
    .mutate({
      mutation: createAgreementContent,
      variables: { input },
    })
}

function getAgreementContentsApi(input: any) {
  return client
    .query({
      query: getAllAgreementContents,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function getAgreementContentByIdApi(input: string) {
  return client
    .query({
      query: getSingleAgreementContent,
      variables: { id: input },
      fetchPolicy: 'no-cache',
    })
}

function updateAgreementContentsApi(input: agreementContentInput) {
  return client
    .mutate({
      mutation: updateAgreementContent,
      variables: { input },
    })
}

function deleteAgreementContentsApi(input: string) {
  return client
    .mutate({
      mutation: deleteAgreementContent,
      variables: { id: input },
    })
}

function getPendingAgreementsApi(input: any) {
  return client
    .query({
      query: getAllPendingAgreementRequests,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function approvePendingAgreementApi(data: any) {
  return client
    .mutate({
      mutation: approvePendingAgreements,
      variables: data,
    })
}

function* updateAgreementData(action: ActionType) {
  try {
    const data = yield updateAgreementApi(action.payload)

    if (data && data.networkError && data.networkError.result && data.networkError.result.errors) {
      yield put({
        type: UPDATE_AGREEMENT_FAILURE,
        payload: data.networkError.result.errors,
      })
    } else {
      yield put({
        type: UPDATE_AGREEMENT_SUCCESS,
        payload: data.data.updateAgreement,
      })
    }
  } catch (error) {
    yield put({
      type: UPDATE_AGREEMENT_FAILURE,
      error: error.networkError.result.errors,
    })
  }
}

function* addAgreement(action: ActionType) {
  try {
    const res = yield addAgreementApi(action.payload)
    if (res && res.networkError && res.networkError.result && res.networkError.result.errors) {
      yield put({
        type: CREATE_AGREEMENT_FAILURE,
        error: res.networkError.result.errors,
      })
    } else {
      yield put({
        type: CREATE_AGREEMENT_SUCCESS,
        payload: res.data.createAgreement,
      })
    }
  } catch (error) {
    yield put({
      type: CREATE_AGREEMENT_FAILURE,
      error: error.networkError.result.errors,
    })
  }
}

function* getAgreements(action: ActionType) {
  try {
    const res = yield getAgreementsApi(action.payload)
    yield put({
      type: GET_ALL_AGREEMENTS_SUCCESS,
      payload: res.data.getAllAgreements,
    })
  } catch (error) {
    yield put({ type: GET_ALL_AGREEMENTS_FAILURE, error })
  }
}

function* deleteAgreements(action: ActionType) {
  try {
    const res = yield deleteAgreementsApi(action.payload)
    yield put({
      type: DELETE_AGREEMENT_SUCCESS,
      payload: res.data.deleteAgreement,
    })
  } catch (error) {
    yield put({ type: DELETE_AGREEMENT_FAILURE, error })
  }
}

function* toggleAgreementStatus(action: ActionType) {
  try {
    const res = yield toggleAgreementStatusApi(action.payload)
    if (res && res.networkError && res.networkError.result && res.networkError.result.errors) {
      yield put({
        type: CHANGE_AGREEMENT_STATUS_FAILURE,
        error: res.networkError.result.errors,
      })
    } else {
      yield put({
        type: CHANGE_AGREEMENT_STATUS_SUCCESS,
        payload: res.data.changeStatusAgreement,
        column: action.payload.column,
      })
    }
  } catch (error) {
    yield put({
      type: CHANGE_AGREEMENT_STATUS_FAILURE,
      error: error.networkError.result.errors,
    })
  }
}

function* addAgreementContent(action: ActionType) {
  try {
    const res = yield addAgreementContentApi(action.payload)
    yield put({
      type: CREATE_AGREEMENT_CONTENT_SUCCESS,
      payload: res.data.createAgreementContent,
    })
  } catch (error) {
    yield put({ type: CREATE_AGREEMENT_CONTENT_FAILURE, error })
  }
}

function* getAgreementContents(action: ActionType) {
  try {
    const res = yield getAgreementContentsApi(action.payload)
    yield put({
      type: GET_ALL_AGREEMENT_CONTENTS_SUCCESS,
      payload: res.data.getAllAgreementContents,
    })
  } catch (error) {
    yield put({ type: GET_ALL_AGREEMENT_CONTENTS_FAILURE, error })
  }
}

function* getAgreementContentById(action: ActionType) {
  try {
    const res = yield getAgreementContentByIdApi(action.payload)
    yield put({
      type: GET_SINGLE_AGREEMENT_CONTENT_SUCCESS,
      payload: res.data.getSingleAgreementContent,
    })
  } catch (error) {
    yield put({ type: GET_SINGLE_AGREEMENT_CONTENT_FAILURE, error })
  }
}

function* updateAgreementContents(action: ActionType) {
  try {
    const res = yield updateAgreementContentsApi(action.payload)
    yield put({
      type: UPDATE_AGREEMENT_CONTENT_SUCCESS,
      payload: res.data.updateAgreementContent,
    })
  } catch (error) {
    yield put({ type: UPDATE_AGREEMENT_CONTENT_FAILURE, error })
  }
}

function* deleteAgreementContents(action: ActionType) {
  try {
    const res = yield deleteAgreementContentsApi(action.payload)
    yield put({
      type: DELETE_AGREEMENT_CONTENT_SUCCESS,
      payload: res.data.deleteAgreementContent,
    })
  } catch (error) {
    yield put({ type: DELETE_AGREEMENT_CONTENT_FAILURE, error })
  }
}

function* getPendingAgreementsData(action: ActionType) {
  try {
    const res = yield getPendingAgreementsApi(action.payload)
    yield put({
      type: GET_ALL_PENDING_AGREEMENT_REQUESTS_SUCCESS,
      payload: res.data.getAllPendingUsers,
    })
  } catch (error) {
    yield put({ type: GET_ALL_PENDING_AGREEMENT_REQUESTS_FAILURE, error })
  }
}

function* approvePendingAgreementData(action: ActionType) {
  try {
    const res = yield approvePendingAgreementApi(action.payload)
    if (res && res.networkError && res.networkError.result && res.networkError.result.errors) {
      yield put({
        type: APPROVE_PENDING_AGREEMENT_FAILURE,
        error: res.networkError.result.errors,
      })
    } else {
      yield put({
        type: APPROVE_PENDING_AGREEMENT_SUCCESS,
        payload: res.data.moveAgreementStep,
      })
    }
  } catch (error) {
    yield put({
      type: APPROVE_PENDING_AGREEMENT_FAILURE,
      error: error.networkError.result.errors,
    })
  }
  yield put({ type: CLEAR_AGREEMENT_CONTENT_FLAG })
}

function* addAgreementSaga() {
  yield takeLatest(CREATE_AGREEMENT, addAgreement)
}

function* updateAgreementSaga() {
  yield takeLatest(UPDATE_AGREEMENT, updateAgreementData)
}

function* getAgreementsSaga() {
  yield takeEvery(GET_ALL_AGREEMENTS, getAgreements)
}

function* deleteAgreementSaga() {
  yield takeLatest(DELETE_AGREEMENT, deleteAgreements)
}

function* toggleAgreementStatusSaga() {
  yield takeLatest(CHANGE_AGREEMENT_STATUS, toggleAgreementStatus)
}

function* addAgreementContentSaga() {
  yield takeLatest(CREATE_AGREEMENT_CONTENT, addAgreementContent)
}

function* getAgreementContentsSaga() {
  yield takeLatest(GET_ALL_AGREEMENT_CONTENTS, getAgreementContents)
}

function* getAgreementContentByIdSaga() {
  yield takeLatest(GET_SINGLE_AGREEMENT_CONTENT, getAgreementContentById)
}

function* updateAgreementContentSaga() {
  yield takeLatest(UPDATE_AGREEMENT_CONTENT, updateAgreementContents)
}

function* deleteAgreementContentSaga() {
  yield takeLatest(DELETE_AGREEMENT_CONTENT, deleteAgreementContents)
}

function* getPendingAgreementsSaga() {
  yield takeLatest(GET_ALL_PENDING_AGREEMENT_REQUESTS, getPendingAgreementsData)
}

function* approvePendingAgreementSaga() {
  yield takeLatest(APPROVE_PENDING_AGREEMENT, approvePendingAgreementData)
}

export default function* agreementSaga() {
  yield all([
    addAgreementSaga(),
    getAgreementsSaga(),
    deleteAgreementSaga(),
    toggleAgreementStatusSaga(),
    addAgreementContentSaga(),
    getAgreementContentsSaga(),
    getAgreementContentByIdSaga(),
    updateAgreementContentSaga(),
    deleteAgreementContentSaga(),
    updateAgreementSaga(),
    getPendingAgreementsSaga(),
    approvePendingAgreementSaga(),
  ])
}
