import gql from 'graphql-tag'

export const getAllReviews = gql`
  query getAllReviews($userType: String, $limit: Int, $page: Int) {
    getAllReviews(userType: $userType, limit: $limit, page: $page) {
      items {
        id
        rating
        reviews
        reviewStatus
        createdAt
        user {
          firstName
          lastName
          address {
            country
          }
        }
        moduleId {
          firstName
          lastName
          address {
            country
          }
        }
      }
      total
    }
  }
`

export const changeReviewStatus = gql`
  mutation changeReviewStatus($id: String!, $column: String!, $value: Boolean!) {
    changeReviewStatus(id: $id, column: $column, value: $value) {
      id
      rating
      reviews
      createdAt
      reviewStatus
      user {
        firstName
        lastName
        address {
          country
        }
      }
      moduleId {
        firstName
        lastName
        address {
          country
        }
      }
    }
  }
`

export const rejectReview = gql`
  mutation rejectReview($id: String!) {
    rejectReview(id: $id) {
      id
      rating
      reviews
      createdAt
      reviewStatus
      user {
        firstName
        lastName
        address {
          country
        }
      }
      moduleId {
        firstName
        lastName
        address {
          country
        }
      }
    }
  }
`
