import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'



export const confirmSubmitHandler = (fn: any, value: any, message: string = 'Are you sure to perform this action ?') => {
  
  confirmAlert({
    title: 'Alert',
    message: message,
    buttons: [
      {
        label: 'Yes',
        onClick: () => fn(value),
      },
      {
        label: 'No',
        onClick: () => null,
      },
    ],
  })
}
export const confirmDelecteHandler = (mutation :any, value: any, message: string = 'Are you sure to perform this action ?') => {
  
  confirmAlert({
    title: 'Alert',
    message: message,
    buttons: [
      {
        label: 'Yes',
        onClick: () =>{ mutation({variables:{id:value}})} ,
      },
      {
        label: 'No',
        onClick: () => null,
      },
    ],
  })
}