import gql from 'graphql-tag'
import { AddressType, imageSchema } from '../schemaTypes'

export const getSingleSellerBoatAds = gql`
  query getPendingBoats($id: String!, $page: Int, $limit: Int ) {
    getPendingBoats(id: $id, page: $page, limit: $limit) {
      items {
        id
        adStatus
        featureStatus
        bestDealStatus
        mustBuyStatus
        address ${AddressType}
        complianceLabelNumber
        hullSerialNumber
        vehicleIdentificationNumber
        licenceNo

        boatType {
          name
        }

        listedBy
        boatStatus {
          id
          alias
          typeId {
            id
            type
          }
        }
        boatName
        boatParking {
          id
          alias
          typeId {
            id
            type
          }
        }
        trailer
        yearBuilt
        manufacturedBy {
          typeId {
            type
            id
          }
          alias
          id
          lookUpName
          isActive
        }
        hullMaterial {
          id
          alias
          typeId {
            id
            type
          }
        }
        hullColor {
          typeId {
            type
            id
          }
          alias
          id
          lookUpName
          isActive
        }
        usedHours
        noOfEngines
        modelYear
        fuelType {
          id
          alias
          typeId {
            id
            type
          }
        }
        fuelCapacity
        holdingCapacity
        freshWater
        engineManufacturer
        engineHp
        engineDrives {
          id
          alias
          typeId {
            id
            type
          }
        }
        engineStroke {
          id
          alias
          typeId {
            id
            type
          }
        }
        waterMarker
        bowThruster
        steeringSystem
        stabilizerSystem
        oilWaterSeparator
        fireBilgePump
        output
        generators
        emergencyGenerator
        batteriesCount
        batteryType
        decks
        heightInFt
        lengthInFt
        widthInFt
        weightInKg
        beam
        draft
        displacement
        mainSaloon {
          id
          alias
          typeId {
            id
            type
          }
        }
        mainSaloonConvertible {
          id
          alias
          typeId {
            id
            type
          }
        }
        numberOfHeads
        crewCabinCount
        crewBerthCount
        crewHeadsCount
        usage
        accidentHistory
        repairHistory
        lastMaintenance
        price
        description
        boatOwnershipId  {
					id
					url
				}
				ownershipAttachment {
					id
					url
				}
				images {
					id
					url
				}
				layout {
					id
					url
				}
        video
        status
        seller {
          firstName
          lastName
          role {
						aliasName
					}
        }
      }
      total
    }
  }
`

export const changeStatus = gql`
  mutation boatChangeStatus($value: Boolean, $column: String!, $id: String!, $adType: String!, $action: String!) {
    boatChangeStatus(value: $value, column: $column, id: $id, adType: $adType, action: $action) {
      id
    }
  }
`

export const getPendingBoatRents = gql`
  query pendingRentBoats($page: Int, $limit: Int) {
    pendingRentBoats(page: $page, limit: $limit) {
      items {
        adStatus
        id
        address ${AddressType}
        captainName
        tripDuration
        startTime
        tripType {
          id
          alias
        }
        endTime
        boatLength
        bedroomAndCabins
        price
        maximumGuest
        bathrooms
        seatsOnBoat
        deckAndEntertainment {
          id
          alias
        }
        whatToBring
        descriptionOfOurTrip
        youtubeLinkVideo
        boatRentStatus
        user {
          id
          firstName
          lastName
          role {
            aliasName
          }
        }
        adId
        reviews {
          reviews {
            id
          }
          ratings {
            count
          }
        }
        createdAt
      }
      total
    }
  }
`

export const getAllPendingMarinaAndStorage = gql`
  query pendingMarinas($page: Int, $limit: Int) {
    pendingMarinas(page: $page, limit: $limit) {
      items {
        id
        accountStatus
        createdAt
        adId
        serviceProvide {
          id
          name
        }
        provider {
          alias
        }
        status
        user {
          id
          firstName
          companyName
          lastName
          role {
            aliasName
          }
        }
        accountStatus
        adStatus
      }
      total
    }
  }
`

export const changeMarinaStatus = gql`
  mutation marinaChangeStatus($value: Boolean, $column: String!, $id: String!) {
    marinaChangeStatus(value: $value, column: $column, id: $id) {
      id
    }
  }
`

export const boatRentChangeStatus = gql`
  mutation boatRentChangeMarketStatus($input: MarketStatusInput!) {
    boatRentChangeMarketStatus(input: $input) {
      id
    }
  }
`

export const getPendingBoatServices = gql`
  query getPendingBoatServices($page: Int, $limit: Int) {
    getPendingBoatServices(page: $page, limit: $limit) {
      items {
        id
        user {
          firstName
          lastName
          companyName
        }
        serviceProvide {
          id
          name
        }
        adId
        createdAt
        adStatus
        status
      }
      total
    }
  }
`
