import {
  ActionType,
  stateData,
  initialStatesState,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAILURE,
  CREATE_STATE,
  CREATE_STATE_SUCCESS,
  CREATE_STATE_FAILURE,
  CLEAR_STATE_FLAG,
  UPDATE_STATE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAILURE,
  DELETE_STATE,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAILURE,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: initialStatesState = {
  isLoading: false,
  stateSuccess: false,
  stateError: false,
  states: [],
  updateError: false,
  updateSuccess: false,
  deleteError: false,
  deleteSuccess: false,
}

export const stateReducer = (state = initialState, action: ActionType): initialStatesState => {
  switch (action.type) {
    case GET_STATE:
      return {
        ...state,
        isLoading: true,
      }
    case GET_STATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        states: action.payload.data.getAllStates.items,
      }
    case GET_STATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_STATE:
      return {
        ...state,
        isLoading: true,
        stateSuccess: false,
        stateError: false,
      }
    case CREATE_STATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stateSuccess: true,
        stateError: false,
      }
    }
    case CREATE_STATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        stateSuccess: false,
        stateError: true,
      }
    }
    case CLEAR_STATE_FLAG: {
      return {
        ...state,
        isLoading: false,
        stateSuccess: false,
        stateError: false,
        updateError: false,
        updateSuccess: false,
        deleteError: false,
        deleteSuccess: false,
      }
    }
    case UPDATE_STATE: {
      return {
        ...state,
        updateError: false,
        updateSuccess: false,
      }
    }
    case UPDATE_STATE_SUCCESS: {
      return {
        ...state,
        updateError: false,
        updateSuccess: true,
      }
    }
    case UPDATE_STATE_FAILURE: {
      return {
        ...state,
        updateError: true,
        updateSuccess: false,
      }
    }
    case DELETE_STATE: {
      return {
        ...state,
        deleteError: false,
        deleteSuccess: false,
      }
    }
    case DELETE_STATE_SUCCESS: {
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteError: false,
        deleteSuccess: true,
      }
    }
    case DELETE_STATE_FAILURE: {
      return {
        ...state,
        deleteError: true,
        deleteSuccess: false,
      }
    }

    default:
      return state
  }
}
