import gql from 'graphql-tag'

export const getSalesEngineHelpContent = gql`
  {
    getAllsaleEngineContexts {
      id
      member
      content
      createdAt
    }
  }
`

export const createSalesEngineHelpContent = gql`
  mutation createsaleEngineContexts($input: SaleEngineHelpContextsInformationInput!) {
    createsaleEngineContexts(input: $input) {
      id
      member
      content
      createdAt
    }
  }
`
