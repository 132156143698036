import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card } from 'antd'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { pagination, paginationPage } from '../../../enums/enums'
import { getJobsApplications } from '../../../store/staticContent/manageJobs/Action'
import { TitleSearch } from '../../../component/SearchBar'
import { getApplicationInput, appliedJobs } from '../../../store/staticContent/manageJobs/Type'
import { getSearchedData } from '../../../helpers/helper'
import { getDocumentUrlAction } from '../../../store/dashboard/documentVerify/Action'

interface Props {
  match: any
  getJobsApplications: typeof getJobsApplications
  getDocumentUrlAction: typeof getDocumentUrlAction
  jobsApplication: appliedJobs[]
  totalApplications: number
}

interface State {
  searchedValue: string
  jobId: string
}

class ManageJobsApplications extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { match } = props
    const { params } = match

    if (params?.id) {
      return {
        jobId: params.id,
      }
    }

    return null
  }

  componentDidMount() {
    const { jobId } = this.state
    const { getJobsApplications } = this.props

    jobId && getJobsApplications({ jobId })
  }

  state = {
    searchedValue: '',
    jobId: '',
    columns: [
      {
        title: 'Name',
        dataIndex: 'fullName',
        key: 'fullName',
      },
      {
        title: 'Experience',
        dataIndex: 'experience',
        key: 'experience',
        render: (text: string) => String(text).replace(/[^0-9]+/g, '') + ' years',
      },
      {
        title: 'CV',
        dataIndex: 'cvUrl',
        key: 'cvUrl',
        render: (text: any, record: tableRecords, index: number) => {
          const { getDocumentUrlAction } = this.props
          return (
            <span className="cursor-pointer" onClick={() => getDocumentUrlAction({ mediaId: text.id })}>
              Download CV
            </span>
          )
        },
      },
      {
        title: 'Cover Letter',
        dataIndex: 'coverLetter',
        key: 'coverLetter',
        render: (text: any, record: tableRecords, index: number) => {
          if (!text) return '-'
          const { getDocumentUrlAction } = this.props
          return (
            <span className="cursor-pointer" onClick={() => getDocumentUrlAction({ mediaId: text.id })}>
              Download Cover Letter
            </span>
          )
        },
      },
    ],
  }

  onSearch = (value: any) => {
    this.setState({ searchedValue: value })
  }

  render() {
    const { columns, searchedValue } = this.state
    const { jobsApplication, totalApplications } = this.props

    return (
      <Card
        title="Manage Jobs Application"
        extra={
          <div className="d-flex flex-wrap">
            <div className="mr-10">
              <TitleSearch onSearch={this.onSearch} />
            </div>
          </div>
        }
      >
        <ExpandTable
          columns={columns}
          tableData={getSearchedData(jobsApplication, searchedValue)}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={totalApplications}
          page={paginationPage.boat}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  jobsApplication: state?.jobReducer?.jobsApplication,
  totalApplications: state?.jobReducer?.totalApplications,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getJobsApplications: (data: getApplicationInput) => dispatch(getJobsApplications(data)),
  getDocumentUrlAction: (data: any) => dispatch(getDocumentUrlAction(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageJobsApplications)
