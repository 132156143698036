import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { BoatTypesData } from '../../../store/staticContent/manageMarinaTypes/Types'
import './ManageBoatTypes.scss'
import History from '../../../History'
import CreateCategoryIndex from '../CategoryIndexPages/CreateCategoryIndex'
import { clearManageBoatTypesFlag, updateBoatTypes, createBoatTypes } from '../../../store/staticContent/manageBoatTypes/Action'
import { MEDIA_NAME } from '../../../enums/enums'

interface Props {
  boatTypeSuccess: boolean
  updateBoatTypeSuccess: boolean
  location: any
  clearManageBoatTypesFlag: typeof clearManageBoatTypesFlag
  updateBoatTypes: typeof updateBoatTypes
  history: typeof History
  createBoatTypes: typeof createBoatTypes
}

class CreateBoatTypes extends Component<Props> {
  render() {
    const {
      boatTypeSuccess,
      updateBoatTypeSuccess,
      createBoatTypes,
      updateBoatTypes,
      history,
      clearManageBoatTypesFlag,
      location,
    } = this.props

    return (
      <CreateCategoryIndex
        history={history}
        pageTitle="Boat Type"
        mediaName={MEDIA_NAME.BOATTYPEICON}
        iconMediaName={MEDIA_NAME.BOATTHUMBNAILTYPEICON}
        imageTitle="Boat Image"
        popupTitle="Boat"
        iconTitle="Boat Icon"
        editedData={location.state ? location.state.boatType : null}
        successType={boatTypeSuccess}
        updateSuccessType={updateBoatTypeSuccess}
        createAction={createBoatTypes}
        updateAction={updateBoatTypes}
        clearTypesFlag={clearManageBoatTypesFlag}
        listPageLink="/manage-boat-types"
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  boatTypeSuccess: state.boatTypesReducer && state.boatTypesReducer.boatTypeSuccess,
  updateBoatTypeSuccess: state.boatTypesReducer && state.boatTypesReducer.updateBoatTypeSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createBoatTypes: (data: BoatTypesData) => dispatch(createBoatTypes(data)),
  updateBoatTypes: (data: BoatTypesData) => dispatch(updateBoatTypes(data)),
  clearManageBoatTypesFlag: () => dispatch(clearManageBoatTypesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateBoatTypes)
