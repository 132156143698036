import {
  deleteReviewQuery,
  getAdReportsQuery,
  getAdminAuditLogs,
  getAllPaymentMethodTypes,
  getRentPaymentsBoatSlotBookingsQuery,
  getRentPaymentsBoatSlotsQuery,
  getRentPaymentsPrivateBoatSlotsQuery,
  getRentPaymentsReceiverBoatsQuery,
  getRentPaymentsReceiversQuery,
  getReportedAdsQuery,
  getReportedReviewsQuery,
  getReviewReportsQuery,
  removePaymentMethodType,
  rentPaymentsRefundIntentQuery,
  rentPaymentsReleaseIntentQuery,
  searchBoat,
  searchBoatService,
  updatePaymentMethodType,
} from './../boat/BoatSchema'
import { all, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { client } from '../..'
import {
  GET_SINGLE_SELLER_BOATS,
  GET_SINGLE_SELLER_BOATS_SUCCESS,
  GET_SINGLE_SELLER_BOATS_FAILURE,
  CHANGE_APPROVAL_STATUS,
  CHANGE_APPROVAL_STATUS_SUCCESS,
  CHANGE_APPROVAL_STATUS_FAILURE,
  GET_PENDING_RENTAL_BOATS,
  GET_PENDING_RENTAL_BOATS_SUCCESS,
  GET_PENDING_RENTAL_BOATS_FAILURE,
  GET_ALL_PENDING_MARINA_STORAGE,
  GET_ALL_PENDING_MARINA_STORAGE_SUCCESS,
  GET_ALL_PENDING_MARINA_STORAGE_FAILURE,
  CHANGE_MARINA_STATUS,
  CHANGE_MARINA_STATUS_FAILURE,
  CHANGE_MARINA_STATUS_SUCCESS,
  GET_BOATS_SUCCESS,
  GET_BOATS_FAILURE,
  GET_BOATS,
  ActionType,
  DELETE_BOATS_SUCCESS,
  DELETE_BOATS_FAILURE,
  DELETE_BOATS,
  CHANGE_RENTAL_BOAT_STATUS,
  CHANGE_RENTAL_BOAT_STATUS_SUCCESS,
  CHANGE_RENTAL_BOAT_STATUS_FAILURE,
  GET_ALL_MARINA,
  GET_ALL_MARINA_FAILURE,
  GET_ALL_MARINA_SUCCESS,
  GET_ALL_RENT,
  GET_ALL_RENT_FAILURE,
  GET_ALL_RENT_SUCCESS,
  GET_ALL_BOAT_SERVICE,
  GET_ALL_BOAT_SERVICE_FAILURE,
  GET_ALL_BOAT_SERVICE_SUCCESS,
  GET_MARINA_MORE_SERVICE,
  GET_MARINA_MORE_SERVICE_FAILURE,
  GET_MARINA_MORE_SERVICE_SUCCESS,
  GET_LIVE_EXPERIENCE_MARINA,
  GET_LIVE_EXPERIENCE_MARINA_FAILURE,
  GET_LIVE_EXPERIENCE_MARINA_SUCCESS,
  GET_MOST_POPULAR_MARINA,
  GET_MOST_POPULAR_MARINA_FAILURE,
  GET_MOST_POPULAR_MARINA_SUCCESS,
  GET_NEAREST_MARINA,
  GET_NEAREST_MARINA_SUCCESS,
  GET_NEAREST_MARINA_FAILURE,
  GET_TOP_RATED_BOAT_SERVICE,
  GET_TOP_RATED_BOAT_SERVICE_FAILURE,
  GET_TOP_RATED_BOAT_SERVICE_SUCCESS,
  GET_NEAREST_BOAT_SERVICE,
  GET_NEAREST_BOAT_SERVICE_FAILURE,
  GET_NEAREST_BOAT_SERVICE_SUCCESS,
  GET_LIVE_EXPERIENCE_RENT,
  GET_LIVE_EXPERIENCE_RENT_FAILURE,
  GET_LIVE_EXPERIENCE_RENT_SUCCESS,
  GET_MOST_POPULAR_RENT,
  GET_MOST_POPULAR_RENT_FAILURE,
  GET_MOST_POPULAR_RENT_SUCCESS,
  GET_RECOMMENDED_RENT,
  GET_RECOMMENDED_RENT_FAILURE,
  GET_RECOMMENDED_RENT_SUCCESS,
  CHANGE_YATCH_SERVICE_STATUS,
  CHANGE_YATCH_SERVICE_STATUS_FAILURE,
  CHANGE_YATCH_SERVICE_STATUS_SUCCESS,
  GET_PENDING_BOAT_SERVICE,
  GET_PENDING_BOAT_SERVICE_SUCCESS,
  GET_PENDING_BOAT_SERVICE_FAILURE,
  DELETE_YATCH_SERVICE_BOATS,
  DELETE_YATCH_SERVICE_BOATS_FAILURE,
  DELETE_YATCH_SERVICE_BOATS_SUCCESS,
  DELETE_MARINA_BOATS,
  DELETE_MARINA_BOATS_FAILURE,
  DELETE_MARINA_BOATS_SUCCESS,
  GET_ACTIVITY_LOGS,
  GET_ACTIVITY_LOGS_SUCCESS,
  GET_ACTIVITY_LOGS_FAILURE,
  CREATE_PAYMENT_METHODS_SUCCESS,
  CREATE_PAYMENT_METHODS_FAILURE,
  CREATE_PAYMENT_METHODS,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
  DELETE_PAYMENT_METHODS,
  DELETE_PAYMENT_METHODS_FAILURE,
  DELETE_PAYMENT_METHODS_SUCCESS,
  UPDATE_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS_SUCCESS,
  UPDATE_PAYMENT_METHODS_FAILURE,
  GET_RENT_PAYMENTS_RECEIVERS,
  GET_RENT_PAYMENTS_RECEIVERS_SUCCESS,
  GET_RENT_PAYMENTS_RECEIVERS_FAILURE,
  GET_RENT_PAYMENTS_RECEIVER_BOATS,
  GET_RENT_PAYMENTS_RECEIVER_BOATS_SUCCESS,
  GET_RENT_PAYMENTS_RECEIVER_BOATS_FAILURE,
  GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS,
  GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_SUCCESS,
  GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FAILURE,
  GET_RENT_PAYMENTS_BOAT_SLOTS,
  GET_RENT_PAYMENTS_BOAT_SLOTS_SUCCESS,
  GET_RENT_PAYMENTS_BOAT_SLOTS_FAILURE,
  GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_SUCCESS,
  GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FAILURE,
  GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS,
  RENT_PAYMENTS_RELEASE_INTENT,
  RENT_PAYMENTS_RELEASE_INTENT_SUCCESS,
  RENT_PAYMENTS_RELEASE_INTENT_FAILURE,
  RENT_PAYMENTS_REFUND_INTENT,
  RENT_PAYMENTS_REFUND_INTENT_SUCCESS,
  RENT_PAYMENTS_REFUND_INTENT_FAILURE,
  SEARCH_BOAT,
  SEARCH_BOAT_SUCCESS,
  SEARCH_BOAT_FAILURE,
  SEARCH_BOAT_SEARVICE_SUCCESS,
  SEARCH_BOAT_SEARVICE_FAILURE,
  SEARCH_BOAT_SEARVICE,
  GET_REPORTED_ADS,
  GET_REPORTED_ADS_SUCCESS,
  GET_REPORTED_ADS_FAILURE,
  GET_AD_REPORTS,
  GET_AD_REPORTS_SUCCESS,
  GET_AD_REPORTS_FAILURE,
  GET_REPORTED_REVIEWS,
  GET_REPORTED_REVIEWS_SUCCESS,
  GET_REPORTED_REVIEWS_FAILURE,
  GET_REVIEW_REPORTS,
  GET_REVIEW_REPORTS_SUCCESS,
  GET_REVIEW_REPORTS_FAILURE,
  DELETE_REVIEW,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAILURE,
  GET_ADMIN_ACTIVITY_LOGS_SUCCESS,
  GET_ADMIN_ACTIVITY_LOGS,
  GET_ADMIN_ACTIVITY_LOGS_FAILURE,
} from './Types'

import {
  getSingleSellerBoatAds,
  changeStatus,
  getPendingBoatRents,
  getAllPendingMarinaAndStorage,
  changeMarinaStatus,
  boatRentChangeStatus,
  getPendingBoatServices,
} from './PendingAdsSchema'
import {
  getAllBoat,
  getBoatsByTypeAdmin,
  deleteBoat,
  getMarinaList,
  getAllRentBoat,
  getAllYachtServiceList,
  getMoreServices,
  getLiveExperienceMarina,
  getMostPopularMarina,
  nearestMarinaByCountry,
  getTopRatedYachtService,
  nearestBoatServiceByCountry,
  getLiveExperienceBoatRents,
  getMostPopularBoatRents,
  recommendedBoatRents,
  changeYachtServiceStatus,
  boatRentChangeMarketStatus,
  marinaChangeMarketStatus,
  deleteYachtService,
  deleteMarinaService,
  getAllAuditLogs,
  createPaymentMethodType,
} from '../boat/BoatSchema'
import { get } from 'lodash'

//get all single seller pending boats

function getSingleSellerBoatsCall({ payload }: any) {
  return client
    .query({
      query: getSingleSellerBoatAds,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
    })
}

function* getSingleSellerBoatsData({ payload }: AnyAction) {
  try {
    const data = yield getSingleSellerBoatsCall({ payload })
    yield put({ type: GET_SINGLE_SELLER_BOATS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_SINGLE_SELLER_BOATS_FAILURE, error })
  }
}

function* getSingleSellerBoats() {
  yield takeEvery(GET_SINGLE_SELLER_BOATS, getSingleSellerBoatsData)
}

// pending boat service

function getPendingBoatServiceCall({ payload }: any) {
  return client
    .query({
      query: getPendingBoatServices,
      variables: { ...payload },
      fetchPolicy: 'no-cache',
    })
}

function* getPendingBoatServiceData({ payload }: AnyAction) {
  try {
    const data = yield getPendingBoatServiceCall({ payload })
    yield put({ type: GET_PENDING_BOAT_SERVICE_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_PENDING_BOAT_SERVICE_FAILURE, error })
  }
}

function* getPendingBoatService() {
  yield takeEvery(GET_PENDING_BOAT_SERVICE, getPendingBoatServiceData)
}
//change status

function changeStatusCall({ payload }: any) {
  console.log(payload, 'input')
  const { fieldArray, fieldName, columnName, ...otherInput } = payload
  otherInput.column = payload.columnName
  return client
    .mutate({
      mutation: changeStatus,
      variables: { ...otherInput },
    })
}

function* changeStatusData({ payload }: AnyAction) {
  try {
    const res = yield changeStatusCall({ payload })
    console.log(res, 'res')

    yield put({
      type: CHANGE_APPROVAL_STATUS_SUCCESS,
      payload: {
        id: res.data.boatChangeStatus.id,
        columnName: payload.columnName,
        value: payload.value,
        fieldName: payload.fieldName,
        fieldArray: payload.fieldArray,
      },
    })
  } catch (error) {
    yield put({ type: CHANGE_APPROVAL_STATUS_FAILURE, error })
  }
}

function* changeStatusSaga() {
  yield takeEvery(CHANGE_APPROVAL_STATUS, changeStatusData)
}

//pending rental boats

function getPendingRentalBoatsApi(data: any) {
  return client
    .query({
      query: getPendingBoatRents,
      variables: { page: data.page, limit: data.limit },
      fetchPolicy: 'no-cache',
    })
}

function* getPendingRentalBoatData(action: AnyAction) {
  try {
    const data = yield getPendingRentalBoatsApi(action.payload)
    yield put({ type: GET_PENDING_RENTAL_BOATS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_PENDING_RENTAL_BOATS_FAILURE, e })
  }
}

function* getPendingRentalBoatsSaga() {
  yield takeEvery(GET_PENDING_RENTAL_BOATS, getPendingRentalBoatData)
}

//get all pending marina storages

function getPendingMarinaStorageApi(data: any) {
  return client
    .query({
      query: getAllPendingMarinaAndStorage,
      variables: { page: data.page, limit: data.limit },
      fetchPolicy: 'no-cache',
    })
}

function* getPendingMarinaStorageData(action: AnyAction) {
  try {
    const data = yield getPendingMarinaStorageApi(action.payload)
    yield put({ type: GET_ALL_PENDING_MARINA_STORAGE_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_ALL_PENDING_MARINA_STORAGE_FAILURE, e })
  }
}

function* getPendingMarinaStorageSaga() {
  yield takeEvery(GET_ALL_PENDING_MARINA_STORAGE, getPendingMarinaStorageData)
}

//change marina status

function changeMarinaStatusApi(data: any) {
  const { fieldName, fieldArray, columnName, ...noField } = data
  noField.column = data.columnName
  return client
    .mutate({
      mutation: changeMarinaStatus,
      variables: { ...noField },
    })
}

function* changeMarinaStatusData(action: AnyAction) {
  try {
    const res = yield changeMarinaStatusApi(action.payload)
    yield put({
      type: CHANGE_MARINA_STATUS_SUCCESS,
      payload: {
        id: res.data.marinaChangeStatus.id,
        value: action.payload.value,
        columnName: action.payload.columnName,
        fieldName: action.payload.fieldName,
        fieldArray: action.payload.fieldArray,
      },
    })
  } catch (e) {
    yield put({ type: CHANGE_MARINA_STATUS_FAILURE, e })
  }
}

function* changeMarinaStatusSaga() {
  yield takeEvery(CHANGE_MARINA_STATUS, changeMarinaStatusData)
}

// boat saga

function getAllBoatCall(input: any) {
  return client
    .query({
      query: getAllBoat,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getAllBoatData({ payload }: AnyAction) {
  try {
    const data = yield getAllBoatCall(payload)
    yield put({ type: GET_BOATS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_BOATS_FAILURE, error })
  }
}

function* getAllBoatSaga() {
  yield takeEvery(GET_BOATS, getAllBoatData)
}

function deleteBoatsApi(data: any) {
  const { id, note, isRent = false } = data.payload
  return client
    .mutate({
      mutation: deleteBoat,
      variables: { id, note, isRent },
    })
}

function* deleteBoatData(action: ActionType) {
  try {
    const res = yield deleteBoatsApi(action)
    if (res && res.networkError && res.networkError.result && res.networkError.result.errors) {
      yield put({
        type: DELETE_BOATS_FAILURE,
        error: res.networkError.result.errors,
      })
    } else {
      yield put({
        type: DELETE_BOATS_SUCCESS,
        payload: { id: res.data, record: action },
      })
    }
  } catch (error) {
    yield put({ type: DELETE_BOATS_FAILURE, error: get(error, 'networkError.result.errors', []) })
  }
}

function* deleteBoatSaga() {
  yield takeLatest(DELETE_BOATS, deleteBoatData)
}

function deleteYatchBoatsApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteYachtService,
      variables: { id },
    })
}

function* deleteYatchBoatData(action: ActionType) {
  try {
    const res = yield deleteYatchBoatsApi(action)
    yield put({ type: DELETE_YATCH_SERVICE_BOATS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_YATCH_SERVICE_BOATS_FAILURE, error })
  }
}

function* deleteYatchBoatSaga() {
  yield takeLatest(DELETE_YATCH_SERVICE_BOATS, deleteYatchBoatData)
}

function deleteMarinaBoatsApi(data: any) {
  const id = data.payload.id
  const note = data.payload.note
  return client
    .mutate({
      mutation: deleteMarinaService,
      variables: { id, note },
    })
}

function* deleteMarinaBoatData(action: ActionType) {
  try {
    const res = yield deleteMarinaBoatsApi(action)
    yield put({ type: DELETE_MARINA_BOATS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_MARINA_BOATS_FAILURE, error })
  }
}

function* deleteMarinaBoatSaga() {
  yield takeLatest(DELETE_MARINA_BOATS, deleteMarinaBoatData)
}

function changeRestStatusApi(data: any) {
  const { payload } = data
  const { fieldName, fieldArray, ...noField } = payload

  return client
    .mutate({
      mutation: boatRentChangeStatus,
      variables: { input: noField },
    })
}

function* changeRestStatusData(action: any) {
  try {
    const res = yield changeRestStatusApi(action)
    yield put({
      type: CHANGE_RENTAL_BOAT_STATUS_SUCCESS,
      payload: {
        id: res.data.boatRentChangeMarketStatus.id,
        value: action.payload.value,
        columnName: action.payload.column,
        fieldName: action.payload.fieldName,
        fieldArray: action.payload.fieldArray,
      },
    })
  } catch (error) {
    yield put({ type: CHANGE_RENTAL_BOAT_STATUS_FAILURE, error })
  }
}

function* changeRestStatusSaga() {
  yield takeLatest(CHANGE_RENTAL_BOAT_STATUS, changeRestStatusData)
}

// boat
function getAllMarinaCall(input: any) {
  return client
    .query({
      query: getMarinaList,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getAllMarinaData({ payload }: AnyAction) {
  try {
    const data = yield getAllMarinaCall(payload)
    yield put({ type: GET_ALL_MARINA_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ALL_MARINA_FAILURE, error })
  }
}

function* getAllMarinaSaga() {
  yield takeEvery(GET_ALL_MARINA, getAllMarinaData)
}

// rent
function getAllRentCall(input: any) {
  return client
    .query({
      query: getAllRentBoat,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getAllRentData({ payload }: AnyAction) {
  try {
    const data = yield getAllRentCall(payload)
    yield put({ type: GET_ALL_RENT_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ALL_RENT_FAILURE, error })
  }
}

function* getAllRentSaga() {
  yield takeEvery(GET_ALL_RENT, getAllRentData)
}

// boat service
function getAllBoatServiceCall(input: any) {
  return client
    .query({
      query: getAllYachtServiceList,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getAllBoatServiceData({ payload }: AnyAction) {
  try {
    const data = yield getAllBoatServiceCall(payload)
    yield put({ type: GET_ALL_BOAT_SERVICE_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ALL_BOAT_SERVICE_FAILURE, error })
  }
}

function* getAllBoatServiceSaga() {
  yield takeEvery(GET_ALL_BOAT_SERVICE, getAllBoatServiceData)
}

// more boat service
function getMarinaMoreServiceCall(input: any) {
  return client
    .query({
      query: getMoreServices,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getMarinaMoreServiceData({ payload }: AnyAction) {
  try {
    const data = yield getMarinaMoreServiceCall(payload)
    yield put({ type: GET_MARINA_MORE_SERVICE_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_MARINA_MORE_SERVICE_FAILURE, error })
  }
}

function* getMarinaMoreServiceSaga() {
  yield takeEvery(GET_MARINA_MORE_SERVICE, getMarinaMoreServiceData)
}

// live experience
function getLiveExperienceServiceCall(input: any) {
  return client
    .query({
      query: getLiveExperienceMarina,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getLiveExperienceServiceData({ payload }: AnyAction) {
  try {
    const data = yield getLiveExperienceServiceCall(payload)
    yield put({ type: GET_LIVE_EXPERIENCE_MARINA_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_LIVE_EXPERIENCE_MARINA_FAILURE, error })
  }
}

function* getLiveExperienceServiceSaga() {
  yield takeEvery(GET_LIVE_EXPERIENCE_MARINA, getLiveExperienceServiceData)
}
// most popular
function getMostPopularMarinaServiceCall(input: any) {
  return client
    .query({
      query: getMostPopularMarina,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getMostPopularMarinaServiceData({ payload }: AnyAction) {
  try {
    const data = yield getMostPopularMarinaServiceCall(payload)
    yield put({ type: GET_MOST_POPULAR_MARINA_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_MOST_POPULAR_MARINA_FAILURE, error })
  }
}

function* getMostPopularMarinaServiceSaga() {
  yield takeEvery(GET_MOST_POPULAR_MARINA, getMostPopularMarinaServiceData)
}
// nearest marina
function getNearestMarinaServiceCall(input: any) {
  return client
    .query({
      query: nearestMarinaByCountry,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getNearestMarinaServiceData({ payload }: AnyAction) {
  try {
    const data = yield getNearestMarinaServiceCall(payload)
    yield put({ type: GET_NEAREST_MARINA_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_NEAREST_MARINA_FAILURE, error })
  }
}

function* getNearestMarinaServiceSaga() {
  yield takeEvery(GET_NEAREST_MARINA, getNearestMarinaServiceData)
}

// top rated yatch
function getTopRatedMarinaServiceCall(input: any) {
  return client
    .query({
      query: getTopRatedYachtService,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getTopRatedMarinaServiceData({ payload }: AnyAction) {
  try {
    const data = yield getTopRatedMarinaServiceCall(payload)
    yield put({ type: GET_TOP_RATED_BOAT_SERVICE_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_TOP_RATED_BOAT_SERVICE_FAILURE, error })
  }
}

function* getTopRatedMarinaServiceSaga() {
  yield takeEvery(GET_TOP_RATED_BOAT_SERVICE, getTopRatedMarinaServiceData)
}

// nearest yatch
function getNearestYachtServiceCall(input: any) {
  return client
    .query({
      query: nearestBoatServiceByCountry,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getNearestYachtServiceData({ payload }: AnyAction) {
  try {
    const data = yield getNearestYachtServiceCall(payload)
    yield put({ type: GET_NEAREST_BOAT_SERVICE_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_NEAREST_BOAT_SERVICE_FAILURE, error })
  }
}

function* getNearestYachtServiceSaga() {
  yield takeEvery(GET_NEAREST_BOAT_SERVICE, getNearestYachtServiceData)
}

// recommended
function getRecommendedTripRentCall(input: any) {
  return client
    .query({
      query: recommendedBoatRents,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getRecommendedTripRentData({ payload }: AnyAction) {
  try {
    const data = yield getRecommendedTripRentCall(payload)
    yield put({ type: GET_RECOMMENDED_RENT_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_RECOMMENDED_RENT_FAILURE, error })
  }
}

function* getRecommendedTripRentSaga() {
  yield takeEvery(GET_RECOMMENDED_RENT, getRecommendedTripRentData)
}

// most popular
function getMostPopularRentCall(input: any) {
  return client
    .query({
      query: getMostPopularBoatRents,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getMostPopularRentData({ payload }: AnyAction) {
  try {
    const data = yield getMostPopularRentCall(payload)
    yield put({ type: GET_MOST_POPULAR_RENT_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_MOST_POPULAR_RENT_FAILURE, error })
  }
}

function* getMostPopularRentSaga() {
  yield takeEvery(GET_MOST_POPULAR_RENT, getMostPopularRentData)
}

// live experience rent
function getLiveExperienceRentCall(input: any) {
  return client
    .query({
      query: getLiveExperienceBoatRents,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getLiveExperienceRentData({ payload }: AnyAction) {
  try {
    const data = yield getLiveExperienceRentCall(payload)
    yield put({ type: GET_LIVE_EXPERIENCE_RENT_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_LIVE_EXPERIENCE_RENT_FAILURE, error })
  }
}

function* getLiveExperienceRentSaga() {
  yield takeEvery(GET_LIVE_EXPERIENCE_RENT, getLiveExperienceRentData)
}

function changeYatchStatusApi(data: any) {
  const { payload } = data
  const { fieldName, fieldArray, columnName, ...noField } = payload
  noField.column = payload.columnName
  return client
    .mutate({
      mutation: changeYachtServiceStatus,
      variables: noField,
    })
}

function* changeYatchStatusData(action: any) {
  try {
    const res = yield changeYatchStatusApi(action)
    yield put({
      type: CHANGE_YATCH_SERVICE_STATUS_SUCCESS,
      payload: {
        id: res.data.boatServiceChangeStatus.id,
        value: action.payload.value,
        columnName: action.payload.columnName,
        fieldName: action.payload.fieldName,
        fieldArray: action.payload.fieldArray,
      },
    })
  } catch (error) {
    yield put({ type: CHANGE_YATCH_SERVICE_STATUS_FAILURE, error })
  }
}

function* changeYatchStatusSaga() {
  yield takeLatest(CHANGE_YATCH_SERVICE_STATUS, changeYatchStatusData)
}

function getActivityLogsApi({ payload }: any) {
  return client
    .query({
      query: getAllAuditLogs,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

function* getActivityLogsData({ payload }: AnyAction) {
  try {
    const data = yield getActivityLogsApi({ payload })
    yield put({ type: GET_ACTIVITY_LOGS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ACTIVITY_LOGS_FAILURE, error })
  }
}

function* getActivityLogsSaga() {
  yield takeEvery(GET_ACTIVITY_LOGS, getActivityLogsData)
}

function getAdminActivityLogsApi({ payload }: any) {
  return client
    .query({
      query: getAdminAuditLogs,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

function* getAdminActivityLogsData({ payload }: AnyAction) {
  try {
    const data = yield getAdminActivityLogsApi({ payload })
    yield put({ type: GET_ADMIN_ACTIVITY_LOGS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ADMIN_ACTIVITY_LOGS_FAILURE, error })
  }
}

function* getAdminActivityLogsSaga() {
  yield takeEvery(GET_ADMIN_ACTIVITY_LOGS, getAdminActivityLogsData)
}

// payment methods
export function createPaymentMethodsApi(input: any) {
  return client
    .mutate({
      mutation: createPaymentMethodType,
      variables: { input },
    })
}

export function* createPaymentMethodsData(action: any) {
  try {
    const data = yield createPaymentMethodsApi(action.payload)
    yield put({ type: CREATE_PAYMENT_METHODS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: CREATE_PAYMENT_METHODS_FAILURE, error })
  }
}

export function* createPaymentMethodsSage() {
  yield takeLatest(CREATE_PAYMENT_METHODS, createPaymentMethodsData)
}
// get boat type

export function getAllPaymentMethodsApi(payload: any) {
  return client
    .query({
      query: getAllPaymentMethodTypes,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

export function* getAllPaymentMethodsData(action: any) {
  try {
    const data = yield getAllPaymentMethodsApi(action.payload)
    yield put({ type: GET_PAYMENT_METHODS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_PAYMENT_METHODS_FAILURE, error })
  }
}

export function* getAllPaymentMethodsSaga() {
  yield takeLatest(GET_PAYMENT_METHODS, getAllPaymentMethodsData)
}

// delete boat type

function deletePaymentMethodsApi(data: string) {
  return client
    .mutate({
      mutation: removePaymentMethodType,
      variables: { id: data },
    })
}

function* deletePaymentMethodsData(action: any) {
  try {
    const res = yield deletePaymentMethodsApi(action.payload)
    if (res && res.networkError && res.networkError.result && res.networkError.result.errors) {
      yield put({
        type: DELETE_PAYMENT_METHODS_FAILURE,
        payload: res.networkError.result.errors,
      })
    } else {
      yield put({ type: DELETE_PAYMENT_METHODS_SUCCESS, payload: res })
    }
  } catch (error) {
    yield put({
      type: DELETE_PAYMENT_METHODS_FAILURE,
      payload: error.networkError.result.errors,
    })
  }
}

function* deletePaymentMethodsSaga() {
  yield takeLatest(DELETE_PAYMENT_METHODS, deletePaymentMethodsData)
}

// update boat type

export function updatePaymentMethodsApi(data: any) {
  return client
    .mutate({
      mutation: updatePaymentMethodType,
      variables: { input: data },
    })
}

export function* updatePaymentMethodsData(action: any) {
  try {
    const data = yield updatePaymentMethodsApi(action.payload)
    yield put({ type: UPDATE_PAYMENT_METHODS_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_PAYMENT_METHODS_FAILURE, error })
  }
}

export function* updatePaymentMethodsSaga() {
  yield takeLatest(UPDATE_PAYMENT_METHODS, updatePaymentMethodsData)
}

// ================================
// get rent payments receivers
// --------------------------------

function* getRentPaymentsReceiversSaga() {
  yield takeEvery(GET_RENT_PAYMENTS_RECEIVERS, getRentPaymentsReceiversData)
}

function* getRentPaymentsReceiversData() {
  try {
    const data = yield getRentPaymentsReceiversAPI()

    if (data.data?.getRentPaymentsReceivers) {
      yield put({
        type: GET_RENT_PAYMENTS_RECEIVERS_SUCCESS,
        payload: data.data.getRentPaymentsReceivers,
      })
    } else {
      yield put({ type: GET_RENT_PAYMENTS_RECEIVERS_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_RENT_PAYMENTS_RECEIVERS_FAILURE })
  }
}

function getRentPaymentsReceiversAPI() {
  return client.query({
    query: getRentPaymentsReceiversQuery,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// get rent payments receiver boats
// --------------------------------

function* getRentPaymentsReceiverBoatsSaga() {
  yield takeEvery(GET_RENT_PAYMENTS_RECEIVER_BOATS, getRentPaymentsReceiverBoatsData)
}

function* getRentPaymentsReceiverBoatsData(action: any) {
  try {
    const data = yield getRentPaymentsReceiverBoatsAPI(action.payload)

    if (data.data?.getRentPaymentsReceiverBoats) {
      yield put({
        type: GET_RENT_PAYMENTS_RECEIVER_BOATS_SUCCESS,
        payload: data.data.getRentPaymentsReceiverBoats,
      })
    } else {
      yield put({ type: GET_RENT_PAYMENTS_RECEIVER_BOATS_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_RENT_PAYMENTS_RECEIVER_BOATS_FAILURE })
  }
}

function getRentPaymentsReceiverBoatsAPI(input: any) {
  return client.query({
    query: getRentPaymentsReceiverBoatsQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// get rent payments boat slots
// --------------------------------

function* getRentPaymentsBoatSlotsSaga() {
  yield takeEvery(GET_RENT_PAYMENTS_BOAT_SLOTS, getRentPaymentsBoatSlotsData)
}

function* getRentPaymentsBoatSlotsData(action: any) {
  try {
    const data = yield getRentPaymentsBoatSlotsAPI(action.payload)

    if (data.data?.getRentPaymentsBoatSlots) {
      yield put({
        type: GET_RENT_PAYMENTS_BOAT_SLOTS_SUCCESS,
        payload: data.data.getRentPaymentsBoatSlots,
      })
    } else {
      yield put({ type: GET_RENT_PAYMENTS_BOAT_SLOTS_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_RENT_PAYMENTS_BOAT_SLOTS_FAILURE })
  }
}

function getRentPaymentsBoatSlotsAPI(input: any) {
  return client.query({
    query: getRentPaymentsBoatSlotsQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// get rent payments boat slot bookings
// --------------------------------

function* getRentPaymentsBoatSlotBookingsSaga() {
  yield takeEvery(GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS, getRentPaymentsBoatSlotBookingsData)
}

function* getRentPaymentsBoatSlotBookingsData(action: any) {
  try {
    const data = yield getRentPaymentsBoatSlotBookingsAPI(action.payload)

    if (data.data?.getRentPaymentsBoatSlotBookings) {
      yield put({
        type: GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_SUCCESS,
        payload: data.data.getRentPaymentsBoatSlotBookings,
      })
    } else {
      yield put({ type: GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FAILURE })
  }
}

function getRentPaymentsBoatSlotBookingsAPI(input: any) {
  return client.query({
    query: getRentPaymentsBoatSlotBookingsQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// get rent payments private boat slots
// --------------------------------

function* getRentPaymentsPrivateBoatSlotsSaga() {
  yield takeEvery(GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS, getRentPaymentsPrivateBoatSlotsData)
}

function* getRentPaymentsPrivateBoatSlotsData(action: any) {
  try {
    const data = yield getRentPaymentsPrivateBoatSlotsAPI(action.payload)

    if (data.data?.getRentPaymentsPrivateBoatSlots) {
      yield put({
        type: GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_SUCCESS,
        payload: data.data.getRentPaymentsPrivateBoatSlots,
      })
    } else {
      yield put({ type: GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FAILURE })
    }
  } catch (error) {
    yield put({ type: GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FAILURE })
  }
}

function getRentPaymentsPrivateBoatSlotsAPI(input: any) {
  return client.query({
    query: getRentPaymentsPrivateBoatSlotsQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// rent payments release intent
// --------------------------------

function* rentPaymentsReleaseIntentSaga() {
  yield takeEvery(RENT_PAYMENTS_RELEASE_INTENT, rentPaymentsReleaseIntentData)
}

function* rentPaymentsReleaseIntentData(action: any) {
  try {
    const res = yield rentPaymentsReleaseIntentAPI(action.payload)

    if (res.data?.rentPaymentsReleaseIntent) {
      yield put({
        type: RENT_PAYMENTS_RELEASE_INTENT_SUCCESS,
        payload: res.data.rentPaymentsReleaseIntent,
      })
    } else {
      yield put({
        type: RENT_PAYMENTS_RELEASE_INTENT_FAILURE,
        payload:
          get(res, 'errors[0].message', '') ||
          get(res, 'networkError.result.errors[0].message', '') ||
          'Cannot release payment!',
      })
    }
  } catch (err) {
    yield put({
      type: RENT_PAYMENTS_RELEASE_INTENT_FAILURE,
      payload:
        get(err, 'errors[0].message', '') || get(err, 'networkError.result.errors[0].message', '') || 'Cannot release payment!',
    })
  }
}

function rentPaymentsReleaseIntentAPI(input: any) {
  return client.query({
    query: rentPaymentsReleaseIntentQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// rent payments refund intent
// --------------------------------

function* rentPaymentsRefundIntentSaga() {
  yield takeEvery(RENT_PAYMENTS_REFUND_INTENT, rentPaymentsRefundIntentData)
}

function* rentPaymentsRefundIntentData(action: any) {
  try {
    const res = yield rentPaymentsRefundIntentAPI(action.payload)

    if (res.data?.rentPaymentsRefundIntent) {
      yield put({
        type: RENT_PAYMENTS_REFUND_INTENT_SUCCESS,
        payload: res.data.rentPaymentsRefundIntent,
      })
    } else {
      yield put({
        type: RENT_PAYMENTS_REFUND_INTENT_FAILURE,
        payload:
          get(res, 'errors[0].message', '') ||
          get(res, 'networkError.result.errors[0].message', '') ||
          'Cannot refund payment!',
      })
    }
  } catch (err) {
    yield put({
      type: RENT_PAYMENTS_REFUND_INTENT_FAILURE,
      payload:
        get(err, 'errors[0].message', '') || get(err, 'networkError.result.errors[0].message', '') || 'Cannot refund payment!',
    })
  }
}

function rentPaymentsRefundIntentAPI(input: any) {
  return client.query({
    query: rentPaymentsRefundIntentQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

//search boat saga

function* searchBoatSaga() {
  yield takeLatest(SEARCH_BOAT, searchBoatDetails)
}

function* searchBoatDetails(action: any) {
  try {
    const res = yield searchBoatApi(action.payload)
    if (res.data?.searchBoat) {
      yield put({
        type: SEARCH_BOAT_SUCCESS,
        payload: res.data.searchBoat,
      })
    } else {
      yield put({
        type: SEARCH_BOAT_FAILURE,
        payload:
          get(res, 'errors[0].message', '') ||
          get(res, 'networkError.result.errors[0].message', '') ||
          'Cannot refund payment!',
      })
    }
  } catch (e) {
    yield put({
      type: SEARCH_BOAT_FAILURE,
    })
  }
}

function searchBoatApi(input: any) {
  return client.query({
    query: searchBoat,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

//search boat service saga

function* searchBoatServiceSaga() {
  yield takeLatest(SEARCH_BOAT_SEARVICE, searchBoatServiceDetails)
}

function* searchBoatServiceDetails(action: any) {
  try {
    const res = yield searchBoatServiceApi(action.payload)
    if (res.data?.searchBoatService) {
      yield put({
        type: SEARCH_BOAT_SEARVICE_SUCCESS,
        payload: res.data.searchBoatService,
      })
    } else {
      yield put({
        type: SEARCH_BOAT_SEARVICE_FAILURE,
        payload:
          get(res, 'errors[0].message', '') ||
          get(res, 'networkError.result.errors[0].message', '') ||
          'Cannot refund payment!',
      })
    }
  } catch (e) {
    yield put({
      type: SEARCH_BOAT_SEARVICE_FAILURE,
    })
  }
}

function searchBoatServiceApi(input: any) {
  return client.query({
    query: searchBoatService,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// reported ads
// --------------------------------

function* getReportedAdsSaga() {
  yield takeEvery(GET_REPORTED_ADS, getReportedAdsData)
}

function* getReportedAdsData(action: any) {
  try {
    const res = yield getReportedAdsAPI(action.payload)

    if (res.data?.getReportedAds) {
      yield put({
        type: GET_REPORTED_ADS_SUCCESS,
        payload: res.data.getReportedAds,
      })
    } else {
      yield put({
        type: GET_REPORTED_ADS_FAILURE,
        payload: get(res, 'errors[0].message', '') || 'Cannot get reported Ads!',
      })
    }
  } catch (err) {
    yield put({
      type: GET_REPORTED_ADS_FAILURE,
      payload: get(err, 'networkError.result.errors[0].message', '') || 'Cannot get reported Ads!',
    })
  }
}

function getReportedAdsAPI(input: any) {
  return client.query({
    query: getReportedAdsQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// ad reports
// --------------------------------

function* getAdReportsSaga() {
  yield takeEvery(GET_AD_REPORTS, getAdReportsData)
}

function* getAdReportsData(action: any) {
  try {
    const res = yield getAdReportsAPI(action.payload)

    if (res.data?.getAdReports) {
      yield put({
        type: GET_AD_REPORTS_SUCCESS,
        payload: res.data.getAdReports,
      })
    } else {
      yield put({
        type: GET_AD_REPORTS_FAILURE,
        payload: get(res, 'errors[0].message', '') || 'Cannot get reported Ads!',
      })
    }
  } catch (err) {
    yield put({
      type: GET_AD_REPORTS_FAILURE,
      payload: get(err, 'networkError.result.errors[0].message', '') || 'Cannot get reported Ads!',
    })
  }
}

function getAdReportsAPI(input: any) {
  return client.query({
    query: getAdReportsQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// reported reviews
// --------------------------------

function* getReportedReviewsSaga() {
  yield takeEvery(GET_REPORTED_REVIEWS, getReportedReviewsData)
}

function* getReportedReviewsData(action: any) {
  try {
    const res = yield getReportedReviewsAPI(action.payload)

    if (res.data?.getReportedReviews) {
      yield put({
        type: GET_REPORTED_REVIEWS_SUCCESS,
        payload: res.data.getReportedReviews,
      })
    } else {
      yield put({
        type: GET_REPORTED_REVIEWS_FAILURE,
        payload: get(res, 'errors[0].message', '') || 'Cannot get reported Reviews!',
      })
    }
  } catch (err) {
    yield put({
      type: GET_REPORTED_REVIEWS_FAILURE,
      payload: get(err, 'networkError.result.errors[0].message', '') || 'Cannot get reported Reviews!',
    })
  }
}

function getReportedReviewsAPI(input: any) {
  return client.query({
    query: getReportedReviewsQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// ad reports
// --------------------------------

function* getReviewReportsSaga() {
  yield takeEvery(GET_REVIEW_REPORTS, getReviewReportsData)
}

function* getReviewReportsData(action: any) {
  try {
    const res = yield getReviewReportsAPI(action.payload)

    if (res.data?.getReviewReports) {
      yield put({
        type: GET_REVIEW_REPORTS_SUCCESS,
        payload: res.data.getReviewReports,
      })
    } else {
      yield put({
        type: GET_REVIEW_REPORTS_FAILURE,
        payload: get(res, 'errors[0].message', '') || 'Cannot get reported Reviews!',
      })
    }
  } catch (err) {
    yield put({
      type: GET_REVIEW_REPORTS_FAILURE,
      payload: get(err, 'networkError.result.errors[0].message', '') || 'Cannot get reported Reviews!',
    })
  }
}

function getReviewReportsAPI(input: any) {
  return client.query({
    query: getReviewReportsQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ================================
// delete review
// --------------------------------

function* deleteReviewSaga() {
  yield takeEvery(DELETE_REVIEW, deleteReviewData)
}

function* deleteReviewData(action: any) {
  try {
    const res = yield deleteReviewAPI(action.payload)

    if (res.data?.deleteReview) {
      yield put({
        type: DELETE_REVIEW_SUCCESS,
        payload: res.data.deleteReview,
      })
    } else {
      yield put({
        type: DELETE_REVIEW_FAILURE,
        payload: get(res, 'errors[0].message', '') || 'Cannot delete Review!',
      })
    }
  } catch (err) {
    yield put({
      type: DELETE_REVIEW_FAILURE,
      payload: get(err, 'networkError.result.errors[0].message', '') || 'Cannot delete Review!',
    })
  }
}

function deleteReviewAPI(input: any) {
  return client.mutate({
    mutation: deleteReviewQuery,
    variables: input,
    fetchPolicy: 'no-cache',
  })
}

// ---------------------------------------------

export default function* pendingAdsSaga() {
  yield all([
    fork(getSingleSellerBoats),
    fork(changeStatusSaga),
    fork(getPendingRentalBoatsSaga),
    fork(getPendingMarinaStorageSaga),
    fork(changeMarinaStatusSaga),
    fork(getAllBoatSaga),
    fork(deleteBoatSaga),
    fork(changeRestStatusSaga),
    fork(getAllMarinaSaga),
    fork(getAllRentSaga),
    fork(getAllBoatServiceSaga),
    fork(getNearestMarinaServiceSaga),
    fork(getMarinaMoreServiceSaga),
    fork(getLiveExperienceServiceSaga),
    fork(getMostPopularMarinaServiceSaga),
    fork(getTopRatedMarinaServiceSaga),
    fork(getNearestYachtServiceSaga),
    fork(getLiveExperienceRentSaga),
    fork(getMostPopularRentSaga),
    fork(getRecommendedTripRentSaga),
    fork(changeYatchStatusSaga),
    fork(getPendingBoatService),
    fork(deleteYatchBoatSaga),
    fork(deleteMarinaBoatSaga),
    fork(getActivityLogsSaga),
    fork(getAdminActivityLogsSaga),
    fork(updatePaymentMethodsSaga),
    fork(deletePaymentMethodsSaga),
    fork(createPaymentMethodsSage),
    fork(getAllPaymentMethodsSaga),
    fork(getRentPaymentsReceiversSaga),
    fork(getRentPaymentsReceiverBoatsSaga),
    fork(getRentPaymentsBoatSlotsSaga),
    fork(getRentPaymentsBoatSlotBookingsSaga),
    fork(getRentPaymentsPrivateBoatSlotsSaga),
    fork(rentPaymentsReleaseIntentSaga),
    fork(rentPaymentsRefundIntentSaga),
    fork(searchBoatSaga),
    fork(searchBoatServiceSaga),
    fork(getReportedAdsSaga),
    fork(getAdReportsSaga),
    fork(getReportedReviewsSaga),
    fork(getReviewReportsSaga),
    fork(deleteReviewSaga),
  ])
}
