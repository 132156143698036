export const getUserGuideData = (userGuide: any) => {
  let userGuideObject: any = []
  let userGuideView

  userGuideView = {
    title: 'User Guide Information',
    userGuideTitle: userGuide.title,
    userGuideDescription: userGuide.description,
  }

  userGuideObject.push(userGuideView)
  return userGuideObject
}
