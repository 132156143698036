import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Typography } from 'antd'
import Tooltip from 'rc-tooltip'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'

import {
  getAllAgreements,
  approvePendingAgreement,
  clearAgreementFlag,
  changeAgreementStatus,
} from '../../store/manageAgreements/Action'
import { agreement } from '../../store/manageAgreements/Types'
import NotificationWithIcon from '../../component/notification/Notification'
import FilterButton from '../filters/FilterButton'
import ApproveButton from '../../component/ApproveButton'
import { getAllBoatPagination, statusValues, rejectAgreementMessage } from '../../enums/enums'
import { TitleSearch } from '../../component/SearchBar'
import { formateDate, getSearchedData, removePTag } from '../../helpers/helper'
import CommonModal from '../../component/modal/CommonModal'
import WSField from '../../component/Field'
import WSToolTip from '../../component/WsToolTip'
const { Text } = Typography

interface Props {
  getAllAgreements: typeof getAllAgreements
  agreements: agreement[]
  approvePendingAgreement: typeof approvePendingAgreement
  approveSuccess: boolean
  totalAgreement: number
  changeAgreementStatus: typeof changeAgreementStatus
  clearAgreementFlag: typeof clearAgreementFlag
  agreementStatusSuccess: boolean
}

interface State {
  columns: Array<Object>
  searchedUsers: string
  modalVisible: boolean
  currentRecord: any
  rejectMessage: string
  error: boolean
}

class ManageAgreementRequests extends Component<Props, State> {
  state = {
    rejectMessage: '',
    modalVisible: false,
    error: false,
    currentRecord: { id: '' },
    searchedUsers: '',
    columns: [
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (text: any) => <> {text.role} </>,
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any) => <> {formateDate(text)} </>,
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: (text: any) => <> {text} </>,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any) => (
          <>
            {' '}
            {record.firstName} {record.lastName}{' '}
          </>
        ),
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
        render: (text: string, record: any) => <> {text} </>,
      },
      {
        title: 'Mobile Number',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Approval Status',
        dataIndex: 'adStatus',
        key: 'adStatus',
        render: (text: string, record: tableRecords) => {
          const { approvePendingAgreement } = this.props

          return (
            <ApproveButton
              action={approvePendingAgreement}
              input={{
                id: record.id,
                agreementStep: statusValues.APPROVE,
              }}
              buttonTitle="Approve"
              className="btn-view"
            />
          )
        },
      },
      {
        title: 'Action',
        dataIndex: 'delete',
        key: 'delete',
        render: (text: string, record: tableRecords) => {
          return (
            <Button className="btn-delete" onClick={() => this.setModalVisible(true, record)}>
              {' '}
              Reject
            </Button>
          )
        },
      },
    ],
  }

  setModalVisible = (data: boolean, record: tableRecords) => {
    this.setState({
      modalVisible: data,
      currentRecord: record,
    })
  }

  // componentDidMount() {
  //   const { getAllAgreements } = this.props
  //   getAllAgreements({
  //     ...getAllBoatPagination,
  //     agreementStep: statusValues.PENDING,
  //   })
  // }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (nextProps, prevState) => {
    const { approveSuccess, clearAgreementFlag, agreementStatusSuccess } = nextProps
    if (approveSuccess) {
      clearAgreementFlag()
    }
    if (agreementStatusSuccess) {
      clearAgreementFlag()
      return { modalVisible: false, rejectMessage: '' }
    }

    return null
  }

  onSearch = (value: any) => {
    const { getAllAgreements } = this.props
    if (value) {
      this.setState({searchedUsers: value})
      getAllAgreements({ ...getAllBoatPagination, agreementStep: statusValues.PENDING, searchTerm: value })
    } else {
      this.setState({searchedUsers: ''})
      getAllAgreements({ ...getAllBoatPagination, agreementStep: statusValues.PENDING })
    }
  }

  onSubmit = () => {
    const { changeAgreementStatus } = this.props
    const { currentRecord, rejectMessage } = this.state
    if (rejectMessage.length) {
      changeAgreementStatus({
        id: currentRecord.id,
        column: 'isDeleted',
        value: true,
        message: removePTag(rejectMessage),
      })
    } else {
      NotificationWithIcon('error', 'Fill the field.')
    }
  }

  handleChange = (data: string) => {
    this.setState({
      rejectMessage: data,
    })
  }
  showHideError = (data: boolean) => {
    this.setState({
      error: data,
    })
  }

  render() {
    const { columns, searchedUsers, modalVisible, rejectMessage, error } = this.state
    const { agreements, getAllAgreements, totalAgreement } = this.props

    return (
      <>
        <Card title="Manage All Pending Agreement Request" extra={<TitleSearch onSearch={this.onSearch} />}>
          <FilterButton triggerAction={getAllAgreements} haveCountry />

          <ExpandTable
            columns={columns}
            tableData={agreements}
            totalUsersRow={totalAgreement}
            isExpand={false}
            pageAction={getAllAgreements}
            page="boat"
            extraInput={{ agreementStep: statusValues.PENDING, searchTerm: searchedUsers }}
          />
        </Card>
        {modalVisible && (
          <CommonModal
            customWidth={'60%'}
            okText="Submit"
            isFlag={modalVisible}
            title="Reason Of Rejection"
            handleCancel={() => this.setModalVisible(false, { id: '' })}
            footerProp={{ footer: null }}
          >
            <div>
              <WSField
                onChangeText={(evt: any) => {
                  error && this.showHideError(false)
                  this.handleChange(evt.editor.getData())
                }}
                type="textArea"
              />
              {error && <Text type="danger">Reject message Required</Text>}

              <WSToolTip title={rejectAgreementMessage}>
                <Button className="btn-view mt-3" onClick={() => (rejectMessage ? this.onSubmit() : this.showHideError(true))}>
                  Submit
                </Button>
              </WSToolTip>
            </div>
          </CommonModal>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  agreements: state.agreementReducer.agreements,
  approveSuccess: state.agreementReducer.approveSuccess,
  totalAgreement: state.agreementReducer.totalAgreement,
  agreementStatusSuccess: state.agreementReducer.agreementStatusSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllAgreements: (data: any) => dispatch(getAllAgreements(data)),
  approvePendingAgreement: (data: any) => dispatch(approvePendingAgreement(data)),
  clearAgreementFlag: () => dispatch(clearAgreementFlag()),
  changeAgreementStatus: (data: any) => dispatch(changeAgreementStatus(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAgreementRequests)
