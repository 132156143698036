import React from 'react'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

const VerifyDocumentCollapse = (props: any) => {
  const { record, getDocumentUrlAction, buttonTitle } = props

  return (
    <div>
      <p className="font-bold text-underline">Verified Document</p>
      <div className="d-flex">
        {!record.documentVerification.governmentIdVerified && !record.documentVerification.commercialLicenceVerified ? (
          <p>No Document Verified</p>
        ) : (
          <React.Fragment>
            <div className="mr-2">
              {record.documentVerification.governmentIdVerified && record.governmentId.length ? (
                record.governmentId.map((item: any) => (
                  <>
                    <div>
                      <b>
                        {(item && item.documentType && item.documentType.alias) || 'Government ID'}{' '}
                        {item && item.meta ? `(${item.meta.type})` : ''}
                      </b>
                    </div>
                    <Button
                      className="mr-2"
                      onClick={() => {
                        getDocumentUrlAction({
                          mediaId: item.id,
                        })
                      }}
                      type="primary"
                      icon={<DownloadOutlined />}
                    >
                      {buttonTitle || 'Show Document'}
                    </Button>
                  </>
                ))
              ) : (
                <></>
              )}
            </div>
            <div>
              {record.documentVerification.commercialLicenceVerified && record.commercialLicence.length ? (
                <>
                  <div>
                    <b>Commercial Licence</b>
                  </div>
                  {record.commercialLicence.map((item: any) => (
                    <Button
                      className="mr-2"
                      onClick={() => {
                        getDocumentUrlAction({
                          mediaId: item.id,
                        })
                      }}
                      type="primary"
                      icon={<DownloadOutlined />}
                    >
                      {buttonTitle || 'Show Document'}
                    </Button>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default VerifyDocumentCollapse
