import React, { Fragment } from 'react'
import { Player, BigPlayButton, ControlBar, Shortcut } from 'video-react'

import { Tabs, List, Button, Table, Row, Col, Card } from 'antd'
import { getBoatDetailData } from '../../../helpers/BoatHelper'
import NoImageAvailableIcon from '../../../assets/image/NoImageAvailableIcon.png'
import './Boats.scss'
import { paginationPage } from '../../../enums/enums'
import History from '../../../History'

const { TabPane } = Tabs
export interface Props {
  location: any
  history: typeof History
}
export interface State {
  index: string
  boat: any
  updatedboat: []
  detailedBoat: [
    {
      title: String
      list: [
        {
          title: string
          value: any
        }
      ]
    }
  ]
}
class BoatDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      index: '',
      boat: props && props.location && props.location.state && props.location.state.boat,
      updatedboat: [],
      detailedBoat: [
        {
          title: '',
          list: [
            {
              title: '',
              value: '',
            },
          ],
        },
      ],
    }
  }
  componentDidMount = async () => {
    const boatObject = await getBoatDetailData(this.state.boat)
    this.setState({ updatedboat: boatObject })
    this.setBoatObject()
  }

  setBoatObject = () => {
    let boat: any = []
    this.state.updatedboat.map((item: any, index: any) => {
      let list: any = [],
        boatObject
      for (let [key, value] of Object.entries(item)) {
        if (key !== 'title') {
          let boatField = {
            title: key,
            value: value,
          }
          list.push(boatField)
        }
      }
      boatObject = {
        title: item.title,
        list: list,
      }
      boat.push(boatObject)
    })
    this.setState({ detailedBoat: boat })
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-between w-100">
          <h2 title="Boat Details">Boat Details</h2>
          <Button onClick={() => this.props.history.goBack()}>Back</Button>
        </div>

        <Tabs defaultActiveKey="1" tabPosition="top">
          {this.state.detailedBoat.map((tab: any) => (
            <TabPane tab={tab.title} key={tab.id}>
              {tab &&
                tab.list.length &&
                tab.list.map((item: any) => {
                  return (
                    <List key={item.id}>
                      {item.title === 'video' ? (
                        <div>
                          {item.value ? (
                            <Card className="boat-card-container">
                              <Row className="ml-3">
                                <Col span={6}>
                                  <span className="list-title">{item.title}</span>
                                </Col>
                                <Col span={12}>
                                  <span className="list-data">
                                    <div className="video-class">
                                      <Player
                                        controls={false}
                                        className="video-class"
                                        muted
                                        autoPlay={true}
                                        playsInline
                                        loop={true}
                                        src={item.value === '' ? NoImageAvailableIcon : item.value}
                                      >
                                        {/* <Shortcut shortcuts={this.newShortcuts} clickable={false} /> */}
                                        <BigPlayButton />
                                        <ControlBar disableCompletely className="my-class" />
                                      </Player>
                                    </div>
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          ) : (
                            <Card className="boat-card-container border-bottom-none">No data found</Card>
                          )}
                        </div>
                      ) : item.title === 'image' || item.title === 'layout' ? (
                        <Card className="boat-card-container">
                          <Row className="ml-3">
                            <Col span={6}>
                              <span className="list-title">{item.title}</span>
                            </Col>
                            <Col span={12}>
                              <span className="list-data">
                                {item && item.value && item.value.length ? (
                                  item.value.map((image: any) => {
                                    return (
                                      <img
                                        src={image === '' ? NoImageAvailableIcon : image}
                                        alt={paginationPage.boat}
                                        className="detail-image"
                                      ></img>
                                    )
                                  })
                                ) : (
                                  <Card className="boat-card-container border-bottom-none">No data found</Card>
                                )}
                              </span>
                            </Col>
                          </Row>
                        </Card>
                      ) : item.title === 'attachId' || item.title === 'ownershipAttachment ' ? (
                        <Card className="boat-card-container">
                          <Row className="ml-3">
                            <Col span={6}>
                              <span className="list-title">{item.title}</span>
                            </Col>
                            <Col span={12}>
                              <span className="list-data">
                                {item.value ? (
                                  <img src={item.value === '' ? NoImageAvailableIcon : item.value} className="icon-100"></img>
                                ) : (
                                  <Card className="boat-card-container border-bottom-none">No data found</Card>
                                )}
                              </span>
                            </Col>
                          </Row>
                        </Card>
                      ) : (
                        <Card className="boat-card-container">
                          <Row className="ml-3">
                            <Col span={6}>
                              <span className="list-title">{item.title}</span>
                            </Col>
                            <Col span={12}>
                              <span className="list-data">{item.value}</span>
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </List>
                  )
                })}
            </TabPane>
          ))}
        </Tabs>
      </Fragment>
    )
  }
}

export default BoatDetails
