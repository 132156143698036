export interface userDocumentState {
  AdminUsersDocument: Array<any>
  getSuccess: boolean
  getError: boolean
  AdminUsersDocumentTotalRow: number
  verifySuccess: boolean
  verifyData: object
  verifyFail: boolean
  getDocumentUrlSuccess: boolean
  getDocumentUrlError: boolean
  getDocumentLink: string
  governmentIdVerifiedLoad: boolean
  urlLoader: boolean
  getDocLoad: boolean
}

export interface userDocumentData {
  AdminUsersDocument: Array<any>
}

export const GET_USER_DOCUMENTS = 'GET_USER_DOCUMENTS'
export const GET_USER_DOCUMENTS_SUCCESS = 'GET_USER_DOCUMENTS_SUCCESS'
export const GET_USER_DOCUMENTS_FAILURE = 'GET_USER_DOCUMENTS_FAILURE'
export const CLEAR_USER_DOCUMENTS_FLAG = 'CLEAR_USER_DOCUMENTS_FLAG'

export const CHANGE_DOCUMENT_STATUS = 'CHANGE_DOCUMENT_STATUS'
export const CHANGE_DOCUMENT_STATUS_SUCCESS = 'CHANGE_DOCUMENT_STATUS_SUCCESS'
export const CHANGE_DOCUMENT_STATUS_ERROR = 'CHANGE_DOCUMENT_STATUS_ERROR'

export const GET_DOCUMENT_URL = 'GET_DOCUMENT_URL'
export const GET_DOCUMENT_URL_SUCCESS = 'GET_DOCUMENT_URL_SUCCESS'
export const GET_DOCUMENT_URL_ERROR = 'GET_DOCUMENT_URL_ERROR'

export const CLEAR_DOCUMENT_STATUS = 'CLEAR_DOCUMENT_STATUS'

interface getUserDocuments {
  type: typeof GET_USER_DOCUMENTS
  payload: any
}

interface getUserDocumentsSuccess {
  type: typeof GET_USER_DOCUMENTS_SUCCESS
  payload: any
}

interface getUserDocumentsFailure {
  type: typeof GET_USER_DOCUMENTS_FAILURE
  payload: any
}

interface getDocumentUrl {
  type: typeof GET_DOCUMENT_URL
  payload: any
}

interface getDocumentUrlSuccess {
  type: typeof GET_DOCUMENT_URL_SUCCESS
  payload: any
}

interface getDocumentUrlFailure {
  type: typeof GET_DOCUMENT_URL_ERROR
  payload: any
}

interface clearUserDocumentFlag {
  type: typeof CLEAR_USER_DOCUMENTS_FLAG
  payload: any
}

interface changeDocumentFlag {
  type: typeof CHANGE_DOCUMENT_STATUS
  payload: any
}

interface clearDocumentFlag {
  type: typeof CLEAR_DOCUMENT_STATUS
}

interface userDocumentStatusSuccess {
  type: typeof CHANGE_DOCUMENT_STATUS_SUCCESS
  payload: any
}

interface userDocumentStatusError {
  type: typeof CHANGE_DOCUMENT_STATUS_ERROR
  payload: any
}

export type ActionType =
  | getUserDocuments
  | getUserDocumentsSuccess
  | getUserDocumentsFailure
  | clearUserDocumentFlag
  | changeDocumentFlag
  | userDocumentStatusSuccess
  | userDocumentStatusError
  | clearDocumentFlag
  | getDocumentUrl
  | getDocumentUrlSuccess
  | getDocumentUrlFailure
