import React from 'react'
import * as CKEditor from 'ckeditor4-react'
import { websiteAppUrl } from '../constants/GlobalConstants'

export const CKEditorWrapper = props => {
  return (
    <CKEditor
      {...props}
      data={props.value}
      onChange={props.onChange}
      config={{
        removeButtons: '',
        extraPlugins: 'uploadimage,colorbutton,colordialog,font,justify',
        filebrowserUploadUrl: websiteAppUrl.replace(/\/+$/, '') + '/upload-file',
      }}
    />
  )
}
