import moment from 'moment'
import randomize from 'randomatic'

import {
  userRoles,
  adminUsers,
  adminOtherUsers,
  auctionRoom,
  salesEngineHelpContentType,
  returnHelpContentType,
  getAllBoatPagination,
  userRoleAlias,
  paginationPage,
  salesEngineStaticContentType,
  salesEngineStaticContentStepFor,
  RentBookingStatusType,
  RentBookingStatus,
} from '../enums/enums'
import { redirectUrl } from '../constants/GlobalConstants'
import { Role } from '../store/role/Types'
import NotificationWithIcon from '../component/notification/Notification'
import { client } from '..'
import gql from 'graphql-tag'

export const lowerHyphenCase = (name: any) => {
  if (name) {
    return name.toLowerCase().split(' ').join('-')
  }
}

export const removePTag = (data: string) => {
  const first = data.replace('<p>', '')
  const sec = first.replace('</p>', '')
  return sec.replaceAll('&nbsp;', ' ')
}

export const viewBoatHandler = (boat: any, param?: any, page?: string, token?: string) => {
  if (boat) {
    const {
      id,
      _original,
      yearBuilt,
      boatType: { name },
      manufacturedBy,
      engineHp,
      seller,
    } = boat
    const userId = id || _original.id
    const { role } = seller
    const boatName = nameFormatter([yearBuilt, name, manufacturedBy, engineHp && (String(engineHp) + '-hp')], '-')
    if (userId && page !== 'pending') {
      window &&
        window.open(
          `${redirectUrl}/boat-inner/${
            (param && param.role && param.role.aliasName) || role.aliasName
          }/${userId}/${readableString(boatName)}`,
          '_blank'
        )
    } else {
      window &&
        window.open(
          `${redirectUrl}/boat-inner/${
            (param && param.role && param.role.aliasName) || role.aliasName
          }/${userId}/${readableString(boatName)}/?autoLoginToken=${token}`,
          '_blank'
        )
    }
  }
}

export const viewRentBoatHandler = (boat: any) => {
  if (boat) {
    const { id, _original, model } = boat
    const userId = id || _original.id
    if (userId) {
      window && window.open(`${redirectUrl}/rent-inner/${userId}/${lowerHyphenCase(model)}?from_admin=1`, '_blank')
    }
  }
}

export const viewMarinaDetails = (value: any, page?: string, token?: string) => {
  if (value) {
    const {
      id,
      user: { companyName },
      provider: { alias },
      serviceProvide,
    } = value

    const [first, ...rest] = serviceProvide
    const last = rest.pop()

    const seoName = nameFormatter([companyName && companyName, alias && alias, first && first.name, last && last.name], '-')
    if (id && page !== 'pending') {
      window && window.open(`${redirectUrl}/marina-storage-inner/${id}/${lowerHyphenCase(seoName)}`, '_blank')
    } else {
      window &&
        window.open(`${redirectUrl}/marina-storage-inner/${id}/${lowerHyphenCase(seoName)}/?autoLoginToken=${token}`, '_blank')
    }
  }
}

export const viewServiceBoatHandler = (value: any, page?: string, token?: string) => {
  if (value) {
    const {
      id,
      serviceProvide,
      user: { companyName },
    } = value

    const [first, ...rest] = serviceProvide
    const last = rest.pop()

    const seoName = nameFormatter([companyName && companyName, first && first.name, last && last.name], '-')
    if (id && page !== 'pending') {
      window && window.open(`${redirectUrl}/boat-service-inner/${id}/${lowerHyphenCase(seoName)}`, '_blank')
    } else {
      window &&
        window.open(`${redirectUrl}/boat-service-inner/${id}/${lowerHyphenCase(seoName)}/?autoLoginToken=${token}`, '_blank')
    }
  }
}

export const handlePageChange = (pageNumber: any, action: any, roles: any, role: any, page: any, extraInput?: any) => {
  if (page === paginationPage.adminUser) {
    const roleId: any = roles && roles.find((item: any) => item.aliasName === role)
    if (action) {
      action({
        page: pageNumber,
        limit: getAllBoatPagination.limit,
        id: roleId && roleId.id,
        ...(extraInput ? extraInput : {}),
        reqId: new Date().toString()
      })
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    }
  } else if (page === paginationPage.boat) {
    if (action) {
      if (extraInput) {
        action({
          limit: getAllBoatPagination.limit,
          page: pageNumber,
          ...(extraInput ? extraInput : {}),
        })
      } else {
        action({ limit: getAllBoatPagination.limit, page: pageNumber })
      }
    }
  } else {
    const roleId = roles && roles.find((item: any) => item.role === role)
    if (action) {
      action({
        page: pageNumber,
        limit: getAllBoatPagination.limit,
        id: roleId && roleId.id,
        ...(extraInput ? extraInput : {}),
      })
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    }
  }
}

export const formateDate = (date: any) => {
  const formateDate = moment(date).format('ll')

  return formateDate || ''
}

export const formateDateTime = (date: any) => {
  return moment(date) ? moment(date).format('LLL') : null
}

export const getRoleCountPath = (arr: any, path: any) => {
  const createCountArray = Object.entries(arr).map(item => {
    return {
      role: path(item[0]).role,
      count: item[1],
      path: path(item[0]).path,
    }
  })
  return createCountArray
}

export const getUserRoles: any = (data: any) => {
  switch (data) {
    case userRoleAlias.member:
      return userRoles.find(item => item.alias === userRoleAlias.member)
    case userRoleAlias.boatOwner:
      return userRoles.find(item => item.alias === userRoleAlias.boatOwner)
    case userRoleAlias.yatchShipper:
      return userRoles.find(item => item.alias === userRoleAlias.yatchShipper)
    case userRoleAlias.serviceMaintenance:
      return userRoles.find(item => item.alias === userRoleAlias.serviceMaintenance)
    case userRoleAlias.rentAndCharter:
      return userRoles.find(item => item.alias === userRoleAlias.rentAndCharter)
    case userRoleAlias.rent:
      return userRoles.find(item => item.alias === userRoleAlias.rent)
    case userRoleAlias.marinaAndStorage:
      return userRoles.find(item => item.alias === userRoleAlias.marinaAndStorage)
    case userRoleAlias.surveyor:
      return userRoles.find(item => item.alias === userRoleAlias.surveyor)
    case userRoleAlias.brokerAndDealer:
      return userRoles.find(item => item.alias === userRoleAlias.brokerAndDealer)
    case userRoleAlias.boatManufacturer:
      return userRoles.find(item => item.alias === userRoleAlias.boatManufacturer)
    case userRoleAlias.agent:
      return userRoles.find(item => item.alias === userRoleAlias.agent)

    default:
      break
  }
}

export function isEmptyObj(obj: any) {
  return !obj || Object.keys(obj).length === 0
}

export const getDataFromArray = (arr: any, keyName: any) => {
  return arr && arr.length && arr.map((element: any) => element[keyName])
}

export const adminUserRole = (data: any) => {
  switch (data) {
    case 'accountUser':
      return adminUsers.find(item => item.alias === 'accountUser')
    case 'auditor':
      return adminUsers.find(item => item.alias === 'auditor')
    case 'customerSupport':
      return adminUsers.find(item => item.alias === 'customerSupport')
    case 'salesMan':
      return adminUsers.find(item => item.alias === 'salesMan')
    case 'subAdministrator':
      return adminUsers.find(item => item.alias === 'subAdministrator')
    case 'administrator':
      return adminUsers.find(item => item.alias === 'administrator')

    default:
      break
  }
}

export const auctionRoomCountDashboard = (data: any) => {
  switch (data) {
    case 'auction-room':
      return auctionRoom.find(item => item.alias === 'auction-room')
  }
}

export const camelCaseToNormalCase = (word: string) => {
  const result = word && word.replace(/([A-Z])/g, ' $1')
  const finalResult = result && result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
}

export const nameFormatter = (stringArray: string[], separator = ' ') => {
  return stringArray.filter(Boolean).join(separator)
}

export const displayDefaultValue = (value: any) => {
  return (value?.toLocaleString()) || '-'
}

export const getAddMedia = (addMedia: any[] = []) => {
  const filterMedia = addMedia && addMedia.map(({ url, ...otherParams }) => otherParams)
  return filterMedia.filter((media: any) => media.key !== undefined)
}

export function dateStringFormate(date: any) {
  return moment(date).format('LL')
}

export const adminOtherRole = (data: any) => {
  switch (data) {
    case 'emailTemplates':
      return adminOtherUsers.find(item => item.alias === 'emailTemplates')
    case 'smsTemplates':
      return adminOtherUsers.find(item => item.alias === 'smsTemplates')
    case 'sliderImages':
      return adminOtherUsers.find(item => item.alias === 'sliderImages')
    case 'faq':
      return adminOtherUsers.find(item => item.alias === 'faq')
    case 'userGuides':
      return adminOtherUsers.find(item => item.alias === 'userGuides')
    case 'jobs':
      return adminOtherUsers.find(item => item.alias === 'jobs')
    case 'boatShows':
      return adminOtherUsers.find(item => item.alias === 'boatShows')
    case 'articles':
      return adminOtherUsers.find(item => item.alias === 'articles')
    case 'graphicMedia':
      return adminOtherUsers.find(item => item.alias === 'graphicMedia')
    case 'videos':
      return adminOtherUsers.find(item => item.alias === 'videos')
    case 'downloads':
      return adminOtherUsers.find(item => item.alias === 'downloads')
    case 'boatTypes':
      return adminOtherUsers.find(item => item.alias === 'boatTypes')
    case 'pages':
      return adminOtherUsers.find(item => item.alias === 'pages')
    case 'states':
      return adminOtherUsers.find(item => item.alias === 'states')
    case 'countries':
      return adminOtherUsers.find(item => item.alias === 'countries')

    default:
      break
  }
}

export const capitalizeChat = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const removeTypeName = (obj: any) => {
  const { __typename, ...noTypeName } = obj
  return noTypeName
}

export const splitUrlFromDocumentUrl = (url: string) => {
  const splitUrl = url ? url.split('.') : ''
  const getExtension = splitUrl ? splitUrl[splitUrl.length - 1].split('?') : ''

  return getExtension && getExtension.length ? getExtension[0] : ''
}

export const splitUrlFromUnderscore = (splitValue: string, url: string, pos?: any) => {
  const splitUrl = url ? url.split(splitValue) : ''

  return splitUrl[pos || splitUrl.length - 1]
}

export const readableString = (name: any) => {
  if (name) {
    return name
      .replace(/\s+$/, '')
      .toLowerCase()
      .split(' ')
      .map((w: any) => w[0] && w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join('-')
  }
}

export const getBoatFormateData = (boats: any[]) => {
  return boats.map((item: any) => {
    const firstName = item.seller && item.seller.firstName
    const lastName = item.seller && item.seller.lastName

    item.adSeller = `${firstName}  ${lastName}`
    item.added_on = moment(item.createdAt).format('DD-MM-YYYY hh:mm a')
    return item
  })
}

export const getSearchedData = (data: any, searchValue: string) => {
  const searchedData =
    data &&
    data.filter((user: any) => {
      const stringObject = JSON.stringify(user)

      if (stringObject && stringObject.toLowerCase().includes(searchValue && searchValue.toLowerCase())) {
        return user
      }
    })

  return searchedData
}

export const getFormatedData = (data: any) => {
  return data.map((item: any) => {
    const { id, createdAt, __typename, ...newItem } = item

    if (item.member === returnHelpContentType.member) {
      newItem.member = salesEngineHelpContentType.member
    }

    if (item.member === returnHelpContentType.memberPendingSaleEngine) {
      newItem.member = salesEngineHelpContentType.memberPendingSaleEngine
    }

    if (item.member === returnHelpContentType.sellersAtGroup) {
      newItem.member = salesEngineHelpContentType.sellersAtGroup
    }

    if (item.member === returnHelpContentType.agent) {
      newItem.member = salesEngineHelpContentType.agent
    }

    if (item.member === returnHelpContentType.seller) {
      newItem.member = salesEngineHelpContentType.seller
    }

    if (item.member === returnHelpContentType.shipment) {
      newItem.member = salesEngineHelpContentType.shipment
    }

    if (item.member === returnHelpContentType.branches) {
      newItem.member = salesEngineHelpContentType.branches
    }

    if (item.member === returnHelpContentType.brokers) {
      newItem.member = salesEngineHelpContentType.brokers
    }

    return newItem
  })
}

export const objectToSingleDataArray = (arr: any[], key: any, passed: any) => {
  let output

  if (key && passed) {
    output =
      arr &&
      arr.map(data => {
        return {
          value: data[passed],
          label: data[key],
        }
      })
  } else {
    output =
      arr &&
      arr.map(data => {
        return {
          value: data,
          label: data,
        }
      })
  }

  return output
}

export const generateAgreementId = () => {
  return randomize('A0', 6)
}

export const generateReferenceNo = () => {
  return randomize('A0', 10)
}

export const getUniqueArray = (data: any, key: any) => {
  let seen = new Set()

  return data.filter((item: any) => {
    let k = key(item)
    return seen.has(k) ? false : seen.add(k)
  })
}

export const mobileNumberFormat = (value = '') => {
  return value.toString().trim().replace(' ', '')
}

export const getLocalStorageItem = (key = '') => {
  return localStorage.getItem(key) || null
}

export const getBannerImageUrl = (image: any) => {
  return (
    image &&
    image.length &&
    image[0].thumbnail &&
    image[0].thumbnail.length &&
    image[0].thumbnail[0] &&
    image[0].thumbnail[0].url
  )
}

export const onlyAllowOneCall = (fn: any, thisVar: any) => {
  let hasBeenCalled = false
  return function () {
    if (hasBeenCalled) {
      throw Error('Attempted to call callback twice')
    }
    hasBeenCalled = true
    return fn.apply(thisVar, arguments)
  }
}

export const formatPrice = (price: number, toFixed = 0) => {
  return price.toLocaleString(undefined, { minimumFractionDigits: toFixed })
}

export const getContentFor = (content: string) => {
  switch (content) {
    case salesEngineStaticContentType.agent.type:
      return salesEngineStaticContentType.agent.value

    case salesEngineStaticContentType.myBoat.type:
      return salesEngineStaticContentType.myBoat.value

    case salesEngineStaticContentType.negotiation.type:
      return salesEngineStaticContentType.negotiation.value

    case salesEngineStaticContentType.inspectionPayment.type:
      return salesEngineStaticContentType.inspectionPayment.value

    case salesEngineStaticContentType.report.type:
      return salesEngineStaticContentType.report.value

    case salesEngineStaticContentType.certifyMyBoat.type:
      return salesEngineStaticContentType.certifyMyBoat.value

    case salesEngineStaticContentType.agreement.type:
      return salesEngineStaticContentType.agreement.value

    case salesEngineStaticContentType.payment.type:
      return salesEngineStaticContentType.payment.value

    case salesEngineStaticContentType.shipment.type:
      return salesEngineStaticContentType.shipment.value

    default:
      return ''
  }
}

export const getPreparedDataForSalesEngineStaticContent = (data: any) => {
  const { agent, members, seller } = salesEngineStaticContentStepFor
  const salesEngineData = data.map((item: any) => {
    let staticData = item
    if (staticData.stepsFor === agent.type) {
      staticData.stepsFor = agent.value
    }
    if (staticData.stepsFor === members.type) {
      staticData.stepsFor = members.value
    }
    if (staticData.stepsFor === seller.type) {
      staticData.stepsFor = seller.value
    }
    return staticData
  })

  return salesEngineData.map((item: any) => {
    const contentFor = getContentFor(item.contentFor)
    return { content: item.content, contentFor, stepsFor: item.stepsFor }
  })
}

export const trimmedValue = (value = '') => {
  return value.toString().trim()
}

export const getRole = (roles: Role[], aliasName: string) => {
  return roles.find((item: Role) => item.aliasName === aliasName)
}

interface errorType {
  message: string
}

export const dynamicError = (errors: errorType[]) => {
  return {
    message: errors?.[0]?.message || 'Some error occurred',
  }
}

type StrOrNumType = string | number

export const Sort = {
  StrOrNum: (a: StrOrNumType, b: StrOrNumType) => (a < b ? -1 : b < a ? 1 : 0),

  // eslint-disable-next-line no-sequences
  Boolean: (a: any, b: any) => ((a = +Boolean(a)), (b = +Boolean(b)), a < b ? -1 : b < a ? 1 : 0),

  DateTime: (a: moment.Moment, b: moment.Moment) => (a.isBefore(b) ? -1 : b.isBefore(a) ? 1 : 0),

  DateOnly: (a: moment.Moment, b: moment.Moment) => (
    // eslint-disable-next-line no-sequences
    (a = a.clone().startOf('day')), (b = b.clone().startOf('day')), a.isBefore(b) ? -1 : b.isBefore(a) ? 1 : 0
  ),

  TimeOnly: (a: moment.Moment, b: moment.Moment) => (
    // eslint-disable-next-line no-sequences
    (a = a.clone().date(1).month(1).year(1)),
    (b = b.clone().date(1).month(1).year(1)),
    a.isBefore(b) ? -1 : b.isBefore(a) ? 1 : 0
  ),
}

export interface EmptyState {
  data: any
  loading: boolean
  success: boolean
  failure: boolean
}

export const emptyState = ({ data = [], loading = false, success = false, failure = false }: Partial<EmptyState> = {}) => ({
  data,
  loading,
  success,
  failure,
})

export const classNames = (...args: any[]) => args.filter(Boolean).join(' ')

export const formatPriceinUSD = (price: string | number) => {
  const sign = String(price).charAt(0) === '-' ? '-' : ''
  const parts1 = String(price).replace(/^-/, '').split('.')
  const offset = parts1[0].length % 3
  const intPart = [+parts1[0].substring(0, offset), ...parts1[0].substring(offset, parts1[0].length).split(/(.{3})/)]
    .filter(Boolean)
    .join(',')

  return sign + (intPart || '0') + '.' + (+('.' + (+parts1[1] || 0))).toFixed(2).split('.')[1] + ' USD'
}

export const getRentStatusMod = (status: RentBookingStatusType, isQRScanned: boolean, isCancelled: boolean) => {
  if (isCancelled) return RentBookingStatus.Cancelled as RentBookingStatusType
  if (status === RentBookingStatus.Pending && isQRScanned) return RentBookingStatus.QRScanned as RentBookingStatusType
  return RentBookingStatus[status] as RentBookingStatusType
}

export const getFileExtFromUrl = (url: any) => {
  try {
    return url.split('?')[0].split('.').pop()
  } catch (err) {
    console.log(err)
    return null
  }
}

export const isFileExpDocType = (ext: any) => {
  try {
    return ['pdf', 'doc', 'docx'].includes(ext)
  } catch (err) {
    console.log(err)
    return null
  }
}

export const logOut = async () => {
  try {
    await client.mutate({ mutation: gql`mutation logOut { logOut }` });
  } catch (err) {
    NotificationWithIcon('error', 'Cannot logout!');
  }

  localStorage.removeItem('auth_token')
  localStorage.removeItem('userName')
  localStorage.removeItem('role')
  window.location.href = '/login'
}

export const logOutUnAuth = (err: any) => {
  if (err?.networkError?.statusCode === 401) {
    logOut()
  }
}
