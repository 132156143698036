import React, { Component } from 'react'
import { Card, Button } from 'antd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { feedbackState } from '../../store/form/Types'
import { roleStateData } from '../../store/role/Types'
import { getFeedback } from '../../store/form/Actions'
import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import CommonModal from '../../component/modal/CommonModal'
import moment from 'moment'
import { formateDate, getSearchedData } from '../../helpers/helper'
import { getAllBoatPagination, paginationPage } from '../../enums/enums'
import { TitleSearch } from '../../component/SearchBar'

interface Props {
  getFeedback: typeof getFeedback
  feedbackUser: []
  feedbackTotalRow: number
}

export interface State {
  isSingleView: boolean
  singleDetail: any
  searchedValue: string
}

export interface userState {
  roleState: roleStateData
  feedbackState: feedbackState
}

class Feedback extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isSingleView: false,
      singleDetail: {},
      searchedValue: '',
    }
  }

  // componentDidMount() {
  //   const { getFeedback } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getFeedback(input)
  // }
  handleCancel = () => {
    this.setState({
      isSingleView: false,
    })
  }

  columns = [
    {
      title: 'Site Member',
      dataIndex: 'isAdamSeaMember:',
      key: 'isAdamSeaMember:',
      render: (text: boolean, record: any, index: number) => <> {record.isAdamSeaMember ? 'Yes' : 'No'} </>,
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'user',
      render: (text: boolean, record: any, index: number) => (
        <>{record.user ? `${record.user.firstName} ${record.user.lastName}` : '-'}</>
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'feedBackAboutSite',
      key: 'feedBackAboutSite',
    },
    {
      title: 'Topic',
      dataIndex: 'feedBackTopic',
      key: 'feedBackTopic',
    },
    {
      title: 'Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: tableRecords, index: number) => <span>{formateDate(record && record.createdAt)} </span>,
    },
    {
      title: 'View',
      dataIndex: 'View',
      key: 'view',
      render: (text: string, record: tableRecords, index: number) => (
        <Button
          className="btn-view"
          onClick={() => {
            this.setState({ isSingleView: true, singleDetail: record })
          }}
        >
          View
        </Button>
      ),
    },
  ]

  onSearch = (value: any) => {
    const { getFeedback } = this.props;

    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    };

    if (value) {
      this.setState({searchedValue: value})
      getFeedback({ ...input, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getFeedback(input)
    }
  }

  render() {
    const { feedbackUser, feedbackTotalRow, getFeedback } = this.props
    const { singleDetail, isSingleView, searchedValue } = this.state
    return (
      <>
        <Card title="Feedback" extra={<TitleSearch onSearch={this.onSearch} />}>
          <ExpandTable
            columns={this.columns}
            tableData={feedbackUser}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={feedbackTotalRow}
            page={paginationPage.boat}
            pageAction={getFeedback}
            extraInput={{ searchTerm: searchedValue }}
          />
        </Card>
        {isSingleView && (
          <CommonModal
            okText="Close"
            isFlag={isSingleView}
            title="Feedback"
            handleCancel={this.handleCancel}
            handleOk={this.handleCancel}
          >
            <div className="feedBack--user">
              <div className="feedback-detail">
                <label className="feedback-label">Are You A AdamSea Member?</label>
                <label className="feedback-value">{singleDetail.isAdamSeaMember ? 'Yes' : 'No'}</label>
              </div>
              <div className="feedback-detail">
                <label className="feedback-label">User Name</label>
                <label className="feedback-value">
                  {singleDetail && singleDetail.userId && singleDetail.userId.firstName}{' '}
                  {singleDetail && singleDetail.userId && singleDetail.userId.lastName}
                </label>
              </div>
              <div className="feedback-detail">
                <label className="feedback-label">How Do You Feel About Our Site?</label>
                <label className="feedback-value">{singleDetail.feedBackAboutSite}</label>
              </div>
              <div className="feedback-detail">
                <label className="feedback-label">Topic</label>
                <label className="feedback-value">{singleDetail.feedBackTopic}</label>
              </div>
              <div className="feedback-detail">
                <label className="feedback-label">Sub Topic</label>
                <label className="feedback-value">{singleDetail.feedSubTopic}</label>
              </div>
              <div className="feedback-detail">
                <label className="feedback-label">Description</label>
                <label className="feedback-value">{singleDetail.description}</label>
              </div>
              <div className="feedback-detail">
                <label className="feedback-label">Added</label>
                <label className="feedback-value">{formateDate(singleDetail.createdAt)}</label>
              </div>
            </div>
          </CommonModal>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    feedbackUser: state.feedbackReducer && state.feedbackReducer.feedbackUser,
    feedbackTotalRow: state.feedbackReducer && state.feedbackReducer.feedbackTotalRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getFeedback: (data: any) => dispatch(getFeedback(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
