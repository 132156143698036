import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'
import 'react-quill/dist/quill.snow.css'
import { Card, Row, Col, Typography, Button, Select, Input, Tooltip, Tag, Spin } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { AdminPermissionGroups, AdminPermissions, pagination } from '../../../enums/enums'
import History from '../../../History'
import { getCountries } from '../../../store/country/manageCountry/Action'
import { adminUserRoleData } from '../../../store/adminUserRole/Type'
import { createAdminUserRole, clearAdminUserRoleFlag } from '../../../store/adminUserRole/Action'
import { getAllRoles, clearRoleFlag } from '../../../store/role/Action'
import NotificationWithIcon from '../../../component/notification/Notification'
import WSIcon from '../../../component/WSIcon'
import WSSelect from '../../../component/WSSelect'
import { PlusCircleOutlined, RedoOutlined } from '@ant-design/icons'
import './permissionStyle.scss'
import { isEmpty } from 'lodash'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const mediumRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/

const { Text } = Typography
const { Option } = Select

interface Props {
  roles: Array<any>
  getCountries: typeof getCountries
  countries: Array<any>
  createAdminUserRole: typeof createAdminUserRole
  history: typeof History
  match: any
  adminUserRoleSuccess: boolean
  isLoading: boolean,
  adminUserRoleFailure: boolean
  deleteAdminUserSuccess: boolean
  clearAdminUserRoleFlag: typeof clearAdminUserRoleFlag
  getAllRoles: typeof getAllRoles
}
interface State {
  addAdminUserFields: {
    id?: string
    firstName: string
    lastName: string
    mobileNumber: string
    email: string
    password?: string
    confirmPassword?: string
    address: any
    userType: string
    permissions: []
  }
  adminRole: any[]
}

class CreateAdminUser extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      addAdminUserFields: {
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        password: '',
        confirmPassword: '',
        address: '',
        userType: '',
        permissions: [],
      },
      adminRole: [],
    }
  }

  componentDidMount() {
    const { getCountries, countries, roles, getAllRoles, match, history } = this.props
    if (isEmpty(roles)) {
      getAllRoles()
    } else {
      this.setState({
        adminRole: roles.filter(element => element.type === 'administration'),
      })
    }

    getCountries({ page: 1, limit: 300 })

    if (history.location.state) {
      const data: any = history.location.state

      this.setState({
        addAdminUserFields: {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          mobileNumber: data.mobileNumber,
          email: data.email,
          address: data.address[0].country,
          userType: data.role.role,
          permissions: data.permissions,
        },
      })
    }
  }

  formSubmitHandler = (values: adminUserRoleData) => {
    const { adminRole } = this.state
    const role: any = adminRole.find((item: any) => item.role === values.userType)
    let input: any = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      address: [{ country: values.address }],
      userType: role.aliasName,
      mobileNumber: values.mobileNumber.toString(),
      permissions: values.permissions,
    }
    if (values.id) {
      input.id = values.id
      input.isUpdate = true
    } else input.password = values.password
    const { addAdminUserFields } = this.state
    const { createAdminUserRole } = this.props
    if (addAdminUserFields) {
      createAdminUserRole(input)
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { adminUserRoleSuccess, adminUserRoleFailure, isLoading , clearAdminUserRoleFlag, history } = props

    if (adminUserRoleSuccess) {
      const path = state.adminRole.find(i => i.role === state.addAdminUserFields.userType)?.aliasName
      NotificationWithIcon('success', history.location.state ? 'User updated successfully' : 'User created successfully')
      clearAdminUserRoleFlag()
      path && props.history.push('/admin-user/' + path)
    } else if (adminUserRoleFailure) {
      clearAdminUserRoleFlag()
    }
    return null
  }

  render() {
    const { addAdminUserFields, adminRole } = this.state
    const { countries, isLoading } = this.props

    return (
      <Fragment>
        <Formik
          key={addAdminUserFields?.id}
          initialValues={addAdminUserFields}
          onSubmit={(values: any) => {
            this.setState(prevState => ({ addAdminUserFields: { ...prevState.addAdminUserFields, userType: values.userType } }))
            this.formSubmitHandler(values)
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required('First Name field is required.'),
            lastName: Yup.string().required('Last Name field is required.'),
            mobileNumber: Yup.string()
              .matches(phoneRegExp, 'Phone number is not valid')
              .required('Contact Number is required.'),
            email: Yup.string().email('Invalid email').required('Email address field is required.'),
            password: Yup.string().when('id', {
              is: id => !id,
              then: Yup.string()
                .matches(mediumRegex, 'Password must contains at least one capital, special character and number')
                .label('Password')
                .required('Password field required.'),
            }),
            confirmPassword: Yup.string().when('id', {
              is: id => !id,
              then: Yup.string()
                .required('Confirm Password field required.')
                .label('Confirm password')
                .test('passwords-match', 'Passwords must match', function (value) {
                  return this.parent.password === value
                }),
            }),
            address: Yup.string().required('Select Country.'),
            userType: Yup.string().required('Select user role.'),
          })}
        >
          {props => {
            const { handleSubmit, setFieldValue, values, resetForm } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      Add Admin User
                    </span>
                  }
                  extra={
                    <>
                      <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                        {isLoading ? <Spin/> : 'Save'}
                      </button>
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="First Name">
                        First Name
                      </span>
                      <Field className="ant-input" name="firstName" placeholder="First Name" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="firstName" component="span" />
                      </Text>
                    </Col>

                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="Last Name ">
                        Last Name
                      </span>
                      <Field className="ant-input" name="lastName" placeholder="Last Name" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="lastName" component="span" />
                      </Text>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="Email Address ">
                        Email Address
                      </span>
                      <Field className="ant-input" name="email" placeholder="Email ID" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="email" component="span" />
                      </Text>
                    </Col>

                    <Col className="gutter-box" md={12}>
                      <span title="Contact Number ">Contact Number</span>
                      <Field type="number" className="ant-input" name="mobileNumber" placeholder="Contact Number" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="mobileNumber" component="span" />
                      </Text>
                    </Col>
                  </Row>

                  {!values.id && (
                    <Row gutter={16}>
                      <Col className="gutter-box" md={12}>
                        <span className="ant-form-item-required" title="Password ">
                          Password
                        </span>
                        <Input.Password
                          name="password"
                          placeholder="Password"
                          onChange={(e: any) => setFieldValue('password', e.target.value)}
                        />
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name="password" component="span" />
                        </Text>
                      </Col>

                      <Col className="gutter-box" md={12}>
                        <span className="ant-form-item-required" title="Re-Type Password ">
                          Re-Type Password
                        </span>
                        <Input.Password
                          name="confirmPassword"
                          placeholder="Confirm password"
                          onChange={(e: any) => setFieldValue('confirmPassword', e.target.value)}
                        />
                        <Text type="danger">
                          <ErrorMessage
                            className="invalid-feedback ant-typography-danger"
                            name="confirmPassword"
                            component="span"
                          />
                        </Text>
                      </Col>
                    </Row>
                  )}

                  <Row gutter={16}>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="Country">
                        Country
                      </span>
                      <WSSelect
                        defaultValue={values.address}
                        listOptions="name"
                        passed="name"
                        arrayList={countries && countries}
                        onChangeText={(value: any) => setFieldValue('address', value)}
                      />

                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="address" component="span" />
                      </Text>
                    </Col>

                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="Country">
                        Select user role
                      </span>
                      <WSSelect
                        listOptions="role"
                        passed="role"
                        defaultValue={values.userType}
                        arrayList={adminRole && adminRole}
                        onChangeText={(e: any) => {
                          setFieldValue('userType', e)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="userType" component="span" />
                      </Text>
                    </Col>

                    <Col className="gutter-box" md={24}>
                      <span className="ant-form-item-required" title="Permissions">
                        Permissions:
                      </span>

                      <Select
                        className="admin-perms-tags"
                        mode="multiple"
                        allowClear
                        // labelInValue
                        defaultValue={values.permissions}
                        style={{ width: '100%' }}
                        placeholder="Select Permissions"
                        options={Object.values(AdminPermissionGroups).map(grp => ({
                          label: grp.label,
                          options: grp.permissions.filter(p => !(values.permissions as any).includes(p.value)),
                        }))}
                        onChange={(opts: any) => {
                          setFieldValue('permissions', opts)
                        }}
                        tagRender={props => {
                          return (
                            <Tag>
                              {AdminPermissions[props.value as keyof typeof AdminPermissions].label}{' '}
                              <span onClick={props.onClose}>x</span>
                            </Tag>
                          )
                        }}
                      ></Select>

                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="permissions" component="span" />
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  adminUserRoleSuccess: state.adminUserRoleReducer && state.adminUserRoleReducer.adminUserRoleSuccess,
  adminUserRoleFailure: state.adminUserRoleReducer && state.adminUserRoleReducer.adminUserRoleFailure,
  isLoading: state.adminUserRoleReducer && state.adminUserRoleReducer.isLoading,
  countries: state.countryReducer.countries,
  roles: state.roleState.roles,
  deleteAdminUserSuccess: state.adminUserRoleReducer && state.adminUserRoleReducer.deleteAdminUserSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllRoles: () => dispatch(getAllRoles()),
  getCountries: (data: any) => dispatch(getCountries(data)),
  createAdminUserRole: (data: adminUserRoleData) => dispatch(createAdminUserRole(data)),
  clearAdminUserRoleFlag: () => dispatch(clearAdminUserRoleFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdminUser)
