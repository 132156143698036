import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { tableRecords } from './FormTable'
import { getSearchedData } from '../../helpers/helper'
import { getCustomerServiceAction, deleteCustomerServicesAction } from '../../store/form/Actions'
import { getAllBoatPagination } from '../../enums/enums'
import { Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import ExpandTable from '../../component/ExpandTable'

interface State {
  columns: Array<Object>,
  searchedValue: string
}

interface Props {
  getCustomerServiceAction: typeof getCustomerServiceAction
  customerService: any[]
  customerServiceTotalRow: number
  deleteCustomerServicesAction: typeof deleteCustomerServicesAction
}

class Customer extends Component<Props, State> {

  // componentDidMount() {
  //   const { getCustomerServiceAction } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getCustomerServiceAction(input)
  // }

  onSearch = (value: any) => {
    const { getCustomerServiceAction } = this.props;

    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    };

    if (value) {
      this.setState({searchedValue: value})
      getCustomerServiceAction({ ...input, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getCustomerServiceAction({ ...input })
    }
  }

  state = {
    columns: [
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        render: (text: any) => {
          return <div>{`${text}`}</div>
        },
      },
      {
        title: 'User Type',
        dataIndex: 'userRole',
        key: 'userRole',
        render: (text: any) => {
          return <div>{text.role}</div>
        },
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Mobile Number',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
        render: (t: any) => t || 'N/A'
      },
      {
        title: 'Help Text',
        dataIndex: 'description',
        key: 'description',
      },
    ],
    searchedValue: '',
  }

  render() {
    const { columns, searchedValue } = this.state
    const { getCustomerServiceAction, customerService, customerServiceTotalRow } = this.props
    return (
      <Card title="Customer Service" extra={<TitleSearch onSearch={this.onSearch} />}>
        <div className='no-white-space'>
          <ExpandTable
            columns={columns}
            tableData={customerService}
            expandHtml={false}
            isExpand={false}
            page="boat"
            totalUsersRow={customerServiceTotalRow}
            pageAction={getCustomerServiceAction}
            extraInput={{ page_type: '0', searchTerm: searchedValue }}
            rowKey={(record: tableRecords) => record?.id}
          />
        </div>
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    customerService: state.feedbackReducer.customerService,
    customerServiceTotalRow: state.feedbackReducer.customerServiceTotalRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getCustomerServiceAction: (data: any) => dispatch(getCustomerServiceAction(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer)
