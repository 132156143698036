// Describing the shape of the chat's slice of state
export interface feedbackState {
  feedbackUser: any[]
  contactUsers: any[]
  contactLoad: boolean
  contactSuccess: boolean
  contactFail: boolean
  feedbackTotalRow: number
  contactUsersTotalRow: number
  newsAndMultimedia: any[]
  newsMultimediaLoad: boolean
  newsMultimediaSuccess: boolean
  newsMultimediaFail: boolean
  newsAndMultimediaTotalRow: number
  partnerRelation: any[]
  partnerRelationTotalRow: number
  partnerRelationLoad: boolean
  partnerRelationSuccess: boolean
  partnerRelationFail: boolean
  career: any[]
  careerTotalRow: number
  careerLoad: boolean
  careerSuccess: boolean
  careerFail: boolean
  complaint: any[]
  complaintTotalRow: number
  complaintLoad: boolean
  complaintSuccess: boolean
  complaintFail: boolean
  suggestions: any[]
  suggestionsTotalRow: number
  suggestionsLoad: boolean
  suggestionsSuccess: boolean
  suggestionsFail: boolean
  customerService: any[]
  customerServiceTotalRow: number
  customerServiceLoad: boolean
  customerServiceSuccess: boolean
  customerServiceFail: boolean
  deleteContactLoad: boolean
  contactDeleteSuccess: boolean
  contactDeleteFail: boolean
  deleteNewsLoad: boolean
  newsDeleteSuccess: boolean
  newsDeleteFail: boolean
  deleteCareerLoad: boolean
  careerDeleteSuccess: boolean
  careerDeleteFail: boolean
  deleteSuggestLoad: boolean
  suggestionsDeleteSuccess: boolean
  suggestionsDeleteFail: boolean
  deleteCustomerServiceLoad: boolean
  customerServicesSuccess: boolean
  customerServicesFail: boolean
}

export interface userState {
  feedbackState: feedbackState
}

//get feedback
export const GET_MEMBER_FEEDBACK = 'GET_MEMBER_FEEDBACK'
export const GET_MEMBER_FEEDBACK_SUCCESS = 'GET_MEMBER_FEEDBACK_SUCCESS'
export const GET_MEMBER_FEEDBACK_FAILURE = 'GET_MEMBER_FEEDBACK_FAILURE'

//get contact us
export const GET_MEMBER_CONTACT = 'GET_MEMBER_CONTACT'
export const GET_MEMBER_CONTACT_SUCCESS = 'GET_MEMBER_CONTACT_SUCCESS'
export const GET_MEMBER_CONTACT_FAILURE = 'GET_MEMBER_CONTACT_FAILURE'

// get multimedia
export const GET_NEWS_MULTIMEDIA = 'GET_NEWS_MULTIMEDIA'
export const GET_NEWS_MULTIMEDIA_SUCCESS = 'GET_NEWS_MULTIMEDIA_SUCCESS'
export const GET_NEWS_MULTIMEDIA_FAILURE = 'GET_NEWS_MULTIMEDIA_FAILURE'

// get partner relation
export const GET_PARTNER_RELATION = 'GET_PARTNER_RELATION'
export const GET_PARTNER_RELATION_SUCCESS = 'GET_PARTNER_RELATION_SUCCESS'
export const GET_PARTNER_RELATION_FAILURE = 'GET_PARTNER_RELATION_FAILURE'

// get career
export const GET_CAREER = 'GET_CAREER'
export const GET_CAREER_SUCCESS = 'GET_CAREER_SUCCESS'
export const GET_CAREER_FAILURE = 'GET_CAREER_FAILURE'

// get complaint
export const GET_COMPLAINT = 'GET_COMPLAINT'
export const GET_COMPLAINT_SUCCESS = 'GET_COMPLAINT_SUCCESS'
export const GET_COMPLAINT_FAILURE = 'GET_COMPLAINT_FAILURE'

// get suggestions
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS'
export const GET_SUGGESTIONS_SUCCESS = 'GET_SUGGESTIONS_SUCCESS'
export const GET_SUGGESTIONS_FAILURE = 'GET_SUGGESTIONS_FAILURE'

// get customer services
export const GET_CUSTOMER_SERVICES = 'GET_CUSTOMER_SERVICES'
export const GET_CUSTOMER_SERVICES_SUCCESS = 'GET_CUSTOMER_SERVICES_SUCCESS'
export const GET_CUSTOMER_SERVICES_FAILURE = 'GET_CUSTOMER_SERVICES_FAILURE'

//delete contact us
export const DELETE_MEMBER_CONTACT = 'DELETE_MEMBER_CONTACT'
export const DELETE_MEMBER_CONTACT_SUCCESS = 'DELETE_MEMBER_CONTACT_SUCCESS'
export const DELETE_MEMBER_CONTACT_FAILURE = 'DELETE_MEMBER_CONTACT_FAILURE'

//delete news multimedia
export const DELETE_NEWS_MULTI = 'DELETE_NEWS_MULTI'
export const DELETE_NEWS_MULTI_SUCCESS = 'DELETE_NEWS_MULTI_SUCCESS'
export const DELETE_NEWS_MULTI_FAILURE = 'DELETE_NEWS_MULTI_FAILURE'

//delete PARTNER
export const DELETE_PARTNER_RELATIONS = 'DELETE_PARTNER_RELATIONS'
export const DELETE_PARTNER_RELATIONS_SUCCESS = 'DELETE_PARTNER_RELATIONS_SUCCESS'
export const DELETE_PARTNER_RELATIONS_FAILURE = 'DELETE_PARTNER_RELATIONS_FAILURE'

//delete CAREER
export const DELETE_CAREER = 'DELETE_CAREER'
export const DELETE_CAREER_SUCCESS = 'DELETE_CAREER_SUCCESS'
export const DELETE_CAREER_FAILURE = 'DELETE_CAREER_FAILURE'

//delete SUGGESTIONS
export const DELETE_SUGGESTIONS = 'DELETE_SUGGESTIONS'
export const DELETE_SUGGESTIONS_SUCCESS = 'DELETE_SUGGESTIONS_SUCCESS'
export const DELETE_SUGGESTIONS_FAILURE = 'DELETE_SUGGESTIONS_FAILURE'

//delete CUSTOMER
export const DELETE_CUSTOMER_SERVICES = 'DELETE_CUSTOMER_SERVICES'
export const DELETE_CUSTOMER_SERVICES_SUCCESS = 'DELETE_CUSTOMER_SERVICES_SUCCESS'
export const DELETE_CUSTOMER_SERVICES_FAILURE = 'DELETE_CUSTOMER_SERVICES_FAILURE'

export const CLEAR_FORMS_FLAG = 'CLEAR_FORMS_FLAG'

// feedback
interface getFeedback {
  type: typeof GET_MEMBER_FEEDBACK
  payload: any
}
interface getFeedbackSuccess {
  type: typeof GET_MEMBER_FEEDBACK_SUCCESS
  payload: any
}
interface getFeedbackFailure {
  type: typeof GET_MEMBER_FEEDBACK_FAILURE
  payload: string
}

// contact
interface getContact {
  type: typeof GET_MEMBER_CONTACT
  payload: any
}
interface getContactSuccess {
  type: typeof GET_MEMBER_CONTACT_SUCCESS
  payload: any
}
interface getContactFailure {
  type: typeof GET_MEMBER_CONTACT_FAILURE
  payload: string
}

// multimedia
interface getNewsMultimedia {
  type: typeof GET_NEWS_MULTIMEDIA
  payload: any
}
interface getNewsMultimediaSuccess {
  type: typeof GET_NEWS_MULTIMEDIA_SUCCESS
  payload: any
}
interface getNewsMultimediaFailure {
  type: typeof GET_NEWS_MULTIMEDIA_FAILURE
  payload: string
}

// partner relation
interface getPartnerRelation {
  type: typeof GET_PARTNER_RELATION
  payload: any
}
interface getPartnerRelationSuccess {
  type: typeof GET_PARTNER_RELATION_SUCCESS
  payload: any
}
interface getPartnerRelationFailure {
  type: typeof GET_PARTNER_RELATION_FAILURE
  payload: string
}

// CAREER
interface getCareer {
  type: typeof GET_CAREER
  payload: any
}
interface getCareerSuccess {
  type: typeof GET_CAREER_SUCCESS
  payload: any
}
interface getCareerFailure {
  type: typeof GET_CAREER_FAILURE
  payload: string
}

// complaint
interface getComplaint {
  type: typeof GET_COMPLAINT
  payload: any
}
interface getComplaintSuccess {
  type: typeof GET_COMPLAINT_SUCCESS
  payload: any
}
interface getComplaintFailure {
  type: typeof GET_COMPLAINT_FAILURE
  payload: string
}

// SUGGESTIONS
interface getSuggestions {
  type: typeof GET_SUGGESTIONS
  payload: any
}
interface getSuggestionsSuccess {
  type: typeof GET_SUGGESTIONS_SUCCESS
  payload: any
}
interface getSuggestionsFailure {
  type: typeof GET_SUGGESTIONS_FAILURE
  payload: string
}

// CUSTOMER SERVICE
interface getCustomerServices {
  type: typeof GET_CUSTOMER_SERVICES
  payload: any
}
interface getCustomerServicesSuccess {
  type: typeof GET_CUSTOMER_SERVICES_SUCCESS
  payload: any
}
interface getCustomerServicesFailure {
  type: typeof GET_CUSTOMER_SERVICES_FAILURE
  payload: string
}

interface deleteContact {
  type: typeof DELETE_MEMBER_CONTACT
  payload: any
}
interface deleteContactSuccess {
  type: typeof DELETE_MEMBER_CONTACT_SUCCESS
  payload: any
}
interface deleteContactFailure {
  type: typeof DELETE_MEMBER_CONTACT_FAILURE
  payload: string
}

interface deleteNewsMulti {
  type: typeof DELETE_NEWS_MULTI
  payload: any
}
interface deleteNewsMultiSuccess {
  type: typeof DELETE_NEWS_MULTI_SUCCESS
  payload: any
}
interface deleteNewsMultiFailure {
  type: typeof DELETE_NEWS_MULTI_FAILURE
  payload: string
}

interface deletePartnerRelation {
  type: typeof DELETE_PARTNER_RELATIONS
  payload: any
}
interface deletePartnerRelationSuccess {
  type: typeof DELETE_PARTNER_RELATIONS_SUCCESS
  payload: any
}
interface deletePartnerRelationFailure {
  type: typeof DELETE_PARTNER_RELATIONS_FAILURE
  payload: string
}

interface deleteCareer {
  type: typeof DELETE_CAREER
  payload: any
}
interface deleteCareerSuccess {
  type: typeof DELETE_CAREER_SUCCESS
  payload: any
}
interface deleteCareerFailure {
  type: typeof DELETE_CAREER_FAILURE
  payload: string
}

interface deleteSuggestions {
  type: typeof DELETE_SUGGESTIONS
  payload: any
}
interface deleteSuggestionsSuccess {
  type: typeof DELETE_SUGGESTIONS_SUCCESS
  payload: any
}
interface deleteSuggestionsFailure {
  type: typeof DELETE_SUGGESTIONS_FAILURE
  payload: string
}

interface deleteCustomer {
  type: typeof DELETE_CUSTOMER_SERVICES
  payload: any
}
interface deleteCustomerSuccess {
  type: typeof DELETE_CUSTOMER_SERVICES_SUCCESS
  payload: any
}
interface deleteCustomerFailure {
  type: typeof DELETE_CUSTOMER_SERVICES_FAILURE
  payload: string
}
interface clearFormsFlag {
  type: typeof CLEAR_FORMS_FLAG
}

export type ActionType =
  | getFeedback
  | getFeedbackSuccess
  | getFeedbackFailure
  | getContact
  | getContactSuccess
  | getContactFailure
  | deleteContact
  | deleteContactSuccess
  | deleteContactFailure
  | clearFormsFlag
  | getNewsMultimedia
  | getNewsMultimediaSuccess
  | getNewsMultimediaFailure
  | getPartnerRelation
  | getPartnerRelationSuccess
  | getPartnerRelationFailure
  | getCareer
  | getCareerSuccess
  | getCareerFailure
  | getComplaint
  | getComplaintSuccess
  | getComplaintFailure
  | getSuggestions
  | getSuggestionsSuccess
  | getSuggestionsFailure
  | getCustomerServices
  | getCustomerServicesSuccess
  | getCustomerServicesFailure
  | deleteNewsMulti
  | deleteNewsMultiSuccess
  | deleteNewsMultiFailure
  | deletePartnerRelation
  | deletePartnerRelationSuccess
  | deletePartnerRelationFailure
  | deleteCareer
  | deleteCareerSuccess
  | deleteCareerFailure
  | deleteSuggestions
  | deleteSuggestionsSuccess
  | deleteSuggestionsFailure
  | deleteCustomer
  | deleteCustomerSuccess
  | deleteCustomerFailure
