import gql from 'graphql-tag'

export const getAllRoles = gql`
  {
    getAllRoles {
      id
      role
      type
      aliasName
    }
  }
`

export const getUsersCountRoleWise = gql`
  query getUsersCountRoleWise($id: String!) {
    getUsersCountRoleWise(id: $id) {
      verifiedUsers
      unVerifiedUsers
      blockedUsers
      activeUsers
      deActiveAccount
    }
  }
`
