import gql from 'graphql-tag'

export const getAllCanadianTaxes = gql`
  {
    getAllCanadianTaxes {
      id
      canadaProvince
      name
      GSTorHST
      PST
      status
    }
  }
`

export const createCanadianTax = gql`
  mutation createCanadianTax($canadianTax: CanadianTaxInput!) {
    createCanadianTax(canadianTax: $canadianTax) {
      id
      canadaProvince
      name
      GSTorHST
      PST
      status
    }
  }
`

export const updateCanadianTax = gql`
  mutation updateCanadianTax($canadianTax: CanadianTaxInput!) {
    updateCanadianTax(canadianTax: $canadianTax) {
      id
      canadaProvince
      name
      GSTorHST
      PST
      status
    }
  }
`

export const deleteCanadianTax = gql`
  mutation deleteCanadianTax($canadianTax: CanadianTaxInput!) {
    deleteCanadianTax(canadianTax: $canadianTax) {
      id
      canadaProvince
      name
      GSTorHST
      PST
      status
    }
  }
`

export const changeStatus = gql`
  mutation changeCanadianTaxStatus($id: String!, $columnName: String!) {
    changeCanadianTaxStatus(id: $id, columnName: $columnName) {
      message
      statusCode
      id
    }
  }
`
