import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../..'
import {
  createJob,
  updateJob,
  deleteJob,
  getAllJobs,
  changeJobStatus,
  getJobsContent,
  createOrUpdateJobContent,
  getAppliedJobsByJob,
} from './Schema'
import {
  ActionType,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAILURE,
  CREATE_JOBS_SUCCESS,
  CREATE_JOBS_FAILURE,
  UPDATE_JOBS_SUCCESS,
  UPDATE_JOBS_FAILURE,
  DELETE_JOBS_SUCCESS,
  DELETE_JOBS_FAILURE,
  GET_ALL_JOBS,
  CREATE_JOBS,
  UPDATE_JOBS,
  DELETE_JOBS,
  CHANGE_JOB_STATUS,
  CHANGE_JOB_STATUS_SUCCESS,
  CHANGE_JOB_STATUS_FAILURE,
  GET_JOBS_CONTENT,
  GET_JOBS_CONTENT_SUCCESS,
  GET_JOBS_CONTENT_FAILURE,
  CREATE_OR_UPDATE_JOB_CONTENT_SUCCESS,
  CREATE_OR_UPDATE_JOB_CONTENT_FAILURE,
  CREATE_OR_UPDATE_JOB_CONTENT,
  GET_JOBS_APPLICATIONS,
  GET_JOBS_APPLICATIONS_SUCCESS,
  GET_JOBS_APPLICATIONS_FAILURE,
  getApplicationInput,
} from './Type'
import { AnyAction } from 'redux'

function getAllJobsApi({ payload }: any) {
  return client
    .query({
      query: getAllJobs,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

function createJobsApi(input: any) {
  return client
    .mutate({
      mutation: createJob,
      variables: {
        input,
      },
    })
}

function updateJobsApi(data: any) {
  const page = {
    id: data.id,
    ...data,
  }
  return client
    .mutate({
      mutation: updateJob,
      variables: { input: page },
    })
}

function deleteJobApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteJob,
      variables: { id },
    })
}

function changeJobStatusApi(data: any) {
  return client
    .mutate({
      mutation: changeJobStatus,
      variables: data,
    })
}

function getJobsContentApi(data: any) {
  return client
    .query({
      query: getJobsContent,
    })
}

function createOrUpdateJobContentApi(input: any) {
  return client
    .mutate({
      mutation: createOrUpdateJobContent,
      variables: { input },
    })
}

function getJobApplicationApi(input: getApplicationInput) {
  return client
    .query({
      query: getAppliedJobsByJob,
      variables: input,
    })
}

function* getAllJobsData({ payload }: AnyAction) {
  try {
    const res = yield getAllJobsApi({ payload })
    yield put({
      type: GET_ALL_JOBS_SUCCESS,
      payload: res.data.getAllJobs,
    })
  } catch (error) {
    yield put({ type: GET_ALL_JOBS_FAILURE, error })
  }
}

function* createJobData(action: ActionType) {
  try {
    const res = yield createJobsApi(action.payload)
    yield put({ type: CREATE_JOBS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_JOBS_FAILURE, error })
  }
}

export function* updateJobsData(action: ActionType) {
  try {
    const data = yield updateJobsApi(action.payload)
    yield put({ type: UPDATE_JOBS_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_JOBS_FAILURE, error })
  }
}

function* deleteJobsData(action: ActionType) {
  try {
    const res = yield deleteJobApi(action)
    yield put({ type: DELETE_JOBS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_JOBS_FAILURE, error })
  }
}

function* changeJobStatusData(action: ActionType) {
  try {
    const res = yield changeJobStatusApi(action.payload)
    yield put({
      type: CHANGE_JOB_STATUS_SUCCESS,
      payload: res.data.jobChangeStatus,
    })
  } catch (error) {
    yield put({ type: CHANGE_JOB_STATUS_FAILURE, error })
  }
}

function* getJobsContentData(action: ActionType) {
  try {
    const res = yield getJobsContentApi(action.payload)
    yield put({
      type: GET_JOBS_CONTENT_SUCCESS,
      payload: res.data.getCareerContent,
    })
  } catch (error) {
    yield put({ type: GET_JOBS_CONTENT_FAILURE, error })
  }
}

function* createOrUpdateJobContentData(action: ActionType) {
  try {
    const res = yield createOrUpdateJobContentApi(action.payload)
    yield put({
      type: CREATE_OR_UPDATE_JOB_CONTENT_SUCCESS,
      payload: res.data.createOrUpdateCareerContent,
    })
  } catch (error) {
    yield put({ type: CREATE_OR_UPDATE_JOB_CONTENT_FAILURE, error })
  }
}

function* getJobApplicationData(action: ActionType) {
  try {
    const res = yield getJobApplicationApi(action.payload)
    yield put({
      type: GET_JOBS_APPLICATIONS_SUCCESS,
      payload: res.data.getAppliedJobsByJob,
    })
  } catch (error) {
    yield put({ type: GET_JOBS_APPLICATIONS_FAILURE, error })
  }
}

function* getAllJobsSaga() {
  yield takeLatest(GET_ALL_JOBS, getAllJobsData)
}

function* createJobsSaga() {
  yield takeLatest(CREATE_JOBS, createJobData)
}

function* updateJobsSaga() {
  yield takeLatest(UPDATE_JOBS, updateJobsData)
}

function* deleteJobsSaga() {
  yield takeLatest(DELETE_JOBS, deleteJobsData)
}

function* changeJobStatusSaga() {
  yield takeLatest(CHANGE_JOB_STATUS, changeJobStatusData)
}

function* getJobsContentSaga() {
  yield takeLatest(GET_JOBS_CONTENT, getJobsContentData)
}

function* createOrUpdateJobContentSaga() {
  yield takeLatest(CREATE_OR_UPDATE_JOB_CONTENT, createOrUpdateJobContentData)
}

function* getJobApplicationSaga() {
  yield takeLatest(GET_JOBS_APPLICATIONS, getJobApplicationData)
}

export default function* jobSaga() {
  yield all([
    getAllJobsSaga(),
    deleteJobsSaga(),
    createJobsSaga(),
    updateJobsSaga(),
    changeJobStatusSaga(),
    getJobsContentSaga(),
    createOrUpdateJobContentSaga(),
    getJobApplicationSaga(),
  ])
}
