export interface smtpState {
  totalSmtpRow: number
  smtps: []
  smtpSuccess: boolean
  smtpError: boolean
  deleteSuccess: boolean
  deleteError: boolean
}

export interface smtpData {
  type: string
  user?: any
  host: string
  port: number
  pass: string
  email: string
}

export const GET_ALL_SMTP = 'GET_ALL_SMTP'
export const GET_ALL_SMTP_SUCCESS = 'GET_ALL_SMTP_SUCCESS'
export const GET_ALL_SMTP_FAILURE = 'GET_ALL_SMTP_FAILURE'

export const CREATE_SMTP = 'CREATE_SMTP'
export const CREATE_SMTP_SUCCESS = 'CREATE_SMTP_SUCCESS'
export const CREATE_SMTP_FAILURE = 'CREATE_SMTP_FAILURE'

export const UPDATE_SMTP = 'UPDATE_SMTP'
export const UPDATE_SMTP_SUCCESS = 'UPDATE_SMTP_SUCCESS'
export const UPDATE_SMTP_FAILURE = 'UPDATE_SMTP_FAILURE'

export const DELETE_SMTP = 'DELETE_SMTP'
export const DELETE_SMTP_SUCCESS = 'DELETE_SMTP_SUCCESS'
export const DELETE_SMTP_FAILURE = 'DELETE_SMTP_FAILURE'

export const CLEAR_SMTP_FLAG = 'CLEAR_SMTP_FLAG'

interface getManageSmtp {
  type: typeof GET_ALL_SMTP
  payload: smtpData
}

interface getManageSmtpSuccess {
  type: typeof GET_ALL_SMTP_SUCCESS
  payload: any
}
interface getManageSmtpFailure {
  type: typeof GET_ALL_SMTP_FAILURE
  payload: any
}

interface createManageSmtp {
  type: typeof CREATE_SMTP
  payload: smtpData
}

interface createManageSmtpSuccess {
  type: typeof CREATE_SMTP_SUCCESS
  payload: any
}

interface createManageSmtpFailure {
  type: typeof CREATE_SMTP_FAILURE
  payload: any
}

interface updateManageSmtp {
  type: typeof UPDATE_SMTP
  payload: smtpData
}

interface updateManageSmtpSuccess {
  type: typeof UPDATE_SMTP_SUCCESS
  payload: smtpData
}

interface updateManageSmtpFailure {
  type: typeof UPDATE_SMTP_FAILURE
  payload: smtpData
}

interface clearSmtpFlag {
  type: typeof CLEAR_SMTP_FLAG
  payload: any
}

interface deleteManageSmtp {
  type: typeof DELETE_SMTP
  payload: smtpData
}

interface deleteManageSmtpSuccess {
  type: typeof DELETE_SMTP_SUCCESS
  payload: smtpData
}

interface deleteManageSmtpFailure {
  type: typeof DELETE_SMTP_FAILURE
  payload: smtpData
}

export type ActionType =
  | getManageSmtp
  | getManageSmtpSuccess
  | getManageSmtpFailure
  | createManageSmtp
  | createManageSmtpSuccess
  | createManageSmtpFailure
  | updateManageSmtp
  | updateManageSmtpSuccess
  | updateManageSmtpFailure
  | clearSmtpFlag
  | deleteManageSmtp
  | deleteManageSmtpSuccess
  | deleteManageSmtpFailure
