import React, { Component } from 'react'
import { Form, Button, Divider, Row, Col } from 'antd'
import WSSelect from '../../component/WSSelect'
import { getCountries } from '../../store/country/manageCountry/Action'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getAllBoatPagination } from '../../enums/enums'

interface FilterButtonProps {
  filterArray?: any[]
  getCountries: typeof getCountries
  countries: any
  haveCountry?: boolean
  triggerAction: any
  extraInput?: any
  hideDivider?: boolean
  mb0?: boolean
  children?: React.ReactNode
}

interface FilterButtonState {
  filterInput: object
}

class FilterButton extends Component<FilterButtonProps, FilterButtonState> {
  constructor(props: FilterButtonProps) {
    super(props)
    this.state = {
      filterInput: {},
    }
  }

  setInput = (input: object) => {
    this.setState({
      filterInput: input,
    })
  }

  onReset = () => {
    const { triggerAction, extraInput } = this.props
    this.setState({
      filterInput: {},
    })
    console.log('RESET')
    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
      ...extraInput,
    }
    triggerAction(input)
  }

  onSubmit = () => {
    const { triggerAction, extraInput } = this.props
    const { filterInput } = this.state
    triggerAction({ ...getAllBoatPagination, ...filterInput, ...extraInput })
  }

  componentDidMount() {
    const { getCountries, countries, haveCountry } = this.props

    if (haveCountry && !countries.length) {
      getCountries({})
    }
  }

  renderCountrySelector = (countries: any[]) => {
    const { filterInput } = this.state
    return (
      <React.Fragment>
        <small>Country</small>
        <WSSelect
          listOptions="name"
          passed="name"
          arrayList={[{ name: 'None' }, ...countries]}
          onChangeText={(e: any) => {
            const { country, ...noNone }: any = filterInput
            this.setInput(e === 'None' ? noNone : { country: e })
          }}
        />
      </React.Fragment>
    )
  }

  render() {
    const { filterArray, haveCountry, countries } = this.props
    const { filterInput } = this.state
    return (
      <Form layout="vertical" style={{ marginBottom: (!this.props.mb0 && this.props.hideDivider) ? '24px' : 0 }}>
        <Row gutter={16}>
          <Col>{haveCountry && this.renderCountrySelector(countries)}</Col>
          {filterArray &&
            filterArray.map((item: any) => (
              <Col>
                <small>{item.title}</small>
                <WSSelect
                  listOptions={item.listOptions}
                  passed={item.passed}
                  arrayList={[{ label: 'None', value: 'none' }, ...item.filedArray]}
                  onChangeText={(e: any) => {
                    const { [item.actionField]: removedRecord, ...noNone }: any = filterInput
                    this.setInput(e === 'none' ? noNone : { ...filterInput, [item.actionField]: e })
                  }}
                />
              </Col>
            ))}
          <Col>
            <div className="mt-1-3rem">
              <Button onClick={this.onSubmit} type="primary">
                Apply
              </Button>
              <Button onClick={this.onReset} className="ml-1" type="primary">
                Reset
              </Button>
            </div>
          </Col>
          {this.props.children}
        </Row>
        {this.props.hideDivider || <Divider />}
      </Form>
    )
  }
}

const mapStateToProps = (state: any) => ({
  countries: state.countryReducer.countries,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCountries: (data: any) => dispatch(getCountries(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton)
