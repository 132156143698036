import {
  ActionType,
  ADD_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATES_SUCCESS,
  UPDATE_EMAIL_TEMPLATE,
  emailTemplateState,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
  CLEAR_EMAIL_TEMPLATE_FLAG,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  ADD_EMAIL_TEMPLATE_FAILURE,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_FAILURE,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  GET_VARIABLE,
  GET_VARIABLE_SUCCESS,
  GET_VARIABLE_FAILURE,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: emailTemplateState = {
  template: {
    id: '',
    type: '',
    subject: '',
    body: '',
    updatedAt: '',
  },
  totalTemplatesRow: 0,
  templates: [],
  emailSuccess: false,
  emailError: false,
  deleteError: false,
  deleteSuccess: false,
  variableSuccess: false,
  variableList: [],
  variableError: false,
}

export const emailTemplateReducer = (state = initialState, action: ActionType): emailTemplateState => {
  switch (action.type) {
    case ADD_EMAIL_TEMPLATE:
      return {
        ...state,
        emailSuccess: false,
        emailError: false,
      }

    case ADD_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        emailSuccess: true,
      }

    case ADD_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        emailError: true,
      }

    case GET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailSuccess: false,
        emailError: false,
      }
    case GET_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload.items,
        totalTemplatesRow: action.payload.total,
      }
    case GET_EMAIL_TEMPLATES_FAILURE:
      return {
        ...state,
        emailSuccess: false,
        emailError: true,
      }

    case UPDATE_EMAIL_TEMPLATE:
      return {
        ...state,
        emailSuccess: false,
        emailError: false,
      }

    case UPDATE_EMAIL_TEMPLATE_SUCCESS:
      NotificationWithIcon('success', 'Record Updated Successfully')
      return {
        ...state,
        emailSuccess: true,
      }
    case UPDATE_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        emailError: true,
      }

    case CLEAR_EMAIL_TEMPLATE_FLAG:
      return {
        ...state,
        emailSuccess: false,
        emailError: false,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_TEMPLATE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_TEMPLATE_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        templates: state.templates.filter(item => item.id !== action.payload.id),
      }

    case DELETE_TEMPLATE_FAILURE:
      return {
        ...state,
        deleteError: true,
      }

    case GET_VARIABLE:
      return {
        ...state,
        variableSuccess: false,
        variableError: false,
      }
    case GET_VARIABLE_SUCCESS:
      return {
        ...state,
        variableSuccess: true,
        variableList: action.payload,
        variableError: false,
      }
    case GET_VARIABLE_FAILURE:
      return {
        ...state,
        variableSuccess: false,
        variableError: true,
      }
    default:
      return state
  }
}
