import { Button, Card, Col, InputNumber, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import ShowTitle from '../../component/ShowTitle'
import History from '../../History'
import WSField from '../../component/Field'
import WSSelect from '../../component/WSSelect'
import { client } from '../..'
import gql from 'graphql-tag'
import NotificationWithIcon from '../../component/notification/Notification'

interface TimerSettingsProps {
  history: typeof History
}

const unitsList = [
  { id: 'minutes', value: 'minutes' },
  { id: 'hours', value: 'hours' },
  { id: 'days', value: 'days' },
  { id: 'weeks', value: 'weeks' },
  { id: 'months', value: 'months' },
]

const TimerSettings = ({ history }: TimerSettingsProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const [surveyorTime, setSurveyorTime] = useState(14)
  const [surveyorUnit, setSurveyorUnit] = useState('days')

  const [boatTime, setBoatTime] = useState(5)
  const [boatUnit, setBoatUnit] = useState('days')

  const [auctionAddTime, setAuctionAddTime] = useState(2)
  const [auctionAddUnit, setAuctionAddUnit] = useState('days')

  const [auctionTime, setAuctionTime] = useState(3)
  const [auctionUnit, setAuctionUnit] = useState('days')

  const [auctionStartGapTime, setAuctionStartGapTime] = useState(30)
  const [auctionStartGapUnit, setAuctionStartGapUnit] = useState('days')

  useEffect(() => {
    getTimerSettings()
  }, [])

  const getTimerSettings = async () => {
    setIsLoading(true)

    try {
      const res = await client.query({
        query: gql`
          query getTimerSettings {
            getTimerSettings {
              surveyorTime
              surveyorUnit
              boatTime
              boatUnit
              auctionAddTime
              auctionAddUnit
              auctionTime
              auctionUnit
              auctionStartGapTime
              auctionStartGapUnit
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })

      if (res.data.getTimerSettings) {
        setSurveyorTime(res.data.getTimerSettings.surveyorTime)
        setSurveyorUnit(res.data.getTimerSettings.surveyorUnit)
        setBoatTime(res.data.getTimerSettings.boatTime)
        setBoatUnit(res.data.getTimerSettings.boatUnit)
        setAuctionAddTime(res.data.getTimerSettings.auctionAddTime)
        setAuctionAddUnit(res.data.getTimerSettings.auctionAddUnit)
        setAuctionTime(res.data.getTimerSettings.auctionTime)
        setAuctionUnit(res.data.getTimerSettings.auctionUnit)
        setAuctionStartGapTime(res.data.getTimerSettings.auctionStartGapTime)
        setAuctionStartGapUnit(res.data.getTimerSettings.auctionStartGapUnit)
      }
    } catch (err) {
      NotificationWithIcon('error', 'Cannot get timer settings')
      console.error(err)
    }

    setIsLoading(false)
  }

  const handleSave = async () => {
    setIsLoading(true)

    try {
      await client.mutate({
        mutation: gql`
          mutation updateTimerSettings($input: TimerSettingsInput!) {
            updateTimerSettings(input: $input)
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            surveyorTime,
            surveyorUnit,
            boatTime,
            boatUnit,
            auctionAddTime,
            auctionAddUnit,
            auctionTime,
            auctionUnit,
            auctionStartGapTime,
            auctionStartGapUnit,
          },
        },
      })

      NotificationWithIcon('success', 'Timer settings update successfully')
    } catch (err) {
      NotificationWithIcon('error', 'Cannot update timer settings')
      console.error(err)
    }

    setIsLoading(false)
  }

  return (
    <Card
      loading={isLoading}
      title="Manage Timer Settings"
      bordered={false}
      extra={
        <div>
          <Button disabled={isLoading} onClick={() => history.goBack()}>
            Back
          </Button>
          <Button type="primary" className="ml-15" disabled={isLoading} onClick={handleSave}>
            Save
          </Button>
        </div>
      }
    >
      <Row gutter={24}>
        {/* -------------------------------- */}
        {/* Timer for surveyor to upload report */}

        <Col xs={8}>
          <div className="ant-form-item-required" style={{ marginBottom: '1.5em' }}>
            Timer for surveyor to upload report
          </div>
        </Col>
        <Col xs={8}>
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Timeline"
            value={surveyorTime}
            onChange={v => setSurveyorTime(+(v || 0))}
          />
        </Col>
        <Col xs={8}>
          <WSSelect
            value={surveyorUnit}
            arrayList={unitsList}
            passed="id"
            listOptions="value"
            onChangeText={(v: any) => setSurveyorUnit(v)}
          />
        </Col>

        {/* -------------------------------- */}
        {/* Timer for buyer to complete boat payment (Sales Engine and Auction) */}

        <Col xs={8}>
          <div className="ant-form-item-required" style={{ marginBottom: '1.5em' }}>
            Timer for buyer to complete boat payment (Sales Engine and Auction)
          </div>
        </Col>
        <Col xs={8}>
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Timeline"
            value={boatTime}
            onChange={v => setBoatTime(+(v || 0))}
          />
        </Col>
        <Col xs={8}>
          <WSSelect
            value={boatUnit}
            arrayList={unitsList}
            passed="id"
            listOptions="value"
            onChangeText={(v: any) => setBoatUnit(v)}
          />
        </Col>

        {/* -------------------------------- */}
        {/* Timer for starting auction (Auction duration) */}

        <Col xs={8}>
          <div className="ant-form-item-required" style={{ marginBottom: '1.5em' }}>
            Timer for Auction start calender duration
          </div>
        </Col>
        <Col xs={8}>
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Timeline"
            value={auctionAddTime}
            onChange={v => setAuctionAddTime(+(v || 0))}
          />
        </Col>
        <Col xs={8}>
          <WSSelect
            value={auctionAddUnit}
            arrayList={unitsList}
            passed="id"
            listOptions="value"
            onChangeText={(v: any) => setAuctionAddUnit(v)}
          />
        </Col>

        {/* -------------------------------- */}
        {/* Timer for starting auction (Auction duration) */}

        <Col xs={8}>
          <div className="ant-form-item-required" style={{ marginBottom: '1.5em' }}>
            Timer for Auction duration
          </div>
        </Col>
        <Col xs={8}>
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Timeline"
            value={auctionTime}
            onChange={v => setAuctionTime(+(v || 0))}
          />
        </Col>
        <Col xs={8}>
          <WSSelect
            value={auctionUnit}
            arrayList={unitsList}
            passed="id"
            listOptions="value"
            onChangeText={(v: any) => setAuctionUnit(v)}
          />
        </Col>

        {/* -------------------------------- */}
        {/* Timer for activating "Add Auction" button */}

        <Col xs={8}>
          <div className="ant-form-item-required" style={{ marginBottom: '1.5em' }}>
            Timer for activating "Add Auction" button
          </div>
        </Col>
        <Col xs={8}>
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Timeline"
            value={auctionStartGapTime}
            onChange={v => setAuctionStartGapTime(+(v || 0))}
          />
        </Col>
        <Col xs={8}>
          <WSSelect
            value={auctionStartGapUnit}
            arrayList={unitsList}
            passed="id"
            listOptions="value"
            onChangeText={(v: any) => setAuctionStartGapUnit(v)}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default TimerSettings
