import React, { Fragment } from 'react'

import { Tabs, List } from 'antd'
import { v1 as uuidv1 } from 'uuid'
import { getUserGuideData } from '../../../helpers/UserGuideHelper'

const { TabPane } = Tabs
export interface Props {
  location: any
}
export interface State {
  index: String
  userGuide: any
  updatedUserGuide: []
  detailedUserGuide: [
    {
      title: String
      list: [
        {
          title: String
          value: String
        }
      ]
    }
  ]
}

class ViewUserGuide extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      index: '',
      userGuide: (props.location.state && props.location.state.userGuide) || {},
      updatedUserGuide: [],
      detailedUserGuide: [
        {
          title: '',
          list: [
            {
              title: '',
              value: '',
            },
          ],
        },
      ],
    }
  }

  componentDidMount = async () => {
    const userGuideObject = await getUserGuideData(this.state.userGuide)
    this.setState({ updatedUserGuide: userGuideObject })
    this.setUserGuideObject()
  }

  setUserGuideObject = () => {
    let userGuide: any = []
    this.state.updatedUserGuide.map((item: any, index: any) => {
      let list: any = [],
        userGuideObject
      for (let [key, value] of Object.entries(item)) {
        if (key !== 'title') {
          let userGuideField = {
            title: key,
            value: value,
          }
          list.push(userGuideField)
        }
      }
      userGuideObject = {
        title: item.title,
        list: list,
      }
      userGuide.push(userGuideObject)
    })
    this.setState({ detailedUserGuide: userGuide })
  }

  render() {
    return (
      <Fragment>
        <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: 220 }}>
          {this.state.detailedUserGuide.map((tab: any) => (
            <TabPane tab={tab.title} key={tab.id}>
              {tab.list.map((item: any) => {
                return (
                  <List key={item.id}>
                    <List.Item>
                      {item.title} : {item.value}
                    </List.Item>
                  </List>
                )
              })}
            </TabPane>
          ))}
        </Tabs>
      </Fragment>
    )
  }
}

export default ViewUserGuide
