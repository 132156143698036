import React, { useEffect, useMemo } from 'react'
import { Card, Row, Col, Input, Button, Select } from 'antd'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import History from '../../../History'
import { createSeoMetaTagAction, clearMetaTagFlags, updateSeoMetaTagAction } from '../../../store/seo/Action'
import { AppState } from '../../../store'
import {redirectUrl } from '../../../constants/GlobalConstants'
// import { availablePagesSelectOptions } from './data'

const { TextArea } = Input

const validationSchema = Yup.object().shape({
  title: Yup.string().required('SEO Title is required'),
  metaTitle: Yup.string().required('SEO Meta Title is required'),
  keywords: Yup.array().min(1, 'Keywords is required').required('Keywords is required').nullable(),
  description: Yup.string().required('SEO Description is required'),
  page: Yup.string()
    .required('Page URL is required')
    .matches(
      // new RegExp(`^(?!https?:\/\/(?:www\\.)?${redirectUrl})(\/[^\/]+)*$`),
      /^\/(?:[a-zA-Z0-9\-.%=\/?]|\/)*$/,
      'Please enter a valid URL pathname without the domain name'
    ),
  canonicalLink: Yup.string()
  .matches(
      new RegExp(`^(?!https?:\/\/(?:www\\.)?${redirectUrl})(\/[^\/]+)*$`),
      'Please enter a valid URL without the domain name'
  ),  
  // .url('Enter correct url in the form https://www.example.com'),
})

const intialFormValues = {
  title: '',
  metaTitle: '',
  keywords: [],
  description: '',
  canonicalLink: '',
  active: true,
  page: '',
}

const CreateSeoMetaTag = () => {
  const history = useHistory()

  const metaTag = (history.location.state as any)?.metaTag
  const initialValues = metaTag || intialFormValues
  const isEditMode = useMemo(() => !!metaTag, [metaTag])

  const { createSuccess, createLoading, updateLoading, updateSuccess } = useSelector((state: AppState) => ({
    createSuccess: state.seo.createSuccess,
    createLoading: state.seo.createLoading,
    updateLoading: state.seo.updateLoading,
    updateSuccess: state.seo.updateSuccess,
  }))
  const dispatch = useDispatch()

  useEffect(() => {
    if (createSuccess) {
      dispatch(clearMetaTagFlags())
      history.push('/seo-meta-tag')
    }
  }, [createSuccess])

  useEffect(() => {
    if (updateSuccess) {
      dispatch(clearMetaTagFlags())
      history.push('/seo-meta-tag')
    }
  }, [updateSuccess])

  return (
    <Formik
      validateOnChange={true}
      validateOnMount
      initialValues={initialValues}
      onSubmit={values => {
        if (isEditMode) {
          // update
          dispatch(updateSeoMetaTagAction(values))
          return
        }
        dispatch(createSeoMetaTagAction(values))
      }}
      validationSchema={validationSchema}
    >
      {props => {
        return (
          <Form onSubmit={props.handleSubmit}>
            <Card
              title="ADD SEO Meta Tag"
              extra={
                <div className="d-flex flex-wrap">
                  <div className="mr-10 mt-8">
                    <Button className="btn-add mt-8 " onClick={() => History.goBack()}>
                      Back
                    </Button>
                  </div>
                  <div className="mr-10 mt-8">
                    <Button
                      disabled={!props.isValid}
                      loading={createLoading || updateLoading}
                      className=" mt-8 ant-btn-primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" xs={24}>
                  <span className="ant-form-item-required" title="Email">
                    Page URL
                  </span>
                  <Field className="ant-input" name="page">
                    {({ field, form: { touched, errors }, meta }: any) => {
                      return (
                        <>
                          <Input
                            {...field}
                            placeholder="/your-pathname"
                            autoComplete="off"
                            addonBefore={redirectUrl}
                            className={meta.touched && meta.error ? 'ant-input-status-error' : ''}
                          />
                          {props.touched.page && props.errors.page && (
                            <div className="ant-input-error">{props.errors.page}</div>
                          )}
                        </>
                      )
                    }}
                  </Field>
                  {/* <Field className="ant-input" name="page">
                    {({ field, form: { touched, errors }, meta }: any) => {
                      console.log(field.value, 'field value')
                      return (
                        <Select
                          mode="tags"
                          options={availablePagesSelectOptions}
                          style={{ width: '100%' }}
                          placeholder="Select a option"
                          {...field}
                          value={field.value}
                          onChange={selectedValue => props.setFieldValue(field.name, selectedValue)}
                        />
                      )
                    }}
                  </Field> */}
                </Col>
              </Row>

              <Row gutter={16}>
                <Col className="gutter-box" md={8}>
                  <span className="ant-form-item-required" title="title name">
                    SEO Title
                  </span>
                  <Field className="ant-input" name="title">
                    {({ field, form: { touched, errors }, meta }: any) => {
                      return (
                        <>
                          <Input
                            {...field}
                            autoComplete="off"
                            placeholder="SEO Title Name"
                            className={meta.touched && meta.error ? 'ant-input-status-error' : ''}
                          />
                          {props.touched.title && props.errors.title && (
                            <div className="ant-input-error">{props.errors.title}</div>
                          )}
                        </>
                      )
                    }}
                  </Field>
                </Col>
                <Col className="gutter-box" md={8}>
                  <span className="ant-form-item-required" title="Meta Title Name">
                    SEO Meta Title
                  </span>
                  <Field className="ant-input" name="metaTitle">
                    {({ field, form: { touched, errors }, meta }: any) => {
                      return (
                        <>
                          <Input
                            {...field}
                            autoComplete="off"
                            placeholder="SEO Meta Title Name"
                            className={meta.touched && meta.error ? 'ant-input-status-error' : ''}
                          />
                          {props.touched.metaTitle && props.errors.metaTitle && (
                            <div className="ant-input-error">{props.errors.metaTitle}</div>
                          )}
                        </>
                      )
                    }}
                  </Field>
                </Col>
                <Col className="gutter-box" md={8}>
                  <span className="ant-form-item-required" title="Email">
                    SEO Keywords
                  </span>
                  <Field className="ant-input" name="keywords">
                    {({ field, form, meta }: any) => {
                      console.log(meta, 'meta meta in keywords')
                      const options = field.value.map((val: string) => ({
                        value: val,
                        label: val,
                      }))
                      return (
                        <>
                          <Select
                            {...field}
                            className={meta.touched && meta.error ? 'ant-input-status-error' : ''}
                            notFoundContent={null}
                            mode="tags"
                            options={options}
                            style={{ width: '100%' }}
                            placeholder="Type keyword and hit enter"
                            value={field.value}
                            onChange={selectedValue => props.setFieldValue(field.name, selectedValue)}
                            onBlur={() => form.setFieldTouched(field.name, true)}
                          />
                          {props.touched.keywords && props.errors.keywords && (
                            <div className="ant-input-error">{props.errors.keywords}</div>
                          )}
                        </>
                      )
                    }}
                  </Field>
                  <div className="card p-fluid"></div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box " md={12}>
                  <span className="ant-form-item-required" title="description">
                    SEO Meta Description
                  </span>
                  <Field className="ant-input" name="description">
                    {({ field, form: { touched, errors }, meta }: any) => {
                      return (
                        <>
                          <TextArea
                            {...field}
                            autoComplete="off"
                            rows={4}
                            placeholder="SEO Meta Description"
                            className={meta.touched && meta.error ? 'ant-input-status-error' : ''}
                          />
                          {props.touched.description && props.errors.description && (
                            <div className="ant-input-error">{props.errors.description}</div>
                          )}
                        </>
                      )
                    }}
                  </Field>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" xs={24}>
                  <span className="ant-form-item-required" title="Canonical URL">
                    Canonical URL
                  </span>
                  <Field className="ant-input" name="canonicalLink">
                    {({ field, form: { touched, errors }, meta }: any) => {
                      return (
                        <>
                          <Input
                            {...field}
                            placeholder="/your-pathname"
                            autoComplete="off"
                            addonBefore={redirectUrl}
                            className={meta.touched && meta.error ? 'ant-input-status-error' : ''}
                          />
                          {props.touched.canonicalLink && props.errors.canonicalLink && (
                            <div className="ant-input-error">{props.errors.canonicalLink}</div>
                          )}
                        </>
                      )
                    }}
                  </Field>
                  {/* <Field className="ant-input" name="page">
                    {({ field, form: { touched, errors }, meta }: any) => {
                      console.log(field.value, 'field value')
                      return (
                        <Select
                          mode="tags"
                          options={availablePagesSelectOptions}
                          style={{ width: '100%' }}
                          placeholder="Select a option"
                          {...field}
                          value={field.value}
                          onChange={selectedValue => props.setFieldValue(field.name, selectedValue)}
                        />
                      )
                    }}
                  </Field> */}
                </Col>
              </Row>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CreateSeoMetaTag
