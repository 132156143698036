import React, { Fragment, useState } from 'react'
import { Select, DatePicker, Input, Button } from 'antd'

import moment from 'moment'
import { formateDate } from '../helpers/helper'
import WSIcon from './WSIcon'
import WSSelect from './WSSelect'
import { UploadOutlined } from '@ant-design/icons'
const { Option } = Select
const { TextArea } = Input
const FieldSelect = (props: any) => {
  switch (props.type) {
    case 'Select':
      return (
        <Fragment>
          <WSSelect {...props} defaultValue={props.field.value} arrayList={props.options} passed="id" listOptions="name" />
        </Fragment>
      )
    case 'MultiSelect':
      return (
        <Fragment>
          <WSSelect
            {...props}
            mode="multiple"
            defaultValue={props.field.value}
            arrayList={props.options}
            passed="value"
            listOptions="name"
          />
        </Fragment>
      )
    case 'DatePicker':
      return (
        <Fragment>
          <DatePicker
            {...props}
            value={props.field.value ? formateDate(props.field.value) : undefined}
            placeholder="Select Payment Date"
            className="w-100"
          />
        </Fragment>
      )
    case 'InputNumber':
      return (
        <Fragment>
          <Input {...props} type="number" value={props.field.value} placeholder={props.placeholder} />
        </Fragment>
      )
    case 'InputText':
      return (
        <Fragment>
          <Input {...props} type="text" value={props.field.value} placeholder={props.placeholder} />
        </Fragment>
      )
    case 'InputTextArea':
      return (
        <Fragment>
          <TextArea
            {...props}
            value={props.field.value}
            placeholder={props.placeholder}
            autosize={{ minRows: 3, maxRows: 5 }}
          />
        </Fragment>
      )

    default:
      return <div />
  }
}
export default FieldSelect
