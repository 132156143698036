import {
  marinaTypesState,
  CREATE_MARINA_TYPES,
  ActionType,
  CREATE_MARINA_TYPES_SUCCESS,
  CLEAR_MARINA_TYPES_FLAG,
  GET_MARINA_TYPES_FAILURE,
  UPDATE_MARINA_TYPES,
  UPDATE_MARINA_TYPES_SUCCESS,
  UPDATE_MARINA_TYPES_FAILURE,
  DELETE_MARINA_TYPES,
  DELETE_MARINA_TYPES_SUCCESS,
  DELETE_MARINA_TYPES_FAILURE,
  GET_MARINA_TYPES_SUCCESS,
  GET_MARINA_TYPES,
  CREATE_MARINA_TYPES_FAILURE,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'
import { get } from 'lodash'

const initialState: marinaTypesState = {
  marinaTypes: [],
  marinaTypeSuccess: false,
  boatTypeError: false,
  deleteSuccess: false,
  deleteError: false,
  updateMarinaTypeSuccess: false,
  totalMarinaRow: 0,
}

export const marinaTypesReducer = (state = initialState, action: ActionType): marinaTypesState => {
  switch (action.type) {
    case CREATE_MARINA_TYPES:
      return {
        ...state,
        marinaTypeSuccess: false,
        boatTypeError: false,
      }
    case CREATE_MARINA_TYPES_SUCCESS:
      return {
        ...state,
        marinaTypeSuccess: true,
        boatTypeError: false,
      }
    case CREATE_MARINA_TYPES_FAILURE:
      return {
        ...state,
        marinaTypeSuccess: false,
        boatTypeError: true,
      }
    case GET_MARINA_TYPES:
      return {
        ...state,
        boatTypeError: false,
      }
    case GET_MARINA_TYPES_SUCCESS:
      return {
        ...state,
        boatTypeError: false,
        marinaTypes: action.payload.items,
        totalMarinaRow: action.payload.total,
      }
    case GET_MARINA_TYPES_FAILURE:
      return {
        ...state,
        boatTypeError: true,
      }
    case CLEAR_MARINA_TYPES_FLAG:
      return {
        ...state,
        updateMarinaTypeSuccess: false,
        marinaTypeSuccess: false,
        boatTypeError: false,
        deleteError: false,
        deleteSuccess: false,
      }
    case UPDATE_MARINA_TYPES:
      return {
        ...state,
        marinaTypeSuccess: false,
        updateMarinaTypeSuccess: false,
        boatTypeError: true,
      }
    case UPDATE_MARINA_TYPES_SUCCESS:
      return {
        ...state,
        updateMarinaTypeSuccess: true,
        boatTypeError: false,
      }
    case UPDATE_MARINA_TYPES_FAILURE:
      return {
        ...state,
        marinaTypeSuccess: false,
        boatTypeError: true,
      }
    case DELETE_MARINA_TYPES:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_MARINA_TYPES_SUCCESS:
      const { marinaTypes } = state
      NotificationWithIcon('success', 'Record Deleted Successfully')

      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
        marinaTypes: marinaTypes.filter((marinaType: any) => marinaType.id !== action.payload.id),
      }

    case DELETE_MARINA_TYPES_FAILURE:
      NotificationWithIcon('error', get(action.error, 'networkError.result.errors[0].message', 'Cannot delete'))

      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }
    default:
      return state
  }
}
