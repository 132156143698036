import {
  ActionType,
  GET_MANAGE_ARTICLES_SUCCESS,
  GET_MANAGE_ARTICLES,
  articlesState,
  DELETE_ARTICLE,
  GET_MANAGE_ARTICLES_FAILURE,
  CHANGE_ARTICLE_STATUS,
  CHANGE_ARTICLE_STATUS_SUCCESS,
  CHANGE_ARTICLE_STATUS_FAILURE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAILURE,
  CLEAR_ARTICLE_CONTENT_FLAG,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: articlesState = {
  article: {
    data: {},
    id: '',
    userId: {
      firstName: '',
      lastName: '',
    },
    title: '',
    titleSlug: '',
    description: '',
    file: '',
    articleViewCount: '',
    articleApproved: '',
  },
  articles: [],
  success: false,
  error: false,
  deleteSuccess: false,
  deleteError: false,
  totalArticlesRow: 0,
}

export const manageArticleReducer = (state = initialState, action: ActionType): articlesState => {
  switch (action.type) {
    case DELETE_ARTICLE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }
    case DELETE_ARTICLE_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')

      return {
        ...state,
        articles: state.articles.filter(item => item.id !== action.payload.articleChangeStatus.id),
        deleteSuccess: true,
        deleteError: false,
      }
    case DELETE_ARTICLE_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case GET_MANAGE_ARTICLES:
      return {
        ...state,
      }

    case GET_MANAGE_ARTICLES_SUCCESS:
      return {
        ...state,
        success: true,
        articles: action.payload.items,
        totalArticlesRow: action.payload.total,
      }
    case GET_MANAGE_ARTICLES_FAILURE:
      return {
        ...state,
        error: true,
      }

    case CHANGE_ARTICLE_STATUS:
      return {
        ...state,
      }
    case CHANGE_ARTICLE_STATUS_SUCCESS:
      const changedArticleData = state.articles.map(item => {
        if (item.id === action.payload.data.articleChangeStatus.id) {
          return {
            ...item,
            articleApproved: !item.articleApproved,
          }
        }
        return item
      })
      NotificationWithIcon('success', 'Status Changed Successfully')

      return {
        ...state,
        articles: changedArticleData,
      }
    case CHANGE_ARTICLE_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }
    case CLEAR_ARTICLE_CONTENT_FLAG:
      return {
        ...state,
        success: false,
        error: false,
        deleteSuccess: false,
        deleteError: false,
      }

    default:
      return state
  }
}
