import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import {
  getManageSalesEngine,
  deleteSalesEngine,
  clearSalesEngineFlag,
} from '../../../store/salesEngine/manageSalesEngine/Action'
import { pagination, salesEngineType } from '../../../enums/enums'

interface Props {
  getManageSalesEngine: typeof getManageSalesEngine
  salesEngine: []
  deleteSalesEngine: typeof deleteSalesEngine
  clearSalesEngineFlag: typeof clearSalesEngineFlag
  salesEnginSuccess: boolean
  deleteSalesEngineSuccess: boolean
}

interface State {}

class SalesEngineListing extends Component<Props, State> {
  state = {
    columns: [
      {
        title: 'Boat Name',
        dataIndex: 'boat',
        key: 'boat.boatName',
        render: (text: any) => {
          return text.boatName
        },
      },
      {
        title: 'Boat Buyer',
        dataIndex: 'buyer.firstName',
        key: 'buyer',

        render: (text: string, record: tableRecords, index: number) => {
          return record && record.buyer && `${record.buyer.firstName} ${record.buyer.lastName}`
        },
      },
      {
        title: 'Boat Status',
        dataIndex: 'boat',
        key: 'boatStatus',
        render: (text: any) => {
          return text?.boatStatus?.alias
        },
      },
      {
        title: 'Boat Buyer Country',
        dataIndex: 'buyer',
        key: 'buyer',
        render: (text: any) => {
          return text && text.buyer && text.buyer.country
        },
      },
      {
        title: 'Buy-Sell Process',
        dataIndex: 'buySellProcess',
        key: 'buySellProcess',
        render: (text: string, record: tableRecords, index: number) =>
          record.buySellProcess && <span>{record.buySellProcess[record.buySellProcess.length - 1]}</span>,
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteSalesEngine } = this.props
          return (
            <>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteSalesEngine, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { salesEnginSuccess, clearSalesEngineFlag } = props

    if (salesEnginSuccess) {
      clearSalesEngineFlag()
    }
    return null
  }

  componentDidMount() {
    const { getManageSalesEngine } = this.props
    const data = {
      page: pagination.page,
      limit: pagination.limit,
      type: salesEngineType.all,
      buyItNow: true,
    }
    getManageSalesEngine(data)
  }

  render() {
    const { salesEngine } = this.props
    const { columns } = this.state

    return (
      <>
        <Card
          title="Manage Buy It Now"
          // extra={
          //   <Button className="btn-add">
          //     <Link to="/sales-engine-listing">Show Sales Engine Process</Link>
          //   </Button>
          // }
        >
          <ExpandTable columns={columns} tableData={salesEngine} expandHtml={false} isExpand={false} />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  salesEngine: state.salesEngineReducer && state.salesEngineReducer.salesEngine,
  deleteSalesEngineSuccess: state.salesEngineReducer && state.salesEngineReducer.deleteSalesEngineSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getManageSalesEngine: (data: any) => dispatch(getManageSalesEngine(data)),
  deleteSalesEngine: (data: string) => dispatch(deleteSalesEngine(data)),
  clearSalesEngineFlag: () => dispatch(clearSalesEngineFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngineListing)
