export const GET_SEO_TAGS = 'GET_SEO_TAGS'
export const GET_SEO_TAGS_SUCCESS = 'GET_SEO_TAGS_SUCCESS'
export const GET_SEO_TAGS_FAILURE = 'GET_SEO_TAGS_FAILURE'

export const DELETE_SEO_TAG = 'DELETE_SEO_TAG'
export const DELETE_SEO_TAG_SUCCESS = 'DELETE_SEO_TAG_SUCCESS'
export const DELETE_SEO_TAG_FAILURE = 'DELETE_SEO_TAG_FAILURE'

export const CREATE_SEO_TAG = 'CREATE_SEO_TAG'
export const CREATE_SEO_TAG_SUCCESS = 'CREATE_SEO_TAG_SUCCESS'
export const CREATE_SEO_TAG_FAILURE = 'CREATE_SEO_TAG_FAILURE'

export const UPDATE_SEO_TAG = 'UPDATE_SEO_TAG'
export const UPDATE_SEO_TAG_SUCCESS = 'UPDATE_SEO_TAG_SUCCESS'
export const UPDATE_SEO_TAG_FAILURE = 'UPDATE_SEO_TAG_FAILURE'

export const SEARCH_SEO_TAG = 'SEARCH_SEO_TAG'
export const SEARCH_SEO_TAG_SUCCESS = 'SEARCH_SEO_TAG_SUCCESS'
export const SEARCH_SEO_TAG_FAILURE = 'SEARCH_SEO_TAG_FAILURE'

export const CLEAR_FLAGS = 'CLEAR_FLAGS'

export interface ResponseGenerator {
  config?: any
  data?: any
  headers?: any
  request?: any
  status?: number
  statusText?: string
}

export interface SeoState {
  title: string
  active: boolean
  metaTitle: string
  keywords: string[]
  id: string
  description: string
}

export interface InitialStateType {
  metaTags: {
    items: SeoState[]
    total: number
  }
  fetchLoading: boolean
  fetchError: any
  fetchSuccess: any

  deleteLoading: boolean
  deleteError: any
  deleteSuccess: any

  createLoading: boolean
  createError: any
  createSuccess: any

  updateLoading: boolean
  updateError: any
  updateSuccess: any

  searchLoading: boolean
  searchError: any
  searchSuccess: any
}
// get
interface getSeoTags {
  type: typeof GET_SEO_TAGS
  payload: any
}
interface getSeoTagsSuccess {
  type: typeof GET_SEO_TAGS_SUCCESS
  payload: any
}
interface getSeoTagsFailure {
  type: typeof GET_SEO_TAGS_FAILURE
  payload: string
}
// delete
interface deleteSeoTags {
  type: typeof DELETE_SEO_TAG
  payload: any
}
interface deleteSeoTagsSuccess {
  type: typeof DELETE_SEO_TAG_SUCCESS
  payload: any
}
interface deleteSeoTagsFailure {
  type: typeof DELETE_SEO_TAG_FAILURE
  payload: string
}

// create
interface createSeoTags {
  type: typeof CREATE_SEO_TAG
  payload: any
}
interface createSeoTagsSuccess {
  type: typeof CREATE_SEO_TAG_SUCCESS
  payload: any
}
interface createSeoTagsFailure {
  type: typeof CREATE_SEO_TAG_FAILURE
  payload: string
}

// update
interface updateSeoTags {
  type: typeof UPDATE_SEO_TAG
  payload: any
}
interface updateSeoTagsSuccess {
  type: typeof UPDATE_SEO_TAG_SUCCESS
  payload: any
}
interface updateSeoTagsFailure {
  type: typeof UPDATE_SEO_TAG_FAILURE
  payload: string
}

// search
interface searchSeoTags {
  type: typeof SEARCH_SEO_TAG
  payload: any
}
interface searchSeoTagsSuccess {
  type: typeof SEARCH_SEO_TAG_SUCCESS
  payload: any
}
interface searchSeoTagsFailure {
  type: typeof SEARCH_SEO_TAG_FAILURE
  payload: string
}

// clear flags
interface clearTags {
  type: typeof CLEAR_FLAGS
}

export type ActionTypes =
  | getSeoTags
  | getSeoTagsSuccess
  | getSeoTagsFailure
  | deleteSeoTags
  | deleteSeoTagsFailure
  | deleteSeoTagsSuccess
  | createSeoTags
  | createSeoTagsFailure
  | createSeoTagsSuccess
  | searchSeoTags
  | searchSeoTagsFailure
  | searchSeoTagsSuccess
  | clearTags
  | updateSeoTags
  | updateSeoTagsSuccess
  | updateSeoTagsFailure
