import {
  getAuctionRoomInput,
  GET_ALL_AUCTION_ROOM,
  AuctionRoomStatusInput,
  CHANGE_AUCTION_ROOM_STATUS,
  DELETE_AUCTION_ROOM,
  getAuctionRoomBidsInput,
  GET_ALL_AUCTION_ROOM_BIDS,
  GET_ALL_AUCTION_ROOM_DEPOSITORS,
  getAuctionRoomDepositInput,
  GET_ALL_AUCTION_ROOM_TRANSACTION,
  getAuctionRoomTransactionInput,
} from './Types'

export const getAllAuctionRoom = (data: getAuctionRoomInput) => ({
  type: GET_ALL_AUCTION_ROOM,
  payload: data,
})

export const changeAuctionRoomStatus = (data: AuctionRoomStatusInput) => ({
  type: CHANGE_AUCTION_ROOM_STATUS,
  payload: data,
})

export const deleteAuctionRoom = (data: string) => ({
  type: DELETE_AUCTION_ROOM,
  payload: data,
})

export const getAllAuctionRoomBids = (data: getAuctionRoomBidsInput) => ({
  type: GET_ALL_AUCTION_ROOM_BIDS,
  payload: data,
})

export const getAllAuctionRoomDepositors = (data: getAuctionRoomDepositInput) => ({
  type: GET_ALL_AUCTION_ROOM_DEPOSITORS,
  payload: data,
})

export const getAllAuctionRoomTransactions = (data: getAuctionRoomTransactionInput) => ({
  type: GET_ALL_AUCTION_ROOM_TRANSACTION,
  payload: data,
})
