import {
  BoatTypesData,
  CREATE_MARINA_TYPES,
  GET_MARINA_TYPES,
  CLEAR_MARINA_TYPES_FLAG,
  BoatTypes,
  UPDATE_MARINA_TYPES,
  DELETE_MARINA_TYPES,
  EDIT_BOAT_TYPES,
} from './Types'

export function createMarinaType(data: BoatTypesData) {
  return {
    type: CREATE_MARINA_TYPES,
    payload: data,
  }
}

export function getMarinaTypes(data: any) {
  return {
    type: GET_MARINA_TYPES,
    payload: data,
  }
}

export function clearManageMarinaTypesFlag() {
  return {
    type: CLEAR_MARINA_TYPES_FLAG,
  }
}

export function updateMarinaTypes(data: BoatTypes) {
  return {
    type: UPDATE_MARINA_TYPES,
    payload: data,
  }
}
export function deleteMarinaTypes(data: string) {
  return {
    type: DELETE_MARINA_TYPES,
    payload: data,
  }
}
export function editBoatTypes(data: string) {
  return {
    type: EDIT_BOAT_TYPES,
    payload: data,
  }
}
