import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getUserDocuments, verifyUserDocuments, getDocumentUrl } from './Schema'
import {
  GET_USER_DOCUMENTS_SUCCESS,
  GET_USER_DOCUMENTS_FAILURE,
  GET_USER_DOCUMENTS,
  CHANGE_DOCUMENT_STATUS,
  ActionType,
  CHANGE_DOCUMENT_STATUS_ERROR,
  CHANGE_DOCUMENT_STATUS_SUCCESS,
  GET_DOCUMENT_URL,
  GET_DOCUMENT_URL_ERROR,
  GET_DOCUMENT_URL_SUCCESS,
} from './Type'

function getUserDocumentApi(payload: any) {
  return client
    .query({
      query: getUserDocuments,
      variables: {
        ...payload,
      },
      fetchPolicy: 'no-cache',
    })
    
}

function userDocumentApi(input: any) {
  return client
    .mutate({
      mutation: verifyUserDocuments,
      variables: {
        ...input,
      },
    })
    
}

function* getUserDocumentData(action: any) {
  try {
    const res = yield getUserDocumentApi(action.payload)

    yield put({ type: GET_USER_DOCUMENTS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_USER_DOCUMENTS_FAILURE, error })
  }
}

function* documentData(action: any) {
  try {
    const { role, record, ...noRole } = action.payload
    const res = yield userDocumentApi(noRole)

    yield put({
      type: CHANGE_DOCUMENT_STATUS_SUCCESS,
      payload: {
        res: res.data.verifyUserDocuments,
        document: action.payload.document,
        role: action.payload.role,
        record: action.payload.record,
      },
    })
  } catch (error) {
    yield put({ type: CHANGE_DOCUMENT_STATUS_ERROR, error })
  }
}

function* getUserDocumentSaga() {
  yield takeLatest(GET_USER_DOCUMENTS, getUserDocumentData)
}

function* documentSaga() {
  yield takeLatest(CHANGE_DOCUMENT_STATUS, documentData)
}

function getDocumentUrlApi(payload: any) {
  return client
    .query({
      query: getDocumentUrl,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
    
}

function* getDocumentUrlData(action: any) {
  try {
    const { id, ...noId } = action.payload
    const res = yield getDocumentUrlApi(noId)

    if (action.payload && !action.payload.isDownload) {
      window.open(res.data.getDocumentUrl, '_blank')
    }
    yield put({
      type: GET_DOCUMENT_URL_SUCCESS,
      payload: { url: res.data.getDocumentUrl },
    })
  } catch (error) {
    yield put({ type: GET_DOCUMENT_URL_ERROR, error })
  }
}

function* getDocumentUrlSaga() {
  yield takeLatest(GET_DOCUMENT_URL, getDocumentUrlData)
}

export default function* getAllUserDocumentSaga() {
  yield all([getUserDocumentSaga(), documentSaga(), getDocumentUrlSaga()])
}
