import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card, Switch, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { userState } from '../../../store/login/Types'
import { getFrontUser, clearLoginFlag, changeAccountStatus } from '../../../store/login/Actions'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { deleteAdminUserRole, clearAdminUserRoleFlag, searchUser } from '../../../store/adminUserRole/Action'
import { TitleSearch } from '../../../component/SearchBar'
import { displayDefaultValue } from '../../../helpers/helper'
import { getAllBoatPagination, activeInactive, verifyOrNot, paginationPage, userAllRoles, roleName } from '../../../enums/enums'
import VerifyDocumentCollapse from '../../../component/VerifyDocumentCollapse'
import FilterButton from '../../filters/FilterButton'
import { getDocumentUrlAction, clearDocumentStatus } from '../../../store/dashboard/documentVerify/Action'
import { userRole } from '../../../store/dashboard/frontUsers/Type'

interface Props {
  roles: any[]
  getFrontUser: typeof getFrontUser
  users: any[]
  clearFlag: typeof clearLoginFlag
  location: Location
  changeAccountStatus: typeof changeAccountStatus
  deleteAdminUserRole: typeof deleteAdminUserRole
  clearAdminUserRoleFlag: typeof clearAdminUserRoleFlag
  deleteAdminUserSuccess: boolean
  totalUsersRow: number
  getDocumentUrlAction: typeof getDocumentUrlAction
  getDocumentUrlSuccess: boolean
  getDocumentLink: string
  clearDocumentStatus: typeof clearDocumentStatus
  frontLoad: boolean
  searchUser: typeof searchUser
}

interface State {
  searchedUsers: any[]
  searchedValue: string
  expandKeys: string[] | undefined
}

interface Location {
  state: Object
}
let pageFilterArray: any[]
class Agent extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchedUsers: [],
      searchedValue: '',
      expandKeys: [],
    }
  }

  componentDidMount() {
    // const { roles, getFrontUser } = this.props
    // const role: any = roles.find((item: any) => item.role === 'AGENT')
    pageFilterArray = [
      {
        title: 'User Status',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'userStatus',
      },
      {
        title: 'Activated Account',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'accountActivated',
      },
      {
        title: 'Verified Status',
        filedArray: verifyOrNot,
        listOptions: 'label',
        passed: 'value',
        actionField: 'isVerified',
      },
    ]
    // const input = {
    //   page: getAllBoatPagination.page,
    //   limit: getAllBoatPagination.limit,
    //   roleType: role && role.aliasName,
    // }
    // role && getFrontUser(input)
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { getFrontUser, roles, clearAdminUserRoleFlag, deleteAdminUserSuccess, getDocumentUrlSuccess, clearDocumentStatus } =
      props
    const role: any = roles.find((item: any) => item.role === 'AGENT')
    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
      roleType: role && role.aliasName,
    }
    if (deleteAdminUserSuccess) {
      clearAdminUserRoleFlag()
      setTimeout(() => {
        role && getFrontUser(input)
      }, 100);
    }
    if (getDocumentUrlSuccess) {
      clearDocumentStatus()
    }
    return null
  }

  columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'address',
      render: (text: any, record: tableRecords, index: number) => {
        return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
      },
    },
    {
      title: 'User Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
      render: (text: string, record: tableRecords, index: number) => {
        const { changeAccountStatus } = this.props

        return (
          <Switch
            checked={record.userStatus}
            onChange={() =>
              changeAccountStatus({
                value: record.userStatus,
                column: 'userStatus',
                id: record.id,
              })
            }
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        )
      },
    },
    {
      title: 'Account Status',
      dataIndex: 'accountActivated',
      key: 'accountActivated',
      render: (text: string, record: tableRecords, index: number) => (
        <span>
          {record.accountActivated ? (
            <span className="active-status-text">{'Active'}</span>
          ) : (
            <span className="inactive-status-text">{'Inactive'}</span>
          )}
        </span>
      ),
    },
    {
      title: 'Verified Status',
      dataIndex: 'isVerified',
      key: 'isVerified',
      render: (text: string, record: tableRecords, index: number) => (
        <span>
          {record.isVerified ? (
            <span className="active-status-text"> {'Verified'}</span>
          ) : (
            <span className="inactive-status-text"> {'Unverified'}</span>
          )}
        </span>
      ),
    },
    {
      title: 'View',
      dataIndex: 'name',
      key: 'view',
      render: (text: string, record: tableRecords, index: number) => (
        <>
          <Button>
            <Link to="view-member">View</Link>
          </Button>
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      render: (text: string, record: tableRecords, index: number) => (
        <>
          <Button
            className="btn-delete"
            onClick={() => {
              record && record.id && confirmSubmitHandler(this.props.deleteAdminUserRole, record.id)
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ]

  onSearch = (value: any, role: any) => {
    const { searchUser, getFrontUser } = this.props
    if (value) {
      this.setState({ searchedValue: value })
      searchUser({ searchTerm: value, page: 1, limit: 10, roleType: role?.aliasName })
    } else {
      this.setState({ searchedValue: '' })
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
        roleType: role && role.aliasName,
      }
      role && getFrontUser(input)
    }
  }

  render() {
    const {
      users,
      frontLoad,
      getFrontUser,
      totalUsersRow,
      getDocumentUrlSuccess,
      getDocumentLink,
      getDocumentUrlAction,
      searchUser,
      roles,
    } = this.props
    const role: userRole = roles.find((item: userRole) => item.role === roleName.AGENT)!
    const { searchedValue, expandKeys } = this.state

    return (
      <>
        <Card title="Agent" extra={<TitleSearch onSearch={(value: any) => this.onSearch(value, role)} />}>
          <FilterButton
            filterArray={pageFilterArray}
            triggerAction={getFrontUser}
            extraInput={{ roleType: userAllRoles.AGENT }}
            haveCountry
          />
          <ExpandTable
            columns={this.columns}
            tableData={users}
            expandHtml={false}
            isExpand={false}
            pageAction={searchedValue ? searchUser : getFrontUser}
            page={paginationPage.boat}
            extraInput={{ roleType: userAllRoles.AGENT, searchTerm: searchedValue }}
            totalUsersRow={totalUsersRow}
            expandedRowRender={(record: any) => (
              <VerifyDocumentCollapse
                buttonTitle="Download"
                record={record}
                getDocumentUrlSuccess={getDocumentUrlSuccess}
                getDocumentLink={getDocumentLink}
                getDocumentUrlAction={getDocumentUrlAction}
              />
            )}
            onExpand={(expanded: boolean, record: tableRecords) => {
              const { id } = record
              if (expanded && id) {
                this.setState({ expandKeys: [id] })
              } else {
                this.setState({ expandKeys: [] })
              }
            }}
            rowKey={(record: tableRecords) => record?.id}
            expandedRowKeys={expandKeys}
            goToDashboard
            tableLoading={frontLoad}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: userState) => {
  return {
    roles: state.roleState.roles,
    users: state.adminUserRoleReducer.users,
    totalUsersRow: state.adminUserRoleReducer.totalUsersRow,
    getDocumentLink: state.userDocumentReducer.getDocumentLink,
    getDocumentUrlSuccess: state.userDocumentReducer.getDocumentUrlSuccess,
    deleteAdminUserSuccess: state.adminUserRoleReducer && state.adminUserRoleReducer.deleteAdminUserSuccess,
    frontLoad: state.adminUserRoleReducer.frontLoad,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getFrontUser: (data: string) => dispatch(getFrontUser(data)),
    clearFlag: () => dispatch(clearLoginFlag()),
    changeAccountStatus: (data: any) => dispatch(changeAccountStatus(data)),
    deleteAdminUserRole: (data: string) => dispatch(deleteAdminUserRole(data)),
    clearAdminUserRoleFlag: () => dispatch(clearAdminUserRoleFlag()),
    clearDocumentStatus: () => dispatch(clearDocumentStatus()),
    getDocumentUrlAction: (data: any) => dispatch(getDocumentUrlAction(data)),
    searchUser: (data: any) => dispatch(searchUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Agent)
