import { all, fork } from 'redux-saga/effects'
import rootLogin from './login/UserLoginSagas'
import emailSaga from './staticContent/emailTemplates/Saga'
// import boatSaga from "./boat/BoatSaga";
import roleSaga from './role/RoleSaga'
import pendingAdsSaga from './pendingAds/PendingAdsSaga'
import homeVideoSaga from './staticContent/manageVideos/Saga'
import articleSaga from './staticContent/manageArticles/Saga'
import siteConfigurationSaga from './generalSetting/Saga'
import canadianTaxSaga from './salesEngine/canadianTax/Saga'
import homeAdvertisementsSaga from './staticContent/forgotPassword/Saga'
import smtpSaga from './staticContent/manageSMTP/Saga'
import userGuideSaga from './staticContent/manageUserGuide/Saga'
import faqSaga from './staticContent/manageFAQ/Saga'
import pageSaga from './staticContent/managePages/Saga'
import boatTypeSaga from './staticContent/manageBoatTypes/Saga'
import yatchTypeSaga from './staticContent/manageYatchServiceTypes/Saga'
import manageBanner from './manageBannerImages/manageCommonBanner/saga'
import getAllFrontUsersCountSaga from './dashboard/frontUsers/Saga'
import smsSaga from './staticContent/smsTemplates/Saga'
import boatShowSaga from './staticContent/manageBoatShows/Saga'
import agreementSaga from './manageAgreements/Saga'
import countrySaga from './country/manageCountry/Saga'
import stateSaga from './country/manageState/Saga'
import auctionRoomSaga from './manageAuctionRoom/Saga'
import allBranchesSaga from './manageSurveyorBranch/allBranches/Saga'
import salesEngineSaga from './salesEngine/manageSalesEngine/Saga'
import marinaTypesSaga from './staticContent/manageMarinaTypes/Saga'
import rentTypeSaga from './staticContent/manageRentTypes/Saga'
import getManageAdminUserCountSaga from './dashboard/adminUsers/Saga'
import getManageOtherUserCountSaga from './dashboard/others/Saga'
import getAllAgreementsCountSaga from './dashboard/manageAgreements/Saga'
import adminUserRoleSaga from './adminUserRole/Saga'
import pageInformationSaga from './staticContent/manageRegistrationContent/Saga'
import jobSaga from './staticContent/manageJobs/Saga'
import getAllUserDocumentSaga from './dashboard/documentVerify/Saga'
import costEstimateSaga from './salesEngine/costEstimate/Saga'
import getManageAuctionRoomSaga from './dashboard/auctionRoom/Saga'
import downloadsSaga from './staticContent/manageDownloads/Saga'
import salesEngineHelpContentSaga from './salesEngineHelpContent/Saga'
import rootFeedBack from './form/Sagas'
import surveyDocumentSaga from './salesEngine/surveyDocument/Saga'
import reviewsSaga from './manageReviews/Saga'
import seoSaga from './seo/Saga'
import permissionsSaga from './permissions/PermissionsSaga'
import getManageRentCountSaga from './dashboard/manageRent/Saga'
import getManageSalesEngineCountSaga from './dashboard/manageSalesEngine/Saga'

export default function* rootSaga(): any {
  yield all([
    fork(rootLogin),
    fork(emailSaga),
    fork(roleSaga),
    fork(pendingAdsSaga),
    fork(homeVideoSaga),
    fork(articleSaga),
    fork(siteConfigurationSaga),
    fork(canadianTaxSaga),
    fork(homeAdvertisementsSaga),
    fork(smtpSaga),
    fork(userGuideSaga),
    fork(faqSaga),
    fork(pageSaga),
    fork(boatTypeSaga),
    fork(manageBanner),
    fork(getAllFrontUsersCountSaga),
    fork(smsSaga),
    fork(boatShowSaga),
    fork(agreementSaga),
    fork(countrySaga),
    fork(stateSaga),
    fork(auctionRoomSaga),
    fork(allBranchesSaga),
    fork(salesEngineSaga),
    fork(marinaTypesSaga),
    fork(yatchTypeSaga),
    fork(rentTypeSaga),
    fork(getManageAdminUserCountSaga),
    fork(getManageOtherUserCountSaga),
    fork(getAllAgreementsCountSaga),
    fork(adminUserRoleSaga),
    fork(pageInformationSaga),
    fork(jobSaga),
    fork(getAllUserDocumentSaga),
    fork(costEstimateSaga),
    fork(getManageAuctionRoomSaga),
    fork(downloadsSaga),
    fork(salesEngineHelpContentSaga),
    fork(rootFeedBack),
    fork(surveyDocumentSaga),
    fork(reviewsSaga),
    fork(seoSaga),
    fork(permissionsSaga),
    fork(getManageRentCountSaga),
    fork(getManageSalesEngineCountSaga)
  ])
}
