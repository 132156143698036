import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getAgreementsCounts } from './Schema'
import { GET_AGREEMENTS_COUNT_SUCCESS, GET_AGREEMENTS_COUNT_FAILURE, ActionType, GET_AGREEMENTS_COUNT } from './Type'

function getAgreementsCountApi() {
  return client
    .query({
      query: getAgreementsCounts,

      fetchPolicy: 'no-cache',
    })
}

function* getAgreementsCountData() {
  try {
    const res = yield getAgreementsCountApi()
    yield put({ type: GET_AGREEMENTS_COUNT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_AGREEMENTS_COUNT_FAILURE, error })
  }
}

function* getAgreementsCountSaga() {
  yield takeLatest(GET_AGREEMENTS_COUNT, getAgreementsCountData)
}

export default function* getAllAgreementsCountSaga() {
  yield all([getAgreementsCountSaga()])
}
