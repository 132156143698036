import {
  boatTypesState,
  CREATE_BOAT_TYPES,
  ActionType,
  CREATE_BOAT_TYPES_SUCCESS,
  CLEAR_MANAGE_BOAT_TYPES_FLAG,
  GET_ALL_BOAT_TYPES_FAILURE,
  UPDATE_BOAT_TYPES,
  UPDATE_BOAT_TYPES_SUCCESS,
  UPDATE_BOAT_TYPES_FAILURE,
  DELETE_BOAT_TYPES,
  DELETE_BOAT_TYPES_SUCCESS,
  DELETE_BOAT_TYPES_FAILURE,
  GET_ALL_BOAT_TYPES_SUCCESS,
  GET_ALL_BOAT_TYPES,
  CREATE_BOAT_TYPES_FAILURE,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: boatTypesState = {
  boatTypes: [],
  boatTypeSuccess: false,
  boatTypeError: false,
  deleteSuccess: false,
  deleteError: false,
  boatTypeTotal: 0,
  updateBoatTypeSuccess: false,
}

export const boatTypesReducer = (state = initialState, action: ActionType): boatTypesState => {
  switch (action.type) {
    case CREATE_BOAT_TYPES:
      return {
        ...state,
        boatTypeSuccess: false,
        boatTypeError: false,
      }
    case CREATE_BOAT_TYPES_SUCCESS:
      return {
        ...state,
        boatTypeSuccess: true,
        boatTypeError: false,
      }
    case CREATE_BOAT_TYPES_FAILURE:
      return {
        ...state,
        boatTypeSuccess: false,
        boatTypeError: true,
      }
    case GET_ALL_BOAT_TYPES:
      return {
        ...state,
        boatTypeError: false,
      }
    case GET_ALL_BOAT_TYPES_SUCCESS:
      return {
        ...state,
        boatTypeError: false,
        boatTypes: action.payload.data.getAllBoatTypes.items,
        boatTypeTotal: action.payload.data.getAllBoatTypes.total,
      }
    case GET_ALL_BOAT_TYPES_FAILURE:
      return {
        ...state,
        boatTypeError: true,
      }
    case CLEAR_MANAGE_BOAT_TYPES_FLAG:
      return {
        ...state,
        boatTypeSuccess: false,
        updateBoatTypeSuccess: false,
        boatTypeError: false,
        deleteError: false,
        deleteSuccess: false,
      }
    case UPDATE_BOAT_TYPES:
      return {
        ...state,
        updateBoatTypeSuccess: false,
        boatTypeError: true,
      }
    case UPDATE_BOAT_TYPES_SUCCESS:
      return {
        ...state,
        updateBoatTypeSuccess: true,
        boatTypeError: false,
      }
    case UPDATE_BOAT_TYPES_FAILURE:
      return {
        ...state,
        updateBoatTypeSuccess: false,
        boatTypeError: true,
      }
    case DELETE_BOAT_TYPES:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_BOAT_TYPES_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }

    case DELETE_BOAT_TYPES_FAILURE:
      action.payload.map((item: any) => NotificationWithIcon('error', item.message))
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }
    default:
      return state
  }
}
