import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'

import { Card, Row, Col, Typography, Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { handleSingleFileUpload, handleSingleFileDelete } from '../../../helpers/S3FileUpload'
import History from '../../../History'
import { MEDIA_TYPE, imageExtensions } from '../../../enums/enums'
import NotificationWithIcon from '../../../component/notification/Notification'
import UploadImage from '../../../component/UploadImage'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { getAddMedia, splitUrlFromUnderscore, trimmedValue } from '../../../helpers/helper'
import { createMarinaType, updateMarinaTypes } from '../../../store/staticContent/manageMarinaTypes/Action'
import { createBoatTypes, updateBoatTypes } from '../../../store/staticContent/manageBoatTypes/Action'
import { updateYatchTypes, createYatchTypes } from '../../../store/staticContent/manageYatchServiceTypes/Action'

const { Text } = Typography

const formValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name field is required.'),
  icon: Yup.string().required('Image upload is required.'),
})

interface Props {
  mediaName: string
  pageTitle: string
  iconTitle: string
  listPageLink: string
  imageTitle: string
  successType: boolean
  updateSuccessType: boolean
  iconMediaName: string
  clearTypesFlag: any
  history: typeof History
  updateAction: typeof updateMarinaTypes | typeof updateBoatTypes | typeof updateYatchTypes
  createAction: typeof createMarinaType | typeof createBoatTypes | typeof createYatchTypes
  editedData: any
  popupTitle: string
}
interface State {
  ImageType: {
    name: string
    icon: string
    thumbnailIcon: string
    id: string
    addMedia: any[]
  }
  loading: boolean
}

class CreateCategoryIndex extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      ImageType: {
        name: '',
        icon: '',
        thumbnailIcon: '',
        id: '',
        addMedia: [],
      },
      loading: false,
    }
  }

  componentDidMount() {
    const { clearTypesFlag } = this.props
    clearTypesFlag()

    this.prepareValue()
  }

  prepareValue = () => {
    const { editedData } = this.props

    if (editedData) {
      // editedData.icon = editedData.icon;
      // editedData.thumbnailIcon = editedData.thumbnailIcon;
      editedData.addMedia = []
      // editedData.id = editedData.id;
      // editedData.name = editedData.name;
      this.setState({ ImageType: editedData })
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = props => {
    const { successType, clearTypesFlag, history, updateSuccessType, listPageLink, popupTitle } = props
    if (successType) {
      NotificationWithIcon('success', `${popupTitle} type created successfully.`)
      clearTypesFlag()
      history.push(listPageLink)
    }
    if (updateSuccessType) {
      NotificationWithIcon('success', `${popupTitle} type Updated successfully.`)
      clearTypesFlag()
      history.push(listPageLink)
    }

    return null
  }

  handleFileUpload = async (file: any, setFieldValue: any, values: any, acceptType: string, imageType: any, name: string) => {
    const { addMedia } = values
    const { mediaName, iconMediaName } = this.props
    const chooseMediaName = imageType === 'icon' ? iconMediaName : mediaName
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(file, name, setFieldValue, addMedia, chooseMediaName, MEDIA_TYPE.IMAGE)
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleRemove = (file: any, setFieldValue: any, values: any, name: string) => {
    const { addMedia } = values

    handleSingleFileDelete(file, name, setFieldValue, addMedia, [values.icon], 'find')
    setFieldValue(name, '')
  }

  onSubmitValues = (values: any) => {
    const { editedData, updateAction, createAction } = this.props
    if (editedData) {
      const { createdAt, updatedAt, __typename, icon, thumbnailIcon, addMedia, ...newValue } = values

      updateAction({ ...newValue, addMedia: getAddMedia(values.addMedia) })
    } else {
      const { icon, thumbnailIcon, addMedia, id, ...noIcon } = values
      createAction({ ...noIcon, addMedia: getAddMedia(values.addMedia) })
    }
  }

  render() {
    const { ImageType, loading } = this.state
    const { editedData, imageTitle, history, pageTitle, iconTitle } = this.props

    return (
      <Fragment>
        <Formik
          initialValues={editedData || ImageType}
          onSubmit={values => this.onSubmitValues(values)}
          validationSchema={formValidationSchema}
        >
          {props => {
            const { handleSubmit, setFieldValue, errors, values } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      {editedData ? 'Edit' : 'Add'} {pageTitle}
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      {!loading ? (
                        <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                          Save
                        </button>
                      ) : (
                        <Button loading={loading} className="ml-15 ant-btn ant-btn-primary">
                          Save
                        </Button>
                      )}
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Name">
                        Name
                      </span>
                      <Field
                        className="ant-input"
                        name="name"
                        placeholder="Name"
                        value={values.name}
                        onBlur={(e: { target: { value: string } }) => setFieldValue('name', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="name" component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={16}>
                      <span>{imageTitle}</span>
                      <div className="d-flex flex-wrap justify-content-center">
                        <div className="addBoatShow-imgUploader">
                          {values.icon ? (
                            <UploadImage
                              defaultFileList={[
                                {
                                  uid: values.icon.id,
                                  name: values.icon.name || 'Thumbnail',
                                  status: 'done',
                                  url: values.icon.url,
                                },
                              ]}
                              openFileDialogOnClick={!values.icon}
                              accept={imageExtensions}
                              withIcon
                              buttonText={imageTitle}
                              selectSiteIcon={(file: any) => {
                                this.handleFileUpload(file, setFieldValue, values, 'image', 'thumbnail', 'icon')
                              }}
                              onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values, 'icon')}
                            />
                          ) : (
                            <UploadImage
                              accept={imageExtensions}
                              openFileDialogOnClick={!values.icon}
                              withIcon
                              buttonText={imageTitle}
                              selectSiteIcon={(file: any) => {
                                this.handleFileUpload(file, setFieldValue, values, 'image', 'thumbnail', 'icon')
                              }}
                              onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values, 'icon')}
                            />
                          )}
                        </div>
                      </div>
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="icon" component="span" />
                      </Text>
                    </Col>

                    <Col className="gutter-box" md={16}>
                      <span>{iconTitle}</span>
                      <div className="d-flex flex-wrap justify-content-center">
                        <div className="addBoatShow-imgUploader">
                          {values && values.thumbnailIcon && values.thumbnailIcon.id && values.thumbnailIcon.url ? (
                            <UploadImage
                              defaultFileList={[
                                {
                                  uid: values.thumbnailIcon.id,
                                  name: values.thumbnailIcon.name || 'Icon',
                                  status: 'done',
                                  url: values.thumbnailIcon.url,
                                },
                              ]}
                              openFileDialogOnClick={!values.thumbnailIcon?.url}
                              accept=".jpg,.png,.gif,.webp,.jpeg,.tif,.tiff,.bmp,.JPG,.GIF,.PNG,.GIF,.WEBP,.JPEG,.TIF,.TIFF,.BMP,.jfif"
                              withIcon
                              buttonText={iconTitle}
                              selectSiteIcon={(file: any) => {
                                this.handleFileUpload(file, setFieldValue, values, 'image', 'icon', 'thumbnailIcon')
                              }}
                              onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values, 'thumbnailIcon')}
                            />
                          ) : (
                            <UploadImage
                              accept=".jpg,.png,.gif,.webp,.jpeg,.tif,.tiff,.bmp,.JPG,.GIF,.PNG,.GIF,.WEBP,.JPEG,.TIF,.TIFF,.BMP,.jfif"
                              withIcon
                              openFileDialogOnClick={!values.thumbnailIcon?.url}
                              buttonText={iconTitle}
                              selectSiteIcon={(file: any) => {
                                this.handleFileUpload(file, setFieldValue, values, 'image', 'icon', 'thumbnailIcon')
                              }}
                              onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values, 'thumbnailIcon')}
                            />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}

export default CreateCategoryIndex
