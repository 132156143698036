import React from 'react'
import { Modal, Button } from 'antd'
import { Field, Formik } from 'formik'
import { trimmedValue } from '../../helpers/helper'

interface ModalProps {
  record?: any
  selectedTypeId?: string
  editCommisionAction: any
  buttonName: string
}

interface ModalState {
  loading: boolean
  visible: boolean
}

class CreateEditCommisionModal extends React.PureComponent<ModalProps, ModalState> {
  state = {
    loading: false,
    visible: false,
  }

  showModal = (data: boolean) => {
    this.setState({
      visible: data,
    })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }

  handleSubmit = (values: any) => {
    const { editCommisionAction } = this.props
    const { role, commision } = values
    editCommisionAction({
      role,
      commision,
    })
    this.showModal(false)
  }

  render() {
    const { visible } = this.state
    const { buttonName, record } = this.props

    return (
      <Formik initialValues={{ role: record.role, commision: record.commision }} onSubmit={this.handleSubmit}>
        {props => {
          const { handleSubmit, setFieldValue, values } = props
          return (
            <div>
              <Button onClick={() => this.showModal(true)} className="btn-view">
                {buttonName}
              </Button>
              <Modal visible={visible} title={`${buttonName} Commision`} footer={false} closable={false}>
                <form onSubmit={handleSubmit}>
                  <Field
                    className="ant-input mb-10"
                    name="role"
                    placeholder="Role"
                    value={values.role}
                    onBlur={(e: { target: { value: string } }) => setFieldValue('role', trimmedValue(e.target.value))}
                    disabled
                  />
                  <Field
                    className="ant-input mb-10"
                    name="commision"
                    placeholder="Commision"
                    value={values.commision}
                    onBlur={(e: { target: { value: string } }) => {
                      setFieldValue('commision', trimmedValue(e.target.value))
                    }}
                  />
                  <Button key="back" className="mr-2" onClick={this.handleCancel}>
                    Return
                  </Button>
                  <button className="ant-btn ant-btn-primary" type="submit">
                    Save
                  </button>
                </form>
              </Modal>
            </div>
          )
        }}
      </Formik>
    )
  }
}

export default CreateEditCommisionModal
