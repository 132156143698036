import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'
import 'react-quill/dist/quill.snow.css' // ES6
import { Card, Row, Col, Typography, Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { handleSingleFileDelete, handleSingleFileUpload } from '../../../helpers/S3FileUpload'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import History from '../../../History'
import NotificationWithIcon from '../../../component/notification/Notification'
import {
  getTripTypes,
  createRentType,
  updateRentType,
  clearRentTypeFlag,
  getSingleRentType,
} from '../../../store/staticContent/manageRentTypes/Actions'
import { rentTypeInput, rentType, LookUp } from '../../../store/staticContent/manageRentTypes/Types'
import './ManageRentTypes.scss'
import Loader from '../../../component/Loading'
import UploadImage from '../../../component/UploadImage'
import WSIcon from '../../../component/WSIcon'
import WSSelect from '../../../component/WSSelect'
import { PlusCircleOutlined } from '@ant-design/icons'
import { imageExtensions, MEDIA_NAME, MEDIA_TYPE } from '../../../enums/enums'
import { getAddMedia, splitUrlFromUnderscore, trimmedValue } from '../../../helpers/helper'

const { Text } = Typography

interface Props {
  history: typeof History
  location: Location
  getTripTypes: typeof getTripTypes
  getSingleRentType: typeof getSingleRentType
  createRentType: typeof createRentType
  updateRentType: typeof updateRentType
  clearRentTypeFlag: typeof clearRentTypeFlag
  tripTypes: LookUp[]
  rentType: rentType
  updateSuccess: boolean
}

interface Location {
  state: any
}

interface State {
  isEditing: boolean
  data: { icon: any } & rentTypeInput
  loading: boolean
}

class CreateMarinTypes extends Component<Props, State> {
  state: State = {
    isEditing: false,
    data: {
      name: '',
      icon: '',
      addMedia: [],
      removeMedia: [],
      tripId: [],
    },
    loading: false,
  }

  componentDidMount() {
    const { getTripTypes, getSingleRentType } = this.props
    const { isEditing, data } = this.state

    isEditing && data.id && getSingleRentType(data.id)
    getTripTypes('Trip')
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (nextProps, prevState) => {
    const { history, location, rentType, updateSuccess, clearRentTypeFlag } = nextProps
    const { isEditing } = prevState

    if (updateSuccess) {
      NotificationWithIcon('success', `Rent type ${isEditing ? 'updated' : 'created'} successfully.`)
      history.goBack()
      clearRentTypeFlag()
    } else if (location.state?.id) {
      return {
        isEditing: true,
        data: {
          ...prevState.data,
          id: location.state.id,
          name: rentType.name,
          icon: rentType.thumbnailIcon.url,
          tripId: rentType.tripId.map(trip => trip.id),
        },
      }
    }

    return null
  }

  handleImageUpload = async (file: any, setFieldValue: any, values: any, acceptType: string, imageType: any, name: string) => {
    const { addMedia } = values
    const { RENTTYPEICON: mediaName, RENTTHUMBNAILTYPEICON: iconMediaName } = MEDIA_NAME
    const chooseMediaName = imageType === 'icon' ? iconMediaName : mediaName
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(file, name, setFieldValue, addMedia, chooseMediaName, MEDIA_TYPE.IMAGE)
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleImageRemove = (file: any, setFieldValue: any, values: any, name: string) => {
    const { addMedia } = values
    handleSingleFileDelete(file, name, setFieldValue, addMedia, [values.icon], 'find')
    setFieldValue(name, '')
  }

  handleSubmit = async (values: any) => {
    const { isEditing } = this.state
    const { createRentType, updateRentType } = this.props
    const { icon, ...validProps } = values
    const validPropsFixed = { ...validProps, addMedia: getAddMedia(values.addMedia) }
    isEditing ? updateRentType(validPropsFixed) : createRentType(validPropsFixed)
  }

  render() {
    const { isEditing, data: stateData, loading } = this.state
    const { history, tripTypes, rentType } = this.props

    return (
      <Fragment>
        {isEditing && !rentType?.id ? (
          <Loader />
        ) : (
          <Formik
            initialValues={{ ...stateData }}
            onSubmit={this.handleSubmit}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required.'),
              icon: Yup.string().required('Icon is required.'),
              tripId: Yup.array(Yup.string()).required('Trip type is required.'),
            })}
          >
            {props => {
              const { handleSubmit, setFieldValue, values } = props

              console.log(props.errors)

              return (
                <form onSubmit={handleSubmit}>
                  <Card
                    title={
                      <span>
                        <WSIcon type={<PlusCircleOutlined />} />
                        Add Rent Type
                      </span>
                    }
                    extra={
                      <>
                        <Button onClick={() => history.goBack()}>Back</Button>
                        {!loading ? (
                          <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                            Save
                          </button>
                        ) : (
                          <Button loading={loading} className="ml-15 ant-btn ant-btn-primary">
                            Save
                          </Button>
                        )}
                      </>
                    }
                  >
                    <Row gutter={16}>
                      <Col className="gutter-box" md={12}>
                        <span className="ant-form-item-required" title="Name">
                          Name
                        </span>
                        <Field
                          className="ant-input"
                          name="name"
                          placeholder="Name"
                          value={values.name}
                          onBlur={(e: { target: { value: string } }) => setFieldValue('name', trimmedValue(e.target.value))}
                        />
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name="name" component="span" />
                        </Text>
                      </Col>

                      <Col className="gutter-box" md={12}>
                        <span className="ant-form-item-required" title="tripId">
                          Trip Type
                        </span>

                        <WSSelect
                          mode="multiple"
                          value={values.tripId}
                          onChangeText={(value: any) => setFieldValue('tripId', value)}
                          arrayList={tripTypes}
                          listOptions="alias"
                          passed="id"
                        />

                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name="tripId" component="span" />
                        </Text>
                      </Col>

                      <Col className="gutter-box" md={12}>
                        <div className="d-flex flex-wrap justify-content-center">
                          <div className="addBoatShow-imgUploader">
                            <UploadImage
                              accept={imageExtensions}
                              withIcon
                              buttonText="Rent Type Image"
                              selectSiteIcon={(file: any) => {
                                this.handleImageUpload(file, setFieldValue, values, 'image', 'icon', 'icon')
                              }}
                              onRemove={(rem: any) => this.handleImageRemove(rem, setFieldValue, values, 'thumbnailIcon')}
                            />
                          </div>
                        </div>
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name="icon" component="span" />
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </form>
              )
            }}
          </Formik>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  tripTypes: state.rentTypeReducer.tripTypes,
  rentType: state.rentTypeReducer.rentType,
  updateSuccess: state.rentTypeReducer.rentTypeSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTripTypes: (data: string) => dispatch(getTripTypes(data)),
  getSingleRentType: (data: string) => dispatch(getSingleRentType(data)),
  createRentType: (data: rentTypeInput) => dispatch(createRentType(data)),
  updateRentType: (data: rentTypeInput) => dispatch(updateRentType(data)),
  clearRentTypeFlag: () => dispatch(clearRentTypeFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateMarinTypes)
