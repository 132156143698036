export const GET_ROLES = 'GET_ROLES'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE'

export const GET_USERS_COUNT_ROLE_WISE = 'GET_USERS_COUNT_ROLE_WISE'
export const GET_USERS_COUNT_ROLE_WISE_SUCCESS = 'GET_USERS_COUNT_ROLE_WISE_SUCCESS'
export const GET_USERS_COUNT_ROLE_WISE_FAILURE = 'GET_USERS_COUNT_ROLE_WISE_FAILURE'

export const CLEAR_ROLES_FLAG = 'CLEAR_ROLES_FLAG'

export interface roleState {
  success: Boolean
  error: Boolean
  roles: []
  roleCounts: []
  roleWiseCountSuccess: Boolean
  roleWiseCountError: Boolean
}

export interface Role {
  role: string
  aliasName: string
  id: string
  type: string
}

export interface roleStateData {
  success: Boolean
  roles: []
  roleCounts: []
}

interface getRoles {
  type: typeof GET_ROLES
}

interface getRoleSuccess {
  type: typeof GET_ROLES_SUCCESS
  payload: any
}

interface getRoleFailure {
  type: typeof GET_ROLES_FAILURE
}

interface getUserCountByRole {
  type: typeof GET_USERS_COUNT_ROLE_WISE
  payload: any
}

interface getUserCountByRoleSuccess {
  type: typeof GET_USERS_COUNT_ROLE_WISE_SUCCESS
  payload: any
}

interface getUserCountByRoleFailure {
  type: typeof GET_USERS_COUNT_ROLE_WISE_FAILURE
  payload: any
}

interface clearRoleFlag {
  type: typeof CLEAR_ROLES_FLAG
}

export type ActionType =
  | getRoles
  | getRoleSuccess
  | getRoleFailure
  | clearRoleFlag
  | getUserCountByRole
  | getUserCountByRoleSuccess
  | getUserCountByRoleFailure
