import { ActionType, manageRentCountState, GET_MANAGE_RENT_COUNT, GET_MANAGE_RENT_COUNT_SUCCESS, GET_MANAGE_RENT_COUNT_FAILURE} from "./Type";

const initialState: manageRentCountState = {
    manageRentCount: [],
    manageRentCountSuccess: false,
    manageRentCountFailure: false
}

export const manageRentCountReducer = (state = initialState, action: ActionType): manageRentCountState => {
    switch(action.type){
        case GET_MANAGE_RENT_COUNT:
            return {
                ...state,
                manageRentCountSuccess:false,
                manageRentCountFailure: false
            }

        case GET_MANAGE_RENT_COUNT_SUCCESS:
            return {
                ...state,
                manageRentCountSuccess:true,
                manageRentCountFailure: false ,
                manageRentCount: action.payload
            }    

        case GET_MANAGE_RENT_COUNT_FAILURE:
            return {
                ...state,
                manageRentCountSuccess:false,
                manageRentCountFailure: true
            }

        default:
            return state    
    }
}