import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getAdminOtherCounts, getCurrencyRates, getSidebarVerificationAlertCount } from './Schema'
import {
  GET_ADMIN_OTHER_COUNT_SUCCESS,
  GET_ADMIN_OTHER_COUNT_FAILURE,
  GET_ADMIN_OTHER_COUNT,
  GET_CURRENCY_RATES,
  ActionType,
  GET_CURRENCY_RATES_SUCCESS,
  GET_CURRENCY_RATES_FAILURE,
  GET_DASHBOAD_VERIFICATION_ALERT,
  GET_DASHBOAD_VERIFICATION_ALERT_SUCCESS,
  GET_DASHBOAD_VERIFICATION_ALERT_FAILURE,
} from './Type'

function getAdminOtherCountApi() {
  return client
    .query({
      query: getAdminOtherCounts,
      fetchPolicy: 'no-cache',
    })
}

function currencyRatesApi() {
  return client
    .query({
      query: getCurrencyRates,
      fetchPolicy: 'no-cache',
    })
}

function dashboardVerificationAlertApi() {
  return client
      .query({
        query: getSidebarVerificationAlertCount,
        fetchPolicy: 'no-cache'
      })   
}

function* getAdminOtherCountData() {
  try {
    const res = yield getAdminOtherCountApi()
    yield put({ type: GET_ADMIN_OTHER_COUNT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_ADMIN_OTHER_COUNT_FAILURE, error })
  }
}

function* currencyRatesData(action: ActionType) {
  try {
    const res = yield currencyRatesApi()
    if (res.data.getCurrencyRates) {
      localStorage.setItem('currencyRates', JSON.stringify(res.data.getCurrencyRates.data))
      yield put({
        type: GET_CURRENCY_RATES_SUCCESS,
        payload: res.data.getCurrencyRates,
      })
    }
  } catch (error) {
    yield put({ type: GET_CURRENCY_RATES_FAILURE, error })
  }
}

function* verificationAlertData(action: ActionType) {
  try {
    const res = yield dashboardVerificationAlertApi()
    // if(res.data.getDashboardVerificationAlerts){
      yield put({
        type: GET_DASHBOAD_VERIFICATION_ALERT_SUCCESS,
        payload: res.data.getDashboardVerificationAlerts
      })
    // }
  } catch (error) {
    yield put({type: GET_DASHBOAD_VERIFICATION_ALERT_FAILURE, error})
  }
}

function* getAdminOtherCountSaga() {
  yield takeLatest(GET_ADMIN_OTHER_COUNT, getAdminOtherCountData)
}

function* currencyRatesSaga() {
  yield takeLatest(GET_CURRENCY_RATES, currencyRatesData)
}

function* verificationAlertSaga(){
  yield takeLatest(GET_DASHBOAD_VERIFICATION_ALERT, verificationAlertData)
}

export default function* getManageOtherUserCountSaga() {
  yield all([getAdminOtherCountSaga(), currencyRatesSaga(), verificationAlertSaga()])
}
