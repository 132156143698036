import NotificationWithIcon from '../../../component/notification/Notification'
import {
  ActionType,
  SurveyDocumentState,
  GET_SURVEY_DOCUMENT,
  GET_SURVEY_DOCUMENT_SUCCESS,
  GET_SURVEY_DOCUMENT_FAILURE,
  CREATE_SURVEY_DOCUMENT,
  CREATE_SURVEY_DOCUMENT_SUCCESS,
  CREATE_SURVEY_DOCUMENT_FAILURE,
  CLEAR_SURVEY_DOCUMENT_FLAG,
} from './Types'

const initialState: SurveyDocumentState = {
  surveyDocument: {},
  createError: false,
  createSuccess: false,
  getError: false,
  getSuccess: false,
}

export function surveyDocumentReducer(state = initialState, action: ActionType): SurveyDocumentState {
  switch (action.type) {
    case GET_SURVEY_DOCUMENT:
      return {
        ...state,
      }

    case GET_SURVEY_DOCUMENT_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        surveyDocument: action.payload,
      }

    case GET_SURVEY_DOCUMENT_FAILURE:
      return {
        ...state,
        getError: true,
      }

    case CREATE_SURVEY_DOCUMENT:
      return {
        ...state,
      }

    case CREATE_SURVEY_DOCUMENT_SUCCESS:
      return {
        ...state,
        createSuccess: true,
        surveyDocument: action.payload,
      }

    case CREATE_SURVEY_DOCUMENT_FAILURE:
      return {
        ...state,
        createError: true,
      }

    case CLEAR_SURVEY_DOCUMENT_FLAG:
      return {
        ...state,
        createError: false,
        createSuccess: false,
        getSuccess: false,
        getError: false,
      }

    default:
      return state
  }
}
