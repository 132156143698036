import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { getAllBoatPagination, pagination, paginationPage } from '../../../enums/enums'
import { getAllPages, deletePages, clearPagesFlag } from '../../../store/staticContent/managePages/Action'
import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData } from '../../../helpers/helper'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'

interface Props {
  type: string
  getAllPages: typeof getAllPages
  page: []
  history: typeof History
  deletePages: typeof deletePages
  clearPagesFlag: typeof clearPagesFlag
  pageSuccess: boolean
  deleteSuccess: boolean
  totalPage: number
  isPageLoading: boolean
  match: {
    params?: any
  }
}

interface State {
  getPageInfo: {
    page: number
    limit: number
  }
  searchedValue: string
  type: string
}

interface PageState {
  pageReducer: pageReducer
}

interface pageReducer {
  page: []
  pageSuccess: boolean
  deleteSuccess: boolean
  totalPage: number
  isPageLoading: boolean
}

class ManagePage extends Component<Props, State> {
  state = {
    getPageInfo: {
      page: pagination.page,
      limit: pagination.limit,
    },
    type: '',
    searchedValue: '',
    columns: [
      {
        title: 'Page Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a: any, b: any) => a.title.length - b.title.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deletePages } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editPage(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deletePages, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    console.log('dsdas')
    const {
      pageSuccess,
      clearPagesFlag,
      getAllPages,
      deleteSuccess,
      match: { params },
    } = props

    let newState: any = { ...state }

    if (params.type !== state.type) {
      getAllPages({ ...state.getPageInfo, type: params.type })

      newState = {
        ...newState,
        isTypeChange: false,
        type: params.type,
      }
    }

    if (pageSuccess) {
      clearPagesFlag()
    }

    if (deleteSuccess) {
      getAllPages({ ...state.getPageInfo, type: params.type })
      clearPagesFlag()
    }

    return newState
  }

  componentDidMount() {
    const {
      getAllPages,
      match: {
        params: { type },
      },
    } = this.props
    // const { getPageInfo } = this.state
    // const pageType = { type }
    this.setState({ type })
    // getAllPages({ ...getPageInfo, ...pageType })
  }

  editPage = (record: tableRecords) => {
    const { history } = this.props
    history.push(`/create-page/${this.state.type}`, { page: { record: record, isEdit: true } })
  }

  onSearch = (value: any) => {
    const { getAllPages } = this.props
    const { type } = this.state

    if (value) {
      this.setState({ searchedValue: value })
      getAllPages({ ...getAllBoatPagination, searchTerm: value, type })
    } else {
      this.setState({ searchedValue: '' })
      getAllPages({ ...getAllBoatPagination, type })
    }
  }

  render() {
    const { columns, searchedValue, type } = this.state
    const { page, totalPage, getAllPages, isPageLoading } = this.props

    return (
      <Card
        title={this.props?.match?.params?.type !== 'salesEngine' ? "Manage Pages" : "Manage SalesEngine Pages"}
        extra={
          <div className="d-flex flex-wrap">
            <div className="mr-10 mt-8">
              <TitleSearch onSearch={this.onSearch} />
            </div>
            <Button className="btn-add mt-8">
              <Link to={`/create-page/${this.props?.match?.params?.type}`}>
                <WSIcon type={<PlusOutlined />} /> Add Page
              </Link>
            </Button>
          </div>
        }
      >
        <ExpandTable
          tableLoading={isPageLoading}
          columns={columns}
          tableData={page}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={totalPage}
          pageAction={getAllPages}
          page={paginationPage.boat}
          extraInput={{ searchTerm: searchedValue, type }}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: PageState) => ({
  page: state.pageReducer.page,
  isPageLoading: state.pageReducer.isPageLoading,
  totalPage: state.pageReducer.totalPage,
  pageSuccess: state.pageReducer && state.pageReducer.pageSuccess,
  deleteSuccess: state.pageReducer && state.pageReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllPages: (data: any) => dispatch(getAllPages(data)),
  deletePages: (data: string) => dispatch(deletePages(data)),
  clearPagesFlag: () => dispatch(clearPagesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagePage)
