import React from "react"
import { Switch } from 'antd'
import { useDispatch } from "react-redux"
import { tableRecords } from '../../../../component/ExpandTable'
import { updateSeoMetaTagAction } from "../../../../store/seo/Action"

interface ActiveTableCellProps {
    active: boolean;
    record: tableRecords;
}

const ActiveTableCell = ({ active, record }: ActiveTableCellProps) => {
    const [checked, setChecked] = React.useState(active);
    const dispatch = useDispatch();

    const onChange = (isChecked: boolean) => {
        setChecked(isChecked);
        dispatch(updateSeoMetaTagAction({
            ...record,
            active: isChecked
        }));
    }

    return (
        <Switch checked={checked} onChange={onChange} />
    )
}

export default ActiveTableCell