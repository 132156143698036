import {
  homeVideoData,
  CREATE_CATEGORY_WISE_VIDEO,
  GET_CATEGORY_WISE_VIDEOS,
  CLEAR_MANAGE_VIDEO_FLAG,
  homeVideo,
  UPDATE_CATEGORY_WISE_VIDEO,
  DELETE_CATEGORY_WISE_VIDEO,
  GET_ALL_VIDEOS_LIST,
} from './Types'

export function createHomeVideo(data: homeVideoData) {
  return {
    type: CREATE_CATEGORY_WISE_VIDEO,
    payload: data,
  }
}

export function getAllVideosList() {
  return {
    type: GET_ALL_VIDEOS_LIST,
  }
}

export function getAllHomeVideos(data: any) {
  return {
    type: GET_CATEGORY_WISE_VIDEOS,
    payload: data,
  }
}

export function clearManageVideoFlag() {
  return {
    type: CLEAR_MANAGE_VIDEO_FLAG,
  }
}

export function updateHomeVideo(data: homeVideo) {
  return {
    type: UPDATE_CATEGORY_WISE_VIDEO,
    payload: data,
  }
}
export function deleteHomeVideo(data: string) {
  return {
    type: DELETE_CATEGORY_WISE_VIDEO,
    payload: data,
  }
}
