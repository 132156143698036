import {
  GET_FRONT_USERS_COUNT,
  GET_FRONT_USERS_BOAT_COUNT,
  GET_FRONT_USERS_PENDING_COUNT,
  CLEAR_FRONT_USERS_COUNT_FLAG,
  GO_TO_DASHBOARD,
  CLEAR_GO_TO_DASHBOARD_FLAG,
  FRONT_USER_WISE_VERIFICATION,
} from './Type'

export function getFrontUsersCount() {
  return {
    type: GET_FRONT_USERS_COUNT,
  }
}

export function getFrontUsersBoatCount() {
  return {
    type: GET_FRONT_USERS_BOAT_COUNT,
  }
}

export function getFrontUsersPendingCount() {
  return {
    type: GET_FRONT_USERS_PENDING_COUNT,
  }
}

export function clearFrontUserCountFlag() {
  return {
    type: CLEAR_FRONT_USERS_COUNT_FLAG,
  }
}

export function goToDashboardAction(data: any) {
  return {
    type: GO_TO_DASHBOARD,
    payload: data,
  }
}

export function clearGoToDashboard() {
  return {
    type: CLEAR_GO_TO_DASHBOARD_FLAG,
  }
}

export function getUserWiseVerificationCount(){
  return {
    type: FRONT_USER_WISE_VERIFICATION
  }
}