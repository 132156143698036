import React from 'react'
import ExpandTable from '../../component/ExpandTable'
import { Card } from 'antd'
import { getActivityLogs } from '../../store/pendingAds/Action'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { adminLogsMethodTypes, adminLogsSections, getAllBoatPagination, nullableFilters, paginationPage } from '../../enums/enums'
import { dateStringFormate, displayDefaultValue } from '../../helpers/helper'
import Loader from '../../component/Loading'
import FilterButton from '../filters/FilterButton'
import { TitleSearch } from '../../component/SearchBar'

let pageFilterArray: any = []

export interface ActivityLogProps {
  getActivityLogs: typeof getActivityLogs
  activityLog: any[]
  totalActivityLogRow: number
  isLoading: boolean
}

interface ActivityLogsState {
  searchedValue: string;
  page: number;
  limit: number;
  methodType: number;
  moduleName: number;
}
class ActivityLogs extends React.Component<ActivityLogProps, ActivityLogsState> {
  constructor(props: ActivityLogProps){
    super(props)
    this.state = {
      searchedValue: "",
      page:getAllBoatPagination.page,
      limit:getAllBoatPagination.limit,
      methodType:0,
      moduleName:0
    }
  }
  columns = [
    {
      title: 'Logged At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <> {dateStringFormate(text)} </>,
    },
    {
      title: 'User Name',
      dataIndex: 'user',
      key: 'user',
      render: (text: any) => (
        <>
          {' '}
          {text && displayDefaultValue(text.firstName)} {text && text.lastName}{' '}
        </>
      ),
    },
    {
      title: 'Module Name',
      dataIndex: 'moduleName',
      key: 'moduleName',
    },
    {
      title: 'Method Type',
      dataIndex: 'methodType',
      key: 'methodType',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ]

  // componentDidMount() {
  //   const { getActivityLogs } = this.props
  //   getActivityLogs(getAllBoatPagination)
  // }
  componentDidMount() {
    this.props.getActivityLogs({})
    pageFilterArray = [
      {
        title: 'Section',
        filedArray: adminLogsSections,
        listOptions: 'label',
        passed: 'value',
        actionField: 'moduleName',
      },
      {
        title: 'Footage',
        filedArray: adminLogsMethodTypes,
        listOptions: 'label',
        passed: 'value',
        actionField: 'methodType',
      },
      
    ]
  }

  onSearch = (value: any) => {
    console.log('value',value);
    const {getActivityLogs} = this.props
    const input = {
      ...getAllBoatPagination
    }

    if(value){
      this.setState({searchedValue: value})
      getActivityLogs({...input, searchTerm: value})
    } else {
      this.setState({searchedValue: ""})
      getActivityLogs(input)
    }
  }

  onExpandTableChange = (value: any) => {
    this.setState({page: value.page, limit: value.limit })
  }

  onFilterButtonChange = (value: any) => {
    this.setState({
      methodType: value.methodType, 
      moduleName:value.moduleName,
      page:getAllBoatPagination.page,
      limit:getAllBoatPagination.limit
    })
  }

  onActionChange = (value: any) => {
    const {methodType,moduleName} = this.state
    if(value.methodType === undefined){
      this.onExpandTableChange(value)
      this.props.getActivityLogs({
        page: value.page, 
        limit: value.limit,
        methodType:methodType,
        moduleName:moduleName
      })
    }else{
      this.onFilterButtonChange(value)
      this.props.getActivityLogs({
        page:getAllBoatPagination.page,
        limit:getAllBoatPagination.limit,
        methodType: value.methodType,
        moduleName:value.moduleName
      })
    }
  }
  
  render() {
    const { activityLog, totalActivityLogRow, isLoading } = this.props
    return (
      <Card title="Activity Logs" extra={<TitleSearch onSearch={this.onSearch} />}>
        <FilterButton filterArray={pageFilterArray} triggerAction={this.onActionChange} />
        <ExpandTable
          columns={this.columns}
          tableData={activityLog}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={totalActivityLogRow}
          pageAction={this.onActionChange}
          page={paginationPage.boat}
          tableLoading={isLoading}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    activityLog: state.pendingAdsState && state.pendingAdsState.activityLog,
    isLoading: state.pendingAdsState && state.pendingAdsState.isLoading,
    totalActivityLogRow: state.pendingAdsState && state.pendingAdsState.totalActivityLogRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getActivityLogs: (data: any) => dispatch(getActivityLogs(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogs)
