import gql from 'graphql-tag'

export const getFrontUsersCount = gql`
  {
    getFrontUsersCount {
      roleCounts
      total
    }
  }
`

export const getFrontUserBoatsCount = gql`
  {
    getFrontUserBoatsCount {
      roleCounts
      total
    }
  }
`

export const getFrontUserPendingCount = gql`
  {
    getFrontUserPendingCount {
      roleCounts
      total
    }
  }
`

export const autoLogin = gql`
  mutation adminToUserLogin($input: AutoLoginInput!) {
    adminToUserLogin(input: $input) {
      id
      user
      autoLoginToken
      status
    }
  }
`

export const getUserWiseVerificationCountSchema = gql`
  query getFrontVerificationCount{
    getFrontVerificationCount {
      pendingMemeberVerification
      pendingBoatownerVerifaication
      pendingBrokerNdealerVerifaication
      pendingBoatMfgVerifaication
      pendingServiceNmaintanenceVerifaication
      pendingSurveyorVerifaication,
      pendingMarinaVerifaication
      pendingRentVerifaication
      pendingYachtVerifaication
      pendingAgentVerifaication
    }
  }
`