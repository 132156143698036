import {
  ActionType,
  rentTypeState,
  GET_ALL_TRIP_TYPES,
  GET_ALL_TRIP_TYPES_SUCCESS,
  GET_ALL_TRIP_TYPES_FAILURE,
  GET_ALL_RENT_TYPES,
  GET_ALL_RENT_TYPES_SUCCESS,
  GET_ALL_RENT_TYPES_FAILURE,
  ADD_RENT_TYPE,
  ADD_RENT_TYPE_SUCCESS,
  ADD_RENT_TYPE_FAILURE,
  GET_SINGLE_RENT_TYPE,
  GET_SINGLE_RENT_TYPE_SUCCESS,
  GET_SINGLE_RENT_TYPE_FAILURE,
  UPDATE_RENT_TYPE,
  UPDATE_RENT_TYPE_SUCCESS,
  UPDATE_RENT_TYPE_FAILURE,
  DELETE_RENT_TYPE,
  DELETE_RENT_TYPE_SUCCESS,
  DELETE_RENT_TYPE_FAILURE,
  CLEAR_RENT_TYPE_FLAG,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: rentTypeState = {
  tripTypes: [],
  rentTotalRow: 0,
  rentType: {
    id: '',
    name: '',
    thumbnailIcon: { url: '' },
    tripId: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  rentTypes: [],
  rentTypeSuccess: false,
  rentTypeError: false,

  deleteRentTypeSuccess: false,
  deleteRentTypeError: false,
}

export const rentTypeReducer = (state = initialState, action: ActionType): rentTypeState => {
  switch (action.type) {
    case GET_ALL_TRIP_TYPES:
      return {
        ...state,
        tripTypes: [],
      }

    case GET_ALL_TRIP_TYPES_SUCCESS:
      return {
        ...state,
        tripTypes: action.payload,
      }

    case GET_ALL_TRIP_TYPES_FAILURE:
      return {
        ...state,
        tripTypes: [],
      }

    case GET_ALL_RENT_TYPES:
      return {
        ...state,
        rentTypes: [],
      }

    case GET_ALL_RENT_TYPES_SUCCESS:
      return {
        ...state,
        rentTypes: action.payload.items,
        rentTotalRow: action.payload.total,
      }

    case GET_ALL_RENT_TYPES_FAILURE:
      return {
        ...state,
        rentTypes: [],
      }

    case ADD_RENT_TYPE:
      return {
        ...state,
        rentTypeSuccess: false,
        rentTypeError: false,
      }

    case ADD_RENT_TYPE_SUCCESS:
      return {
        ...state,
        rentTypeSuccess: true,
      }

    case ADD_RENT_TYPE_FAILURE:
      return {
        ...state,
        rentTypeError: true,
      }

    case GET_SINGLE_RENT_TYPE:
      return {
        ...state,
        rentType: {
          id: '',
          name: '',
          thumbnailIcon: { url: '' },
          tripId: [],
          createdAt: new Date(),
          updatedAt: new Date(),
        },
      }

    case GET_SINGLE_RENT_TYPE_SUCCESS:
      return {
        ...state,
        rentType: action.payload,
      }

    case GET_SINGLE_RENT_TYPE_FAILURE:
      const { rentType } = state

      return {
        ...state,
        rentType,
      }

    case UPDATE_RENT_TYPE:
      return {
        ...state,
        rentTypeSuccess: false,
        rentTypeError: false,
      }

    case UPDATE_RENT_TYPE_SUCCESS:
      return {
        ...state,
        rentTypeSuccess: true,
      }

    case UPDATE_RENT_TYPE_FAILURE:
      return {
        ...state,
        rentTypeError: true,
      }

    case DELETE_RENT_TYPE:
      return {
        ...state,
        deleteRentTypeSuccess: false,
        deleteRentTypeError: false,
      }

    case DELETE_RENT_TYPE_SUCCESS:
      const { rentTypes } = state
      NotificationWithIcon('success', 'Record Deleted Successfully')

      return {
        ...state,
        rentTypes: rentTypes.filter(item => item.id !== action.payload.id),
        deleteRentTypeSuccess: true,
      }

    case DELETE_RENT_TYPE_FAILURE:
      return {
        ...state,
        deleteRentTypeError: true,
      }

    case CLEAR_RENT_TYPE_FLAG:
      return {
        ...state,
        rentTypeSuccess: false,
        rentTypeError: false,
        deleteRentTypeSuccess: false,
        deleteRentTypeError: false,
      }

    default:
      return state
  }
}
