export interface boatShowState {
  boatShows: boatShow[]
  boatShowsTotal: number
  isLoading: boolean
  deleteSuccess: boolean
  deleteError: boolean
}

export interface boatShow {
  address: any
  id: string
  title: string
  showDescription: string
  showLogo: string
  adId: string
  latitude: any
  longitude: any
  createdAt: Date
  startDate: Date
  endDate: Date
  status: boolean
  user: {
    id: string
    firstName: string
    middleName: string
    lastName: string
  }
}

export interface getBoatShowInput {
  page: any
  limit: any
  city: string
  country: string
  isAdmin?: boolean
  searchTerm?: string
}
export interface BoatShowStatusInput {
  id?: string
  column: string
  value?: boolean
}

export interface deleteBoatShowInputSuccess {
  record: deleteBoatShowInput
  id: string
}
export interface deleteBoatShowInput {
  id?: string
  note?: string
  fieldName: string
  fieldArray: boatShow[]
}

export const GET_ALL_BOAT_SHOWS = 'GET_ALL_BOAT_SHOWS'
export const GET_ALL_BOAT_SHOWS_SUCCESS = 'GET_ALL_BOAT_SHOWS_SUCCESS'
export const GET_ALL_BOAT_SHOWS_FAILURE = 'GET_ALL_BOAT_SHOWS_FAILURE'

export const CHANGE_BOAT_SHOW_STATUS = 'CHANGE_BOAT_SHOW_STATUS'
export const CHANGE_BOAT_SHOW_STATUS_SUCCESS = 'CHANGE_BOAT_SHOW_STATUS_SUCCESS'
export const CHANGE_BOAT_SHOW_STATUS_FAILURE = 'CHANGE_BOAT_SHOW_STATUS_FAILURE'

// Delete Boat Show
export const DELETE_BOAT_SHOW = 'DELETE_BOAT_SHOW'
export const DELETE_BOAT_SHOW_SUCCESS = 'DELETE_BOAT_SHOW_SUCCESS'
export const DELETE_BOAT_SHOW_FAILURE = 'DELETE_BOAT_SHOW_FAILURE'

export const CLEAR_BOAT_SHOW_FLAG = 'CLEAR_BOAT_SHOW_FLAG'

interface getAllBoatShows {
  type: typeof GET_ALL_BOAT_SHOWS
  payload: any
}
interface getAllBoatShowsSuccess {
  type: typeof GET_ALL_BOAT_SHOWS_SUCCESS
  payload: {
    items: boatShow[]
    total: number
  }
}
interface getAllBoatShowsFailure {
  type: typeof GET_ALL_BOAT_SHOWS_FAILURE
  payload: any
}

interface changeBoatShowStatus {
  type: typeof CHANGE_BOAT_SHOW_STATUS
  payload: BoatShowStatusInput
}
interface changeBoatShowStatusSuccess {
  type: typeof CHANGE_BOAT_SHOW_STATUS_SUCCESS
  column: string
  payload: {
    id: string
    message: string
    statusCode: number
  }
}
interface changeBoatShowStatusFailure {
  type: typeof CHANGE_BOAT_SHOW_STATUS_FAILURE
  payload: any
}

interface deleteBoatShow {
  type: typeof DELETE_BOAT_SHOW
  payload: deleteBoatShowInput
}

interface deleteBoatShowSuccess {
  type: typeof DELETE_BOAT_SHOW_SUCCESS
  payload: deleteBoatShowInputSuccess
}

interface deleteBoatShowFailure {
  error: any
  type: typeof DELETE_BOAT_SHOW_FAILURE
  payload: deleteBoatShowInputSuccess
}

interface clearBoatShowFlag {
  type: typeof CLEAR_BOAT_SHOW_FLAG
  payload: boatShowState
}

export type ActionType =
  | getAllBoatShows
  | getAllBoatShowsSuccess
  | getAllBoatShowsFailure
  | changeBoatShowStatus
  | changeBoatShowStatusSuccess
  | changeBoatShowStatusFailure
  | deleteBoatShow
  | deleteBoatShowSuccess
  | deleteBoatShowFailure
  | clearBoatShowFlag
