import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { client } from '../..'
import {
  GET_MEMBER_FEEDBACK,
  GET_MEMBER_FEEDBACK_SUCCESS,
  GET_MEMBER_FEEDBACK_FAILURE,
  GET_MEMBER_CONTACT_SUCCESS,
  GET_MEMBER_CONTACT_FAILURE,
  GET_MEMBER_CONTACT,
  DELETE_MEMBER_CONTACT,
  DELETE_MEMBER_CONTACT_SUCCESS,
  DELETE_MEMBER_CONTACT_FAILURE,
  GET_NEWS_MULTIMEDIA,
  GET_NEWS_MULTIMEDIA_SUCCESS,
  GET_NEWS_MULTIMEDIA_FAILURE,
  GET_PARTNER_RELATION,
  GET_PARTNER_RELATION_SUCCESS,
  GET_PARTNER_RELATION_FAILURE,
  GET_CAREER,
  GET_CAREER_SUCCESS,
  GET_CAREER_FAILURE,
  GET_COMPLAINT,
  GET_COMPLAINT_SUCCESS,
  GET_COMPLAINT_FAILURE,
  GET_SUGGESTIONS,
  GET_SUGGESTIONS_SUCCESS,
  GET_SUGGESTIONS_FAILURE,
  GET_CUSTOMER_SERVICES,
  GET_CUSTOMER_SERVICES_SUCCESS,
  GET_CUSTOMER_SERVICES_FAILURE,
  DELETE_PARTNER_RELATIONS,
  DELETE_PARTNER_RELATIONS_FAILURE,
  DELETE_PARTNER_RELATIONS_SUCCESS,
  DELETE_NEWS_MULTI,
  DELETE_NEWS_MULTI_FAILURE,
  DELETE_NEWS_MULTI_SUCCESS,
  DELETE_SUGGESTIONS,
  DELETE_SUGGESTIONS_FAILURE,
  DELETE_SUGGESTIONS_SUCCESS,
  DELETE_CUSTOMER_SERVICES,
  DELETE_CUSTOMER_SERVICES_FAILURE,
  DELETE_CUSTOMER_SERVICES_SUCCESS,
} from './Types'
import {
  getAllFeedBack,
  getAllContactUs,
  deleteContactUs,
  getAllApplyJob,
  getAllNewsMultiMedia,
  getAllPartnerRelation,
  getAllComplaints,
  getAllSuggestions,
  getAllCustomerService,
  deletePartnerRelation,
  deleteNewsMultiMedia,
  deleteApplyJob,
  deleteComplaint,
  deleteSuggestion,
  deleteCustomerService,
} from './UserSchema'
import { DELETE_COMPLAINT, DELETE_COMPLAINT_FAILURE, DELETE_COMPLAINT_SUCCESS } from '../adminUserRole/Type'

function getFeedbackApi(data: any) {
  return client
    .query({
      query: getAllFeedBack,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getFeedbackData(action: any) {
  try {
    const data = yield getFeedbackApi(action.payload)
    yield put({
      type: GET_MEMBER_FEEDBACK_SUCCESS,
      payload: data.data.getAllFeedBack,
    })
  } catch (e) {
    yield put({ type: GET_MEMBER_FEEDBACK_FAILURE, e })
  }
}

function* getFeedbackSaga() {
  yield takeEvery(GET_MEMBER_FEEDBACK, getFeedbackData)
}

// contact
function getContactApi(data: any) {
  return client
    .query({
      query: getAllContactUs,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getContactData(action: any) {
  try {
    const data = yield getContactApi(action.payload)
    yield put({
      type: GET_MEMBER_CONTACT_SUCCESS,
      payload: data.data.getAllContactUs,
    })
  } catch (e) {
    yield put({ type: GET_MEMBER_CONTACT_FAILURE, e })
  }
}

function* getContactSaga() {
  yield takeEvery(GET_MEMBER_CONTACT, getContactData)
}

// news multimedia
function getNewsMultimediaApi(data: any) {
  return client
    .query({
      query: getAllNewsMultiMedia,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getNewsMultimediaData(action: any) {
  try {
    const data = yield getNewsMultimediaApi(action.payload)
    yield put({
      type: GET_NEWS_MULTIMEDIA_SUCCESS,
      payload: data.data.getAllNewsMultiMedia,
    })
  } catch (e) {
    yield put({ type: GET_NEWS_MULTIMEDIA_FAILURE, e })
  }
}

function* getNewsMultimediaSaga() {
  yield takeEvery(GET_NEWS_MULTIMEDIA, getNewsMultimediaData)
}

// partner relation
function getPartnerRelationApi(data: any) {
  return client
    .query({
      query: getAllPartnerRelation,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getPartnerRelationData(action: any) {
  try {
    const data = yield getPartnerRelationApi(action.payload)
    yield put({
      type: GET_PARTNER_RELATION_SUCCESS,
      payload: data.data.getAllPartnerRelation,
    })
  } catch (e) {
    yield put({ type: GET_PARTNER_RELATION_FAILURE, e })
  }
}

function* getPartnerRelationSaga() {
  yield takeEvery(GET_PARTNER_RELATION, getPartnerRelationData)
}

// career
function getCareerApi(data: any) {
  return client
    .query({
      query: getAllApplyJob,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getCareerData(action: any) {
  try {
    const data = yield getCareerApi(action.payload)
    yield put({
      type: GET_CAREER_SUCCESS,
      payload: data.data.getAllApplyJob,
    })
  } catch (e) {
    yield put({ type: GET_CAREER_FAILURE, e })
  }
}

function* getCareerSaga() {
  yield takeEvery(GET_CAREER, getCareerData)
}

// complaint
function getComplaintApi(data: any) {
  return client
    .query({
      query: getAllComplaints,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getComplaintData(action: any) {
  try {
    const data = yield getComplaintApi(action.payload)
    yield put({
      type: GET_COMPLAINT_SUCCESS,
      payload: data.data.getComplaintsForAdmin,
    })
  } catch (e) {
    yield put({ type: GET_COMPLAINT_FAILURE, e })
  }
}

function* getComplaintSaga() {
  yield takeEvery(GET_COMPLAINT, getComplaintData)
}

// suggestions
function getSuggestionsApi(data: any) {
  return client
    .query({
      query: getAllSuggestions,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getSuggestionsData(action: any) {
  try {
    const data = yield getSuggestionsApi(action.payload)
    yield put({
      type: GET_SUGGESTIONS_SUCCESS,
      payload: data.data.getSuggestionsForAdmin,
    })
  } catch (e) {
    yield put({ type: GET_SUGGESTIONS_FAILURE, e })
  }
}

function* getSuggestionsSaga() {
  yield takeEvery(GET_SUGGESTIONS, getSuggestionsData)
}

// customer services
function getCustomerServicesApi(data: any) {
  return client
    .query({
      query: getAllCustomerService,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getCustomerServicesData(action: any) {
  try {
    const data = yield getCustomerServicesApi(action.payload)
    yield put({
      type: GET_CUSTOMER_SERVICES_SUCCESS,
      payload: data.data.getCustomerServiceForAdmin,
    })
  } catch (e) {
    yield put({ type: GET_CUSTOMER_SERVICES_FAILURE, e })
  }
}

function* getCustomerServicesSaga() {
  yield takeEvery(GET_CUSTOMER_SERVICES, getCustomerServicesData)
}

// delete contact
function deleteContactApi(data: any) {
  return client
    .query({
      query: deleteContactUs,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* deleteContactData(action: any) {
  try {
    const data = yield deleteContactApi(action.payload)
    yield put({
      type: DELETE_MEMBER_CONTACT_SUCCESS,
      payload: data.data.deleteContactUs,
    })
  } catch (e) {
    yield put({ type: DELETE_MEMBER_CONTACT_FAILURE, e })
  }
}

function* deleteContactSaga() {
  yield takeEvery(DELETE_MEMBER_CONTACT, deleteContactData)
}

// delete news
function deleteNewsMultimediaApi(data: any) {
  return client
    .query({
      query: deleteNewsMultiMedia,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* deleteNewsMultimediaData(action: any) {
  try {
    const data = yield deleteNewsMultimediaApi(action.payload)
    yield put({
      type: DELETE_NEWS_MULTI_SUCCESS,
      payload: data.data.deleteNewsMultiMedia,
    })
  } catch (e) {
    yield put({ type: DELETE_NEWS_MULTI_FAILURE, e })
  }
}

function* deleteNewsMultimediaSaga() {
  yield takeEvery(DELETE_NEWS_MULTI, deleteNewsMultimediaData)
}

// delete partnerRelation
function deletePartnerApi(data: any) {
  return client
    .query({
      query: deletePartnerRelation,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* deletePartnerData(action: any) {
  try {
    const data = yield deletePartnerApi(action.payload)
    yield put({
      type: DELETE_PARTNER_RELATIONS_SUCCESS,
      payload: data.data.deletePartnerRelation,
    })
  } catch (e) {
    yield put({ type: DELETE_PARTNER_RELATIONS_FAILURE, e })
  }
}

function* deletePartnerSaga() {
  yield takeEvery(DELETE_PARTNER_RELATIONS, deletePartnerData)
}

// delete career
function deleteCareerApi(data: any) {
  return client
    .query({
      query: deleteApplyJob,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* deleteCareerData(action: any) {
  try {
    const data = yield deleteCareerApi(action.payload)
    yield put({
      type: DELETE_PARTNER_RELATIONS_SUCCESS,
      payload: data.data.deleteApplyJob,
    })
  } catch (e) {
    yield put({ type: DELETE_PARTNER_RELATIONS_FAILURE, e })
  }
}

function* deleteCareerSaga() {
  yield takeEvery(DELETE_PARTNER_RELATIONS, deleteCareerData)
}

// delete complaint
function deleteComplaintApi(data: any) {
  return client
    .query({
      query: deleteComplaint,
      variables: {
        id: data.input.ids[0],
      },
      fetchPolicy: 'no-cache',
    })
}

function* deleteComplaintData(action: any) {
  try {
    const data = yield deleteComplaintApi(action.payload)
    yield put({
      type: DELETE_COMPLAINT_SUCCESS,
      payload: data.data.removeFlagreport,
    })
  } catch (e) {
    yield put({ type: DELETE_COMPLAINT_FAILURE, e })
  }
}

function* deleteComplaintSaga() {
  yield takeEvery(DELETE_COMPLAINT, deleteComplaintData)
}

// delete suggest
function deleteSuggestionApi(data: any) {
  return client
    .query({
      query: deleteSuggestion,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* deleteSuggestionData(action: any) {
  try {
    const data = yield deleteSuggestionApi(action.payload)
    yield put({
      type: DELETE_SUGGESTIONS_SUCCESS,
      payload: data.data.deleteSuggestion,
    })
  } catch (e) {
    yield put({ type: DELETE_SUGGESTIONS_FAILURE, e })
  }
}

function* deleteSuggestionSaga() {
  yield takeEvery(DELETE_SUGGESTIONS, deleteSuggestionData)
}

// delete suggest
function deleteCustomerServiceApi(data: any) {
  return client
    .query({
      query: deleteCustomerService,
      variables: {
        ...data,
      },
      fetchPolicy: 'no-cache',
    })
}

function* deleteCustomerServiceData(action: any) {
  try {
    const data = yield deleteCustomerServiceApi(action.payload)
    yield put({
      type: DELETE_CUSTOMER_SERVICES_SUCCESS,
      payload: data.data.deleteCustomerService,
    })
  } catch (e) {
    yield put({ type: DELETE_CUSTOMER_SERVICES_FAILURE, e })
  }
}

function* deleteCustomerServiceSaga() {
  yield takeEvery(DELETE_CUSTOMER_SERVICES, deleteCustomerServiceData)
}
export default function* rootFeedBack() {
  yield all([
    fork(getFeedbackSaga),
    fork(getContactSaga),
    fork(deleteContactSaga),
    fork(getNewsMultimediaSaga),
    fork(getPartnerRelationSaga),
    fork(getCareerSaga),
    fork(getComplaintSaga),
    fork(getSuggestionsSaga),
    fork(getCustomerServicesSaga),
    fork(deleteNewsMultimediaSaga),
    fork(deletePartnerSaga),
    fork(deleteCareerSaga),
    fork(deleteComplaintSaga),
    fork(deleteSuggestionSaga),
    fork(deleteCustomerServiceSaga),
  ])
}
