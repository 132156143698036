export interface articlesState {
  article: article
  articles: any[]
  success: boolean
  error: boolean
  deleteSuccess: boolean
  deleteError: boolean
  totalArticlesRow: number
}

export interface article {
  data: any
  id: string
  userId: {
    firstName: string
    lastName: string
  }
  title: string
  titleSlug: string
  description: string
  file: string
  articleViewCount: string
  articleApproved: string
}

export interface articleData {
  type: string
  subject: string
  body: string
}

export interface articleInput {
  id: string
  columnName: string
  value: boolean
  note: string
}

export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAILURE = 'DELETE_ARTICLE_FAILURE'
export const GET_MANAGE_ARTICLES = 'GET_MANAGE_ARTICLES'
export const GET_MANAGE_ARTICLES_SUCCESS = 'GET_MANAGE_ARTICLES_SUCCESS'
export const GET_MANAGE_ARTICLES_FAILURE = 'GET_MANAGE_ARTICLES_FAILURE'

//change article status
export const CHANGE_ARTICLE_STATUS = 'CHANGE_ARTICLE_STATUS'
export const CHANGE_ARTICLE_STATUS_SUCCESS = 'CHANGE_ARTICLE_STATUS_SUCCESS'
export const CHANGE_ARTICLE_STATUS_FAILURE = 'CHANGE_ARTICLE_STATUS_FAILURE'

export const CLEAR_ARTICLE_CONTENT_FLAG = 'CLEAR_ARTICLE_CONTENT_FLAG'

interface deleteArticle {
  type: typeof DELETE_ARTICLE
  payload: articleInput
}

interface deleteArticlesSuccess {
  type: typeof DELETE_ARTICLE_SUCCESS
  payload: any
}
interface deleteArticlesFailure {
  type: typeof DELETE_ARTICLE_FAILURE
  payload: any
}

interface getManageArticles {
  type: typeof GET_MANAGE_ARTICLES
  payload: articleData
}

interface getManageArticlesSuccess {
  type: typeof GET_MANAGE_ARTICLES_SUCCESS
  payload: any
}
interface getManageArticlesFailure {
  type: typeof GET_MANAGE_ARTICLES_FAILURE
  payload: any
}

interface changeArticleStatus {
  type: typeof CHANGE_ARTICLE_STATUS
  payload: any
}

interface changeArticleStatusSuccess {
  type: typeof CHANGE_ARTICLE_STATUS_SUCCESS
  payload: article
}

interface changeArticleStatusFailure {
  type: typeof CHANGE_ARTICLE_STATUS_FAILURE
  payload: string
}

interface clearArticleFlag {
  type: typeof CLEAR_ARTICLE_CONTENT_FLAG
  payload: any
}

export type ActionType =
  | deleteArticle
  | getManageArticles
  | getManageArticlesSuccess
  | getManageArticlesFailure
  | deleteArticlesSuccess
  | deleteArticlesFailure
  | changeArticleStatus
  | changeArticleStatusSuccess
  | changeArticleStatusFailure
  | clearArticleFlag
