import gql from 'graphql-tag'

export const deleteManageArticle = gql`
  mutation deleteArticle($id: String!) {
    deleteArticle(id: $id) {
      id
      title
      titleSlug
      description
      articleViewCount
      articleApproved
    }
  }
`
export const getAllManageArticles = gql`
  query getAllArticles($page: Int, $limit: Int, $articleApproved: Boolean = null, $searchTerm: String) {
    getAllArticles(page: $page, limit: $limit, articleApproved: $articleApproved, searchTerm: $searchTerm) {
      items {
        id
        adId
        user {
          firstName
          lastName
        }
        title
        titleSlug
        description
        files {
          id
          url
          meta
        }
        articleViewCount
        articleApproved
        shortDescription
        status
        createdAt
      }
      total
    }
  }
`

export const changeStatus = gql`
  mutation articleChangeStatus($id: String!, $value: Boolean!, $columnName: String!, $note: String) {
    articleChangeStatus(id: $id, value: $value, columnName: $columnName, note: $note) {
      message
      statusCode
      id
    }
  }
`
