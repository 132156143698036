import {
  ActionType,
  ADD_SITE_CONFIGURATION,
  GET_SITE_CONFIGURATION_SUCCESS,
  siteConfigurationState,
  ADD_SITE_CONFIGURATION_SUCCESS,
  GET_SITE_CONFIGURATION,
  UPDATE_SITE_CONFIGURATION_SUCCESS,
  UPDATE_SITE_CONFIGURATION,
  UPDATE_SITE_CONFIGURATION_FAILURE,
  CLEAR_CONFIG_FLAG,
  GET_SITE_CONFIGURATION_FAILURE,
} from './Types'
import NotificationWithIcon from '../../component/notification/Notification'

const initialState: siteConfigurationState = {
  isLoading: false,
  success: false,
  error: false,
  isFlag: false,
  generalConfigurations: {},
  getSiteConfigSuccess: false,
}

export const siteConfigurationReducer = (state = initialState, action: ActionType): siteConfigurationState => {
  switch (action.type) {
    case ADD_SITE_CONFIGURATION:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      }
    case ADD_SITE_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
        error: false,
        generalConfigurations: action.payload,
      }
    }
    case GET_SITE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isFlag: false,
        getSiteConfigSuccess: true,
        generalConfigurations: action.payload,
      }
    case GET_SITE_CONFIGURATION_FAILURE:
      return {
        ...state,
        isFlag: false,
        getSiteConfigSuccess: false,
      }
    case GET_SITE_CONFIGURATION:
      return {
        ...state,
        getSiteConfigSuccess: false,
        isFlag: true,
      }
    case UPDATE_SITE_CONFIGURATION: {
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      }
    }
    case UPDATE_SITE_CONFIGURATION_SUCCESS: {
      NotificationWithIcon('success', 'Site Configuration updated successfully.')
      return {
        ...state,
        isLoading: false,
        success: true,
        error: false,
        generalConfigurations: action.payload,
      }
    }
    case UPDATE_SITE_CONFIGURATION_FAILURE: {
      NotificationWithIcon('error', 'Something went wrong on update.')
      return {
        ...state,
        isLoading: false,
        success: false,
        error: true,
      }
    }

    case CLEAR_CONFIG_FLAG: {
      return {
        ...state,
        isLoading: false,
        success: false,
        error: false,
        isFlag: false,
        getSiteConfigSuccess: false,
      }
    }

    default:
      return state
  }
}
