import React from 'react'

import { Player, ControlBar } from 'video-react'

const Video = (props: any) => {
  return <Player controls={false} muted autoPlay playsInline loop poster={props.thumbnail} src={props.videoUrl} />
}
export default Video

Video.defaultProperty = {
  thumbnail: '',
}
