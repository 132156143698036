import {
  homeVideoState,
  ActionType,
  CREATE_CATEGORY_WISE_VIDEO,
  CREATE_CATEGORY_WISE_VIDEO_SUCCESS,
  CREATE_CATEGORY_WISE_VIDEO_FAILURE,
  GET_CATEGORY_WISE_VIDEOS,
  GET_CATEGORY_WISE_VIDEOS_SUCCESS,
  GET_CATEGORY_WISE_VIDEOS_FAILURE,
  CLEAR_MANAGE_VIDEO_FLAG,
  UPDATE_CATEGORY_WISE_VIDEO,
  UPDATE_CATEGORY_WISE_VIDEO_SUCCESS,
  UPDATE_CATEGORY_WISE_VIDEO_FAILURE,
  DELETE_CATEGORY_WISE_VIDEO,
  DELETE_CATEGORY_WISE_VIDEO_SUCCESS,
  DELETE_CATEGORY_WISE_VIDEO_FAILURE,
  GET_ALL_VIDEOS_LIST,
  GET_ALL_VIDEOS_LIST_FAILURE,
  GET_ALL_VIDEOS_LIST_SUCCESS,
} from './Types'
import { formateDate } from '../../../helpers/helper'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: homeVideoState = {
  videos: [],
  loadVideo: false,
  success: false,
  error: false,
  deleteError: false,
  deleteSuccess: false,
  allVideosList: [],
  totalVideosRow: 0,
}

export const homeVideoReducer = (state = initialState, action: ActionType): homeVideoState => {
  switch (action.type) {
    case GET_ALL_VIDEOS_LIST:
      return {
        ...state,
        success: false,
        error: false,
      }
    case GET_ALL_VIDEOS_LIST_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        allVideosList: action.payload.getAllVideosList,
      }
    case GET_ALL_VIDEOS_LIST_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }
    case CREATE_CATEGORY_WISE_VIDEO:
      return {
        ...state,
        success: false,
        error: false,
      }
    case CREATE_CATEGORY_WISE_VIDEO_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        allVideosList: [{ ...action.payload.createVideo }, state.allVideosList],
      }
    case CREATE_CATEGORY_WISE_VIDEO_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }
    case GET_CATEGORY_WISE_VIDEOS:
      return {
        ...state,
        success: false,
        error: false,
        loadVideo: true,
      }
    case GET_CATEGORY_WISE_VIDEOS_SUCCESS:
      const data = action.payload.getAdminVideosList.items.map((item: any) => {
        item.updatedAt = formateDate(item.updatedAt)
        return item
      })
      return {
        ...state,
        success: true,
        loadVideo: false,
        error: false,
        videos: data,
        totalVideosRow: action.payload.getAdminVideosList.total,
      }
    case GET_CATEGORY_WISE_VIDEOS_FAILURE:
      return {
        ...state,
        success: false,
        loadVideo: false,
        error: true,
      }
    case CLEAR_MANAGE_VIDEO_FLAG:
      return {
        ...state,
        success: false,
        error: false,
        deleteError: false,
        deleteSuccess: false,
      }
    case UPDATE_CATEGORY_WISE_VIDEO:
      return {
        ...state,
        success: false,
        error: true,
      }
    case UPDATE_CATEGORY_WISE_VIDEO_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
      }
    case UPDATE_CATEGORY_WISE_VIDEO_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }
    case DELETE_CATEGORY_WISE_VIDEO:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_CATEGORY_WISE_VIDEO_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }

    case DELETE_CATEGORY_WISE_VIDEO_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }
    default:
      return state
  }
}
