/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { clear_deleteReview, clear_getReportedReviews, deleteReview, getReportedReviews } from '../../store/pendingAds/Action'
import ExpandTable from '../../component/ExpandTable'
import { Button, Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData, Sort } from '../../helpers/helper'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'

const MARKETS = {
  BOATRENT: 'Rent',
  MARINA: 'Marina',
  YACHT: 'Service',
}

const ManageReviewComplaints = ({
  history,
  getReportedReviews,
  reportedReviews,
  clear_getReportedReviews,
  deleteReview,
  deletedReview,
  clear_deleteReview,
}: any) => {
  const [tableData, setTableData] = useState([] as any[])
  const [totalRows, setTotalRows] = useState(0)
  const [searchedValue, setSearchedValue] = useState('')

  useEffect(() => {
    if (reportedReviews.success) {
      setTableData(reportedReviews.data.items)
      setTotalRows(reportedReviews.data.total)
    }

    if (reportedReviews.success || reportedReviews.failure) {
      clear_getReportedReviews()
    }
  }, [reportedReviews])

  useEffect(() => {
    if (deletedReview.success || deletedReview.failure) {
      clear_deleteReview()
      getReportedReviews()
    }
  }, [deletedReview])

  const viewReports = (moduleId: string) => {
    history.push({ pathname: '/manage-review-complaints/' + moduleId })
  }

  const tableCols = () => [
    {
      title: 'Market',
      dataIndex: 'market',
      key: 'market',
      render: (v: keyof typeof MARKETS) => (v ? MARKETS[v] || 'Boat' : <>&#8212;</>),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.market, b.market),
    },
    {
      title: 'Ad Owner',
      dataIndex: 'adOwner',
      key: 'adOwner',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.adOwner, b.adOwner),
    },
    {
      title: 'Ad Country',
      dataIndex: 'adCountry',
      key: 'adCountry',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.adCountry, b.adCountry),
    },
    {
      title: 'Review Owner',
      dataIndex: 'reviewOwner',
      key: 'reviewOwner',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.reviewOwner, b.reviewOwner),
    },
    {
      title: 'Review',
      dataIndex: 'review',
      key: 'review',
      render: (v: any) => (v ? <LongMsg msg={v} /> : <>&#8212;</>),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.review, b.review),
    },
    {
      title: 'Total Reports',
      dataIndex: 'totalReports',
      key: 'totalReports',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.totalReports, b.totalReports),
    },
    {
      title: 'Actions',
      dataIndex: 'moduleId',
      key: 'moduleId',
      render: (id: any, row: any) => (
        <>
          <Button className={row.totalReports < 10 ? 'btn-view' : 'btn-delete'} onClick={() => viewReports(id)}>
            View Reports
          </Button>
          <Button className="btn-delete ml-1" onClick={() => confirmSubmitHandler(deleteReview, { id })}>
            Delete Review
          </Button>
        </>
      ),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.moduleId, b.moduleId),
    },
  ]

  return (
    <Card title="Manage Review Complaints" extra={<TitleSearch onSearch={setSearchedValue} />}>
      {(() => {
        const data = getSearchedData(tableData, searchedValue)

        return (
          <ExpandTable
            columns={tableCols()}
            tableData={data}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={totalRows}
            tableLoading={reportedReviews.loading}
            pageAction={getReportedReviews}
          />
        )
      })()}
    </Card>
  )
}

const mapStateToProps = (state: any) => {
  return {
    reportedReviews: state.pendingAdsState.reportedReviews,
    deletedReview: state.pendingAdsState.deletedReview,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getReportedReviews: (payload: any) => dispatch(getReportedReviews(payload)),
  clear_getReportedReviews: () => dispatch(clear_getReportedReviews()),
  deleteReview: (payload: any) => dispatch(deleteReview(payload)),
  clear_deleteReview: () => dispatch(clear_deleteReview()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageReviewComplaints)

const LongMsg = ({ msg }: { msg: string }) => {
  const [expandable, setExpandable] = useState(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    setExpanded(msg.length < 100)
    setExpandable(msg.length >= 100)
  }, [msg])

  return (
    <div style={{ display: 'inline-block', width: 300, whiteSpace: 'pre-wrap' }}>
      {expandable ? (
        expanded ? (
          <>
            <em>"{msg}"</em>{' '}
            <div className="reports-more" onClick={() => setExpanded(false)}>
              Read less
            </div>
          </>
        ) : (
          <>
            <em>"{msg.substring(0, 99)}</em>
            {'..." '}
            <span className="reports-more" onClick={() => setExpanded(true)}>
              Read more
            </span>
          </>
        )
      ) : (
        <em>"{msg}"</em>
      )}
    </div>
  )
}
