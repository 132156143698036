import { DELETE_ARTICLE, GET_MANAGE_ARTICLES, CHANGE_ARTICLE_STATUS, CLEAR_ARTICLE_CONTENT_FLAG, articleInput } from './Types'

export function getManageArticles(data: any) {
  return {
    type: GET_MANAGE_ARTICLES,
    payload: data,
  }
}
export function deleteArticle(data: articleInput) {
  return {
    type: DELETE_ARTICLE,
    payload: data,
  }
}

export function changeArticleStatus(data: any) {
  return {
    type: CHANGE_ARTICLE_STATUS,
    payload: data,
  }
}

export function clearArticleFlag() {
  return {
    type: CLEAR_ARTICLE_CONTENT_FLAG,
  }
}
