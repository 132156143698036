import {
  userSaveData,
  LOGIN_USER,
  CLEAR_RECORD,
  GET_ALL_LOOKUPS,
  GET_TRIP_LOOKUPS,
  GET_ALL_TYPES,
  CLEAR_ALL_LOOKUPS,
  EDIT_LOOKUP,
  CREATE_LOOKUP,
  DELETE_LOOKUP,
} from './Types'
import { GET_FRONT_USERS, CHANGE_ACCOUNT_STATUS } from '../adminUserRole/Type'

export function userLogin(user: userSaveData) {
  return {
    type: LOGIN_USER,
    payload: user,
  }
}

export function clearLoginFlag() {
  return {
    type: CLEAR_RECORD,
  }
}

export function getFrontUser(data: any) {
  return {
    type: GET_FRONT_USERS,
    payload: data,
  }
}

export function getAllLookupsAction(data: any) {
  return {
    type: GET_ALL_LOOKUPS,
    payload: data,
  }
}

export function getTripLookupsAction(data: any) {
  return {
    type: GET_TRIP_LOOKUPS,
    payload: data,
  }
}

export function getAllTypesAction(data: any) {
  return {
    type: GET_ALL_TYPES,
    payload: data,
  }
}

export function clearLookUpAction() {
  return {
    type: CLEAR_ALL_LOOKUPS,
  }
}

export function changeAccountStatus(data: any) {
  return {
    type: CHANGE_ACCOUNT_STATUS,
    payload: data,
  }
}

export function editLookupsAction(data: any) {
  return {
    type: EDIT_LOOKUP,
    payload: data,
  }
}

export function createLookupsAction(data: any) {
  return {
    type: CREATE_LOOKUP,
    payload: data,
  }
}

export function deleteLookupsAction(data: any) {
  return {
    type: DELETE_LOOKUP,
    payload: data,
  }
}
