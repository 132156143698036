import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { pagination } from '../../../enums/enums'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { getStates, deleteState, clearStateFlag } from '../../../store/country/manageState/Action'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'

export interface Props {
  getStates: typeof getStates
  states: []
  history: typeof History
  deleteState: typeof deleteState
  clearStateFlag: typeof clearStateFlag
  emailSuccess: boolean
  deleteSuccess: boolean
}

export interface State {}

class ManageStates extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { clearStateFlag, getStates, deleteSuccess } = props

    if (deleteSuccess) {
      const data = {
        page: pagination.page,
        limit: pagination.limit,
        input: {
          fieldName: 'featureStatus',
        },
      }
      getStates(data)
      clearStateFlag()
    }
    return null
  }

  componentDidMount() {
    const { getStates } = this.props
    const data = {
      page: pagination.page,
      limit: pagination.limit,
      input: {
        fieldName: 'featureStatus',
      },
    }
    getStates(data)
  }

  editState = (record: tableRecords) => {
    const { history } = this.props
    history.push('/add-state', { stateProvince: record, isEdit: true })
  }

  state = {
    columns: [
      {
        title: 'State Code',
        dataIndex: 'stateCode',
        key: 'stateCode',
        sorter: (a: any, b: any) => a.stateCode.length - b.stateCode.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Title',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name.length - b.name.length,
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Country',
        dataIndex: 'country.name',
        key: 'country',
        sorter: (a: any, b: any) => a.country.name.length - b.country.name.length,
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          // const { deleteTemplate } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editState(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(this.props.deleteState, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  render() {
    const { columns } = this.state
    const { states } = this.props

    return (
      <>
        <Card
          title="Manage State/Province"
          extra={
            <Button className="btn-add">
              <Link to="/add-state">
                <WSIcon type={<PlusOutlined />} /> Add State
              </Link>
            </Button>
          }
        >
          <ExpandTable columns={columns} tableData={states} expandHtml={false} isExpand={false} />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  states: state.stateReducer && state.stateReducer.states,
  deleteSuccess: state.stateReducer && state.stateReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getStates: (data: any) => dispatch(getStates(data)),
  clearStateFlag: () => dispatch(clearStateFlag()),
  deleteState: (data: string) => dispatch(deleteState(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageStates)
