import React, { GetDerivedStateFromProps } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  getAllLookupsAction,
  getAllTypesAction,
  clearLookUpAction,
  editLookupsAction,
  deleteLookupsAction,
  createLookupsAction,
} from '../../store/login/Actions'
import { getAllBoatPagination, paginationPage, activeInactive, activeInactiveNumbers, defaultImage } from '../../enums/enums'
import WSSelect from '../../component/WSSelect'
import { Card, Divider, Tag, Button, Switch } from 'antd'
import ExpandTable from '../../component/ExpandTable'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import CreateEditModal from './CreateEditModal'
import FilterButton from '../filters/FilterButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { manageType, manageTypeText, typeId } from '../../store/login/Types'
import History from '../../History'
import { TitleSearch } from '../../component/SearchBar'
import { SelectOutlined } from '@ant-design/icons'

interface ManageTypesProps {
  editLookupsAction: typeof editLookupsAction
  getAllTypesAction: typeof getAllTypesAction
  typesData: any[]
  lookupsData: any[]
  totalLookupsRow: number
  clearLookUpAction: typeof clearLookUpAction
  getTypeSuccess: boolean
  getAllLookupsAction: typeof getAllLookupsAction
  createLookupsAction: typeof createLookupsAction
  deleteLookupsAction: typeof deleteLookupsAction
  history: typeof History
}

interface ManageTypesState {
  selectedTypeId: string
  selectedTypeIsTripType: boolean
  pageFilterArray: {
    title: string
    filedArray: {
      label: string
      value: boolean
    }
    listOptions: string
    passed: string
    actionField: string
  }[]
}

class ManageTypes extends React.Component<ManageTypesProps, ManageTypesState> {
  constructor(props: ManageTypesProps) {
    super(props)
    this.state = {
      selectedTypeId: '',
      selectedTypeIsTripType: false,
      pageFilterArray: [
        {
          title: 'Status',
          filedArray: activeInactive,
          listOptions: 'label',
          passed: 'value',
          actionField: 'isActive',
        },
      ],
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<ManageTypesProps, ManageTypesState> = (props, state) => {
    const { clearLookUpAction, getTypeSuccess } = props
    if (getTypeSuccess) clearLookUpAction()
    return null
  }

  componentDidMount() {
    this.props.getAllTypesAction({})

    const params = new URLSearchParams(this.props.history.location.search)

    if (params.get('typeId')) {
      this.selectTypeId(params.get('typeId') || '')
    }
  }

  componentDidUpdate(): void {
    const params = new URLSearchParams(this.props.history.location.search)
    if (this.props.typesData?.length && !params.get('typeId')) this.selectTypeId(this.props.typesData[0].id)
  }

  selectTypeId = (typeId: string) => {
    this.setState({ selectedTypeId: typeId })

    const params = new URLSearchParams(this.props.history.location.search)
    params.set('typeId', typeId)
    this.props.history.push({ pathname: this.props.history.location.pathname, search: params.toString() })
  }

  onClickSelect = (val: string) => {
    const selectedType = this.props.typesData.find(i => i.id === val)?.type
    this.setState({ selectedTypeIsTripType: selectedType === 'Trip Type' })
    this.selectTypeId(val)
  }
  
  onSearchAlias = (text: string) => {
    this.props.getAllLookupsAction({
      ...getAllBoatPagination,
      alias: text,
      typeId: this.state.selectedTypeId
    });
  }

  columns = [
    {
      title: 'Type Name',
      dataIndex: 'typeId',
      key: 'typeId',
      render: (text: typeId) => <span>{text?.type}</span>,
    },
    {
      title: 'Alias',
      dataIndex: 'alias',
      key: 'alias',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (text: manageTypeText) => (
        <>
          {text ? (
            <img key={text.id} src={text.url || defaultImage.image} alt="Icon" className="category-icon"></img>
          ) : (
            <>
              <div>
                <FontAwesomeIcon icon={faImages} size="5x" />
              </div>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (text: boolean, record: manageType) => (
        <Switch
          checked={text}
          onChange={() =>
            this.props.deleteLookupsAction({
              id: record.id,
              value: !text,
            })
          }
          checkedChildren="Active"
          unCheckedChildren="Block"
        />
      ),
    },
    {
      title: 'View Ad',
      dataIndex: 'ViewAd',
      key: 'view',
      render: (text: string, record: any) => (
        <CreateEditModal
          typeId={this.state.selectedTypeId}
          isTypeIdTripType={this.state.selectedTypeIsTripType}
          trip_typeId={this.props.typesData.find(i => i.type === 'Trip')?.id}
          record={record}
          editLookupsAction={this.props.editLookupsAction}
          buttonName="Edit"
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      render: (text: string, record: manageType) => (
        <Button
          disabled={!record.isActive}
          className="btn-delete"
          onClick={() => {
            record &&
              record.id &&
              confirmSubmitHandler(this.props.deleteLookupsAction, {
                id: record.id,
                value: false,
              })
          }}
        >
          Delete
        </Button>
      ),
    },
  ]

  tripColumn = {
    title: 'Trip',
    dataIndex: 'tripId',
    key: 'tripId',
    render: (trips: any[]) => <span>{trips?.map(t => t.alias).join(', ')}</span>,
  }

  render() {
    const { typesData, lookupsData, totalLookupsRow, getAllLookupsAction, createLookupsAction } = this.props
    const { selectedTypeId, selectedTypeIsTripType, pageFilterArray } = this.state

    return (
      <Card title="Manage Types">
        <p>Select type</p>
        <WSSelect
          onChangeText={(value: any) => this.onClickSelect(value)}
          arrayList={typesData}
          listOptions="type"
          passed="id"
          value={selectedTypeId}
        />
        <Divider />
        <Card
          bordered={false}
          // extra={             
          //     selectedTypeId && (
          //       <CreateEditModal
          //         typeId={selectedTypeId}
          //         isTypeIdTripType={selectedTypeIsTripType}
          //         trip_typeId={this.props.typesData.find(i => i.type === 'Trip')?.id}
          //         editLookupsAction={createLookupsAction}
          //         buttonName="Create"
          //       />
          //     )}         
        >
          {!!selectedTypeId && (
            <>
            <div className='d-flex justify-content-between'>

              <FilterButton
                filterArray={pageFilterArray}
                triggerAction={getAllLookupsAction}
                extraInput={{ typeId: selectedTypeId }}
              />
              <div className='d-flex' style={{width: 'max-content', height: 'fit-content', marginTop: '1.3rem'}}>
                <TitleSearch
                allowClear={true} 
                className='mr-2'
                placeHolder='Search by Alias...'
                onSearch={this.onSearchAlias} 
                />
                {             
              selectedTypeId && (
                <CreateEditModal
                  typeId={selectedTypeId}
                  isTypeIdTripType={selectedTypeIsTripType}
                  trip_typeId={this.props.typesData.find(i => i.type === 'Trip')?.id}
                  editLookupsAction={createLookupsAction}
                  buttonName="Create"
                />
              )}
              </div>
            </div>
              <ExpandTable
                columns={
                  selectedTypeIsTripType
                    ? [...this.columns.slice(0, 2), this.tripColumn, ...this.columns.slice(2)]
                    : this.columns
                }
                key={selectedTypeId}
                tableData={lookupsData}
                expandHtml={false}
                isExpand={false}
                totalUsersRow={totalLookupsRow}
                pageAction={getAllLookupsAction}
                extraInput={{ typeId: selectedTypeId }}
                page={paginationPage.boat}
              />
            </>
          )}
        </Card>
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    typesData: state.userLoginState.typesData,
    lookupsData: state.userLoginState.lookupsData,
    totalLookupsRow: state.userLoginState.totalLookupsRow,
    getTypeSuccess: state.userLoginState.getTypeSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllLookupsAction: (data: any) => dispatch(getAllLookupsAction(data)),
    getAllTypesAction: (data: any) => dispatch(getAllTypesAction(data)),
    editLookupsAction: (data: any) => dispatch(editLookupsAction(data)),
    createLookupsAction: (data: any) => dispatch(createLookupsAction(data)),
    clearLookUpAction: () => dispatch(clearLookUpAction()),
    deleteLookupsAction: (data: any) => dispatch(deleteLookupsAction(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTypes)
