import {
  costEstimateState,
  ActionType,
  CREATE_OR_UPDATE_COST_ESTIMATE,
  GET_COST_ESTIMATE_SUCCESS,
  GET_COST_ESTIMATE,
  GET_COST_ESTIMATE_FAILURE,
  CREATE_OR_UPDATE_COST_ESTIMATE_FAILURE,
  CREATE_OR_UPDATE_COST_ESTIMATE_SUCCESS,
  CLEAR_COST_ESTIMATE,
} from './Types'

const initialState: costEstimateState = {
  isLoading: false,
  costEstimateSuccess: false,
  costEstimateError: false,
  isFlag: true,
  costEstimation: {
    id: {},
    adminFee: 0,
    payPalFee: 0,
    payPalFixedPrice: 0,
    payPalFeeOther: 0,
    estimatedCostNote: '',
    bankAccountDetail: '',
    sitePayPalEmail: '',
    payPalUserName: '',
    payPalPassword: '',
    payPalSignature: '',
    surveyorReportSubmissionDays: 0,
    stopSalesEngineDays: 0,
    daysOfAutoDeleteBoat: 0,
    daysCounterForInspection: 0,
    daysCounterInactiveBuyers: 0,
    daysCounterSurveyorRespond: 0,
  },
  getSuccess: false,
}

export const costEstimateReducer = (state = initialState, action: ActionType): costEstimateState => {
  switch (action.type) {
    case CREATE_OR_UPDATE_COST_ESTIMATE:
      return {
        ...state,
        isLoading: true,
        costEstimateSuccess: false,
        costEstimateError: false,
      }

    case CREATE_OR_UPDATE_COST_ESTIMATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        costEstimateSuccess: true,
        costEstimateError: false,
        costEstimation: action.payload,
      }

    case CREATE_OR_UPDATE_COST_ESTIMATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        costEstimateSuccess: false,
        costEstimateError: true,
      }

    case GET_COST_ESTIMATE:
      return {
        ...state,
        isFlag: false,
        getSuccess: false,
        costEstimateError: false,
      }

    case GET_COST_ESTIMATE_SUCCESS:
      return {
        ...state,
        isFlag: true,
        getSuccess: true,
        costEstimateError: false,
        costEstimation: action.payload,
      }

    case GET_COST_ESTIMATE_FAILURE:
      return {
        ...state,
        getSuccess: false,
        costEstimateError: true,
      }
    case CLEAR_COST_ESTIMATE:
      return {
        ...state,
        getSuccess: false,
        costEstimateError: false,
        isFlag: false,
        isLoading: false,
        costEstimateSuccess: false,
      }
    default:
      return state
  }
}
