import {
  jobData,
  GET_ALL_JOBS,
  CREATE_JOBS,
  UPDATE_JOBS,
  DELETE_JOBS,
  CLEAR_JOBS_FLAG,
  jobStatusInput,
  CHANGE_JOB_STATUS,
  GET_JOBS_CONTENT,
  CREATE_OR_UPDATE_JOB_CONTENT,
  JobsContent,
  GET_JOBS_APPLICATIONS,
  getApplicationInput,
} from './Type'

export function getAllJobs(data: any) {
  return {
    type: GET_ALL_JOBS,
    payload: data,
  }
}

export function createJobs(data: jobData) {
  return {
    type: CREATE_JOBS,
    payload: data,
  }
}

export function updateJobs(data: jobData) {
  return {
    type: UPDATE_JOBS,
    payload: data,
  }
}

export function deleteJobs(data: string) {
  return {
    type: DELETE_JOBS,
    payload: data,
  }
}

export function clearJobsFlag() {
  return {
    type: CLEAR_JOBS_FLAG,
  }
}

export function changeJobStatus(data: jobStatusInput) {
  return {
    type: CHANGE_JOB_STATUS,
    payload: data,
  }
}

export function getJobsContent() {
  return {
    type: GET_JOBS_CONTENT,
  }
}

export function createOrUpdateJobsContent(data: JobsContent) {
  return {
    type: CREATE_OR_UPDATE_JOB_CONTENT,
    payload: data,
  }
}

export const getJobsApplications = (data: getApplicationInput) => ({
  type: GET_JOBS_APPLICATIONS,
  payload: data,
})
