import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Row, Col } from 'antd'
import { defaultVerticalProps } from '../../helpers/ChartHelpers'

interface Props {
  width: Number
  height: Number
  data: any
  mainLabel: String
  labels: any
}

export const VerticalGraph = (props: any) => {
  const data = defaultVerticalProps(props.labels, props.mainLabel, props.data)

  return (
    <Row gutter={16}>
      <Col span={20}>
        <Bar data={data} width={props.width} height={props.height} />
      </Col>
    </Row>
  )
}
