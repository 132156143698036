import * as React from 'react'
import { Input, Select } from 'antd'
import { genericSearch } from '../enums/enums'
const { Option } = Select

const SearchWithDropdown = (props: any) => {
  return (
    <div style={{ marginTop: 15, width: 400 }}>
      <Input
        addonAfter={
          <Select defaultValue="Admin User">
            {genericSearch.map((item: any) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        }
      />
    </div>
  )
}
export default SearchWithDropdown
