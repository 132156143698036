import { CREATE_FORGOT_PASSWORD, GET_FORGOT_PASSWORD, passCreateData, CLEAR_FORGOT_PASSWORD_FLAG } from './Type'

export function createForgotPassword(data: passCreateData) {
  return {
    type: CREATE_FORGOT_PASSWORD,
    payload: data,
  }
}

export function getForgotPassword() {
  return {
    type: GET_FORGOT_PASSWORD,
  }
}

export function clearPasswordFlag() {
  return {
    type: CLEAR_FORGOT_PASSWORD_FLAG,
  }
}
