import {
  allBranchesState,
  ActionType,
  GET_ALL_SURVEYORS,
  GET_ALL_SURVEYORS_SUCCESS,
  GET_ALL_SURVEYORS_FAILURE,
  DELETE_SURVEYORS,
  DELETE_SURVEYORS_SUCCESS,
  DELETE_SURVEYORS_FAILURE,
  CLEAR_BRANCHES_FLAG,
  CHANGE_SURVEYOR_STATUS,
  CHANGE_SURVEYOR_STATUS_SUCCESS,
  CHANGE_SURVEYOR_STATUS_FAILURE,
  GET_ALL_SURVEYOR_BRANCHES,
  GET_ALL_SURVEYOR_BRANCHES_SUCCESS,
  GET_ALL_SURVEYOR_BRANCHES_FAILURE,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: allBranchesState = {
  allBranches: [],
  allBranchesSuccess: false,
  allBranchesFailure: false,
  deleteSurveyorSuccess: false,
  deleteSurveyorFailure: false,
  branchStatusSuccess: false,
  branchStatusError: false,
  allBranchesTotal: 0,
  branchesBySurveyorSuccess: false,
  branchesBySurveyorError: false,
  surveyorBranches: [],
  isLoading: false,
}

export const allBranchesReducer = (state = initialState, action: ActionType): allBranchesState => {
  const { allBranches } = state

  switch (action.type) {
    case GET_ALL_SURVEYORS:
      return {
        ...state,
        allBranchesSuccess: false,
        allBranchesFailure: false,
      }

    case GET_ALL_SURVEYORS_SUCCESS:
      return {
        ...state,
        allBranches: action.payload.items,
        allBranchesTotal: action.payload.total,
        allBranchesSuccess: true,
        allBranchesFailure: false,
      }

    case GET_ALL_SURVEYORS_FAILURE:
      return {
        ...state,
        allBranchesSuccess: false,
        allBranchesFailure: true,
      }

    case DELETE_SURVEYORS_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')

      return {
        ...state,
        allBranches: allBranches.filter(item => item.id !== action.payload.id),
        deleteSurveyorSuccess: true,
        deleteSurveyorFailure: false,
      }

    case DELETE_SURVEYORS_FAILURE:
      return {
        ...state,
        deleteSurveyorSuccess: false,
        deleteSurveyorFailure: true,
      }

    case CLEAR_BRANCHES_FLAG:
      return {
        ...state,
        allBranchesSuccess: false,
        allBranchesFailure: false,
        deleteSurveyorSuccess: false,
        deleteSurveyorFailure: false,
      }

    case CHANGE_SURVEYOR_STATUS:
      return {
        ...state,
        branchStatusSuccess: false,
        branchStatusError: false,
      }

    case CHANGE_SURVEYOR_STATUS_SUCCESS:
      NotificationWithIcon('success', 'Status Changed Successfully')
      return {
        ...state,
        branchStatusSuccess: true,
        branchStatusError: false,
      }

    case CHANGE_SURVEYOR_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        branchStatusSuccess: false,
        branchStatusError: true,
      }

    case GET_ALL_SURVEYOR_BRANCHES:
      return {
        ...state,
        surveyorBranches: [],
        isLoading: true,
      }

    case GET_ALL_SURVEYOR_BRANCHES_SUCCESS:
      return {
        ...state,
        branchesBySurveyorSuccess: true,
        surveyorBranches: action.payload.data.getBranchesByUser.items,
        isLoading: false,
      }

    case GET_ALL_SURVEYOR_BRANCHES_FAILURE:
      return {
        ...state,
        branchesBySurveyorError: true,
        surveyorBranches: [],
        isLoading: false,
      }

    default:
      return state
  }
}
