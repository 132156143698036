import default2 from '../assets/image/NoImageAvailableIcon.png'
export const pagination = {
  page: 1,
  limit: 10,
  allBoatsLimit: 10,
}

export const getAllBoatPagination = {
  page: 1,
  limit: 10,
}

export const salesEngineType = {
  all: 'all',
  archive: 'archive',
  blackList: 'blackList',
}

export const pageSize = {
  pageLimit: 10,
}

export const PageSubModuleCategory: any = {
  member: 'member',
  'boat-owner': 'boat-owner',
  'broker-and-dealer': 'broker-and-dealer',
  'boat-manufacturer': 'boat-manufacturer',
  'service-and-maintenance': 'service-and-maintenance',
  surveyor: 'surveyor',
  'marina-and-storage': 'marina-and-storage',
  'rent-and-charter': 'rent-and-charter',
  'yacht-shipper': 'yacht-shipper',
  agent: 'agent',
}

export const agreementUserType = {
  builder: 'builder',
  surveyor: 'surveyor',
  shipment: 'shipment',
}

export const filterVideoCategory = [{
  title: 'Category',
  filedArray: [
    {
      text: 'Rent',
      value: 'rent',
    },
    {
      text: 'Marina & Storage',
      value: 'marinaStorage',
    },
    {
      text: 'Boat Service',
      value: 'boatService',
    },
    {
      text: 'Home',
      value: 'home',
    },
    {
      text: 'Boat Show',
      value: 'boatShow',
    },
    {
      text: 'Boat Media',
      value: 'boatMedia',
    },
  ],
  listOptions: 'text',
  passed: 'value',
  actionField: 'type',
}];

export const videoCategory = [
  {
    text: 'rent',
    value: 'rent',
  },
  {
    text: 'marinaStorage',
    value: 'marinaStorage',
  },
  {
    text: 'boatService',
    value: 'boatService',
  },
  {
    text: 'home',
    value: 'home',
  },
  {
    text: 'boatShow',
    value: 'boatShow',
  },
  {
    text: 'boatMedia',
    value: 'boatMedia',
  },
  {
    text: 'adamseaMedia',
    value: 'adamseaMedia',
  },
  {
    text: 'ad',
    value: 'ad',
  },
]

export const agreementCategory = [
  {
    id: 'builder',
    value: 'Boat Builder Agreement',
  },
  {
    id: 'surveyor',
    value: 'Surveyor Agreement',
  },
  {
    id: 'shipment',
    value: 'Shipment Agreement',
  },
]

export const defaultImage = {
  image: default2,
}

export const dataTable = {
  VERTICAL_SCROLL: 600,
}

export const userRoles = [
  {
    role: 'MEMBER',
    alias: 'member',
    path: '/member',
  },
  {
    role: 'BOAT OWNER',
    alias: 'boatOwner',
    path: '/boat-owner',
  },
  {
    role: 'BROKER AND DEALER',
    alias: 'brokerAndDealer',
    path: '/boat-and-dealer',
  },
  {
    role: 'BOAT MANUFACTURER',
    alias: 'boatManufacturer',
    path: '/boat-manufactory',
  },
  {
    role: 'SERVICE AND MAINTENANCE',
    alias: 'serviceAndMaintenance',
    path: '/yacht-service',
  },
  {
    role: 'SURVEYOR',
    alias: 'surveyor',
    path: '/surveyor',
  },
  {
    role: 'MARINA & STORAGE',
    alias: 'marinaAndStorage',
    path: '/marina-storage',
  },
  {
    role: 'RENT',
    alias: 'rent',
    path: '/rent',
  },
  {
    role: 'RENT',
    alias: 'rentAndCharter',
    path: '/rent',
  },
  {
    role: 'SHIPMENT',
    alias: 'yachtShipper',
    path: '/shipment',
  },
  {
    role: 'AGENT',
    alias: 'agent',
    path: '/agent',
  },
]

export const genericSearch = [
  { label: 'Admin User', value: 'adminUser' },
  { label: 'Front User', value: 'frontUser' },
  { label: 'Agreements', value: 'agreements' },
  { label: 'Boats', value: 'boats' },
]

export const TEMPLATENAME: any = {
  1: 'Email User Registration',
  2: 'Email User Activation Notification',
  3: 'Email Forgot Password',
  5: 'Email User Adamsea Share',
  6: 'Email User Confirmation',
  7: 'Email Agreement',
  9: 'Email cost estimation',
  11: 'Sales Engine Create',
  12: 'Sales Engine Send Agreement Request',
  13: 'Sales Engine Remove Agent',
  14: 'Sales Engine Negotiation',
  15: 'Sales Engine Send Surveyor Request',
  16: 'Sales Engine Surveyor Accept',
  17: 'Sales Engine Surveyor Decline',
  18: 'Sales Engine Buyer Skip Surveyor',
  19: 'Sales Engine Buyer Remove Surveyor',
  20: 'Sales Engine Surveyor Payment',
  21: 'Sales Engine Surveyor Report',
  22: 'Sales Engine Buyer Agreement',
  23: 'Sales Engine Boat Payment',
  24: 'Sales Engine Buyer Shipment',
  25: 'Sales Engine Buyer Add Shipment Location',
  26: 'Sales Engine Shipper Proposal',
  27: 'Sales Engine Buyer Choose Shipper',
  28: 'Sales Engine Shipper Payment',
  29: 'Sales Engine Shipper Started Shipment',
  30: 'Sales Engine Shipper Shipped Boat',
  31: 'Sales Engine Shipper Added Document',
  32: 'Pending Email Agreement',
  33: 'Reject Agreement Request',
  34: 'Email AdamSea User Share',
  35: 'Email Boat Show Share',
  41: 'Rent Ticket',
  42: 'Create Admin User',
  43: 'Change Password',
  44: 'Web Change Password Notification',
  46: 'Web User Verification Request Notification',
  47: 'Web User First Ad Notification',
}

export const TEMPLATESMSNAME: any = {
  4: 'SMS User Mobile Confirmation',
  8: 'SMS Agreement',
  10: 'SMS verification notification',
}

export const SMTPTYPE: any = {
  1: 'NONE',
  2: 'SSL',
  3: 'TLS',
}

export const typeSelector = [
  { label: 'NONE', value: 1 },
  { label: 'SSL', value: 2 },
  { label: 'TLS', value: 3 },
]

export const MEDIA_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  DOCUMENT: 'DOCUMENT',
}

export const DOMAINTYPE: any = {
  1: 'AWS',
  2: 'GMAIL',
}
export const adminUsers = [
  {
    role: 'ACCOUNT USERS',
    alias: 'accountUser',
    path: '/admin-user/account-user',
  },
  {
    role: 'AUDITORS',
    alias: 'auditor',
    path: '/admin-user/auditor',
  },
  {
    role: 'CUSTOMER SUPPORT',
    alias: 'customerSupport',
    path: '/admin-user/customers-support',
  },
  {
    role: 'SALES MEN',
    alias: 'salesMan',
    path: '/admin-user/sales-man',
  },
  {
    role: 'ADMINISTRATOR',
    alias: 'administrator',
    path: '/admin-user/administrator',
  },
  {
    role: 'SUB ADMINISTRATOR',
    alias: 'subAdministrator',
    path: '/admin-user/sub-administrator',
  },
]

export const auctionRoom = [
  {
    role: 'AUCTION ROOM',
    alias: 'auction-room',
    path: '/manage-auction-room',
  },
]

export const adminOtherUsers = [
  {
    role: 'EMAIL TEMPLATES',
    alias: 'emailTemplates',
    path: '/email-templates',
    imgPath: require('../assets/image/othersImages/Email_Temp_Big@3x.png'),
  },
  {
    role: 'SMS TEMPLATES',
    alias: 'smsTemplates',
    path: '/sms-templates',
    imgPath: require('../assets/image/othersImages/SMS_Content_Small@3x.png'),
  },
  // {
  //     role: "SLIDER IMAGES",
  //     alias: 'slider-images',
  //     path: ""
  // },
  {
    role: 'FAQS',
    alias: 'faq',
    path: '/manage-faqs',
    imgPath: require('../assets/image/othersImages/FAQ_Big@3x.png'),
  },
  {
    role: 'USER GUIDE',
    alias: 'userGuides',
    path: '/manage-user-guide',
    imgPath: require('../assets/image/othersImages/User_Guide_Big@3x.png'),
  },
  {
    role: 'JOBS',
    alias: 'jobs',
    path: '/manage-jobs',
    imgPath: require('../assets/image/othersImages/Job_Small@3x.png'),
  },
  {
    role: 'BOAT SHOWS',
    alias: 'boatShows',
    path: '/manage-boat-shows',
    imgPath: require('../assets/image/othersImages/Boat_Show_Big@3x.png'),
  },
  {
    role: 'ARTICLES',
    alias: 'articles',
    path: '/manage-articles',
    imgPath: require('../assets/image/othersImages/Article_Big@3x.png'),
  },
  // {
  //     role: "GRAPHIC MEDIA",
  //     alias: 'graphic-media',
  //     path: ""
  // },
  // {
  //     role: "VIDEOS",
  //     alias: 'videos',
  //     path: ""
  // },
  // {
  //     role: "DOWNLOADS",
  //     alias: 'downloads',
  //     path: ""
  // },
  {
    role: 'BOAT TYPES',
    alias: 'boatTypes',
    path: '/manage-boat-types',
    imgPath: require('../assets/image/othersImages/Boat_Type_Small@3x.png'),
  },
  {
    role: 'STATIC PAGES',
    alias: 'pages',
    path: '/manage-pages',
    imgPath: require('../assets/image/othersImages/Static_Page_Small@3x.png'),
  },
  {
    role: 'STATIC PAGES',
    alias: 'pages',
    path: '/manage-rent-pages',
    imgPath: require('../assets/image/othersImages/Static_Page_Small@3x.png'),
  },
  {
    role: 'STATIC PAGES',
    alias: 'pages',
    path: '/manage-agreements',
    imgPath: require('../assets/image/othersImages/Static_Page_Small@3x.png'),
  },
  // {
  //     role: "MANAGE SEO",
  //     alias: 'manage-seo',
  //     path: ""
  // },
  {
    role: 'STATES',
    alias: 'states',
    path: '/manage-state',
    imgPath: require('../assets/image/othersImages/State_Big@3x.png'),
  },
  {
    role: 'COUNTRIES',
    alias: 'countries',
    path: '/manage-country',
    imgPath: require('../assets/image/othersImages/Country_Big@3x.png'),
  },
]

export const allAgreementsCounts: any = {
  'yacht-shipper': '/shipment-agreements',

  surveyor: '/surveyor-agreements',

  'boat-manufacturer': '/builder-agreements',
}

export const mediaPosition = ['TOP', 'MIDDLE']

export const metaType = ['image', 'video']
export const metaTypeForSignUp = ['image']
export const mediaCategory = {
  marinaStorage: {
    type: 'marinaStorage',
    metatype: 'image',
    fieldName: 'marinaStorageBanner',
  },
  boatShow: {
    type: 'boatShow',
    metatype: 'image',
    fieldName: 'boatShowBanner',
  },
  boatMedia: {
    type: 'boatMedia',
    metatype: 'image',
    fieldName: 'boatMediaBanner',
  },
  boatService: {
    type: 'boatService',
    metatype: 'image',
    fieldName: 'boatServiceBanner',
  },
  home: {
    type: 'home',
    metatype: 'image',
    fieldName: 'homeBanner',
  },
  rent: {
    type: 'rent',
    metatype: 'image',
    fieldName: 'rentBanner',
  },
  siteContent: {
    type: 'siteContent',
    metatype: 'image',
    fieldName: 'siteBanner',
  },
  article: {
    type: 'articleMedia',
    metatype: 'image',
    fieldName: 'articleBanner',
  },
  download: {
    type: 'downloaded',
    metatype: 'image',
    fieldName: 'downloadBanner',
  },
  faq: {
    type: 'faq',
    metatype: 'image',
    fieldName: 'faqBanner',
  },
  userGuide: {
    type: 'userGuide',
    metatype: 'image',
    fieldName: 'userGuideBanner',
  },
  otherPage: {
    type: 'otherPage',
    metatype: 'image',
    fieldName: 'otherPageBanner',
  },
  adminLogin: {
    type: 'adminLogin',
    metatype: 'image',
    fieldName: 'adminLoginBanner',
  },
  logIn: {
    type: 'login',
    metatype: 'image',
    fieldName: 'loginBanner',
  },
  signUp: {
    type: 'signUp',
    metatype: 'image',
    fieldName: 'signUpBanner',
  },
}

export const salesEngineHelpContentType = {
  member: 'member',
  memberPendingSaleEngine: 'memberPendingSaleEngine',
  sellersAtGroup: 'sellersAtGroup',
  seller: 'seller',
  agent: 'agent',
  shipment: 'shipment',
  branches: 'branches',
  brokers: 'brokers',
}

export const imgExtension = [
  '.jpg',
  '.gif',
  '.png',
  '.gif',
  '.webp',
  '.jpeg',
  '.tif',
  '.tiff',
  '.bmp',
  '.JPG',
  '.GIF',
  '.PNG',
  '.GIF',
  '.WEBP',
  '.JPEG',
  '.TIF',
  '.TIFF',
  '.BMP',
]

export const returnHelpContentType = {
  member: 'Member',
  memberPendingSaleEngine: "Member's Pending Sales Engine",
  sellersAtGroup: 'Seller At Group Level',
  seller: 'Seller',
  agent: 'Agent',
  shipment: 'Shipment',
  branches: 'Branches',
  brokers: 'Brokers',
}

export const userRoleAlias = {
  member: 'member',
  boatOwner: 'boatOwner',
  yatchShipper: 'yachtShipper',
  serviceMaintenance: 'serviceAndMaintenance',
  rentAndCharter: 'rentAndCharter',
  rent: 'rent',
  marinaAndStorage: 'marinaAndStorage',
  surveyor: 'surveyor',
  brokerAndDealer: 'brokerAndDealer',
  boatManufacturer: 'boatManufacturer',
  agent: 'agent',
}

export const paginationPage = {
  boat: 'boat',
  adminUser: 'adminUser',
}

export const userAllRoles = {
  MEMBER: 'member',
  BOAT_OWNER: 'boat-owner',
  BROKER_AND_DEALER: 'broker-and-dealer',
  BOAT_MANUFACTURER: 'boat-manufacturer',
  YACHT_SHIPPER: 'yacht-shipper',
  SURVEYOR: 'surveyor',
  RENT_AND_CHARTER: 'rent-and-charter',
  SERVICE_AND_MAINTENANCE: 'service-and-maintenance',
  MARINA_AND_STORAGE: 'marina-and-storage',
  AGENT: 'agent',
}

export const userRolesSelectList = [
  { label: 'Member', value: userAllRoles.MEMBER },
  { label: 'Boat Owner', value: userAllRoles.BOAT_OWNER },
  { label: 'Broker & Dealer', value: userAllRoles.BROKER_AND_DEALER },
  { label: 'Boat Manufacturer', value: userAllRoles.BOAT_MANUFACTURER },
  { label: 'Shipper', value: userAllRoles.YACHT_SHIPPER },
  { label: 'Surveyor', value: userAllRoles.SURVEYOR },
  { label: 'Rent & Charter', value: userAllRoles.RENT_AND_CHARTER },
  { label: 'Marina & Storage', value: userAllRoles.MARINA_AND_STORAGE },
  { label: 'Service & Maintainance', value: userAllRoles.SERVICE_AND_MAINTENANCE },
  { label: 'Agent', value: userAllRoles.AGENT },
];

export const userRolesMap_aliasToName = {
  [userAllRoles.MEMBER]: 'Member',
  [userAllRoles.BOAT_OWNER]: 'Boat Owner',
  [userAllRoles.BROKER_AND_DEALER]: 'Broker & Dealer',
  [userAllRoles.BOAT_MANUFACTURER]: 'Boat Manufacturer',
  [userAllRoles.YACHT_SHIPPER]: 'Shipper',
  [userAllRoles.SURVEYOR]: 'Surveyor',
  [userAllRoles.RENT_AND_CHARTER]: 'Rent & Charter',
  [userAllRoles.MARINA_AND_STORAGE]: 'Marina & Storage',
  [userAllRoles.SERVICE_AND_MAINTENANCE]: 'Service & Maintainance',
  [userAllRoles.AGENT]: 'Agent',
};

export const documentRoles = [
  userAllRoles.AGENT,
  userAllRoles.BROKER_AND_DEALER,
  userAllRoles.BOAT_MANUFACTURER,
  userAllRoles.YACHT_SHIPPER,
  userAllRoles.SURVEYOR,
  userAllRoles.SERVICE_AND_MAINTENANCE,
  userAllRoles.MARINA_AND_STORAGE,
]

export const templateName = [
  {
    aliasName: 'EMAIL_USER_REGISTRATION',
    value: 1,
    name: 'Email User Registration',
  },
  {
    aliasName: 'EMAIL_USER_ACTIVATE',
    value: 2,
    name: 'Email User Activation Notification',
  },
  {
    aliasName: 'EMAIL_USER_FORGOT_PASSWORD',
    value: 3,
    name: 'Email Forgot Password',
  },
  { aliasName: 'EMAIL_ADAMSEA_SHARE', value: 5, name: 'Email AdamSea Share' },
  {
    aliasName: 'EMAIL_ADAMSEA_BOATSHOW_SHARE',
    value: 35,
    name: 'Email Boat Show Share',
  },
  {
    aliasName: 'EMAIL_USER_EMAIL_CONFIRMATION',
    value: 6,
    name: 'Email User Confirmation',
  },
  { aliasName: 'EMAIl_AGREEMENT', value: 7, name: 'Email Agreement' },
  { aliasName: 'EMAIl_COSTESTIMATE', value: 9, name: 'Email Cost Estimation' },
  { value: 11, aliasName: 'SALES_ENGINE_CREATE', name: 'Sales Engine Create' },
  {
    value: 12,
    aliasName: 'SALES_ENGINE_SENDAGENTREQUEST',
    name: 'Sales Engine Send Agreement Request',
  },
  {
    value: 13,
    aliasName: 'SALES_ENGINE_REMOVEAGENT',
    name: 'Sales Engine Remove Agent',
  },
  {
    value: 14,
    aliasName: 'SALES_ENGINE_NEGOTIATION',
    name: 'Sales Engine Negotiation',
  },
  {
    value: 15,
    aliasName: 'SALES_ENGINE_SENDSURVEYORREQUEST',
    name: 'Sales Engine Send Surveyor Request',
  },
  {
    value: 16,
    aliasName: 'SALES_ENGINE_SURVEYORACCEPT',
    name: 'Sales Engine Surveyor Accept',
  },
  {
    value: 17,
    aliasName: 'SALES_ENGINE_SURVEYORDECLINE',
    name: 'Sales Engine Surveyor Decline',
  },
  {
    value: 18,
    aliasName: 'SALES_ENGINE_BUYERSKIPSURVEYOR',
    name: 'Sales Engine Buyer Skip Surveyor',
  },
  {
    value: 19,
    aliasName: 'SALES_ENGINE_BUYERREMOVEDSURVEYOR',
    name: 'Sales Engine Buyer Remove Surveyor',
  },
  {
    value: 20,
    aliasName: 'SALES_ENGINE_SURVEYORPAYMENT',
    name: 'Sales Engine Surveyor Payment',
  },
  {
    value: 21,
    aliasName: 'SALES_ENGINE_SURVEYORREPORT',
    name: 'Sales Engine Surveyor Report',
  },
  {
    value: 22,
    aliasName: 'SALES_ENGINE_BUYERAGREEMENT',
    name: 'Sales Engine Buyer Agreement',
  },
  {
    value: 23,
    aliasName: 'SALES_ENGINE_BOATPAYMENT',
    name: 'Sales Engine Boat Payment',
  },
  {
    value: 24,
    aliasName: 'SALES_ENGINE_BUYERSHIPMENT',
    name: 'Sales Engine Buyer Shipment',
  },
  {
    value: 25,
    aliasName: 'SALES_ENGINE_BUYERADDSHIPMENTLOCATION',
    name: 'Sales Engine Buyer Add Shipment Location',
  },
  {
    value: 26,
    aliasName: 'SALES_ENGINE_SHIPPERPROPOSAL',
    name: 'Sales Engine Shipper Proposal',
  },
  {
    value: 27,
    aliasName: 'SALES_ENGINE_BUYERCHOOSESHIPPER',
    name: 'Sales Engine Buyer Choose Shipper',
  },
  {
    value: 28,
    aliasName: 'SALES_ENGINE_SHIPPERPAYMENT',
    name: 'Sales Engine Shipper Payment',
  },
  {
    value: 29,
    aliasName: 'SALES_ENGINE_SHIPPERSTARTEDSHIPMENT',
    name: 'Sales Engine Shipper Started Shipment',
  },
  {
    value: 30,
    aliasName: 'SALES_ENGINE_SHIPPERSHIPPEDBOAT',
    name: 'Sales Engine Shipper Shipped Boat',
  },
  {
    value: 31,
    aliasName: 'SALES_ENGINE_SHIPPERADDEDDOCUMENT',
    name: 'Sales Engine Shipper Added Document',
  },
  {
    value: 32,
    aliasName: 'PENDING_EMAIl_AGREEMENT',
    name: 'Pending Email Agreement',
  },
  {
    value: 33,
    aliasName: 'REJECT_AGREEMENT_REQUEST',
    name: 'Reject Agreement Request',
  },
  {
    value: 34,
    aliasName: 'EMAIL_ADAMSEA_USER_SHARE',
    name: 'Email AdamSea User Share',
  },
  { value: 38, aliasName: 'DELETE_MARKET_AD', name: 'Delete Market Ad' },
  {
    aliasName: 'SALES_ENGINE_AGENT_ACCEPT_REJECT_REQUEST',
    value: 39,
    name: 'Sales Engine Agent Accept/Reject Request',
  },
  {
    aliasName: 'SALES_ENGINE_BROKER_ACCEPT_REJECT_REQUEST',
    value: 40,
    name: 'Sales Engine Broker Accept/Reject Request',
  },
  {
    aliasName: 'RENT_TICKET',
    value: 41,
    name: TEMPLATENAME[41],
  },
  {
    aliasName: 'CREATE_ADMIN_USER',
    value: 42,
    name: TEMPLATENAME[42],
  },
  {
    aliasName: 'CHANGE_PASSWORD',
    value: 43,
    name: TEMPLATENAME[43]
  },
  {
    aliasName: 'WEB_CHANGE_PASSWORD_NOTIFICATION',
    value: 44,
    name: TEMPLATENAME[44]
  },
  {
    aliasName: 'WEB_USER_VERIFICATION_REQUEST_NOTIFICATION',
    value: 46,
    name: TEMPLATENAME[46]
  },
  {
    aliasName: 'WEB_USER_FIRST_AD_NOTIFICATION',
    value: 47,
    name: TEMPLATENAME[47]
  },
]

export const templateSmsName = [
  {
    aliasName: 'SMS_USER_MOBILE_CONFIRMATION',
    value: 4,
    name: 'SMS User Mobile Confirmation',
  },
  { aliasName: 'SMS_AGREEMENT', value: 8, name: 'SMS Agreement' },
  {
    value: 10,
    aliasName: 'SMS_VERIFICATION_NOTIFICATION',
    name: 'SMS verification notification',
  },
]

export const siteConfigImage: any = {
  boatShowLogo: 'SITEBOATSHOWIMAGE',
  rentLogo: 'SITERENTIMAGE',
  marinaAndStorageLogo: 'SITEMARINAANDSTORAGEIMAGE',
  yachtServiceLogo: 'SITEYACHTIMAGE',
  adamseaConnectLogo: 'SITEADAMSEACONNECTIMAGE',
  adamseaShareLogo: 'ADAMSEASHARELOGO',
  adamseaCommunityLogo: 'SITECOMMUNITYIMAGE',
  siteLogo: 'SITELOGO',
  siteFavicon: 'SITEFAVICON',
  boatShowBanner: 'BOATSHOWBANNER',
  buyAndSellBanner: 'BUYANDSELLBANNER',
  marinaBanner: 'MARINABANNER',
  rentBanner: 'RENTBANNER',
  buyAndSellLogo: 'BUYANDSELLIMAGE',
  siteWelcomeBanner: 'SITEWELCOMEBANNER',
  serviceMaintenanceBanner: 'SERVICEMAINTENANCEBANNER',
  boatMediaBanner: 'BOATMEDIABANNER',
  boatMediaLogo: 'BOATMEDIALOGO',
}

export const MEDIA_NAME = {
  MEDIAURL: 'MEDIAURL',
  MEDIATHUMBNAIL: 'MEDIATHUMBNAIL',
  BOATLAYOUT: 'BOATLAYOUT',
  BOATIMAGE: 'BOATIMAGE',
  BOATVIDEO: 'BOATVIDEO',
  USERIMAGE: 'USERIMAGE',
  USERCOMPANYLOGO: 'USERCOMPANYLOGO',
  RENTIMAGE: 'RENTIMAGE',
  RENTBOATLAYOUT: 'RENTBOATLAYOUT',
  YACHTIMAGE: 'YACHTIMAGE',
  YACHTSALESMANPIC: 'YACHTSALESMANPIC',
  MARINAPERSONIMAGE: 'MARINAPERSONIMAGE',
  MARINADOCKLAYOUT: 'MARINADOCKLAYOUT',
  MARINASTORAGELAYOUT: 'MARINASTORAGELAYOUT',
  MARINAIMAGES: 'MARINAIMAGES',
  BOATSHOWIMAGE: 'BOATSHOWIMAGE',
  BOATOWNERSHIPID: 'BOATOWNERSHIPID',
  BOATOWNERSHIPATTACHMENT: 'BOATOWNERSHIPATTACHMENT',
  USERCOMMERCIALLICENCE: 'USERCOMMERCIALLICENCE',
  USERGOVERNMENTID: 'USERGOVERNMENTID',
  COUNTRYIMAGE: 'COUNTRYIMAGE',
  BOATCOSTESTIMATEPDF: 'BOATCOSTESTIMATEPDF',
  BOATPDF: 'BOATPDF',
  YACHTPDF: 'YACHTPDF',
  MARINAPDF: 'MARINAPDF',
  BOATTYPEICON: 'BOATTYPEICON',
  RENTTYPEICON: 'RENTTYPEICON',
  SERVICETYPEICON: 'SERVICETYPEICON',
  MARINATYPEICON: 'MARINATYPEICON',
  ARTICLEFILE: 'ARTICLEFILE',
  MARINATHUMBNAILTYPEICON: 'MARINATHUMBNAILTYPEICON',
  BOATTHUMBNAILTYPEICON: 'BOATTHUMBNAILTYPEICON',
  YACHTTHUMBNAILTYPEICON: 'YACHTTHUMBNAILTYPEICON',
  RENTTHUMBNAILTYPEICON: 'RENTTHUMBNAILTYPEICON',
  JOBDOCUMENTURL: 'JOBDOCUMENTURL',
  PAYMENTMETHODTYPEICON: 'PAYMENTMETHODTYPEICON',
  PASSWORDCONTENTICON: 'PASSWORDCONTENTICON',
  MANAGEDOWNLOADURL: 'MANAGEDOWNLOADURL',
  SURVEYDATADOCUMENT: 'SURVEYDATADOCUMENT',
  BOATFEATURES: 'BOATFEATURES',
}
export const moduleType: any = {
  1: 'MARINA',
  2: 'BOATRENT',
  3: 'BOAT',
  4: 'BOATSHOW',
  5: 'YACHT',
  6: 'REVIEW',
  7: 'FLAGREPORT',
  8: 'USER',
  9: 'COUNTRY',
  10: 'BOATTYPE',
  11: 'RENTTYPE',
  12: 'SERVICETYPE',
  13: 'MARINATYPE',
  14: 'ARTICLE',
  15: 'MARKETMEDIA',
  16: 'ADVERTISEMENT',
  17: 'SURVYEORREPORT',
  18: 'SITECONFIGURATION',
  19: 'AUCTIONROOM',
  20: 'MANAGEJOB',
}

export const statusValues = {
  ALL: 'ALL',
  CONFIRMED: 'CONFIRMED',
  APPROVE: 'APPROVE',
  UNAPPROVE: 'UNAPPROVE',
  PENDING: 'PENDING',
  APPROVEORCRITERIA: 'APPROVEORCRITERIA',
}

export const activeInactive: any = [
  { label: 'Active', value: true },
  { label: 'InActive', value: false },
]

export const adminLogsSections: any = [
  {label: "BOAT", value: 1},
  {label: "MARINA", value: 2},
  {label: "YACHT", value: 3},
  {label: "AUCTIONROOM", value: 4},
  {label: "AUCTIONROOMBID", value: 5},
  {label: "USER", value: 6},
  {label: "SALESENGINE", value: 7},
  {label: "LIKE", value: 8},
  {label: "WISHLIST", value: 9},
  {label: "ARTICLE", value: 10},
  {label: "BOAT RENT", value: 11},
  {label: "BOATSHOW", value: 12},
  {label: "FRONT USER", value: 13},
  {label: "BOAT OWNER ADS", value: 14 },
  {label: "BROKER AND DEALER ADS", value: 15},
  {label: "BOAT MANUFACTURER ADS", value: 16},
  {label: "BOAT SERVICE", value: 17},
  {label: "RENT ADS", value: 18},
  {label: "MARINA ADS", value: 19},
  {label: "PENDING VERIFICATION", value: 20},
  {label: "RENT PAYMENT VERIFICATION", value: 21},
  {label: "RELEASE PAYMENT SALES ENGINE VERIFICATION", value: 22},
]

export const adminLogsMethodTypes = [
  // {label: "CREATED", value: 0},
  {label: "UPDATED", value: 1},
  {label: "DELETED", value: 2},
  // {label: "GET", value: 3},
  {label: "APPROVED", value: 4},
  {label: "REJECTED", value: 5},
  {label: "ENABLED", value: 6},
  {label: "DISABLED", value: 7},
  {label: "RELEASE", value: 8},
  {label: "APPROVED GOVERNMENT ID", value: 9},
  {label: "APPROVED COMMERICIAL ID", value: 10},
  {label: "REJECTED GOVERNMENT ID", value: 11},
  {label: "REJECTED COMMERICIAL ID", value: 12}
]

export const activeInactiveNumbers: any = [
  { label: 'Active', value: 1 },
  { label: 'InActive', value: 0 },
]

export const nullableFilters: any = [
  { label: 'Approve', value: 'APPROVE' },
  { label: 'Unapprove', value: 'UNAPPROVE' },
  { label: 'Pending', value: 'PENDING' },
]

export const verifyOrNot: any = [
  { label: 'Verified', value: true },
  { label: 'Unverified', value: false },
]

export const auctionStatus: any = [
  { value: 'APPROVE', label: 'Approved' },
  { value: 'DECLINE', label: 'Declined' },
  // , { value: "AUCTIONENABLED", label: "Auction Enabled" }
  // , { value: "AWARDED", label: "Awarded" }
  { value: 'SOLDOUT', label: 'Sold Out' },
  { value: 'CLOSE', label: 'Closed' },
  { value: 'APPROVALPANDING', label: 'Approval Pending' },
  { value: 'COMINGSOON', label: 'Coming Soon' },
  { value: 'INAUCTION', label: 'In auction' },
]
export const sortEnums: any = [
  { label: 'Ascending', value: 'ASCENDING' },
  { label: 'Descending', value: 'DESCENDING' },
]

export const salesEnginTemplate = {
  11: 'SALES_ENGINE_CREATE',
  12: 'SALES_ENGINE_SENDAGENTREQUEST',
  13: 'SALES_ENGINE_REMOVEAGENT',
  14: 'SALES_ENGINE_NEGOTIATION',
  15: 'SALES_ENGINE_SENDSURVEYORREQUEST',
  16: 'SALES_ENGINE_SURVEYORACCEPT',
  17: 'SALES_ENGINE_SURVEYORDECLINE',
  18: 'SALES_ENGINE_BUYERSKIPSURVEYOR',
  19: 'SALES_ENGINE_BUYERREMOVEDSURVEYOR',
  20: 'SALES_ENGINE_SURVEYORPAYMENT',
  21: 'SALES_ENGINE_SURVEYORREPORT',
  22: 'SALES_ENGINE_BUYERAGREEMENT',
  23: 'SALES_ENGINE_BOATPAYMENT',
  24: 'SALES_ENGINE_BUYERSHIPMENT',
  25: 'SALES_ENGINE_BUYERADDSHIPMENTLOCATION',
  26: 'SALES_ENGINE_SHIPPERPROPOSAL',
  27: 'SALES_ENGINE_BUYERCHOOSESHIPPER',
  28: 'SALES_ENGINE_SHIPPERPAYMENT',
  29: 'SALES_ENGINE_SHIPPERSTARTEDSHIPMENT',
  30: 'SALES_ENGINE_SHIPPERSHIPPEDBOAT',
  31: 'SALES_ENGINE_SHIPPERADDEDDOCUMENT',
  32: 'PENDING_EMAIl_AGREEMENT',
}

export const emailTemplate: any = {
  1: 'EMAIL_USER_REGISTRATION',
  2: 'EMAIL_USER_ACTIVATE',
  3: 'EMAIL_USER_FORGOT_PASSWORD',
  5: 'EMAIL_ADAMSEA_SHARE',
  6: 'EMAIL_USER_EMAIL_CONFIRMATION',
  7: 'EMAIl_AGREEMENT',
  9: 'EMAIl_COSTESTIMATE',
  33: 'REJECT_AGREEMENT_REQUEST',
  34: 'EMAIL_ADAMSEA_USER_SHARE',
  35: 'EMAIL_ADAMSEA_BOATSHOW_SHARE',
  38: 'DELETE_MARKET_AD',
  41: 'RENT_TICKET',
  42: 'CREATE_ADMIN_USER',
  43: 'CHANGE_PASSWORD',
  44: 'WEB_CHANGE_PASSWORD_NOTIFICATION',
  46: 'WEB_USER_VERIFICATION_REQUEST_NOTIFICATION',
  47: 'WEB_USER_FIRST_AD_NOTIFICATION',
  ...salesEnginTemplate,
}
export const smsTemplate: any = {
  4: 'SMS_USER_MOBILE_CONFIRMATION',
  8: 'SMS_AGREEMENT',
  10: 'SMS_VERIFICATION_NOTIFICATION',
}

export const bannerType: any = {
  rent: 'rent',
  'boat service': 'boatService',
  'marina & storage': 'marinaStorage',
  'boat show': 'boatShow',
  home: 'home',
  'header content': 'headerContent',
  'site content': 'siteContent',
  'market service': 'marketService',
  'article media': 'articleMedia',
  downloaded: 'downloaded',
  faq: 'faq',
  'user guide': 'userGuide',
  'other page': 'otherPage',
  'admin login': 'adminLogin',
  'boat media': 'boatMedia',
  login: 'login',
  signup: 'signUp',
  ad: 'ad',
  adamseaMedia: 'adamseaMedia',
  howTo: 'howTo',
  boatPdf: 'boatPdf',
  marinPdf: 'marinPdf',
  yachtPdf: 'yachtPdf',
}

export const imageExtensions =
  '.jpg,.png,.gif,.webp,.jpeg,.tif,.tiff,.bmp,.JPG,.GIF,.PNG,.GIF,.WEBP,.JPEG,.TIF,.TIFF,.BMP,.jfif'

export const salesEngineStaticContentType = {
  agent: { value: 'agent', type: 'Agent' },
  myBoat: { value: 'myBoat', type: 'My Boat' },
  negotiation: { value: 'negotiation', type: 'Negotiation' },
  inspectionPayment: { value: 'inspectionPayment', type: 'Inspection Payment' },
  report: { value: 'report', type: 'Report' },
  certifyMyBoat: { value: 'certifyMyBoat', type: 'Certify My Boat' },
  agreement: { value: 'agreement', type: 'Agreement' },
  payment: { value: 'payment', type: 'Payment' },
  shipment: { value: 'shipment', type: 'Shipment' },
}

export const salesEngineStaticContentStepFor = {
  agent: { type: 'Agent', value: 'agent' },
  members: { type: 'Members', value: 'members' },
  seller: { type: 'Seller', value: 'seller' },
}

export const tooltipText = {
  boat: {
    declineButton: 'When you click on decline boat will move to pending Ads section',
    deleteButton: 'By clicking on delete boat will be removed from the admin and user dashboard',
  },
}

export const rejectAgreementMessage =
  'With submit the reason this agreement ID request will be removed from the list and mail sent to this user'

export const rejectReviewTooltip =
  'Rejecting this review will be out from the index and user will be notify to Resend it with modification'
export const rejectBoatAd =
  'On submission, the request will be removed from the list and a mail with the reason of rejection will be sent to this user '

export const rejectBoatShow =
  'On submission, the boat show will be rejected and a mail with the reason will be sent to the user'

export const rejectErrorMessage = 'Reject message required'

export const fillField = 'Fill the field.'

export const roleName = {
  MEMBER: 'MEMBER',
  BOAT_OWNER: 'BOAT OWNER',
  BROKER_DEALER: 'BROKER AND DEALER',
  BOAT_MANUFACTURER: 'BOAT MANUFACTURER',
  SERVICE_MAINTENANCE: 'SERVICE & MAINTENANCE',
  SURVEYOR: 'SURVEYOR',
  MARINA_STORAGE: 'MARINA & STORAGE',
  RENT_CHARTER: 'RENT & CHARTER',
  YACHT_SHIPPER: 'YACHT SHIPPER',
  AGENT: 'AGENT',
}

export const successNotification = 'success'

export const errorNotification = 'error'

export const resetPasswordSuccess = 'Password reset successful'

export const resetPasswordFailure = 'Password reset failed'

export const invalidFile = 'File Is Invalid'

export const transactionCategory: any = [
  { label: 'Sales Engine', value: 'sales_engine' },
  { label: 'Auction', value: 'auction' },
]

export const transactionSubCategory: any = [
  {
    label: 'Surveryor Payment',
    value: 'surveryor_payment',
    parent: 'sales_engine',
  },
  { label: 'Boat Payment', value: 'boat_payment', parent: 'sales_engine' },
  { label: 'Agent Payment', value: 'agent_payment', parent: 'sales_engine' },
  {
    label: 'Shipper Payment',
    value: 'shipper_payment',
    parent: 'sales_engine',
  },

  {
    label: 'Uncaptured Payment',
    value: 'uncaptured_payment',
    parent: 'auction',
  },
  { label: 'Captured Payment', value: 'captured_payment', parent: 'auction' },
  { label: 'Boat Payment', value: 'boat_payment', parent: 'auction' },
  { label: 'Shipper Payment', value: 'shipper_payment', parent: 'auction' },
]

export type RentBookingStatusType = 'Pending' | 'QRScanned' | 'Started' | 'Ongoing' | 'Settled' | 'Expired' | 'Cancelled'

export const RentBookingStatus = {
  Pending: 'Pending',
  QRScanned: 'QRScanned',
  Started: 'Started',
  Ongoing: 'Ongoing',
  Settled: 'Settled',
  Expired: 'Expired',
  Cancelled: 'Cancelled',
}

export const StatusColors = {
  [RentBookingStatus.Pending]: 'rgb(153, 0, 153)',
  [RentBookingStatus.QRScanned]: 'rgb(255, 192, 0)',
  [RentBookingStatus.Started]: 'rgb(255, 192, 0)',
  [RentBookingStatus.Ongoing]: 'rgb(0, 112, 192)',
  [RentBookingStatus.Settled]: 'rgb(0, 176, 80)',
  [RentBookingStatus.Expired]: 'rgb(255, 51, 0)',
  [RentBookingStatus.Cancelled]: 'rgb(255, 51, 0)',
}

export const RentBookingStatusBadgeClassname = {
  Pending: 'Decline',
  QRScanned: 'in--process',
  Started: 'in--process',
  Ongoing: 'in--process',
  Settled: 'Verified',
  Expired: 'Unverified',
  Cancelled: 'Unverified',
}

export const RentTripStatusLabels = {
  Pending: 'Booked',
  QRScanned: 'QR ✓',
  Started: 'Started',
  Ongoing: 'Started / QR ✓',
  Settled: 'Trip Completed',
  Expired: 'Expired',
  Cancelled: 'Cancelled',
  RefundTransferred: 'Refund Transferred',
}

export const RentTripStatusFilterArray = [
  { label: RentTripStatusLabels['Pending'], value: 'Pending' },
  { label: RentTripStatusLabels['Started'], value: 'Started' },
  { label: RentTripStatusLabels['Ongoing'], value: 'Ongoing' },
  { label: RentTripStatusLabels['Settled'], value: 'Settled' },
  { label: RentTripStatusLabels['Expired'], value: 'Expired' },
  { label: RentTripStatusLabels['Cancelled'], value: 'Cancelled' },
]

export const AdminPermissions = {
  DASHBOARD__FRONT_USERS: {
    label: 'Front Users',
    value: 'DASHBOARD__FRONT_USERS',
  },
  DASHBOARD__ADMIN_USERS: {
    label: 'Admin Users',
    value: 'DASHBOARD__ADMIN_USERS',
  },
  DASHBOARD__OTHERS: {
    label: 'Others',
    value: 'DASHBOARD__OTHERS',
  },
  DASHBOARD__MANAGE_AGREEMENTS: {
    label: 'Manage Agreements',
    value: 'DASHBOARD__MANAGE_AGREEMENTS',
  },
  DASHBOARD__MANAGE_SALES_ENGINES: {
    label: 'Manage Sales Engines',
    value: 'DASHBOARD__MANAGE_SALES_ENGINES',
  },
  DASHBOARD__MANAGE_AUCTION_ROOMS: {
    label: 'Manage Auction Rooms',
    value: 'DASHBOARD__MANAGE_AUCTION_ROOMS',
  },
  DASHBOARD__MANAGE_RENT: {
    label: 'Manage Rent',
    value: 'DASHBOARD__MANAGE_RENT',
  },

  GENERAL_SETTINGS: {
    label: 'General Settings',
    value: 'GENERAL_SETTINGS',
  },
  PENDING_VERIFICATION: {
    label: 'Pending Verification',
    value: 'PENDING_VERIFICATION',
  },

  FRONT_USERS__MEMBER: {
    label: 'Member',
    value: 'FRONT_USERS__MEMBER',
  },
  FRONT_USERS__BOAT_OWNER: {
    label: 'Boat Owner',
    value: 'FRONT_USERS__BOAT_OWNER',
  },
  FRONT_USERS__BROKER_AND_DEALER: {
    label: 'Broker & Dealer',
    value: 'FRONT_USERS__BROKER_AND_DEALER',
  },
  FRONT_USERS__BOAT_MANUFACTURER: {
    label: 'Boat Manufacturer',
    value: 'FRONT_USERS__BOAT_MANUFACTURER',
  },
  FRONT_USERS__SHIPMENT: {
    label: 'Shipment',
    value: 'FRONT_USERS__SHIPMENT',
  },
  FRONT_USERS__SURVEYOR: {
    label: 'Surveyor',
    value: 'FRONT_USERS__SURVEYOR',
  },
  FRONT_USERS__RENT: {
    label: 'Rent',
    value: 'FRONT_USERS__RENT',
  },
  FRONT_USERS__SERVICE_AND_MAINTENANCE: {
    label: 'Service & Maintenance',
    value: 'FRONT_USERS__SERVICE_AND_MAINTENANCE',
  },
  FRONT_USERS__MARINA_AND_STORAGE: {
    label: 'Marina & Storage',
    value: 'FRONT_USERS__MARINA_AND_STORAGE',
  },
  FRONT_USERS__ADAMSEA_AGENT: {
    label: 'AdamSea Agent',
    value: 'FRONT_USERS__ADAMSEA_AGENT',
  },

  EMAIL_SETTINGS__MANAGE_SMTP: {
    label: 'Manage SMTP',
    value: 'EMAIL_SETTINGS__MANAGE_SMTP',
  },
  EMAIL_SETTINGS__EMAIL_TEMPLATES: {
    label: 'Email Templates',
    value: 'EMAIL_SETTINGS__EMAIL_TEMPLATES',
  },

  SMS_TEMPLATES: {
    label: 'SMS Templates',
    value: 'SMS_TEMPLATES',
  },
  SEO_META_TAGS: {
    label: 'SEO Meta Tags',
    value: 'SEO_META_TAGS',
  },
  MANAGE_TYPE: {
    label: 'Manage Type',
    value: 'MANAGE_TYPE',
  },

  MANAGE_BOATS__ALL_COUNTRY_BOATS: {
    label: 'All Country Boats',
    value: 'MANAGE_BOATS__ALL_COUNTRY_BOATS',
  },
  MANAGE_BOATS__MANAGE_ALL_BOATS: {
    label: 'Manage All Boats',
    value: 'MANAGE_BOATS__MANAGE_ALL_BOATS',
  },
  MANAGE_BOATS__MANAGE_FEATURED_BOATS: {
    label: 'Manage Featured Boats',
    value: 'MANAGE_BOATS__MANAGE_FEATURED_BOATS',
  },
  MANAGE_BOATS__MANAGE_BEST_DEAL_BOATS: {
    label: 'Manage Best Deal Boats',
    value: 'MANAGE_BOATS__MANAGE_BEST_DEAL_BOATS',
  },
  MANAGE_BOATS__MANAGE_LITTLE_BOATS: {
    label: 'Manage Little Boats',
    value: 'MANAGE_BOATS__MANAGE_LITTLE_BOATS',
  },

  MANAGE_RENT_BOATS__MANAGE_RENT_BOATS: {
    label: 'Manage Rent Boats',
    value: 'MANAGE_RENT_BOATS__MANAGE_RENT_BOATS',
  },
  MANAGE_RENT_BOATS__MANAGE_RECOMMENDED: {
    label: 'Manage Recommended',
    value: 'MANAGE_RENT_BOATS__MANAGE_RECOMMENDED',
  },
  MANAGE_RENT_BOATS__MOST_POPULAR: {
    label: 'Most Popular',
    value: 'MANAGE_RENT_BOATS__MOST_POPULAR',
  },
  MANAGE_RENT_BOATS__MANAGE_LIVE_EXPERIENCE: {
    label: 'Manage Live Experience',
    value: 'MANAGE_RENT_BOATS__MANAGE_LIVE_EXPERIENCE',
  },
  MANAGE_RENT_BOATS__MANAGE_PAYMENTS: {
    label: 'Manage Payments',
    value: 'MANAGE_RENT_BOATS__MANAGE_PAYMENTS',
  },

  MANAGE_BOAT_SERVICE__MANAGE_ALL_BOAT_SERVICE: {
    label: 'Manage All Boat Service',
    value: 'MANAGE_BOAT_SERVICE__MANAGE_ALL_BOAT_SERVICE',
  },
  MANAGE_BOAT_SERVICE__MANAGE_NEAREST_YACHT: {
    label: 'Manage Nearest Yacht',
    value: 'MANAGE_BOAT_SERVICE__MANAGE_NEAREST_YACHT',
  },
  MANAGE_BOAT_SERVICE__MANAGE_TOP_RATED_YACHT: {
    label: 'Manage Top Rated Yacht',
    value: 'MANAGE_BOAT_SERVICE__MANAGE_TOP_RATED_YACHT',
  },

  MANAGE_MARINA_AND_STORAGE__MANAGE_ALL_MARINA_BOATS: {
    label: 'Manage All Marina Boats',
    value: 'MANAGE_MARINA_AND_STORAGE__MANAGE_ALL_MARINA_BOATS',
  },
  MANAGE_MARINA_AND_STORAGE__MANAGE_POPULAR_MARINA: {
    label: 'Manage Popular Marina',
    value: 'MANAGE_MARINA_AND_STORAGE__MANAGE_POPULAR_MARINA',
  },
  MANAGE_MARINA_AND_STORAGE__MANAGE_MORE_SERVICES: {
    label: 'Manage More Services',
    value: 'MANAGE_MARINA_AND_STORAGE__MANAGE_MORE_SERVICES',
  },
  MANAGE_MARINA_AND_STORAGE__MANAGE_NEAREST_MARINA: {
    label: 'Manage Nearest Marina',
    value: 'MANAGE_MARINA_AND_STORAGE__MANAGE_NEAREST_MARINA',
  },
  MANAGE_MARINA_AND_STORAGE__MANAGE_LIVE_EXPERIENCE: {
    label: 'Manage Live Experience',
    value: 'MANAGE_MARINA_AND_STORAGE__MANAGE_LIVE_EXPERIENCE',
  },

  MANAGE_BOAT_SHOWS: {
    label: 'Manage Boat Shows',
    value: 'MANAGE_BOAT_SHOWS',
  },

  PENDING_ADS__BOAT_OWNER_ADS: {
    label: 'Boat Owner Ads',
    value: 'PENDING_ADS__BOAT_OWNER_ADS',
  },
  PENDING_ADS__BROKER_AND_DEALER_ADS: {
    label: 'Broker & Dealer Ads',
    value: 'PENDING_ADS__BROKER_AND_DEALER_ADS',
  },
  PENDING_ADS__BOAT_MANUFACTURER_ADS: {
    label: 'Boat Manufacturer Ads',
    value: 'PENDING_ADS__BOAT_MANUFACTURER_ADS',
  },
  PENDING_ADS__BOAT_SERVICE_ADS: {
    label: 'Boat Service Ads',
    value: 'PENDING_ADS__BOAT_SERVICE_ADS',
  },
  PENDING_ADS__RENT_ADS: {
    label: 'Rent Ads',
    value: 'PENDING_ADS__RENT_ADS',
  },
  PENDING_ADS__MARINA_AND_STORAGE_ADS: {
    label: 'Marina & Storage Ads',
    value: 'PENDING_ADS__MARINA_AND_STORAGE_ADS',
  },

  PAYMENT_METHODS: {
    label: 'Payment Methods',
    value: 'PAYMENT_METHODS',
  },

  MANAGE_COMPLAINTS__MANAGE_AD_COMPLAINTS: {
    label: 'Manage Ad Complaints',
    value: 'MANAGE_COMPLAINTS__MANAGE_AD_COMPLAINTS',
  },
  MANAGE_COMPLAINTS__MANAGE_REVIEW_COMPLAINTS: {
    label: 'Manage Review Complaints',
    value: 'MANAGE_COMPLAINTS__MANAGE_REVIEW_COMPLAINTS',
  },

  MANAGE_AGREEMENTS__BUILDER_AGREEMENTS: {
    label: 'Builder Agreements',
    value: 'MANAGE_AGREEMENTS__BUILDER_AGREEMENTS',
  },
  MANAGE_AGREEMENTS__SHIPMENT_AGREEMENTS: {
    label: 'Shipment Agreements',
    value: 'MANAGE_AGREEMENTS__SHIPMENT_AGREEMENTS',
  },
  MANAGE_AGREEMENTS__SURVEYOR_AGREEMENTS: {
    label: 'Surveyor Agreements',
    value: 'MANAGE_AGREEMENTS__SURVEYOR_AGREEMENTS',
  },
  MANAGE_AGREEMENTS__ADD_AGREEMENT_CONTENT: {
    label: 'Add Agreement Content',
    value: 'MANAGE_AGREEMENTS__ADD_AGREEMENT_CONTENT',
  },
  MANAGE_AGREEMENTS__AGREEMENT_REQUESTS: {
    label: 'Agreement Requests',
    value: 'MANAGE_AGREEMENTS__AGREEMENT_REQUESTS',
  },

  MANAGE_VIDEOS: {
    label: 'Manage Videos',
    value: 'MANAGE_VIDEOS',
  },
  MANAGE_DOWNLOADS: {
    label: 'Manage Downloads',
    value: 'MANAGE_DOWNLOADS',
  },

  STATIC_CONTENT__MANAGE_AGREEMENTS_LISTING: {
    label: 'Manage Agreements Listing',
    value: 'STATIC_CONTENT__MANAGE_AGREEMENTS_LISTING',
  },
  STATIC_CONTENT__MANAGE_PAGES: {
    label: 'Manage Pages',
    value: 'STATIC_CONTENT__MANAGE_PAGES',
  },
  STATIC_CONTENT__MANAGE_RENT_PAGES: {
    label: 'Manage Rent Pages',
    value: 'STATIC_CONTENT__MANAGE_RENT_PAGES',
  },
  STATIC_CONTENT__MANAGE_SALES_ENGINE_PAGES: {
    label: 'Manage Sales Engine Pages',
    value: 'STATIC_CONTENT__MANAGE_SALES_ENGINE_PAGES',
  },
  STATIC_CONTENT__FORGOT_PASSWORD: {
    label: 'Forgot Password',
    value: 'STATIC_CONTENT__FORGOT_PASSWORD',
  },
  STATIC_CONTENT__MANAGE_ARTICLES: {
    label: 'Manage Articles',
    value: 'STATIC_CONTENT__MANAGE_ARTICLES',
  },
  STATIC_CONTENT__MANAGE_USER_GUIDE: {
    label: 'Manage User Guide',
    value: 'STATIC_CONTENT__MANAGE_USER_GUIDE',
  },
  STATIC_CONTENT__MANAGE_JOBS: {
    label: 'Manage Jobs',
    value: 'STATIC_CONTENT__MANAGE_JOBS',
  },
  STATIC_CONTENT__MANAGE_FAQS: {
    label: 'Manage FAQs',
    value: 'STATIC_CONTENT__MANAGE_FAQS',
  },
  STATIC_CONTENT__REGISTRATION_HEADER: {
    label: 'Registration Header',
    value: 'STATIC_CONTENT__REGISTRATION_HEADER',
  },
  STATIC_CONTENT__REGISTRATION_CONTENT: {
    label: 'Registration Content',
    value: 'STATIC_CONTENT__REGISTRATION_CONTENT',
  },
  STATIC_CONTENT__HELP_SECTION: {
    label: 'Help Section',
    value: 'STATIC_CONTENT__HELP_SECTION',
  },

  MANAGE_CATEGORY__MANAGE_RENT_TYPE: {
    label: 'Manage Rent Type',
    value: 'MANAGE_CATEGORY__MANAGE_RENT_TYPE',
  },
  MANAGE_CATEGORY__MANAGE_MARINA_TYPE: {
    label: 'Manage Marina Type',
    value: 'MANAGE_CATEGORY__MANAGE_MARINA_TYPE',
  },
  MANAGE_CATEGORY__MANAGE_BOAT_TYPES: {
    label: 'Manage Boat Types',
    value: 'MANAGE_CATEGORY__MANAGE_BOAT_TYPES',
  },
  MANAGE_CATEGORY__MANAGE_YATCH_SERVICE: {
    label: 'Manage Yacht Service',
    value: 'MANAGE_CATEGORY__MANAGE_YATCH_SERVICE',
  },

  COUNTRY__MANAGE_COUNTRIES: {
    label: 'Manage Countries',
    value: 'COUNTRY__MANAGE_COUNTRIES',
  },
  COUNTRY__MANAGE_STATE_PROVINCE: {
    label: 'Manage State Province',
    value: 'COUNTRY__MANAGE_STATE_PROVINCE',
  },

  FORMS__COMPLAINT: {
    label: 'Complaint',
    value: 'FORMS__COMPLAINT',
  },
  FORMS__CONTACT_US: {
    label: 'Contact Us',
    value: 'FORMS__CONTACT_US',
  },
  FORMS__SUGGESTIONS: {
    label: 'Suggestions',
    value: 'FORMS__SUGGESTIONS',
  },
  FORMS__CUSTOMER_SERVICES: {
    label: 'Customer Services',
    value: 'FORMS__CUSTOMER_SERVICES',
  },
  FORMS__FEEDBACK: {
    label: 'Feedback',
    value: 'FORMS__FEEDBACK',
  },

  SALES_ENGINE__COMMISSION_AND_SERVICE_FEES: {
    label: 'Commission & Service Fees',
    value: 'SALES_ENGINE__COMMISSION_AND_SERVICE_FEES',
  },
  SALES_ENGINE__RELEASE_PAYMENT: {
    label: 'Release Payment',
    value: 'SALES_ENGINE__RELEASE_PAYMENT',
  },
  SALES_ENGINE__SALES_ENGINE_PAYMENT: {
    label: 'Sales Engine Payment',
    value: 'SALES_ENGINE__SALES_ENGINE_PAYMENT',
  },
  SALES_ENGINE__SALES_ENGINE_PROGRESS: {
    label: 'Sales Engine Progress',
    value: 'SALES_ENGINE__SALES_ENGINE_PROGRESS',
  },
  SALES_ENGINE__MANAGE_SALES_ENGINE: {
    label: 'Manage Sales Engine',
    value: 'SALES_ENGINE__MANAGE_SALES_ENGINE',
  },
  SALES_ENGINE__ARCHIVE_SALES_PROCESS: {
    label: 'Archive Sales Process',
    value: 'SALES_ENGINE__ARCHIVE_SALES_PROCESS',
  },
  SALES_ENGINE__BLACKLIST_SALES_PROCESS: {
    label: 'Blacklist Sales Process',
    value: 'SALES_ENGINE__BLACKLIST_SALES_PROCESS',
  },
  SALES_ENGINE__MANAGE_HELP_CONTENT: {
    label: 'Manage Help Content',
    value: 'SALES_ENGINE__MANAGE_HELP_CONTENT',
  },
  SALES_ENGINE__MANAGE_STATIC_CONTENT: {
    label: 'Manage Static Content',
    value: 'SALES_ENGINE__MANAGE_STATIC_CONTENT',
  },
  SALES_ENGINE__MANAGE_SURVEY_DOCUMENT_DATA: {
    label: 'Manage Survey Document Data',
    value: 'SALES_ENGINE__MANAGE_SURVEY_DOCUMENT_DATA',
  },

  MANAGE_BANNER_IMAGES__HOME_PAGE_BANNER: {
    label: 'Home Page Banner',
    value: 'MANAGE_BANNER_IMAGES__HOME_PAGE_BANNER',
  },
  MANAGE_BANNER_IMAGES__MARKET_RENT_BANNER: {
    label: 'Market Rent Banner',
    value: 'MANAGE_BANNER_IMAGES__MARKET_RENT_BANNER',
  },
  MANAGE_BANNER_IMAGES__BOAT_SHOW_BANNER: {
    label: 'Boat Show Banner',
    value: 'MANAGE_BANNER_IMAGES__BOAT_SHOW_BANNER',
  },
  MANAGE_BANNER_IMAGES__MARKET_SERVICE_BANNER: {
    label: 'Market Service Banner',
    value: 'MANAGE_BANNER_IMAGES__MARKET_SERVICE_BANNER',
  },
  MANAGE_BANNER_IMAGES__BOAT_MEDIA_BANNER: {
    label: 'Boat Media Banner',
    value: 'MANAGE_BANNER_IMAGES__BOAT_MEDIA_BANNER',
  },
  MANAGE_BANNER_IMAGES__MARKET_MARINA_BANNER: {
    label: 'Market Marina Banner',
    value: 'MANAGE_BANNER_IMAGES__MARKET_MARINA_BANNER',
  },
  MANAGE_BANNER_IMAGES__ARTICLE_BANNER: {
    label: 'Article Banner',
    value: 'MANAGE_BANNER_IMAGES__ARTICLE_BANNER',
  },
  MANAGE_BANNER_IMAGES__DOWNLOAD_BANNER: {
    label: 'Download Banner',
    value: 'MANAGE_BANNER_IMAGES__DOWNLOAD_BANNER',
  },
  MANAGE_BANNER_IMAGES__FAQ_BANNER: {
    label: 'FAQ Banner',
    value: 'MANAGE_BANNER_IMAGES__FAQ_BANNER',
  },
  MANAGE_BANNER_IMAGES__USER_GUIDE_BANNER: {
    label: 'User Guide Banner',
    value: 'MANAGE_BANNER_IMAGES__USER_GUIDE_BANNER',
  },
  MANAGE_BANNER_IMAGES__OTHER_PAGE_BANNER: {
    label: 'Other Page Banner',
    value: 'MANAGE_BANNER_IMAGES__OTHER_PAGE_BANNER',
  },
  MANAGE_BANNER_IMAGES__ADMIN_LOGIN_BANNER: {
    label: 'Admin Login Banner',
    value: 'MANAGE_BANNER_IMAGES__ADMIN_LOGIN_BANNER',
  },
  MANAGE_BANNER_IMAGES__LOGIN_BANNER: {
    label: 'Login Banner',
    value: 'MANAGE_BANNER_IMAGES__LOGIN_BANNER',
  },
  MANAGE_BANNER_IMAGES__SIGNUP_BANNER: {
    label: 'Signup Banner',
    value: 'MANAGE_BANNER_IMAGES__SIGNUP_BANNER',
  },

  MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOMS: {
    label: 'Manage Auction Rooms',
    value: 'MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOMS',
  },
  MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOM_PAYMENTS: {
    label: 'Manage Auction Room Payments',
    value: 'MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOM_PAYMENTS',
  },

  MANAGE_SURVEYOR_BRANCH__ALL_BRANCHES: {
    label: 'All Branches',
    value: 'MANAGE_SURVEYOR_BRANCH__ALL_BRANCHES',
  },

  ACTIVITY_LOGS: {
    label: 'Activity Logs',
    value: 'ACTIVITY_LOGS',
  },

  ADMIN_ACTIVITY_LOGS: {
    label: 'Admin Activity Logs',
    value: 'ADMIN_ACTIVITY_LOGS',
  },

  TIMER_SETTINGS: {
    label: 'Timer Settings',
    value: 'TIMER_SETTINGS',
  },

  MANAGE_REVIEWS__BOAT_OWNER_REVIEWS: {
    label: 'Boat Owner Reviews',
    value: 'MANAGE_REVIEWS__BOAT_OWNER_REVIEWS',
  },
  MANAGE_REVIEWS__BOAT_MANUFACTURER_REVIEWS: {
    label: 'Boat Manufacturer Reviews',
    value: 'MANAGE_REVIEWS__BOAT_MANUFACTURER_REVIEWS',
  },
  MANAGE_REVIEWS__SHIPPER_REVIEWS: {
    label: 'Shipper Reviews',
    value: 'MANAGE_REVIEWS__SHIPPER_REVIEWS',
  },
  MANAGE_REVIEWS__BROKER_AND_DEALER_REVIEWS: {
    label: 'Broker & Dealer Reviews',
    value: 'MANAGE_REVIEWS__BROKER_AND_DEALER_REVIEWS',
  },

  USER_ACTIVITIES__READ: {
    label: 'AWS Personalize',
    value: 'USER_ACTIVITIES__READ',
  },

  SOCIAL_MEDIA__POST_REPORTS: {
    label: 'Social Media - Post Reports',
    value: 'SOCIAL_MEDIA__POST_REPORTS'
  },

  MEDIA_HANDLER: {
    label: 'Media Handler',
    value: 'MEDIA_HANDLER',
  },
}

export const AdminPermissionGroups = {
  ALL: {
    label: 'All',
    value: 'ALL',
    permissions: [
      AdminPermissions.GENERAL_SETTINGS,
      AdminPermissions.PENDING_VERIFICATION,
      AdminPermissions.SMS_TEMPLATES,
      AdminPermissions.SEO_META_TAGS,
      AdminPermissions.MANAGE_TYPE,
      AdminPermissions.MANAGE_BOAT_SHOWS,
      AdminPermissions.PAYMENT_METHODS,
      AdminPermissions.MANAGE_VIDEOS,
      AdminPermissions.MANAGE_DOWNLOADS,
      AdminPermissions.ACTIVITY_LOGS,
      AdminPermissions.ADMIN_ACTIVITY_LOGS,
      AdminPermissions.TIMER_SETTINGS,
      AdminPermissions.USER_ACTIVITIES__READ,
      AdminPermissions.MEDIA_HANDLER,
    ],
  },
  DASHBOARD: {
    label: 'Dashboard',
    value: 'DASHBOARD',
    permissions: [
      AdminPermissions.DASHBOARD__FRONT_USERS,
      AdminPermissions.DASHBOARD__ADMIN_USERS,
      AdminPermissions.DASHBOARD__OTHERS,
      AdminPermissions.DASHBOARD__MANAGE_AGREEMENTS,
      AdminPermissions.DASHBOARD__MANAGE_SALES_ENGINES,
      AdminPermissions.DASHBOARD__MANAGE_AUCTION_ROOMS,
    ],
  },
  FRONT_USERS: {
    label: 'Front Users',
    value: 'FRONT_USERS',
    permissions: [
      AdminPermissions.FRONT_USERS__MEMBER,
      AdminPermissions.FRONT_USERS__BOAT_OWNER,
      AdminPermissions.FRONT_USERS__BROKER_AND_DEALER,
      AdminPermissions.FRONT_USERS__BOAT_MANUFACTURER,
      AdminPermissions.FRONT_USERS__SHIPMENT,
      AdminPermissions.FRONT_USERS__SURVEYOR,
      AdminPermissions.FRONT_USERS__RENT,
      AdminPermissions.FRONT_USERS__SERVICE_AND_MAINTENANCE,
      AdminPermissions.FRONT_USERS__MARINA_AND_STORAGE,
      AdminPermissions.FRONT_USERS__ADAMSEA_AGENT,
    ],
  },
  EMAIL_SETTINGS: {
    label: 'Email Settings',
    value: 'EMAIL_SETTINGS',
    permissions: [AdminPermissions.EMAIL_SETTINGS__MANAGE_SMTP, AdminPermissions.EMAIL_SETTINGS__EMAIL_TEMPLATES],
  },
  MANAGE_BOATS: {
    label: 'Manage Boats',
    value: 'MANAGE_BOATS',
    permissions: [
      AdminPermissions.MANAGE_BOATS__ALL_COUNTRY_BOATS,
      AdminPermissions.MANAGE_BOATS__MANAGE_ALL_BOATS,
      AdminPermissions.MANAGE_BOATS__MANAGE_FEATURED_BOATS,
      AdminPermissions.MANAGE_BOATS__MANAGE_BEST_DEAL_BOATS,
      AdminPermissions.MANAGE_BOATS__MANAGE_LITTLE_BOATS,
    ],
  },
  MANAGE_RENT_BOATS: {
    label: 'Manage Rent Boats',
    value: 'MANAGE_RENT_BOATS',
    permissions: [
      AdminPermissions.MANAGE_RENT_BOATS__MANAGE_RENT_BOATS,
      AdminPermissions.MANAGE_RENT_BOATS__MANAGE_RECOMMENDED,
      AdminPermissions.MANAGE_RENT_BOATS__MOST_POPULAR,
      AdminPermissions.MANAGE_RENT_BOATS__MANAGE_LIVE_EXPERIENCE,
      AdminPermissions.MANAGE_RENT_BOATS__MANAGE_PAYMENTS,
    ],
  },
  MANAGE_BOAT_SERVICE: {
    label: 'Manage Boat Service',
    value: 'MANAGE_BOAT_SERVICE',
    permissions: [
      AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_ALL_BOAT_SERVICE,
      AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_NEAREST_YACHT,
      AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_TOP_RATED_YACHT,
    ],
  },
  MANAGE_MARINA_AND_STORAGE: {
    label: 'Manage Marina & Storage',
    value: 'MANAGE_MARINA_AND_STORAGE',
    permissions: [
      AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_ALL_MARINA_BOATS,
      AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_POPULAR_MARINA,
      AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_MORE_SERVICES,
      AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_NEAREST_MARINA,
      AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_LIVE_EXPERIENCE,
    ],
  },
  PENDING_ADS: {
    label: 'Pending Ads',
    value: 'PENDING_ADS',
    permissions: [
      AdminPermissions.PENDING_ADS__BOAT_OWNER_ADS,
      AdminPermissions.PENDING_ADS__BROKER_AND_DEALER_ADS,
      AdminPermissions.PENDING_ADS__BOAT_MANUFACTURER_ADS,
      AdminPermissions.PENDING_ADS__BOAT_SERVICE_ADS,
      AdminPermissions.PENDING_ADS__RENT_ADS,
      AdminPermissions.PENDING_ADS__MARINA_AND_STORAGE_ADS,
    ],
  },
  MANAGE_COMPLAINTS: {
    label: 'Manage Complaints',
    value: 'MANAGE_COMPLAINTS',
    permissions: [
      AdminPermissions.MANAGE_COMPLAINTS__MANAGE_AD_COMPLAINTS,
      AdminPermissions.MANAGE_COMPLAINTS__MANAGE_REVIEW_COMPLAINTS,
    ],
  },
  MANAGE_AGREEMENTS: {
    label: 'Manage Agreements',
    value: 'MANAGE_AGREEMENTS',
    permissions: [
      AdminPermissions.MANAGE_AGREEMENTS__BUILDER_AGREEMENTS,
      AdminPermissions.MANAGE_AGREEMENTS__SHIPMENT_AGREEMENTS,
      AdminPermissions.MANAGE_AGREEMENTS__SURVEYOR_AGREEMENTS,
      AdminPermissions.MANAGE_AGREEMENTS__ADD_AGREEMENT_CONTENT,
      AdminPermissions.MANAGE_AGREEMENTS__AGREEMENT_REQUESTS,
    ],
  },
  STATIC_CONTENT: {
    label: 'Static Content',
    value: 'STATIC_CONTENT',
    permissions: [
      AdminPermissions.STATIC_CONTENT__MANAGE_AGREEMENTS_LISTING,
      AdminPermissions.STATIC_CONTENT__MANAGE_PAGES,
      AdminPermissions.STATIC_CONTENT__MANAGE_RENT_PAGES,
      AdminPermissions.STATIC_CONTENT__MANAGE_SALES_ENGINE_PAGES,
      AdminPermissions.STATIC_CONTENT__FORGOT_PASSWORD,
      AdminPermissions.STATIC_CONTENT__MANAGE_ARTICLES,
      AdminPermissions.STATIC_CONTENT__MANAGE_USER_GUIDE,
      AdminPermissions.STATIC_CONTENT__MANAGE_JOBS,
      AdminPermissions.STATIC_CONTENT__MANAGE_FAQS,
      AdminPermissions.STATIC_CONTENT__REGISTRATION_HEADER,
      AdminPermissions.STATIC_CONTENT__REGISTRATION_CONTENT,
      AdminPermissions.STATIC_CONTENT__HELP_SECTION,
    ],
  },
  MANAGE_CATEGORY: {
    label: 'Manage Category',
    value: 'MANAGE_CATEGORY',
    permissions: [
      AdminPermissions.MANAGE_CATEGORY__MANAGE_RENT_TYPE,
      AdminPermissions.MANAGE_CATEGORY__MANAGE_MARINA_TYPE,
      AdminPermissions.MANAGE_CATEGORY__MANAGE_BOAT_TYPES,
      AdminPermissions.MANAGE_CATEGORY__MANAGE_YATCH_SERVICE,
    ],
  },
  COUNTRY: {
    label: 'Country',
    value: 'COUNTRY',
    permissions: [AdminPermissions.COUNTRY__MANAGE_COUNTRIES, AdminPermissions.COUNTRY__MANAGE_STATE_PROVINCE],
  },
  FORMS: {
    label: 'Forms',
    value: 'FORMS',
    permissions: [
      AdminPermissions.FORMS__COMPLAINT,
      AdminPermissions.FORMS__CONTACT_US,
      AdminPermissions.FORMS__SUGGESTIONS,
      AdminPermissions.FORMS__CUSTOMER_SERVICES,
      AdminPermissions.FORMS__FEEDBACK,
    ],
  },
  SALES_ENGINE: {
    label: 'Sales Engine',
    value: 'SALES_ENGINE',
    permissions: [
      AdminPermissions.SALES_ENGINE__COMMISSION_AND_SERVICE_FEES,
      AdminPermissions.SALES_ENGINE__RELEASE_PAYMENT,
      AdminPermissions.SALES_ENGINE__SALES_ENGINE_PAYMENT,
      AdminPermissions.SALES_ENGINE__SALES_ENGINE_PROGRESS,
      AdminPermissions.SALES_ENGINE__MANAGE_SALES_ENGINE,
      AdminPermissions.SALES_ENGINE__ARCHIVE_SALES_PROCESS,
      AdminPermissions.SALES_ENGINE__BLACKLIST_SALES_PROCESS,
      AdminPermissions.SALES_ENGINE__MANAGE_HELP_CONTENT,
      AdminPermissions.SALES_ENGINE__MANAGE_STATIC_CONTENT,
      AdminPermissions.SALES_ENGINE__MANAGE_SURVEY_DOCUMENT_DATA,
    ],
  },
  MANAGE_BANNER_IMAGES: {
    label: 'Manage Banner Images',
    value: 'MANAGE_BANNER_IMAGES',
    permissions: [
      AdminPermissions.MANAGE_BANNER_IMAGES__HOME_PAGE_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_RENT_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__BOAT_SHOW_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_SERVICE_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__BOAT_MEDIA_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_MARINA_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__ARTICLE_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__DOWNLOAD_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__FAQ_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__USER_GUIDE_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__OTHER_PAGE_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__ADMIN_LOGIN_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__LOGIN_BANNER,
      AdminPermissions.MANAGE_BANNER_IMAGES__SIGNUP_BANNER,
    ],
  },
  MANAGE_AUCTION_ROOMS: {
    label: 'Manage Auction Rooms',
    value: 'MANAGE_AUCTION_ROOMS',
    permissions: [
      AdminPermissions.MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOMS,
      AdminPermissions.MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOM_PAYMENTS,
    ],
  },
  MANAGE_SURVEYOR_BRANCH: {
    label: 'Manage Surveyor Branch',
    value: 'MANAGE_SURVEYOR_BRANCH',
    permissions: [AdminPermissions.MANAGE_SURVEYOR_BRANCH__ALL_BRANCHES],
  },
  MANAGE_REVIEWS: {
    label: 'Manage Reviews',
    value: 'MANAGE_REVIEWS',
    permissions: [
      AdminPermissions.MANAGE_REVIEWS__BOAT_OWNER_REVIEWS,
      AdminPermissions.MANAGE_REVIEWS__BOAT_MANUFACTURER_REVIEWS,
      AdminPermissions.MANAGE_REVIEWS__SHIPPER_REVIEWS,
      AdminPermissions.MANAGE_REVIEWS__BROKER_AND_DEALER_REVIEWS,
    ],
  },
  SOCIAL_MEDIA: {
    label: 'Social Media',
    value: 'SOCIAL_MEDIA',
    permissions: [
      AdminPermissions.SOCIAL_MEDIA__POST_REPORTS,
    ],
  }
}

export const passwordStrengthEnum = {
  POOR: 'Poor',
  WEAK: 'Weak',
  AVERAGE: 'Average',
  STRONG: 'Strong',
}
