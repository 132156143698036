import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button, Menu, Dropdown, Select } from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ReactQuill from 'react-quill'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import History from '../../../History'

import {
  createManageUserGuide,
  updateManageUserGuide,
  clearUserGuideFlag,
} from '../../../store/staticContent/manageUserGuide/Action'
import { userGuideData } from '../../../store/staticContent/manageUserGuide/Type'
import { CKEditorWrapper } from '../../../component/CKEditor'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { removePTag, trimmedValue } from '../../../helpers/helper'

const { Text } = Typography

interface Props {
  createManageUserGuide: typeof createManageUserGuide
  location: any
  updateManageUserGuide: typeof updateManageUserGuide
  history: typeof History
  userGuideSuccess: boolean
  clearUserGuideFlag: typeof clearUserGuideFlag
}
interface State {
  editUserGuide: any
  isEdit: boolean
  manageUserGuide: {
    title: string
    description: string
  }
}

class CreateUserGuide extends Component<Props, State> {
  formSubmitHandler = (values: userGuideData) => {
    const { editUserGuide, isEdit } = this.state
    const { createManageUserGuide, updateManageUserGuide } = this.props
    if (isEdit) {
      updateManageUserGuide(values)
    } else {
      createManageUserGuide(values)
    }
  }

  componentDidMount() {
    const { clearUserGuideFlag } = this.props
    clearUserGuideFlag()
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      editUserGuide: props.location.state ? props.location.state.userGuide.record : null,
      isEdit: props.location.state ? props.location.state.userGuide.isEdit : null,
      manageUserGuide: {
        title: '',
        description: '',
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { userGuideSuccess, clearUserGuideFlag, history } = props

    if (userGuideSuccess) {
      clearUserGuideFlag()
      history.goBack()
    }
    return null
  }

  render() {
    const { manageUserGuide, editUserGuide } = this.state
    const { history } = this.props

    return (
      <Formik
        initialValues={editUserGuide || manageUserGuide}
        onSubmit={values => {
          this.formSubmitHandler(values)
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title field is required.'),
          description: Yup.string().required('Description field is required.'),
        })}
        render={({ errors, status, touched, setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  Add User Guide
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                    Save
                  </button>
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="Title">
                    Title
                  </span>
                  <Input
                    name="title"
                    placeholder="Title"
                    value={values.title}
                    onChange={e => setFieldValue('title', e.target.value)}
                    onBlur={e => setFieldValue('title', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`title`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="Description">
                    Description
                  </span>
                  {/* <ReactQuill className="editor-height" value={values.description} theme={"snow"} onChange={(values) => setFieldValue('description', values)} /> */}
                  <WSField
                    type="textArea"
                    defaultValue={values.description}
                    onChangeText={(evt: any) => {
                      setFieldValue('description', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('description', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`description`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  userGuideSuccess: state.userGuideReducer && state.userGuideReducer.userGuideSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createManageUserGuide: (data: userGuideData) => dispatch(createManageUserGuide(data)),
  updateManageUserGuide: (data: any) => dispatch(updateManageUserGuide(data)),
  clearUserGuideFlag: () => dispatch(clearUserGuideFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserGuide)
