import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button, Select } from 'antd'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import History from '../../../History'
import Loader from '../../../component/Loading'
import NotificationWithIcon from '../../../component/notification/Notification'
import {
  createAgreementContent,
  updateAgreementContent,
  getSingleAgreementContent,
  clearAgreementFlag,
} from '../../../store/manageAgreements/Action'
import { agreementContentInput, agreementContent } from '../../../store/manageAgreements/Types'
import ReactQuill from 'react-quill'
import { agreementCategory } from '../../../enums/enums'
import WSIcon from '../../../component/WSIcon'
import WSSelect from '../../../component/WSSelect'
import { PlusCircleOutlined } from '@ant-design/icons'
import WSField from '../../../component/Field'
import { removePTag, trimmedValue } from '../../../helpers/helper'

const { Text } = Typography
const { Option } = Select

interface Props {
  history: typeof History
  location: any
  createAgreementContent: typeof createAgreementContent
  updateAgreementContent: typeof updateAgreementContent
  getSingleAgreementContent: typeof getSingleAgreementContent
  agreementContent: agreementContent
  agreementContentSuccess: boolean
  agreementContentError: boolean
  clearAgreementFlag: typeof clearAgreementFlag
}
interface State {
  editAgreementContent: any
  manageAgreementContent: agreementContentInput
  agreementContentId: string
  isEditForm: boolean
}

class CreateAgreementContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      editAgreementContent: props.location.state
        ? props.location.state.agreementContents && props.location.state.agreementContents.record
        : null,
      manageAgreementContent: {
        title: '',
        fileTitle: '',
        body: '',
      },
      agreementContentId: '',
      isEditForm: false,
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (nextProps, prevState) => {
    const { location, agreementContentSuccess, agreementContentError, history, clearAgreementFlag } = nextProps

    // NEED TO CHECK GIVING ERROR ON HISTORY
    if (agreementContentSuccess) {
      clearAgreementFlag()
      history && history.push('/agreement-contents')
    } else if (agreementContentError) {
      NotificationWithIcon('error', 'Invalid inputs')
    } else if (location && location.state) {
      return {
        smsId: location.state,
        isEditForm: true,
      }
    }
    return null
  }

  componentDidMount() {
    const { isEditForm, agreementContentId } = this.state
    const { getSingleAgreementContent } = this.props

    isEditForm && agreementContentId && getSingleAgreementContent(agreementContentId)
  }

  formSubmitHandler = (values: any) => {
    const { editAgreementContent } = this.state
    const { createAgreementContent, updateAgreementContent } = this.props

    if (editAgreementContent) {
      const { titleSlug, createdAt, updatedAt, __typename, ...newValues } = values
      updateAgreementContent(newValues)
    } else {
      createAgreementContent(values)
    }
  }

  render() {
    const { editAgreementContent, manageAgreementContent } = this.state
    const { history, agreementContent } = this.props

    return (
      <Formik
        initialValues={editAgreementContent || manageAgreementContent}
        onSubmit={values => this.formSubmitHandler(values)}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title field is required.'),
          fileTitle: Yup.string().required('File Title field is required.'),
          body: Yup.string().required('Body field is required.'),
        })}
        render={({ setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  {editAgreementContent ? 'Edit' : 'Create'} Agreement Content
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                    {editAgreementContent ? 'Update' : 'Save'}
                  </button>
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="Title">
                    Title
                  </span>
                  <WSSelect
                    defaultValue={values.title}
                    arrayList={agreementCategory && agreementCategory}
                    onChangeText={(e: any) => {
                      console.log(e, 'e/val')

                      setFieldValue('title', e)
                    }}
                    passed="id"
                    listOptions="value"
                  />

                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`title`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="File Title">
                    File Title
                  </span>
                  <Input
                    name="fileTitle"
                    placeholder="File Title"
                    value={values.fileTitle}
                    onChange={e => setFieldValue('fileTitle', e.target.value)}
                    onBlur={e => setFieldValue('fileTitle', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`fileTitle`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="Body">
                    Body
                  </span>
                  <WSField
                    defaultValue=""
                    type="textArea"
                    onChangeText={(values: any) => {
                      setFieldValue('body', values.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('body', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`body`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  agreementContent: state.agreementReducer.agreementContent,
  agreementContentSuccess: state.agreementReducer.agreementContentSuccess,
  agreementContentError: state.agreementReducer.agreementContentError,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createAgreementContent: (data: agreementContentInput) => dispatch(createAgreementContent(data)),
  updateAgreementContent: (data: agreementContentInput) => dispatch(updateAgreementContent(data)),
  getSingleAgreementContent: (data: string) => dispatch(getSingleAgreementContent(data)),
  clearAgreementFlag: () => dispatch(clearAgreementFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAgreementContent)
