import React, { Component } from 'react'
import moment from 'moment'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, Switch, Typography } from 'antd'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'

import { getAllAgreements, changeAgreementStatus, deleteAgreement } from '../../store/manageAgreements/Action'
import { agreement } from '../../store/manageAgreements/Types'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import History from '../../History'
import { formateDate, getSearchedData } from '../../helpers/helper'
import WSIcon from '../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import { getAllBoatPagination, activeInactive } from '../../enums/enums'
import FilterButton from '../filters/FilterButton'
import ApproveButton from '../../component/ApproveButton'
import { TitleSearch } from '../../component/SearchBar'
const { Text } = Typography

let pageFilterArray: any = []

interface Props {
  history: typeof History
  getAllAgreements: typeof getAllAgreements
  agreements: []
  changeAgreementStatus: typeof changeAgreementStatus
  deleteAgreement: typeof deleteAgreement
  totalAgreement: number
}

interface State {
  columns: Array<Object>
  searchedUsers: string
}

class ShipmentAgreementsDashboard extends Component<Props, State> {
  state = {
    searchedUsers: '',
    columns: [
      {
        title: 'Agreement ID ',
        dataIndex: 'agreementId',
        key: 'agreementId',
        sorter: (a: any, b: any) => a.agreementId.length - b.agreementId.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Company Name',
        dataIndex: 'companyName',
        key: 'companyName',
        sorter: (a: any, b: any) => a.companyName.length - b.companyName.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.firstName.length - b.firstName.length,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any, index: number) => <> {record.firstName + ' ' + record.lastName}</>,
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: (a: any, b: any) => a.country.length - b.country.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a: any, b: any) => a.email.length - b.email.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Mobile Number',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string, record: tableRecords, index: number) => <> {formateDate(record && record.createdAt)}</>,
        sorter: (a: any, b: any) => a.createdAt.length - b.createdAt.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a: any, b: any) => a.status.length - b.status.length,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: tableRecords, index: number) => {
          const { changeAgreementStatus } = this.props
          return (
            <Switch
              checked={record.status}
              disabled={record.status}
              onChange={() =>
                changeAgreementStatus({
                  id: record.id,
                  column: 'status',
                  value: !record.status,
                  message: null,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Block"
            />
          )
        },
      },
      {
        title: 'Account Status',
        dataIndex: 'accountActivated',
        key: 'accountActivated',
        render: (text: string, record: tableRecords) => (
          <span>
            {record.user ? (
              <span className="active-status-text">{'Registered'}</span>
            ) : (
              <span className="inactive-status-text">{'Not-registered'}</span>
            )}
          </span>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { changeAgreementStatus } = this.props
          return (
            <>
              <Button className="btn-edit mr-2" onClick={() => this.editAgreements(record)}>
                Edit
              </Button>
              <ApproveButton
                action={changeAgreementStatus}
                input={{ id: record.id, column: 'isDeleted', value: true }}
                buttonTitle="Delete"
                className="btn-delete"
              />
            </>
          )
        },
      },
    ],
  }

  componentDidMount() {
    // const { getAllAgreements } = this.props
    pageFilterArray = [
      {
        title: 'Status',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'status',
      },
    ]
    // getAllAgreements({ ...getAllBoatPagination, userType: 'shipment' })
  }

  editAgreements = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-agreement/shipment', {
      agreements: { record: record, isEdit: true, role: 'YACHT SHIPPER' },
    })
  }

  onSearch = (value: any) => {
    const { getAllAgreements } = this.props
    if (value) {
      this.setState({searchedUsers: value})
      getAllAgreements({ ...getAllBoatPagination, userType: 'shipment', searchTerm: value })
    } else {
      this.setState({searchedUsers: ''})
      getAllAgreements({ ...getAllBoatPagination, userType: 'shipment' })
    }
  }


  render() {
    const { columns, searchedUsers } = this.state
    const { agreements, totalAgreement, getAllAgreements, history } = this.props

    return (
      <Card
        title="Manage All Shipment Agreements"
        extra={
          <>
            <TitleSearch onSearch={this.onSearch} />
            <Button
              className="ml-1 btn-view"
              onClick={() =>
                history.push('/create-agreement/shipment', {
                  agreements: { role: 'YACHT SHIPPER' },
                })
              }
            >
              <WSIcon type={<PlusOutlined />} />
              Add Shipment Agreements
            </Button>
          </>
        }
      >
        <FilterButton
          triggerAction={getAllAgreements}
          filterArray={pageFilterArray}
          extraInput={{ userType: 'shipment' }}
          haveCountry
        />
        <Text className="ant-typography-danger"> here highlighted record shows duplicate entries</Text>
        <ExpandTable
          columns={columns}
          tableData={agreements}
          isExpand={false}
          page="boat"
          extraInput={{ userType: 'shipment', searchTerm: searchedUsers }}
          pageAction={getAllAgreements}
          totalUsersRow={totalAgreement}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  agreements: state.agreementReducer.agreements,
  totalAgreement: state.agreementReducer.totalAgreement,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllAgreements: (data: any) => dispatch(getAllAgreements(data)),
  changeAgreementStatus: (data: any) => dispatch(changeAgreementStatus(data)),
  deleteAgreement: (data: string) => dispatch(deleteAgreement(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentAgreementsDashboard)
