import {
  ADD_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATES,
  emailData,
  CLEAR_EMAIL_TEMPLATE_FLAG,
  DELETE_TEMPLATE,
  GET_VARIABLE,
} from './Types'

export function addEmailTemplate(data: emailData) {
  return {
    type: ADD_EMAIL_TEMPLATE,
    payload: data,
  }
}

export function getEmailTemplates(data: any) {
  return {
    type: GET_EMAIL_TEMPLATES,
    payload: data,
  }
}

export function updateEmailTemplate(data: emailData) {
  return {
    type: UPDATE_EMAIL_TEMPLATE,
    payload: data,
  }
}

export function clearFlag() {
  return {
    type: CLEAR_EMAIL_TEMPLATE_FLAG,
  }
}

export function deleteTemplate(data: string) {
  return {
    type: DELETE_TEMPLATE,
    payload: data,
  }
}

export function getVariableAction(data: any) {
  return {
    type: GET_VARIABLE,
    payload: data,
  }
}
