import React, { Component } from 'react'
import jwtDecode from 'jwt-decode'
import { connect } from 'react-redux'
import { getCurrencyData } from '../store/dashboard/others/Action'
import { logOut } from '../helpers/helper'

export default function (ComposedComponent) {
  class Authentication extends Component {
    componentDidMount() {
      const { getCurrencyData } = this.props
      this.isAuthCheck(this.props)
      // getCurrencyData()
    }

    componentDidUpdate(nextProps) {
      this.isAuthCheck(nextProps)
    }

    isAuthCheck = props => {
      const isAuth = localStorage.getItem('auth_token')

      if (!isAuth) {
        window.location.href = '/login'
      } else {
        const now = Date.now()
        const decoded = jwtDecode(isAuth)
        if (now >= decoded.exp * 1000) {
          logOut()
        }
      }
      return
    }

    render() {
      const isAuth = localStorage.getItem('auth_token')

      return <>{isAuth && <ComposedComponent {...this.props} />}</>
    }
  }

  const mapStateToProps = state => ({
    currencyRates: state.adminOtherCountState?.currencyRates,
  })

  const mapDispatchToProps = dispatch => ({
    getCurrencyData: () => dispatch(getCurrencyData()),
  })

  return connect(mapStateToProps, mapDispatchToProps)(Authentication)
}
