export const PendingAds = {
    BOT_OWNER_ADS: 'BOT_OWNER_ADS',
    BROKER_AND_DEALER_ADS: 'BROKER_AND_DEALER_ADS',
    BOAT_MANUFACTURER_ADS: 'BOAT_MANUFACTURER_ADS',
    BOAT_SERVICE: 'BOAT_SERVICE',
    RENT_ADS: 'RENT_ADS',
    MARINA_ADS: 'MARINA_ADS'
  } as const;

  export const LogType = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    REJECTED: 'REJECTED' 
  } as const