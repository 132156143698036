export interface resetPasswordType {
  id?: string
  newPassword: string
  oldPassword?: string
  email?: string
}

export interface resetPasswordTypeState {
  success: boolean
  error: boolean
}

export interface resetPasswordState {
  resetPasswordReducer: resetPasswordTypeState
}

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

interface getChangePassword {
  type: typeof RESET_PASSWORD
  payload: resetPasswordType
}

interface getChangePasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS
  payload: resetPasswordType
}

interface getChangePasswordFailure {
  type: typeof RESET_PASSWORD_FAILURE
  payload: any
}

export type ActionType = getChangePassword | getChangePasswordSuccess | getChangePasswordFailure
