import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card } from 'antd'

import ExpandTable from '../../component/ExpandTable'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import { TitleSearch } from '../../component/SearchBar'
import { displayDefaultValue, getSearchedData } from '../../helpers/helper'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import NotificationWithIcon from '../../component/notification/Notification'
import { paginationPage, defaultImage, mediaCategory, getAllBoatPagination, userRolesMap_aliasToName } from '../../enums/enums'
import History from '../../History'
import { Link } from 'react-router-dom'
import { getCategoriesWiseBanners, deleteCategoryIdBanner } from '../../store/manageBannerImages/manageCommonBanner/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-regular-svg-icons'

export interface Props {
  getCategoriesWiseBanners: typeof getCategoriesWiseBanners
  deleteCategoryIdBanner: typeof deleteCategoryIdBanner
  tableList?: any
  totalUsersRow?: any
  history: typeof History
  getListAction?: any
  bannerLoader: boolean
  renderColumn?: any
  tableTitle: any
  formModule?: string
  createBtnText: string
  createLink: string
  homeBannersTotalRow: number
  homeBanners: any[]
  isDeleted: boolean
  isDeletedError: boolean
  category: string
}

export interface State {
  searchedValue: string
}

export interface tableRecords {
  id?: any
  lastName?: string
}

class BannerListIndex extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchedValue: '',
    }
  }

  // componentDidMount() {
  //   const { getCategoriesWiseBanners, category } = this.props
  //   const input = {
  //     ...getAllBoatPagination,
  //     data: mediaCategory[category],
  //   }
  //   getCategoriesWiseBanners(input)
  // }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { isDeleted, getCategoriesWiseBanners, isDeletedError, category } = props

    if (isDeleted) {
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
        data: mediaCategory[category as keyof typeof mediaCategory],
      }
      getCategoriesWiseBanners(input)
    }
    if (isDeletedError) {
      NotificationWithIcon('error', 'Problem while deleting Banner.')
    }
    return null
  }

  editBoatTypes = (record: tableRecords) => {
    const { history, createLink } = this.props
    history.push(createLink, { editHomeBanner: record })
  }

  columns = [
    {
      title: 'Slider Images',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (text: any, record: any, index: number) => (
        <>
          {text && text.length ? (
            <img
              key={text[text.length - 1].id}
              src={text[text.length - 1].url || defaultImage.image}
              alt="category icon"
              className="category-icon"
            ></img>
          ) : (
            <>
              <div>
                <FontAwesomeIcon icon={faImages} size="5x" />
              </div>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Page Title',
      dataIndex: 'title',
      key: 'title',
      render: (text: any, record: any, index: number) => {
        const { title } = record
        return displayDefaultValue(title)
      },
    },
    {
      title: 'User Role',
      dataIndex: 'role',
      key: 'role',
      render: (v: string) => userRolesMap_aliasToName[v] || '-'
    },
    {
      title: 'Media Position',
      dataIndex: 'mediaPosition',
      key: 'mediaPosition',
    },
    {
      title: 'Image Order',
      dataIndex: 'mediaOrder',
      key: 'mediaOrder',
    },
    {
      title: 'Action',
      dataIndex: 'title',
      key: 'action',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <Button className="mr-2 btn-edit" onClick={() => this.editBoatTypes(record)}>
              Edit
            </Button>
            <Button
              className="btn-delete"
              onClick={() => {
                const { deleteCategoryIdBanner } = this.props
                record && record.id && confirmSubmitHandler(deleteCategoryIdBanner, record.id)
              }}
            >
              Delete
            </Button>
          </>
        )
      },
    },
  ].filter(Boolean)

  onSearch = (value: any) => {
    const { getCategoriesWiseBanners, category } = this.props
    const input = {
      ...getAllBoatPagination,
      data: mediaCategory[category as keyof typeof mediaCategory],
    }
    if (value) {
      this.setState({ searchedValue: value })
      getCategoriesWiseBanners({ ...input, searchTerm: value })
    } else {
      this.setState({ searchedValue: '' })
      getCategoriesWiseBanners(input)
    }
  }

  render() {
    const { homeBanners, homeBannersTotalRow, category, tableTitle, createLink, createBtnText, bannerLoader, getCategoriesWiseBanners } = this.props

    return (
      <Card
        title={tableTitle}
        extra={
          <div className="d-flex flex-wrap">
            <div className="mr-10 mt-8">
              <TitleSearch onSearch={this.onSearch} />
            </div>
            <Button className="btn-add mt-8">
              <Link to={createLink}>{createBtnText}</Link>
            </Button>
          </div>
        }
      >
        <ExpandTable
          columns={this.columns}
          tableData={homeBanners}
          totalUsersRow={homeBannersTotalRow}
          isExpand={false}
          extraInput={{ data: mediaCategory[category as keyof typeof mediaCategory] }}
          pageAction={getCategoriesWiseBanners}
          page={paginationPage.boat}
          tableLoading={bannerLoader}
        />
      </Card>
    )
  }
}
const mapStateToProps = (state: any) => ({
  isDeleted: state.bannerReducer && state.bannerReducer.deleteHomeBannerSuccess,
  isDeletedError: state.bannerReducer && state.bannerReducer.deleteHomeBannerError,
  bannerLoader: state.bannerReducer.bannerLoader,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCategoriesWiseBanners: (data: any) => dispatch(getCategoriesWiseBanners(data)),
  deleteCategoryIdBanner: (id: string) => dispatch(deleteCategoryIdBanner(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BannerListIndex)
