import {
  canadianTaxState,
  CREATE_CANADIAN_TAX,
  GET_ALL_CANADIAN_TAX_SUCCESS,
  UPDATE_CANADIAN_TAX,
  UPDATE_CANADIAN_TAX_SUCCESS,
  UPDATE_CANADIAN_TAX_FAILURE,
  CLEAR_CANADIAN_TAX_FLAG,
  ActionType,
  CHANGE_TAX_STATUS,
  CHANGE_TAX_STATUS_SUCCESS,
  CHANGE_TAX_STATUS_FAILURE,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: canadianTaxState = {
  canadianTax: [],
  success: false,
  error: false,
}

export const canadianTaxReducer = (state = initialState, action: ActionType): canadianTaxState => {
  switch (action.type) {
    case CREATE_CANADIAN_TAX:
      return {
        ...state,
      }

    case GET_ALL_CANADIAN_TAX_SUCCESS:
      return {
        ...state,
        canadianTax: action.payload,
      }

    case UPDATE_CANADIAN_TAX:
      return {
        ...state,
        success: false,
        error: true,
      }

    case UPDATE_CANADIAN_TAX_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
      }

    case UPDATE_CANADIAN_TAX_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    case CLEAR_CANADIAN_TAX_FLAG:
      return {
        ...state,
        success: false,
        error: false,
      }

    case CHANGE_TAX_STATUS:
      return {
        ...state,
        success: false,
        error: false,
      }
    case CHANGE_TAX_STATUS_SUCCESS:
      NotificationWithIcon('success', 'Status Changed Successfully')
      return {
        ...state,
        success: true,
        error: false,
      }
    case CHANGE_TAX_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        success: false,
        error: true,
      }

    default:
      return state
  }
}
