import { smtpData, CREATE_SMTP, GET_ALL_SMTP, UPDATE_SMTP, DELETE_SMTP, CLEAR_SMTP_FLAG } from './Type'

export function createManageSmtp(data: smtpData) {
  return {
    type: CREATE_SMTP,
    payload: data,
  }
}

export function getManageSmtp(data?: any) {
  return {
    type: GET_ALL_SMTP,
    payload: data,
  }
}

export function updateManageSmtp(data: smtpData) {
  return {
    type: UPDATE_SMTP,
    payload: data,
  }
}

export function deleteManageSmtp(data: string) {
  return {
    type: DELETE_SMTP,
    payload: data,
  }
}

export function clearSmtpFlag() {
  return {
    type: CLEAR_SMTP_FLAG,
  }
}
