import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch, Pagination } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { pagination, paginationPage } from '../../../enums/enums'
import { getAllJobs, deleteJobs, clearJobsFlag, changeJobStatus } from '../../../store/staticContent/manageJobs/Action'
import { getSearchedData } from '../../../helpers/helper'
import { TitleSearch } from '../../../component/SearchBar'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import { jobStatusInput } from '../../../store/staticContent/manageJobs/Type'

interface Props {
  getAllJobs: typeof getAllJobs
  job: []
  history: typeof History
  deleteJobs: typeof deleteJobs
  clearJobsFlag: typeof clearJobsFlag
  jobSuccess: boolean
  deleteSuccess: boolean
  jobTotalRow: number
  changeJobStatus: typeof changeJobStatus
}

interface State {
  searchedValue: string
  getPageInfo: {
    page: number
    limit: number
  }
}

interface JobState {
  jobReducer: jobReducer
}

interface jobReducer {
  job: []
  jobTotalRow: number
  jobSuccess: boolean
  deleteSuccess: boolean
}

class ManageJobs extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { jobSuccess, clearJobsFlag, getAllJobs, deleteSuccess } = props
    const { getPageInfo } = state
    if (jobSuccess) {
      clearJobsFlag()
    }
    if (deleteSuccess) {
      getAllJobs(getPageInfo)
      clearJobsFlag()
    }
    return null
  }

  // componentDidMount() {
  //   const { getAllJobs } = this.props
  //   const { getPageInfo } = this.state

  //   getAllJobs(getPageInfo)
  // }

  editJob = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-job', { input: { record: record, isEdit: true } })
  }

  viewJobsHandler = (id: string = '') => {
    const { history } = this.props
    history.push(`/jobs-applications/${id}`)
  }

  state = {
    searchedValue: '',
    getPageInfo: {
      page: pagination.page,
      limit: pagination.limit,
    },
    columns: [
      {
        title: 'Job Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a: any, b: any) => a.title.length - b.title.length,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: tableRecords, index: number) => {
          return (
            <span onClick={() => this.viewJobsHandler(record?.id)} className="cursor-pointer">
              {text}
            </span>
          )
        },
      },
      {
        title: 'Job Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string, record: tableRecords, index: number) => {
          const { changeJobStatus } = this.props
          return (
            <Switch
              checked={record.status}
              onChange={() => {
                record.id &&
                  changeJobStatus({
                    column: 'status',
                    id: record.id,
                    value: record.status,
                  })
              }}
              checkedChildren="Active"
              unCheckedChildren="Block"
            />
          )
        },
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: (text: any, record: tableRecords, index: number) => {
          return <span>{(text?.name === undefined ? "Any" : text?.name)}</span>
        },
      },
      {
        title: 'Needed Experience',
        dataIndex: 'experience',
        key: 'experience',
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteJobs } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editJob(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteJobs, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
      {
        title: 'CV Received',
        dataIndex: 'cvCount',
        key: 'cvCount',
      },
    ],
  }

  onSearch = (value: any) => {
    const { getAllJobs } = this.props;
    const { getPageInfo } = this.state

    if (value) {
      this.setState({searchedValue: value})
      getAllJobs({ ...getPageInfo, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getAllJobs({ ...getPageInfo })
    }
  }

  render() {
    const { columns, searchedValue } = this.state
    const { job, jobTotalRow, getAllJobs } = this.props

    return (
      <Card
        title="Manage Jobs"
        extra={
          <div className="d-flex flex-wrap">
            <div className="mr-10">
              <TitleSearch onSearch={this.onSearch} />
            </div>
            <Button className="btn-add">
              <Link to="/create-job">
                <WSIcon type={<PlusOutlined />} /> Add Job
              </Link>
            </Button>
            <Button className=" ml-15 btn-add">
              <Link to="/career-content">Manage Careers Content</Link>
            </Button>
          </div>
        }
      >
        <ExpandTable
          columns={columns}
          tableData={job}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={jobTotalRow}
          pageAction={getAllJobs}
          page={paginationPage.boat}
          extraInput={{ searchTerm: searchedValue }}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: JobState) => ({
  job: state.jobReducer.job,
  jobTotalRow: state.jobReducer.jobTotalRow,
  jobSuccess: state.jobReducer && state.jobReducer.jobSuccess,
  deleteSuccess: state.jobReducer && state.jobReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllJobs: (data: any) => dispatch(getAllJobs(data)),
  deleteJobs: (data: string) => dispatch(deleteJobs(data)),
  clearJobsFlag: () => dispatch(clearJobsFlag()),
  changeJobStatus: (data: jobStatusInput) => dispatch(changeJobStatus(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageJobs)
