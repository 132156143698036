import gql from 'graphql-tag'

export const createState = gql`
  mutation createState($input: StateInput!) {
    createState(state: $input) {
      id
      name
      stateCode
      createdAt
    }
  }
`

export const getAllState = gql`
  query getAllStates($page: Int, $limit: Int) {
    getAllStates(page: $page, limit: $limit) {
      items {
        id
        country {
          id
          name
          ISOCode
          countryPhoneCode
          sellerStatus
          builderStatus
          surveyorStatus
          commonModuleStatus
          salesEngineStatus
        }
        name
        stateCode
        createdAt
      }
      total
    }
  }
`

export const updateState = gql`
  mutation updateState($state: StateInput!) {
    updateState(state: $state) {
      id
    }
  }
`

export const deleteState = gql`
  mutation deleteState($id: String!) {
    deleteState(id: $id) {
      id
    }
  }
`
