import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import WSIcon from '../../../component/WSIcon'
import { AppState } from '../../../store'
import { deleteSeoMetaTagAction, getAllSeoMetaTagsAction, searchSeoMetaTagAction } from '../../../store/seo/Action'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import ActiveTableCell from './components/ActiveTableCell'
import { TitleSearch } from '../../../component/SearchBar'
import { redirectUrl } from '../../../constants/GlobalConstants'

const LIMIT = 10

const SeoMetaTag = () => {
  const history = useHistory()
  const {
    items: seoItems,
    total,
    fetchLoading,
    searchLoading,
  } = useSelector((state: AppState) => ({
    items: state.seo.metaTags.items,
    total: state.seo.metaTags.total,
    fetchLoading: state.seo.fetchLoading,
    searchLoading: state.seo.searchLoading
  }))
  const dispatch = useDispatch()

  const getData = (input: any) => (console.log(input), dispatch(getAllSeoMetaTagsAction(input)))

  const onDelete = React.useCallback((id: any) => {
    dispatch(deleteSeoMetaTagAction(id))
  }, [])

  const columns = useMemo(() => {
    return [
      {
        title: 'Page',
        key: 'page',
        dataIndex: 'page',
        render: (path: string) => {
          return <a href={path} target='_blank' rel="noopener noreferrer">{path?.includes('adamoceans') ? path :  (redirectUrl + path)}</a>
        },
      },
      {
        title: 'Document Title',
        key: 'title',
        dataIndex: 'title',
      },
      {
        title: 'Meta Title',
        key: 'metaTitle',
        dataIndex: 'metaTitle',
      },
      {
        title: 'Meta Description',
        key: 'description',
        dataIndex: 'description',
      },
      {
        title: 'Meta Keywords',
        key: 'keywords',
        dataIndex: 'keywords',
        render: (keywords: string[]) => {
          return <span>{keywords?.length > 0 ? keywords.join(', ') : '-'}</span>
        },
      },
      {
        title: 'Active',
        key: 'active',
        dataIndex: 'active',
        render: (active: boolean, record: tableRecords) => {
          console.log(active);
          return <ActiveTableCell record={record} active={active} key={String(active)} />
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          return (
            <>
              <Button
                onClick={() => {
                  history.push('/create-seo-meta-tag', { metaTag: record })
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(onDelete, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ]
  }, [])

  const onSearch = React.useCallback((value: string) => {
    dispatch(
      searchSeoMetaTagAction({
        limit: 10,
        page: 1,
        value: value,
      })
    )
  }, [])

  return (
    <div>
      <Card
        title="SEO Meta Tags"
        extra={
          <div className="d-flex flex-wrap align-items-center">
            <div className="mr-2">
              <TitleSearch onSearch={onSearch} />
            </div>
            <div className="mr-10">
              <Link to="/create-seo-meta-tag">
                <Button className="btn-add">
                  <WSIcon type={<PlusOutlined />} /> Add Meta Tag
                </Button>
              </Link>
            </div>
          </div>
        }
      >
        <ExpandTable
          columns={columns}
          tableData={seoItems}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={total}
          pageAction={getData}
          tableLoading={fetchLoading || searchLoading}
          extraInput={{ active: '' }}
        />
      </Card>
    </div>
  )
}
export default SeoMetaTag
