import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getFrontUsersCount, getFrontUserBoatsCount, getFrontUserPendingCount, autoLogin, getUserWiseVerificationCountSchema } from './Schema'
import {
  GET_FRONT_USERS_COUNT_SUCCESS,
  GET_FRONT_USERS_COUNT_FAILURE,
  GET_FRONT_USERS_BOAT_COUNT_SUCCESS,
  GET_FRONT_USERS_BOAT_COUNT_FAILURE,
  GET_FRONT_USERS_PENDING_COUNT_SUCCESS,
  GET_FRONT_USERS_PENDING_COUNT_FAILURE,
  GET_FRONT_USERS_COUNT,
  GET_FRONT_USERS_BOAT_COUNT,
  GET_FRONT_USERS_PENDING_COUNT,
  GO_TO_DASHBOARD,
  GO_TO_DASHBOARD_SUCCESS,
  GO_TO_DASHBOARD_FAILURE,
  ActionType,
  CLEAR_GO_TO_DASHBOARD_FLAG,
  FRONT_USER_WISE_VERIFICATION,
  FRONT_USER_WISE_VERIFICATION_SUCCESS,
  FRONT_USER_WISE_VERIFICATION_FAILURE,
} from './Type'

function getFrontUsersCountApi() {
  return client
    .query({
      query: getFrontUsersCount,
      fetchPolicy: 'no-cache',
    })
    
}

function getFrontUsersBoatCountApi() {
  return client
    .query({
      query: getFrontUserBoatsCount,
      fetchPolicy: 'no-cache',
    })
    
}

function getFrontUsersPendingCountApi() {
  return client
    .query({
      query: getFrontUserPendingCount,
      fetchPolicy: 'no-cache',
    })
}

function getUserWiseVerificationCountApi() {
  return client
    .query({
      query: getUserWiseVerificationCountSchema,
      fetchPolicy: 'no-cache'
    })
}

function autoDashboardLoginApi(user: string) {
  return client
    .mutate({
      mutation: autoLogin,
      variables: { input: { user } },
    })
}

function* getFrontUsersCountData() {
  try {
    const res = yield getFrontUsersCountApi()
    yield put({ type: GET_FRONT_USERS_COUNT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_FRONT_USERS_COUNT_FAILURE, error })
  }
}

function* getFrontUserBoatsCountData() {
  try {
    const res = yield getFrontUsersBoatCountApi()
    yield put({ type: GET_FRONT_USERS_BOAT_COUNT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_FRONT_USERS_BOAT_COUNT_FAILURE, error })
  }
}

function* getFrontUsersPendingData() {
  try {
    const res = yield getFrontUsersPendingCountApi()
    yield put({
      type: GET_FRONT_USERS_PENDING_COUNT_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    yield put({ type: GET_FRONT_USERS_PENDING_COUNT_FAILURE, error })
  }
}

function* getFrontUserWiseVerificationData(){
  try {
    const res = yield getUserWiseVerificationCountApi()
    yield put({
      type: FRONT_USER_WISE_VERIFICATION_SUCCESS,
      payload: res.data
    })
  } catch (error) {
    yield put({type: FRONT_USER_WISE_VERIFICATION_FAILURE, error})
  }
}

function* autoDashboardLogin(action: any) {
  console.log(action)
  try {
    const res = yield autoDashboardLoginApi(action.payload)
    yield put({
      type: GO_TO_DASHBOARD_SUCCESS,
      payload: res.data.adminToUserLogin,
    })
    console.log(res)
  } catch (error) {
    yield put({ type: GO_TO_DASHBOARD_FAILURE, error })
  }
  // yield put({ type: CLEAR_GO_TO_DASHBOARD_FLAG })
}

function* getFrontUsersCountSaga() {
  yield takeLatest(GET_FRONT_USERS_COUNT, getFrontUsersCountData)
}

function* getFrontUserBoatsCountSaga() {
  yield takeLatest(GET_FRONT_USERS_BOAT_COUNT, getFrontUserBoatsCountData)
}

function* getFrontUserPendingCountSaga() {
  yield takeLatest(GET_FRONT_USERS_PENDING_COUNT, getFrontUsersPendingData)
}

function* autoDashboardLoginSaga() {
  yield takeLatest(GO_TO_DASHBOARD, autoDashboardLogin)
}

function* getUserWiseVerificationSaga() {
  yield takeLatest(FRONT_USER_WISE_VERIFICATION, getFrontUserWiseVerificationData)
}

export default function* getAllFrontUsersCountSaga() {
  yield all([getFrontUsersCountSaga(), getFrontUserBoatsCountSaga(), getFrontUserPendingCountSaga(), autoDashboardLoginSaga(), getUserWiseVerificationSaga()])
}
