import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import {
  ActionType,
  CREATE_REGISTRATION_CONTENT,
  CREATE_REGISTRATION_CONTENT_SUCCESS,
  CREATE_REGISTRATION_CONTENT_FAILURE,
  GET_ALL_PAGE_INFORMATION,
  GET_ALL_PAGE_INFORMATION_SUCCESS,
  GET_ALL_PAGE_INFORMATION_FAILURE,
} from './Types'
import { getAllPageInformations, createPageInformations } from './Schema'

function getAllPageInfoApi(input: any) {
  console.log(input, 'input')

  return client
    .query({
      query: getAllPageInformations,
      variables: { input: input },
    })
}

function createRegistrationContentsApi(input: any) {
  return client
    .mutate({
      mutation: createPageInformations,
      variables: { input },
    })
}

function* getAllPageInfo(action: any) {
  try {
    const res = yield getAllPageInfoApi(action.payload)
    yield put({
      type: GET_ALL_PAGE_INFORMATION_SUCCESS,
      payload: res.data.getAllPageInformationByType,
      uiType: action.payload.uiType,
    })
  } catch (error) {
    yield put({ type: GET_ALL_PAGE_INFORMATION_FAILURE, error })
  }
}

function* createRegistrationContents(action: ActionType) {
  try {
    const res = yield createRegistrationContentsApi(action.payload)
    yield put({
      type: CREATE_REGISTRATION_CONTENT_SUCCESS,
      payload: res.data.createPageInformations,
    })
  } catch (error) {
    yield put({ type: CREATE_REGISTRATION_CONTENT_FAILURE, error })
  }
}

function* getAllPageInfoSaga() {
  yield takeLatest(GET_ALL_PAGE_INFORMATION, getAllPageInfo)
}

function* createRegistrationContentsSaga() {
  yield takeLatest(CREATE_REGISTRATION_CONTENT, createRegistrationContents)
}

export default function* pageInformationSaga() {
  yield all([getAllPageInfoSaga(), createRegistrationContentsSaga()])
}
