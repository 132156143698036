import React, { Component, GetDerivedStateFromProps } from 'react'
import { CircularGraph } from '../../../component/charts/CircularGraph'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Row, Col } from 'antd'

import {
  getFrontUsersCount,
  getFrontUsersBoatCount,
  getFrontUsersPendingCount,
  clearFrontUserCountFlag,
  getUserWiseVerificationCount,
} from '../../../store/dashboard/frontUsers/Action'
import History from '../../../History'
import { getDataFromArray } from '../../../helpers/helper'
import UserTypeCountCard from '../../../component/UserTypeCountCard'
import { getDashboardVerificationAlertCount } from '../../../store/dashboard/others/Action'

interface Props {
  getFrontUsersCount: typeof getFrontUsersCount
  getFrontUsersBoatCount: typeof getFrontUsersBoatCount
  getFrontUsersPendingCount: typeof getFrontUsersPendingCount
  frontUsers: []
  frontUsersBoatCount: []
  frontUserPendingCount: []
  getSuccess: boolean
  clearFrontUserCountFlag: typeof clearFrontUserCountFlag
  history: typeof History
  loading: boolean
  verificationAlertCount: any
  userWiseVerificationCount:any
  getFronUserWiseVerificationCounts: typeof getUserWiseVerificationCount
}
interface State {}

class FrontUsers extends Component<Props, State> {
  state = {}

  componentDidMount() {
    const { getFrontUsersCount, getFrontUsersBoatCount, getFrontUsersPendingCount, getFronUserWiseVerificationCounts} = this.props
    getFrontUsersCount()
    getFrontUsersBoatCount()
    getFrontUsersPendingCount()
    getFronUserWiseVerificationCounts()
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { getSuccess, clearFrontUserCountFlag } = props
    if (getSuccess) {
      clearFrontUserCountFlag()
    }
    return null
  }

  render() {
    const { frontUsers, frontUserPendingCount, frontUsersBoatCount, loading, verificationAlertCount, userWiseVerificationCount } = this.props
    const {
      pendingBoatAdsCount, pendingBrokerAndDealerCount, 
      pendingMfgAdsCount, pendingServiceAds, 
      pendingRentAds, pendingMarinaAds, 
      pendingBuilderAgreement, pendingShipperAgreement, 
      pendingSurveyorAgreement, pendingAgreementReq} = verificationAlertCount

    const {
      pendingMemeberVerification,
      pendingBoatownerVerifaication,
      pendingBrokerNdealerVerifaication,
      pendingBoatMfgVerifaication,
      pendingServiceNmaintanenceVerifaication,
      pendingSurveyorVerifaication,
      pendingMarinaVerifaication,
      pendingRentVerifaication,
      pendingYachtVerifaication,
      pendingAgentVerifaication,
    }  = userWiseVerificationCount
     
      function getTotalBoatsData(inputData: any) {
        // Find the count for "TOTAL OF REST THREE"
        const totalOfRestThreeCount = inputData
            .filter((entry: any) => entry.role !== "RENT")
            .reduce((total:number, entry: any) => total + entry.count, 0);
    
        // Find the count for "RENT"
        const rentCount = inputData.find((entry: any) => entry.role === "RENT")?.count;
    
        return [totalOfRestThreeCount, rentCount];
    }
    const pendingAdsArr = [pendingBoatAdsCount, pendingBrokerAndDealerCount, pendingMfgAdsCount, pendingServiceAds, pendingRentAds, pendingMarinaAds]
    const pendingAgreements =  [pendingBuilderAgreement, pendingShipperAgreement, pendingSurveyorAgreement, pendingAgreementReq]
    return (
      <>
        <div style={{ background: '#ECECEC', padding: '30px' }}>
          <Row gutter={16}>
            {frontUsers.map((element: any) => {
              return (
                <Col sm={6} className="mb-20">
                  <UserTypeCountCard item={element} loading={loading} />
                </Col>
              )
            })}
          </Row>
        </div>

        <br />

        {/* Ask @miraj before remove this code */}

        <Row gutter={16}>
          <Col sm={12} className="mb-20" style={{paddingInline: 0}}>
            <CircularGraph
              alignLabels='left'
              loading={loading}
              chartTitle={'FRONT USERS'}
              labels={getDataFromArray(frontUsers, 'role')}
              data={getDataFromArray(frontUsers, 'count')}
            />
          </Col>

          <Col sm={12} className="mb-20" style={{paddingInline: 0}}>
            <CircularGraph
              alignLabels='left'
              loading={loading}
              chartTitle={'PENDING ADS'}
              labels={['BOAT ADS', 'BROKER & DEALER ADS', 'BOAT MANUFACTURER ADS', 'BOAT SERVICE ADS', 'RENT ADS', 'MARINA ADS']}
              data={pendingAdsArr}
            />
          </Col>

          <Col sm={12} className="mb-20">
            <CircularGraph
              alignLabels='left'
              loading={loading}
              chartTitle={'MANAGE AGREEMENT'}
              labels={['BUILDER AGREEMENTS', 'SHIPPER AGREEMENTS', 'SURVEYOR AGREEMENTS', 'AGREEMENT REQUESTS']}
              data={pendingAgreements}
            />
          </Col>

          <Col sm={12} className="mb-20">
            <CircularGraph
              alignLabels='left'
              loading={loading}
              chartTitle={'TOTAL BOATS'}
              labels={['TOTAL BOATS', 'RENT ADS']}
              data={getTotalBoatsData(frontUsersBoatCount)}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12} style={{paddingInline: 0}}>
            <CircularGraph
              alignLabels='left'
              loading={loading}
              chartTitle={'NO. OF BOATS AND NUMBER OF ADS'}
              labels={getDataFromArray(frontUserPendingCount, 'role')}
              data={getDataFromArray(frontUserPendingCount, 'count')}
            />
          </Col>

          <Col sm={12} style={{paddingInline: 0}}>
            <CircularGraph
              alignLabels='left'
              loading={loading}
              chartTitle={'PENDING VERIFICATION'}
              labels={['MEMBERS', 'BOAT OWNER', 'BROKER & DEALER', 'BOAT MANUFACTURER', 'SERVICE & MAINTANENCE', 'SURVEYOR', 'MARINA', 'RENT', 'YACHT SHIPPER', 'AGENT']}
              data={[
                pendingMemeberVerification,
                pendingBoatownerVerifaication,
                pendingBrokerNdealerVerifaication,
                pendingBoatMfgVerifaication,
                pendingServiceNmaintanenceVerifaication,
                pendingSurveyorVerifaication,
                pendingMarinaVerifaication,
                pendingRentVerifaication,
                pendingYachtVerifaication,
                pendingAgentVerifaication,
              ]}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return({
    frontUsers: state.frontUsersCountReducer.frontUsers,
    frontUsersBoatCount: state.frontUsersCountReducer.frontUsersBoatCount,
    frontUserPendingCount: state.frontUsersCountReducer.frontUserPendingCount,
    getSuccess: state.frontUsersCountReducer && state.frontUsersCountReducer.getSuccess,
    loading: state.frontUsersCountReducer && state.frontUsersCountReducer.loading,
    verificationAlertCount: state.adminOtherCountReducer.alertCount ,
    userWiseVerificationCount: state.frontUsersCountReducer.frontUserWiseVerificationCount
  })
}
// const mapStateToProps = (state: any) => ({
//   frontUsers: state.frontUsersCountReducer.frontUsers,
//   frontUsersBoatCount: state.frontUsersCountReducer.frontUsersBoatCount,
//   frontUserPendingCount: state.frontUsersCountReducer.frontUserPendingCount,
//   getSuccess: state.frontUsersCountReducer && state.frontUsersCountReducer.getSuccess,
//   loading: state.frontUsersCountReducer && state.frontUsersCountReducer.loading,
//   verificationAlertCount: state.adminOtherCountReducer.alertCount ,
//   userWiseVerificationCount: state.frontUsersCountReducer.frontUserWiseVerificationCount
// })

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getFrontUsersCount: () => dispatch(getFrontUsersCount()),
  getFrontUsersBoatCount: () => dispatch(getFrontUsersBoatCount()),
  getFrontUsersPendingCount: () => dispatch(getFrontUsersPendingCount()),
  clearFrontUserCountFlag: () => dispatch(clearFrontUserCountFlag()),
  getFronUserWiseVerificationCounts: () => dispatch(getUserWiseVerificationCount())
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontUsers)
