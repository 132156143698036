import gql from 'graphql-tag'
import { imageSchema } from '../schemaTypes'

export const createManageSiteConfiguration = gql`
  mutation createSiteConfiguration($input: SiteConfigurationInput!) {
    createSiteConfiguration(input: $input) {
      id
      siteName
      siteTitle
      metaKeyword
      metaDescription
      siteLogo
      siteFavicon
      supportNumber
      supportEmail
      copyRightText
      blogLink
      linkedInLink
      facebookLink
      twitterLink
      pinterestLink
      address
      siteUrl
      googlePlusLink
      CNSPE
      CUCYABL
      paymentEmail
      youtubeLink
      EPC
      SWC
      CINBE
      CUCTBBL
      DTBC
      instaLink
    }
  }
`
export const getAllManageSiteConfiguration = gql`
  query getSiteConfigurations {
    getSiteConfigurations {
      id
      siteName
      siteTitle
      metaKeyword
      metaDescription
      supportNumber
      supportEmail
      copyRightText
      linkedInLink
      facebookLink
      twitterLink
      pinterestLink
      address
      siteUrl
      minimumPercentageAmountForDeposit
      googlePlusLink
      youtubeLink
      siteWelcomeContent
      instaLink
      adamseaCommunityLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      adamseaConnectLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      adamseaShareLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      boatMediaLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      boatShowBanner {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      boatMediaBanner {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      boatShowLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      buyAndSellBanner {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      marinaAndStorageLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      marinaBanner {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      rentBanner {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      rentLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      serviceMaintenanceBanner {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      siteFavicon {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      siteLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      siteWelcomeBanner {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      yachtServiceLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
      buyAndSellLogo {
        isActive
        id
        key
        mediaType
        mediaName
        moduleType
        url
      }
    }
  }
`

export const editSiteConfiguration = gql`
  {
    editSiteConfiguration(id: $id) {
      id
      siteName
      siteTitle
      metaKeyword
      metaDescription
      siteLogo
      siteFavicon
      supportNumber
      supportEmail
      copyRightText
      blogLink
      linkedInLink
      facebookLink
      twitterLink
      pinterestLink
      address
      siteUrl
      googlePlusLink
      CNSPE
      CUCYABL
      paymentEmail
      youtubeLink
      EPC
      SWC
      CINBE
      CUCTBBL
      DTBC
      instaLink
    }
  }
`

export const updateSiteConfigurationG = gql`
mutation updateSiteConfiguration($input: SiteConfigurationInput!){
    updateSiteConfiguration(input: $input) {
        id
        siteName
        siteTitle
        metaKeyword
        metaDescription
        siteLogo${imageSchema}
        siteFavicon ${imageSchema}
        supportNumber
        supportEmail
        copyRightText
        linkedInLink
        facebookLink
        twitterLink
        pinterestLink
        address
        siteUrl
        googlePlusLink
        youtubeLink
        siteWelcomeContent
        instaLink
        buyAndSellLogo ${imageSchema}
        boatShowLogo ${imageSchema}
        marinaAndStorageLogo ${imageSchema}
        yachtServiceLogo ${imageSchema}
        adamseaCommunityLogo ${imageSchema}
        adamseaConnectLogo ${imageSchema}
        adamseaShareLogo ${imageSchema}
        boatMediaLogo ${imageSchema}
    }
}`
