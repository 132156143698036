import React, { useEffect, useState } from 'react'
import FileViewer from 'react-file-viewer'
import { splitUrlFromDocumentUrl } from '../../../helpers/helper'

const ShowDocumentModal = ({ filePath }: { filePath: string }) => {
  const [url, setUrl] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {
    if (filePath) {
      try {
        setType(splitUrlFromDocumentUrl(filePath))
        setUrl(filePath.split('?')[0])
      } catch (err) {
        console.log(err)
      }
    }
    return () => {
      setUrl('')
    }
  }, [filePath])

  return (
    <div className="text-center" style={{ flex: 1 }}>
      {/* <h2>Displaying File</h2> */}
      {url && <FileViewer filePath={url} fileType={type} />}
    </div>
  )
}

export default ShowDocumentModal
