import {
  GET_ALL_RENT_TYPES,
  paginationType,
  ADD_RENT_TYPE,
  GET_SINGLE_RENT_TYPE,
  UPDATE_RENT_TYPE,
  DELETE_RENT_TYPE,
  CLEAR_RENT_TYPE_FLAG,
  rentTypeInput,
  GET_ALL_TRIP_TYPES,
} from './Types'

export const getTripTypes = (data: string) => ({
  type: GET_ALL_TRIP_TYPES,
  payload: data,
})

export const getAllRentTypes = (data: any) => ({
  type: GET_ALL_RENT_TYPES,
  payload: data,
})

export const createRentType = (data: rentTypeInput) => ({
  type: ADD_RENT_TYPE,
  payload: data,
})

export const getSingleRentType = (data: string) => ({
  type: GET_SINGLE_RENT_TYPE,
  payload: data,
})

export const updateRentType = (data: rentTypeInput) => ({
  type: UPDATE_RENT_TYPE,
  payload: data,
})

export const deleteRentType = (data: string) => ({
  type: DELETE_RENT_TYPE,
  payload: data,
})

export const clearRentTypeFlag = () => ({
  type: CLEAR_RENT_TYPE_FLAG,
})
