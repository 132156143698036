import React from 'react'
import 'antd/dist/antd.css'
import { Layout, Menu, message } from 'antd'
import '../App.css'
import '../assets/css/icon.scss'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import MenuItem from '../component/MenuItem'
import WSIcon from '../component/WSIcon'
import { ArrowRightOutlined, BellFilled } from '@ant-design/icons'
import { AdminPermissions, AdminPermissionGroups } from '../enums/enums'
import Notification from './Notification'

const { SubMenu } = Menu
const { Sider } = Layout

interface Props {
  isSuperAdmin: boolean
  verificationAlertCount: any
  collapsed: boolean
  openKeys: any[]
  key: string
  onOpenChange: any
  haveAnyPermissionInGroup: any
  hasPermission: any
}

interface State {}

class XMenu extends React.PureComponent<Props, State> {
  render() {
    const {
      isSuperAdmin,
      verificationAlertCount,
      collapsed,
      openKeys,
      key,
      onOpenChange,
      haveAnyPermissionInGroup,
      hasPermission,
    } = this.props

    const isPendingAdsExist =
      verificationAlertCount?.pendingBoatAdsCount !== 0 ||
      verificationAlertCount?.pendingBrokerAndDealerCount !== 0 ||
      verificationAlertCount?.pendingMfgAdsCount !== 0 ||
      verificationAlertCount?.pendingServiceAds !== 0 ||
      verificationAlertCount?.pendingRentAds !== 0

    const isPendingAgreementExist =
      verificationAlertCount?.pendingAgreementReq !== 0 ||
      verificationAlertCount?.pendingSurveyorAgreement !== 0 ||
      verificationAlertCount?.pendingShipperAgreement !== 0 ||
      verificationAlertCount?.pendingBuilderAgreement !== 0

    return (
      <>
        <Notification notify={isPendingAdsExist || isPendingAgreementExist} />

        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          breakpoint="lg"
          width={277}
          className={collapsed ? 'aside-slider overflow-scroll  slider-is' : 'aside-slider overflow-scroll'}
        >
          {!collapsed && (
            <div className="logo">
              <span className="ref">AdamSea</span>
            </div>
          )}
          {collapsed && (
            <div className="logo">
              <span className="ref">AS</span>
            </div>
          )}
          <Menu openKeys={openKeys} onOpenChange={onOpenChange} mode="inline" theme="dark">
            {haveAnyPermissionInGroup(AdminPermissionGroups.DASHBOARD) && (
              <SubMenu
                key="sub1"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-home-1" />} name="Dashboard" />}
              >
                {hasPermission(AdminPermissions.DASHBOARD__FRONT_USERS) && (
                  <Menu.Item className={key === 'sub1' ? `selected-key` : ''}>
                    <NavLink
                      to="/front-users"
                      isActive={match => {
                        if (!match) {
                          this.setState({ key: '' })
                          return false
                        }

                        this.setState({ key: 'sub1' })
                        return true
                      }}
                      exact
                    >
                      {message.destroy()}
                      <WSIcon type={<ArrowRightOutlined />} /> Front Users
                    </NavLink>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.DASHBOARD__ADMIN_USERS) && (
                  <Menu.Item>
                    <Link to="/admin-users">
                      <WSIcon type={<ArrowRightOutlined />} /> Admin Users
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.DASHBOARD__OTHERS) && (
                  <Menu.Item>
                    <Link to="/others">
                      <WSIcon type={<ArrowRightOutlined />} /> Others
                    </Link>
                  </Menu.Item>
                )}

                {/* {hasPermission(AdminPermissions.DASHBOARD__MANAGE_AGREEMENTS) && (
                  <Menu.Item>
                    <Link to="/manage-agreements-listing">
                      <WSIcon type={<ArrowRightOutlined />} /> Manage Agreements
                    </Link>
                  </Menu.Item>
                )} */}

                {hasPermission(AdminPermissions.DASHBOARD__MANAGE_SALES_ENGINES) && (
                  <Menu.Item>
                    <Link to="/manage-sales-engine">
                      <WSIcon type={<ArrowRightOutlined />} /> Manage Sales Engine
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.DASHBOARD__MANAGE_AUCTION_ROOMS) && (
                  <Menu.Item>
                    <Link to="/manage-auction-room-dashboard">
                      <WSIcon type={<ArrowRightOutlined />} /> Manage Auction Room
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.DASHBOARD__MANAGE_RENT) && (
                  <Menu.Item>
                    <Link to="/manage-rent">
                      <WSIcon type={<ArrowRightOutlined />} /> Manage Rent
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {hasPermission(AdminPermissions.GENERAL_SETTINGS) && (
              <Menu.Item>
                <Link to="/general">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-settings1" />} name="General Settings" />
                </Link>
              </Menu.Item>
            )}

            {hasPermission(AdminPermissions.TIMER_SETTINGS) && (
              <Menu.Item>
                <Link to="/settings/timer">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-alarm-clock" />} name="Timer Settings" />
                </Link>
              </Menu.Item>
            )}

            {hasPermission(AdminPermissions.PENDING_VERIFICATION) && (
              <Menu.Item>
                <Link to="/pending-verification">
                  <MenuItem
                    isCollapse={collapsed}
                    iconClass={<i className="adam-list-1" />}
                    name={
                      <>
                        Pending Verification{' '}
                        {verificationAlertCount?.pendingVerification > 0 && (
                          <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                        )}
                      </>
                    }
                  />
                </Link>
              </Menu.Item>
            )}

            {isSuperAdmin && (
              <SubMenu
                key="sub2"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-user"></i>} name="Admin Users" />}
              >
                <Menu.Item>
                  <Link
                    to={{
                      pathname: '/admin-user/account-user',
                      state: { titleValue: 'Manage Account User' },
                    }}
                  >
                    <WSIcon type={<ArrowRightOutlined />} /> Account User
                  </Link>
                </Menu.Item>

                <Menu.Item>
                  <Link
                    to={{
                      pathname: '/admin-user/auditor',
                      state: { titleValue: 'Manage Auditors' },
                    }}
                  >
                    <WSIcon type={<ArrowRightOutlined />} /> Auditors
                  </Link>
                </Menu.Item>

                <Menu.Item>
                  <Link
                    to={{
                      pathname: '/admin-user/customers-support',
                      state: { titleValue: 'Manage Customer Supporters' },
                    }}
                  >
                    <WSIcon type={<ArrowRightOutlined />} /> Customer Supports
                  </Link>
                </Menu.Item>

                <Menu.Item>
                  <Link
                    to={{
                      pathname: '/admin-user/sub-administrator',
                      state: { titleValue: 'Manage Sub Administrators' },
                    }}
                  >
                    <WSIcon type={<ArrowRightOutlined />} /> Sub Administrators
                  </Link>
                </Menu.Item>

                <Menu.Item>
                  <Link
                    to={{
                      pathname: '/admin-user/administrator',
                      state: { titleValue: 'Manage Administrators' },
                    }}
                  >
                    <WSIcon type={<ArrowRightOutlined />} /> Administrators
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.FRONT_USERS) && (
              <SubMenu
                key="sub3"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-user-3"></i>} name="Front Users" />}
              >
                {hasPermission(AdminPermissions.FRONT_USERS__MEMBER) && (
                  <Menu.Item>
                    <Link to="/member">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Member
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__BOAT_OWNER) && (
                  <Menu.Item>
                    <Link to="/boat-owner">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Boat Owner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__BROKER_AND_DEALER) && (
                  <Menu.Item>
                    <Link to="/boat-and-dealer">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Broker And Dealer
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__BOAT_MANUFACTURER) && (
                  <Menu.Item>
                    <Link to="/boat-manufactory">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Boat Manufacturer
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__SHIPMENT) && (
                  <Menu.Item>
                    <Link to="/shipment">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Shipment
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__SURVEYOR) && (
                  <Menu.Item>
                    <Link to="/surveyor">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Surveyor
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__RENT) && (
                  <Menu.Item>
                    <Link to="/rent">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Rent
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__SERVICE_AND_MAINTENANCE) && (
                  <Menu.Item>
                    <Link to="/yacht-service">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Service and Maintenance
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__MARINA_AND_STORAGE) && (
                  <Menu.Item>
                    <Link to="/marina-storage">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Marina & storage
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FRONT_USERS__ADAMSEA_AGENT) && (
                  <Menu.Item>
                    <Link to="/agent">
                      <WSIcon type={<ArrowRightOutlined />} />
                      AdmSea Agent
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.EMAIL_SETTINGS) && (
              <SubMenu
                key="email"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-envelope" />} name="Email Settings" />}
              >
                {hasPermission(AdminPermissions.EMAIL_SETTINGS__MANAGE_SMTP) && (
                  <Menu.Item>
                    <Link to="/manage-smtp">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage SMTP
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.EMAIL_SETTINGS__EMAIL_TEMPLATES) && (
                  <Menu.Item>
                    <Link to="/email-templates">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Email Templates
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {hasPermission(AdminPermissions.SMS_TEMPLATES) && (
              <Menu.Item>
                <Link to="/sms-templates">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-chat-4" />} name="SMS Templates" />
                </Link>
              </Menu.Item>
            )}

            {hasPermission(AdminPermissions.SEO_META_TAGS) && (
              <Menu.Item>
                <Link to="/seo-meta-tag">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-tag" />} name="SEO Meta Tags" />
                </Link>
              </Menu.Item>
            )}

            {hasPermission(AdminPermissions.MANAGE_TYPE) && (
              <Menu.Item>
                <Link to="/manage-types">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-management" />} name="Manage Type" />
                </Link>
              </Menu.Item>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_BOATS) && (
              <SubMenu
                key="sub10"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-big-anchor" />} name="Manage Boats" />}
              >
                {hasPermission(AdminPermissions.MANAGE_BOATS__ALL_COUNTRY_BOATS) && (
                  <Menu.Item>
                    <Link to="/all-country-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      All Country Boats
                    </Link>
                  </Menu.Item>
                )}
                {hasPermission(AdminPermissions.MANAGE_BOATS__MANAGE_ALL_BOATS) && (
                  <Menu.Item>
                    <Link to="/manage-all-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage All Boats
                    </Link>
                  </Menu.Item>
                )}
                {hasPermission(AdminPermissions.MANAGE_BOATS__MANAGE_FEATURED_BOATS) && (
                  <Menu.Item>
                    <Link to="/manage-featured-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Featured Boats
                    </Link>
                  </Menu.Item>
                )}
                {hasPermission(AdminPermissions.MANAGE_BOATS__MANAGE_BEST_DEAL_BOATS) && (
                  <Menu.Item>
                    <Link to="/manage-best-deal-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Best Deal Boats
                    </Link>
                  </Menu.Item>
                )}
                {hasPermission(AdminPermissions.MANAGE_BOATS__MANAGE_LITTLE_BOATS) && (
                  <Menu.Item>
                    <Link to="/manage-must-buy-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Little Boats
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_RENT_BOATS) && (
              <SubMenu
                key="rentBoat"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-user" />} name="Manage Rent Boats" />}
              >
                {hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MANAGE_RENT_BOATS) && (
                  <Menu.Item>
                    <Link to="/manage-rent-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Rent Boat
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MANAGE_RECOMMENDED) && (
                  <Menu.Item>
                    <Link to="/recommended-rent-boat">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Recommended
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MOST_POPULAR) && (
                  <Menu.Item>
                    <Link to="/most-popular-boat">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Most Popular
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MANAGE_LIVE_EXPERIENCE) && (
                  <Menu.Item>
                    <Link to="/live-experience-rent">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Live Experience
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MANAGE_PAYMENTS) && (
                  <Menu.Item>
                    <Link to="/view-rent-payments-receivers">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Payments
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_BOAT_SERVICE) && (
              <SubMenu
                key="boatService"
                title={
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-settings" />} name="Manage Boat Service" />
                }
              >
                {hasPermission(AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_ALL_BOAT_SERVICE) && (
                  <Menu.Item>
                    <Link to="/manage-boat-service">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage All Boat Service
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_NEAREST_YACHT) && (
                  <Menu.Item>
                    <Link to="/nearest-boat-service">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Nearest Yacht
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_TOP_RATED_YACHT) && (
                  <Menu.Item>
                    <Link to="/top-rated-boat-service">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Top Rated Yacht
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_MARINA_AND_STORAGE) && (
              <SubMenu
                key="marina"
                title={
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-parking" />} name="Manage Marina & Storage" />
                }
              >
                {hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_ALL_MARINA_BOATS) && (
                  <Menu.Item>
                    <Link to="/manage-marina-storage">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage All Marina Boats
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_POPULAR_MARINA) && (
                  <Menu.Item>
                    <Link to="/most-popular-marina">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Popular Marina
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_MORE_SERVICES) && (
                  <Menu.Item>
                    <Link to="/marina-more-services">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage More Services
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_NEAREST_MARINA) && (
                  <Menu.Item>
                    <Link to="/nearest-marina">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Nearest Marina
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_LIVE_EXPERIENCE) && (
                  <Menu.Item>
                    <Link to="/live-experience-marina">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Live Experience
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {hasPermission(AdminPermissions.MANAGE_BOAT_SHOWS) && (
              <Menu.Item>
                <Link to="/manage-boat-shows">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-boat" />} name="Manage Boat Shows" />
                </Link>
              </Menu.Item>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.PENDING_ADS) && (
              <SubMenu
                key="sub11"
                title={
                  <MenuItem
                    isCollapse={collapsed}
                    iconClass={<i className="adam-time" />}
                    name={<>Pending Ads {isPendingAdsExist && <BellFilled className="ml-10" style={{ color: '#f7d715' }} />}</>}
                  />
                }
              >
                {hasPermission(AdminPermissions.PENDING_ADS__BOAT_OWNER_ADS) && (
                  <Menu.Item>
                    <Link to="/boat-owner-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Boat owner Ads
                      {verificationAlertCount?.pendingBoatAdsCount > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.PENDING_ADS__BROKER_AND_DEALER_ADS) && (
                  <Menu.Item>
                    <Link to="/broker-and-dealer-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Broker and dealer Ads
                      {verificationAlertCount?.pendingBrokerAndDealerCount > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.PENDING_ADS__BOAT_MANUFACTURER_ADS) && (
                  <Menu.Item>
                    <Link to="/boat-manufacturer-boats">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Boat Manufacturer Ads
                      {verificationAlertCount?.pendingMfgAdsCount > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.PENDING_ADS__BOAT_SERVICE_ADS) && (
                  <Menu.Item>
                    <Link to="/pending-boat-service">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Boat Service
                      {verificationAlertCount?.pendingServiceAds > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.PENDING_ADS__RENT_ADS) && (
                  <Menu.Item>
                    <Link to="/pending-boat-rents">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Rent Ads
                      {verificationAlertCount?.pendingRentAds > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.PENDING_ADS__MARINA_AND_STORAGE_ADS) && (
                  <Menu.Item>
                    <Link to="/pending-marina-and-storages">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Marina & Storage Ads
                      {verificationAlertCount?.pendingMarinaAds > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {hasPermission(AdminPermissions.PAYMENT_METHODS) && (
              <Menu.Item>
                <Link to="/payment-methods">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-payment-method" />} name="Payment Methods" />
                </Link>
              </Menu.Item>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_COMPLAINTS) && (
              <SubMenu
                key="complaint"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-chat-2"></i>} name="Manage Complaints" />}
              >
                {hasPermission(AdminPermissions.MANAGE_COMPLAINTS__MANAGE_AD_COMPLAINTS) && (
                  <Menu.Item>
                    <Link to="/manage-ad-complaints">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Ad Complaints
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_COMPLAINTS__MANAGE_REVIEW_COMPLAINTS) && (
                  <Menu.Item>
                    <Link to="/manage-review-complaints">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Review Complaints
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_AGREEMENTS) && (
              <SubMenu
                key="sub4"
                title={
                  <MenuItem
                    isCollapse={collapsed}
                    iconClass={<i className="adam-list"></i>}
                    name={
                      <>
                        Manage Agreements
                        {isPendingAgreementExist && <BellFilled className="ml-10" style={{ color: '#f7d715' }} />}
                      </>
                    }
                  />
                }
              >
                {hasPermission(AdminPermissions.MANAGE_AGREEMENTS__BUILDER_AGREEMENTS) && (
                  <Menu.Item>
                    <Link to="/builder-agreements">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Builder Agreements
                      {verificationAlertCount?.pendingBuilderAgreement > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_AGREEMENTS__SHIPMENT_AGREEMENTS) && (
                  <Menu.Item>
                    <Link to="/shipment-agreements">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Shipment Agreements
                      {verificationAlertCount?.pendingShipperAgreement > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_AGREEMENTS__SURVEYOR_AGREEMENTS) && (
                  <Menu.Item>
                    <Link to="/surveyor-agreements">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Surveyor Agreements
                      {verificationAlertCount?.pendingSurveyorAgreement > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_AGREEMENTS__ADD_AGREEMENT_CONTENT) && (
                  <Menu.Item>
                    <Link to="/agreement-contents">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Add Agreement Content
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_AGREEMENTS__AGREEMENT_REQUESTS) && (
                  <Menu.Item>
                    <Link to="/agreement-requests">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Agreement Requests
                      {verificationAlertCount?.pendingAgreementReq > 0 && (
                        <BellFilled className="ml-10" style={{ color: '#f7d715' }} />
                      )}
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {hasPermission(AdminPermissions.MANAGE_VIDEOS) && (
              <Menu.Item>
                <Link to="/manage-videos">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-video-camera11" />} name="Manage Videos" />
                </Link>
              </Menu.Item>
            )}

            {hasPermission(AdminPermissions.MANAGE_DOWNLOADS) && (
              <Menu.Item>
                <Link to="/manage-downloads">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-download1" />} name="Manage Downloads" />
                </Link>
              </Menu.Item>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.STATIC_CONTENT) && (
              <SubMenu
                key="sub5"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-edit-2" />} name="Static Content" />}
              >
                {hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_AGREEMENTS_LISTING) && (
                  <Menu.Item>
                    <Link to="/manage-agreements-listing">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Agreements Listing
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_PAGES) && (
                  <Menu.Item>
                    <Link to="/manage-pages/all">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Pages
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_RENT_PAGES) && (
                  <Menu.Item>
                    <Link to="/manage-rent-pages">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Rent Pages
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_SALES_ENGINE_PAGES) && (
                  <Menu.Item>
                    <Link to="/manage-pages/salesEngine">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Sales Engine Pages
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__FORGOT_PASSWORD) && (
                  <Menu.Item>
                    <Link to="/forgot-password">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Forgot Password
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_ARTICLES) && (
                  <Menu.Item>
                    <Link to="/manage-articles">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Articles
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_USER_GUIDE) && (
                  <Menu.Item>
                    <Link to="/manage-user-guide">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage User Guide
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_JOBS) && (
                  <Menu.Item>
                    <Link to="/manage-jobs">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Jobs
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_FAQS) && (
                  <Menu.Item>
                    <Link to="/manage-faqs">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage FAQs
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__REGISTRATION_HEADER) && (
                  <Menu.Item>
                    <Link
                      to={{
                        pathname: '/registration-header',
                        state: { typeIs: 'header' },
                      }}
                    >
                      <WSIcon type={<ArrowRightOutlined />} />
                      Registration Header
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__REGISTRATION_CONTENT) && (
                  <Menu.Item>
                    <Link
                      to={{
                        pathname: '/registration-content',
                        state: { typeIs: 'content' },
                      }}
                    >
                      <WSIcon type={<ArrowRightOutlined />} />
                      Registration Content
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.STATIC_CONTENT__HELP_SECTION) && (
                  <Menu.Item>
                    <Link to="/manage-help-section">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Help Section
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_CATEGORY) && (
              <SubMenu
                key="category"
                title={
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-newspaper-1" />} name="Manage Category" />
                }
              >
                {hasPermission(AdminPermissions.MANAGE_CATEGORY__MANAGE_RENT_TYPE) && (
                  <Menu.Item>
                    <Link to="/manage-rent-types">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Rent Type
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_CATEGORY__MANAGE_MARINA_TYPE) && (
                  <Menu.Item>
                    <Link to="/manage-marina-types">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Marina Type
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_CATEGORY__MANAGE_BOAT_TYPES) && (
                  <Menu.Item>
                    <Link to="/manage-boat-types">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Boat types
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_CATEGORY__MANAGE_YATCH_SERVICE) && (
                  <Menu.Item>
                    <Link to="/manage-yatch-types">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Yatch Service
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.COUNTRY) && (
              <SubMenu
                key="sub6"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-country-1" />} name="Country" />}
              >
                {hasPermission(AdminPermissions.COUNTRY__MANAGE_COUNTRIES) && (
                  <Menu.Item>
                    <Link to="/manage-country">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Countries
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.COUNTRY__MANAGE_STATE_PROVINCE) && (
                  <Menu.Item>
                    <Link to="/manage-state">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage State/Province
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.FORMS) && (
              <SubMenu
                key="feedback"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-home-1"></i>} name="Forms" />}
              >
                {hasPermission(AdminPermissions.FORMS__COMPLAINT) && (
                  <Menu.Item>
                    <Link to="/complaint">
                      <WSIcon type={<ArrowRightOutlined />} /> Complaint
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FORMS__SUGGESTIONS) && (
                  <Menu.Item>
                    <Link to="/suggestions">
                      <WSIcon type={<ArrowRightOutlined />} /> Suggestions
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FORMS__CUSTOMER_SERVICES) && (
                  <Menu.Item>
                    <Link to="/customer-service">
                      <WSIcon type={<ArrowRightOutlined />} /> Customer Services
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.FORMS__FEEDBACK) && (
                  <Menu.Item>
                    <Link to="/feedback">
                      <WSIcon type={<ArrowRightOutlined />} /> Feedback
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.SALES_ENGINE) && (
              <SubMenu
                key="sub7"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-sales"></i>} name="Sales Engine" />}
              >
                {hasPermission(AdminPermissions.SALES_ENGINE__COMMISSION_AND_SERVICE_FEES) && (
                  <Menu.Item>
                    <Link to="/manage-sales-engin-commision">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Commision and Service Fees
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.SALES_ENGINE__RELEASE_PAYMENT) && (
                  <Menu.Item>
                    <Link to="/manage-sales-engin-release-payment">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Release Payment
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.SALES_ENGINE__SALES_ENGINE_PAYMENT) && (
                  <Menu.Item>
                    <Link to="/manage-sales-engin-payment">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Sales Engin Payment
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.SALES_ENGINE__SALES_ENGINE_PROGRESS) && (
                  <Menu.Item>
                    <Link to="/sales-engin-progress">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Sales Engin Progress
                    </Link>
                  </Menu.Item>
                )}

                {/* {hasPermission(AdminPermissions.SALES_ENGINE__MANAGE_SALES_ENGINE) && (
                  <Menu.Item>
                    <Link to="/sales-engine-listing">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Sales Engine
                    </Link>
                  </Menu.Item>
                )} */}

                {/* {hasPermission(AdminPermissions.SALES_ENGINE__ARCHIVE_SALES_PROCESS) && (
                  <Menu.Item>
                    <Link to="/sales-engine-archive-listing">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Archive Sales Process
                    </Link>
                  </Menu.Item>
                )} */}

                {hasPermission(AdminPermissions.SALES_ENGINE__BLACKLIST_SALES_PROCESS) && (
                  <Menu.Item>
                    <Link to="/view-sales-engine-blacklist">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Blacklist Sales Process
                    </Link>
                  </Menu.Item>
                )}

                {/* {hasPermission(AdminPermissions.SALES_ENGINE__MANAGE_HELP_CONTENT) && (
                  <Menu.Item>
                    <Link to="/sale-engine-help-content">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Help Content
                    </Link>
                  </Menu.Item>
                )} */}

                {/* {hasPermission(AdminPermissions.SALES_ENGINE__MANAGE_STATIC_CONTENT) && (
                  <Menu.Item>
                    <Link to="/manage-static-sales-content">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Static Content
                    </Link>
                  </Menu.Item>
                )} */}

                {hasPermission(AdminPermissions.SALES_ENGINE__MANAGE_SURVEY_DOCUMENT_DATA) && (
                  <Menu.Item>
                    <Link to="/survey-document-data">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Manage Survey Document Data
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_BANNER_IMAGES) && (
              <SubMenu
                key="sub8"
                title={
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-list"></i>} name="Manage Banner Images" />
                }
              >
                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__HOME_PAGE_BANNER) && (
                  <Menu.Item>
                    <Link to="/home-banner">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Home Page Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_RENT_BANNER) && (
                  <Menu.Item>
                    <Link to="/rent-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Market Rent Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__BOAT_SHOW_BANNER) && (
                  <Menu.Item>
                    <Link to="/boatshow-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Boat Show Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_SERVICE_BANNER) && (
                  <Menu.Item>
                    <Link to="/service-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Market Service Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__BOAT_MEDIA_BANNER) && (
                  <Menu.Item>
                    <Link to="/boat-media-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Boat Media Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_MARINA_BANNER) && (
                  <Menu.Item>
                    <Link to="/marina-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Market Marina Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__ARTICLE_BANNER) && (
                  <Menu.Item>
                    <Link to="/manage-article-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Article Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__DOWNLOAD_BANNER) && (
                  <Menu.Item>
                    <Link to="/manage-download-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Download Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__FAQ_BANNER) && (
                  <Menu.Item>
                    <Link to="/manage-faq-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      FAQ Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__USER_GUIDE_BANNER) && (
                  <Menu.Item>
                    <Link to="/manage-user-guide-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      User Guide Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__OTHER_PAGE_BANNER) && (
                  <Menu.Item>
                    <Link to="/manage-other-page-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Other Page Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__ADMIN_LOGIN_BANNER) && (
                  <Menu.Item>
                    <Link to="/manage-admin-login-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Admin Login Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__LOGIN_BANNER) && (
                  <Menu.Item>
                    <Link to="/manage-login-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Login Banner
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__SIGNUP_BANNER) && (
                  <Menu.Item>
                    <Link to="/manage-signup-banners">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Signup Banner
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_AUCTION_ROOMS) && (
              <SubMenu
                key="sub14"
                title={
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-auction" />} name="Manage Auction Room" />
                }
              >
                {hasPermission(AdminPermissions.MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOMS) && (
                  <Menu.Item>
                    <Link to="/manage-auction-room">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Auction Room
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOM_PAYMENTS) && (
                  <Menu.Item>
                    <Link to="/manage-auction-room-payment">
                      <WSIcon type={<ArrowRightOutlined />} />
                      Auction Room Payment
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_SURVEYOR_BRANCH) && (
              <SubMenu
                key="sub12"
                title={
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-service" />} name="Manage Surveyor Branch" />
                }
              >
                {hasPermission(AdminPermissions.MANAGE_SURVEYOR_BRANCH__ALL_BRANCHES) && (
                  <Menu.Item>
                    <Link to="/all-branches">
                      <WSIcon type={<ArrowRightOutlined />} />
                      All Branches
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {hasPermission(AdminPermissions.ACTIVITY_LOGS) && (
              <Menu.Item>
                <Link to="/activity-log">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-list-1" />} name="Activity Logs" />
                </Link>
              </Menu.Item>
            )}

            {hasPermission(AdminPermissions.ADMIN_ACTIVITY_LOGS) && (
              <Menu.Item>
                <Link to="/admin-activity-log">
                  <MenuItem isCollapse={collapsed} iconClass={<i className="adam-list-1" />} name="Admin Activity Logs" />
                </Link>
              </Menu.Item>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_REVIEWS) && (
              <SubMenu
                key="sub13"
                title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-auction" />} name="Manage Reviews" />}
              >
                {hasPermission(AdminPermissions.MANAGE_REVIEWS__BOAT_OWNER_REVIEWS) && (
                  <Menu.Item>
                    <Link to="/manage-review-ratings/boat-owner">
                      <MenuItem isCollapse={collapsed} iconClass={<i className="adam-list-1" />} name="Boat Owner Reviews" />
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_REVIEWS__BOAT_MANUFACTURER_REVIEWS) && (
                  <Menu.Item>
                    <Link to="/manage-review-ratings/boat-manufacturer">
                      <MenuItem
                        isCollapse={collapsed}
                        iconClass={<i className="adam-list-1" />}
                        name="Boat Manufacturer Reviews"
                      />
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_REVIEWS__SHIPPER_REVIEWS) && (
                  <Menu.Item>
                    <Link to="/manage-review-ratings/yacht-shipper">
                      <MenuItem isCollapse={collapsed} iconClass={<i className="adam-list-1" />} name="Shipper Reviews" />
                    </Link>
                  </Menu.Item>
                )}

                {hasPermission(AdminPermissions.MANAGE_REVIEWS__BROKER_AND_DEALER_REVIEWS) && (
                  <Menu.Item>
                    <Link to="/manage-review-ratings/broker-and-dealer">
                      <MenuItem
                        isCollapse={collapsed}
                        iconClass={<i className="adam-list-1" />}
                        name="Broker & Dealer Reviews"
                      />
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {haveAnyPermissionInGroup(AdminPermissionGroups.SOCIAL_MEDIA) && (
              <SubMenu title={<MenuItem isCollapse={collapsed} iconClass={<i className="adam-newspaper-1" />} name="Social Media" />}>
                {hasPermission(AdminPermissions.SOCIAL_MEDIA__POST_REPORTS) && (
                  <Menu.Item>
                    <Link to="/social-media/post-reports">
                      <MenuItem
                        isCollapse={collapsed}
                        iconClass={<i className="adam-list-1" />}
                        name="Post Reports"
                      />
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}
          </Menu>
        </Sider>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    isSuperAdmin: state.permissionsState.isSuperAdmin,
    verificationAlertCount: state.adminOtherCountReducer.alertCount,
  }
}

export default connect(mapStateToProps)(XMenu)
