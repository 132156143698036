import gql from 'graphql-tag'

export const getDownloadByModule = gql`
  query getAllDownloads($page: Int, $limit: Int, $searchTerm: String) {
    getAllDownloads(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        id
        title
        description
        createdAt
        downloadUrl {
          id
          url
        }
        status
      }
      total
    }
  }
`

export const createDownload = gql`
  mutation createDownload($input: ManageDownloadInput!) {
    createDownload(input: $input) {
      id
      title
      description
      createdAt
      status
    }
  }
`

export const updateDownload = gql`
  mutation updateDownload($input: ManageDownloadInput!) {
    updateDownload(input: $input) {
      id
    }
  }
`

export const deleteDownload = gql`
  mutation deleteDownload($id: String!) {
    deleteDownload(id: $id) {
      id
    }
  }
`
