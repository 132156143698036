import gql from 'graphql-tag'

export const getAllFaqList = gql`
  query getAllFaqList($page: Int, $limit: Int, $searchTerm: String, $status: Boolean) {
    getAllFaqList(page: $page, limit: $limit, searchTerm: $searchTerm, status: $status) {
      items {
        id
        question
        description
        status
      }
      total
    }
  }
`

export const createFaqDetail = gql`
  mutation createFaqDetail($input: FaqInput!) {
    createFaqDetail(input: $input) {
      question
      description
    }
  }
`

export const updateFaqDetail = gql`
  mutation updateFaqDetail($input: FaqInput!) {
    updateFaqDetail(input: $input) {
      question
      description
    }
  }
`

export const deleteFaqDetail = gql`
  mutation deleteFaqDetail($id: String!) {
    deleteFaqDetail(id: $id) {
      id
    }
  }
`

export const changeFaqDetailStatus = gql`
  mutation changeFaqDetailStatus($value: Boolean!, $column: String!, $id: String!) {
    changeFaqDetailStatus(value: $value, column: $column, id: $id) {
      id
      question
      description
      status
    }
  }
`
