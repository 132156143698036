import React, { Component } from 'react'
import moment from 'moment'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card } from 'antd'

import History from '../../../History'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'

import { getAllAgreementContents, deleteAgreementContent } from '../../../store/manageAgreements/Action'
import { agreementContent } from '../../../store/manageAgreements/Types'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { formateDate } from '../../../helpers/helper'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import { getAllBoatPagination } from '../../../enums/enums'
import FilterButton from '../../filters/FilterButton'

interface Props {
  history: typeof History
  getAllAgreementContents: typeof getAllAgreementContents
  agreementContents: []
  agreementContentsTotalRow: Number
  deleteAgreementContent: typeof deleteAgreementContent
}

interface State {
  columns: Array<Object>
}

class ManageAgreementContents extends Component<Props, State> {
  state = {
    columns: [
      {
        title: 'Page Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a: any, b: any) => a.title.length - b.title.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Last Modified',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text: string, record: tableRecords, index: number) => <> {formateDate(record && record.updatedAt)} </>,
        sorter: (a: any, b: any) => a.updatedAt.length - b.updatedAt.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteAgreementContent } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editAgreementContent(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteAgreementContent, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  // componentDidMount() {
  //   const { getAllAgreementContents } = this.props
  //   getAllAgreementContents({ ...getAllBoatPagination })
  // }

  editAgreementContent = (record: tableRecords) => {
    const { history } = this.props
    if (record && history) {
      history.push('/create-agreement-content', {
        agreementContents: { record },
      })
    }
  }

  render() {
    const { columns } = this.state
    const { agreementContents, agreementContentsTotalRow, getAllAgreementContents, history } = this.props

    return (
      <Card
        title="Manage All Agreement Contents"
        extra={
          <Button className="ml-1 btn-view" onClick={() => history.push('/create-agreement-content')}>
            <WSIcon type={<PlusOutlined />} />
            Add Agreements Content
          </Button>
        }
      >
        <ExpandTable
          columns={columns}
          tableData={agreementContents}
          totalUsersRow={agreementContentsTotalRow}
          isExpand={false}
          page="boat"
          pageAction={getAllAgreementContents}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  agreementContents: state.agreementReducer.agreementContents,
  agreementContentsTotalRow: state.agreementReducer.agreementContentsTotalRow,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllAgreementContents: (data: any) => dispatch(getAllAgreementContents(data)),
  deleteAgreementContent: (data: string) => dispatch(deleteAgreementContent(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAgreementContents)
