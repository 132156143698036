export interface adminUsersState {
  adminUsers: any[]
  adminCountSuccess: boolean
  adminCountFailure: boolean
}

export interface adminUserData {
  roleCounts: [string]
  total: number
}

export const GET_ADMIN_USERS_COUNT = 'GET_ADMIN_USERS_COUNT'
export const GET_ADMIN_USERS_COUNT_SUCCESS = 'GET_ADMIN_USERS_COUNT_SUCCESS'
export const GET_ADMIN_USERS_COUNT_FAILURE = 'GET_ADMIN_USERS_COUNT_FAILURE'

export const CLEAR_ADMIN_USERS_COUNT_FLAG = 'CLEAR_ADMIN_USERS_COUNT_FLAG'

interface getAdminUsersCount {
  type: typeof GET_ADMIN_USERS_COUNT
  payload: any
}

interface getAdminUsersCountSuccess {
  type: typeof GET_ADMIN_USERS_COUNT_SUCCESS
  payload: any
}

interface getAdminUsersCountFailure {
  type: typeof GET_ADMIN_USERS_COUNT_FAILURE
  payload: any
}

interface clearAdminUserFlag {
  type: typeof CLEAR_ADMIN_USERS_COUNT_FLAG
  payload: any
}

export type ActionType = getAdminUsersCount | getAdminUsersCountSuccess | getAdminUsersCountFailure | clearAdminUserFlag
