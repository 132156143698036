import {
  GET_ADMIN_USER_ROLE,
  CLEAR_ADMIN_USER_ROLE_FLAG,
  adminUserRoleData,
  CREATE_ADMIN_USER_ROLE,
  DELETE_ADMIN_USER_ROLE,
  GET_FLAG_COMPLAINT,
  SEARCH_USER,
} from './Type'

export function getAdminUserRole(data: any) {
  return {
    type: GET_ADMIN_USER_ROLE,
    payload: data,
  }
}

export function clearAdminUserRoleFlag() {
  return {
    type: CLEAR_ADMIN_USER_ROLE_FLAG,
  }
}

export function createAdminUserRole(data: adminUserRoleData) {
  return {
    type: CREATE_ADMIN_USER_ROLE,
    payload: data,
  }
}

export function deleteAdminUserRole(data: string) {
  return {
    type: DELETE_ADMIN_USER_ROLE,
    payload: data,
  }
}

export function getFlagComplaint(data: any) {
  return {
    type: GET_FLAG_COMPLAINT,
    payload: data,
  }
}

export const searchUser = (data: any) => ({
  type: SEARCH_USER,
  payload: data,
})
