import React, { Component } from 'react'

import CreateBannerIndex from '../CreateBannerIndex'
import History from '../../../History'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  location: any
  history: typeof History
}

class CreateBannerImages extends Component<Props> {
  render() {
    const { location, history } = this.props
    return (
      <CreateBannerIndex
        history={history}
        backLink="/home-banner"
        location={location}
        pageTitle="Home Banner"
        titlePage="Home"
        type={mediaCategory.home.type}
      />
    )
  }
}

export default CreateBannerImages
