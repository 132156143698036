import {
  YatchTypesData,
  GET_ALL_YATCH_TYPES,
  DELETE_YATCH_TYPES,
  CLEAR_MANAGE_YATCH_TYPES_FLAG,
  CREATE_YATCH_TYPES,
  EDIT_YATCH_TYPES,
  UPDATE_YATCH_TYPES,
  yatchTypes,
} from './Types'

export function createYatchTypes(data: YatchTypesData) {
  return {
    type: CREATE_YATCH_TYPES,
    payload: data,
  }
}

export function getAllYachtServiceTypes(data: any) {
  return {
    type: GET_ALL_YATCH_TYPES,
    payload: data,
  }
}

export function clearManageYatchTypesFlag() {
  return {
    type: CLEAR_MANAGE_YATCH_TYPES_FLAG,
  }
}

export function updateYatchTypes(data: yatchTypes) {
  return {
    type: UPDATE_YATCH_TYPES,
    payload: data,
  }
}
export function deleteYatchTypes(data: string) {
  return {
    type: DELETE_YATCH_TYPES,
    payload: data,
  }
}
export function editYatchTypes(data: string) {
  return {
    type: EDIT_YATCH_TYPES,
    payload: data,
  }
}
