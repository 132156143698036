import { put, takeLatest, all } from 'redux-saga/effects'
import {
  CREATE_MARINA_TYPES,
  ActionType,
  CREATE_MARINA_TYPES_SUCCESS,
  CREATE_MARINA_TYPES_FAILURE,
  GET_MARINA_TYPES,
  GET_MARINA_TYPES_SUCCESS,
  GET_MARINA_TYPES_FAILURE,
  UPDATE_MARINA_TYPES,
  UPDATE_MARINA_TYPES_SUCCESS,
  UPDATE_MARINA_TYPES_FAILURE,
  DELETE_MARINA_TYPES,
  DELETE_MARINA_TYPES_SUCCESS,
  DELETE_MARINA_TYPES_FAILURE,
  BoatTypesData,
  EDIT_BOAT_TYPES_SUCCESS,
  EDIT_BOAT_TYPES_FAILURE,
  EDIT_BOAT_TYPES,
} from './Types'
import { client } from '../../../'
import { createMarinaType, updateMarinaType, deleteMarinaType, editBoatTypes, getAllMarinaTypes } from './Schema'
import { get } from 'lodash'

// create marina type

export function createMarinaTypeApi(input: BoatTypesData) {
  return client
    .mutate({
      mutation: createMarinaType,
      variables: { input },
    })
}

export function* createMarinaTypeData(action: ActionType) {
  try {
    const data = yield createMarinaTypeApi(action.payload)
    yield put({ type: CREATE_MARINA_TYPES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: CREATE_MARINA_TYPES_FAILURE, error })
  }
}

export function* createMarinaTypeSaga() {
  yield takeLatest(CREATE_MARINA_TYPES, createMarinaTypeData)
}

// get marina type

export function getMarinaTypesApi(payload: any) {
  return client
    .query({
      query: getAllMarinaTypes,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

export function* getMarinaTypesData(action: ActionType) {
  try {
    const data = yield getMarinaTypesApi(action.payload)
    yield put({
      type: GET_MARINA_TYPES_SUCCESS,
      payload: data.data.getAllMarinaTypes,
    })
  } catch (error) {
    yield put({ type: GET_MARINA_TYPES_FAILURE, error })
  }
}

export function* getMarinaTypesSaga() {
  yield takeLatest(GET_MARINA_TYPES, getMarinaTypesData)
}

// delete marina type

function deleteMarinaTypesApi(data: string) {
  return client
    .mutate({
      mutation: deleteMarinaType,
      variables: { id: data },
    })
}

function* deleteMarinaTypesData(action: ActionType) {
  try {
    const res = yield deleteMarinaTypesApi(action.payload)

    if (res?.data?.removeMarinaType) {
      yield put({
        type: DELETE_MARINA_TYPES_SUCCESS,
        payload: res.data.removeMarinaType,
      })
    } else {
      console.log(res)
      yield put({
        type: DELETE_MARINA_TYPES_FAILURE,
        payload: '',
      })
    }
  } catch (error) {
    console.log(error, get(error, 'networkError', ''))
    yield put({ type: DELETE_MARINA_TYPES_FAILURE, error })
  }
}

function* deleteMarinaTypesSaga() {
  yield takeLatest(DELETE_MARINA_TYPES, deleteMarinaTypesData)
}

// edit marina type

function editBoatTypesApi(data: string) {
  return client
    .query({
      query: editBoatTypes,
      variables: { id: data },
    })
}

function* editBoatTypesData(action: ActionType) {
  try {
    const res = yield editBoatTypesApi(action.payload)
    yield put({ type: EDIT_BOAT_TYPES_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: EDIT_BOAT_TYPES_FAILURE, error })
  }
}

function* editBoatTypesSaga() {
  yield takeLatest(EDIT_BOAT_TYPES, editBoatTypesData)
}

// update marina type

export function updateMarinaTypesApi(data: any) {
  return client
    .mutate({
      mutation: updateMarinaType,
      variables: { input: data },
    })
}

export function* updateMarinaTypesData(action: ActionType) {
  try {
    const data = yield updateMarinaTypesApi(action.payload)
    yield put({ type: UPDATE_MARINA_TYPES_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_MARINA_TYPES_FAILURE, error })
  }
}

export function* updateMarinaTypesSaga() {
  yield takeLatest(UPDATE_MARINA_TYPES, updateMarinaTypesData)
}

export default function* marinaTypesSaga() {
  yield all([
    createMarinaTypeSaga(),
    getMarinaTypesSaga(),
    updateMarinaTypesSaga(),
    deleteMarinaTypesSaga(),
    editBoatTypesSaga(),
  ])
}
