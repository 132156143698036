import {
  GET_SALES_ENGINE,
  DELETE_SALES_ENGINE,
  CLEAR_SALES_ENGINE_FLAG,
  GET_SALES_ENGINE_STATIC_CONTENT,
  CREATE_SALES_ENGINE_STATIC_CONTENT,
  GET_SALES_ENGINE_PAYMENT,
  getSalesEnginPaymentInput,
  GET_SALES_ENGINE_COMMISION,
  EDIT_COMMISION,
  getSalesEnginReleasePaymentInput,
  GET_SALES_ENGINE_RELEASE,
  RELEASE_PAYMENT,
  REFUND_PAYMENT,
} from './Type'

export function getManageSalesEngine(data: any) {
  return {
    type: GET_SALES_ENGINE,
    payload: data,
  }
}

export const getAllSalesEnginPayment = (data: getSalesEnginPaymentInput) => ({
  type: GET_SALES_ENGINE_PAYMENT,
  payload: data,
})

export const getAllSalesEnginCommision = (data: getSalesEnginPaymentInput) => ({
  type: GET_SALES_ENGINE_COMMISION,
  payload: data,
})

export const getAllSalesEnginRelease = (data: getSalesEnginReleasePaymentInput) => ({
  type: GET_SALES_ENGINE_RELEASE,
  payload: data,
})

export const releasePayment = (data: any) => ({
  type: RELEASE_PAYMENT,
  payload: data,
})

export function deleteSalesEngine(data: string) {
  return {
    type: DELETE_SALES_ENGINE,
    payload: data,
  }
}

export function clearSalesEngineFlag() {
  return {
    type: CLEAR_SALES_ENGINE_FLAG,
  }
}

export function getManageSalesEngineStaticContent() {
  return {
    type: GET_SALES_ENGINE_STATIC_CONTENT,
  }
}

export function createSalesEngineStaticContent(data: any) {
  return {
    type: CREATE_SALES_ENGINE_STATIC_CONTENT,
    payload: data,
  }
}

export function editCommisionAction(data: any) {
  return {
    type: EDIT_COMMISION,
    payload: data,
  }
}

export const refundPayment = (payload: any) => ({
  type: REFUND_PAYMENT,
  payload
})