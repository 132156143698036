import React from 'react'

import { Pagination } from 'antd'

const PaginationBox = (props: any) => {
  const { totalRow, onPageChange, currentPage } = props
  return (
    <Pagination
      showSizeChanger={false}
      style={{ paddingTop: 20 }}
      current={currentPage}
      total={totalRow}
      onChange={(current: any) => onPageChange(current)}
    />
  )
}
export default PaginationBox
