import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { Card, Row, Col, Typography, Button, Input } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { createCanadianTax, updateCanadianTax, clearFlag } from '../../../store/salesEngine/canadianTax/Action'
import { canadianTaxData } from '../../../store/salesEngine/canadianTax/Type'
import { connect } from 'react-redux'
import History from '../../../History'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'

const { Text } = Typography

export interface Props {
  createCanadianTax: typeof createCanadianTax
  location: any
  updateCanadianTax: typeof updateCanadianTax
  success: boolean
  clearFlag: typeof clearFlag
  history: typeof History
}
export interface State {
  editTax: any
  canadianTax: {
    canadaProvince: string
    name: string
    GSTorHST: string
    PST: string
  }
}

class CreateCanadianTax extends Component<Props, State> {
  formSubmitHandler = (values: canadianTaxData) => {
    const { editTax } = this.state
    const { createCanadianTax, updateCanadianTax } = this.props
    if (editTax) {
      updateCanadianTax(values)
    } else {
      createCanadianTax(values)
    }
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      editTax: props.location.state ? props.location.state.canadianTax : null,
      canadianTax: {
        canadaProvince: '',
        name: '',
        GSTorHST: '',
        PST: '',
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    if (props.success) {
      window.location.href = '/canadian-tax'
      props.clearFlag()
    }
    return null
  }

  render() {
    const { canadianTax, editTax } = this.state
    const { history } = this.props
    return (
      <Fragment>
        <Formik
          initialValues={editTax || canadianTax}
          onSubmit={values => this.formSubmitHandler(values)}
          validationSchema={Yup.object().shape({
            canadaProvince: Yup.string().required('Canada province field is required.'),
            name: Yup.string().required('Canada province fullName field is required.'),
            GSTorHST: Yup.string().required('Canada GST/HST field is required.'),
            PST: Yup.string().required('Canada pst field is required.'),
          })}
        >
          {props => {
            const { handleSubmit, setFieldValue, values } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      Add Tax
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                        Save
                      </button>
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Canada Province">
                        Canada Province
                      </span>

                      <Field
                        className="ant-input"
                        name="canadaProvince"
                        placeholder="Canada Province"
                        value={values.canadaProvince}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name="canadaProvince"
                          component="span"
                        />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Canada Province Full Name">
                        Canada Province Full Name
                      </span>

                      <Field className="ant-input" name="name" placeholder="canada Province Full Name" value={values.name} />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="name" component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Canada GST/HST">
                        Canada GST/HST
                      </span>

                      <Field className="ant-input" name="GSTorHST" placeholder="Canada GST/HST" value={values.GSTorHST} />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="GSTorHST" component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Canada PST">
                        Canada PST
                      </span>

                      <Field className="ant-input" name="PST" placeholder="Canada PST" value={values.PST} />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="PST" component="span" />
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  success: state.canadianTexReducer && state.canadianTexReducer.success,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createCanadianTax: (data: canadianTaxData) => dispatch(createCanadianTax(data)),
  updateCanadianTax: (data: any) => dispatch(updateCanadianTax(data)),
  clearFlag: () => dispatch(clearFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCanadianTax)
