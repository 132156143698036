import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getAllCanadianTaxes, createCanadianTax, deleteCanadianTax, updateCanadianTax, changeStatus } from './Schema'
import {
  GET_ALL_CANADIAN_TAX_SUCCESS,
  GET_ALL_CANADIAN_TAX_FAILURE,
  CREATE_CANADIAN_TAX_SUCCESS,
  CREATE_CANADIAN_TAX_FAILURE,
  ActionType,
  DELETE_CANADIAN_TAX_SUCCESS,
  DELETE_CANADIAN_TAX_FAILURE,
  UPDATE_CANADIAN_TAX_SUCCESS,
  UPDATE_CANADIAN_TAX_FAILURE,
  GET_ALL_CANADIAN_TAX,
  DELETE_CANADIAN_TAX,
  CREATE_CANADIAN_TAX,
  UPDATE_CANADIAN_TAX,
  CHANGE_TAX_STATUS_SUCCESS,
  CHANGE_TAX_STATUS_FAILURE,
  CHANGE_TAX_STATUS,
} from './Type'

function getCanadianTaxApi() {
  return client
    .query({
      query: getAllCanadianTaxes,
    })
}

function createCanadianTaxApi(canadianTax: any) {
  return client
    .mutate({
      mutation: createCanadianTax,
      variables: { canadianTax },
    })
}

function deleteCanadianTaxApi(data: any) {
  const canadianTax = data.payload
  return client
    .mutate({
      mutation: deleteCanadianTax,
      variables: { canadianTax },
    })
}

function updateCanadianTaxApi(data: any) {
  const canadianTax = {
    id: data.id,
    canadaProvince: data.canadaProvince,
    name: data.name,
    GSTorHST: data.GSTorHST,
    PST: data.PST,
    status: data.status,
  }
  return client
    .mutate({
      mutation: updateCanadianTax,
      variables: { canadianTax },
    })
}

function changeTaxStatusApi(payload: any) {
  return client.mutate({
    mutation: changeStatus,
    variables: { id: payload.id, columnName: payload.columnName },
  })
}

function* changeTaxStatus(action: ActionType) {
  try {
    const data = yield changeTaxStatusApi(action.payload)
    yield put({ type: CHANGE_TAX_STATUS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CHANGE_TAX_STATUS_FAILURE, e })
  }
}
function* getCanadianTax() {
  try {
    const res = yield getCanadianTaxApi()
    yield put({
      type: GET_ALL_CANADIAN_TAX_SUCCESS,
      payload: res.data.getAllCanadianTaxes,
    })
  } catch (error) {
    yield put({ type: GET_ALL_CANADIAN_TAX_FAILURE, error })
  }
}

function* createTax(action: ActionType) {
  try {
    const res = yield createCanadianTaxApi(action.payload)
    yield put({ type: CREATE_CANADIAN_TAX_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_CANADIAN_TAX_FAILURE, error })
  }
}

function* deleteTax(action: ActionType) {
  try {
    const res = yield deleteCanadianTaxApi(action)
    yield put({ type: DELETE_CANADIAN_TAX_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_CANADIAN_TAX_FAILURE, error })
  }
}

function* updateTax(action: ActionType) {
  try {
    const data = yield updateCanadianTaxApi(action.payload)
    yield put({ type: UPDATE_CANADIAN_TAX_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_CANADIAN_TAX_FAILURE, error })
  }
}

function* changeTaxStatusSaga() {
  yield takeLatest(CHANGE_TAX_STATUS, changeTaxStatus)
}

function* deleteCanadianTaxSaga() {
  yield takeLatest(DELETE_CANADIAN_TAX, deleteTax)
}

function* createCanadianTaxSaga() {
  yield takeLatest(CREATE_CANADIAN_TAX, createTax)
}

function* getCanadianTaxSaga() {
  yield takeLatest(GET_ALL_CANADIAN_TAX, getCanadianTax)
}

function* updateCanadianTaxSaga() {
  yield takeLatest(UPDATE_CANADIAN_TAX, updateTax)
}

export default function* canadianTaxSaga() {
  yield all([
    getCanadianTaxSaga(),
    createCanadianTaxSaga(),
    deleteCanadianTaxSaga(),
    updateCanadianTaxSaga(),
    changeTaxStatusSaga(),
  ])
}
