import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button, Menu, Dropdown, Select } from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ReactQuill from 'react-quill'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import History from '../../../History'

import { createManageFaq, updateManageFaq, clearFaqFlag } from '../../../store/staticContent/manageFAQ/Action'
import { faqData } from '../../../store/staticContent/manageFAQ/Type'
import { CKEditorWrapper } from '../../../component/CKEditor'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { removePTag, trimmedValue } from '../../../helpers/helper'

const { Text } = Typography

interface Props {
  createManageFaq: typeof createManageFaq
  location: any
  updateManageFaq: typeof updateManageFaq
  history: typeof History
  faqSuccess: boolean
  clearFaqFlag: typeof clearFaqFlag
}
interface State {
  editFaq: any
  isEdit: boolean
  manageFaq: {
    question: string
    description: string
  }
}

class CreateFAQ extends Component<Props, State> {
  formSubmitHandler = (values: faqData) => {
    const { editFaq, isEdit } = this.state
    const { createManageFaq, updateManageFaq } = this.props

    if (isEdit) {
      updateManageFaq(values)
    } else {
      createManageFaq(values)
    }
  }

  componentDidMount() {
    const { clearFaqFlag } = this.props
    clearFaqFlag()
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      editFaq: props.location.state ? props.location.state.faq.record : null,
      isEdit: props.location.state ? props.location.state.faq.isEdit : null,
      manageFaq: {
        question: '',
        description: '',
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { faqSuccess, clearFaqFlag, history } = props

    if (faqSuccess) {
      clearFaqFlag()
      history.goBack()
    }
    return null
  }

  render() {
    const { manageFaq, editFaq } = this.state
    const { history } = this.props

    return (
      <Formik
        initialValues={editFaq || manageFaq}
        onSubmit={values => {
          this.formSubmitHandler(values)
        }}
        validationSchema={Yup.object().shape({
          question: Yup.string().required('Question field is required.'),
          description: Yup.string().required('Description field is required.'),
        })}
        render={({ errors, status, touched, setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  Create Frequently Asked Questions
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                    Save
                  </button>
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" xs={24}>
                  <span className="ant-form-item-required" title="Question">
                    Question
                  </span>
                  <Input
                    name="question"
                    placeholder="Question"
                    value={values.question}
                    onChange={e => setFieldValue('question', e.target.value)}
                    onBlur={e => setFieldValue('question', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`question`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="Description">
                    Description
                  </span>
                  {/* <ReactQuill className="editor-height" value={values.description} theme={"snow"} onChange={(values) => setFieldValue('description', values)} /> */}
                  <WSField
                    type="textArea"
                    defaultValue={values.description}
                    onChangeText={(evt: any) => {
                      setFieldValue('description', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('description', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`description`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  faqSuccess: state.faqReducer && state.faqReducer.faqSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createManageFaq: (data: faqData) => dispatch(createManageFaq(data)),
  updateManageFaq: (data: any) => dispatch(updateManageFaq(data)),
  clearFaqFlag: () => dispatch(clearFaqFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateFAQ)
