import gql from 'graphql-tag'
import { AddressType, imageSchema } from '../schemaTypes'

export const getAllBoat = gql`
 query getAllBoat($page: Int, $limit: Int, $country: String, $status: Boolean, $roleType: String, $pendingAd: Boolean, $adStatus: Boolean, $featureStatus: MARKETSTATUSFILTER, $bestDealStatus: MARKETSTATUSFILTER, $mustBuyStatus: MARKETSTATUSFILTER, $searchTerm: String) {
	getAllBoat(page: $page, limit: $limit, country: $country, status: $status, roleType: $roleType, pendingAd: $pendingAd, adStatus: $adStatus, featureStatus: $featureStatus, bestDealStatus: $bestDealStatus, mustBuyStatus: $mustBuyStatus, searchTerm: $searchTerm) {
			items {
				id
				adId
				boatName
				createdAt
				address ${AddressType}
				status
				price
				adStatus
				featureStatus
				bestDealStatus
				mustBuyStatus
				isDeleted
				yearBuilt
				engineHp
				seller {
					id
					firstName
					lastName
					role {
						aliasName
					}
				}
				boatType {
					id
					name
					icon ${imageSchema}
				}
				manufacturedBy {
					typeId {
					  type
					  id
					}
					alias
					id
					lookUpName

				}
			}
			total
		}
	}
`

export const getBoatsByTypeAdmin = gql`
	query getBoatsByTypeAdmin($page: Int, $limit: Int, $input: BoatTypes!, $country: String, $status: Boolean, $adStatus: Boolean) {
		getBoatsByTypeAdmin(page: $page, limit: $limit, input: $input, country: $country, status: $status, adStatus: $adStatus) {
			items {
				id
				adId
				adStatus
				featureStatus
				bestDealStatus
				mustBuyStatus
				address ${AddressType}
				complianceLabelNumber
				hullSerialNumber
				vehicleIdentificationNumber
				licenceNo
				boatOwnershipId  {
					mediaId
					url
				}
				ownershipAttachment {
					mediaId
					url
				}
				images {
					mediaId
					url
				}
				layout {
					mediaId
					url
				}
				listedBy
				boatType {
					id
					name
					icon ${imageSchema}
				}
				boatStatus {
					id
					alias
					typeId {
						id
						type
					}
				}
				boatName
				boatParking {
					id
					alias
					typeId {
						id
						type
					}
				}
				trailer
				yearBuilt
				manufacturedBy {
					typeId {
					  type
					  id
					}
					alias
					id
					lookUpName
					isActive
				  }
				hullColor {
					typeId {
					  type
					  id
					}
					alias
					id
					lookUpName
					isActive
				  }
				hullMaterial {
					id
					alias
					typeId {
						id
						type
					}
				}
				usedHours
				noOfEngines
				modelYear
				fuelType {
					id
					alias
					typeId {
						id
						type
					}
				}
				fuelCapacity
				holdingCapacity
				freshWater
				engineManufacturer
				engineHp
				engineDrives {
					id
					alias
					typeId {
						id
						type
					}
				}
				engineStroke {
					id
					alias
					typeId {
						id
						type
					}
				}
				waterMarker
				bowThruster
				steeringSystem
				stabilizerSystem
				oilWaterSeparator
				fireBilgePump
				output
				generators
				emergencyGenerator
				batteriesCount
				batteryType
				decks
				heightInFt
				lengthInFt
				widthInFt
				weightInKg
				beam
				draft
				displacement
				mainSaloon {
					id
					alias
					typeId {
						id
						type
					}
				}
				mainSaloonConvertible {
					id
					alias
					typeId {
						id
						type
					}
				}
				numberOfHeads
				crewCabinCount
				crewBerthCount
				crewHeadsCount
				usage
				accidentHistory
				repairHistory
				lastMaintenance
				price

				description
				video
				status
				seller {
					firstName
					lastName
					role {
						aliasName
					}
				}
				createdAt
			}
			total
		}
	}
`

export const deleteBoat = gql`
  mutation deleteBoat($id: String!, $note: String!, $isRent: Boolean!, $adType: String) {
    deleteBoat(id: $id, note: $note, isRent: $isRent, adType: $adType) {
      id
    }
  }
`

export const deleteYachtService = gql`
  mutation deleteYachtService($id: String!) {
    deleteYachtService(id: $id) {
      id
    }
  }
`

export const deleteMarinaService = gql`
  mutation deleteMarina($id: String!, $note: String!) {
    deleteMarina(id: $id, note: $note) {
      id
    }
  }
`

export const getAllAuditLogs = gql`
  query getAllAuditLogs($page: Int, $limit: Int, $methodType: Float, $moduleName: Float, $searchTerm: String) {
    getAllAuditLogs(page: $page, limit: $limit, methodType: $methodType, moduleName: $moduleName, searchTerm: $searchTerm) {
      items {
        id
        moduleId
        methodType
        moduleName
        actionType
        user {
          id
          firstName
          lastName
        }
        description
        createdAt
      }
      total
    }
  }
`

export const getAdminAuditLogs = gql`
  query getAdminAuditLogs($page: Int, $limit: Int, $methodType: Float, $moduleName: Float, $searchTerm: String) {
    getAdminAuditLogs(page: $page, limit: $limit, methodType: $methodType, moduleName: $moduleName, searchTerm: $searchTerm) {
      items {
        id
        moduleId
        methodType
        moduleName
        actionType
        user {
          id
          firstName
          lastName
		  role {
			type
			role
		  }
        }
        description
        createdAt
      }
      total
    }
  }
`

export const getMarinaList = gql`
 query getMarinaList($page: Int, $limit: Int, $adStatus: Boolean, $country: String, $pendingAd: Boolean, $liveExperience: MARKETSTATUSFILTER, $moreServices: MARKETSTATUSFILTER, $isPopular: MARKETSTATUSFILTER, $isNearest: MARKETSTATUSFILTER, $searchTerm: String) {
	 	getMarinaList(page: $page, limit: $limit, adStatus: $adStatus, country: $country, pendingAd: $pendingAd, liveExperience: $liveExperience, moreServices: $moreServices,isPopular: $isPopular, isNearest: $isNearest, searchTerm: $searchTerm) {

			items {
				id
				adId
				user {
					id
					companyName
					firstName
					lastName
				}
				address ${AddressType}
				serviceProvide {
					id
					name
				}
				provider {
					alias
				}
				createdAt
				moreServices
				liveExperience
				nearest
				popular
				status
				adStatus
			}
			total
	}
}`

export const getAllRentBoat = gql`
	query getAllRentBoat($page: Int, $limit: Int, $country: String) {
		getAllRentBoat(page: $page, limit: $limit, country: $country) {
			items {
				id
				adId
				model
				address ${AddressType}
				user {
					companyName
					firstName
					lastName
				}
				recommendedTrip
				createdAt
				liveExperience
				status
				popular
				adStatus
			}
			total
	}
}`

export const getAllYachtServiceList = gql`
	query getAllYachtServiceList($page: Int, $limit: Int, $status: Boolean, $adStatus: Boolean, $country: String, $pendingAd: Boolean, $isPopular: MARKETSTATUSFILTER, $isNearest: MARKETSTATUSFILTER, $searchTerm: String) {
		getAllYachtServiceList(page: $page, limit: $limit, status: $status, adStatus: $adStatus, country: $country, pendingAd: $pendingAd, isPopular: $isPopular, isNearest: $isNearest, searchTerm: $searchTerm) {
			items {
				id
				adId
				address ${AddressType}
				user {
					id
					companyName
					firstName
					lastName
				}
				serviceProvide {
					id
					name
				}
				createdAt
				nearest
				popular
				status
				adStatus
			}
			total
	}
}`

export const getMoreServices = gql`
	query getMoreServices($page: Int, $limit: Int) {
		getMoreServices(page: $page, limit: $limit) {
			items {
				address ${AddressType}
				id
				user {
					companyName
					firstName
					lastName
				}
				serviceProvide {
					id
					name
				}
				provider {
					alias
				}
				adId
				createdAt
				moreServices
				adStatus
			}
			total
		}
	}
`

export const nearestMarinaByCountry = gql`
	query nearestMarinaByCountry($page: Int, $limit: Int) {
		nearestMarinaByCountry(page: $page, limit: $limit) {
			items {
				id
				adId
				address ${AddressType}
				user {
					companyName
					firstName
					lastName
				}
				serviceProvide {
					id
					name
				}
				provider {
					alias
				}
				createdAt
				nearest
				adStatus
			}
			total
		}
	}
`

export const getMostPopularMarina = gql`
	query getMostPopularMarina($page: Int, $limit: Int) {
		getMostPopularMarina(page: $page, limit: $limit) {
			items {
				id
				adId
				address ${AddressType}
				user {
					companyName
					firstName
					lastName
				}
				serviceProvide {
					id
					name
				}
				provider {
					alias
				}
				createdAt
				popular
				adStatus
			}
			total
	}
}`

export const getLiveExperienceMarina = gql`
	query getLiveExperienceMarina($page: Int, $limit: Int) {
		getLiveExperienceMarina(page: $page, limit: $limit) {
			items {
				id
				adId
				address ${AddressType}
				user {
					companyName
					firstName
					lastName
				}
				serviceProvide {
					id
					name
				}
				provider {
					alias
				}
				createdAt
				liveExperience
				adStatus
			}
			total
	}
}`

export const nearestBoatServiceByCountry = gql`
	query nearestBoatServiceByCountry($page: Int, $limit: Int) {
		nearestBoatServiceByCountry(page: $page, limit: $limit) {
			items {
				id
				adId
				address ${AddressType}
				user {
					companyName
					firstName
					lastName
				}
				serviceProvide {
					id
					name
				}
				createdAt
				nearest
				adStatus
			}
			total
	}
}`

export const getTopRatedYachtService = gql`
	query getTopRatedYachtService($page: Int, $limit: Int) {
		getTopRatedYachtService(page: $page, limit: $limit) {
			items {
				id
				adId
				address ${AddressType}
				serviceProvide {
					id
					name
				}
				user {
					companyName
					firstName
					lastName
				}
				createdAt
				popular
				adStatus
			}
			total
	}
}`

export const recommendedBoatRents = gql`
	query recommendedBoatRents($page: Int, $limit: Int) {
		recommendedBoatRents(page: $page, limit: $limit) {
			items {
				id
				adId
				address ${AddressType}
				user {
					companyName
					firstName
					lastName
				}
				recommendedTrip
				boatName
				createdAt
				adStatus
			}
			total
	}
}`

export const getMostPopularBoatRents = gql`
	query getMostPopularBoatRents($page: Int, $limit: Int) {
		getMostPopularBoatRents(page: $page, limit: $limit) {
			items {
				id
				address ${AddressType}
				adId
				user {
					companyName
					firstName
					lastName
				}
				boatName
				createdAt
				popular
				adStatus
			}
			total
	}
}`

export const getLiveExperienceBoatRents = gql`
	query getLiveExperienceBoatRents($page: Int, $limit: Int) {
		getLiveExperienceBoatRents(page: $page, limit: $limit) {
			items {
				id
				address ${AddressType}
				adId
				user {
					companyName
					firstName
					lastName
				}
				boatName
				createdAt
				liveExperience
				adStatus
			}
			total
	}
}`

export const changeYachtServiceStatus = gql`
  mutation boatServiceChangeStatus($value: Boolean, $column: String!, $id: String!, $note: String, $adType: String!, $action: String!) {
    boatServiceChangeStatus(value: $value, column: $column, id: $id, note: $note, adType: $adType, action: $action) {
      id
    }
  }
`

export const marinaChangeMarketStatus = gql`
  mutation marinaChangeStatus($value: Boolean, $column: String!, $id: String!) {
    marinaChangeStatus(value: $value, column: $column, id: $id) {
      user
      message
      statusCode
      id
      ids
    }
  }
`

export const boatRentChangeMarketStatus = gql`
  mutation boatRentChangeMarketStatus($input: MarketStatusInput!) {
    boatRentChangeMarketStatus(input: $input) {
      user
      message
      statusCode
      id
      ids
    }
  }
`

export const yachtChangeMarketStatus = gql`
  mutation boatServiceChangeStatus($value: Boolean, $column: String!, $id: String) {
    boatServiceChangeStatus(value: $value, column: $column, id: $id) {
      id
    }
  }
`

export const getAllPaymentMethodTypes = gql`
  query getAllPaymentMethodTypes($page: Int, $limit: Int, $searchTerm: String) {
    getAllPaymentMethodTypes(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        id
        icon {
          id
          url
          name
        }
        name
        createdAt
        updatedAt
      }
      total
    }
  }
`

export const createPaymentMethodType = gql`
  mutation createPaymentMethodType($input: PaymentMethodInput!) {
    createPaymentMethodType(input: $input) {
      id
      icon {
        id
        url
      }
      name
      createdAt
      updatedAt
    }
  }
`

export const updatePaymentMethodType = gql`
  mutation updatePaymentMethodType($input: PaymentMethodInput!) {
    updatePaymentMethodType(input: $input) {
      id
      icon {
        id
        url
      }
      name
      createdAt
      updatedAt
    }
  }
`

export const removePaymentMethodType = gql`
  mutation removePaymentMethodType($id: String!) {
    removePaymentMethodType(id: $id) {
      id
      icon {
        id
        url
      }
      name
      createdAt
      updatedAt
    }
  }
`

export const getRentPaymentsReceiversQuery = gql`
  query getRentPaymentsReceivers {
    getRentPaymentsReceivers {
      id
      companyName
      name
      country
      city
      stripeAccountId
      baseAmount
      serviceFees
      stripeFees
      totalAmount
      commission
      amountToRelease
    }
  }
`

export const getRentPaymentsReceiverBoatsQuery = gql`
  query getRentPaymentsReceiverBoats($receiverId: String!) {
    getRentPaymentsReceiverBoats(receiverId: $receiverId) {
      id
      adId
      trip
      tripType
      model
      baseAmount
      serviceFees
      stripeFees
      totalAmount
      commission
      amountToRelease
    }
  }
`

export const getRentPaymentsBoatSlotsQuery = gql`
  query getRentPaymentsBoatSlots($boatId: String!) {
    getRentPaymentsBoatSlots(boatId: $boatId) {
      id
      startDate
      startTime
      endDate
      endTime
      price
      totalBookings
      status
      baseAmount
      serviceFees
      stripeFees
      totalAmount
      commission
      amountToRelease
    }
  }
`

export const getRentPaymentsBoatSlotBookingsQuery = gql`
  query getRentPaymentsBoatSlotBookings($slotId: String!) {
    getRentPaymentsBoatSlotBookings(slotId: $slotId) {
      id
      startDate
      startTime
      endDate
      endTime
      ticketId
      paymentIntentId
      bookedBy
      units
      status
      isQRScanned
      isCancelled
      baseAmount
      serviceFee
      stripeFee
      totalAmount
      commission
      amountToRelease
      isReleased
      releaseId
      isRefunded
      refundId
    }
  }
`

export const getRentPaymentsPrivateBoatSlotsQuery = gql`
  query getRentPaymentsPrivateBoatSlots($boatId: String!) {
    getRentPaymentsPrivateBoatSlots(boatId: $boatId) {
      id
      startDate
      startTime
      endDate
      endTime
      ticketId
      paymentIntentId
      bookedBy
      status
      isQRScanned
      isCancelled
      baseAmount
      serviceFee
      stripeFee
      totalAmount
      commission
      amountToRelease
      isReleased
      releaseId
      isRefunded
      refundId
    }
  }
`

export const rentPaymentsReleaseIntentQuery = gql`
  mutation rentPaymentsReleaseIntent($paymentIntentId: String!) {
    rentPaymentsReleaseIntent(paymentIntentId: $paymentIntentId)
  }
`

export const rentPaymentsRefundIntentQuery = gql`
  mutation rentPaymentsRefundIntent($paymentIntentId: String!) {
    rentPaymentsRefundIntent(paymentIntentId: $paymentIntentId)
  }
`

export const searchBoat = gql`
query searchBoat($query: String!, $page: Int!, $limit: Int) {
	searchBoat(query: $query, page: $page, limit: $limit) {
		items {
			id
			adId
			boatName
			createdAt
			address ${AddressType}
			status
			price
			adStatus
			featureStatus
			bestDealStatus
			mustBuyStatus
			isDeleted
			yearBuilt
			engineHp
			seller {
				id
				firstName
				lastName
				role {
					aliasName
				}
			}
			boatType {
				id
				name
				icon ${imageSchema}
			}
			manufacturedBy {
				typeId {
				  type
				  id
				}
				alias
				id
				lookUpName

			}
		}
		total
	}
}
`

export const searchBoatService = gql`
query searchBoatService($query: String!, $page: Int, $limit: Int) {
	searchBoatService(query: $query, page: $page, limit: $limit) {
		items {
			id
			adId
			address ${AddressType}
			user {
				id
				companyName
				firstName
				lastName
			}
			serviceProvide {
				id
				name
			}
			createdAt
			nearest
			popular
			status
			adStatus
		}
		total
	}
}
`

export const getReportedAdsQuery = gql`
  query getReportedAds($limit: Int!, $page: Int!) {
    getReportedAds(limit: $limit, page: $page) {
      total
      items {
        moduleId
        market
        adId
        adOwner
        adCountry
        totalReports
      }
    }
  }
`

export const getAdReportsQuery = gql`
  query getAdReports($moduleId: String!, $limit: Int!, $page: Int!) {
    getAdReports(moduleId: $moduleId, limit: $limit, page: $page) {
      total
      items {
        user {
          role {
            _id
            role
            aliasName
          }
          firstName
          lastName
          address {
            type
            country
          }
          email
          mobileNumber
        }
        reportType
        message
        createdAt
      }
    }
  }
`

export const getReportedReviewsQuery = gql`
  query getReportedReviews($limit: Int!, $page: Int!) {
    getReportedReviews(limit: $limit, page: $page) {
      total
      items {
        moduleId
        market
        adOwner
        adCountry
        reviewOwner
        review
        totalReports
      }
    }
  }
`

export const getReviewReportsQuery = gql`
  query getReviewReports($moduleId: String!, $limit: Int!, $page: Int!) {
    getReviewReports(moduleId: $moduleId, limit: $limit, page: $page) {
      total
      items {
        user {
          role {
            _id
            role
            aliasName
          }
          firstName
          lastName
          address {
            type
            country
          }
          email
          mobileNumber
        }
        reportType
        message
        createdAt
      }
    }
  }
`

export const deleteReviewQuery = gql`
  mutation deleteReview($id: String!) {
    deleteReview(id: $id)
  }
`
