/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { clear_getReportedAds, getReportedAds } from '../../store/pendingAds/Action'
import ExpandTable from '../../component/ExpandTable'
import { Button, Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData, Sort } from '../../helpers/helper'

const MARKETS = {
  BOATRENT: 'Rent',
  MARINA: 'Marina',
  YACHT: 'Service',
}

const ManageAdComplaints = ({ history, getReportedAds, reportedAds, clear_getReportedAds }: any) => {
  const [tableData, setTableData] = useState([] as any[])
  const [totalRows, setTotalRows] = useState(0)
  const [searchedValue, setSearchedValue] = useState('')

  useEffect(() => {
    if (reportedAds.success) {
      setTableData(reportedAds.data.items)
      setTotalRows(reportedAds.data.total)
    }

    if (reportedAds.success || reportedAds.failure) clear_getReportedAds()
  }, [reportedAds])

  const viewReports = (moduleId: string) => {
    history.push({ pathname: '/manage-ad-complaints/' + moduleId })
  }

  const tableCols = () => [
    {
      title: 'Market',
      dataIndex: 'market',
      key: 'market',
      render: (v: keyof typeof MARKETS) => (v ? MARKETS[v] || 'Boat' : <>&#8212;</>),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.market, b.market),
    },
    {
      title: 'Ad Id',
      dataIndex: 'adId',
      key: 'adId',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.adId, b.adId),
    },
    {
      title: 'Ad Owner',
      dataIndex: 'adOwner',
      key: 'adOwner',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.adOwner, b.adOwner),
    },
    {
      title: 'Ad Country',
      dataIndex: 'adCountry',
      key: 'adCountry',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.adCountry, b.adCountry),
    },
    {
      title: 'Total Reports',
      dataIndex: 'totalReports',
      key: 'totalReports',
      render: (v: any) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.totalReports, b.totalReports),
    },
    {
      title: 'Module Id',
      dataIndex: 'moduleId',
      key: 'moduleId',
      render: (id: any, row: any) => (
        <Button className="btn-view" onClick={() => viewReports(id)}>
          View Reports
        </Button>
      ),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.moduleId, b.moduleId),
    },
  ]

  return (
    <Card title="Manage Ad Complaints" extra={<TitleSearch onSearch={setSearchedValue} />}>
      {(() => {
        const data = getSearchedData(tableData, searchedValue)

        return (
          <ExpandTable
            columns={tableCols()}
            tableData={data}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={totalRows}
            tableLoading={reportedAds.loading}
            pageAction={getReportedAds}
          />
        )
      })()}
    </Card>
  )
}

const mapStateToProps = (state: any) => {
  return {
    reportedAds: state.pendingAdsState.reportedAds,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getReportedAds: (payload: any) => dispatch(getReportedAds(payload)),
  clear_getReportedAds: () => dispatch(clear_getReportedAds()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAdComplaints)
