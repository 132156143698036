import { put, takeLatest, all } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { client } from '../../../'
import {
  getAllUserGuidList,
  createUserGuidDetail,
  updateUserGuidDetail,
  deleteUserGuidDetail,
  changeUserGuidStatus,
} from './Schema'
import {
  ActionType,
  CHANGE_USER_GUIDE_STATUS_SUCCESS,
  CHANGE_USER_GUIDE_STATUS_FAILURE,
  CHANGE_USER_GUIDE_STATUS,
  GET_ALL_USER_GUIDE_LIST_SUCCESS,
  GET_ALL_USER_GUIDE_LIST_FAILURE,
  CREATE_USER_GUIDE_DETAIL_SUCCESS,
  CREATE_USER_GUIDE_DETAIL_FAILURE,
  UPDATE_USER_GUIDE_DETAIL_SUCCESS,
  UPDATE_USER_GUIDE_DETAIL_FAILURE,
  DELETE_USER_GUIDE_DETAIL_SUCCESS,
  DELETE_USER_GUIDE_DETAIL_FAILURE,
  GET_ALL_USER_GUIDE_LIST,
  CREATE_USER_GUIDE_DETAIL,
  UPDATE_USER_GUIDE_DETAIL,
  DELETE_USER_GUIDE_DETAIL,
} from './Type'

function getAllUserGuideListApi({ payload }: any) {
  return client
    .query({
      query: getAllUserGuidList,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

function createUserGuideDetailApi(input: any) {
  return client
    .mutate({
      mutation: createUserGuidDetail,
      variables: {
        input,
      },
    })
}

function updateUserGuideDetailApi(data: any) {
  const input = {
    id: data.id,
    title: data.title,
    description: data.description,
  }

  return client
    .mutate({
      mutation: updateUserGuidDetail,
      variables: { input: input },
    })
}

function deleteUserGuideDetailApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteUserGuidDetail,
      variables: { id },
    })
}

function changeUserGuideStatusApi({ payload }: any) {
  return client
    .mutate({
      mutation: changeUserGuidStatus,
      variables: {
        id: payload.id,
        column: payload.column,
        value: payload.value,
      },
    })
}

function* changeUserGuideDetailStatusData({ payload }: AnyAction) {
  try {
    const data = yield changeUserGuideStatusApi({ payload })
    yield put({ type: CHANGE_USER_GUIDE_STATUS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: CHANGE_USER_GUIDE_STATUS_FAILURE, error })
  }
}

function* getAllUserGuideListData({ payload }: AnyAction) {
  try {
    const res = yield getAllUserGuideListApi({ payload })
    yield put({
      type: GET_ALL_USER_GUIDE_LIST_SUCCESS,
      payload: res.data.getAllUserGuidList,
    })
  } catch (error) {
    yield put({ type: GET_ALL_USER_GUIDE_LIST_FAILURE, error })
  }
}

function* createUserGuideDetailData(action: ActionType) {
  try {
    const res = yield createUserGuideDetailApi(action.payload)
    yield put({ type: CREATE_USER_GUIDE_DETAIL_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_USER_GUIDE_DETAIL_FAILURE, error })
  }
}

export function* updateUserGuideDetailData(action: ActionType) {
  try {
    const data = yield updateUserGuideDetailApi(action.payload)
    yield put({ type: UPDATE_USER_GUIDE_DETAIL_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_USER_GUIDE_DETAIL_FAILURE, error })
  }
}

function* deleteUserGuideDetailData(action: ActionType) {
  try {
    const res = yield deleteUserGuideDetailApi(action)
    yield put({ type: DELETE_USER_GUIDE_DETAIL_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_USER_GUIDE_DETAIL_FAILURE, error })
  }
}

function* changeUserGuideDetailStatusSaga() {
  yield takeLatest(CHANGE_USER_GUIDE_STATUS, changeUserGuideDetailStatusData)
}

function* getAllUserGuideListSaga() {
  yield takeLatest(GET_ALL_USER_GUIDE_LIST, getAllUserGuideListData)
}

function* createUserGuideDetailSaga() {
  yield takeLatest(CREATE_USER_GUIDE_DETAIL, createUserGuideDetailData)
}

function* updateUserGuideDetailSaga() {
  yield takeLatest(UPDATE_USER_GUIDE_DETAIL, updateUserGuideDetailData)
}

function* deleteUserGuideDetailSaga() {
  yield takeLatest(DELETE_USER_GUIDE_DETAIL, deleteUserGuideDetailData)
}

export default function* userGuideSaga() {
  yield all([
    deleteUserGuideDetailSaga(),
    updateUserGuideDetailSaga(),
    createUserGuideDetailSaga(),
    getAllUserGuideListSaga(),
    changeUserGuideDetailStatusSaga(),
  ])
}
