export interface agreementsCountState {
  agreementsCount: any
  agreementCountSuccess: boolean
  agreementCountFailure: boolean
}

export interface agreementsCountData {
  roleCounts: [string]
  total: number
}

export const GET_AGREEMENTS_COUNT = 'GET_AGREEMENTS_COUNT'
export const GET_AGREEMENTS_COUNT_SUCCESS = 'GET_AGREEMENTS_COUNT_SUCCESS'
export const GET_AGREEMENTS_COUNT_FAILURE = 'GET_AGREEMENTS_COUNT_FAILURE'

interface getAgreementsCount {
  type: typeof GET_AGREEMENTS_COUNT
  payload: any
}

interface getAgreementsCountSuccess {
  type: typeof GET_AGREEMENTS_COUNT_SUCCESS
  payload: any
}
interface getAgreementsCountFailure {
  type: typeof GET_AGREEMENTS_COUNT_FAILURE
  payload: any
}

export type ActionType = getAgreementsCount | getAgreementsCountSuccess | getAgreementsCountFailure
