export interface Reviews {
  id: string
  rating?: number
  reviews?: string
}

export interface UserModule {
  id?: string
  firstName: string
  lastName: string
  address?: Address[]
}

interface Address {
  country?: string
}

interface GetAllRes {
  items: Reviews[]
  total: number
}

export interface ReviewState {
  reviews: Reviews[]
  isLoading: boolean
}

export interface GetInput {
  limit?: number
  page?: number
  userType?: string
}

export interface ChangeReviewStatusInput {
  id?: string
  column?: string
  value?: boolean
}

export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS'
export const GET_ALL_REVIEWS_SUCCESS = 'GET_ALL_REVIEWS_SUCCESS'
export const GET_ALL_REVIEWS_FAILURE = 'GET_ALL_REVIEWS_FAILURE'

export const CHANGE_REVIEW_STATUS = 'CHANGE_REVIEW_STATUS'
export const CHANGE_REVIEW_STATUS_SUCCESS = 'CHANGE_REVIEW_STATUS_SUCCESS'
export const CHANGE_REVIEW_STATUS_FAILURE = 'CHANGE_REVIEW_STATUS_FAILURE'

export const REJECT_REVIEW = 'REJECT_REVIEW'
export const REJECT_REVIEW_SUCCESS = 'REJECT_REVIEW_SUCCESS'
export const REJECT_REVIEW_FAILURE = 'REJECT_REVIEW_FAILURE'

interface getReviews {
  type: typeof GET_ALL_REVIEWS
  payload: GetInput
}

interface getReviewsSuccess {
  type: typeof GET_ALL_REVIEWS_SUCCESS
  payload: GetAllRes
}

interface getReviewsFailure {
  type: typeof GET_ALL_REVIEWS_FAILURE
  payload: any
}

interface changeReviewStatus {
  type: typeof CHANGE_REVIEW_STATUS
  payload: ChangeReviewStatusInput
}

interface changeReviewStatusSuccess {
  type: typeof CHANGE_REVIEW_STATUS_SUCCESS
  payload: Reviews
}

interface changeReviewStatusFailure {
  type: typeof CHANGE_REVIEW_STATUS_FAILURE
  payload: any
}

interface rejectReview {
  type: typeof REJECT_REVIEW
  payload: ChangeReviewStatusInput
}

interface rejectReviewSuccess {
  type: typeof REJECT_REVIEW_SUCCESS
  payload: Reviews
}

interface rejectReviewFailure {
  type: typeof REJECT_REVIEW_FAILURE
  payload: any
}

export type ActionType =
  | getReviews
  | getReviewsSuccess
  | getReviewsFailure
  | changeReviewStatus
  | changeReviewStatusSuccess
  | changeReviewStatusFailure
  | rejectReview
  | rejectReviewSuccess
  | rejectReviewFailure
