import {
  faqState,
  ActionType,
  CREATE_FAQ_DETAIL,
  CREATE_FAQ_DETAIL_SUCCESS,
  CREATE_FAQ_DETAIL_FAILURE,
  GET_ALL_FAQ_LIST,
  GET_ALL_FAQ_LIST_SUCCESS,
  GET_ALL_FAQ_LIST_FAILURE,
  UPDATE_FAQ_DETAIL,
  UPDATE_FAQ_DETAIL_SUCCESS,
  UPDATE_FAQ_DETAIL_FAILURE,
  DELETE_FAQ_DETAIL_SUCCESS,
  DELETE_FAQ_DETAIL_FAILURE,
  CLEAR_FAQ_FLAG,
  CHANGE_FAQ_DETAIL_STATUS,
  CHANGE_FAQ_DETAIL_STATUS_SUCCESS,
  CHANGE_FAQ_DETAIL_STATUS_FAILURE,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: faqState = {
  totalFAQRows: 0,
  faqs: [],
  faqSuccess: false,
  faqError: false,
  deleteSuccess: false,
  deleteError: false,
}

export const faqReducer = (state = initialState, action: ActionType): faqState => {
  switch (action.type) {
    case GET_ALL_FAQ_LIST:
      return {
        ...state,
        faqSuccess: false,
        faqError: false,
      }
    case GET_ALL_FAQ_LIST_SUCCESS:
      return {
        ...state,
        faqSuccess: true,
        faqError: false,
        faqs: action.payload.items,
        totalFAQRows: action.payload.total,
      }
    case GET_ALL_FAQ_LIST_FAILURE:
      return {
        ...state,
        faqSuccess: false,
        faqError: true,
      }

    case CREATE_FAQ_DETAIL:
      return {
        ...state,
        faqSuccess: false,
        faqError: false,
      }
    case CREATE_FAQ_DETAIL_SUCCESS:
      return {
        ...state,
        faqSuccess: true,
        faqError: false,
      }
    case CREATE_FAQ_DETAIL_FAILURE:
      return {
        ...state,
        faqSuccess: false,
        faqError: true,
      }

    case UPDATE_FAQ_DETAIL:
      return {
        ...state,
        faqSuccess: false,
        faqError: false,
      }
    case UPDATE_FAQ_DETAIL_SUCCESS:
      return {
        ...state,
        faqSuccess: true,
        faqError: false,
      }
    case UPDATE_FAQ_DETAIL_FAILURE:
      return {
        ...state,
        faqSuccess: false,
        faqError: true,
      }

    case DELETE_FAQ_DETAIL_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }
    case DELETE_FAQ_DETAIL_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case CLEAR_FAQ_FLAG:
      return {
        ...state,
        faqSuccess: false,
        faqError: false,
        deleteSuccess: false,
        deleteError: false,
      }

    case CHANGE_FAQ_DETAIL_STATUS:
      return {
        ...state,
        faqSuccess: false,
        faqError: false,
      }
    case CHANGE_FAQ_DETAIL_STATUS_SUCCESS:
      NotificationWithIcon('success', 'Status Changed Successfully')
      return {
        ...state,
        faqSuccess: true,
        faqError: false,
      }
    case CHANGE_FAQ_DETAIL_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        faqSuccess: false,
        faqError: true,
      }

    default:
      return state
  }
}
