import { put, takeLatest, all } from 'redux-saga/effects'
import {
  ActionType,
  GET_ALL_YATCH_TYPES,
  GET_ALL_YATCH_TYPES_SUCCESS,
  GET_ALL_YATCH_TYPES_FAILURE,
  YatchTypesData,
  CREATE_YATCH_TYPES_SUCCESS,
  CREATE_YATCH_TYPES_FAILURE,
  CREATE_YATCH_TYPES,
  UPDATE_YATCH_TYPES,
  UPDATE_YATCH_TYPES_SUCCESS,
  UPDATE_YATCH_TYPES_FAILURE,
  DELETE_YATCH_TYPES,
  DELETE_YATCH_TYPES_FAILURE,
  DELETE_YATCH_TYPES_SUCCESS,
} from './Types'
import { client } from '../../..'
import { getAllBoatServiceTypes, createBoatServiceType, updateBoatServiceTypes, deleteBoatServiceType } from './Schema'

// create boat type

export function createYatchTypesApi(input: YatchTypesData) {
  return client
    .mutate({
      mutation: createBoatServiceType,
      variables: { input },
    })
}

export function* createYatchTypesData(action: ActionType) {
  try {
    const data = yield createYatchTypesApi(action.payload)
    yield put({ type: CREATE_YATCH_TYPES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: CREATE_YATCH_TYPES_FAILURE, error })
  }
}

export function* createYatchTypesSage() {
  yield takeLatest(CREATE_YATCH_TYPES, createYatchTypesData)
}

// get yatch type

export function getAllYatchTypesApi(payload: any) {
  return client
    .query({
      query: getAllBoatServiceTypes,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

export function* getAllYatchTypesData(action: ActionType) {
  try {
    const data = yield getAllYatchTypesApi(action.payload)
    yield put({ type: GET_ALL_YATCH_TYPES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ALL_YATCH_TYPES_FAILURE, error })
  }
}

export function* getAllYatchTypesSaga() {
  yield takeLatest(GET_ALL_YATCH_TYPES, getAllYatchTypesData)
}

// delete boat type

function deleteYatchTypesApi(data: string) {
  return client
    .mutate({
      mutation: deleteBoatServiceType,
      variables: { id: data },
    })
}

function* deleteYatchTypesData(action: ActionType) {
  try {
    const res = yield deleteYatchTypesApi(action.payload)
    yield put({ type: DELETE_YATCH_TYPES_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: DELETE_YATCH_TYPES_FAILURE, error })
  }
}

function* deleteYatchTypesSaga() {
  yield takeLatest(DELETE_YATCH_TYPES, deleteYatchTypesData)
}

// // edit boat type

// function editYatchTypesApi(data: string) {
//   return client
//     .query({
//       query: editYatchTypes,
//       variables: { id: data }
//     })
//     .then(res => res)
//     .catch(error => {
//       throw error;
//     });
// }

// function* editYatchTypesData(action: ActionType) {
//   try {
//     const res = yield editYatchTypesApi(action.payload);
//     yield put({ type: EDIT_BOAT_TYPES_SUCCESS, payload: res });
//   } catch (error) {
//     yield put({ type: EDIT_BOAT_TYPES_FAILURE, error });
//   }
// }

// function* editYatchTypesSaga() {
//   yield takeLatest(EDIT_BOAT_TYPES, editYatchTypesData);
// }

// // update boat type

export function updateYatchTypesApi(data: any) {
  return client
    .mutate({
      mutation: updateBoatServiceTypes,
      variables: { input: data },
    })
}

export function* updateYatchTypesData(action: ActionType) {
  try {
    const data = yield updateYatchTypesApi(action.payload)
    yield put({ type: UPDATE_YATCH_TYPES_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_YATCH_TYPES_FAILURE, error })
  }
}

export function* updateYatchTypesSaga() {
  yield takeLatest(UPDATE_YATCH_TYPES, updateYatchTypesData)
}

export default function* yatchTypeSaga() {
  yield all([createYatchTypesSage(), getAllYatchTypesSaga(), updateYatchTypesSaga(), deleteYatchTypesSaga()])
}
