import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button, Select } from 'antd'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import History from '../../../History'

import { createJobs, updateJobs, clearJobsFlag } from '../../../store/staticContent/manageJobs/Action'
import { jobData } from '../../../store/staticContent/manageJobs/Type'
import { getCountries } from '../../../store/country/manageCountry/Action'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import WSSelect from '../../../component/WSSelect'
import { PlusCircleOutlined } from '@ant-design/icons'
import UploadImage from '../../../component/UploadImage'
import { handleSingleFileUpload, handleSingleFileDelete } from '../../../helpers/S3FileUpload'
import { splitUrlFromUnderscore, getAddMedia, removePTag, generateReferenceNo, trimmedValue } from '../../../helpers/helper'
import NotificationWithIcon from '../../../component/notification/Notification'
import { MEDIA_NAME, MEDIA_TYPE, imageExtensions } from '../../../enums/enums'
import Loader from '../../../component/Loading'

const { Text } = Typography
const { Option } = Select

interface Props {
  createJobs: typeof createJobs
  location: any
  getCountries: typeof getCountries
  countries: Array<any>
  updateJobs: typeof updateJobs
  history: typeof History
  jobSuccess: boolean
  clearJobsFlag: typeof clearJobsFlag
}

interface State {
  editJob: any
  isEdit: boolean
  manageJob: {
    country: string
    city: string
    title: string
    description: string
    reference: string
    salaryPackage: string
    candidateProfile: string
    applicationInformation: string
    documentUrl: string
    addMedia: any[]
    status: boolean
    experience: string
  }
  loading: boolean
}

class CreateJobs extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      editJob: props.location.state ? props.location.state.input.record : null,
      isEdit: props.location.state ? props.location.state.input.isEdit : null,
      loading: false,
      manageJob: {
        title: '',
        country: '',
        city: '',
        description: '',
        reference: generateReferenceNo(),
        salaryPackage: '',
        candidateProfile: '',
        applicationInformation: '',
        documentUrl: '',
        addMedia: [],
        status: true,
        experience: '',
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { jobSuccess, clearJobsFlag, history } = props

    if (jobSuccess) {
      clearJobsFlag()
      history.push('/manage-jobs')
    }
    return null
  }

  componentDidMount() {
    const { getCountries, countries, clearJobsFlag } = this.props
    clearJobsFlag()
    countries?.length < 10 && getCountries({ page: 1, limit: 300 })
  }

  formSubmitHandler = (values: jobData) => {
    const { isEdit } = this.state
    const { createJobs, updateJobs } = this.props
    const { __typename, documentUrl, addMedia, cvCount, ...noTypeName } = values
    const input = { addMedia: getAddMedia(values.addMedia), ...noTypeName }
    
    if(input.country === ''){
      delete input['country']
      delete input['city']
    }

    if (isEdit) {
      updateJobs(input)
    } else {
      createJobs(input)
    }
  }

  selectSiteIcon = async (file: any, setFieldValue: any, values: any, acceptType: any) => {
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(
        file,
        'documentUrl',
        setFieldValue,
        values.addMedia,
        MEDIA_NAME.JOBDOCUMENTURL,
        MEDIA_TYPE.IMAGE
      )
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleRemove = (file: any, setFieldValue: any, values: any) => {
    const { addMedia } = values
    if (addMedia && addMedia.length) {
      handleSingleFileDelete(file, 'documentUrl', setFieldValue, addMedia, values.thumbnail, 'filter')
    }
  }

  prepareInitValue = () => {
    const { editJob, manageJob } = this.state

    if (editJob) {
      if (!editJob.hasOwnProperty('experience')) {
        return {
          ...editJob,
          experience: '',
        }
      } else {
        return editJob
      }
    } else {
      return manageJob
    }
  }

  render() {
    const { manageJob, editJob, loading } = this.state
    const { history, countries } = this.props
    const editJobData = editJob && {
      ...editJob,
      experience: editJob?.experience || '',
    }

    const initValue = editJobData ? { ...editJobData, country: editJobData?.country?.id } : manageJob

    return countries?.length ? (
      <Formik
        initialValues={initValue}
        onSubmit={values => {
          values.candidateProfile = removePTag(values.candidateProfile)
          values.applicationInformation = removePTag(values.applicationInformation)
          values.description = removePTag(values.description)
          this.formSubmitHandler(values)
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title field is required.'),
          country: Yup.string().nullable(),
          description: Yup.string().required('Job description field is required.'),
          reference: Yup.string().required('Job reference field is required.'),
          salaryPackage: Yup.string().required('Job Salary Package field is required.'),
          candidateProfile: Yup.string().required('Candidate Profile field is required.'),
          applicationInformation: Yup.string().required('Application Information field is required.'),
          experience: Yup.string().required('Minimum Experience field is required.'),
          city: Yup.string().nullable(),
          // documentUrl: Yup.string().required("Select document."),
        })}
        render={({ setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  Create Job
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  {!loading ? (
                    <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                      Save
                    </button>
                  ) : (
                    <Button loading={loading} className="ml-15 ant-btn ant-btn-primary">
                      Save
                    </Button>
                  )}
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="country">
                    Country<span className="required">*</span>
                  </span>

                  <WSSelect
                    defaultValue={values.country || ''}
                    onChangeText={(value: any) => setFieldValue('country', value)}
                    arrayList={countries?.length ? countries : []}
                    listOptions="name"
                    passed="id"
                    addAny={true}
                  />

                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name="country" component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="title">
                    Job Title<span className="required">*</span>
                  </span>
                  <Input
                    name="title"
                    placeholder="Job Title"
                    value={values.title}
                    onChange={e => setFieldValue('title', e.target.value)}
                    onBlur={e => setFieldValue('title', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`title`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="reference">
                    Job reference<span className="required">*</span>
                  </span>
                  <Input
                    name="reference"
                    placeholder="Job reference"
                    value={values.reference}
                    disabled
                    // onChange={e => setFieldValue("reference", e.target.value)}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`reference`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="reference">
                    Minimum Experience<span className="required">*</span>
                  </span>
                  <Input
                    name="experience"
                    placeholder="Minimum Experience"
                    type="text"
                    value={values.experience}
                    onChange={e => setFieldValue('experience', e.target.value)}
                    onBlur={e => setFieldValue('experience', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`experience`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="salaryPackage">
                    Job Salary Package<span className="required">*</span>
                  </span>
                  <Input
                    name="salaryPackage"
                    placeholder="Job Salary Package"
                    value={values.salaryPackage}
                    onChange={e => setFieldValue('salaryPackage', e.target.value)}
                    onBlur={e => setFieldValue('salaryPackage', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`salaryPackage`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="candidateProfile">
                    Desired Candidate Profile<span className="required">*</span>
                  </span>
                  <WSField
                    type="textArea"
                    defaultValue={values.candidateProfile}
                    onChangeText={(evt: any) => {
                      setFieldValue('candidateProfile', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('candidateProfile', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage
                      className="invalid-feedback ant-typography-danger"
                      name={`candidateProfile`}
                      component="span"
                    />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="applicationInformation">
                    Application Information<span className="required">*</span>
                  </span>
                  <WSField
                    type="textArea"
                    defaultValue={values.applicationInformation}
                    onChangeText={(evt: any) => {
                      setFieldValue('applicationInformation', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('applicationInformation', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage
                      className="invalid-feedback ant-typography-danger"
                      name={`applicationInformation`}
                      component="span"
                    />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box">
                  <span title="city">
                    Town/City
                  </span>
                  <Input
                    name="city"
                    placeholder="Job city"
                    value={values.city}
                    onChange={e => setFieldValue('city', e.target.value)}
                    onBlur={e => setFieldValue('city', trimmedValue(e.target.value))}
                    disabled={(values.country === undefined) || (values.country === '')}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`city`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box">
                  <span title="documentUrl">Upload Job Document (Only Pdf/Doc Extension Are Allowed)</span>
                  <br></br>
                  {values && values?.documentUrl?.id ? (
                    <UploadImage
                      defaultFileList={[
                        {
                          uid: values.documentUrl.id,
                          name: 'Uploaded Document',
                          status: 'done',
                          url: values.documentUrl.url,
                        },
                      ]}
                      openFileDialogOnClick={!values.documentUrl}
                      withIcon
                      accept=".doc, .docx, .pdf"
                      buttonText="Upload Document"
                      onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                      selectSiteIcon={(file: any) => this.selectSiteIcon(file, setFieldValue, values, 'application')}
                    />
                  ) : (
                    <UploadImage
                      accept=".doc, .docx, .pdf"
                      withIcon
                      openFileDialogOnClick={!values.documentUrl}
                      buttonText="Upload Document"
                      onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                      selectSiteIcon={(file: any) => this.selectSiteIcon(file, setFieldValue, values, 'application')}
                    />
                  )}
                  {/* <input type="file"
                    onChange={(evt: any) => {

                      setFieldValue('documentUrl', evt.target.value)
                    }}
                    id="documentUrl" name="documentUrl"
                    accept=".pdf, .doc, .docx" /> */}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="description">
                    Job Description<span className="required">*</span>
                  </span>
                  <WSField
                    type="textArea"
                    defaultValue={values.description}
                    onChangeText={(evt: any) => {
                      setFieldValue('description', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('description', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`description`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    ) : (
      <Loader />
    )
  }
}

const mapStateToProps = (state: any) => ({
  jobSuccess: state.jobReducer && state.jobReducer.jobSuccess,
  countries: state.countryReducer.countries,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createJobs: (data: jobData) => dispatch(createJobs(data)),
  updateJobs: (data: jobData) => dispatch(updateJobs(data)),
  getCountries: (data: any) => dispatch(getCountries(data)),
  clearJobsFlag: () => dispatch(clearJobsFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobs)
