export interface manageSalesEngineCountState {
    manageSalesEngineCount: []
    manageSalesEngineCountSuccess: boolean
    manageSalesEngineCountFailure:boolean
}

export const GET_MANAGE_SALES_ENGINE_COUNT = 'GET_MANAGE_SALES_ENGINE_COUNT'
export const GET_MANAGE_SALES_ENGINE_COUNT_SUCCESS = 'GET_MANAGE_SALES_ENGINE_COUNT_SUCCESS'
export const GET_MANAGE_SALES_ENGINE_COUNT_FAILURE = 'GET_MANAGE_SALES_ENGINE_COUNT_FAILURE' 

interface getManageSalesEngineCount {
    type: typeof GET_MANAGE_SALES_ENGINE_COUNT
    payload: any
}

interface getManageSalesEngineCountSuccess {
    type: typeof GET_MANAGE_SALES_ENGINE_COUNT_SUCCESS
    payload: any
}

interface getManageSalesEngineCountFailure {
    type: typeof GET_MANAGE_SALES_ENGINE_COUNT_FAILURE
    payload: any
}

export type ActionType = getManageSalesEngineCount | getManageSalesEngineCountSuccess | getManageSalesEngineCountFailure