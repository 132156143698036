import React, { Component, GetDerivedStateFromProps } from 'react'
import ExpandTable from '../../../component/ExpandTable'
import { Dispatch } from 'redux'

import { Button, Card } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  getAllHomeVideos,
  clearManageVideoFlag,
  deleteHomeVideo,
  getAllVideosList,
} from '../../../store/staticContent/manageVideos/Action'
import history from '../../../History'
import './manageHomeVideo.scss'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { filterVideoCategory, getAllBoatPagination, paginationPage, defaultImage } from '../../../enums/enums'
import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData, formateDate } from '../../../helpers/helper'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import FilterButton from '../../filters/FilterButton'

export interface Props {
  getAllHomeVideos: typeof getAllHomeVideos
  getAllVideosList: typeof getAllVideosList
  videos: []
  history: typeof history
  clearManageVideoFlag: typeof clearManageVideoFlag
  deleteHomeVideoData: typeof deleteHomeVideo
  deleteSuccess: boolean
  totalVideosRow: number
  loadVideo: boolean
}

export interface State {
  videos: []
  searchedValue: string
  type: string
}
class ManageHomeVideo extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      videos: [],
      searchedValue: '',
      type: '',
    }
  }

  componentDidMount() {
    const { clearManageVideoFlag, getAllHomeVideos } = this.props
    // const input = {
    //   page: getAllBoatPagination.page,
    //   limit: getAllBoatPagination.limit,
    // }
    clearManageVideoFlag()
    // getAllHomeVideos(input)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.state.type !== prevState.type) {
      this.props.getAllHomeVideos({
        searchTerm: this.state.searchedValue,
        type: this.state.type,
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      })
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    props.clearManageVideoFlag()
    const { deleteSuccess } = props
    if (deleteSuccess) {
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      props.getAllHomeVideos(input)
      props.clearManageVideoFlag()
    }
    return null
  }

  editVideo = (record: any) => {
    const { history } = this.props
    history.push('/create-manage-video', { video: record })
  }

  columns = [
    {
      title: 'Video Name',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: any, b: any) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Category',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Thumbnail Image',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (text: any) => (
        <img
          src={(text && text.length && text[0].url) || defaultImage.image}
          alt="Video Thumbnail"
          className="video-thumbnail"
        ></img>
      ),
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string, record: any, index: number) => <>{formateDate(record && record.updatedAt)}</>,
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      render: (text: string, record: any, index: number) => (
        <>
          <Button
            onClick={() => {
              this.editVideo(record)
            }}
            className="btn-edit mr-2"
          >
            Edit
          </Button>
          <Button
            className="btn-delete"
            onClick={() => {
              record && record.id && confirmSubmitHandler(this.props.deleteHomeVideoData, record.id)
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ]

  onSearch = (value: any) => {
    const { getAllHomeVideos } = this.props

    if (value) {
      this.setState({ searchedValue: value })
      getAllHomeVideos({ ...getAllBoatPagination, searchTerm: value })
    } else {
      this.setState({ searchedValue: '' })
      getAllHomeVideos({ ...getAllBoatPagination })
    }
  }

  render() {
    const { videos, totalVideosRow, getAllHomeVideos, loadVideo } = this.props
    const { searchedValue, type } = this.state

    return (
      <>
        <Card
          title="Manage Videos"
          extra={
            <div className="d-flex flex-wrap">
              <FilterButton mb0 hideDivider filterArray={filterVideoCategory} triggerAction={(values: any) => {
                if (values.type) this.setState({ type: values.type })
                else this.setState({ type: '' })
              }} />
              <div className="mr-10" style={{ marginLeft: 20, marginTop: 21 }}>
                <TitleSearch onSearch={this.onSearch} />
              </div>
              <Button className="btn-add video-button" style={{ marginTop: 21 }}>
                <WSIcon type={<PlusOutlined />} />{' '}
                <Link to="/create-manage-video">
                  <span className="text-white">Add Video</span>
                </Link>
              </Button>
            </div>
          }
        >
          <ExpandTable
            key={videos.map((v: any) => v.id).join()}
            columns={this.columns}
            tableData={videos}
            totalUsersRow={totalVideosRow}
            isExpand={false}
            pageAction={getAllHomeVideos}
            page={paginationPage.boat}
            tableLoading={loadVideo}
            extraInput={{ searchTerm: searchedValue, type }}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  videos: state.homeVideoReducer.videos,
  totalVideosRow: state.homeVideoReducer.totalVideosRow,
  deleteSuccess: state.homeVideoReducer && state.homeVideoReducer.deleteSuccess,
  loadVideo: state.homeVideoReducer.loadVideo,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllVideosList: () => dispatch(getAllVideosList()),
  getAllHomeVideos: (data: any) => dispatch(getAllHomeVideos(data)),
  clearManageVideoFlag: () => dispatch(clearManageVideoFlag()),
  deleteHomeVideoData: (data: string) => dispatch(deleteHomeVideo(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageHomeVideo)
