import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { formateDate } from '../../helpers/helper'
import ManageBoatTable, { tableRecords } from '../../component/ManageBoatTable'
import {
  getAllBoatServiceAction,
  changeYatchServiceAction,
  deleteYatchServiceBoats,
  clearBoatFlag,
  searchBoatService
} from '../../store/pendingAds/Action'
import { getAllBoatPagination, statusValues } from '../../enums/enums'
import ThreeToggleSwitch from '../../component/ThreeToggleSwitch'
import ApproveButton from '../../component/ApproveButton'

interface Props {
  getAllBoatServiceAction: typeof getAllBoatServiceAction
  allBoatService: any[]
  totalBoatServiceRow: number
  changeYatchServiceAction: typeof changeYatchServiceAction
  deleteYatchServiceBoats: typeof deleteYatchServiceBoats
  deleteYatchSuccess: boolean
  clearBoatFlag: typeof clearBoatFlag
  searchBoatService: typeof searchBoatService
}

class NearestBoatService extends Component<Props> {
  changeYatchServiceAction = (columnName: any, record: any, changedValue: any) => {
    const { changeYatchServiceAction, allBoatService } = this.props
    changeYatchServiceAction({
      id: record.id,
      columnName,
      value: changedValue,
      fieldArray: allBoatService,
      fieldName: 'allBoatService',
    })
  }

  columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text: any, record: tableRecords) => <div>{`${text.firstName} ${text.lastName}`}</div>,
    },
    {
      title: 'Company Name',
      dataIndex: 'user',
      key: 'companyName',
      render: (text: any, record: tableRecords) => <div>{text.companyName}</div>,
    },
    {
      title: 'Ad ID',
      dataIndex: 'adId',
      key: 'adId',
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <> {formateDate(text)} </>,
    },
    {
      title: 'Nearest Boat Service',
      dataIndex: 'nearest',
      key: 'nearest',
      render: (text: boolean, record: tableRecords) => (
        <ThreeToggleSwitch
          toggleMarketStatus={(e: any) => this.changeYatchServiceAction('nearest', record, e.target.value)}
          status={text}
        />
      ),
    },
    {
      title: 'Approval Status',
      dataIndex: 'adStatus',
      key: 'adStatus',
      render: (text: string, record: tableRecords) => {
        const { changeYatchServiceAction, allBoatService } = this.props
        return (
          <ApproveButton
            action={changeYatchServiceAction}
            input={{
              id: record.id,
              columnName: 'adStatus',
              value: record.adStatus,
              fieldArray: allBoatService,
              fieldName: 'allBoatService',
            }}
            buttonTitle="Decline"
            className="btn-delete"
          />
        )
      },
    },
  ]

  componentDidMount() {
    const { getAllBoatServiceAction } = this.props

    getAllBoatServiceAction({
      ...getAllBoatPagination,
      isNearest: statusValues.APPROVEORCRITERIA,
      adStatus: true,
    })
  }

  render() {
    const {
      allBoatService,
      totalBoatServiceRow,
      getAllBoatServiceAction,
      clearBoatFlag,
      deleteYatchServiceBoats,
      deleteYatchSuccess,
      searchBoatService
    } = this.props
    return (
      <ManageBoatTable
        tableTitle="Manage Nearest Boat Services"
        isQuery
        renderColumn={this.columns}
        tableList={allBoatService}
        totalUsersRow={totalBoatServiceRow}
        getListAction={getAllBoatServiceAction}
        searchAction={searchBoatService}
        view="service"
        deleteAction={deleteYatchServiceBoats}
        deleteSuccess={deleteYatchSuccess}
        clearAction={clearBoatFlag}
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    allBoatService: state.pendingAdsState.allBoatService,
    totalBoatServiceRow: state.pendingAdsState.totalBoatServiceRow,
    deleteYatchSuccess: state.pendingAdsState.deleteYatchSuccess,
    isLoading: state.pendingAdsState.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllBoatServiceAction: (data: any) => dispatch(getAllBoatServiceAction(data)),
    deleteYatchServiceBoats: (data: any) => dispatch(deleteYatchServiceBoats(data)),
    clearBoatFlag: () => dispatch(clearBoatFlag()),
    changeYatchServiceAction: (data: any) => dispatch(changeYatchServiceAction(data)),
    searchBoatService: (data: any) => dispatch(searchBoatService(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NearestBoatService)
