import { put, takeLatest, takeEvery, all } from 'redux-saga/effects'
import { client } from '../../../'
import {
  ActionType,
  CREATE_COUNTRY,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAILURE,
  GET_SINGLE_COUNTRY,
  GET_SINGLE_COUNTRY_SUCCESS,
  GET_SINGLE_COUNTRY_FAILURE,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAILURE,
  DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  CHANGE_COUNTRY_STATUS,
  CHANGE_COUNTRY_STATUS_FAILURE,
  CHANGE_COUNTRY_STATUS_SUCCESS,
  DELETE_COUNTRY_FAILURE,
  SEARCH_COUNTRY,
  SEARCH_COUNTRY_SUCCESS,
  SEARCH_COUNTRY_FAILURE,
} from './Type'
import { createCountry, getAllCountries, updateCountry, deleteCountry, changeCountryStatus, searchCountry } from './Schema'

function deleteCountryApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteCountry,
      variables: { id },
    })
    
}

function createCountryApi(input: any) {
  return client
    .mutate({
      mutation: createCountry,
      variables: { country: input },
    })
    
}

function getCountriesApi(data: any) {
  return client
    .query({
      query: getAllCountries,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    
}

function updateCountryApi(data: any) {
  const input = {
    id: data.id,
    name: data.name,
    ISOCode: data.ISOCode,
    countryPhoneCode: data.countryPhoneCode,
    image: data.image,
    addMedia: data.addMedia,
    removeMedia: data.removeMedia,
  }
  return client
    .mutate({
      mutation: updateCountry,
      variables: { country: input },
    })
    
}

function changeCountryStatusCall({ payload }: any) {
  return client
    .mutate({
      mutation: changeCountryStatus,
      variables: {
        id: payload.id,
        column: payload.column,
        value: payload.value,
      },
    })
    
}

function searchCountryApi({ payload }: any) {
  return client
    .query({
      query: searchCountry,
      variables: payload,
    })
    
}

function* deleteCountryData(action: ActionType) {
  try {
    const res = yield deleteCountryApi(action)
    yield put({
      type: DELETE_COUNTRY_SUCCESS,
      payload: res.data.deleteCountry,
    })
  } catch (error) {
    yield put({ type: DELETE_COUNTRY_FAILURE, error })
  }
}

function* createCountryData(action: any) {
  try {
    const res = yield createCountryApi(action.payload)
    yield put({ type: CREATE_COUNTRY_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_COUNTRY_FAILURE, error })
  }
}

function* getCountriesData(id: any) {
  try {
    const res = yield getCountriesApi(id.payload)
    yield put({ type: GET_SINGLE_COUNTRY_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: GET_SINGLE_COUNTRY_FAILURE, error })
  }
}

export function* updateCountryData(action: any) {
  try {
    const data = yield updateCountryApi(action.payload)
    yield put({ type: UPDATE_COUNTRY_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: UPDATE_COUNTRY_FAILURE, error })
  }
}

function* changeCountryStatusData({ payload }: any) {
  try {
    const res = yield changeCountryStatusCall({ payload })
    const { id, [payload.column]: status } = res.data.changeCountryStatus
    yield put({
      type: CHANGE_COUNTRY_STATUS_SUCCESS,
      payload: {
        id,
        column: payload.column,
        status,
      },
    })
  } catch (error) {
    yield put({ type: CHANGE_COUNTRY_STATUS_FAILURE, error })
  }
}

function* searchCountryStatusData({ payload }: any) {
  try {
    const res = yield searchCountryApi({ payload })
    yield put({
      type: SEARCH_COUNTRY_SUCCESS,
      payload: res.data.searchCountry,
    })
  } catch (error) {
    yield put({ type: SEARCH_COUNTRY_FAILURE, error })
  }
}

function* deleteCountrySaga() {
  yield takeLatest(DELETE_COUNTRY, deleteCountryData)
}

function* createCountrySaga() {
  yield takeLatest(CREATE_COUNTRY, createCountryData)
}

function* getCountries() {
  yield takeLatest(GET_SINGLE_COUNTRY, getCountriesData)
}

function* updateCountrySaga() {
  yield takeLatest(UPDATE_COUNTRY, updateCountryData)
}
function* changeCountryStatusSaga() {
  yield takeEvery(CHANGE_COUNTRY_STATUS, changeCountryStatusData)
}

function* searchCountrySaga() {
  yield takeEvery(SEARCH_COUNTRY, searchCountryStatusData)
}

export default function* countrySaga() {
  yield all([deleteCountrySaga(), createCountrySaga(), getCountries(), updateCountrySaga(), changeCountryStatusSaga(), searchCountrySaga()])
}
