import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button } from 'antd'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import History from '../../../History'

import { createPages, updatePages, clearPagesFlag } from '../../../store/staticContent/managePages/Action'
import { pageData } from '../../../store/staticContent/managePages/Type'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { removePTag, trimmedValue } from '../../../helpers/helper'
import WSSelect from '../../../component/WSSelect'
import { getAllRoles, clearRoleFlag } from '../../../store/role/Action'

const { Text } = Typography

interface Props {
  getAllRoles: typeof getAllRoles
  clearRoleFlag: typeof clearRoleFlag
  roles: any[]
  roleSuccess: Boolean
  createPages: typeof createPages
  location: any
  updatePages: typeof updatePages
  history: typeof History
  pageSuccess: boolean
  pageError: boolean
  clearPagesFlag: typeof clearPagesFlag
}

interface State {
  editPage: any
  isEdit: boolean
  managePage: {
    role: string
    title: string
    pageContent: string
  }
  isSaving: boolean
}

class CreateAgreementListing extends Component<Props, State> {
  formSubmitHandler = (values: pageData) => {
    const { isEdit } = this.state
    const { createPages, updatePages } = this.props

    this.setState({ isSaving: true })

    values.type = 'agreement'
    values.pageContent = removePTag(values.pageContent)

    if (isEdit) {
      updatePages(values)
    } else {
      createPages(values)
    }
  }

  componentDidMount() {
    const { clearPagesFlag } = this.props
    clearPagesFlag()
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      editPage: props.location.state
        ? { ...props.location.state.page.record, role: props.location.state.page.record.role.id }
        : null,
      isEdit: props.location.state ? props.location.state.page.isEdit : null,
      managePage: {
        role: '',
        title: '',
        pageContent: '',
      },
      isSaving: false,
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { roleSuccess, clearRoleFlag, pageSuccess, clearPagesFlag, history } = props

    if (roleSuccess) {
      clearRoleFlag()
    }

    if (pageSuccess) {
      clearPagesFlag()
      history.push('/manage-agreements-listing')
      return { ...state, isSaving: false }
    }

    return { ...state }
  }

  render() {
    const { managePage, editPage } = this.state
    const { history, roles } = this.props

    return (
      <Formik
        initialValues={editPage || managePage}
        onSubmit={values => this.formSubmitHandler(values)}
        validationSchema={Yup.object().shape({
          role: Yup.string().required('Role field is required.'),
          title: Yup.string().required('Title field is required.'),
          pageContent: Yup.string().required('Content field is required.'),
        })}
        render={({ setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  Create Agreement
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  <button className="ml-15 ant-btn ant-btn-primary" type="submit" disabled={this.state.isSaving}>
                    Save
                  </button>
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="role">
                    Role<span className="required">*</span>
                  </span>

                  <WSSelect
                    value={values.role}
                    onChangeText={(value: any) => setFieldValue('role', value)}
                    arrayList={roles}
                    listOptions="role"
                    passed="id"
                  />

                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name="role" component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="title">
                    Title<span className="required">*</span>
                  </span>
                  <Input
                    name="title"
                    placeholder="Title"
                    value={values.title}
                    onChange={e => setFieldValue('title', e.target.value)}
                    onBlur={e => setFieldValue('title', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`title`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="pageContent">
                    Content<span className="required">*</span>
                  </span>

                  <WSField
                    type="textArea"
                    defaultValue={values.pageContent}
                    onChangeText={(evt: any) => {
                      setFieldValue('pageContent', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('pageContent', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`pageContent`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.roleState.roles,
  roleSuccess: state.roleState.success,
  pageSuccess: state.pageReducer && state.pageReducer.pageSuccess,
  pageError: state.pageReducer && state.pageReducer.pageSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllRoles: () => dispatch(getAllRoles()),
  clearRoleFlag: () => dispatch(clearRoleFlag()),
  createPages: (data: pageData) => dispatch(createPages(data)),
  updatePages: (data: any) => dispatch(updatePages(data)),
  clearPagesFlag: () => dispatch(clearPagesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAgreementListing)
