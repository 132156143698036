import React, { Component } from 'react'

import CreateBannerIndex from '../CreateBannerIndex'
import History from '../../../History'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  location: any
  history: typeof History
}

class CreateAdminLoginBanner extends Component<Props> {
  render() {
    const { location, history } = this.props
    return (
      <CreateBannerIndex
        history={history}
        backLink="/manage-admin-login-banners"
        location={location}
        pageTitle="Admin Login Banner"
        titlePage="AdminLogin"
        type={mediaCategory.adminLogin.type}
      />
    )
  }
}

export default CreateAdminLoginBanner
