import React, { Component, Fragment } from 'react'
import UserAdminForm from '../../../component/form/UserAdminForm'
import History from '../../../History'

export interface Props {
  history: typeof History
}

export interface State {}

class CreateSalesMan extends Component<Props, State> {
  render() {
    const { history } = this.props
    return (
      <Fragment>
        <UserAdminForm title="Add Sales Man" history={history} />
      </Fragment>
    )
  }
}
export default CreateSalesMan
