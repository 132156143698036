import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import History from '../../../History'

import ListCategoryIndex from '../CategoryIndexPages/ListCategoryIndex'
import {
  clearManageMarinaTypesFlag,
  deleteMarinaTypes,
  getMarinaTypes,
} from '../../../store/staticContent/manageMarinaTypes/Action'

export interface Props {
  history: typeof History
  getMarinaTypes: typeof getMarinaTypes

  marinaTypes: []
  deleteMarinaTypes: typeof deleteMarinaTypes
  clearManageMarinaTypesFlag: typeof clearManageMarinaTypesFlag
  deleteRentTypeSuccess: boolean
  totalMarinaRow: number
}

export interface State {
  searchedValue: string
}
class ManageMarinaTypes extends Component<Props, State> {
  render() {
    const {
      deleteMarinaTypes,
      history,
      marinaTypes,
      totalMarinaRow,
      getMarinaTypes,
      clearManageMarinaTypesFlag,
      deleteRentTypeSuccess,
    } = this.props
    return (
      <ListCategoryIndex
        type={'marinaType'}
        history={history}
        pageTitle="Manage Marina Type"
        createPageLink="/create-marina-types"
        createPageTitle="Add Marina Type"
        listArray={marinaTypes}
        pageTotal={totalMarinaRow}
        listAction={getMarinaTypes}
        clearFlagAction={clearManageMarinaTypesFlag}
        deleteSuccess={deleteRentTypeSuccess}
        deleteAction={deleteMarinaTypes}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  marinaTypes: state.marinaTypesReducer && state.marinaTypesReducer.marinaTypes,
  totalMarinaRow: state.marinaTypesReducer.totalMarinaRow,
  marinaTypeSuccess: state.marinaTypesReducer && state.marinaTypesReducer.marinaTypeSuccess,
  deleteSuccess: state.marinaTypesReducer && state.marinaTypesReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getMarinaTypes: (data: any) => dispatch(getMarinaTypes(data)),
  deleteMarinaTypes: (data: string) => dispatch(deleteMarinaTypes(data)),
  clearManageMarinaTypesFlag: () => dispatch(clearManageMarinaTypesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageMarinaTypes)
