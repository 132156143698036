import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getAllFaqList, createFaqDetail, updateFaqDetail, deleteFaqDetail, changeFaqDetailStatus } from './Schema'
import {
  GET_ALL_FAQ_LIST_SUCCESS,
  GET_ALL_FAQ_LIST_FAILURE,
  ActionType,
  CREATE_FAQ_DETAIL_SUCCESS,
  CREATE_FAQ_DETAIL_FAILURE,
  UPDATE_FAQ_DETAIL_SUCCESS,
  UPDATE_FAQ_DETAIL_FAILURE,
  DELETE_FAQ_DETAIL_SUCCESS,
  DELETE_FAQ_DETAIL_FAILURE,
  DELETE_FAQ_DETAIL,
  CREATE_FAQ_DETAIL,
  UPDATE_FAQ_DETAIL,
  GET_ALL_FAQ_LIST,
  CHANGE_FAQ_DETAIL_STATUS_SUCCESS,
  CHANGE_FAQ_DETAIL_STATUS_FAILURE,
  CHANGE_FAQ_DETAIL_STATUS,
} from './Type'
import { AnyAction } from 'redux'

function getAllFaqListApi({ payload }: any) {
  return client
    .query({
      query: getAllFaqList,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

function createFaqDetailApi(input: any) {
  return client
    .mutate({
      mutation: createFaqDetail,
      variables: {
        input,
      },
    })
}

function updateFaqDetailApi(data: any) {
  const input = {
    id: data.id,
    question: data.question,
    description: data.description,
  }

  return client
    .mutate({
      mutation: updateFaqDetail,
      variables: { input: input },
    })
}

function deleteFaqDetailApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteFaqDetail,
      variables: { id },
    })
}

function changeFaqDetailStatusApi({ payload }: any) {
  return client
    .mutate({
      mutation: changeFaqDetailStatus,
      variables: {
        id: payload.id,
        column: payload.column,
        value: payload.value,
      },
    })
}

function* changeFaqDetailStatusData({ payload }: AnyAction) {
  try {
    const data = yield changeFaqDetailStatusApi({ payload })
    yield put({ type: CHANGE_FAQ_DETAIL_STATUS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: CHANGE_FAQ_DETAIL_STATUS_FAILURE, error })
  }
}

function* changeFaqDetailStatusSaga() {
  yield takeLatest(CHANGE_FAQ_DETAIL_STATUS, changeFaqDetailStatusData)
}

function* getAllFaqListData({ payload }: AnyAction) {
  try {
    const res = yield getAllFaqListApi({ payload })
    yield put({
      type: GET_ALL_FAQ_LIST_SUCCESS,
      payload: res.data.getAllFaqList,
    })
  } catch (error) {
    yield put({ type: GET_ALL_FAQ_LIST_FAILURE, error })
  }
}

function* createFaqDetailData(action: ActionType) {
  try {
    const res = yield createFaqDetailApi(action.payload)
    yield put({ type: CREATE_FAQ_DETAIL_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_FAQ_DETAIL_FAILURE, error })
  }
}

export function* updateFaqDetailData(action: ActionType) {
  try {
    const data = yield updateFaqDetailApi(action.payload)
    yield put({ type: UPDATE_FAQ_DETAIL_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_FAQ_DETAIL_FAILURE, error })
  }
}

function* deleteFaqDetailData(action: ActionType) {
  try {
    const res = yield deleteFaqDetailApi(action)
    yield put({ type: DELETE_FAQ_DETAIL_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_FAQ_DETAIL_FAILURE, error })
  }
}

function* getAllFaqListSaga() {
  yield takeLatest(GET_ALL_FAQ_LIST, getAllFaqListData)
}

function* createFaqDetailSaga() {
  yield takeLatest(CREATE_FAQ_DETAIL, createFaqDetailData)
}

function* updateFaqDetailSaga() {
  yield takeLatest(UPDATE_FAQ_DETAIL, updateFaqDetailData)
}

function* deleteFaqDetailSaga() {
  yield takeLatest(DELETE_FAQ_DETAIL, deleteFaqDetailData)
}

export default function* faqSaga() {
  yield all([
    deleteFaqDetailSaga(),
    createFaqDetailSaga(),
    updateFaqDetailSaga(),
    getAllFaqListSaga(),
    changeFaqDetailStatusSaga(),
  ])
}
