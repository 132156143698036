export interface boatTypesState {
  updateBoatTypeSuccess: boolean
  boatTypes: any[]
  boatTypeSuccess: boolean
  boatTypeError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  boatTypeTotal: number
}

export interface BoatTypesData {
  icon: String
  name: String
  id: String
}

export interface BoatTypes {
  icon: String
  name: String
  id: String
}

//create boat types

export const CREATE_BOAT_TYPES = 'CREATE_BOAT_TYPES'
export const CREATE_BOAT_TYPES_SUCCESS = 'CREATE_BOAT_TYPES_SUCCESS'
export const CREATE_BOAT_TYPES_FAILURE = 'CREATE_BOAT_TYPES_FAILURE'

//get all boat types

export const GET_ALL_BOAT_TYPES = 'GET_ALL_BOAT_TYPES'
export const GET_ALL_BOAT_TYPES_SUCCESS = 'GET_ALL_BOAT_TYPES_SUCCESS'
export const GET_ALL_BOAT_TYPES_FAILURE = 'GET_ALL_BOAT_TYPES_FAILURE'

//clear flag

export const CLEAR_MANAGE_BOAT_TYPES_FLAG = 'CLEAR_MANAGE_BOAT_TYPES_FLAG'

//update boat types

export const UPDATE_BOAT_TYPES = 'UPDATE_BOAT_TYPES'
export const UPDATE_BOAT_TYPES_SUCCESS = 'UPDATE_BOAT_TYPES_SUCCESS'
export const UPDATE_BOAT_TYPES_FAILURE = 'UPDATE_BOAT_TYPES_FAILURE'

// delete boat types

export const DELETE_BOAT_TYPES = 'DELETE_BOAT_TYPES'
export const DELETE_BOAT_TYPES_SUCCESS = 'DELETE_BOAT_TYPES_SUCCESS'
export const DELETE_BOAT_TYPES_FAILURE = 'DELETE_BOAT_TYPES_FAILURE'

// edit boat types

export const EDIT_BOAT_TYPES = 'EDIT_BOAT_TYPES'
export const EDIT_BOAT_TYPES_SUCCESS = 'EDIT_BOAT_TYPES_SUCCESS'
export const EDIT_BOAT_TYPES_FAILURE = 'EDIT_BOAT_TYPES_FAILURE'

interface createBoatTypes {
  type: typeof CREATE_BOAT_TYPES
  payload: BoatTypesData
}

interface createBoatTypesSuccess {
  type: typeof CREATE_BOAT_TYPES_SUCCESS
  payload: BoatTypes
}

interface createBoatTypesFailure {
  type: typeof CREATE_BOAT_TYPES_FAILURE
  payload: any
}

interface getAllBoatTypes {
  type: typeof GET_ALL_BOAT_TYPES
  payload: BoatTypesData
}

interface getAllBoatTypesSuccess {
  type: typeof GET_ALL_BOAT_TYPES_SUCCESS
  payload: any
}

interface getAllBoatTypesFailure {
  type: typeof GET_ALL_BOAT_TYPES_FAILURE
  payload: any
}

interface clearManageBoatTypesFlag {
  type: typeof CLEAR_MANAGE_BOAT_TYPES_FLAG
  payload: any
}

interface updateBoatTypes {
  type: typeof UPDATE_BOAT_TYPES
  payload: BoatTypes
}

interface updateBoatTypesSuccess {
  type: typeof UPDATE_BOAT_TYPES_SUCCESS
  payload: BoatTypes
}

interface updateBoatTypesFailure {
  type: typeof UPDATE_BOAT_TYPES_FAILURE
  payload: any
}

interface editBoatTypes {
  type: typeof UPDATE_BOAT_TYPES
  payload: string
}

interface editBoatTypesSuccess {
  type: typeof UPDATE_BOAT_TYPES_SUCCESS
  payload: string
}

interface editBoatTypesFailure {
  type: typeof UPDATE_BOAT_TYPES_FAILURE
  payload: any
}

interface deleteBoatTypes {
  type: typeof DELETE_BOAT_TYPES
  payload: string
}

interface deleteBoatTypesSuccess {
  type: typeof DELETE_BOAT_TYPES_SUCCESS
  payload: any
}

interface deleteBoatTypesFailure {
  type: typeof DELETE_BOAT_TYPES_FAILURE
  payload: any[]
}

export type ActionType =
  | createBoatTypes
  | createBoatTypesSuccess
  | createBoatTypesFailure
  | getAllBoatTypes
  | getAllBoatTypesSuccess
  | getAllBoatTypesFailure
  | clearManageBoatTypesFlag
  | deleteBoatTypes
  | deleteBoatTypesSuccess
  | deleteBoatTypesFailure
  | updateBoatTypes
  | updateBoatTypesSuccess
  | updateBoatTypesFailure
  | editBoatTypes
  | editBoatTypesSuccess
  | editBoatTypesFailure
