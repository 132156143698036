import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch, Pagination } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import {
  getManageUserGuide,
  deleteManageUserGuide,
  clearUserGuideFlag,
  changeUserGuideStatus,
} from '../../../store/staticContent/manageUserGuide/Action'
import { pagination, getAllBoatPagination, paginationPage } from '../../../enums/enums'
import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData } from '../../../helpers/helper'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import FilterButton from '../../filters/FilterButton'

interface Props {
  getManageUserGuide: typeof getManageUserGuide
  userGuides: []
  history: typeof History
  deleteManageUserGuide: typeof deleteManageUserGuide
  clearUserGuideFlag: typeof clearUserGuideFlag
  userGuideSuccess: boolean
  deleteSuccess: boolean
  changeUserGuideStatus: typeof changeUserGuideStatus
  userGuidesTotalRow: number
}

export interface State {
  searchedValue: string
  getInfo: {
    page: number
    limit: number
  }
}

class ManageFAQ extends Component<Props, State> {
  filterArray = [
    {
      title: 'Status',
      filedArray: [
        { label: 'Active', value: true },
        { label: 'Blocked', value: false },
      ],
      listOptions: 'label',
      passed: 'value',
      actionField: 'status',
    },
  ]

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { userGuideSuccess, clearUserGuideFlag, getManageUserGuide, deleteSuccess } = props
    const { getInfo } = state
    if (userGuideSuccess) {
      clearUserGuideFlag()
    }
    if (deleteSuccess) {
      getManageUserGuide(getInfo)
      clearUserGuideFlag()
    }
    return null
  }

  // componentDidMount() {
  //   const { getManageUserGuide } = this.props
  //   const { getInfo } = this.state

  //   getManageUserGuide(getInfo)
  // }

  editUserGuide = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-user-guide', {
      userGuide: { record: record, isEdit: true },
    })
  }

  changeUserGuideStatus = (column: any, record: any) => {
    const { changeUserGuideStatus } = this.props
    changeUserGuideStatus({ id: record.id, column, value: record[column] })
  }

  userGuideDetail = (record: any) => {
    const { history } = this.props
    if (record && record.hasOwnProperty('id')) {
      history.push('/view-user-guide', { userGuide: record })
    }
  }

  state = {
    searchedValue: '',
    getInfo: {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    },
    columns: [
      {
        title: 'User Guide Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a: any, b: any) => a.title.length - b.title.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'User Guide Status',
        dataIndex: 'status',
        key: 'status',
        // sorter: (a: any, b: any) => a.status.length - b.status.length,
        // sortDirections: ['descend', 'ascend'],
        render: (text: string, record: tableRecords, index: number) => (
          <Switch
            checked={record.status}
            onChange={() => this.changeUserGuideStatus('status', record)}
            checkedChildren="Active"
            unCheckedChildren="Blocked"
          />
        ),
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteManageUserGuide } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editUserGuide(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteManageUserGuide, record.id)
                }}
                className="btn-delete"
              >
                Delete
              </Button>
            </>
          )
        },
      },
      {
        title: 'View Ad',
        dataIndex: 'ViewAd',
        key: 'view',
        render: (text: string, record: tableRecords, index: number) => (
          <>
            <Button className="btn-view" onClick={() => this.userGuideDetail(record)}>
              View
            </Button>
          </>
        ),
      },
    ],
  }

  onSearch = (value: any) => {
    const { getManageUserGuide } = this.props;
    const { getInfo } = this.state

    if (value) {
      this.setState({searchedValue: value})
      getManageUserGuide({ ...getInfo, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getManageUserGuide({ ...getInfo })
    }
  }

  render() {
    const { columns, searchedValue } = this.state
    const { userGuides, userGuidesTotalRow, getManageUserGuide } = this.props

    return (
      <>
        <Card
          title="Manage User Guide"
          extra={
            <div className="d-flex flex-wrap">
              <div className="mr-10 mt-8">
                <TitleSearch onSearch={this.onSearch} />
              </div>
              <Button className="btn-add mt-8">
                <Link to="/create-user-guide">
                  <WSIcon type={<PlusOutlined />} /> Add User Guide
                </Link>
              </Button>
            </div>
          }
        >
          <FilterButton filterArray={this.filterArray} triggerAction={(data: any) => getManageUserGuide({ ...this.state.getInfo, searchTerm: this.state.searchedValue, ...data })} />
          <ExpandTable
            columns={columns}
            tableData={userGuides}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={userGuidesTotalRow}
            pageAction={getManageUserGuide}
            page={paginationPage.boat}
            extraInput={{ searchTerm: searchedValue }}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  userGuides: state.userGuideReducer.userGuides,
  userGuidesTotalRow: state.userGuideReducer.userGuidesTotalRow,
  userGuideSuccess: state.userGuideReducer && state.userGuideReducer.userGuideSuccess,
  deleteSuccess: state.userGuideReducer && state.userGuideReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getManageUserGuide: (data: any) => dispatch(getManageUserGuide(data)),
  deleteManageUserGuide: (data: string) => dispatch(deleteManageUserGuide(data)),
  clearUserGuideFlag: () => dispatch(clearUserGuideFlag()),
  changeUserGuideStatus: (data: any) => dispatch(changeUserGuideStatus(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageFAQ)
