import gql from 'graphql-tag'
import { AddressType } from '../../schemaTypes'

export const getAllBranches = gql`
    query getAllBranches($page: Float, $limit: Float){
        getAllBranches(page: $page, limit: $limit) {
            items {
                id
                contactName
                emailAddress
                address ${AddressType}
                branchStatus
                branchVerificationStatus
                user  {
                    companyName
                }
                branchName
                mobileNumber
                position {
                    alias
                    lookUpName
                }
                pricePerFt
                updatedAt
                image {
                    url
                }
            }
            total
        }
    }`

export const deleteBranch = gql`
  mutation deleteBranch($id: String!) {
    deleteBranch(id: $id) {
      id
    }
  }
`

export const changeBranchStatus = gql`
  mutation changeBranchStatus($id: String!, $value: Boolean!, $column: String!) {
    changeBranchStatus(id: $id, column: $column, value: $value) {
      id
      branchStatus
      branchVerificationStatus
    }
  }
`

export const getBranchesBySurveyor = gql`
query getBranchesByUser($user: String!, $limit: Float, $page: Float) {
    getBranchesByUser (user: $user, limit: $limit, page: $page) {
        items {
            id
            contactName
            emailAddress
            address ${AddressType}
            branchStatus
            branchVerificationStatus
            user  {
                companyName
                firstName
                lastName
                createdAt
            }
            branchName
            mobileNumber
            position {
                alias
                lookUpName
            }
            pricePerFt
            updatedAt
            image {
                url
            }
        }
        total
    }
}
`
