import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import History from '../../../History'

import ListCategoryIndex from '../CategoryIndexPages/ListCategoryIndex'
import {
  getAllYachtServiceTypes,
  deleteYatchTypes,
  clearManageYatchTypesFlag,
} from '../../../store/staticContent/manageYatchServiceTypes/Action'

export interface Props {
  history: typeof History
  getAllYachtServiceTypes: typeof getAllYachtServiceTypes

  yatchTypes: []
  deleteYatchTypes: typeof deleteYatchTypes
  clearManageYatchTypesFlag: typeof clearManageYatchTypesFlag
  deleteSuccess: boolean
  yatchTypesTableRow: number
}

export interface State {
  searchedValue: string
}
class ManageYatchServiceTypes extends Component<Props, State> {
  render() {
    const {
      deleteYatchTypes,
      history,
      yatchTypes,
      yatchTypesTableRow,
      getAllYachtServiceTypes,
      clearManageYatchTypesFlag,
      deleteSuccess,
    } = this.props
    return (
      <ListCategoryIndex
        type={'yatchType'}
        history={history}
        pageTitle="Manage Yacht Service Type"
        createPageLink="/create-yatch-types"
        createPageTitle="Add Yacht Service Type"
        listArray={yatchTypes}
        pageTotal={yatchTypesTableRow}
        listAction={getAllYachtServiceTypes}
        clearFlagAction={clearManageYatchTypesFlag}
        deleteSuccess={deleteSuccess}
        deleteAction={deleteYatchTypes}
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    yatchTypes: state.yatchTypesReducer && state.yatchTypesReducer.yatchTypes,
    yatchTypesTableRow: state.yatchTypesReducer && state.yatchTypesReducer.yatchTypesTableRow,
    yatchTypeSuccess: state.yatchTypesReducer && state.yatchTypesReducer.yatchTypeSuccess,
    deleteSuccess: state.yatchTypesReducer && state.yatchTypesReducer.deleteSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllYachtServiceTypes: (data: any) => dispatch(getAllYachtServiceTypes(data)),
  deleteYatchTypes: (data: string) => dispatch(deleteYatchTypes(data)),
  clearManageYatchTypesFlag: () => dispatch(clearManageYatchTypesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageYatchServiceTypes)
