import gql from 'graphql-tag'

export const createSmsTemplate = gql`
  mutation createSmsTemplate($input: TemplateInput!) {
    createSmsTemplate(input: $input) {
      id
      type
      body
      updatedAt
    }
  }
`
export const getAllSmsTemplates = gql`
  query getAllSmsTemplates($page: Int, $limit: Int) {
    getAllSmsTemplates(page: $page, limit: $limit) {
      items {
        id
        subject
        smtp {
          id
          email
        }
        name
        type
        body
        createdAt
        updatedAt
      }
      total
    }
  }
`

export const editSmsTemplate = gql`
  query EditSmsTemplate($id: String!) {
    EditSmsTemplate(id: $id) {
      id
      type
      body
      updatedAt
    }
  }
`

export const updateSmsTemplate = gql`
  mutation updateSmsTemplate($input: TemplateInput!) {
    updateSmsTemplate(input: $input) {
      id
      type
      body
      updatedAt
    }
  }
`

export const deleteSmsTemplate = gql`
  mutation deleteSmsTemplate($id: String!) {
    deleteSmsTemplate(id: $id) {
      id
      type
      body
      updatedAt
    }
  }
`

export const searchTemplate = gql`
query searchTemplate($query: String!, $templateType: Float! ,$page: Int, $limit: Int){
  searchTemplate(query: $query, templateType: $templateType, page: $page, limit: $limit){
    items {
        id
        subject
        smtp {
          id
          email
        }
        name
        type
        body
        createdAt
        updatedAt
      }
      total
  }
}
`;
