import {
  ActionType,
  pageState,
  GET_ALL_PAGES,
  GET_ALL_PAGES_SUCCESS,
  GET_ALL_PAGES_FAILURE,
  CREATE_PAGES,
  CREATE_PAGES_SUCCESS,
  CREATE_PAGES_FAILURE,
  UPDATE_PAGES,
  UPDATE_PAGES_SUCCESS,
  UPDATE_PAGES_FAILURE,
  DELETE_PAGES_SUCCESS,
  DELETE_PAGES_FAILURE,
  CLEAR_PAGES_FLAG,
  // GET_SINGLE_PAGE,
  // GET_SINGLE_PAGE_SUCCESS,
  // GET_SINGLE_PAGE_FAILURE,
  // GET_PAGES_BY_TITLE,
  // GET_PAGES_BY_TITLE_SUCCESS,
  // GET_PAGES_BY_TITLE_FAILURE
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: pageState = {
  page: [],
  pageSuccess: false,
  pageError: false,
  deleteSuccess: false,
  deleteError: false,
  totalPage: 0,
  isPageLoading: false
  
}

export const pageReducer = (state = initialState, action: ActionType): pageState => {
  switch (action.type) {
    case GET_ALL_PAGES:
      return {
        ...state,
        isPageLoading: true,
        pageSuccess: false,
        pageError: false,
      }
    case GET_ALL_PAGES_SUCCESS:
      return {
        ...state,
        pageSuccess: true,
        pageError: false,
        isPageLoading: false,
        page: action.payload.items,
        totalPage: action.payload.total,
      }
    case GET_ALL_PAGES_FAILURE:
      return {
        ...state,
        pageSuccess: false,
        pageError: true,
        isPageLoading: false,
      }

    case CREATE_PAGES:
      return {
        ...state,
        pageSuccess: false,
        pageError: false,
      }
    case CREATE_PAGES_SUCCESS:
      return {
        ...state,
        pageSuccess: true,
        pageError: false,
      }
    case CREATE_PAGES_FAILURE:
      return {
        ...state,
        pageSuccess: false,
        pageError: true,
      }

    case UPDATE_PAGES:
      return {
        ...state,
        pageSuccess: false,
        pageError: false,
      }
    case UPDATE_PAGES_SUCCESS:
      return {
        ...state,
        pageSuccess: true,
        pageError: false,
      }
    case UPDATE_PAGES_FAILURE:
      return {
        ...state,
        pageSuccess: false,
        pageError: true,
      }

    case DELETE_PAGES_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }
    case DELETE_PAGES_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case CLEAR_PAGES_FLAG:
      return {
        ...state,
        pageSuccess: false,
        pageError: false,
        deleteSuccess: false,
        deleteError: false,
      }
    // case GET_SINGLE_PAGE:
    //     return {
    //         ...state,
    //         pageSuccess: false,
    //         pageError: false
    //     }
    // case GET_SINGLE_PAGE_SUCCESS:
    //     return {
    //         ...state,
    //         pageSuccess: true,
    //         pageError: false,
    //         page: action.payload.id
    //     }
    // case GET_SINGLE_PAGE_FAILURE:
    //     return {
    //         ...state,
    //         pageSuccess: false,
    //         pageError: true
    //     }
    //     case GET_PAGES_BY_TITLE:
    //     return {
    //         ...state,
    //         pageSuccess: false,
    //         pageError: false
    //     }
    // case GET_PAGES_BY_TITLE_SUCCESS:
    //     return {
    //         ...state,
    //         pageSuccess: true,
    //         pageError: false,
    //         page: action.payload.title
    //     }
    // case GET_PAGES_BY_TITLE_FAILURE:
    //     return {
    //         ...state,
    //         pageSuccess: false,
    //         pageError: true
    //     }

    default:
      return state
  }
}
