import { GET_SEO_TAGS, DELETE_SEO_TAG, CREATE_SEO_TAG, SEARCH_SEO_TAG, UPDATE_SEO_TAG, CLEAR_FLAGS } from './Types'

export const clearMetaTagFlags = () => {
    return {
        type: CLEAR_FLAGS,
    }
}

export const getAllSeoMetaTagsAction = (payload: any) => {
  return {
    type: GET_SEO_TAGS,
    payload,
  }
}

export const deleteSeoMetaTagAction = (id: any) => {
  return {
    type: DELETE_SEO_TAG,
    payload: id,
  }
}

export const createSeoMetaTagAction = (payload: any) => {
  return {
    type: CREATE_SEO_TAG,
    payload,
  }
}

export const updateSeoMetaTagAction = (payload: any) => {
  return {
    type: UPDATE_SEO_TAG,
    payload,
  }
}

export const searchSeoMetaTagAction = (payload: any) => {
  return {
    type: SEARCH_SEO_TAG,
    payload,
  }
}
