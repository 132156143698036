import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Button, Row, Col } from 'antd'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { v1 as uuidv1 } from 'uuid'

import { updateSiteConfiguration, getSiteConfiguration, clearSiteConfigurationAction } from '../../store/generalSetting/Action'
import WSField from '../../component/Field'
import {
  camelCaseToNormalCase,
  nameFormatter,
  getUniqueArray,
  splitUrlFromUnderscore,
  trimmedValue,
} from '../../helpers/helper'
import History from '../../History'
import { uploadFile } from '../../helpers/S3FileUpload'
import { MEDIA_TYPE, siteConfigImage, imageExtensions } from '../../enums/enums'
import ShowTitle from '../../component/ShowTitle'

export interface Props {
  getSiteConfiguration: typeof getSiteConfiguration
  generalConfigurations: any
  updateSiteConfiguration: typeof updateSiteConfiguration
  history: typeof History
  getSiteConfigSuccess: boolean
  clearSiteConfigurationAction: typeof clearSiteConfigurationAction
  isFlag: boolean
}

export interface State {
  addMedia: any[]
  removeMedia: any[]
  loading: boolean
  generalSiteInfo: any
  generalLinks: any
  generalMetaInfo: any
  generalSiteLogo: any
  generalMarketLogo: any
  generalMarketBanner: any
  errors: any
}

class GeneralSettings1 extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      addMedia: [],
      removeMedia: [],
      loading: false,
      generalSiteInfo: {},
      generalLinks: {},
      generalMetaInfo: {},
      generalSiteLogo: {},
      generalMarketLogo: {},
      generalMarketBanner: {},
      errors: {},
    }
  }

  componentDidMount() {
    const { getSiteConfiguration } = this.props
    getSiteConfiguration()
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = nextProps => {
    const { generalConfigurations, getSiteConfigSuccess, clearSiteConfigurationAction } = nextProps

    const {
      siteName,
      siteTitle,
      siteUrl,
      siteWelcomeContent,
      supportEmail,
      supportNumber,
      minimumPercentageAmountForDeposit,
      copyRightText,
      address,
      facebookLink,
      googlePlusLink,
      instaLink,
      twitterLink,
      youtubeLink,
      linkedInLink,
      pinterestLink,
      metaDescription,
      metaKeyword,
      siteFavicon,
      siteLogo,
      adamseaCommunityLogo,
      adamseaConnectLogo,
      adamseaShareLogo,
      rentLogo,
      marinaAndStorageLogo,
      boatShowLogo,
      yachtServiceLogo,
      buyAndSellLogo,
      boatMediaLogo,
      siteWelcomeBanner,
      rentBanner,
      boatShowBanner,
      boatMediaBanner,
      marinaBanner,
      serviceMaintenanceBanner,
      buyAndSellBanner,
    } = generalConfigurations

    if (getSiteConfigSuccess) {
      clearSiteConfigurationAction()
      return {
        generalSiteInfo: {
          siteName,
          siteTitle,
          siteUrl,
          siteWelcomeContent,
          supportEmail,
          supportNumber,
          minimumPercentageAmountForDeposit,
          copyRightText,
          address,
        },
        generalLinks: {
          facebookLink,
          googlePlusLink,
          instaLink,
          twitterLink,
          youtubeLink,
          linkedInLink,
          pinterestLink,
        },
        generalMetaInfo: { metaDescription, metaKeyword },
        generalSiteLogo: {
          siteFavicon,
          siteLogo,
          adamseaCommunityLogo,
          adamseaConnectLogo,
          adamseaShareLogo,
        },
        generalMarketLogo: {
          rentLogo,
          marinaAndStorageLogo,
          boatShowLogo,
          yachtServiceLogo,
          // buyAndSellLogo,
          boatMediaLogo
        },
        generalMarketBanner: {
          siteWelcomeBanner,
          rentBanner,
          boatShowBanner,
          boatMediaBanner,
          serviceMaintenanceBanner,
          marinaBanner,
          buyAndSellBanner,
        },
      }
    }
    return null
  }

  selectSiteLogo = async (file: any, fieldName: string) => {
    if(file.status === 'removed') return;
    const { errors } = this.state

    if (errors && errors[fieldName]) {
      const newErrors = errors
      delete newErrors[fieldName]

      this.setState({ errors: newErrors })
    }

    this.setState({ loading: true })

    const fileKey = await nameFormatter([uuidv1(), file.type.split('/', 2)[1]], '.')

    await uploadFile(file, MEDIA_TYPE.IMAGE, fileKey)

    this.setState(prevState => ({
      addMedia: [
        ...prevState.addMedia,
        {
          key: fileKey,
          mediaName: siteConfigImage[fieldName],
          mediaType: MEDIA_TYPE.IMAGE,
        },
      ],
    }))

    this.setState({ loading: false })
  }

  handleRemove = (value: any, imageVar: any, name: any) => {
    const updatedAddMedia = this.state.addMedia?.filter(item => item.mediaName !== siteConfigImage[name])

    const data = value.uid.split('-')
    if (data[0] !== 'rc') {
      this.setState(
        (prevState: any) =>
          ({
            removeMedia: [...prevState.removeMedia, value.uid],
            [imageVar]: { ...prevState[imageVar], [name]: { url: '' } },
          } as Pick<State, keyof State>)
      )
    } else {
      this.setState({
        addMedia: updatedAddMedia,
      })
    }
  }

  handleChange = async (columnName: string, text: any, stateName: string) => {
    this.setState(
      (prevState: any) =>
        ({
          [stateName]: {
            ...prevState[stateName],
            [columnName]: trimmedValue(text),
          },
        } as Pick<State, keyof State>)
    )
  }

  onClickSave = () => {
    const { updateSiteConfiguration, generalConfigurations } = this.props
    const { generalSiteInfo, addMedia, removeMedia, generalLinks, generalMetaInfo, generalMarketBanner } = this.state

    generalSiteInfo.minimumPercentageAmountForDeposit = +generalSiteInfo.minimumPercentageAmountForDeposit

    let missingFields = Object.entries(generalMarketBanner).filter((item: any) => {
      if ((!item[1] || (item[1] && !item[1]?.url)) && !addMedia.find(media => media.mediaName == siteConfigImage[item[0]])) {
        return item
      }
    })

    if (missingFields.length) {
      missingFields.forEach(item => {
        this.setState(prevState => ({
          errors: { ...prevState.errors, [item[0]]: 'Required.' },
        }))
      })
    } else {
      this.setState({ errors: {} })

      const input: any = {
        ...generalSiteInfo,
        ...generalLinks,
        ...generalMetaInfo,
        removeMedia,
      }

      updateSiteConfiguration({
        id: generalConfigurations.id,
        addMedia: getUniqueArray(addMedia, (it: any) => it.mediaName),
        ...input,
      })
    }
  }
  render() {
    const {
      generalSiteInfo,
      loading,
      generalLinks,
      generalMetaInfo,
      generalSiteLogo,
      generalMarketLogo,
      generalMarketBanner,
      errors,
    } = this.state
    const { history, isFlag } = this.props

    return (
      <Card
        loading={isFlag}
        title="Manage Site Configurations"
        bordered={false}
        extra={
          <div>
            <Button onClick={() => history.goBack()}>Back</Button>
            <Button type="primary" className="ml-15" onClick={this.onClickSave}>
              Save
            </Button>
          </div>
        }
      >
        <ShowTitle title="Manage Site Information" />
        <Row gutter={16}>
          {Object.entries(generalSiteInfo).map(element => {
            return (
              <Col xs={16} sm={16} md={8} lg={8} xl={8}>
                <div className="ant-form-item-required">{camelCaseToNormalCase(element[0])}</div>
                <WSField
                  type="textbox"
                  placeholder={camelCaseToNormalCase(element[0])}
                  defaultValue={element[1]}
                  onChange={(e: any) => this.handleChange(element[0], e.target.value, 'generalSiteInfo')}
                />
              </Col>
            )
          })}
        </Row>
        <ShowTitle title="Manage Links" />
        <Row gutter={16}>
          {Object.entries(generalLinks).map(element => {
            return (
              <Col xs={16} sm={16} md={8} lg={8} xl={8}>
                <div className="ant-form-item-required">{camelCaseToNormalCase(element[0])}</div>
                <WSField
                  type="textbox"
                  placeholder={camelCaseToNormalCase(element[0])}
                  defaultValue={element[1]}
                  onChange={(e: any) => this.handleChange(element[0], e.target.value, 'generalLinks')}
                />
              </Col>
            )
          })}
        </Row>
        <ShowTitle title="Manage Site Logo" />
        <Row gutter={16}>
          {Object.entries(generalSiteLogo).map((element: any) => {
            const name = element[0] === 'adamseaConnectLogo' ? 'AS Chat Logo' : camelCaseToNormalCase(element[0])
            return (
              <Col xs={16} sm={16} md={8} lg={8} xl={8}>
                <div className="ant-form-item-required">{name}</div>
                <div className="market--logo--div">
                  {element[1] && element[1].url ? (
                    <>
                      <WSField
                        openFileDialogOnClick={!element[1].url}
                        defaultFileList={[
                          {
                            uid: element[1].id,
                            name: splitUrlFromUnderscore('_', element[1] && element[1].url),
                            status: 'done',
                            url: element[1].url,
                          },
                        ]}
                        defaultValue={name}
                        type="imageUpload"
                        accepted={imageExtensions}
                        placeholder={name}
                        uploadFunction={(file: any) => this.selectSiteLogo(file, element[0])}
                        onRemove={(rem: any) => this.handleRemove(rem, 'generalSiteLogo', element[0])}
                        covertCase={false}
                      />
                    </>
                  ) : (
                    <WSField
                      openFileDialogOnClick={
                        !this.state.addMedia?.find(item => item.mediaName === siteConfigImage[element[0]])?.key && !loading
                      }
                      type="imageUpload"
                      accepted={imageExtensions}
                      defaultValue={name}
                      placeholder={name}
                      uploadFunction={(file: any) => this.selectSiteLogo(file, element[0])}
                      onRemove={(rem: any) => this.handleRemove(rem, 'generalSiteLogo', element[0])}
                      covertCase={false}
                    />
                  )}
                </div>
              </Col>
            )
          })}
        </Row>
        <ShowTitle title="Manage Market Logo" />
        <Row gutter={16}>
          {Object.entries(generalMarketLogo).map((element: any) => {
            return (
              <Col xs={16} sm={16} md={8} lg={8} xl={8}>
                <div className="ant-form-item-required">{camelCaseToNormalCase(element[0])}</div>

                <div className="market--logo--div">
                  {element[1] && element[1].url ? (
                    <>
                      <WSField
                        openFileDialogOnClick={!element[1].url}
                        defaultFileList={[
                          {
                            uid: element[1].id,
                            name: splitUrlFromUnderscore('_', element[1] && element[1].url),
                            status: 'done',
                            url: element[1].url,
                          },
                        ]}
                        type="imageUpload"
                        accepted={imageExtensions}
                        placeholder={camelCaseToNormalCase(element[0])}
                        defaultValue={camelCaseToNormalCase(element[0])}
                        uploadFunction={(file: any) => this.selectSiteLogo(file, element[0])}
                        onRemove={(rem: any) => this.handleRemove(rem, 'generalMarketLogo', element[0])}
                      />
                    </>
                  ) : (
                    <WSField
                      openFileDialogOnClick={
                        !this.state.addMedia?.find(item => item.mediaName === siteConfigImage[element[0]])?.key && !loading
                      }
                      type="imageUpload"
                      accepted={imageExtensions}
                      defaultValue={camelCaseToNormalCase(element[0])}
                      placeholder={camelCaseToNormalCase(element[0])}
                      uploadFunction={(file: any) => this.selectSiteLogo(file, element[0])}
                      onRemove={(rem: any) => this.handleRemove(rem, 'generalMarketLogo', element[0])}
                    />
                  )}
                </div>
              </Col>
            )
          })}
        </Row>
        <ShowTitle title="Manage Market Banner" />
        <Row gutter={16}>
          {Object.entries(generalMarketBanner).map((element: any) => {
            const hasErrors: any = Object.entries(errors).find(item => item[0] === element[0])
            return (
              <Col xs={16} sm={16} md={8} lg={8} xl={8}>
                <div className="ant-form-item-required">{camelCaseToNormalCase(element[0])}</div>

                <div className="market--logo--div">
                  {element[1] && element[1].url ? (
                    <>
                      <WSField
                        openFileDialogOnClick={!element[1].url}
                        defaultFileList={[
                          {
                            uid: element[1].id,
                            name: splitUrlFromUnderscore('_', element[1] && element[1].url),
                            status: 'done',
                            url: element[1].url,
                          },
                        ]}
                        type="imageUpload"
                        accepted={imageExtensions}
                        placeholder={camelCaseToNormalCase(element[0])}
                        defaultValue={camelCaseToNormalCase(element[0])}
                        uploadFunction={(file: any) => this.selectSiteLogo(file, element[0])}
                        onRemove={(rem: any) => this.handleRemove(rem, 'generalMarketBanner', element[0])}
                      />
                    </>
                  ) : (
                    <>
                      <WSField
                        openFileDialogOnClick={
                          !this.state.addMedia?.find(item => item.mediaName === siteConfigImage[element[0]])?.key && !loading
                        }
                        type="imageUpload"
                        accepted={imageExtensions}
                        defaultValue={camelCaseToNormalCase(element[0])}
                        placeholder={camelCaseToNormalCase(element[0])}
                        uploadFunction={(file: any) => this.selectSiteLogo(file, element[0])}
                        onRemove={(rem: any) => this.handleRemove(rem, 'generalMarketBanner', element[0])}
                      />
                      {hasErrors && <span className="error-message-text">{hasErrors?.[1]}</span>}
                    </>
                  )}
                </div>
              </Col>
            )
          })}
        </Row>
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  generalConfigurations: state.siteConfigurationReducer.generalConfigurations,
  getSiteConfigSuccess: state.siteConfigurationReducer.getSiteConfigSuccess,
  isFlag: state.siteConfigurationReducer.isFlag,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateSiteConfiguration: (data: any) => dispatch(updateSiteConfiguration(data)),
  getSiteConfiguration: () => dispatch(getSiteConfiguration()),
  clearSiteConfigurationAction: () => dispatch(clearSiteConfigurationAction()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings1)
