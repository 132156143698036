import gql from 'graphql-tag'

export const auctionRoomDashboard = gql`
  {
    auctionRoomDashboard {
      auctionRoom
      pendingRequest
      comingSoon
      closed
      completedAuction
      noBids
      backToMarket
    }
  }
`
