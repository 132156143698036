import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { client } from '../..'
import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
  ActionType,
} from './Types'
import { getAdminPermissions } from './PermissionsSchema'

function getPermissionsCall({ payload }: any) {
  return client
    .query({
      query: getAdminPermissions,
      fetchPolicy: 'no-cache',
    })
}

function* getPermissionsData({ payload }: AnyAction) {
  try {
    const data = yield getPermissionsCall({ payload })
    yield put({ type: GET_PERMISSIONS_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_PERMISSIONS_FAILURE, error })
  }
}

function* getPermissionsSaga() {
  yield takeEvery(GET_PERMISSIONS, getPermissionsData)
}

export default function* permissionsSaga() {
  yield all([fork(getPermissionsSaga)])
}
