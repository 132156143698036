import {
  auctionRoomCountState,
  ActionType,
  GET_AUCTION_ROOM_COUNT,
  GET_AUCTION_ROOM_COUNT_FAILURE,
  GET_AUCTION_ROOM_COUNT_SUCCESS,
} from './Type'
import { auctionRoomCountDashboard } from '../../../helpers/helper'

const initialState: auctionRoomCountState = {
  auctionRoomCount: [],
  auctionRoomCountSuccess: false,
  auctionRoomCountFailure: false,
}

export const auctionRoomCountReducer = (state = initialState, action: ActionType): auctionRoomCountState => {
  switch (action.type) {
    case GET_AUCTION_ROOM_COUNT:
      return {
        ...state,
        auctionRoomCountSuccess: false,
        auctionRoomCountFailure: false,
      }

    case GET_AUCTION_ROOM_COUNT_SUCCESS:
      return {
        ...state,
        auctionRoomCountSuccess: true,
        auctionRoomCountFailure: false,
        auctionRoomCount: action.payload.auctionRoomDashboard,
      }

    case GET_AUCTION_ROOM_COUNT_FAILURE:
      return {
        ...state,
        auctionRoomCountSuccess: false,
        auctionRoomCountFailure: true,
      }

    default:
      return state
  }
}
