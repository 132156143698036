import {
  CREATE_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS,
  DELETE_PAYMENT_METHODS,
  GET_PAYMENT_METHODS,
  CLEAR_PAYMENT_METHODS,
  GET_SINGLE_SELLER_BOATS,
  CLEAR_PENDING_ADS_FLAG,
  CHANGE_APPROVAL_STATUS,
  GET_PENDING_RENTAL_BOATS,
  GET_ALL_PENDING_MARINA_STORAGE,
  CHANGE_MARINA_STATUS,
  GET_BOATS,
  DELETE_BOATS,
  CLEAR_BOATS_FLAG,
  CHANGE_RENTAL_BOAT_STATUS,
  GET_ALL_MARINA,
  GET_ALL_BOAT_SERVICE,
  GET_ALL_RENT,
  GET_MARINA_MORE_SERVICE,
  GET_NEAREST_MARINA,
  GET_MOST_POPULAR_MARINA,
  GET_LIVE_EXPERIENCE_MARINA,
  GET_NEAREST_BOAT_SERVICE,
  GET_TOP_RATED_BOAT_SERVICE,
  GET_RECOMMENDED_RENT,
  GET_MOST_POPULAR_RENT,
  GET_LIVE_EXPERIENCE_RENT,
  CHANGE_YATCH_SERVICE_STATUS,
  GET_PENDING_BOAT_SERVICE,
  DELETE_YATCH_SERVICE_BOATS,
  DELETE_MARINA_BOATS,
  GET_ACTIVITY_LOGS,
  GET_RENT_PAYMENTS_RECEIVERS,
  CLEAR_RENT_PAYMENTS_RECEIVERS_FLAG,
  GET_RENT_PAYMENTS_RECEIVER_BOATS,
  CLEAR_RENT_PAYMENTS_RECEIVER_BOATS_FLAG,
  GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS,
  CLEAR_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FLAG,
  GET_RENT_PAYMENTS_BOAT_SLOTS,
  CLEAR_RENT_PAYMENTS_BOAT_SLOTS_FLAG,
  GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS,
  CLEAR_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FLAG,
  RENT_PAYMENTS_RELEASE_INTENT,
  CLEAR_RENT_PAYMENTS_RELEASE_INTENT_FLAG,
  RENT_PAYMENTS_REFUND_INTENT,
  CLEAR_RENT_PAYMENTS_REFUND_INTENT_FLAG,
  SEARCH_BOAT,
  SEARCH_BOAT_SEARVICE,
  GET_REPORTED_ADS,
  CLEAR__GET_REPORTED_ADS,
  GET_AD_REPORTS,
  CLEAR__GET_AD_REPORTS,
  GET_REPORTED_REVIEWS,
  CLEAR__GET_REPORTED_REVIEWS,
  GET_REVIEW_REPORTS,
  CLEAR__GET_REVIEW_REPORTS,
  DELETE_REVIEW,
  CLEAR__DELETE_REVIEW,
  GET_ADMIN_ACTIVITY_LOGS,
} from './Types'

export function getSingleSellerBoats(data: any) {
  return {
    type: GET_SINGLE_SELLER_BOATS,
    payload: data,
  }
}

export function clearPendingAdFlag() {
  return {
    type: CLEAR_PENDING_ADS_FLAG,
  }
}

export function changeBoatApproveStatus(data: any) {
  return {
    type: CHANGE_APPROVAL_STATUS,
    payload: data,
  }
}
export function getPendingRentalBoats(data: any) {
  return {
    type: GET_PENDING_RENTAL_BOATS,
    payload: data,
  }
}

export function getPendingMarinaStorages(data: any) {
  return {
    type: GET_ALL_PENDING_MARINA_STORAGE,
    payload: data,
  }
}

export function changeMarinaStatus(data: any) {
  return {
    type: CHANGE_MARINA_STATUS,
    payload: data,
  }
}

// boat actions
export function getAllBoats(data: any) {
  return {
    type: GET_BOATS,
    payload: data,
  }
}

export function getActivityLogs(data: any) {
  return {
    type: GET_ACTIVITY_LOGS,
    payload: data,
  }
}

export function getAdminActivityLogs(data: any) {
  return {
    type: GET_ADMIN_ACTIVITY_LOGS,
    payload: data,
  }
}

export function deleteBoats(data: any) {
  return {
    type: DELETE_BOATS,
    payload: data,
  }
}

export function deleteYatchServiceBoats(data: any) {
  return {
    type: DELETE_YATCH_SERVICE_BOATS,
    payload: data,
  }
}

export function deleteMarinaBoats(data: any) {
  return {
    type: DELETE_MARINA_BOATS,
    payload: data,
  }
}

export function clearBoatFlag() {
  return {
    type: CLEAR_BOATS_FLAG,
  }
}

export function changeRentStatus(data: any) {
  return {
    type: CHANGE_RENTAL_BOAT_STATUS,
    payload: data,
  }
}

// marina
export function getAllMarinaBoatsAction(data: any) {
  return {
    type: GET_ALL_MARINA,
    payload: data,
  }
}

export function getMarinaMoreServiceAction(data: any) {
  return {
    type: GET_MARINA_MORE_SERVICE,
    payload: data,
  }
}

export function getNearestMarinaAction(data: any) {
  return {
    type: GET_NEAREST_MARINA,
    payload: data,
  }
}

export function getMostPopularMarinaAction(data: any) {
  return {
    type: GET_MOST_POPULAR_MARINA,
    payload: data,
  }
}

export function getLiveExperienceMarinaAction(data: any) {
  return {
    type: GET_LIVE_EXPERIENCE_MARINA,
    payload: data,
  }
}

// boat service
export function getAllBoatServiceAction(data: any) {
  return {
    type: GET_ALL_BOAT_SERVICE,
    payload: data,
  }
}
export function getNearestServiceAction(data: any) {
  return {
    type: GET_NEAREST_BOAT_SERVICE,
    payload: data,
  }
}
export function getTopRatedServiceAction(data: any) {
  return {
    type: GET_TOP_RATED_BOAT_SERVICE,
    payload: data,
  }
}
// boat rent
export function getAllRentAction(data: any) {
  return {
    type: GET_ALL_RENT,
    payload: data,
  }
}
export function getRecommendedRentAction(data: any) {
  return {
    type: GET_RECOMMENDED_RENT,
    payload: data,
  }
}
export function getMostPopularRentAction(data: any) {
  return {
    type: GET_MOST_POPULAR_RENT,
    payload: data,
  }
}
export function getLiveExperienceRentAction(data: any) {
  return {
    type: GET_LIVE_EXPERIENCE_RENT,
    payload: data,
  }
}
export function changeYatchServiceAction(data: any) {
  return {
    type: CHANGE_YATCH_SERVICE_STATUS,
    payload: data,
  }
}

// pending boat service
export function getPendingBoatService(data: any) {
  return {
    type: GET_PENDING_BOAT_SERVICE,
    payload: data,
  }
}

//payment
export function getAllPaymentMethods(data: any) {
  return {
    type: GET_PAYMENT_METHODS,
    payload: data,
  }
}

export function createPaymentMethods(data: any) {
  return {
    type: CREATE_PAYMENT_METHODS,
    payload: data,
  }
}

export function updatePaymentMethods(data: any) {
  return {
    type: UPDATE_PAYMENT_METHODS,
    payload: data,
  }
}
export function deletePaymentMethods(data: string) {
  return {
    type: DELETE_PAYMENT_METHODS,
    payload: data,
  }
}

export function clearManagePaymentMethodsFlag() {
  return {
    type: CLEAR_PAYMENT_METHODS,
  }
}

export const getRentPaymentsReceivers = () => ({
  type: GET_RENT_PAYMENTS_RECEIVERS,
})

export const clearRentPaymentsReceiversFlag = () => ({
  type: CLEAR_RENT_PAYMENTS_RECEIVERS_FLAG,
})

export const getRentPaymentsReceiverBoats = (payload: any) => ({
  type: GET_RENT_PAYMENTS_RECEIVER_BOATS,
  payload,
})

export const clearRentPaymentsReceiverBoatsFlag = () => ({
  type: CLEAR_RENT_PAYMENTS_RECEIVER_BOATS_FLAG,
})

export const getRentPaymentsBoatSlots = (payload: any) => ({
  type: GET_RENT_PAYMENTS_BOAT_SLOTS,
  payload,
})

export const clearRentPaymentsBoatSlotsFlag = () => ({
  type: CLEAR_RENT_PAYMENTS_BOAT_SLOTS_FLAG,
})

export const getRentPaymentsBoatSlotBookings = (payload: any) => ({
  type: GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS,
  payload,
})

export const clearRentPaymentsBoatSlotBookingsFlag = () => ({
  type: CLEAR_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FLAG,
})

export const getRentPaymentsPrivateBoatSlots = (payload: any) => ({
  type: GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS,
  payload,
})

export const clearRentPaymentsPrivateBoatSlotsFlag = () => ({
  type: CLEAR_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FLAG,
})

export const rentPaymentsReleaseIntent = (payload: any) => ({
  type: RENT_PAYMENTS_RELEASE_INTENT,
  payload,
})

export const clearRentPaymentsReleaseIntentFlag = () => ({
  type: CLEAR_RENT_PAYMENTS_RELEASE_INTENT_FLAG,
})

export const rentPaymentsRefundIntent = (payload: any) => ({
  type: RENT_PAYMENTS_REFUND_INTENT,
  payload,
})

export const clearRentPaymentsRefundIntentFlag = () => ({
  type: CLEAR_RENT_PAYMENTS_REFUND_INTENT_FLAG,
})

export const searchBoats = (payload: any) => ({
  type: SEARCH_BOAT,
  payload,
})

export const searchBoatService = (payload: any) => ({
  type: SEARCH_BOAT_SEARVICE,
  payload,
})

export const getReportedAds = (payload: any) => ({
  type: GET_REPORTED_ADS,
  payload,
})

export const clear_getReportedAds = () => ({
  type: CLEAR__GET_REPORTED_ADS,
})

export const getAdReports = (payload: any) => ({
  type: GET_AD_REPORTS,
  payload,
})

export const clear_getAdReports = () => ({
  type: CLEAR__GET_AD_REPORTS,
})

export const getReportedReviews = (payload: any) => ({
  type: GET_REPORTED_REVIEWS,
  payload,
})

export const clear_getReportedReviews = () => ({
  type: CLEAR__GET_REPORTED_REVIEWS,
})

export const getReviewReports = (payload: any) => ({
  type: GET_REVIEW_REPORTS,
  payload,
})

export const clear_getReviewReports = () => ({
  type: CLEAR__GET_REVIEW_REPORTS,
})

export const deleteReview = (payload: any) => ({
  type: DELETE_REVIEW,
  payload,
})

export const clear_deleteReview = () => ({
  type: CLEAR__DELETE_REVIEW,
})
