import gql from 'graphql-tag'
import { AddressType, imageSchema } from '../schemaTypes'

export const addUserMutation = gql`
  mutation adminLogin($input: LoginType!) {
    adminLogin(input: $input)
  }
`

export const changeUserStatus = gql`
  mutation changeUserStatus($id: String!, $column: String!, $value: Boolean!) {
    changeUserStatus(id: $id, column: $column, value: $value) {
      message
      statusCode
      id
    }
  }
`

export const getAllTypes = gql`
  query getAllTypes($page: Int, $limit: Int) {
    getAllTypes(page: $page, limit: $limit) {
      items {
        type
        id
      }
      total
    }
  }
`

export const getAllLookUps = gql`
  query getAllLookUps($page: Int, $limit: Int, $typeId: String, $isActive: Boolean, $isAdmin: Boolean = true, $alias: String) {
    getAllLookUps(page: $page, limit: $limit, typeId: $typeId, isActive: $isActive, isAdmin: $isAdmin, alias: $alias) {
      items {
        typeId {
          id
          type
        }
        tripId {
          id
          alias
        }
        alias
        id
        lookUpName
        isActive
        icon ${imageSchema}
      }
      total
    }
  }
`

export const createLookUp = gql`
  mutation createLookUp($lookUp: LookUpInput!) {
    createLookUp(lookUp: $lookUp) {
      typeId {
        type
        id
      }
      alias
      id
      lookUpName
      isActive
      icon {
        id
        type
        url
        mediaType
        key
        name
        order
        user
        moduleType
        isActive
      }
    }
  }
`

export const updateLookUp = gql`
  mutation updateLookUp($lookUp: LookUpInput!) {
    updateLookUp(lookUp: $lookUp) {
      typeId {
        type
        id
      }
      alias
      id
      icon ${imageSchema}
      tripId {
        id
        alias
      }
      isActive
    }
  }
`

export const changeLookUpStatus = gql`
  mutation changeLookUpStatus($input: LookupChangeStatusInput!) {
    changeLookUpStatus(input: $input) {
      id
      isActive
    }
  }
`
