import gql from 'graphql-tag'

export const getAllPages = gql`
  query getAllPages($page: Int, $limit: Int, $type: String, $searchTerm: String) {
    getAllPages(page: $page, limit: $limit, type: $type, searchTerm: $searchTerm) {
      items {
        id
        role {
          id
          role
          aliasName
        }
        title
        pageContent
        titleSlug
        type
      }
      total
    }
  }
`

export const createPage = gql`
  mutation createPage($page: PageInput!) {
    createPage(page: $page) {
      id
      title
      pageContent
      titleSlug
    }
  }
`

export const updatePage = gql`
  mutation updatePage($page: PageInput!) {
    updatePage(page: $page) {
      id
    }
  }
`

export const deletePage = gql`
  mutation deletePage($id: String!) {
    deletePage(id: $id) {
      id
    }
  }
`

//     export const getSinglePage = gql`
// query getSinglePage($id: String!){
//     getSinglePage(id: $id){
//         id
//         title
//         pageContent
//         titleSlug
//     }
// }`

// export const getPagesByTitle = gql`
// query getPagesByTitle($title: String!){
//     getPagesByTitle(title: $title){
//         id
//         title
//         pageContent
//         titleSlug
//     }
// }`
