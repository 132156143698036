import gql from 'graphql-tag'

export const getAdminOtherCounts = gql`
  {
    getAdminOtherCounts {
      roleCounts
      total
    }
  }
`

export const getCurrencyRates = gql`
  {
    getCurrencyRates {
      id
      data
    }
  }
`

export const getSidebarVerificationAlertCount = gql`
  {
    getDashboardVerificationAlerts {
      pendingVerification
      pendingBoatAdsCount
      pendingBrokerAndDealerCount
      pendingMfgAdsCount
      pendingServiceAds 
      pendingMarinaAds
      pendingRentAds
      pendingAgreementReq
      pendingSurveyorAgreement
      pendingShipperAgreement
      pendingBuilderAgreement
    }
  }
`