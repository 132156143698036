import React, { Component } from 'react'
import { VerticalGraph } from '../../../component/charts/VerticalGraph'

import { Card, Row, Col, Typography, Avatar } from 'antd'
import { CircularGraph } from '../../../component/charts/CircularGraph'
import { getAgreementsCount } from '../../../store/dashboard/manageAgreements/Action'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { allAgreementsCounts } from '../../../enums/enums'
import { Link } from 'react-router-dom'
import History from '../../../History'
import { getDataFromArray } from '../../../helpers/helper'

const { Title } = Typography

export interface Props {
  getAgreementsCount: typeof getAgreementsCount
  agreementsCount: any
  agreementCountSuccess: boolean
  history: typeof History
}

interface countObj {
  _id: any
  counts: Number
}

class ManageDashboardAgreements extends Component<Props> {
  componentDidMount() {
    const { getAgreementsCount } = this.props

    getAgreementsCount()
  }

  onClickFunction(value: any) {
    const { history, agreementsCount } = this.props
    console.log('onClickFunction')

    switch (value.toLowercase()) {
      case 'Boat Builder Agreement':
        agreementsCount.find((item: countObj) => item._id === 'Boat Builder Agreement')
        history.push('/builder-agreements')
        break
      case 'Shipment Agreement':
        agreementsCount.find((item: countObj) => item._id === 'Shipment Agreement')
        history.push('/shipment-agreements')
        break
      case 'Surveyor AgreementSurveyor Agreement':
        agreementsCount.find((item: countObj) => item._id === 'Surveyor Agreement')
        history.push('/surveyor-agreements')
        break
    }
  }
  render() {
    const { agreementsCount } = this.props
    console.log(agreementsCount, 'agreementsCount')

    return (
      <>
        <div style={{ background: '#ECECEC', padding: '30px' }}>
          <Row gutter={16}>
            {agreementsCount.all &&
              agreementsCount.all.length &&
              agreementsCount.all.map((item: countObj) => {
                return (
                  <Col span={6}>
                    <Link to={allAgreementsCounts[item._id]}>
                      <Card title={item._id} className="text-transform-uppercase" bordered={false}>
                        <div className="d-flex justify-content-between">
                          <Col span={8}>
                            <img
                              alt="no-img"
                              src={require(`../../../assets/image/manageAgreements/Boat Builder Agreement.png`)}
                              height="50px"
                              width="50px"
                            />
                          </Col>
                          <Col span={8}>
                            <Title level={2} type="secondary">
                              {item.counts}
                            </Title>
                          </Col>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                )
              })}
          </Row>
        </div>

        <br />
        {console.log(agreementsCount, 'agreementsCount')}

        <Row gutter={16} className="mb-20">
          <Col span={12}>
            <CircularGraph
              chartTitle={'AGREEMENTS'}
              labels={getDataFromArray(agreementsCount.all, '_id')}
              data={getDataFromArray(agreementsCount.all, 'counts')}
            />
          </Col>
          <Col span={12}>
            <CircularGraph
              chartTitle={'PENDING REQUEST'}
              labels={getDataFromArray(agreementsCount.pending, '_id')}
              data={getDataFromArray(agreementsCount.pending, 'counts')}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  agreementsCount: state.agreementsCountReducer && state.agreementsCountReducer.agreementsCount,
  agreementCountSuccess: state.agreementsCountReducer && state.agreementsCountReducer.agreementCountSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAgreementsCount: () => dispatch(getAgreementsCount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDashboardAgreements)
