import gql from 'graphql-tag'

const Agreement = `{
    id
    country
    companyName
    agreementId
    firstName
    lastName
    email
    companyWebsite
    mobileNumber
    userType
    agreementStep
    isDeleted
    isDuplicateEntry
    status
    user {
      id
    }
    role {
      id
      role
      aliasName
    }
    createdAt
}`

const AgreementContent = `{
    id
    title
    titleSlug
    fileTitle
    body
    createdAt
    updatedAt
}`

export const createAgreement = gql`
 mutation createAgreement($input: AgreementInput!) {
    createAgreement(input: $input) 
        ${Agreement}
 }
 `

export const updateAgreement = gql`
 mutation updateAgreement($input: AgreementInput!){
  updateAgreement(input: $input)
    ${Agreement}
  }
  `

export const changeStatusAgreement = gql`
  mutation changeStatusAgreement($id: String!, $column: String!, $value: Boolean!, $message: String = null) {
    changeStatusAgreement(id: $id, column: $column, value: $value, message: $message) {
      id
      country
      companyName
      agreementId
      firstName
      lastName
      email
      companyWebsite
      mobileNumber
      isDuplicateEntry
      userType
      agreementStep
      isDeleted
      status
      role {
        id
        role
        aliasName
      }
      createdAt
    }
  }
`

export const getAllAgreements = gql`
    query  getAllAgreements($page: Int, $limit: Int, $userType: String, $country: String, $status: Boolean, $agreementStep: AGREEMENTSTATUS, $searchTerm: String) {
     getAllAgreements(page: $page, limit: $limit, userType: $userType, country: $country, status: $status, agreementStep: $agreementStep, searchTerm: $searchTerm) {
         items ${Agreement}
         total
     }    
  }
  `

export const deleteAgreement = gql`
  mutation deleteAgreement($id: String!) {
    deleteAgreement(id: $id) 
        ${Agreement}
  }
`

export const createAgreementContent = gql`
 mutation createAgreementContent($input: AgreementContentInput!) {
    createAgreementContent(input: $input) 
        ${AgreementContent}
 }
 `

export const updateAgreementContent = gql`
 mutation updateAgreementContent($input: AgreementContentInput!) {
    updateAgreementContent(input: $input) 
        ${AgreementContent}
 }
 `

export const getAllAgreementContents = gql`
    query  getAllAgreementContents($page: Int, $limit: Int) {
     getAllAgreementContents(page: $page, limit: $limit) {
         items ${AgreementContent}
         total
     }    
  }
`

export const getSingleAgreementContent = gql`
  query getSingleAgreementContent($id: String!) {
    getSingleAgreementContent(id: $id) 
        ${AgreementContent}
  }
`

export const deleteAgreementContent = gql`
  mutation deleteAgreementContent($id: String!) {
    deleteAgreementContent(id: $id) 
        ${AgreementContent}
  }
`

export const getAllPendingAgreementRequests = gql`
  query getAllPendingUsers($page: Int, $limit: Int) {
    getAllPendingUsers(page: $page, limit: $limit) {
      items {
        id
        country
        companyName
        agreementId
        firstName
        lastName
        email
        role {
          role
          aliasName
          id
        }
        companyWebsite
        mobileNumber
      }
      total
    }
  }
`

export const approvePendingAgreements = gql`
  mutation moveAgreementStep($agreementStep: AGREEMENTSTATUS!, $id: String!) {
    moveAgreementStep(agreementStep: $agreementStep, id: $id) {
      id
      role {
        role
        aliasName
        id
      }
      country
      companyName
      agreementId
      firstName
      lastName
      email
      companyWebsite
      mobileNumber
    }
  }
`
