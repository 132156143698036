import React, { Component, GetDerivedStateFromProps } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import * as Yup from 'yup'
import Text from 'antd/lib/typography/Text'
import { Card, Row, Col } from 'antd'
import { Form, Formik, ErrorMessage } from 'formik'

import History from '../../../History'
import {
  getAllPageInformation,
  createRegistrationContent,
  clearCreateRegistrationContentFlag,
} from '../../../store/staticContent/manageRegistrationContent/Actions'
import { pageInformation } from '../../../store/staticContent/manageRegistrationContent/Types'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { RouteComponentProps } from 'react-router'
import { PageSubModuleCategory } from '../../../enums/enums'
import { removePTag, trimmedValue } from '../../../helpers/helper'
import Loader from '../../../component/Loading'

const registrationStaticHeader = [
  { title: 'Member', keyName: 'member' },
  { title: 'Boat Owner', keyName: 'boat-owner' },
  { title: 'Broker And Dealer', keyName: 'broker-and-dealer' },
  { title: 'Boat Manufacturer', keyName: 'boat-manufacturer' },
  { title: 'Yacht Services', keyName: 'service-and-maintenance' },
  { title: 'Surveyor', keyName: 'surveyor' },
  { title: 'Marina And Storage', keyName: 'marina-and-storage' },
  { title: 'Rent', keyName: 'rent-and-charter' },
  { title: 'Shipment', keyName: 'yacht-shipper' },
  { title: 'Agent', keyName: 'agent' },
]

const chooseValidation = Yup.object().shape({
  // member: Yup.string().required(
  //     "Member field is required.",
  // ),
  // singleseller: Yup.string().required(
  //     "Boat Owner field is required.",
  // ),
  // corporateseller: Yup.string().required(
  //     "Broker and dealer field is required.",
  // ),
  // boatbuilder: Yup.string().required(
  //     "Boat Manufacture field is required.",
  // ),
  // rental: Yup.string().required(
  //     "Rent and charter field is required.",
  // ),
  // service: Yup.string().required(
  //     "Service and maintenance field is required.",
  // ),
  // marina: Yup.string().required(
  //     "Marina and storage field is required.",
  // ),
  // surveyor: Yup.string().required(
  //     "Surveyor field is required.",
  // ),
  // shipment: Yup.string().required(
  //     "Shipment field is required.",
  // ),
  // agent: Yup.string().required(
  //     "Agent field is required.",
  // ),
})

interface Props {
  history: typeof History
  getAllPageInformation: typeof getAllPageInformation
  createRegistrationContent: typeof createRegistrationContent
  clearCreateRegistrationContentFlag: typeof clearCreateRegistrationContentFlag
  allPageInformation: pageInformation[]
  registrationContentSuccess: boolean
  registrationContentError: boolean
  pageInfoSuccess: boolean
  storedType: String
  isLoading: boolean
}

interface Props extends RouteComponentProps<{ typeIs: string }> {
  typeIs: string
}
interface State {
  input: any
}

class RegistrationContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      input: {
        member: '',
        singleseller: '',
        corporateseller: '',
        boatbuilder: '',
        rental: '',
        service: '',
        marina: '',
        surveyor: '',
        shipment: '',
        agent: '',
      },
    }
  }

  componentDidMount() {
    const { getAllPageInformation, location } = this.props
    const { typeIs } = location.state as any
    const input = {
      uiType: typeIs,
    }
    getAllPageInformation(input)
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { allPageInformation, pageInfoSuccess, location, storedType, getAllPageInformation } = props
    const { typeIs } = location.state as any

    if (pageInfoSuccess) {
      if (storedType !== typeIs) {
        const input = {
          uiType: typeIs,
        }
        getAllPageInformation(input)
      }
      const getContent: any = allPageInformation.reduce((acc: any, cur: any) => ({ ...acc, [cur.module]: cur[typeIs] }), {})
      console.log(getContent, 'getContent')
      return { input: getContent }
    }
    return null
  }

  formSubmitHandler = (values: any) => {
    const { createRegistrationContent, location } = this.props
    const { typeIs } = location.state as any
    console.log(values, 'values')

    let pageInformation = []
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const content = removePTag(values[key])
        pageInformation.push({
          uiType: typeIs,
          module: PageSubModuleCategory[key],
          [typeIs]: content,
        })
      }
    }

    if (pageInformation.length) {
      let input = {
        uiType: typeIs,
        pageInformations: pageInformation,
      }

      createRegistrationContent(input)
    }
  }

  handleChange = (text: string, setFieldValue: any, name: string) => {}

  render() {
    const { input } = this.state
    const { location, isLoading } = this.props
    const { typeIs } = location.state as any

    return (
      <>
        {' '}
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={input}
            onSubmit={values => this.formSubmitHandler(values)}
            validationSchema={chooseValidation}
            render={({ setFieldValue, values, errors }) => (
              <Form>
                {console.log(values)}

                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      Manage Registration Static {typeIs === 'content' ? ` Content` : ` Header`}
                    </span>
                  }
                  extra={
                    <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                      Save
                    </button>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" span={24}>
                      {registrationStaticHeader.map((element: any) => (
                        <>
                          <p>Registration Text For {element.title}</p>

                          <WSField
                            type="textArea"
                            defaultValue={values[PageSubModuleCategory[element.keyName]]}
                            onChangeText={(evt: any) => setFieldValue(element.keyName, evt.editor.getData())}
                            onBlur={(evt: { editor: { getData: () => string } }) =>
                              setFieldValue(element.keyName, trimmedValue(removePTag(evt.editor.getData())))
                            }
                          />
                          <Text type="danger">
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger"
                              name={element.keyName}
                              component="span"
                            />
                          </Text>
                          <br />
                        </>
                      ))}
                    </Col>
                  </Row>
                </Card>
              </Form>
            )}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  allPageInformation: state.pageInformationReducer.allPageInformation,
  pageInfoSuccess: state.pageInformationReducer.pageInfoSuccess,
  registrationContentSuccess: state.pageInformationReducer.registrationContentSuccess,
  registrationContentError: state.pageInformationReducer.registrationContentError,
  storedType: state.pageInformationReducer.storedType,
  isLoading: state.pageInformationReducer.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllPageInformation: (data: any) => dispatch(getAllPageInformation(data)),
  createRegistrationContent: (data: any) => dispatch(createRegistrationContent(data)),
  clearCreateRegistrationContentFlag: () => dispatch(clearCreateRegistrationContentFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationContent)
