export interface downloadsState {
  downloads: []
  downloadsSuccess: boolean
  downloadsError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  downloadsTotal: number
}

export interface downloadsData {
  title: string
  description: string
  downloadUrl?: string
  status: boolean
  addMedia?: any[]
  removeMedia?: any[]
  __typename?: string
}

export const GET_ALL_DOWNLOADS = 'GET_ALL_DOWNLOADS'
export const GET_ALL_DOWNLOADS_SUCCESS = 'GET_ALL_DOWNLOADS_SUCCESS'
export const GET_ALL_DOWNLOADS_FAILURE = 'GET_ALL_DOWNLOADS_FAILURE'

export const CREATE_DOWNLOADS = 'CREATE_DOWNLOADS'
export const CREATE_DOWNLOADS_SUCCESS = 'CREATE_DOWNLOADS_SUCCESS'
export const CREATE_DOWNLOADS_FAILURE = 'CREATE_DOWNLOADS_FAILURE'

export const UPDATE_DOWNLOADS = 'UPDATE_DOWNLOADS'
export const UPDATE_DOWNLOADS_SUCCESS = 'UPDATE_DOWNLOADS_SUCCESS'
export const UPDATE_DOWNLOADS_FAILURE = 'UPDATE_DOWNLOADS_FAILURE'

export const DELETE_DOWNLOADS = 'DELETE_DOWNLOADS'
export const DELETE_DOWNLOADS_SUCCESS = 'DELETE_DOWNLOADS_SUCCESS'
export const DELETE_DOWNLOADS_FAILURE = 'DELETE_DOWNLOADS_FAILURE'

export const CLEAR_DOWNLOADS_FLAG = 'CLEAR_DOWNLOADS_FLAG'

interface getAllDownloads {
  type: typeof GET_ALL_DOWNLOADS
  payload: downloadsData
}

interface getAllDownloadsSuccess {
  type: typeof GET_ALL_DOWNLOADS_SUCCESS
  payload: any
}

interface getAllDownloadsFailure {
  type: typeof GET_ALL_DOWNLOADS_FAILURE
  payload: any
}

interface createDownloads {
  type: typeof CREATE_DOWNLOADS
  payload: downloadsData
}

interface createDownloadsSuccess {
  type: typeof CREATE_DOWNLOADS_SUCCESS
  payload: any
}

interface createDownloadsFailure {
  type: typeof CREATE_DOWNLOADS_FAILURE
  payload: any
}

interface updateDownloads {
  type: typeof UPDATE_DOWNLOADS
  payload: downloadsData
}

interface updateDownloadsSuccess {
  type: typeof UPDATE_DOWNLOADS_SUCCESS
  payload: downloadsData
}

interface updateDownloadsFailure {
  type: typeof UPDATE_DOWNLOADS_FAILURE
  payload: downloadsData
}

interface deleteDownloads {
  type: typeof DELETE_DOWNLOADS
  payload: string
}

interface deleteDownloadsSuccess {
  type: typeof DELETE_DOWNLOADS_SUCCESS
  payload: downloadsData
}

interface deleteDownloadsFailure {
  type: typeof DELETE_DOWNLOADS_FAILURE
  payload: downloadsData
}

interface clearDownloadsFlag {
  type: typeof CLEAR_DOWNLOADS_FLAG
  payload: any
}

export type ActionType =
  | getAllDownloads
  | getAllDownloadsSuccess
  | getAllDownloadsFailure
  | createDownloads
  | createDownloadsSuccess
  | createDownloadsFailure
  | updateDownloads
  | updateDownloadsSuccess
  | updateDownloadsFailure
  | deleteDownloads
  | deleteDownloadsSuccess
  | deleteDownloadsFailure
  | clearDownloadsFlag
