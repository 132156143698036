import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import History from '../../../History'
import CreateCategoryIndex from '../CategoryIndexPages/CreateCategoryIndex'
import {
  clearManageYatchTypesFlag,
  updateYatchTypes,
  createYatchTypes,
} from '../../../store/staticContent/manageYatchServiceTypes/Action'
import { YatchTypesData } from '../../../store/staticContent/manageYatchServiceTypes/Types'
import { MEDIA_NAME } from '../../../enums/enums'

interface Props {
  getYatchTypeSuccess: boolean
  updateYatchTypeSuccess: boolean
  location: any
  clearManageYatchTypesFlag: typeof clearManageYatchTypesFlag
  updateYatchTypes: typeof updateYatchTypes
  history: typeof History
  createYatchTypes: typeof createYatchTypes
}

class CreateYatchServiceTypes extends Component<Props> {
  render() {
    const {
      getYatchTypeSuccess,
      updateYatchTypeSuccess,
      createYatchTypes,
      updateYatchTypes,
      history,
      location,
      clearManageYatchTypesFlag,
    } = this.props
    return (
      <CreateCategoryIndex
        history={history}
        mediaName={MEDIA_NAME.SERVICETYPEICON}
        iconMediaName={MEDIA_NAME.YACHTTHUMBNAILTYPEICON}
        pageTitle="Yacht Service Type"
        imageTitle="Yacht Service Image"
        iconTitle="Yacht Icon"
        popupTitle="Yacht Service"
        editedData={location.state ? location.state.yatchType : null}
        successType={getYatchTypeSuccess}
        updateSuccessType={updateYatchTypeSuccess}
        createAction={createYatchTypes}
        updateAction={updateYatchTypes}
        clearTypesFlag={clearManageYatchTypesFlag}
        listPageLink="/manage-yatch-types"
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  getYatchTypeSuccess: state.yatchTypesReducer && state.yatchTypesReducer.getYatchTypeSuccess,
  updateYatchTypeSuccess: state.yatchTypesReducer && state.yatchTypesReducer.updateYatchTypeSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createYatchTypes: (data: YatchTypesData) => dispatch(createYatchTypes(data)),
  updateYatchTypes: (data: YatchTypesData) => dispatch(updateYatchTypes(data)),
  clearManageYatchTypesFlag: () => dispatch(clearManageYatchTypesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateYatchServiceTypes)
