import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { getManageSmtp, deleteManageSmtp, clearSmtpFlag } from '../../../store/staticContent/manageSMTP/Action'
import { getAllBoatPagination, paginationPage, SMTPTYPE, typeSelector } from '../../../enums/enums'
import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData } from '../../../helpers/helper'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import FilterButton from '../../filters/FilterButton'

let pageFilterArray: any = []

interface Props {
  getManageSmtp: typeof getManageSmtp
  smtps: []
  history: typeof History
  deleteManageSmtp: typeof deleteManageSmtp
  clearSmtpFlag: typeof clearSmtpFlag
  smtpSuccess: boolean
  deleteSuccess: boolean
  totalSmtpRow: number
  roles: any
  isLoading: boolean
}

export interface State {
  searchedValue: string
  isFilterCall: boolean
}

class ManageSMTP extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { isFilterCall } = state
    const { smtpSuccess, clearSmtpFlag, getManageSmtp, deleteSuccess } = props

    if (isFilterCall && smtpSuccess) {
      clearSmtpFlag()
      return { isFilterCall: true }
    }
    if (deleteSuccess) {
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getManageSmtp(input)
      clearSmtpFlag()
    }
    return null
  }

  componentDidMount() {
    // const { getManageSmtp } = this.props
    pageFilterArray = [
      {
        title: 'Type',
        filedArray: typeSelector,
        listOptions: 'label',
        passed: 'value',
        actionField: 'type',
      },
    ]
    // const input = {
    //   page: getAllBoatPagination.page,
    //   limit: getAllBoatPagination.limit,
    // }
    // getManageSmtp(input)
  }

  editSmtps = (record: tableRecords) => {
    const { history } = this.props
    console.log(record, 'record')

    history.push('/create-smtp', { smtp: record })
  }

  state = {
    isFilterCall: true,
    searchedValue: '',
    columns: [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text: any) => <span>{SMTPTYPE[text]}</span>,
      },
      {
        title: 'Host',
        dataIndex: 'host',
        key: 'host',
      },
      {
        title: 'Port',
        dataIndex: 'port',
        key: 'port',
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        sorter: (a: any, b: any) => a.email.length - b.email.length,
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteManageSmtp } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editSmtps(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteManageSmtp, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  onSearch = (value: any) => {
    const { getManageSmtp } = this.props
    if (value) {
      this.setState({searchedValue: value})
      getManageSmtp({ searchTerm: value, page: 1, limit: 10 })
    } else {
      this.setState({searchedValue: ''})
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getManageSmtp(input)
    }
  }

  render() {
    const { columns, searchedValue } = this.state
    const { smtps, totalSmtpRow, getManageSmtp, isLoading } = this.props

    return (
      <>
        <Card
          title="Manage SMTP"
          extra={
            <div className="d-flex flex-wrap">
              <div className="mr-10 mt-8">
                <TitleSearch onSearch={this.onSearch} />
              </div>
              <Button className="btn-add mt-8">
                <Link to="/create-smtp">
                  <WSIcon type={<PlusOutlined />} /> Add SMTP
                </Link>
              </Button>
            </div>
          }
        >
          <FilterButton filterArray={pageFilterArray} triggerAction={getManageSmtp} />
          <ExpandTable
            columns={columns}
            tableData={getSearchedData(smtps, searchedValue)}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={totalSmtpRow}
            pageAction={getManageSmtp}
            page={paginationPage.boat}
            tableLoading={isLoading}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  smtps: state.smtpReducer.smtps,
  roles: state.roleState.roles,
  totalSmtpRow: state.smtpReducer.totalSmtpRow,
  smtpSuccess: state.smtpReducer && state.smtpReducer.smtpSuccess,
  deleteSuccess: state.smtpReducer && state.smtpReducer.deleteSuccess,
  isLoading: state.smtpReducer.isLoading,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getManageSmtp: (data: any) => dispatch(getManageSmtp(data)),
  deleteManageSmtp: (data: string) => dispatch(deleteManageSmtp(data)),
  clearSmtpFlag: () => dispatch(clearSmtpFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageSMTP)
