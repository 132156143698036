import { EmptyState } from '../../helpers/helper'

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE'

//create boat types
export const CREATE_PAYMENT_METHODS = 'CREATE_PAYMENT_METHODS'
export const CREATE_PAYMENT_METHODS_SUCCESS = 'CREATE_PAYMENT_METHODS_SUCCESS'
export const CREATE_PAYMENT_METHODS_FAILURE = 'CREATE_PAYMENT_METHODS_FAILURE'

//update boat types
export const UPDATE_PAYMENT_METHODS = 'UPDATE_PAYMENT_METHODS'
export const UPDATE_PAYMENT_METHODS_SUCCESS = 'UPDATE_PAYMENT_METHODS_SUCCESS'
export const UPDATE_PAYMENT_METHODS_FAILURE = 'UPDATE_PAYMENT_METHODS_FAILURE'

// delete boat types
export const DELETE_PAYMENT_METHODS = 'DELETE_PAYMENT_METHODS'
export const DELETE_PAYMENT_METHODS_SUCCESS = 'DELETE_PAYMENT_METHODS_SUCCESS'
export const DELETE_PAYMENT_METHODS_FAILURE = 'DELETE_PAYMENT_METHODS_FAILURE'

//clear flag
export const CLEAR_PAYMENT_METHODS = 'CLEAR_PAYMENT_METHODS'

export const GET_SINGLE_SELLER_BOATS = 'GET_SINGLE_SELLER_BOATS'
export const GET_SINGLE_SELLER_BOATS_SUCCESS = 'GET_SINGLE_SELLER_BOATS_SUCCESS'
export const GET_SINGLE_SELLER_BOATS_FAILURE = 'GET_SINGLE_SELLER_BOATS_FAILURE'

//clear flags
export const CLEAR_PENDING_ADS_FLAG = 'CLEAR_PENDING_ADS_FLAG'

//change approve status
export const CHANGE_APPROVAL_STATUS = 'CHANGE_APPROVAL_STATUS'
export const CHANGE_APPROVAL_STATUS_SUCCESS = 'CHANGE_APPROVAL_STATUS_SUCCESS'
export const CHANGE_APPROVAL_STATUS_FAILURE = 'CHANGE_APPROVAL_STATUS_FAILURE'

//get rental boats
export const GET_PENDING_RENTAL_BOATS = 'GET_PENDING_RENTAL_BOATS'
export const GET_PENDING_RENTAL_BOATS_SUCCESS = 'GET_PENDING_RENTAL_BOATS_SUCCESS'
export const GET_PENDING_RENTAL_BOATS_FAILURE = 'GET_PENDING_RENTAL_BOATS_FAILURE'

//get all pending marinas
export const GET_ALL_PENDING_MARINA_STORAGE = 'GET_ALL_PENDING_MARINA_STORAGE'
export const GET_ALL_PENDING_MARINA_STORAGE_SUCCESS = 'GET_ALL_PENDING_MARINA_STORAGE_SUCCESS'
export const GET_ALL_PENDING_MARINA_STORAGE_FAILURE = 'GET_ALL_PENDING_MARINA_STORAGE_FAILURE'

//change marina status
export const CHANGE_MARINA_STATUS = 'CHANGE_MARINA_STATUS'
export const CHANGE_MARINA_STATUS_SUCCESS = 'CHANGE_MARINA_STATUS_SUCCESS'
export const CHANGE_MARINA_STATUS_FAILURE = 'CHANGE_MARINA_STATUS_FAILURE'

//change pending ent status
export const CHANGE_RENTAL_BOAT_STATUS = 'CHANGE_RENTAL_BOAT_STATUS'
export const CHANGE_RENTAL_BOAT_STATUS_SUCCESS = 'CHANGE_RENTAL_BOAT_STATUS_SUCCESS'
export const CHANGE_RENTAL_BOAT_STATUS_FAILURE = 'CHANGE_RENTAL_BOAT_STATUS_FAILURE'

// marina boat
export const GET_ALL_MARINA = 'GET_ALL_MARINA'
export const GET_ALL_MARINA_SUCCESS = 'GET_ALL_MARINA_SUCCESS'
export const GET_ALL_MARINA_FAILURE = 'GET_ALL_MARINA_FAILURE'

// rent boat
export const GET_ALL_RENT = 'GET_ALL_RENT'
export const GET_ALL_RENT_SUCCESS = 'GET_ALL_RENT_SUCCESS'
export const GET_ALL_RENT_FAILURE = 'GET_ALL_RENT_FAILURE'

// boat service
export const GET_ALL_BOAT_SERVICE = 'GET_ALL_BOAT_SERVICE'
export const GET_ALL_BOAT_SERVICE_SUCCESS = 'GET_ALL_BOAT_SERVICE_SUCCESS'
export const GET_ALL_BOAT_SERVICE_FAILURE = 'GET_ALL_BOAT_SERVICE_FAILURE'

// boat marina
export const GET_MARINA_MORE_SERVICE = 'GET_MARINA_MORE_SERVICE'
export const GET_MARINA_MORE_SERVICE_SUCCESS = 'GET_MARINA_MORE_SERVICE_SUCCESS'
export const GET_MARINA_MORE_SERVICE_FAILURE = 'GET_MARINA_MORE_SERVICE_FAILURE'

// boat marina nearest
export const GET_NEAREST_MARINA = 'GET_NEAREST_MARINA'
export const GET_NEAREST_MARINA_SUCCESS = 'GET_NEAREST_MARINA_SUCCESS'
export const GET_NEAREST_MARINA_FAILURE = 'GET_NEAREST_MARINA_FAILURE'

// boat marina live experience
export const GET_LIVE_EXPERIENCE_MARINA = 'GET_LIVE_EXPERIENCE_MARINA'
export const GET_LIVE_EXPERIENCE_MARINA_SUCCESS = 'GET_LIVE_EXPERIENCE_MARINA_SUCCESS'
export const GET_LIVE_EXPERIENCE_MARINA_FAILURE = 'GET_LIVE_EXPERIENCE_MARINA_FAILURE'

// boat marina most popular
export const GET_MOST_POPULAR_MARINA = 'GET_MOST_POPULAR_MARINA'
export const GET_MOST_POPULAR_MARINA_SUCCESS = 'GET_MOST_POPULAR_MARINA_SUCCESS'
export const GET_MOST_POPULAR_MARINA_FAILURE = 'GET_MOST_POPULAR_MARINA_FAILURE'

// boat service nearest
export const GET_NEAREST_BOAT_SERVICE = 'GET_NEAREST_BOAT_SERVICE'
export const GET_NEAREST_BOAT_SERVICE_SUCCESS = 'GET_NEAREST_BOAT_SERVICE_SUCCESS'
export const GET_NEAREST_BOAT_SERVICE_FAILURE = 'GET_NEAREST_BOAT_SERVICE_FAILURE'

// boat top rated
export const GET_TOP_RATED_BOAT_SERVICE = 'GET_TOP_RATED_BOAT_SERVICE'
export const GET_TOP_RATED_BOAT_SERVICE_SUCCESS = 'GET_TOP_RATED_BOAT_SERVICE_SUCCESS'
export const GET_TOP_RATED_BOAT_SERVICE_FAILURE = 'GET_TOP_RATED_BOAT_SERVICE_FAILURE'

// boat rent recommended
export const GET_RECOMMENDED_RENT = 'GET_RECOMMENDED_RENT'
export const GET_RECOMMENDED_RENT_SUCCESS = 'GET_RECOMMENDED_RENT_SUCCESS'
export const GET_RECOMMENDED_RENT_FAILURE = 'GET_RECOMMENDED_RENT_FAILURE'

// boat rent
export const GET_LIVE_EXPERIENCE_RENT = 'GET_LIVE_EXPERIENCE_RENT'
export const GET_LIVE_EXPERIENCE_RENT_SUCCESS = 'GET_LIVE_EXPERIENCE_RENT_SUCCESS'
export const GET_LIVE_EXPERIENCE_RENT_FAILURE = 'GET_LIVE_EXPERIENCE_RENT_FAILURE'

// boat service nearest
export const GET_MOST_POPULAR_RENT = 'GET_MOST_POPULAR_RENT'
export const GET_MOST_POPULAR_RENT_SUCCESS = 'GET_MOST_POPULAR_RENT_SUCCESS'
export const GET_MOST_POPULAR_RENT_FAILURE = 'GET_MOST_POPULAR_RENT_FAILURE'

export const CHANGE_YATCH_SERVICE_STATUS = 'CHANGE_YATCH_SERVICE_STATUS'
export const CHANGE_YATCH_SERVICE_STATUS_SUCCESS = 'CHANGE_YATCH_SERVICE_STATUS_SUCCESS'
export const CHANGE_YATCH_SERVICE_STATUS_FAILURE = 'CHANGE_YATCH_SERVICE_STATUS_FAILURE'

//pending  boat service
export const GET_PENDING_BOAT_SERVICE = 'GET_PENDING_BOAT_SERVICE'
export const GET_PENDING_BOAT_SERVICE_SUCCESS = 'GET_PENDING_BOAT_SERVICE_SUCCESS'
export const GET_PENDING_BOAT_SERVICE_FAILURE = 'GET_PENDING_BOAT_SERVICE_FAILURE'

// boat types

//get all boat types
export const GET_BOATS = 'GET_BOATS'
export const GET_BOATS_SUCCESS = 'GET_BOATS_SUCCESS'
export const GET_BOATS_FAILURE = 'GET_BOATS_FAILURE'
export const CLEAR_BOATS_FLAG = 'CLEAR_BOATS_FLAG'

export const DELETE_BOATS = 'DELETE_BOATS'
export const DELETE_BOATS_SUCCESS = 'DELETE_BOATS_SUCCESS'
export const DELETE_BOATS_FAILURE = 'DELETE_BOATS_FAILURE'

// yatch service
export const DELETE_YATCH_SERVICE_BOATS = 'DELETE_YATCH_SERVICE_BOATS'
export const DELETE_YATCH_SERVICE_BOATS_SUCCESS = 'DELETE_YATCH_SERVICE_BOATS_SUCCESS'
export const DELETE_YATCH_SERVICE_BOATS_FAILURE = 'DELETE_YATCH_SERVICE_BOATS_FAILURE'

// marina
export const DELETE_MARINA_BOATS = 'DELETE_MARINA_BOATS'
export const DELETE_MARINA_BOATS_SUCCESS = 'DELETE_MARINA_BOATS_SUCCESS'
export const DELETE_MARINA_BOATS_FAILURE = 'DELETE_MARINA_BOATS_FAILURE'

export const GET_ACTIVITY_LOGS = 'GET_ACTIVITY_LOGS'
export const GET_ACTIVITY_LOGS_SUCCESS = 'GET_ACTIVITY_LOGS_SUCCESS'
export const GET_ACTIVITY_LOGS_FAILURE = 'GET_ACTIVITY_LOGS_FAILURE'

export const GET_ADMIN_ACTIVITY_LOGS = 'GET_ADMIN_ACTIVITY_LOGS'
export const GET_ADMIN_ACTIVITY_LOGS_SUCCESS = 'GET_ADMIN_ACTIVITY_LOGS_SUCCESS'
export const GET_ADMIN_ACTIVITY_LOGS_FAILURE = 'GET_ADMIN_ACTIVITY_LOGS_FAILURE'

export const GET_RENT_PAYMENTS_RECEIVERS = 'GET_RENT_PAYMENTS_RECEIVERS'
export const GET_RENT_PAYMENTS_RECEIVERS_SUCCESS = 'GET_RENT_PAYMENTS_RECEIVERS_SUCCESS'
export const GET_RENT_PAYMENTS_RECEIVERS_FAILURE = 'GET_RENT_PAYMENTS_RECEIVERS_FAILURE'
export const CLEAR_RENT_PAYMENTS_RECEIVERS_FLAG = 'CLEAR_RENT_PAYMENTS_RECEIVERS_FLAG'

export const GET_RENT_PAYMENTS_RECEIVER_BOATS = 'GET_RENT_PAYMENTS_RECEIVER_BOATS'
export const GET_RENT_PAYMENTS_RECEIVER_BOATS_SUCCESS = 'GET_RENT_PAYMENTS_RECEIVER_BOATS_SUCCESS'
export const GET_RENT_PAYMENTS_RECEIVER_BOATS_FAILURE = 'GET_RENT_PAYMENTS_RECEIVER_BOATS_FAILURE'
export const CLEAR_RENT_PAYMENTS_RECEIVER_BOATS_FLAG = 'CLEAR_RENT_PAYMENTS_RECEIVER_BOATS_FLAG'

export const GET_RENT_PAYMENTS_BOAT_SLOTS = 'GET_RENT_PAYMENTS_BOAT_SLOTS'
export const GET_RENT_PAYMENTS_BOAT_SLOTS_SUCCESS = 'GET_RENT_PAYMENTS_BOAT_SLOTS_SUCCESS'
export const GET_RENT_PAYMENTS_BOAT_SLOTS_FAILURE = 'GET_RENT_PAYMENTS_BOAT_SLOTS_FAILURE'
export const CLEAR_RENT_PAYMENTS_BOAT_SLOTS_FLAG = 'CLEAR_RENT_PAYMENTS_BOAT_SLOTS_FLAG'

export const GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS = 'GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS'
export const GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_SUCCESS = 'GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_SUCCESS'
export const GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FAILURE = 'GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FAILURE'
export const CLEAR_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FLAG = 'CLEAR_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FLAG'

export const GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS = 'GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS'
export const GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_SUCCESS = 'GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_SUCCESS'
export const GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FAILURE = 'GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FAILURE'
export const CLEAR_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FLAG = 'CLEAR_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FLAG'

export const RENT_PAYMENTS_RELEASE_INTENT = 'RENT_PAYMENTS_RELEASE_INTENT'
export const RENT_PAYMENTS_RELEASE_INTENT_SUCCESS = 'RENT_PAYMENTS_RELEASE_INTENT_SUCCESS'
export const RENT_PAYMENTS_RELEASE_INTENT_FAILURE = 'RENT_PAYMENTS_RELEASE_INTENT_FAILURE'
export const CLEAR_RENT_PAYMENTS_RELEASE_INTENT_FLAG = 'CLEAR_RENT_PAYMENTS_RELEASE_INTENT_FLAG'

export const RENT_PAYMENTS_REFUND_INTENT = 'RENT_PAYMENTS_REFUND_INTENT'
export const RENT_PAYMENTS_REFUND_INTENT_SUCCESS = 'RENT_PAYMENTS_REFUND_INTENT_SUCCESS'
export const RENT_PAYMENTS_REFUND_INTENT_FAILURE = 'RENT_PAYMENTS_REFUND_INTENT_FAILURE'
export const CLEAR_RENT_PAYMENTS_REFUND_INTENT_FLAG = 'CLEAR_RENT_PAYMENTS_REFUND_INTENT_FLAG'

export const SEARCH_BOAT = 'SEARCH_BOAT'
export const SEARCH_BOAT_SUCCESS = 'SEARCH_BOAT_SUCCESS'
export const SEARCH_BOAT_FAILURE = 'SEARCH_BOAT_FAILURE'

export const SEARCH_BOAT_SEARVICE = 'SEARCH_BOAT_SEARVICE'
export const SEARCH_BOAT_SEARVICE_SUCCESS = 'SEARCH_BOAT_SEARVICE_SUCCESS'
export const SEARCH_BOAT_SEARVICE_FAILURE = 'SEARCH_BOAT_SEARVICE_FAILURE'

export const GET_REPORTED_ADS = 'GET_REPORTED_ADS'
export const GET_REPORTED_ADS_SUCCESS = 'GET_REPORTED_ADS_SUCCESS'
export const GET_REPORTED_ADS_FAILURE = 'GET_REPORTED_ADS_FAILURE'
export const CLEAR__GET_REPORTED_ADS = 'CLEAR__GET_REPORTED_ADS'

export const GET_AD_REPORTS = 'GET_AD_REPORTS'
export const GET_AD_REPORTS_SUCCESS = 'GET_AD_REPORTS_SUCCESS'
export const GET_AD_REPORTS_FAILURE = 'GET_AD_REPORTS_FAILURE'
export const CLEAR__GET_AD_REPORTS = 'CLEAR__GET_AD_REPORTS'

export const GET_REPORTED_REVIEWS = 'GET_REPORTED_REVIEWS'
export const GET_REPORTED_REVIEWS_SUCCESS = 'GET_REPORTED_REVIEWS_SUCCESS'
export const GET_REPORTED_REVIEWS_FAILURE = 'GET_REPORTED_REVIEWS_FAILURE'
export const CLEAR__GET_REPORTED_REVIEWS = 'CLEAR__GET_REPORTED_REVIEWS'

export const GET_REVIEW_REPORTS = 'GET_REVIEW_REPORTS'
export const GET_REVIEW_REPORTS_SUCCESS = 'GET_REVIEW_REPORTS_SUCCESS'
export const GET_REVIEW_REPORTS_FAILURE = 'GET_REVIEW_REPORTS_FAILURE'
export const CLEAR__GET_REVIEW_REPORTS = 'CLEAR__GET_REVIEW_REPORTS'

export const DELETE_REVIEW = 'DELETE_REVIEW'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE'
export const CLEAR__DELETE_REVIEW = 'CLEAR__DELETE_REVIEW'

export interface pendingAdsState {
  paymentLoader: boolean
  paymentMethodError: boolean
  paymentMethods: any[]
  paymentMethodTotal: number
  paymentMethodSuccess: boolean
  updatePaymentMethodSuccess: boolean
  activityLogSuccess: boolean
  activityLogError: boolean
  activityLog: any[]
  adminActivityLogSuccess: boolean,
  adminActivityLogError: boolean,
  adminActivityLog: any[],
  totalActivityLogRow: number
  success: Boolean
  boatServicePendingBoats: any[]
  deleteMarinaSuccess: boolean
  deleteMarinaError: boolean
  totalBoatServicePendingBoats: number
  totalPendingMarinaAndStorages: any
  totalPendingRentalBoats: any
  totalPendingBoats: any
  error: Boolean
  deleteYatchSuccess: boolean
  deleteYatchError: boolean
  isLoading: Boolean
  singleSellerPendingBoats: any[]
  pendingRentalBoats: any[]
  pendingMarinaAndStorages: any[]
  statusSuccess: Boolean
  statusError: Boolean
  totalAdminRow: any
  totalBoatByType: any
  boatIsLoading: Boolean
  boatSuccess: Boolean
  boatError: Boolean
  boats: any[]
  deleteSuccess: boolean
  deleteError: boolean
  changeRentStatusSuccess: boolean
  changeRentStatusError: boolean
  marinaSuccess: boolean
  marinaError: boolean
  allMarina: any[]
  totalMarinaRow: number
  rentSuccess: boolean
  rentError: boolean
  allRent: any[]
  totalRentRow: number
  boatServiceSuccess: boolean
  boatServiceError: boolean
  allBoatService: any[]
  totalBoatServiceRow: number
  moreServiceSuccess: boolean
  moreServiceError: boolean
  moreBoatService: any[]
  totalMoreBoatServiceRow: number
  liveExperienceSuccess: boolean
  liveExperienceError: boolean
  liveExperienceMarina: any[]
  totalLiveExperienceMarinaRow: number
  nearestMarinaSuccess: boolean
  nearestMarinaError: boolean
  nearestMarina: any[]
  totalNearestMarinaRow: number
  mostPopularSuccess: boolean
  mostPopularError: boolean
  mostPopularMarina: any[]
  totalMostPopularMarinaRow: number
  nearestBoatYachtSuccess: boolean
  nearestBoatYachtError: boolean
  nearestBoatYacht: any[]
  totalNearestBoatYachtRow: number
  topRatedYachtSuccess: boolean
  topRatedYachtError: boolean
  topRatedYacht: any[]
  totalTopRatedYachtRow: number
  liveExperienceRentSuccess: boolean
  liveExperienceRentError: boolean
  liveExperienceRent: any[]
  totalLiveExperienceRentRow: number
  mostPopularRentSuccess: boolean
  mostPopularRentError: boolean
  mostPopularRent: any[]
  totalMostPopularRentRow: number
  recommendedRentBoatSuccess: boolean
  recommendedRentBoatError: boolean
  recommendedRentBoat: any[]
  changeYatchStatusError: boolean
  changeYatchStatusSuccess: boolean
  totalRecommendedRentBoatRow: number
  changeYatchNullStatusError: boolean
  changeYatchNullStatusSuccess: boolean
  changeMarinaNullStatusError: boolean
  changeMarinaNullStatusSuccess: boolean
  changeBoatNullStatusError: boolean
  changeBoatNullStatusSuccess: boolean
  changeRentNullStatusError: boolean
  changeRentNullStatusSuccess: boolean
  rentPaymentsReceivers: EmptyState
  rentPaymentsReceiverBoats: EmptyState
  rentPaymentsBoatSlots: EmptyState
  rentPaymentsBoatSlotBookings: EmptyState
  rentPaymentsPrivateBoatSlots: EmptyState
  rentPaymentsReleaseIntent: EmptyState
  rentPaymentsRefundIntent: EmptyState
  reportedAds: EmptyState
  adReports: EmptyState
  reportedReviews: EmptyState
  reviewReports: EmptyState
  deletedReview: EmptyState
}

interface getSingleSellerBoats {
  type: typeof GET_SINGLE_SELLER_BOATS
  payload: String
}

interface getSingleSellerBoatsSuccess {
  type: typeof GET_SINGLE_SELLER_BOATS_SUCCESS
  payload: any
}

interface getSingleSellerBoatsFailure {
  type: typeof GET_SINGLE_SELLER_BOATS_FAILURE
}

interface getActivityLogs {
  type: typeof GET_ACTIVITY_LOGS
  payload: any
}

interface getActivityLogsSuccess {
  type: typeof GET_ACTIVITY_LOGS_SUCCESS
  payload: any
}

interface getActivityLogsFailure {
  type: typeof GET_ACTIVITY_LOGS_FAILURE
}

interface getAdminActivityLogs {
  type: typeof GET_ADMIN_ACTIVITY_LOGS
  payload: any
}

interface getAdminActivityLogsSuccess {
  type: typeof GET_ADMIN_ACTIVITY_LOGS_SUCCESS
  payload: any
}

interface getAdminActivityLogsFailure {
  type: typeof GET_ADMIN_ACTIVITY_LOGS_FAILURE
}

interface getPendingBoatServices {
  type: typeof GET_PENDING_BOAT_SERVICE
  payload: any
}

interface getPendingBoatServicesSuccess {
  type: typeof GET_PENDING_BOAT_SERVICE_SUCCESS
  payload: any
}

interface getPendingBoatServicesFailure {
  type: typeof GET_PENDING_BOAT_SERVICE_FAILURE
}

interface clearPendingAdsFlags {
  type: typeof CLEAR_PENDING_ADS_FLAG
}

///change approval status types

interface changeApprovalStatus {
  type: typeof CHANGE_APPROVAL_STATUS
  payload: any
}

interface changeApprovalStatusSuccess {
  type: typeof CHANGE_APPROVAL_STATUS_SUCCESS
  payload: any
}

interface changeApprovalStatusFailure {
  type: typeof CHANGE_APPROVAL_STATUS_FAILURE
}

//get pending boats
interface getPendingBoatRents {
  type: typeof GET_PENDING_RENTAL_BOATS
  payload: any
}

interface getPendingBoatRentsSuccess {
  type: typeof GET_PENDING_RENTAL_BOATS_SUCCESS
  payload: any
}

interface getPendingBoatRentsFailure {
  type: typeof GET_PENDING_RENTAL_BOATS_FAILURE
}

//get pending marinas
interface getPendingMarinaStorage {
  type: typeof GET_ALL_PENDING_MARINA_STORAGE
  payload: any
}

interface getPendingMarinaStorageSuccess {
  type: typeof GET_ALL_PENDING_MARINA_STORAGE_SUCCESS
  payload: any
}

interface getPendingMarinaStorageFailure {
  type: typeof GET_ALL_PENDING_MARINA_STORAGE_FAILURE
  payload: any
}

//change marina status

interface changeMarinaStatus {
  type: typeof CHANGE_MARINA_STATUS
  payload: any
}
interface changeMarinaStatusSuccess {
  type: typeof CHANGE_MARINA_STATUS_SUCCESS
  payload: any
}
interface changeMarinaStatusFailure {
  type: typeof CHANGE_MARINA_STATUS_FAILURE
  payload: any
}

// change rent status

interface changeRentStatus {
  type: typeof CHANGE_RENTAL_BOAT_STATUS
  payload: any
}
interface changeRentStatusSuccess {
  type: typeof CHANGE_RENTAL_BOAT_STATUS_SUCCESS
  payload: any
}
interface changeRentStatusFailure {
  type: typeof CHANGE_RENTAL_BOAT_STATUS_FAILURE
  payload: any
}

interface getBoats {
  type: typeof GET_BOATS
}

interface getBoatSuccess {
  type: typeof GET_BOATS_SUCCESS
  payload: any
}

interface getBoatFailure {
  type: typeof GET_BOATS_FAILURE
}

interface deleteBoats {
  type: typeof DELETE_BOATS
}

interface deleteBoatsSuccess {
  type: typeof DELETE_BOATS_SUCCESS
  payload: any
}

interface deleteBoatsFailure {
  error: any
  type: typeof DELETE_BOATS_FAILURE
}

interface deleteYatchServiceBoats {
  type: typeof DELETE_YATCH_SERVICE_BOATS
}

interface deleteYatchServiceBoatsSuccess {
  type: typeof DELETE_YATCH_SERVICE_BOATS_SUCCESS
  payload: any
}

interface deleteYatchServiceBoatsFailure {
  type: typeof DELETE_YATCH_SERVICE_BOATS_FAILURE
}

interface deleteMarinaBoats {
  type: typeof DELETE_MARINA_BOATS
}

interface deleteMarinaBoatsSuccess {
  type: typeof DELETE_MARINA_BOATS_SUCCESS
  payload: any
}

interface deleteMarinaBoatsFailure {
  type: typeof DELETE_MARINA_BOATS_FAILURE
}

interface clearBoatFlag {
  type: typeof CLEAR_BOATS_FLAG
}

interface getMarina {
  type: typeof GET_ALL_MARINA
}

interface getMarinaSuccess {
  type: typeof GET_ALL_MARINA_SUCCESS
  payload: any
}

interface getMarinaFailure {
  type: typeof GET_ALL_MARINA_FAILURE
}

interface getRent {
  type: typeof GET_ALL_RENT
}

interface getRentSuccess {
  type: typeof GET_ALL_RENT_SUCCESS
  payload: any
}

interface getRentFailure {
  type: typeof GET_ALL_RENT_FAILURE
}
interface getBoatService {
  type: typeof GET_ALL_BOAT_SERVICE
}

interface getBoatServiceSuccess {
  type: typeof GET_ALL_BOAT_SERVICE_SUCCESS
  payload: any
}

interface getBoatServiceFailure {
  type: typeof GET_ALL_BOAT_SERVICE_FAILURE
}

interface getMarinaMoreService {
  type: typeof GET_MARINA_MORE_SERVICE
}

interface getMarinaMoreServiceSuccess {
  type: typeof GET_MARINA_MORE_SERVICE_SUCCESS
  payload: any
}

interface getMarinaMoreServiceFailure {
  type: typeof GET_MARINA_MORE_SERVICE_FAILURE
}

interface getMarinaNearest {
  type: typeof GET_NEAREST_MARINA
}

interface getMarinaNearestSuccess {
  type: typeof GET_NEAREST_MARINA_SUCCESS
  payload: any
}

interface getMarinaNearestFailure {
  type: typeof GET_NEAREST_MARINA_FAILURE
}

interface getMostPopularMarina {
  type: typeof GET_MOST_POPULAR_MARINA
}

interface getMostPopularMarinaSuccess {
  type: typeof GET_MOST_POPULAR_MARINA_SUCCESS
  payload: any
}

interface getMostPopularMarinaFailure {
  type: typeof GET_MOST_POPULAR_MARINA_FAILURE
}

interface getMarinaLiveExperience {
  type: typeof GET_LIVE_EXPERIENCE_MARINA
}

interface getMarinaLiveExperienceSuccess {
  type: typeof GET_LIVE_EXPERIENCE_MARINA_SUCCESS
  payload: any
}

interface getMarinaLiveExperienceFailure {
  type: typeof GET_LIVE_EXPERIENCE_MARINA_FAILURE
}
interface getNearestBoatService {
  type: typeof GET_NEAREST_BOAT_SERVICE
}

interface getNearestBoatServiceSuccess {
  type: typeof GET_NEAREST_BOAT_SERVICE_SUCCESS
  payload: any
}

interface getNearestBoatServiceFailure {
  type: typeof GET_NEAREST_BOAT_SERVICE_FAILURE
}

interface getTopRatedBoatService {
  type: typeof GET_TOP_RATED_BOAT_SERVICE
}

interface getTopRatedBoatServiceSuccess {
  type: typeof GET_TOP_RATED_BOAT_SERVICE_SUCCESS
  payload: any
}

interface getTopRatedBoatServiceFailure {
  type: typeof GET_TOP_RATED_BOAT_SERVICE_FAILURE
}

interface getRecommendedRent {
  type: typeof GET_RECOMMENDED_RENT
}

interface getRecommendedRentSuccess {
  type: typeof GET_RECOMMENDED_RENT_SUCCESS
  payload: any
}

interface getRecommendedRentFailure {
  type: typeof GET_RECOMMENDED_RENT_FAILURE
}

interface getLiveExperienceRent {
  type: typeof GET_LIVE_EXPERIENCE_RENT
}

interface getLiveExperienceRentSuccess {
  type: typeof GET_LIVE_EXPERIENCE_RENT_SUCCESS
  payload: any
}

interface getLiveExperienceRentFailure {
  type: typeof GET_LIVE_EXPERIENCE_RENT_FAILURE
}

interface getMostPopularRent {
  type: typeof GET_MOST_POPULAR_RENT
}

interface getMostPopularRentSuccess {
  type: typeof GET_MOST_POPULAR_RENT_SUCCESS
  payload: any
}

interface getMostPopularRentFailure {
  type: typeof GET_MOST_POPULAR_RENT_FAILURE
}

interface changeYatchServiceStatus {
  type: typeof CHANGE_YATCH_SERVICE_STATUS
  payload: any
}

interface changeYatchServiceStatusSuccess {
  type: typeof CHANGE_YATCH_SERVICE_STATUS_SUCCESS
  payload: any
}

interface changeYatchServiceStatusFailure {
  type: typeof CHANGE_YATCH_SERVICE_STATUS_FAILURE
}

// payments
interface createPaymentMethods {
  type: typeof CREATE_PAYMENT_METHODS
  payload: any
}

interface createPaymentMethodsSuccess {
  type: typeof CREATE_PAYMENT_METHODS_SUCCESS
  payload: any
}

interface createPaymentMethodsFailure {
  type: typeof CREATE_PAYMENT_METHODS_FAILURE
  payload: any
}

interface getAllPaymentMethods {
  type: typeof GET_PAYMENT_METHODS
  payload: any
}

interface getAllPaymentMethodsSuccess {
  type: typeof GET_PAYMENT_METHODS_SUCCESS
  payload: any
}

interface getAllPaymentMethodsFailure {
  type: typeof GET_PAYMENT_METHODS_FAILURE
  payload: any
}

interface clearManagePaymentMethodsFlag {
  type: typeof CLEAR_PAYMENT_METHODS
  payload: any
}

interface updatePaymentMethods {
  type: typeof UPDATE_PAYMENT_METHODS
  payload: any
}

interface updatePaymentMethodsSuccess {
  type: typeof UPDATE_PAYMENT_METHODS_SUCCESS
  payload: any
}

interface updatePaymentMethodsFailure {
  type: typeof UPDATE_PAYMENT_METHODS_FAILURE
  payload: any
}

interface editPaymentMethods {
  type: typeof UPDATE_PAYMENT_METHODS
  payload: string
}

interface editPaymentMethodsSuccess {
  type: typeof UPDATE_PAYMENT_METHODS_SUCCESS
  payload: string
}

interface editPaymentMethodsFailure {
  type: typeof UPDATE_PAYMENT_METHODS_FAILURE
  payload: any
}

interface deletePaymentMethods {
  type: typeof DELETE_PAYMENT_METHODS
  payload: string
}

interface deletePaymentMethodsSuccess {
  type: typeof DELETE_PAYMENT_METHODS_SUCCESS
  payload: any
}

interface deletePaymentMethodsFailure {
  type: typeof DELETE_PAYMENT_METHODS_FAILURE
  payload: any
}

interface getRentPaymentsReceivers {
  type: typeof GET_RENT_PAYMENTS_RECEIVERS
}

interface getRentPaymentsReceiversSuccess {
  type: typeof GET_RENT_PAYMENTS_RECEIVERS_SUCCESS
  payload: any[]
}

interface getRentPaymentsReceiversFailure {
  type: typeof GET_RENT_PAYMENTS_RECEIVERS_FAILURE
}

interface clearRentPaymentsReceiversFlag {
  type: typeof CLEAR_RENT_PAYMENTS_RECEIVERS_FLAG
}

interface getRentPaymentsReceiverBoats {
  type: typeof GET_RENT_PAYMENTS_RECEIVER_BOATS
}

interface getRentPaymentsReceiverBoatsSuccess {
  type: typeof GET_RENT_PAYMENTS_RECEIVER_BOATS_SUCCESS
  payload: any[]
}

interface getRentPaymentsReceiverBoatsFailure {
  type: typeof GET_RENT_PAYMENTS_RECEIVER_BOATS_FAILURE
}

interface clearRentPaymentsReceiverBoatsFlag {
  type: typeof CLEAR_RENT_PAYMENTS_RECEIVER_BOATS_FLAG
}

interface getRentPaymentsBoatSlots {
  type: typeof GET_RENT_PAYMENTS_BOAT_SLOTS
}

interface getRentPaymentsBoatSlotsSuccess {
  type: typeof GET_RENT_PAYMENTS_BOAT_SLOTS_SUCCESS
  payload: any[]
}

interface getRentPaymentsBoatSlotsFailure {
  type: typeof GET_RENT_PAYMENTS_BOAT_SLOTS_FAILURE
}

interface clearRentPaymentsBoatSlotsFlag {
  type: typeof CLEAR_RENT_PAYMENTS_BOAT_SLOTS_FLAG
}

interface getRentPaymentsBoatSlotBookings {
  type: typeof GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS
}

interface getRentPaymentsBoatSlotBookingsSuccess {
  type: typeof GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_SUCCESS
  payload: any[]
}

interface getRentPaymentsBoatSlotBookingsFailure {
  type: typeof GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FAILURE
}

interface clearRentPaymentsBoatSlotBookingsFlag {
  type: typeof CLEAR_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FLAG
}

interface getRentPaymentsPrivateBoatSlots {
  type: typeof GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS
}

interface getRentPaymentsPrivateBoatSlotsSuccess {
  type: typeof GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_SUCCESS
  payload: any[]
}

interface getRentPaymentsPrivateBoatSlotsFailure {
  type: typeof GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FAILURE
}

interface clearRentPaymentsPrivateBoatSlotsFlag {
  type: typeof CLEAR_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FLAG
}

interface rentPaymentsReleaseIntent {
  type: typeof RENT_PAYMENTS_RELEASE_INTENT
  payload: any
}

interface rentPaymentsReleaseIntentSuccess {
  type: typeof RENT_PAYMENTS_RELEASE_INTENT_SUCCESS
  payload: any
}

interface rentPaymentsReleaseIntentFailure {
  type: typeof RENT_PAYMENTS_RELEASE_INTENT_FAILURE
  payload: any
}

interface clearrentPaymentsReleaseIntentFlag {
  type: typeof CLEAR_RENT_PAYMENTS_RELEASE_INTENT_FLAG
}

interface rentPaymentsRefundIntent {
  type: typeof RENT_PAYMENTS_REFUND_INTENT
  payload: any
}

interface rentPaymentsRefundIntentSuccess {
  type: typeof RENT_PAYMENTS_REFUND_INTENT_SUCCESS
  payload: any
}

interface rentPaymentsRefundIntentFailure {
  type: typeof RENT_PAYMENTS_REFUND_INTENT_FAILURE
  payload: any
}

interface clearrentPaymentsRefundIntentFlag {
  type: typeof CLEAR_RENT_PAYMENTS_REFUND_INTENT_FLAG
}

interface searchBoat {
  type: typeof SEARCH_BOAT
  payload: any
}
interface searchBoatSuccess {
  type: typeof SEARCH_BOAT_SUCCESS
  payload: any
}

interface searchBoatFailure {
  type: typeof SEARCH_BOAT_FAILURE
}

interface searchBoatService {
  type: typeof SEARCH_BOAT_SEARVICE
  payload: any
}
interface searchBoatServiceSuccess {
  type: typeof SEARCH_BOAT_SEARVICE_SUCCESS
  payload: any
}

interface searchBoatServiceFailure {
  type: typeof SEARCH_BOAT_SEARVICE_FAILURE
}

interface getReportedAds {
  type: typeof GET_REPORTED_ADS
  payload: any
}
interface getReportedAdsSuccess {
  type: typeof GET_REPORTED_ADS_SUCCESS
  payload: any
}
interface getReportedAdsFailure {
  type: typeof GET_REPORTED_ADS_FAILURE
}
interface clear_getReportedAds {
  type: typeof CLEAR__GET_REPORTED_ADS
}

interface getAdReports {
  type: typeof GET_AD_REPORTS
  payload: any
}
interface getAdReportsSuccess {
  type: typeof GET_AD_REPORTS_SUCCESS
  payload: any
}
interface getAdReportsFailure {
  type: typeof GET_AD_REPORTS_FAILURE
}
interface clear_getAdReports {
  type: typeof CLEAR__GET_AD_REPORTS
}

interface getReportedReviews {
  type: typeof GET_REPORTED_REVIEWS
  payload: any
}
interface getReportedReviewsSuccess {
  type: typeof GET_REPORTED_REVIEWS_SUCCESS
  payload: any
}
interface getReportedReviewsFailure {
  type: typeof GET_REPORTED_REVIEWS_FAILURE
}
interface clear_getReportedReviews {
  type: typeof CLEAR__GET_REPORTED_REVIEWS
}

interface getReviewReports {
  type: typeof GET_REVIEW_REPORTS
  payload: any
}
interface getReviewReportsSuccess {
  type: typeof GET_REVIEW_REPORTS_SUCCESS
  payload: any
}
interface getReviewReportsFailure {
  type: typeof GET_REVIEW_REPORTS_FAILURE
}
interface clear_getReviewReports {
  type: typeof CLEAR__GET_REVIEW_REPORTS
}

interface deleteReview {
  type: typeof DELETE_REVIEW
  payload: any
}
interface deleteReviewSuccess {
  type: typeof DELETE_REVIEW_SUCCESS
  payload: any
}
interface deleteReviewFailure {
  type: typeof DELETE_REVIEW_FAILURE
}
interface clear_deleteReview {
  type: typeof CLEAR__DELETE_REVIEW
}

export type ActionType =
  | createPaymentMethods
  | createPaymentMethodsSuccess
  | createPaymentMethodsFailure
  | getAllPaymentMethods
  | getAllPaymentMethodsSuccess
  | getAllPaymentMethodsFailure
  | clearManagePaymentMethodsFlag
  | updatePaymentMethods
  | updatePaymentMethodsSuccess
  | updatePaymentMethodsFailure
  | editPaymentMethods
  | editPaymentMethodsSuccess
  | editPaymentMethodsFailure
  | deletePaymentMethods
  | deletePaymentMethodsSuccess
  | deletePaymentMethodsFailure
  | getSingleSellerBoats
  | getSingleSellerBoatsSuccess
  | getSingleSellerBoatsFailure
  | clearPendingAdsFlags
  | changeApprovalStatus
  | changeApprovalStatusSuccess
  | changeApprovalStatusFailure
  | getPendingBoatRents
  | getPendingBoatRentsSuccess
  | getPendingBoatRentsFailure
  | getPendingMarinaStorage
  | getPendingMarinaStorageSuccess
  | getPendingMarinaStorageFailure
  | changeMarinaStatus
  | changeMarinaStatusSuccess
  | changeMarinaStatusFailure
  | getBoats
  | getBoatSuccess
  | getBoatFailure
  | clearBoatFlag
  | deleteBoats
  | deleteBoatsSuccess
  | deleteBoatsFailure
  | changeRentStatus
  | changeRentStatusSuccess
  | changeRentStatusFailure
  | getMarina
  | getMarinaSuccess
  | getMarinaFailure
  | getRent
  | getRentSuccess
  | getRentFailure
  | getBoatService
  | getBoatServiceSuccess
  | getBoatServiceFailure
  | getMarinaMoreService
  | getMarinaMoreServiceSuccess
  | getMarinaMoreServiceFailure
  | getMarinaNearest
  | getMarinaNearestSuccess
  | getMarinaNearestFailure
  | getMarinaLiveExperience
  | getMarinaLiveExperienceSuccess
  | getMarinaLiveExperienceFailure
  | getNearestBoatService
  | getNearestBoatServiceSuccess
  | getNearestBoatServiceFailure
  | getTopRatedBoatService
  | getTopRatedBoatServiceSuccess
  | getTopRatedBoatServiceFailure
  | getMostPopularMarina
  | getMostPopularMarinaSuccess
  | getMostPopularMarinaFailure
  | getMostPopularRent
  | getMostPopularRentSuccess
  | getMostPopularRentFailure
  | getLiveExperienceRent
  | getLiveExperienceRentSuccess
  | getLiveExperienceRentFailure
  | getRecommendedRent
  | getRecommendedRentSuccess
  | getRecommendedRentFailure
  | changeYatchServiceStatus
  | changeYatchServiceStatusSuccess
  | changeYatchServiceStatusFailure
  | getPendingBoatServices
  | getPendingBoatServicesSuccess
  | getPendingBoatServicesFailure
  | deleteYatchServiceBoats
  | deleteYatchServiceBoatsSuccess
  | deleteYatchServiceBoatsFailure
  | deleteMarinaBoats
  | deleteMarinaBoatsSuccess
  | deleteMarinaBoatsFailure
  | getActivityLogs
  | getActivityLogsSuccess
  | getActivityLogsFailure
  | getAdminActivityLogs
  | getAdminActivityLogsSuccess
  | getAdminActivityLogsFailure
  | getRentPaymentsReceivers
  | getRentPaymentsReceiversSuccess
  | getRentPaymentsReceiversFailure
  | clearRentPaymentsReceiversFlag
  | getRentPaymentsReceiverBoats
  | getRentPaymentsReceiverBoatsSuccess
  | getRentPaymentsReceiverBoatsFailure
  | clearRentPaymentsReceiverBoatsFlag
  | getRentPaymentsBoatSlots
  | getRentPaymentsBoatSlotsSuccess
  | getRentPaymentsBoatSlotsFailure
  | clearRentPaymentsBoatSlotsFlag
  | getRentPaymentsBoatSlotBookings
  | getRentPaymentsBoatSlotBookingsSuccess
  | getRentPaymentsBoatSlotBookingsFailure
  | clearRentPaymentsBoatSlotBookingsFlag
  | getRentPaymentsPrivateBoatSlots
  | getRentPaymentsPrivateBoatSlotsSuccess
  | getRentPaymentsPrivateBoatSlotsFailure
  | clearRentPaymentsPrivateBoatSlotsFlag
  | rentPaymentsReleaseIntent
  | rentPaymentsReleaseIntentSuccess
  | rentPaymentsReleaseIntentFailure
  | clearrentPaymentsReleaseIntentFlag
  | rentPaymentsRefundIntent
  | rentPaymentsRefundIntentSuccess
  | rentPaymentsRefundIntentFailure
  | clearrentPaymentsRefundIntentFlag
  | searchBoat
  | searchBoatSuccess
  | searchBoatFailure
  | searchBoatService
  | searchBoatServiceSuccess
  | searchBoatServiceFailure
  | getReportedAds
  | getReportedAdsSuccess
  | getReportedAdsFailure
  | clear_getReportedAds
  | getAdReports
  | getAdReportsSuccess
  | getAdReportsFailure
  | clear_getAdReports
  | getReportedReviews
  | getReportedReviewsSuccess
  | getReportedReviewsFailure
  | clear_getReportedReviews
  | getReviewReports
  | getReviewReportsSuccess
  | getReviewReportsFailure
  | clear_getReviewReports
  | deleteReview
  | deleteReviewSuccess
  | deleteReviewFailure
  | clear_deleteReview
