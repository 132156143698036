import gql from 'graphql-tag'
import { AddressType } from '../schemaTypes'

export const getUsersByCountry = gql`
    query getAllUsers($page: Int, $limit: Int, $roleType: String!, $country: String, $userStatus: Boolean, $accountActivated: Boolean, $isVerified: Boolean, $reqId: String){
      getAllUsers(page: $page, limit: $limit, roleType: $roleType, country: $country, userStatus: $userStatus, accountActivated: $accountActivated, isVerified: $isVerified, reqId: $reqId){
            items {
                id
                firstName
                lastName
                token
                email
                mobileNumber
                isVerified
                address ${AddressType}
                userStatus
                role{
                    id
                    role
                }
                branchList {
                  branchName
                }
                accountActivated
                createdAt
                companyName
                agreementId
                documentVerification {
                    governmentIdVerified
                    commercialLicenceVerified
                }
                commercialLicence {
                  id
                  url
                }
                permissions
                governmentId {
                  id
                  url
                  documentType {
                    alias
                    id
                    lookUpName
                    isActive
                  }
                  meta
                }
                branch {
                  branchName
                }
            }
            total
        }
    }`

export const createUser = gql`
  mutation createUser($input: UserCreateInput!, $isUpdate: Boolean) {
    createUser(input: $input, isUpdate: $isUpdate) {
      id
      firstName
      lastName
      email
      address {
        country
      }
      mobileNumber
    }
  }
`

export const deleteUser = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const flagReportList = gql`
  query flagReportList(
    $limit: Int
    $page: Int
    $boatType: String
    $country: String
    $status: Boolean
    $adStatus: Boolean
    $serviceProvide: String
    $provider: String
    $tripType: String
    $moduleId: String
    $moduleType: MODULETYPE
    $rating: Int
    $reportType: String
    $id: String
    $isAdmin: Boolean = true
  ) {
    flagReportList(
      limit: $limit
      page: $page
      boatType: $boatType
      country: $country
      status: $status
      adStatus: $adStatus
      serviceProvide: $serviceProvide
      provider: $provider
      tripType: $tripType
      moduleId: $moduleId
      moduleType: $moduleType
      rating: $rating
      reportType: $reportType
      id: $id
      isAdmin: $isAdmin
    ) {
      items {
        id
        moduleId
        moduleType
        reportType
        message
        country
        user {
          firstName
          lastName
          role {
            role
          }
        }
        boat {
          boatName
        }
        boatRent {
          boatName
        }
        boatShow {
          title
        }
        marina {
          facilities
        }
        yacht {
          user {
            companyName
          }
        }
        createdAt
        rating
      }
      total
    }
  }
`

export const searchUsers = gql`
    query searchUsers ($searchTerm: String! = "", $limit: Int, $page: Int, $roleType: String ) {
        searchUsers(searchTerm: $searchTerm, limit: $limit, page: $page, roleType: $roleType) {
          items {
            id
          firstName
          lastName
          token
          email
          mobileNumber
          address ${AddressType}
          isVerified
          userStatus
          accountActivated
          role{
              id
              role
          }
          branchList {
            branchName
          }
          createdAt
          companyName
          agreementId
          documentVerification {
              governmentIdVerified
              commercialLicenceVerified
          }
          commercialLicence {
            id
            url
          }
          governmentId {
            id
            url
            documentType {
              alias
              id
              lookUpName
              isActive
            }
            meta
          }
          branch {
            branchName
          }
          }
          total
        }
    }
`
