import { Tabs } from 'antd'
import * as React from 'react'

const { TabPane } = Tabs

function callback(key: any) {
  console.log(key)
}

const SearchTabs = (props: any) => {
  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="Admin User" key="1">
        Admin User
      </TabPane>
      <TabPane tab="Front User" key="2">
        Front User
      </TabPane>
      <TabPane tab="Agreements" key="3">
        Agreements
      </TabPane>
      <TabPane tab="Boats" key="4">
        Boats
      </TabPane>
    </Tabs>
  )
}
export default SearchTabs
