import gql from 'graphql-tag'

export const createEmailTemplate = gql`
  mutation createTemplate($input: TemplateInput!) {
    createTemplate(input: $input) {
      id
      type
      body
      updatedAt
      subject
    }
  }
`

export const getAllEmailTemplates = gql`
  query getEmailTemplates($page: Int, $limit: Int, $searchTerm: String) {
    getEmailTemplates(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        id
        subject
        smtp {
          id
          email
        }
        name
        type
        body
        createdAt
        updatedAt
      }
      total
    }
  }
`

export const updateTemplate = gql`
  mutation updateTemplate($input: TemplateInput!) {
    updateTemplate(input: $input) {
      id
      subject
      type
      body
    }
  }
`

export const deleteEmailTemplate = gql`
  mutation deleteTemplate($id: String!) {
    deleteTemplate(id: $id) {
      id
      subject
      type
      body
      updatedAt
    }
  }
`

export const getVariables = gql`
  query getVariables($template: TEMPLATENAME) {
    getVariables(template: $template) {
      id
      name
      aliasName
      template
    }
  }
`
