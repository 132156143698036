import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Divider, Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { agreementInput, AgreementUserType } from '../../store/manageAgreements/Types'
import { createAgreement, updateAgreement, clearAgreementFlag } from '../../store/manageAgreements/Action'
import History from '../../History'
import { mobileNumberFormat, isEmptyObj, trimmedValue } from '../../helpers/helper'
import CountrySelector from '../../component/CountrySelector'
import WSIcon from '../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import WSField from '../../component/Field'
import { Redirect } from 'react-router-dom'

const { Text } = Typography

interface Props {
  title: string
  match?: any
  validType: AgreementUserType
  roles: any[]
  location: any
  createAgreement: typeof createAgreement
  updateAgreement: typeof updateAgreement
  agreementCreateSuccess: boolean
  agreementUpdateSuccess: boolean
  history: typeof History
  clearAgreementFlag: typeof clearAgreementFlag
}
export interface managePermission {
  manageSalesEngineProcess: boolean
  manageArchiveEngineProcess: boolean
  manageSalesReview: boolean
}
interface State {
  editAgreements: any
  manageAgreements: agreementInput
  getRole: any
  invalidType: boolean
}

class CreateAgreements extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      invalidType: false,
      getRole: {},
      manageAgreements: {
        country: '',
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        companyWebsite: '',
        mobileNumber: '',
        userType: this.props.validType,
      },
      editAgreements:
        props.location.state && props.location.state.agreements && props.location.state.agreements.record
          ? {
              ...props.location.state.agreements.record,
              userType: AgreementUserType.builder,
            }
          : null,
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (nextProps, prevState) => {
    const {
      validType,
      agreementCreateSuccess,
      agreementUpdateSuccess,
      history,
      clearAgreementFlag,
      roles,
      location,
    } = nextProps

    let { manageAgreements, getRole } = prevState
    if (isEmptyObj(getRole)) {
      return {
        getRole: roles.find((item: any) => location.state.agreements.role === item.role),
      }
    }

    if (agreementUpdateSuccess || agreementCreateSuccess) {
      clearAgreementFlag()
      switch (manageAgreements.userType) {
        case 'builder':
          history.push('/builder-agreements')
          break
        case 'surveyor':
          history.push('/surveyor-agreements')
          break
        case 'shipment':
          history.push('/shipment-agreements')
          break

        default:
          break
      }
    } else if (validType) {
      switch (validType) {
        case 'builder':
          manageAgreements.userType = AgreementUserType.builder
          break
        case 'surveyor':
          manageAgreements.userType = AgreementUserType.surveyor
          break
        case 'shipment':
          manageAgreements.userType = AgreementUserType.shipment
          break

        default:
          break
      }

      return {
        manageAgreements,
      }
    }

    return null
  }

  formSubmitHandler = (values: any) => {
    const { editAgreements, manageAgreements } = this.state
    const { createAgreement, updateAgreement } = this.props
    const { role, ...noRole } = values
    if (editAgreements) {
      updateAgreement({ ...noRole, userType: manageAgreements.userType })
    } else {
      createAgreement({ ...values })
    }
  }

  render() {
    const { editAgreements, manageAgreements } = this.state
    const { history } = this.props

    return (
      <Fragment>
        <Formik
          initialValues={editAgreements || manageAgreements}
          onSubmit={(values: any) => this.formSubmitHandler(values)}
          validationSchema={Yup.object().shape({
            companyName: Yup.string().required('Company name field is required.'),
            companyWebsite: Yup.string()
              .required('Company Website field is required.')
              .matches(
                /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Enter correct url!'
              ),
            firstName: Yup.string().required('First name field is required.'),
            lastName: Yup.string().required('Last name field is required.'),
            mobileNumber: Yup.string().required('Mobile number field is required.'),
            email: Yup.string().email().required('Email field is required.'),
            country: Yup.string().required('Country field is required.'),
          })}
        >
          {props => {
            const { handleSubmit, setFieldValue, values, errors } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} /> {'Add Agreements'}
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                        Send Agreement ID
                      </button>
                    </>
                  }
                >
                  <Row gutter={16}>
                    <h3 title="Contact Info">Contact Info</h3>
                  </Row>

                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Country">
                        Country
                      </span>
                      <CountrySelector
                        disabled={editAgreements}
                        value={values.country}
                        setValue={(data: any) => {
                          setFieldValue('country', data)
                        }}
                      />

                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback" name={`country`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Company Name">
                        Company Name
                      </span>
                      <Field
                        className="ant-input"
                        name="companyName"
                        placeholder="Company Name"
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('companyName', trimmedValue(e.target.value))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback" name={`companyName`} component="span" />
                      </Text>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <h3 title="Point of Contact">Point of Contact</h3>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="First Name">
                        First Name
                      </span>
                      <Field
                        className="ant-input"
                        name="firstName"
                        placeholder="First Name"
                        onBlur={(e: { target: { value: string } }) => setFieldValue('firstName', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`firstName`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Last Name">
                        Last Name
                      </span>
                      <Field
                        className="ant-input"
                        name="lastName"
                        placeholder="Last Name"
                        onBlur={(e: { target: { value: string } }) => setFieldValue('lastName', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback" name={`lastName`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Email">
                        Email
                      </span>
                      <Field disabled={editAgreements} className="ant-input" name="email" placeholder="Email" />

                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback" name={`email`} component="span" />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Company Website">
                        Company Website
                      </span>
                      <Field
                        className="ant-input"
                        name="companyWebsite"
                        placeholder="company Website"
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('companyWebsite', trimmedValue(e.target.value))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback " name={`companyWebsite`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Mobile No">
                        Mobile No
                      </span>

                      <WSField
                        mobDisabled={editAgreements}
                        countryField={values.country}
                        placeholder="Enter Mobile Number"
                        type="mobile-number"
                        name="mobileNumber"
                        value={values.mobileNumber}
                        onChangeText={(e: any) => {
                          mobileNumberFormat(e)
                          setFieldValue('mobileNumber', `+${e}`)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback " name={`mobileNumber`} component="span" />
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.roleState.roles,
  agreementCreateSuccess: state.agreementReducer && state.agreementReducer.agreementCreateSuccess,
  agreementUpdateSuccess: state.agreementReducer && state.agreementReducer.agreementUpdateSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createAgreement: (data: agreementInput) => dispatch(createAgreement(data)),
  updateAgreement: (data: any) => dispatch(updateAgreement(data)),
  clearAgreementFlag: () => dispatch(clearAgreementFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateAgreements)
