import gql from 'graphql-tag'

export const getCostEstimate = gql`
  query getCostEstimate {
    getCostEstimate
  }
`

export const createOrUpdateCostEstimate = gql`
  mutation createOrUpdateCostEstimate($input: CostEstimateInput!) {
    createOrUpdateCostEstimate(input: $input) {
      id
      adminFee
      payPalFee
      payPalFixedPrice
      payPalFeeOther
      estimatedCostNote
      bankAccountDetail
      sitePayPalEmail
      payPalUserName
      payPalPassword
      payPalSignature
      surveyorReportSubmissionDays
      stopSalesEngineDays
      daysOfAutoDeleteBoat
      daysCounterForInspection
      daysCounterInactiveBuyers
      daysCounterSurveyorRespond
    }
  }
`
