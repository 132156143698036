import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'

import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData, formateDate } from '../../../helpers/helper'
import { getAllBoatPagination, paginationPage, defaultImage } from '../../../enums/enums'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import { getAllBoatTypes, clearManageBoatTypesFlag, deleteBoatTypes } from '../../../store/staticContent/manageBoatTypes/Action'
import {
  getMarinaTypes,
  clearManageMarinaTypesFlag,
  deleteMarinaTypes,
} from '../../../store/staticContent/manageMarinaTypes/Action'
import {
  getAllYachtServiceTypes,
  clearManageYatchTypesFlag,
  deleteYatchTypes,
} from '../../../store/staticContent/manageYatchServiceTypes/Action'
import { clearRentTypeFlag, deleteRentType, getAllRentTypes } from '../../../store/staticContent/manageRentTypes/Actions'

interface Props {
  history: typeof History
  type: string
  deleteSuccess: boolean
  deleteAction: typeof deleteYatchTypes | typeof deleteRentType | typeof deleteMarinaTypes | typeof deleteBoatTypes
  pageTitle: string
  createPageLink: string
  createPageTitle: string
  listArray: any[]
  pageTotal: number
  listAction: typeof getAllBoatTypes | typeof getMarinaTypes | typeof getAllRentTypes | typeof getAllYachtServiceTypes
  clearFlagAction:
    | typeof clearManageBoatTypesFlag
    | typeof clearManageMarinaTypesFlag
    | typeof clearRentTypeFlag
    | typeof clearManageYatchTypesFlag
}

interface State {
  searchedValue: string
}
class ListCategoryIndex extends Component<Props, State> {
  state = {
    searchedValue: '',
  }
  // componentDidMount() {
  //   const { listAction } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   listAction(input)
  // }

  columns = [
    {
      title: 'Category Icon',
      dataIndex: 'thumbnailIcon',
      key: 'thumbnailIcon',
      render: (text: any) => (
        <img src={(text && text.url) || defaultImage.image} alt="category icon" className="category-icon"></img>
      ),
    },
    {
      title: 'Category Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string, record: tableRecords, index: number) => <>{formateDate(record && record.updatedAt)}</>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: any, index: number) => {
        return (
          <>
            <Button
              onClick={() => {
                this.editBoatTypes(record)
              }}
              className="btn-edit mr-2"
            >
              Edit
            </Button>
            <Button
              className="btn-delete"
              onClick={() => {
                record && record.id && confirmSubmitHandler(this.props.deleteAction, record.id)
              }}
            >
              Delete
            </Button>
          </>
        )
      },
    },
  ]

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = props => {
    const { clearFlagAction, deleteSuccess } = props

    if (deleteSuccess) {
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      props.listAction(input)
      clearFlagAction()
    }
    return null
  }

  editBoatTypes = (record: tableRecords) => {
    const { history, createPageLink, type } = this.props
    history.push(createPageLink, { [type]: record })
  }

  onSearch = (value: any) => {
    const { listAction } = this.props;

    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    };

    if (value) {
      this.setState({searchedValue: value})
      listAction({ ...input, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      listAction({ ...input })
    }
  }

  render() {
    const { listArray, listAction, pageTotal, createPageTitle, createPageLink, pageTitle } = this.props
    const { searchedValue } = this.state
    return (
      <Card
        title={pageTitle}
        extra={
          <div className="d-flex flex-wrap">
            <div className="mr-10 mt-8">
              <TitleSearch onSearch={this.onSearch} />
            </div>
            <Button className="btn-add mt-8">
              <Link to={createPageLink}>
                <WSIcon type={<PlusOutlined />} /> {createPageTitle}
              </Link>
            </Button>
          </div>
        }
      >
        <ExpandTable
          columns={this.columns}
          tableData={listArray}
          isExpand={false}
          totalUsersRow={pageTotal}
          pageAction={listAction}
          page={paginationPage.boat}
          extraInput={{ searchTerm: searchedValue }}
        />
      </Card>
    )
  }
}

export default ListCategoryIndex
