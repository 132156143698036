import S3 from 'aws-s3'
import { getLocalStorageItem, nameFormatter, splitUrlFromUnderscore } from './helper'
import { v1 as uuidv1 } from 'uuid'
import axios from 'axios'
import { MEDIA_TYPE } from '../enums/enums'
import { fileType, iconType, removeFileType } from '../store/login/Types'
import { websiteClient } from '..'
import { CREATE_META_RECORD, GET_PRESIGNED_URL } from './UploadSchema'
import NotificationWithIcon from '../component/notification/Notification'

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_DIR_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
}

const S3Client = new S3(config)
const S3ClientVideo = new S3({
  ...config,
  dirName: process.env.REACT_APP_DIR_NAME_VIDEO,
})

export default function uploadToS3(selectedFile: any) {
  return S3Client.uploadFile(selectedFile, (selectedFile && selectedFile.name) || '')
}

export function uploadToS3Video(selectedFile: any) {
  return S3ClientVideo.uploadFile(selectedFile)
    .then((data: any) => data)
    .catch((err: any) => {
      return err
    })
}

export const uploadFile = async (file: any, mediaType: any, siteFileKey: any, multiple = false, setUploadPerc: any = null) => {
  const allFiles: any[] = multiple ? file : [file]

  let images: any[] = []
  console.log('uploadFile')

  const largeVideoFiles = allFiles.some(f => /^video\//.test(f.type) && f.size > 512 * 1024 * 1024)

  if (largeVideoFiles) {
    NotificationWithIcon('error', `Video size must not exceed 512 MB.`)
    return []
  }

  await Promise.all(
    allFiles &&
      allFiles.map(async (singleFile: any) => {
        const mimeType = singleFile.type

        try {
          const {
            data: { fileUpload_getPresignedUrl: data_getPresignedUrl },
          } = await websiteClient.query({
            query: GET_PRESIGNED_URL,
            variables: { input: { type: mediaType, mime: mimeType, name: singleFile.name } },
          })

          const key = data_getPresignedUrl.key
          const presignedUrl = data_getPresignedUrl.url

          const options = {
            headers: {
              'Content-Type': mimeType,
              'Access-Control-Allow-Origin': '*',
            },
            crossDomain: true,
            onUploadProgress: (e: any) => {
              const { loaded, total } = e
              const percent = Math.floor((loaded * 100) / total)
              setUploadPerc?.(percent)
            },
          }

          await axios.put(presignedUrl, singleFile, options)

          const res = await websiteClient.mutate({
            mutation: CREATE_META_RECORD,
            variables: {
              input: {
                type: mediaType,
                mime: mimeType,
                key,
                size: singleFile.size,
              },
            },
          })

          images = [
            ...images,
            {
              _id: res.data.fileUpload_createMetaRecord,
              url: URL.createObjectURL(new Blob([singleFile], { type: mimeType })),
              key,
              type: mimeType,
              name: singleFile.name,
            },
          ]
        } catch (er) {
          console.error(er)
          NotificationWithIcon('error', 'Cannot upload file!')
        }
      })
  )

  return images
}

export const handleSingleFileUpload = async (
  file: any,
  name: any,
  setFieldValue: any,
  addMedia: any,
  mediaName: any,
  mediaType = MEDIA_TYPE.IMAGE
) => {
  if (file) {
    const res = await uploadFile(file, mediaType, null)

    res.length &&
      setFieldValue(name, {
        key: res[0].key,
        name: res[0].name,
        mediaName,
        mediaType,
        type: res[0].type,
      })

    res.length &&
      setFieldValue('addMedia', [
        ...addMedia,
        ...res.map(item => ({
          ...item,
          mediaName,
          mediaType,
        })),
      ])

    return res
  }
}

export const handleSingleFileDelete = async (
  value: any,
  name: any,
  setFieldValue: any,
  addMedia: any,
  valuesData: any,
  method: any,
  formVals?: any
) => {
  const filterData = valuesData && valuesData.length ? valuesData.filter((item: any) => item.id !== value.uid) : []
  console.log('valuesData')
  valuesData &&
    valuesData.length &&
    value &&
    value.uid &&
    setFieldValue('removeMedia', [...(formVals?.removeMedia || []), value.uid])
  setFieldValue(
    'addMedia',
    addMedia.filter((item: any) => item.key !== splitUrlFromUnderscore('_', value.url))
  )
  setFieldValue(name, filterData)
}

export const handleSingleFileUploadObj = async (
  file: fileType,
  name: string,
  setFieldValue: any,
  mediaName: string,
  mediaType = MEDIA_TYPE.IMAGE
) => {
  if (file) {
    const res = await uploadFile(file, mediaType, null)
    return (
      res.length &&
      setFieldValue(name, {
        key: res[0].key,
        name: res[0].name,
        mediaName,
        mediaType,
        type: res[0].type,
      })
    )
  }
}

export const handleSingleFileDeleteObj = async (
  value: removeFileType,
  name: string,
  setFieldValue: any,
  valuesData: iconType
) => {
  const filterData = valuesData && valuesData.length ? valuesData.filter((item: any) => item.id !== value.uid) : []
  setFieldValue(name, filterData)
}
