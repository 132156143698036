import React, { Component } from 'react'

import CreateBannerIndex from '../CreateBannerIndex'
import History from '../../../History'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  location: any
  history: typeof History
}

class CreateMarketMarina extends Component<Props> {
  render() {
    const { location, history } = this.props
    return (
      <CreateBannerIndex
        history={history}
        backLink="/marina-banners"
        location={location}
        pageTitle="Marina Banner"
        titlePage="Marina"
        type={mediaCategory.marinaStorage.type}
      />
    )
  }
}

export default CreateMarketMarina
