import { put, takeLatest, all } from 'redux-saga/effects'
import {
  ActionType,
  CREATE_FORGOT_PASSWORD,
  CREATE_FORGOT_PASSWORD_SUCCESS,
  CREATE_FORGOT_PASSWORD_FAILURE,
  GET_FORGOT_PASSWORD,
  GET_FORGOT_PASSWORD_SUCCESS,
  GET_FORGOT_PASSWORD_FAILURE,
} from './Type'
import { client } from '../../..'
import { createOrUpdateManagePasswordContent, getManagePasswordContent } from './Schema'

function createPasswordAPI(input: any) {
  return client
    .mutate({
      mutation: createOrUpdateManagePasswordContent,
      variables: { input },
    })
}

function getPassword() {
  return client
    .query({
      query: getManagePasswordContent,
      fetchPolicy: 'no-cache',
    })
}

function* createPasswordData(action: ActionType) {
  try {
    const res = yield createPasswordAPI(action.payload)
    yield put({ type: CREATE_FORGOT_PASSWORD_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_FORGOT_PASSWORD_FAILURE, error })
  }
}

function* getPasswordData() {
  try {
    const res = yield getPassword()
    yield put({ type: GET_FORGOT_PASSWORD_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_FORGOT_PASSWORD_FAILURE, error })
  }
}

function* createPasswordSaga() {
  yield takeLatest(CREATE_FORGOT_PASSWORD, createPasswordData)
}

function* getPasswordSaga() {
  yield takeLatest(GET_FORGOT_PASSWORD, getPasswordData)
}

export default function* homeAdvertisementsSaga() {
  yield all([createPasswordSaga(), getPasswordSaga()])
}
