import {
  adminOtherCountState,
  ActionType,
  GET_ADMIN_OTHER_COUNT,
  GET_ADMIN_OTHER_COUNT_SUCCESS,
  GET_ADMIN_OTHER_COUNT_FAILURE,
  GET_CURRENCY_RATES,
  GET_CURRENCY_RATES_SUCCESS,
  GET_CURRENCY_RATES_FAILURE,
  GET_DASHBOAD_VERIFICATION_ALERT,
  GET_DASHBOAD_VERIFICATION_ALERT_SUCCESS,
  GET_DASHBOAD_VERIFICATION_ALERT_FAILURE,
} from './Type'
import { adminOtherRole, getRoleCountPath } from '../../../helpers/helper'

const initialState: adminOtherCountState = {
  otherCounts: [],
  otherCountSuccess: false,
  otherCountFailure: false,
  currencyRates: {},
  alertCount: {
    pendingVerification: 0,
    pendingBoatAdsCount: 0,
    pendingBrokerAndDealerCount: 0,
    pendingMfgAdsCount: 0,
    pendingServiceAds: 0,
    pendingMarinaAds: 0,
    pendingRentAds: 0,
    pendingAgreementReq: 0,
    pendingSurveyorAgreement: 0,
    pendingShipperAgreement: 0,
    pendingBuilderAgreement: 0,
  }
}

export const adminOtherCountReducer = (state = initialState, action: ActionType): adminOtherCountState => {
  switch (action.type) {
    case GET_ADMIN_OTHER_COUNT:
      return {
        ...state,
        otherCountSuccess: false,
        otherCountFailure: false,
      }

    case GET_ADMIN_OTHER_COUNT_SUCCESS:
      return {
        ...state,
        otherCountSuccess: true,
        otherCountFailure: false,
        otherCounts: getRoleCountPath(action.payload.getAdminOtherCounts.roleCounts, adminOtherRole),
      }

    case GET_ADMIN_OTHER_COUNT_FAILURE:
      return {
        ...state,
        otherCountSuccess: false,
        otherCountFailure: true,
      }

    case GET_CURRENCY_RATES:
      return {
        ...state,
        currencyRates: {},
      }

    case GET_CURRENCY_RATES_SUCCESS:
      return {
        ...state,
        currencyRates: action.payload.data,
      }

    case GET_CURRENCY_RATES_FAILURE:
      return {
        ...state,
      }
    
    case GET_DASHBOAD_VERIFICATION_ALERT:
      return {
        ...state,
      }  

    case GET_DASHBOAD_VERIFICATION_ALERT_SUCCESS:
      return {
        ...state,
        alertCount: action.payload

      }  

    case GET_DASHBOAD_VERIFICATION_ALERT_FAILURE:
      return {
        ...state
      }  

    default:
      return state
  }
}
