import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch } from 'antd'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { Link } from 'react-router-dom'
import { getCanadianTax, deleteCanadianTax, changeTaxStatus } from '../../../store/salesEngine/canadianTax/Action'
import History from '../../../History'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'

export interface Props {
  getCanadianTax: typeof getCanadianTax
  canadianTax: []
  history: typeof History
  deleteCanadianTax: typeof deleteCanadianTax
  changeTaxStatus: typeof changeTaxStatus
}

export interface State {}

class CanadianTax extends Component<Props, State> {
  editTax = (record: tableRecords) => {
    const { history } = this.props
    history.push('/add-tex', { canadianTax: record })
  }

  state = {
    columns: [
      {
        title: 'Province',
        dataIndex: 'canadaProvince',
        key: 'canadaProvince',
      },
      {
        title: 'GST/HST',
        dataIndex: 'GSTorHST',
        key: 'GSTorHST',
      },
      {
        title: 'PST',
        dataIndex: 'PST',
        key: 'PST',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string, record: tableRecords, index: number) => (
          <Switch
            checked={record.status}
            onChange={() => {
              const changeStatusObject = {
                id: record && record.id,
                columnName: 'status',
              }
              this.props.changeTaxStatus(changeStatusObject)
            }}
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        ),
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => (
          <>
            {' '}
            <Button
              onClick={() => {
                this.editTax(record)
              }}
            >
              Edit
            </Button>
            {/* <Button onClick={() => {record && record.id && this.props.deleteCanadianTax(record.id)}} >Delete</Button> */}
          </>
        ),
      },
    ],
  }

  componentDidMount() {
    const { getCanadianTax } = this.props
    getCanadianTax()
  }

  render() {
    const { columns } = this.state

    const { canadianTax } = this.props

    return (
      <>
        <Card
          title="Canadian Tax"
          extra={
            <Button>
              <Link to="/add-tex">
                <WSIcon type={<PlusOutlined />} /> Add Tax
              </Link>
            </Button>
          }
        >
          <ExpandTable columns={columns} tableData={canadianTax} expandHtml={false} isExpand={false} />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  canadianTax: state.canadianTaxReducer.canadianTax,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCanadianTax: () => dispatch(getCanadianTax()),
  changeTaxStatus: (data: any) => dispatch(changeTaxStatus(data)),
  // deleteCanadianTax: (data: string) => (dispatch(deleteCanadianTax(data)))
})

export default connect(mapStateToProps, mapDispatchToProps)(CanadianTax)
