import { GET_ROLES, CLEAR_ROLES_FLAG, GET_USERS_COUNT_ROLE_WISE } from './Types'

export function getAllRoles() {
  return {
    type: GET_ROLES,
  }
}

export function clearRoleFlag() {
  return {
    type: CLEAR_ROLES_FLAG,
  }
}

export function getUserCountByRoleWise(data: any) {
  return {
    type: GET_USERS_COUNT_ROLE_WISE,
    payload: data,
  }
}
