import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import FormTable, { tableRecords } from './FormTable'
import { displayDefaultValue } from '../../helpers/helper'
import { getPartnerRelationAction, deletePartnerRelationsAction } from '../../store/form/Actions'
import { getAllBoatPagination } from '../../enums/enums'

interface Props {
  getPartnerRelationAction: typeof getPartnerRelationAction
  partnerRelationTotalRow: number
  deletePartnerRelationsAction: typeof deletePartnerRelationsAction
  partnerRelation: any[]
}

class Partner extends Component<Props> {
  columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: any) => {
        return <div>{displayDefaultValue(text && text.name)}</div>
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: any, record: tableRecords) => {
        return <div>{`${text} ${record.lastName}`}</div>
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Organization name',
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: 'Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]

  componentDidMount() {
    const { getPartnerRelationAction } = this.props
    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    }
    getPartnerRelationAction(input)
  }

  render() {
    const { deletePartnerRelationsAction, getPartnerRelationAction, partnerRelationTotalRow, partnerRelation } = this.props
    return (
      <FormTable
        tableTitle="Partner Relation"
        renderColumn={this.columns}
        getListAction={getPartnerRelationAction}
        totalUsersRow={partnerRelationTotalRow}
        tableList={partnerRelation}
        deleteAction={deletePartnerRelationsAction}
        formModule="partnerModule"
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    partnerRelation: state.feedbackReducer.partnerRelation,
    partnerRelationTotalRow: state.feedbackReducer.partnerRelationTotalRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getPartnerRelationAction: (data: any) => dispatch(getPartnerRelationAction(data)),
    deletePartnerRelationsAction: (data: any) => dispatch(deletePartnerRelationsAction(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Partner)
