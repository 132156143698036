import { imageSchema } from './../../schemaTypes'
import gql from 'graphql-tag'

export const createMarinaType = gql`
 mutation createMarinaType($input: MarinaTypeInput!) {
    createMarinaType(input: $input) {
        id
        name
        thumbnailIcon ${imageSchema}
        icon ${imageSchema}
    }
 }
 `

export const getAllMarinaTypes = gql`
    query getAllMarinaTypes($page: Int, $limit: Int, $isAdmin: Boolean = true, $searchTerm: String) {
        getAllMarinaTypes(page: $page, limit: $limit, isAdmin: $isAdmin, searchTerm: $searchTerm) {
            items {
                id
                name
                icon ${imageSchema}
                thumbnailIcon ${imageSchema}
                createdAt
                updatedAt
            }
            total
        }
    }
`

export const updateMarinaType = gql`
 mutation updateMarinaType($input: MarinaTypeInput!) {
    updateMarinaType(input: $input) { 
        id
        name
        thumbnailIcon ${imageSchema}
        icon ${imageSchema}
    }
 }
 `

export const deleteMarinaType = gql`
  mutation removeMarinaType($id: String!) {
    removeMarinaType(id: $id) {
      id
      name
    }
  }
`

export const editBoatTypes = gql`
query editBoatType($id: String!) {
    editBoatType(id: $id) {
        id
        name
        thumbnailIcon ${imageSchema}
        icon ${imageSchema}
    }
}
`
