import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch } from 'antd'
import moment from 'moment'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import { getAllAuctionRoomBids } from '../../store/manageAuctionRoom/Action'
import { getAuctionRoomBidsInput } from '../../store/manageAuctionRoom/Types'
import { formateDateTime, formatPrice } from '../../helpers/helper'
import History from '../../History'

interface Props {
  history: typeof History
  getAllAuctionRoomBids: typeof getAllAuctionRoomBids
  location: any
  auctionRoomBids: any[]
  auctionRoomBidsTotalRow: number
}

interface State {
  auctionId: string
}

class ManageAuctionBids extends Component<Props, State> {
  componentDidMount() {
    // const { getAllAuctionRoomBids } = this.props
    // const { auctionId } = this.state

    // if (auctionId) {
    //   getAllAuctionRoomBids({ auctionId })
    // }
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      auctionId: props.location.state.auctionId,
    }
  }

  render() {
    const { auctionRoomBids, auctionRoomBidsTotalRow, getAllAuctionRoomBids, history } = this.props
    const { auctionId } = this.state

    const columns = [
      {
        title: 'Buyer Name',
        dataIndex: 'user',
        key: 'user',
        render: (text: any) => (
          <span>
            {text.firstName} {text.lastName}
          </span>
        ),
      },
      {
        title: 'Bid Price in USD',
        dataIndex: 'price',
        key: 'price',
        render: (text: string, record: tableRecords, index: number) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Bid Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string, record: tableRecords, index: number) => <> {formateDateTime(text)} </>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string, record: any, index: number) =>
          record.auctionRoom && record.auctionRoom.auctionWinner ? (
            record.user.id === record.auctionRoom.auctionWinner.id ? (
              <span className="active-status-text">Win</span>
            ) : (
              <span className="inactive-status-text">Lose</span>
            )
          ) : (
            <span className="active-status-text">In Progress</span>
          ),
      },
    ]

    return (
      <Card title="Manage Boat's Bids" extra={<Button onClick={() => history.goBack()}>Back</Button>}>
        <ExpandTable
          pageAction={getAllAuctionRoomBids}
          columns={columns}
          tableData={auctionRoomBids}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={auctionRoomBidsTotalRow}
          extraInput={{ auctionId: auctionId }}
          page="boat"
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  auctionRoomBids: state.auctionRoomReducer && state.auctionRoomReducer.auctionRoomBids,
  auctionRoomBidsTotalRow: state.auctionRoomReducer.auctionRoomBidsTotalRow,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllAuctionRoomBids: (data: getAuctionRoomBidsInput) => dispatch(getAllAuctionRoomBids(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAuctionBids)
