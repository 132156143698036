import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../'

import {
  GET_ALL_REVIEWS,
  GET_ALL_REVIEWS_SUCCESS,
  GET_ALL_REVIEWS_FAILURE,
  ActionType,
  CHANGE_REVIEW_STATUS,
  CHANGE_REVIEW_STATUS_SUCCESS,
  CHANGE_REVIEW_STATUS_FAILURE,
  ChangeReviewStatusInput,
  GetInput,
  REJECT_REVIEW,
  REJECT_REVIEW_SUCCESS,
  REJECT_REVIEW_FAILURE,
} from './Types'
import { getAllReviews, changeReviewStatus, rejectReview } from './Schema'

function getAllReviewsApi(input: GetInput) {
  return client
    .query({
      query: getAllReviews,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function changeReviewStatusApi(input: ChangeReviewStatusInput) {
  return client
    .mutate({
      mutation: changeReviewStatus,
      variables: input,
    })
}

function rejectReviewApi(input: ChangeReviewStatusInput) {
  return client
    .mutate({
      mutation: rejectReview,
      variables: input,
    })
}

function* getAllReviewsData(action: ActionType) {
  try {
    const res = yield getAllReviewsApi(action.payload)
    yield put({
      type: GET_ALL_REVIEWS_SUCCESS,
      payload: res.data.getAllReviews,
    })
  } catch (error) {
    yield put({ type: GET_ALL_REVIEWS_FAILURE, error })
  }
}

function* changeReviewStatusData(action: ActionType) {
  try {
    const res = yield changeReviewStatusApi(action.payload)
    yield put({
      type: CHANGE_REVIEW_STATUS_SUCCESS,
      payload: res.data.changeReviewStatus,
    })
  } catch (error) {
    yield put({ type: CHANGE_REVIEW_STATUS_FAILURE, error })
  }
}

function* rejectReviewData(action: ActionType) {
  try {
    const res = yield rejectReviewApi(action.payload)
    yield put({ type: REJECT_REVIEW_SUCCESS, payload: res.data.rejectReview })
  } catch (error) {
    yield put({ type: REJECT_REVIEW_FAILURE, error })
  }
}

function* getAllReviewsSaga() {
  yield takeLatest(GET_ALL_REVIEWS, getAllReviewsData)
}

function* changeReviewStatusSaga() {
  yield takeLatest(CHANGE_REVIEW_STATUS, changeReviewStatusData)
}

function* rejectReviewSaga() {
  yield takeLatest(REJECT_REVIEW, rejectReviewData)
}

export default function* reviewsSaga() {
  yield all([getAllReviewsSaga(), changeReviewStatusSaga(), rejectReviewSaga()])
}
