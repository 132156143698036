export interface pageState {
  page: []
  pageSuccess: boolean
  pageError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  totalPage: number
  isPageLoading: boolean
}

export interface pageData {
  id: string
  type?: string
  title: string
  pageContent: string
  titleSlug: string
}

export const GET_ALL_PAGES = 'GET_ALL_PAGES'
export const GET_ALL_PAGES_SUCCESS = 'GET_ALL_PAGES_SUCCESS'
export const GET_ALL_PAGES_FAILURE = 'GET_ALL_PAGES_FAILURE'

export const CREATE_PAGES = 'CREATE_PAGES'
export const CREATE_PAGES_SUCCESS = 'CREATE_PAGES_SUCCESS'
export const CREATE_PAGES_FAILURE = 'CREATE_PAGES_FAILURE'

export const UPDATE_PAGES = 'UPDATE_PAGES'
export const UPDATE_PAGES_SUCCESS = 'UPDATE_PAGES_SUCCESS'
export const UPDATE_PAGES_FAILURE = 'UPDATE_PAGES_FAILURE'

export const DELETE_PAGES = 'DELETE_PAGES'
export const DELETE_PAGES_SUCCESS = 'DELETE_PAGES_SUCCESS'
export const DELETE_PAGES_FAILURE = 'DELETE_PAGES_FAILURE'

export const CLEAR_PAGES_FLAG = 'CLEAR_PAGES_FLAG'

// export const GET_SINGLE_PAGE = "GET_SINGLE_PAGE"
// export const GET_SINGLE_PAGE_SUCCESS = "GET_SINGLE_PAGE_SUCCESS"
// export const GET_SINGLE_PAGE_FAILURE = "GET_SINGLE_PAGE_FAILURE"

// export const GET_PAGES_BY_TITLE = "GET_PAGES_BY_TITLE"
// export const GET_PAGES_BY_TITLE_SUCCESS = "GET_PAGES_BY_TITLE_SUCCESS"
// export const GET_PAGES_BY_TITLE_FAILURE = "GET_PAGES_BY_TITLE_FAILURE"

interface getAllPages {
  type: typeof GET_ALL_PAGES
  payload: pageData
}

interface getAllPagesSuccess {
  type: typeof GET_ALL_PAGES_SUCCESS
  payload: any
}

interface getAllPagesFailure {
  type: typeof GET_ALL_PAGES_FAILURE
  payload: any
}

interface createPages {
  type: typeof CREATE_PAGES
  payload: pageData
}

interface createPagesSuccess {
  type: typeof CREATE_PAGES_SUCCESS
  payload: any
}

interface createPagesFailure {
  type: typeof CREATE_PAGES_FAILURE
  payload: any
}

interface updatePages {
  type: typeof UPDATE_PAGES
  payload: pageData
}

interface updatePagesSuccess {
  type: typeof UPDATE_PAGES_SUCCESS
  payload: pageData
}

interface updatePagesFailure {
  type: typeof UPDATE_PAGES_FAILURE
  payload: pageData
}

interface deletePages {
  type: typeof DELETE_PAGES
  payload: pageData
}

interface deletePagesSuccess {
  type: typeof DELETE_PAGES_SUCCESS
  payload: pageData
}

interface deletePagesFailure {
  type: typeof DELETE_PAGES_FAILURE
  payload: pageData
}

interface clearPagesFlag {
  type: typeof CLEAR_PAGES_FLAG
  payload: any
}

// interface getSinglePage  {
//     type: typeof GET_SINGLE_PAGE
//     payload: pageData
// }

// interface getSinglePageSuccess  {
//     type: typeof GET_SINGLE_PAGE_SUCCESS
//     payload: any
// }

// interface getSinglePageFailure  {
//     type: typeof GET_SINGLE_PAGE_FAILURE
//     payload: any
// }

// interface getPagesByTitle  {
//     type: typeof GET_PAGES_BY_TITLE
//     payload: pageData
// }

// interface getPagesByTitleSuccess  {
//     type: typeof GET_PAGES_BY_TITLE_SUCCESS
//     payload: any
// }

// interface getPagesByTitleFailure  {
//     type: typeof GET_PAGES_BY_TITLE_FAILURE
//     payload: any
// }

export type ActionType =
  | getAllPages
  | getAllPagesSuccess
  | getAllPagesFailure
  | createPages
  | createPagesSuccess
  | createPagesFailure
  | updatePages
  | updatePagesSuccess
  | updatePagesFailure
  | deletePages
  | deletePagesSuccess
  | deletePagesFailure
  | clearPagesFlag
// | getSinglePage
// | getSinglePageSuccess
// | getSinglePageFailure
// | getPagesByTitle
// | getPagesByTitleSuccess
// | getPagesByTitleFailure
