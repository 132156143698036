import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Surveyor from '../container/frontUser/surveyor/Surveyor'
import FrontUsers from '../container/dashboard/frontUsers/FrontUsers'
import AdminUsers from '../container/dashboard/adminUsers/AdminUsers'
import ManageHomeVideoDashboard from '../container/staticContent/manageHomeVideo/ManageHomeVideoDashboard'
import EmailTemplates from '../container/staticContent/emailTemplates/EmailTemplates'
import ManageSMTP from '../container/staticContent/manageSMTP/ManageSMTP'
import CreateSMTP from '../container/staticContent/manageSMTP/CreateSMTP'
import ManageArticles from '../container/staticContent/manageArticles/ManageArticles'
import CreateEmailTemplate from '../container/staticContent/emailTemplates/CreateEmailTemplate'
import SalesManDashboard from '../container/userAdmin/salesMan/SalesManDashboard'
import CreateSalesMan from '../container/userAdmin/salesMan/CreateSalesMan'
import BuilderAgreementsDashboard from '../container/manageAgreements/BuilderAgreementsDashboard'
import ShipmentAgreementsDashboard from '../container/manageAgreements/ShipmentAgreementsDashboard'
import SurveyorAgreementsDashboard from '../container/manageAgreements/SurveyorAgreementsDashboard'
import ManageAgreementContents from '../container/manageAgreements/manageAgreementContents/ManageAgreementContents'
import CreateAgreementContent from '../container/manageAgreements/manageAgreementContents/CreateAgreementContent'
import CreateAgreements from '../container/manageAgreements/CreateAgreements'
import ViewBuilderAgreement from '../container/manageAgreements/ViewBuilderAgreement'
import CreateManageHomeVideo from '../container/staticContent/manageHomeVideo/CreateManageHomeVideo'
import Member from '../container/frontUser/member/Member'
import AllBoats from '../container/boats/manageAllBoats/AllBoats'
import ViewMember from '../container/frontUser/member/ViewMember'
import BoatDetails from '../container/boats/manageAllBoats/BoatDetails'
import AddCountry from '../container/countries/manageCountries/AddCountry'
import ManageCountries from '../container/countries/manageCountries/ListCountry'
import ManageStates from '../container/countries/manageState/ListState'
import AddStateProvince from '../container/countries/manageState/AddStateProvince'
import CreateCanadianTax from '../container/salesEngine/canadianTax/CreateCanadianTax'
import CreateCostEstimate from '../container/salesEngine/costEstimate/CreateCostEstimate'
import ManageHelpContent from '../container/salesEngine/ManageHelpContent'
import ManageStaticSalesContent from '../container/salesEngine/ManageStaticSalesContent'
// import PaymentSettings from "../container/manageAdvertisements/PaymentSettings";
import CreateBannerImages from '../container/manageBannerImages/manageHomePage/CreateBannerImages'
import SingleSellerBoats from '../container/pendingAds/SingleSellerBoats'
import SingleSeller from '../container/frontUser/singleSeller/SingleSeller'
import CorporateSeller from '../container/frontUser/corporateSeller/CorporateSeller'
import BoatBuilders from '../container/frontUser/boatBuilders/BoatBuilders'
import Shipment from '../container/frontUser/shipment/Shipment'
import Rent from '../container/frontUser/rent/Rent'
import YachtService from '../container/frontUser/yachtService/YachtService'
import MarinaAndStorage from '../container/frontUser/marinaAndStorage/MarinaAndStorage'
import CanadianTax from '../container/salesEngine/canadianTax/CanadianTax'
import FeaturedBoats from '../container/boats/featureBoats/FeatureBoats'
import BestDealBoats from '../container/boats/bestDealBoats/BestDealBoats'
import MustBuyBoats from '../container/boats/mustBuyBoats/MustBuyBoats'
import PendingBoatRents from '../container/pendingAds/PendingBoatRents'
import ManageUserGuide from '../container/staticContent/manageUserGuide/ManageUserGuide'
import CreateUserGuide from '../container/staticContent/manageUserGuide/CreateUserGuide'
import ManageFAQ from '../container/staticContent/manageFAQ/ManageFAQ'
import CreateFAQ from '../container/staticContent/manageFAQ/CreateFAQ'
import PendingMarinaAndStorages from '../container/pendingAds/PendingMarinaAndStorages'
import ViewUserGuide from '../container/staticContent/manageUserGuide/ViewUserGuide'
import CreatePage from '../container/staticContent/managePages/CreatePage'
import ManageBoatTypes from '../container/staticContent/manageBoatTypes/ManageBoatTypes'
import CreateBoatTypes from '../container/staticContent/manageBoatTypes/CreateBoatTypes'
import HomeBanner from '../container/manageBannerImages/manageHomePage/HomeBanner'
import MarketRentBanner from '../container/manageBannerImages/manageMarketRent/MarketRentBanner'
import CreateMarketRent from '../container/manageBannerImages/manageMarketRent/CreateMarketRent'
import MarketBoatShowBanner from '../container/manageBannerImages/manageBoatShow/MarketBoatShow'
import MarketBoatMediaBanner from '../container/manageBannerImages/manageBoatMedia/MarketBoatMedia'
import CreateMarketBoatShow from '../container/manageBannerImages/manageBoatShow/CreateMarketBoatShow'
import MarketServiceBanner from '../container/manageBannerImages/manageMarketService/MarketServiceBanner'
import CreateMarketService from '../container/manageBannerImages/manageMarketService/CreateMarketService'
import CreateMarketMarina from '../container/manageBannerImages/manageMarketMarina/CreateMarketMarina'
import MarketMarinaBanner from '../container/manageBannerImages/manageMarketMarina/MarketMarinaBanner'
import ManagePages from '../container/staticContent/managePages/ManagePages'
import SmsTemplates from '../container/staticContent/smsTemplates/SmsTemplates'
import CreateSmsTemplate from '../container/staticContent/smsTemplates/CreateSmsTemplate'
import ManageBoatShows from '../container/staticContent/manageBoatShows/ManageBoatShows'
import ManageAuctionRoom from '../container/manageAuctionRoom/ManageAuctionRoom'
import ManageAuctionRoomPayment from '../container/manageAuctionRoom/ManageAuctionRoomPayment'
import ManageAllBranches from '../container/manageSurveyorBranch/allBranches/AllBranches'
import SalesEngineListing from '../container/salesEngine/manageSalesEngine/SalesEngineListing'
import SalesEnginePendingListing from '../container/salesEngine/manageSalesEngine/SalesEnginePendingListing'
import SalesEngineArchiveListing from '../container/salesEngine/archiveSalesProcess/ArchiveSalesProcess'
import SalesEngineBlacklist from '../container/salesEngine/blacklistSalesProcess/BlacklistSalesProcess'
import ViewSalesEngine from '../container/salesEngine/manageSalesEngine/ViewSalesEngine'
import ManageMarinaTypes from '../container/staticContent/manageMarinaType/ManageMarinaTypes'
import CreateMarinTypes from '../container/staticContent/manageMarinaType/CreateMarinTypes'
import ManageYatchServiceTypes from '../container/staticContent/manageYatchServiceTypes/ManageYatchServiceTypes'
import CreateYatchServiceTypes from '../container/staticContent/manageYatchServiceTypes/CreateYatchServiceTypes'
import CreateRentType from '../container/staticContent/manageRentType/CreateRentType'
import ManageRentTypes from '../container/staticContent/manageRentType/ManageRentTypes'
import ManageDashboardAgreements from '../container/dashboard/manageAgreements/ManageAgreements'
import ManageSalesEngine from '../container/dashboard/manageSalesEngine/ManageSalesEngine'
import ManageDashboardAuctionRoom from '../container/dashboard/manageAuctionRoom/ManageAuctionRoom'
import ManageDashboardAdvertisements from '../container/dashboard/manageAdvertisements/ManageAdvertisements'
import OtherUsers from '../container/dashboard/otherCount/OtherCount'
import AccountUser from '../container/adminUsers/accountUser/AccountUser'
import CreateAdminUser from '../container/adminUsers/createAdminUser/CreateAdminUser'
import withAuth from '../hoc/withAuth'
import RegistrationContent from '../container/staticContent/registrationContent/RegistrationContent'
import ManageAuctionBids from '../container/manageAuctionBids/ManageAuctionBids'
import ManageJobs from '../container/staticContent/manageJobs/ManageJobs'
import CreateJobs from '../container/staticContent/manageJobs/CreateJobs'
import DocumentList from '../container/frontUser/documentVerify/DocumentList'
import BoatManufacturerBoats from '../container/pendingAds/BoatManufacturerBoats'
import BrokerAndSellerBoats from '../container/pendingAds/BrokerAndSellerBoats'
import ManageArticleBanner from '../container/manageBannerImages/manageArticle/ManageArticleBanner'
import CreateArticleBanner from '../container/manageBannerImages/manageArticle/CreateArticleBanner'
import CreateDownloadBanner from '../container/manageBannerImages/manageDownloadBanner/CreateDownloadBanner'
import ManageDownLoadBanner from '../container/manageBannerImages/manageDownloadBanner/ManageDownLoadBanner'
import ManageFaqBanner from '../container/manageBannerImages/manageFaq/ManageFaqBanner'
import CreateFaqBanner from '../container/manageBannerImages/manageFaq/CreateFaqBanner'
import ManageUserGuideBanner from '../container/manageBannerImages/manageUserGuideBanner/ManageUserGuideBanner'
import CreateUserGuideBanner from '../container/manageBannerImages/manageUserGuideBanner/CreateUserGuideBanner'
import ManageOtherPageBanner from '../container/manageBannerImages/manageOtherPageBanner/ManageOtherPageBanner'
import CreateOtherPageBanner from '../container/manageBannerImages/manageOtherPageBanner/CreateOtherPageBanner'
import ManageAdminLoginBanner from '../container/manageBannerImages/manageAdminLoginBanner/ManageAdminLoginBanner'
import CreateAdminLoginBanner from '../container/manageBannerImages/manageAdminLoginBanner/CreateAdminLoginBanner'
import ManageDownloads from '../container/staticContent/manageDownloads/ManageDownloads'
import CreateDownloads from '../container/staticContent/manageDownloads/CreateDownloads'
import Agent from '../container/frontUser/agent/Agent'
import ManageLogInBanner from '../container/manageBannerImages/manageLoginBanner/ManageLogInBanner'
import ManageSignUpBanner from '../container/manageBannerImages/manageSignUpBanner/ManageSignUpBanner'
import CreateLogInBanner from '../container/manageBannerImages/manageLoginBanner/CreateLogInBanner'
import CreateSignUpBanner from '../container/manageBannerImages/manageSignUpBanner/CreateSignUpBanner'
import Feedback from '../container/forms/Feedback'
import ManageAgreementRequests from '../container/manageAgreements/ManageAgreementRequests'
import ContactUs from '../container/forms/ContactUs'
import Multimedia from '../container/forms/Multimedia'
import Partner from '../container/forms/Partner'
import Career from '../container/forms/Career'
import Complaint from '../container/forms/Complaint'
import Suggestions from '../container/forms/Suggestions'
import Customer from '../container/forms/Customer'
import CallMeBack from '../container/forms/CallMeBack'
import AdminLeads from '../container/forms/AdminLeads'
import ManageRentBoats from '../container/manageRentBoats/ManageRentBoats'
import ManageMarinaServices from '../container/manageMarinaStorage/ManageMarinaServices'
import ManageBoatService from '../container/manageBoatService/ManageBoatService'
import GeneralSettings1 from '../container/generalSettings/GeneralSettings1'
import MarinaMoreServices from '../container/manageMarinaStorage/MarinaMoreServices'
import LiveExperienceMarina from '../container/manageMarinaStorage/LiveExperienceMarina'
import NearestMarina from '../container/manageMarinaStorage/NearestMarina'
import MostPopularMarina from '../container/manageMarinaStorage/MostPopularMarina'
import NearestBoatService from '../container/manageBoatService/NearestBoatService'
import TopRatedBoatService from '../container/manageBoatService/TopRatedBoatService'
import { recommendedBoatRents } from '../store/boat/BoatSchema'
import MostPopularRent from '../container/manageRentBoats/MostPopularRent'
import LiveExperienceRent from '../container/manageRentBoats/LiveExperienceRent'
import RecommendedTrips from '../container/manageRentBoats/RecommendedTrips'
import ManageReviews from '../container/manageReviews/ManageReviews'
import SalesEnginProgress from '../container/salesEngine/SalesEnginProgress'
import SalesEnginPayment from '../container/salesEngine/SalesEnginPaymentComponent'
import SalesEnginPaymentTransactions from '../container/salesEngine/SalesEnginPaymentTransactions'
import SalesEnginCommision from '../container/salesEngine/SalesEnginCommision'
import SalesEnginReleasePayment from '../container/salesEngine/SalesEnginReleasePayment'
import SearchTabs from '../component/SearchTabs'
import PendingBoatService from '../container/pendingAds/PendingBoatService'
import ManageTypes from '../container/manageType/ManageType'
import ActivityLogs from '../container/logs/ActivityLogs'
import ManagePayment from '../container/payment/ManagePayment'
import CreatePayment from '../container/payment/CreatePayment'
import ForgotPassword from '../container/staticContent/forgotPassword/ForgotPassword'
import SurveyorBranches from '../container/frontUser/surveyor/SurveyorBranches'
import CareerContent from '../container/staticContent/manageJobs/CareerContent'
import ManageSurveyorDocumentData from '../container/salesEngine/ManageSurveyDocuementData'
import ManageJobsApplications from '../container/staticContent/manageJobs/ManageJobsApplications'
import ManageAuctionDepositors from '../container/manageAuctionDepositors/manageAuctionDepositors'
import ManageAuctionTransactions from '../container/manageAuctionTransactions/manageAuctionTransactions'
import AllCountryBoat from '../container/boats/allCountryBoats/AllCountryBoats'
import CreateMarketBoatMedia from '../container/manageBannerImages/manageBoatMedia/CreateMarketBoatMedia'
import ChangePassword from '../container/changePassword/ChangePassword'
import ManageReviewRatings from '../container/manageReviews/ManageReviewRatings'
import SalesEnginReleaseTransactions from '../container/salesEngine/SalesEnginReleaseTransactions'
import RentPaymentsReceivers from '../container/manageRentBoats/Receivers'
import ManageRentPages from '../container/staticContent/managePages/ManageRentPages'
import CreateRentPage from '../container/staticContent/managePages/CreateRentPage'
import RentPaymentsReceiverBoats from '../container/manageRentBoats/ReceiverBoats'
import RentPaymentsPrivateBoatSlots from '../container/manageRentBoats/PrivateBoatSlots'
import RentPaymentsBoatSlots from '../container/manageRentBoats/BoatSlots'
import RentPaymentsBoatSlotBookings from '../container/manageRentBoats/BoatSlotBookings'
import ManageAdComplaints from '../container/manageComplaints/ManageAdComplaints'
import ManageReviewComplaints from '../container/manageComplaints/ManageReviewComplaints'
import ManageAdComplaintsIndividual from '../container/manageComplaints/ManageAdComplaintsIndividual'
import ManageReviewComplaintsIndividual from '../container/manageComplaints/ManageReviewComplaintsIndividual'
import ManageAgreementsListing from '../container/staticContent/managePages/ManageAgreementsListing'
import CreateAgreementListing from '../container/staticContent/managePages/CreateAgreementListing'
import ManageHelpSection from '../container/manageHelpSection/manageHelpSection'
import CreateHelpSection from '../container/manageHelpSection/createHelpSection'
import CreateSeoMetaTag from '../container/staticContent/seoMetaTag/CreateSeoMetaTag'
import SeoMetaTag from '../container/staticContent/seoMetaTag/SeoMetaTag'
import { AdminPermissionGroups, AdminPermissions } from '../enums/enums'
import AdminActivityLogs from '../container/logs/AdminActivityLogs'
import ManageRent from '../container/dashboard/manageRent/ManageRent'
import TimerSettings from '../container/timerSettings'
import PostsWithReports from '../container/social-media/posts-with-reports'
import PostReports from '../container/social-media/post-reports'

const AppRouter = ({ allProps }: any) => {
  return (
    <Switch>
      <>
        {allProps.hasPermission(AdminPermissions.GENERAL_SETTINGS) && (
          <>
            <Route path="/general" component={withAuth(GeneralSettings1)} exact />
          </>
        )}

        {allProps.hasPermission(AdminPermissions.PENDING_VERIFICATION) && (
          <>
            <Route path="/pending-verification" component={withAuth(DocumentList)} exact />
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.DASHBOARD) && (
          <>
            {allProps.hasPermission(AdminPermissions.DASHBOARD__FRONT_USERS) && (
              <>
                <Route path="/front-users" component={withAuth(FrontUsers)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.DASHBOARD__ADMIN_USERS) && (
              <>
                <Route path="/admin-users" component={withAuth(AdminUsers)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.DASHBOARD__OTHERS) && (
              <>
                <Route path="/others" component={withAuth(OtherUsers)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.DASHBOARD__MANAGE_AGREEMENTS) && (
              <>{/* <Route path="/manage-agreements-listing" component={withAuth(ManageAgreementsListing)} exact /> */}</>
            )}
            {allProps.hasPermission(AdminPermissions.DASHBOARD__MANAGE_SALES_ENGINES) && (
              <>
                <Route path="/manage-sales-engine" component={withAuth(ManageSalesEngine)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.DASHBOARD__MANAGE_AUCTION_ROOMS) && (
              <>
                <Route path="/manage-auction-room-dashboard" component={withAuth(ManageDashboardAuctionRoom)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.DASHBOARD__MANAGE_AUCTION_ROOMS) && (
              <>
                <Route path="/manage-rent" component={withAuth(ManageRent)} exact />
              </>
            )}
          </>
        )}

        {allProps.hasPermission(AdminPermissions.DASHBOARD__ADMIN_USERS) && (
          <>
            <Route path="/admin-user/:role" component={withAuth(AccountUser)} exact />
            <Route path="/add-admin-users" component={withAuth(CreateAdminUser)} exact />
            <Route path="/edit-admin-user" component={withAuth(CreateAdminUser)} exact />
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.FRONT_USERS) && (
          <>
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__MEMBER) && (
              <>
                <Route path="/member" component={withAuth(Member)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__BOAT_OWNER) && (
              <>
                <Route path="/boat-owner" component={withAuth(SingleSeller)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__BROKER_AND_DEALER) && (
              <>
                <Route path="/boat-and-dealer" component={withAuth(CorporateSeller)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__BOAT_MANUFACTURER) && (
              <>
                <Route path="/boat-manufactory" component={withAuth(BoatBuilders)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__SHIPMENT) && (
              <>
                <Route path="/shipment" component={withAuth(Shipment)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__SURVEYOR) && (
              <>
                <Route path="/surveyor" component={withAuth(Surveyor)} exact />
                <Route path="/surveyor-branches/:id" component={withAuth(SurveyorBranches)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__RENT) && (
              <>
                <Route path="/rent" component={withAuth(Rent)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__SERVICE_AND_MAINTENANCE) && (
              <>
                <Route path="/yacht-service" component={withAuth(YachtService)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__MARINA_AND_STORAGE) && (
              <>
                <Route path="/marina-storage" component={withAuth(MarinaAndStorage)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FRONT_USERS__ADAMSEA_AGENT) && (
              <>
                <Route path="/agent" component={withAuth(Agent)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.EMAIL_SETTINGS) && (
          <>
            {allProps.hasPermission(AdminPermissions.EMAIL_SETTINGS__MANAGE_SMTP) && (
              <>
                <Route path="/manage-smtp" component={withAuth(ManageSMTP)} exact />
                <Route path="/create-smtp" component={withAuth(CreateSMTP)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.EMAIL_SETTINGS__EMAIL_TEMPLATES) && (
              <>
                <Route path="/email-templates" component={withAuth(EmailTemplates)} exact />
                <Route path="/create-email-template" component={withAuth(CreateEmailTemplate)} exact />
              </>
            )}
          </>
        )}

        {allProps.hasPermission(AdminPermissions.SMS_TEMPLATES) && (
          <>
            <Route path="/sms-templates" component={withAuth(SmsTemplates)} exact />
            <Route path="/create-sms-template" component={withAuth(CreateSmsTemplate)} exact />
            <Route path="/edit-sms-template/:type" component={withAuth(CreateSmsTemplate)} exact />
          </>
        )}

        {allProps.hasPermission(AdminPermissions.SEO_META_TAGS) && (
          <>
            <Route path="/seo-meta-tag" component={withAuth(SeoMetaTag)} exact />
            <Route path="/create-seo-meta-tag" component={withAuth(CreateSeoMetaTag)} exact />
          </>
        )}

        {allProps.hasPermission(AdminPermissions.MANAGE_TYPE) && (
          <>
            <Route path="/manage-types" component={withAuth(ManageTypes)} exact />
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_BOATS) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_BOATS__ALL_COUNTRY_BOATS) && (
              <>
                <Route path="/all-country-boats" component={withAuth(AllCountryBoat)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BOATS__MANAGE_ALL_BOATS) && (
              <>
                <Route path="/manage-all-boats" component={withAuth(AllBoats)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BOATS__MANAGE_FEATURED_BOATS) && (
              <>
                <Route path="/manage-featured-boats" component={withAuth(FeaturedBoats)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BOATS__MANAGE_BEST_DEAL_BOATS) && (
              <>
                <Route path="/manage-best-deal-boats" component={withAuth(BestDealBoats)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BOATS__MANAGE_LITTLE_BOATS) && (
              <>
                <Route path="/manage-must-buy-boats" component={withAuth(MustBuyBoats)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_RENT_BOATS) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MANAGE_RENT_BOATS) && (
              <>
                <Route path="/manage-rent-boats" component={withAuth(ManageRentBoats)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MANAGE_RECOMMENDED) && (
              <>
                <Route path="/recommended-rent-boat" component={withAuth(RecommendedTrips)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MOST_POPULAR) && (
              <>
                <Route path="/most-popular-boat" component={withAuth(MostPopularRent)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MANAGE_LIVE_EXPERIENCE) && (
              <>
                <Route path="/live-experience-rent" component={withAuth(LiveExperienceRent)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_RENT_BOATS__MANAGE_PAYMENTS) && (
              <>
                <Route path="/view-rent-payments-receivers" component={withAuth(RentPaymentsReceivers)} exact />
                <Route
                  path="/view-rent-payments-receiver-boats/:receiverId"
                  component={withAuth(RentPaymentsReceiverBoats)}
                  exact
                />
                <Route
                  path="/view-rent-payments-private-boat-slots/:boatId"
                  component={withAuth(RentPaymentsPrivateBoatSlots)}
                  exact
                />
                <Route path="/view-rent-payments-boat-slots/:boatId" component={withAuth(RentPaymentsBoatSlots)} exact />
                <Route
                  path="/view-rent-payments-boat-slot-bookings/:boatId"
                  component={withAuth(RentPaymentsBoatSlotBookings)}
                  exact
                />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_BOAT_SERVICE) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_ALL_BOAT_SERVICE) && (
              <>
                <Route path="/manage-boat-service" component={withAuth(ManageBoatService)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_NEAREST_YACHT) && (
              <>
                <Route path="/nearest-boat-service" component={withAuth(NearestBoatService)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BOAT_SERVICE__MANAGE_TOP_RATED_YACHT) && (
              <>
                <Route path="/top-rated-boat-service" component={withAuth(TopRatedBoatService)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_MARINA_AND_STORAGE) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_ALL_MARINA_BOATS) && (
              <>
                <Route path="/manage-marina-storage" component={withAuth(ManageMarinaServices)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_POPULAR_MARINA) && (
              <>
                <Route path="/most-popular-marina" component={withAuth(MostPopularMarina)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_MORE_SERVICES) && (
              <>
                <Route path="/marina-more-services" component={withAuth(MarinaMoreServices)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_NEAREST_MARINA) && (
              <>
                <Route path="/nearest-marina" component={withAuth(NearestMarina)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_MARINA_AND_STORAGE__MANAGE_LIVE_EXPERIENCE) && (
              <>
                <Route path="/live-experience-marina" component={withAuth(LiveExperienceMarina)} exact />
              </>
            )}
          </>
        )}

        {allProps.hasPermission(AdminPermissions.MANAGE_BOAT_SHOWS) && (
          <>
            <Route path="/manage-boat-shows" component={withAuth(ManageBoatShows)} exact />
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.PENDING_ADS) && (
          <>
            {allProps.hasPermission(AdminPermissions.PENDING_ADS__BOAT_OWNER_ADS) && (
              <>
                <Route path="/boat-owner-boats" component={withAuth(SingleSellerBoats)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.PENDING_ADS__BROKER_AND_DEALER_ADS) && (
              <>
                <Route path="/broker-and-dealer-boats" component={withAuth(BrokerAndSellerBoats)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.PENDING_ADS__BOAT_MANUFACTURER_ADS) && (
              <>
                <Route path="/boat-manufacturer-boats" component={withAuth(BoatManufacturerBoats)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.PENDING_ADS__BOAT_SERVICE_ADS) && (
              <>
                <Route path="/pending-boat-service" component={withAuth(PendingBoatService)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.PENDING_ADS__RENT_ADS) && (
              <>
                <Route path="/pending-boat-rents" component={withAuth(PendingBoatRents)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.PENDING_ADS__MARINA_AND_STORAGE_ADS) && (
              <>
                <Route path="/pending-marina-and-storages" component={withAuth(PendingMarinaAndStorages)} exact />
              </>
            )}
          </>
        )}

        {allProps.hasPermission(AdminPermissions.PAYMENT_METHODS) && (
          <>
            <Route path="/payment-methods" component={withAuth(ManagePayment)} exact />
            <Route path="/create-payment-methods" component={withAuth(CreatePayment)} exact />
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_COMPLAINTS) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_COMPLAINTS__MANAGE_AD_COMPLAINTS) && (
              <>
                <Route path="/manage-ad-complaints" component={withAuth(ManageAdComplaints)} exact />
                <Route path="/manage-ad-complaints/:moduleId" component={withAuth(ManageAdComplaintsIndividual)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_COMPLAINTS__MANAGE_REVIEW_COMPLAINTS) && (
              <>
                <Route path="/manage-review-complaints" component={withAuth(ManageReviewComplaints)} exact />
                <Route
                  path="/manage-review-complaints/:moduleId"
                  component={withAuth(ManageReviewComplaintsIndividual)}
                  exact
                />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_AGREEMENTS) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_AGREEMENTS__BUILDER_AGREEMENTS) && (
              <>
                <Route path="/builder-agreements" component={withAuth(BuilderAgreementsDashboard)} exact />
                <Route
                  path="/create-agreement/builder"
                  component={withAuth((props: any) => (
                    <CreateAgreements {...props} validType="builder" />
                  ))}
                  exact
                />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_AGREEMENTS__BUILDER_AGREEMENTS) && (
              <>
                <Route path="/shipment-agreements" component={withAuth(ShipmentAgreementsDashboard)} exact />
                <Route
                  path="/create-agreement/shipment"
                  component={withAuth((props: any) => (
                    <CreateAgreements {...props} validType="shipment" />
                  ))}
                  exact
                />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_AGREEMENTS__SURVEYOR_AGREEMENTS) && (
              <>
                <Route path="/surveyor-agreements" component={withAuth(SurveyorAgreementsDashboard)} exact />
                <Route
                  path="/create-agreement/surveyor"
                  component={withAuth((props: any) => (
                    <CreateAgreements {...props} validType="surveyor" />
                  ))}
                  exact
                />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_AGREEMENTS__ADD_AGREEMENT_CONTENT) && (
              <>
                <Route path="/agreement-contents" component={withAuth(ManageAgreementContents)} exact />
                <Route path="/create-agreement-content" component={withAuth(CreateAgreementContent)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_AGREEMENTS__AGREEMENT_REQUESTS) && (
              <>
                <Route path="/agreement-requests" component={withAuth(ManageAgreementRequests)} exact />
              </>
            )}
          </>
        )}

        {allProps.hasPermission(AdminPermissions.MANAGE_VIDEOS) && (
          <>
            <Route path="/manage-videos" component={withAuth(ManageHomeVideoDashboard)} exact />
            <Route path="/create-manage-video" component={withAuth(CreateManageHomeVideo)} exact />
          </>
        )}

        {allProps.hasPermission(AdminPermissions.MANAGE_DOWNLOADS) && (
          <>
            <Route path="/manage-downloads" component={withAuth(ManageDownloads)} exact />
            <Route path="/create-downloads" component={withAuth(CreateDownloads)} exact />
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.STATIC_CONTENT) && (
          <>
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_AGREEMENTS_LISTING) && (
              <>
                <Route path="/manage-agreements-listing" component={withAuth(ManageAgreementsListing)} exact />
                <Route path="/create-agreement-listing" component={withAuth(CreateAgreementListing)} exact />
              </>
            )}
            {(allProps.hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_PAGES) ||
              allProps.hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_SALES_ENGINE_PAGES)) && (
              <>
                <Route path="/manage-pages/:type" component={withAuth(ManagePages)} exact />
                <Route path="/create-page/:type" component={withAuth(CreatePage)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_RENT_PAGES) && (
              <>
                <Route path="/manage-rent-pages" component={withAuth(ManageRentPages)} exact />
                <Route path="/create-rent-page" component={withAuth(CreateRentPage)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__FORGOT_PASSWORD) && (
              <>
                <Route path="/forgot-password" component={withAuth(ForgotPassword)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_ARTICLES) && (
              <>
                <Route path="/manage-articles" component={withAuth(ManageArticles)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_USER_GUIDE) && (
              <>
                <Route path="/manage-user-guide" component={withAuth(ManageUserGuide)} exact />
                <Route path="/create-user-guide" component={withAuth(CreateUserGuide)} exact />
                <Route path="/view-user-guide" component={withAuth(ViewUserGuide)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_JOBS) && (
              <>
                <Route path="/manage-jobs" component={withAuth(ManageJobs)} exact />
                <Route path="/create-job" component={withAuth(CreateJobs)} exact />
                <Route path="/career-content" component={withAuth(CareerContent)} exact />
                <Route path="/jobs-applications/:id" component={withAuth(ManageJobsApplications)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__MANAGE_FAQS) && (
              <>
                <Route path="/manage-faqs" component={withAuth(ManageFAQ)} exact />
                <Route path="/create-faqs" component={withAuth(CreateFAQ)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__REGISTRATION_HEADER) && (
              <>
                <Route path="/registration-header" component={RegistrationContent} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__REGISTRATION_CONTENT) && (
              <>
                <Route path="/registration-content" component={RegistrationContent} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.STATIC_CONTENT__HELP_SECTION) && (
              <>
                <Route path="/manage-help-section" component={withAuth(ManageHelpSection)} exact />
                <Route path="/create-help-section" component={withAuth(CreateHelpSection)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_CATEGORY) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_CATEGORY__MANAGE_RENT_TYPE) && (
              <>
                <Route path="/manage-rent-types" component={withAuth(ManageRentTypes)} exact />
                <Route path="/create-rent-type" component={withAuth(CreateRentType)} exact />
                <Route path="/edit-rent-type/:id" component={withAuth(CreateRentType)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_CATEGORY__MANAGE_MARINA_TYPE) && (
              <>
                <Route path="/manage-marina-types" component={withAuth(ManageMarinaTypes)} exact />
                <Route path="/create-marina-types" component={withAuth(CreateMarinTypes)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_CATEGORY__MANAGE_BOAT_TYPES) && (
              <>
                <Route path="/manage-boat-types" component={withAuth(ManageBoatTypes)} exact />
                <Route path="/create-boat-types" component={withAuth(CreateBoatTypes)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_CATEGORY__MANAGE_YATCH_SERVICE) && (
              <>
                <Route path="/manage-yatch-types" component={withAuth(ManageYatchServiceTypes)} exact />
                <Route path="/create-yatch-types" component={withAuth(CreateYatchServiceTypes)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.COUNTRY) && (
          <>
            {allProps.hasPermission(AdminPermissions.COUNTRY__MANAGE_COUNTRIES) && (
              <>
                <Route path="/manage-country" component={withAuth(ManageCountries)} exact />
                <Route path="/add-country" component={withAuth(AddCountry)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.COUNTRY__MANAGE_STATE_PROVINCE) && (
              <>
                <Route path="/manage-state" component={withAuth(ManageStates)} exact />
                <Route path="/add-state" component={withAuth(AddStateProvince)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.FORMS) && (
          <>
            {allProps.hasPermission(AdminPermissions.FORMS__COMPLAINT) && (
              <>
                <Route path="/complaint" component={withAuth(Complaint)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FORMS__SUGGESTIONS) && (
              <>
                <Route path="/suggestions" component={withAuth(Suggestions)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FORMS__CUSTOMER_SERVICES) && (
              <>
                <Route path="/customer-service" component={withAuth(Customer)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.FORMS__FEEDBACK) && (
              <>
                <Route path="/feedback" component={withAuth(Feedback)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.SALES_ENGINE) && (
          <>
            {allProps.hasPermission(AdminPermissions.SALES_ENGINE__COMMISSION_AND_SERVICE_FEES) && (
              <>
                <Route path="/manage-sales-engin-commision" component={withAuth(SalesEnginCommision)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.SALES_ENGINE__RELEASE_PAYMENT) && (
              <>
                <Route path="/manage-sales-engin-release-payment" component={withAuth(SalesEnginReleasePayment)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.SALES_ENGINE__SALES_ENGINE_PAYMENT) && (
              <>
                <Route path="/manage-sales-engin-payment" component={withAuth(SalesEnginPayment)} exact />
                <Route path="/manage-sales-engin-release-money" component={withAuth(SalesEnginReleaseTransactions)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.SALES_ENGINE__SALES_ENGINE_PROGRESS) && (
              <>
                <Route path="/sales-engin-progress" component={withAuth(SalesEnginProgress)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.SALES_ENGINE__MANAGE_SALES_ENGINE) && (
              <>
                {/* <Route path="/sales-engine-listing" component={withAuth(SalesEngineListing)} exact /> */}
                <Route path="/sales-engine-pending-listing" component={withAuth(SalesEnginePendingListing)} exact />
              </>
            )}
            {/* {allProps.hasPermission(AdminPermissions.SALES_ENGINE__ARCHIVE_SALES_PROCESS) && (
              <>
                <Route path="/sales-engine-archive-listing" component={withAuth(SalesEngineArchiveListing)} exact />
              </>
            )} */}
            {allProps.hasPermission(AdminPermissions.SALES_ENGINE__BLACKLIST_SALES_PROCESS) && (
              <>
                <Route path="/view-sales-engine-blacklist" component={withAuth(SalesEngineBlacklist)} exact />
              </>
            )}
            {/* {allProps.hasPermission(AdminPermissions.SALES_ENGINE__MANAGE_HELP_CONTENT) && (
              <>
                <Route path="/sale-engine-help-content" component={withAuth(ManageHelpContent)} exact />
              </>
            )} */}
            {/* {allProps.hasPermission(AdminPermissions.SALES_ENGINE__MANAGE_STATIC_CONTENT) && (
              <>
                <Route path="/manage-static-sales-content" component={withAuth(ManageStaticSalesContent)} exact />
              </>
            )} */}
            {allProps.hasPermission(AdminPermissions.SALES_ENGINE__MANAGE_SURVEY_DOCUMENT_DATA) && (
              <>
                <Route path="/survey-document-data" component={withAuth(ManageSurveyorDocumentData)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_BANNER_IMAGES) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__HOME_PAGE_BANNER) && (
              <>
                <Route path="/home-banner" component={withAuth(HomeBanner)} exact />
                <Route path="/add-home-banner" component={withAuth(CreateBannerImages)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_RENT_BANNER) && (
              <>
                <Route path="/rent-banners" component={withAuth(MarketRentBanner)} exact />
                <Route path="/add-rent-banner" component={withAuth(CreateMarketRent)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__BOAT_SHOW_BANNER) && (
              <>
                <Route path="/boatshow-banners" component={withAuth(MarketBoatShowBanner)} exact />
                <Route path="/add-boatshow-banner" component={withAuth(CreateMarketBoatShow)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_SERVICE_BANNER) && (
              <>
                <Route path="/service-banners" component={withAuth(MarketServiceBanner)} exact />
                <Route path="/add-service-banner" component={withAuth(CreateMarketService)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__BOAT_MEDIA_BANNER) && (
              <>
                <Route path="/boat-media-banners" component={withAuth(MarketBoatMediaBanner)} exact />
                <Route path="/add-boat-media-banner" component={withAuth(CreateMarketBoatMedia)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__MARKET_MARINA_BANNER) && (
              <>
                <Route path="/marina-banners" component={withAuth(MarketMarinaBanner)} exact />
                <Route path="/add-marina-banner" component={withAuth(CreateMarketMarina)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__ARTICLE_BANNER) && (
              <>
                <Route path="/manage-article-banners" component={withAuth(ManageArticleBanner)} exact />
                <Route path="/create-article-banner" component={withAuth(CreateArticleBanner)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__DOWNLOAD_BANNER) && (
              <>
                <Route path="/manage-download-banners" component={withAuth(ManageDownLoadBanner)} exact />
                <Route path="/create-download-banner" component={withAuth(CreateDownloadBanner)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__FAQ_BANNER) && (
              <>
                <Route path="/manage-faq-banners" component={withAuth(ManageFaqBanner)} exact />
                <Route path="/create-faq-banner" component={withAuth(CreateFaqBanner)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__USER_GUIDE_BANNER) && (
              <>
                <Route path="/manage-user-guide-banners" component={withAuth(ManageUserGuideBanner)} exact />
                <Route path="/create-user-guide-banner" component={withAuth(CreateUserGuideBanner)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__OTHER_PAGE_BANNER) && (
              <>
                <Route path="/manage-other-page-banners" component={withAuth(ManageOtherPageBanner)} exact />
                <Route path="/create-other-page-banner" component={withAuth(CreateOtherPageBanner)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__ADMIN_LOGIN_BANNER) && (
              <>
                <Route path="/manage-admin-login-banners" component={withAuth(ManageAdminLoginBanner)} exact />
                <Route path="/create-admin-login-banner" component={withAuth(CreateAdminLoginBanner)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__LOGIN_BANNER) && (
              <>
                <Route path="/manage-login-banners" component={withAuth(ManageLogInBanner)} exact />
                <Route path="/create-login-banner" component={withAuth(CreateLogInBanner)} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_BANNER_IMAGES__SIGNUP_BANNER) && (
              <>
                <Route path="/manage-signup-banners" component={withAuth(ManageSignUpBanner)} exact />
                <Route path="/create-signup-banner" component={withAuth(CreateSignUpBanner)} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_AUCTION_ROOMS) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOMS) && (
              <>
                <Route path="/manage-auction-room" component={withAuth(ManageAuctionRoom)} exact />
                <Route path="/manage-auction-room-bids" component={ManageAuctionBids} exact />
                <Route path="/manage-auction-room-depositors" component={ManageAuctionDepositors} exact />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_AUCTION_ROOMS__MANAGE_AUCTION_ROOM_PAYMENTS) && (
              <>
                <Route path="/manage-auction-room-payment" component={withAuth(ManageAuctionRoomPayment)} exact />
                <Route path="/manage-auction-room-transactions" component={ManageAuctionTransactions} exact />
                <Route path="/manage-sales-engin-transactions" component={SalesEnginPaymentTransactions} exact />
              </>
            )}
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_SURVEYOR_BRANCH) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_SURVEYOR_BRANCH__ALL_BRANCHES) && (
              <>
                <Route path="/all-branches" component={withAuth(ManageAllBranches)} exact />
              </>
            )}
          </>
        )}

        {allProps.hasPermission(AdminPermissions.ACTIVITY_LOGS) && (
          <>
            <Route path="/activity-log" component={withAuth(ActivityLogs)} exact />
          </>
        )}

        {allProps.hasPermission(AdminPermissions.ADMIN_ACTIVITY_LOGS) && (
          <>
            <Route path="/admin-activity-log" component={withAuth(AdminActivityLogs)} exact />
          </>
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.MANAGE_REVIEWS) && (
          <>
            {allProps.hasPermission(AdminPermissions.MANAGE_REVIEWS__BOAT_OWNER_REVIEWS) && (
              <>
                <Route
                  path="/manage-review-ratings/:type"
                  component={withAuth((props: any) => (
                    <ManageReviewRatings {...props} validType="boat-owner" />
                  ))}
                  exact
                />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_REVIEWS__BOAT_MANUFACTURER_REVIEWS) && (
              <>
                <Route
                  path="/manage-review-ratings/:type"
                  component={withAuth((props: any) => (
                    <ManageReviewRatings {...props} validType="boat-manufacturer" />
                  ))}
                  exact
                />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_REVIEWS__SHIPPER_REVIEWS) && (
              <>
                <Route
                  path="/manage-review-ratings/:type"
                  component={withAuth((props: any) => (
                    <ManageReviewRatings {...props} validType="yacht-shipper" />
                  ))}
                  exact
                />
              </>
            )}
            {allProps.hasPermission(AdminPermissions.MANAGE_REVIEWS__BROKER_AND_DEALER_REVIEWS) && (
              <>
                <Route
                  path="/manage-review-ratings/:type"
                  component={withAuth((props: any) => (
                    <ManageReviewRatings {...props} validType="broker-and-dealer" />
                  ))}
                  exact
                />
              </>
            )}
          </>
        )}
        {allProps.hasPermission(AdminPermissions.TIMER_SETTINGS) && (
          <Route path="/settings/timer" component={withAuth(TimerSettings)} exact />
        )}

        {allProps.haveAnyPermissionInGroup(AdminPermissionGroups.SOCIAL_MEDIA) && (
          <>
            {allProps.hasPermission(AdminPermissions.SOCIAL_MEDIA__POST_REPORTS) && (
              <>
                <Route path="/social-media/post-reports" component={withAuth(PostsWithReports)} exact />
                <Route path="/social-media/post-reports/:postId" component={withAuth(PostReports)} exact />
              </>
            )}
          </>
        )}

        <Route path="/manage-agreements" component={ManageDashboardAgreements} exact />
        <Route path="/manage-advertisements" component={withAuth(ManageDashboardAdvertisements)} exact />
        <Route path="/sales-man" component={withAuth(SalesManDashboard)} exact />
        <Route path="/create-sales-man" component={withAuth(CreateSalesMan)} exact />
        <Route path="/edit-agreement-content/:id" component={withAuth(CreateAgreementContent)} exact />
        <Route path="/create-page" component={withAuth(CreatePage)} exact />
        <Route path="/cost-estimate" component={withAuth(CreateCostEstimate)} exact />
        <Route path="/view-builder-agreements" component={withAuth(ViewBuilderAgreement)} exact />
        <Route path="/view-member" component={withAuth(ViewMember)} exact />
        <Route path="/add-tex" component={withAuth(CreateCanadianTax)} exact />
        <Route path="/canadian-tax" component={withAuth(CanadianTax)} exact />
        <Route path="/boat-details/:id" component={withAuth(BoatDetails)} exact />
        <Route path="/manage-reviews" component={withAuth(ManageReviews)} exact />
        <Route path="/view-sales-engine" component={withAuth(ViewSalesEngine)} exact />

        <Route path="/search-page" component={withAuth(SearchTabs)} exact />

        {/* complaints */}

        {/* forms */}
        <Route path="/multimedia" component={withAuth(Multimedia)} exact />
        <Route path="/partner" component={withAuth(Partner)} exact />
        <Route path="/career" component={withAuth(Career)} exact />
        <Route path="/contact-us" component={withAuth(ContactUs)} exact />
        <Route path="/callmeback" component={withAuth(CallMeBack)} exact />
        <Route path="/all-leads" component={withAuth(AdminLeads)} exact />
        {/* end forms */}

        {/* Manage Boats */}

        {/* End - Manage Boats */}

        <Route path="/change-password" component={withAuth(ChangePassword)} exact />

        

        {/* manage review ratings */}
        <Route path="/" component={() => <Redirect to="/front-users" />} exact />
      </>
    </Switch>
  )
}
export default AppRouter
