import React, { Component } from 'react'
import { VerticalGraph } from '../../../component/charts/VerticalGraph'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Card, Row, Col, Typography, Avatar } from 'antd'
import { CircularGraph } from '../../../component/charts/CircularGraph'
import { getAuctionRoomCount } from '../../../store/dashboard/auctionRoom/Action'
import { Link } from 'react-router-dom'
const { Title } = Typography

export interface Props {
  getAuctionRoomCount: typeof getAuctionRoomCount
  auctionRoomCountSuccess: boolean
  auctionRoomCount: any
}
export interface State {}

class ManageDashboardAuctionRoom extends Component<Props, State> {
  state = {}

  componentDidMount() {
    const { getAuctionRoomCount } = this.props
    getAuctionRoomCount()
  }

  render() {
    const { auctionRoomCount } = this.props
    return (
      <>
        <div style={{ background: '#ECECEC', padding: '30px' }}>
          <Row gutter={16}>
            <Col span={6}>
              <Link to="/manage-auction-room">
                <Card title="AUCTION ROOM" bordered={false}>
                  <div className="d-flex justify-content-between">
                    <Col span={8}>
                      <img
                        src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                        height="50px"
                        width="50px"
                      />
                    </Col>
                    <Col span={8}>
                      <Title level={2} type="secondary">
                        {auctionRoomCount?.auctionRoom}
                      </Title>
                    </Col>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col span={6}>
              <Link to="/manage-auction-room">
                <Card title="PENDING REQUEST" bordered={false}>
                  <div className="d-flex justify-content-between">
                    <Col span={8}>
                      <img
                        src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                        height="50px"
                        width="50px"
                      />
                    </Col>
                    <Col span={8}>
                      <Title level={2} type="secondary">
                        {auctionRoomCount?.pendingRequest}
                      </Title>
                    </Col>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col span={6}>
              <Link to="/manage-auction-room">
                <Card title="COMING SOON" bordered={false}>
                  <div className="d-flex justify-content-between">
                    <Col span={8}>
                      <img
                        src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                        height="50px"
                        width="50px"
                      />
                    </Col>
                    <Col span={8}>
                      <Title level={2} type="secondary">
                        {auctionRoomCount?.comingSoon}
                      </Title>
                    </Col>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col span={6}>
              <Link to="/manage-auction-room">
                <Card title="BACK TO MARKET" bordered={false}>
                  <div className="d-flex justify-content-between">
                    <Col span={8}>
                      <img
                        src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                        height="50px"
                        width="50px"
                      />
                    </Col>
                    <Col span={8}>
                      <Title level={2} type="secondary">
                        {auctionRoomCount?.backToMarket}
                      </Title>
                    </Col>
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row gutter={16} style={{marginTop:'12px'}}>
          <Col span={6}>
              <Link to="/manage-auction-room">
                <Card title="COMPLETED AUCTION" bordered={false}>
                  <div className="d-flex justify-content-between">
                    <Col span={8}>
                      <img
                        src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                        height="50px"
                        width="50px"
                      />
                    </Col>
                    <Col span={8}>
                      <Title level={2} type="secondary">
                        {auctionRoomCount?.completedAuction}
                      </Title>
                    </Col>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col span={6}>
              <Link to="/manage-auction-room">
                <Card title="NO BIDS" bordered={false}>
                  <div className="d-flex justify-content-between">
                    <Col span={8}>
                      <img
                        src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                        height="50px"
                        width="50px"
                      />
                    </Col>
                    <Col span={8}>
                      <Title level={2} type="secondary">
                        {auctionRoomCount?.noBids}
                      </Title>
                    </Col>
                  </div>
                </Card>
              </Link>
            </Col>
            <Col span={6}>
              <Link to="/manage-auction-room">
                <Card title="CLOSED" bordered={false}>
                  <div className="d-flex justify-content-between">
                    <Col span={8}>
                      <img
                        src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                        height="50px"
                        width="50px"
                      />
                    </Col>
                    <Col span={8}>
                      <Title level={2} type="secondary">
                        {auctionRoomCount?.closed}
                      </Title>
                    </Col>
                  </div>
                </Card>
              </Link>
            </Col>
          </Row>
        </div>
        <br />
        <div style={{marginLeft:'10%', width:'75%'}}>
            <CircularGraph 
            alignLabels='left'
            chartTitle={''} 
            labels={['AUCTION ROOM', 'PENDING REQUEST', 'CLOSED', 'BACK TO MARKET', 'NO BIDS', 'COMING SOON', 'COMPLETED AUCTION']} 
            data={[auctionRoomCount?.auctionRoom, auctionRoomCount?.pendingRequest, auctionRoomCount?.closed, auctionRoomCount?.backToMarket, auctionRoomCount?.noBids, auctionRoomCount?.comingSoon, auctionRoomCount?.completedAuction]} />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  auctionRoomCount: state.auctionRoomCountReducer && state.auctionRoomCountReducer.auctionRoomCount,
  auctionRoomCountSuccess: state.auctionRoomCountReducer && state.auctionRoomCountReducer.auctionRoomCountSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAuctionRoomCount: () => dispatch(getAuctionRoomCount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDashboardAuctionRoom)
