import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { auctionRoomDashboard } from './Schema'
import { GET_AUCTION_ROOM_COUNT_SUCCESS, GET_AUCTION_ROOM_COUNT_FAILURE, GET_AUCTION_ROOM_COUNT } from './Type'

function getAuctionRoomCountApi() {
  return client
    .query({
      query: auctionRoomDashboard,
      fetchPolicy: 'no-cache',
    })
    
}

function* getAuctionRoomCountData() {
  try {
    const res = yield getAuctionRoomCountApi()
    yield put({ type: GET_AUCTION_ROOM_COUNT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_AUCTION_ROOM_COUNT_FAILURE, error })
  }
}

function* getAuctionRoomSaga() {
  yield takeLatest(GET_AUCTION_ROOM_COUNT, getAuctionRoomCountData)
}

export default function* getManageAuctionRoomSaga() {
  yield all([getAuctionRoomSaga()])
}
