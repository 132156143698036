import React from 'react'
import Layout from './layout/Layout'

function App() {
  return (
    <div className="App h-100">
      <Layout />
    </div>
  )
}
export default React.memo(App)
