import React, { Component, Fragment } from 'react'

import { Card, Row, Col, Typography, Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Link } from 'react-router-dom'
import { PlusCircleOutlined } from '@ant-design/icons'

import { handleSingleFileUpload, handleSingleFileDelete } from '../../helpers/S3FileUpload'
import { addBannerImage, updateBannerImage } from '../../store/manageBannerImages/manageCommonBanner/actions'
import {
  metaType,
  metaTypeForSignUp,
  MEDIA_NAME,
  MEDIA_TYPE,
  bannerType,
  mediaPosition,
  imageExtensions,
  mediaCategory,
  userRolesSelectList,
} from '../../enums/enums'

import NotificationWithIcon from '../../component/notification/Notification'
import UploadImage from '../../component/UploadImage'
import { getAddMedia, removeTypeName, splitUrlFromUnderscore, trimmedValue } from '../../helpers/helper'
import WSIcon from '../../component/WSIcon'
import WSSelect from '../../component/WSSelect'
import History from '../../History'
const { Text } = Typography

export interface Props {
  addBannerImage: typeof addBannerImage
  updateBannerImage: typeof updateBannerImage
  isBannerCreated: boolean
  location: any
  history: typeof History
  isErrorBanner: boolean
  isUpdatedSuccess: boolean
  isUpdatedError: boolean
  backLink: string
  pageTitle: string
  titlePage: string
  type: string
  bannerLoader: boolean
  isSignUpBanner?: boolean
}
export interface State {
  editHomeBanner: any
  loading: boolean
  mediaPosition: string
  bannerImages: {
    type: string
    mediaOrder?: string
    metatype: string
    url: any[]
    title: string
    link: string
    thumbnail: any[]
    addMedia: any[]
    role?: string
  }
}

export interface AddRequest {
  type: string
  mediaOrder?: string
  metatype: string
  url: string
  bannerLoader: boolean
}

class CreateBannerIndex extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      editHomeBanner: props.location.state ? props.location.state.editHomeBanner : null,
      loading: false,
      mediaPosition: props.location.state ? props.location.state.editHomeBanner.mediaPosition : 'TOP',
      bannerImages: {
        type: props.type,
        metatype: 'image',
        url: [],
        mediaOrder: '',
        title: '',
        link: '',
        thumbnail: [],
        addMedia: [],
      },
    }

    if (this.props.type === mediaCategory.signUp.type) {
      this.state.bannerImages.role = '';
    }
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const { isBannerCreated, isErrorBanner, history, isUpdatedSuccess, isUpdatedError, backLink } = nextProps
    if (isBannerCreated) {
      NotificationWithIcon('success', 'Banner add successfully.')
      history.push(backLink)
    }
    if (isErrorBanner) {
      NotificationWithIcon('error', 'Problem while adding Banner.')
    }

    if (isUpdatedSuccess) {
      NotificationWithIcon('success', 'Banner updated successfully.')
      history.push(backLink)
    }
    if (isUpdatedError) {
      NotificationWithIcon('error', 'Problem while updating Banner.')
    }
    return {
      ...prevState,
    }
  }

  handleFileUpload = async (file: any, setFieldValue: any, name: string, values: any, acceptType: string) => {
    const { addMedia } = values
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(file, name, setFieldValue, addMedia, MEDIA_NAME.MEDIATHUMBNAIL, MEDIA_TYPE.IMAGE)
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleRemove = (file: any, setFieldValue: any, values: any, name: string) => {
    const { addMedia } = values
    handleSingleFileDelete(file, name, setFieldValue, addMedia, values[name], 'filter', values)
  }

  handleVideoUpload = async (file: any, setFieldValue: any, values: any, acceptType: string) => {
    const { addMedia } = values
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(file, 'url', setFieldValue, addMedia, MEDIA_NAME.MEDIAURL, MEDIA_TYPE.VIDEO)
      this.setState({ loading: false })
    } else {
      // NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleVideoRemove = (file: any, setFieldValue: any, values: any) => {
    const { addMedia } = values
    handleSingleFileDelete(file, 'url', setFieldValue, addMedia, values.url, 'filter', values)
  }

  prepareValue = () => {
    const { editHomeBanner } = this.state

    if (editHomeBanner) {
      editHomeBanner.addMedia = []
      this.setState({ bannerImages: editHomeBanner })
    }
  }

  componentDidMount() {
    this.prepareValue()
  }

  render() {
    const { bannerImages, editHomeBanner, loading } = this.state
    const { updateBannerImage, addBannerImage, backLink, pageTitle, titlePage, bannerLoader, isSignUpBanner } = this.props

    const imageSchema = Yup.object().shape({
      mediaOrder: Yup.string().required('Image order field is required.'),
      thumbnail: Yup.string().when('metatype', {
        is: val => val == 'image',
        then: Yup.string().required('Thumbnail field is required.'),
        otherwise: Yup.string(),
      }),
      // ...(this.props.type === mediaCategory.signUp.type ? { role: Yup.string().required('Role is required') } : {})
    })

    const videoSchema = Yup.object().shape({
      mediaOrder: Yup.string().required('Image order field is required.'),
      thumbnail: Yup.string().when('metatype', {
        is: val => val == 'image',
        then: Yup.string().required('Thumbnail field is required.'),
        otherwise: Yup.string(),
      }),
      url: Yup.string().required('Video field is required.'),
    })

    return (
      <Fragment>
        <Formik
          initialValues={editHomeBanner || bannerImages}
          onSubmit={values => {
            if (editHomeBanner) {
              const { __typename, videoUrl, icon, addMedia, url, thumbnail, type, ...newValues } = values
              newValues.mediaOrder = parseInt(newValues.mediaOrder)

              updateBannerImage({
                ...newValues,
                type: values.type,
                addMedia: getAddMedia(values.addMedia),
              })
            } else {
              const { __typename, videoUrl, icon, addMedia, url, thumbnail, ...newValues } = values
              newValues.mediaOrder = parseInt(newValues.mediaOrder)
              addBannerImage({
                ...newValues,
                addMedia: getAddMedia(values.addMedia),
              })
            }
          }}
          validationSchema={bannerImages.metatype === 'image' ? imageSchema : videoSchema}
        >
          {props => {
            const { handleSubmit, setFieldValue, values } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      {editHomeBanner ? `Edit ${pageTitle}` : `Add ${pageTitle}`}
                    </span>
                  }
                  extra={
                    <>
                      <Button>
                        <Link to={backLink}>Back</Link>
                      </Button>

                      {bannerLoader || !loading ? (
                        <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                          Save
                        </button>
                      ) : (
                        <Button loading={loading} className="ml-15 ant-btn ant-btn-primary">
                          Save
                        </Button>
                      )}
                    </>
                  }
                >
                  <Row gutter={16}>
                    {this.props.type === mediaCategory.signUp.type && (
                      <Col className="gutter-box" md={16}>
                        <span className="ant-form-item-required" title="Upload video">
                          User Role
                        </span>
                        <WSSelect
                          defaultValue={values.role}
                          onChangeText={(value: string) => setFieldValue('role', value)}
                          arrayList={userRolesSelectList}
                          passed="value"
                          listOptions="label"
                        />
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name={`role`} />
                        </Text>
                      </Col>
                    )}
                    <Col className="gutter-box" md={16}>
                      <span className="ant-form-item-required" title="Upload video">
                        Media Position
                      </span>
                      <WSSelect
                        defaultValue={values.mediaPosition}
                        onChangeText={(value: any) => {
                          setFieldValue('mediaPosition', value)
                          this.setState({
                            mediaPosition: value,
                          })
                        }}
                        arrayList={mediaPosition}
                      />
                    </Col>
                    {this.state.mediaPosition === 'TOP' && (
                      <Col className="gutter-box" md={16}>
                        <span className="ant-form-item-required" title="Upload video">
                          Category
                        </span>
                        <WSSelect
                          defaultValue={values.metatype}
                          onChangeText={(value: any) => {
                            setFieldValue('metatype', value)
                            this.setState((prevState: any) => ({
                              bannerImages: {
                                ...prevState.metatype,
                                metatype: value,
                              },
                            }))
                          }}
                          arrayList={isSignUpBanner ? metaTypeForSignUp && metaTypeForSignUp : metaType && metaType}
                        />
                      </Col>
                    )}

                    <Col className="gutter-box" md={16}>
                      <span className="ant-form-item-required" title="Image Order">
                        Image Order
                      </span>
                      <Field
                        className="ant-input"
                        name="mediaOrder"
                        type="number"
                        value={values.mediaOrder}
                        placeholder="Image Order"
                        onChange={(e: any) => {
                          setFieldValue('mediaOrder', e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`mediaOrder`} />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={16}>
                      <span className="ant-form-item-required" title="Image Order">
                        Title
                      </span>
                      <Field
                        className="ant-input"
                        name="title"
                        value={values.title}
                        placeholder="Title"
                        onChange={(e: any) => {
                          setFieldValue('title', e.target.value)
                        }}
                        onBlur={(e: { target: { value: string } }) => setFieldValue('title', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`title`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={16}>
                      <span className="ant-form-item-required" title="Image Order">
                        Description
                      </span>
                      <Field
                        as="textarea"
                        className="ant-input"
                        name="description"
                        value={values.description}
                        placeholder="Short Description"
                        onChange={(e: any) => {
                          setFieldValue('description', e.target.value)
                        }}
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('description', trimmedValue(e.target.value))
                        }
                      />
                    </Col>
                    {this.state.mediaPosition !== 'TOP' && (
                      <Col className="gutter-box" md={16}>
                        <span className="ant-form-item-required" title="Image Order">
                          Link
                        </span>
                        <Field
                          className="ant-input"
                          name="link"
                          value={values.link}
                          placeholder="Link"
                          onChange={(e: any) => {
                            setFieldValue('link', e.target.value)
                          }}
                        />
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name={`link`} component="span" />
                        </Text>
                      </Col>
                    )}
                  </Row>

                  <Row className="d-flex align-items-center mt-5">
                    <Col className="gutter-box pl-8 pr-8" md={16}>
                      <div className="d-flex flex-wrap justify-content-center">
                        <div className="addBoatShow-imgUploader">
                          {values.thumbnail && values.thumbnail.length ? (
                            <UploadImage
                              defaultFileList={values.thumbnail.map((item: any) => {
                                return {
                                  uid: item.id,
                                  name: item.name || 'Thumbnail',
                                  status: 'done',
                                  url: item.url,
                                }
                              })}
                              openFileDialogOnClick={values.thumbnail && !values.thumbnail.length}
                              withIcon
                              accept={imageExtensions}
                              buttonText={values.metatype === 'image' ? `${titlePage} Banner` : 'Thumbnail'}
                              selectSiteIcon={(file: any) => {
                                this.handleFileUpload(file, setFieldValue, 'thumbnail', values, 'image')
                              }}
                              onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values, 'thumbnail')}
                            />
                          ) : (
                            <UploadImage
                              openFileDialogOnClick={values.thumbnail && !values.thumbnail.length}
                              withIcon
                              accept={imageExtensions}
                              buttonText={values.metatype === 'image' ? `${titlePage} Banner` : 'Thumbnail'}
                              selectSiteIcon={(file: any) => {
                                this.handleFileUpload(file, setFieldValue, 'thumbnail', values, 'image')
                              }}
                              onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values, 'thumbnail')}
                            />
                          )}
                          <Text type="danger">
                            <ErrorMessage className="invalid-feedback ant-typography-danger" name={`thumbnail`} />
                          </Text>
                        </div>
                      </div>
                    </Col>

                    {values.metatype !== 'image' && this.state.mediaPosition === 'TOP' && (
                      <Col className="gutter-box pl-8 pr-8" md={16}>
                        {values.url && values.url.length ? (
                          <UploadImage
                            defaultFileList={values.url.map((item: any) => {
                              return {
                                uid: item.id,
                                name: item.name || 'Video',
                                status: 'done',
                                url: item.url,
                              }
                            })}
                            withIcon
                            openFileDialogOnClick={values.url && !values.url.length}
                            accept="video/*"
                            buttonText="Upload Video"
                            selectSiteIcon={(file: any) => {
                              this.handleVideoUpload(file, setFieldValue, values, 'video')
                            }}
                            onRemove={(rem: any) => this.handleVideoRemove(rem, setFieldValue, values)}
                          />
                        ) : (
                          <UploadImage
                            openFileDialogOnClick={values.url && !values.url.length}
                            withIcon
                            accept="video/*"
                            buttonText="Upload Video"
                            selectSiteIcon={(file: any) => this.handleVideoUpload(file, setFieldValue, values, 'video')}
                            onRemove={(rem: any) => this.handleVideoRemove(rem, setFieldValue, values)}
                          />
                        )}
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback ant-typography-danger" name={`url`} />
                        </Text>
                      </Col>
                    )}
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}
const mapStateToProps = (state: any) => ({
  isBannerCreated: state.bannerReducer && state.bannerReducer.isHomeBannerCreated,
  isErrorBanner: state.bannerReducer && state.bannerReducer.isHomeBannerError,
  isUpdatedSuccess: state.bannerReducer && state.bannerReducer.isHomeBannerUpdateSuccess,
  isUpdatedError: state.bannerReducer && state.bannerReducer.isHomeBannerUpdateError,
  bannerLoader: state.bannerReducer.bannerLoader,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addBannerImage: (data: AddRequest) => dispatch(addBannerImage(data)),
  updateBannerImage: (data: AddRequest) => dispatch(updateBannerImage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateBannerIndex)
