import {
  downloadsData,
  GET_ALL_DOWNLOADS,
  CREATE_DOWNLOADS,
  UPDATE_DOWNLOADS,
  DELETE_DOWNLOADS,
  CLEAR_DOWNLOADS_FLAG,
} from './Type'

export function getAllDownloads(data: any) {
  return {
    type: GET_ALL_DOWNLOADS,
    payload: data,
  }
}

export function createDownloads(data: downloadsData) {
  return {
    type: CREATE_DOWNLOADS,
    payload: data,
  }
}

export function updateDownloads(data: downloadsData) {
  return {
    type: UPDATE_DOWNLOADS,
    payload: data,
  }
}

export function deleteDownloads(data: string) {
  return {
    type: DELETE_DOWNLOADS,
    payload: data,
  }
}

export function clearDownloadsFlag() {
  return {
    type: CLEAR_DOWNLOADS_FLAG,
  }
}
