import {
  CREATE_COUNTRY,
  countryData,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAILURE,
  GET_SINGLE_COUNTRY,
  GET_SINGLE_COUNTRY_FAILURE,
  GET_SINGLE_COUNTRY_SUCCESS,
  CHANGE_COUNTRY_STATUS,
  CHANGE_COUNTRY_STATUS_SUCCESS,
  CLEAR_COUNTRY_FLAG,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
  CHANGE_COUNTRY_STATUS_FAILURE,
  countryStatus,
  SEARCH_COUNTRY,
} from './Type'

export function getCountries(data?: any) {
  return {
    type: GET_SINGLE_COUNTRY,
    payload: data,
  }
}
export function getCountriesSuccess(id: string) {
  return {
    type: GET_SINGLE_COUNTRY_SUCCESS,
    payload: id,
  }
}
export function getCountriesFailure(id: string) {
  return {
    type: GET_SINGLE_COUNTRY_FAILURE,
    payload: id,
  }
}

export function createCountry(data: countryData) {
  return {
    type: CREATE_COUNTRY,
    payload: data,
  }
}

export function createCountrySuccess(data: countryData) {
  return {
    type: CREATE_COUNTRY_SUCCESS,
    payload: data,
  }
}

export function changeCountryStatus(data: countryStatus) {
  return {
    type: CHANGE_COUNTRY_STATUS,
    payload: data,
  }
}

export function changeCountryStatusSuccess(data: any) {
  return {
    type: CHANGE_COUNTRY_STATUS_SUCCESS,
    payload: data,
  }
}

export function changeCountryStatusFailure() {
  return {
    type: CHANGE_COUNTRY_STATUS_FAILURE,
  }
}

export function clearCountryFlag() {
  return {
    type: CLEAR_COUNTRY_FLAG,
  }
}

export function updateCountry(data: countryData) {
  return {
    type: UPDATE_COUNTRY,
    payload: data,
  }
}

export function deleteCountry(data: string) {
  return {
    type: DELETE_COUNTRY,
    payload: data,
  }
}

export const searchCountry = (payload: any) => ({
  type: SEARCH_COUNTRY,
  payload
})