export interface countryState {
  countries: any
  isLoading: boolean
  countrySuccess: boolean
  countryError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  updateSuccess: boolean
  updateError: boolean
  statusSuccess: boolean
  statusError: boolean
  countriesTotalRow: number
}
interface imageArray {
  mediaId: string
  url: string
}
interface addMediaTypes {
  key: string
  type: string
  mediaName: string
  mediaType: string
}
export interface countryData {
  data?: any
  id?: String
  name: String
  ISOCode: String
  countryPhoneCode: String
  images?: any[]
  addMedia: addMediaTypes[]
}
export interface countryStatus {
  id?: String
  column: String
  value?: boolean
}

export const GET_SINGLE_COUNTRY = 'GET_SINGLE_COUNTRY'
export const GET_SINGLE_COUNTRY_SUCCESS = 'GET_SINGLE_COUNTRY_SUCCESS'
export const GET_SINGLE_COUNTRY_FAILURE = 'GET_SINGLE_COUNTRY_FAILURE'

export const CREATE_COUNTRY = 'CREATE_COUNTRY'
export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS'
export const CREATE_COUNTRY_FAILURE = 'CREATE_COUNTRY_FAILURE'

export const UPDATE_COUNTRY = 'UPDATE_COUNTRY'
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS'
export const UPDATE_COUNTRY_FAILURE = 'UPDATE_COUNTRY_FAILURE'

export const DELETE_COUNTRY = 'DELETE_COUNTRY'
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS'
export const DELETE_COUNTRY_FAILURE = 'DELETE_COUNTRY_FAILURE'

export const CLEAR_COUNTRY_FLAG = 'CLEAR_COUNTRY_FLAG'

export const CHANGE_COUNTRY_STATUS = 'CHANGE_COUNTRY_STATUS'
export const CHANGE_COUNTRY_STATUS_SUCCESS = 'CHANGE_COUNTRY_STATUS_SUCCESS'
export const CHANGE_COUNTRY_STATUS_FAILURE = 'CHANGE_COUNTRY_STATUS_FAILURE'

export const SEARCH_COUNTRY = 'SEARCH_COUNTRY';
export const SEARCH_COUNTRY_SUCCESS = 'SEARCH_COUNTRY_SUCCESS'
export const SEARCH_COUNTRY_FAILURE = 'SEARCH_COUNTRY_FAILURE'

interface getManageCountry {
  type: typeof GET_SINGLE_COUNTRY
  payload: any
}

interface getManageCountrySuccess {
  type: typeof GET_SINGLE_COUNTRY_SUCCESS
  payload: any
}
interface getManageCountryFailure {
  type: typeof GET_SINGLE_COUNTRY_FAILURE
  payload: any
}

interface createManageCountry {
  type: typeof CREATE_COUNTRY
  payload: any
}

interface createManageCountrySuccess {
  type: typeof CREATE_COUNTRY_SUCCESS
  payload: any
}

interface createManageCountryFailure {
  type: typeof CREATE_COUNTRY_FAILURE
  payload: any
}

interface updateManageCountry {
  type: typeof UPDATE_COUNTRY
  payload: countryData
}

interface updateManageCountrySuccess {
  type: typeof UPDATE_COUNTRY_SUCCESS
  payload: countryData
}

interface updateManageCountryFailure {
  type: typeof UPDATE_COUNTRY_FAILURE
  payload: countryData
}

interface clearCountryFlag {
  type: typeof CLEAR_COUNTRY_FLAG
  payload: any
}

interface deleteManageCountry {
  type: typeof DELETE_COUNTRY
  payload: countryData
}

interface deleteManageCountrySuccess {
  type: typeof DELETE_COUNTRY_SUCCESS
  payload: countryData
}

interface deleteManageCountryFailure {
  type: typeof DELETE_COUNTRY_FAILURE
  payload: countryData
}

interface changeCountryStatus {
  type: typeof CHANGE_COUNTRY_STATUS
  payload: any
}

interface changeCountryStatusSuccess {
  type: typeof CHANGE_COUNTRY_STATUS_SUCCESS
  payload: any
}

interface changeCountryStatusFailure {
  type: typeof CHANGE_COUNTRY_STATUS_FAILURE
  payload: any
}

interface searchCountry {
  type: typeof SEARCH_COUNTRY
  payload: any
}

interface searchCountrySucsess {
  type: typeof SEARCH_COUNTRY_SUCCESS,
  payload: any
}

interface searchCountryFailure {
  type: typeof SEARCH_COUNTRY_FAILURE
}

export type ActionType =
  | getManageCountry
  | getManageCountrySuccess
  | getManageCountryFailure
  | createManageCountry
  | createManageCountrySuccess
  | createManageCountryFailure
  | updateManageCountry
  | updateManageCountrySuccess
  | updateManageCountryFailure
  | clearCountryFlag
  | deleteManageCountry
  | deleteManageCountrySuccess
  | deleteManageCountryFailure
  | changeCountryStatus
  | changeCountryStatusSuccess
  | changeCountryStatusFailure
  | searchCountry
  | searchCountrySucsess
  | searchCountryFailure
