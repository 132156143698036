import React from 'react'
import { Button } from 'antd'
import { confirmSubmitHandler } from './ConfirmSubmitHandler'
import WSToolTip from './WsToolTip'

const ApproveButton = (props: any) => {
  const { action, input, className: cname, buttonTitle, visibility, tooltip } = props

  return (
    <WSToolTip title={tooltip || ''}>
      <Button {...props} disabled={visibility} onClick={() => confirmSubmitHandler(action, input)} className={cname}>
        {buttonTitle}
      </Button>
    </WSToolTip>
  )
}
export default ApproveButton
