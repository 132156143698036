export interface siteConfigurationState {
  isLoading: Boolean
  success: Boolean
  error: Boolean
  isFlag: boolean
  getSiteConfigSuccess: boolean
  generalConfigurations: any
}

export const ADD_SITE_CONFIGURATION = 'ADD_SITE_CONFIGURATION'
export const ADD_SITE_CONFIGURATION_SUCCESS = 'ADD_SITE_CONFIGURATION_SUCCESS'
export const ADD_SITE_CONFIGURATION_FAILURE = 'ADD_SITE_CONFIGURATION_FAILURE'
export const UPDATE_SITE_CONFIGURATION = 'UPDATE_SITE_CONFIGURATION'
export const UPDATE_SITE_CONFIGURATION_SUCCESS = 'UPDATE_SITE_CONFIGURATION_SUCCESS'
export const UPDATE_SITE_CONFIGURATION_FAILURE = 'UPDATE_SITE_CONFIGURATION_FAILURE'
export const GET_SITE_CONFIGURATION = 'GET_SITE_CONFIGURATION'
export const GET_SITE_CONFIGURATION_SUCCESS = 'GET_SITE_CONFIGURATION_SUCCESS'
export const GET_SITE_CONFIGURATION_FAILURE = 'GET_SITE_CONFIGURATION_FAILURE'
export const CLEAR_CONFIG_FLAG = 'CLEAR_CONFIG_FLAG'

interface addSiteConfiguration {
  type: typeof ADD_SITE_CONFIGURATION
  payload: any
}

interface addSiteConfigurationSuccess {
  type: typeof ADD_SITE_CONFIGURATION_SUCCESS
  payload: any
}

interface getSiteConfiguration {
  type: typeof GET_SITE_CONFIGURATION
  payload: any
}

interface getSiteConfigurationSuccess {
  type: typeof GET_SITE_CONFIGURATION_SUCCESS
  payload: any
}

interface getSiteConfigurationError {
  type: typeof GET_SITE_CONFIGURATION_FAILURE
  payload: any
}
interface clearConfigFlag {
  type: typeof CLEAR_CONFIG_FLAG
  payload: any
}

interface updateSiteConfiguration {
  type: typeof UPDATE_SITE_CONFIGURATION
  payload: any
}

interface updateSiteConfigurationSuccess {
  type: typeof UPDATE_SITE_CONFIGURATION_SUCCESS
  payload: any
}

interface updateSiteConfigurationError {
  type: typeof UPDATE_SITE_CONFIGURATION_FAILURE
  payload: any
}

export type ActionType =
  | getSiteConfiguration
  | getSiteConfigurationSuccess
  | addSiteConfiguration
  | addSiteConfigurationSuccess
  | updateSiteConfiguration
  | updateSiteConfigurationSuccess
  | updateSiteConfigurationError
  | clearConfigFlag
  | getSiteConfigurationError
