import { GET_ADMIN_OTHER_COUNT, GET_CURRENCY_RATES, GET_DASHBOAD_VERIFICATION_ALERT } from './Type'

export const getAdminOtherCount = () => ({
  type: GET_ADMIN_OTHER_COUNT,
})

export const getCurrencyData = () => ({
  type: GET_CURRENCY_RATES,
})

export const getDashboardVerificationAlertCount = () => ({
  type: GET_DASHBOAD_VERIFICATION_ALERT
})