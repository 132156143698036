export interface allBranchesState {
  allBranches: allBranchesData[]
  allBranchesSuccess: boolean
  allBranchesFailure: boolean
  deleteSurveyorSuccess: boolean
  deleteSurveyorFailure: boolean
  branchStatusSuccess: boolean
  branchStatusError: boolean
  allBranchesTotal: number
  branchesBySurveyorSuccess: boolean
  branchesBySurveyorError: boolean
  surveyorBranches: allBranchesData[]
  isLoading: boolean
}

export interface surveyorStatusData {
  value: boolean
  columnName: string
  id: string
}

export interface allBranchesData {
  id: string
  contactName: string
  emailAddress: string
  address: any
  branchStatus: boolean
  branchVerificationStatus: boolean
  user: {
    companyName: string
  }
}

export interface getBranchesData {
  user: string
  limit?: number
  page?: number
}

export const GET_ALL_SURVEYORS = 'GET_ALL_SURVEYORS'
export const GET_ALL_SURVEYORS_SUCCESS = 'GET_ALL_SURVEYORS_SUCCESS'
export const GET_ALL_SURVEYORS_FAILURE = 'GET_ALL_SURVEYORS_FAILURE'

export const DELETE_SURVEYORS = 'DELETE_SURVEYORS'
export const DELETE_SURVEYORS_SUCCESS = 'DELETE_SURVEYORS_SUCCESS'
export const DELETE_SURVEYORS_FAILURE = 'DELETE_SURVEYORS_FAILURE'

export const CLEAR_BRANCHES_FLAG = 'CLEAR_BRANCHES_FLAG'

export const CHANGE_SURVEYOR_STATUS = 'CHANGE_SURVEYOR_STATUS'
export const CHANGE_SURVEYOR_STATUS_SUCCESS = 'CHANGE_SURVEYOR_STATUS_SUCCESS'
export const CHANGE_SURVEYOR_STATUS_FAILURE = 'CHANGE_SURVEYOR_STATUS_FAILURE'

export const GET_ALL_SURVEYOR_BRANCHES = 'GET_ALL_SURVEYOR_BRANCHES'
export const GET_ALL_SURVEYOR_BRANCHES_SUCCESS = 'GET_ALL_SURVEYOR_BRANCHES_SUCCESS'
export const GET_ALL_SURVEYOR_BRANCHES_FAILURE = 'GET_ALL_SURVEYOR_BRANCHES_FAILURE'

interface getManageAllSurveyors {
  type: typeof GET_ALL_SURVEYORS
  payload: allBranchesData
}

interface getManageAllSurveyorsSuccess {
  type: typeof GET_ALL_SURVEYORS_SUCCESS
  payload: any
}
interface getManageAllSurveyorsFailure {
  type: typeof GET_ALL_SURVEYORS_FAILURE
  payload: any
}

interface deleteSurveyors {
  type: typeof DELETE_SURVEYORS
  payload: allBranchesData
}

interface deleteSurveyorsSuccess {
  type: typeof DELETE_SURVEYORS_SUCCESS
  payload: any
}
interface deleteSurveyorsFailure {
  type: typeof DELETE_SURVEYORS_FAILURE
  payload: any
}

interface clearBranchesFlag {
  type: typeof CLEAR_BRANCHES_FLAG
  payload: any
}

interface changeSurveyorStatus {
  type: typeof CHANGE_SURVEYOR_STATUS
  payload: surveyorStatusData
}

interface changeSurveyorStatusSuccess {
  type: typeof CHANGE_SURVEYOR_STATUS_SUCCESS
  payload: any
}

interface changeSurveyorStatusFailure {
  type: typeof CHANGE_SURVEYOR_STATUS_FAILURE
  payload: any
}
interface getAllSurveyorBranches {
  type: typeof GET_ALL_SURVEYOR_BRANCHES
  payload: getBranchesData
}

interface getAllSurveyorBranchesSuccess {
  type: typeof GET_ALL_SURVEYOR_BRANCHES_SUCCESS
  payload: any
}

interface getAllSurveyorBranchesFailure {
  type: typeof GET_ALL_SURVEYOR_BRANCHES_FAILURE
  payload: any
}

export type ActionType =
  | getManageAllSurveyors
  | getManageAllSurveyorsSuccess
  | getManageAllSurveyorsFailure
  | deleteSurveyors
  | deleteSurveyorsSuccess
  | deleteSurveyorsFailure
  | clearBranchesFlag
  | changeSurveyorStatus
  | changeSurveyorStatusSuccess
  | changeSurveyorStatusFailure
  | getAllSurveyorBranches
  | getAllSurveyorBranchesSuccess
  | getAllSurveyorBranchesFailure
