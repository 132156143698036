import React from 'react'
import { Row, Col } from 'antd'

const MenuItem = (props: any) => {
  const { iconClass, name, isCollapse } = props
  return (
    <div style={{ display: 'flex' }}>
      <div className={isCollapse ? 'mr-32' : 'mr-20'}>{iconClass}</div>
      <div>{name}</div>
    </div>
  )
}

export default MenuItem
