import * as React from 'react'
import { Input, Form } from 'antd'

interface TextBoxProps {
  placeholder?: string
  defaultValue?: any
  required?: any
  message?: string
  form?: any
  onBlur?: (e: any) => void
}

const TextBox = (props: TextBoxProps) => {
  const { placeholder, required, message, defaultValue, onBlur } = props

  return (
    <Form.Item label="" style={{ position: 'relative', zIndex: 10 }} rules={[{ required: required, message: message }]}>
      <Input {...props} defaultValue={defaultValue} placeholder={placeholder} onBlur={onBlur} />
    </Form.Item>
  )
}

export default TextBox
