import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import {
  GET_SURVEY_DOCUMENT,
  GET_SURVEY_DOCUMENT_SUCCESS,
  GET_SURVEY_DOCUMENT_FAILURE,
  CREATE_SURVEY_DOCUMENT,
  SurveyDocument,
  CLEAR_SURVEY_DOCUMENT_FLAG,
  CREATE_SURVEY_DOCUMENT_SUCCESS,
  CREATE_SURVEY_DOCUMENT_FAILURE,
} from './Types'
import { getSurveyDocument, createSurveyDocument } from './Schema'

function getSurveyDocumentApi() {
  return client
    .query({
      query: getSurveyDocument,
    })
}

function createSurveyDocumentApi(input: SurveyDocument) {
  return client
    .mutate({
      mutation: createSurveyDocument,
      variables: { input },
    })
}

function* getSurveyDocumentData(action: any) {
  try {
    const res = yield getSurveyDocumentApi()
    yield put({
      type: GET_SURVEY_DOCUMENT_SUCCESS,
      payload: res.data.getSurveyData,
    })
  } catch (error) {
    yield put({ type: GET_SURVEY_DOCUMENT_FAILURE, error })
  }
  yield put({ type: CLEAR_SURVEY_DOCUMENT_FLAG })
}

function* createSurveyDocumentData(action: any) {
  try {
    const res = yield createSurveyDocumentApi(action.payload)
    yield put({ type: CREATE_SURVEY_DOCUMENT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_SURVEY_DOCUMENT_FAILURE, error })
  }
  yield put({ type: CLEAR_SURVEY_DOCUMENT_FLAG })
}

function* getSurveyDocumentSaga() {
  yield takeLatest(GET_SURVEY_DOCUMENT, getSurveyDocumentData)
}

function* createSurveyDocumentSaga() {
  yield takeLatest(CREATE_SURVEY_DOCUMENT, createSurveyDocumentData)
}

export default function* surveyDocumentSaga() {
  yield all([getSurveyDocumentSaga(), createSurveyDocumentSaga()])
}
