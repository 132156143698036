import {
  pendingAdsState,
  ActionType,
  GET_SINGLE_SELLER_BOATS,
  GET_SINGLE_SELLER_BOATS_FAILURE,
  GET_SINGLE_SELLER_BOATS_SUCCESS,
  CLEAR_PENDING_ADS_FLAG,
  CHANGE_APPROVAL_STATUS,
  CHANGE_APPROVAL_STATUS_SUCCESS,
  CHANGE_APPROVAL_STATUS_FAILURE,
  GET_PENDING_RENTAL_BOATS,
  GET_PENDING_RENTAL_BOATS_SUCCESS,
  GET_PENDING_RENTAL_BOATS_FAILURE,
  GET_ALL_PENDING_MARINA_STORAGE,
  GET_ALL_PENDING_MARINA_STORAGE_SUCCESS,
  GET_ALL_PENDING_MARINA_STORAGE_FAILURE,
  CHANGE_MARINA_STATUS,
  CHANGE_MARINA_STATUS_SUCCESS,
  CHANGE_MARINA_STATUS_FAILURE,
  GET_BOATS,
  GET_BOATS_SUCCESS,
  GET_BOATS_FAILURE,
  CLEAR_BOATS_FLAG,
  DELETE_BOATS,
  DELETE_BOATS_SUCCESS,
  DELETE_BOATS_FAILURE,
  CHANGE_RENTAL_BOAT_STATUS,
  CHANGE_RENTAL_BOAT_STATUS_SUCCESS,
  CHANGE_RENTAL_BOAT_STATUS_FAILURE,
  GET_ALL_MARINA,
  GET_ALL_MARINA_SUCCESS,
  GET_ALL_MARINA_FAILURE,
  GET_ALL_RENT,
  GET_ALL_RENT_SUCCESS,
  GET_ALL_RENT_FAILURE,
  GET_ALL_BOAT_SERVICE,
  GET_ALL_BOAT_SERVICE_SUCCESS,
  GET_ALL_BOAT_SERVICE_FAILURE,
  GET_MARINA_MORE_SERVICE,
  GET_MARINA_MORE_SERVICE_SUCCESS,
  GET_MARINA_MORE_SERVICE_FAILURE,
  GET_MOST_POPULAR_MARINA,
  GET_MOST_POPULAR_MARINA_SUCCESS,
  GET_MOST_POPULAR_MARINA_FAILURE,
  GET_NEAREST_MARINA,
  GET_NEAREST_MARINA_SUCCESS,
  GET_NEAREST_MARINA_FAILURE,
  GET_LIVE_EXPERIENCE_MARINA,
  GET_LIVE_EXPERIENCE_MARINA_SUCCESS,
  GET_LIVE_EXPERIENCE_MARINA_FAILURE,
  GET_TOP_RATED_BOAT_SERVICE,
  GET_TOP_RATED_BOAT_SERVICE_SUCCESS,
  GET_TOP_RATED_BOAT_SERVICE_FAILURE,
  GET_NEAREST_BOAT_SERVICE,
  GET_NEAREST_BOAT_SERVICE_SUCCESS,
  GET_NEAREST_BOAT_SERVICE_FAILURE,
  GET_RECOMMENDED_RENT,
  GET_RECOMMENDED_RENT_SUCCESS,
  GET_RECOMMENDED_RENT_FAILURE,
  GET_MOST_POPULAR_RENT,
  GET_MOST_POPULAR_RENT_SUCCESS,
  GET_MOST_POPULAR_RENT_FAILURE,
  GET_LIVE_EXPERIENCE_RENT,
  GET_LIVE_EXPERIENCE_RENT_SUCCESS,
  GET_LIVE_EXPERIENCE_RENT_FAILURE,
  CHANGE_YATCH_SERVICE_STATUS,
  CHANGE_YATCH_SERVICE_STATUS_SUCCESS,
  GET_PENDING_BOAT_SERVICE,
  GET_PENDING_BOAT_SERVICE_SUCCESS,
  GET_PENDING_BOAT_SERVICE_FAILURE,
  DELETE_YATCH_SERVICE_BOATS,
  DELETE_YATCH_SERVICE_BOATS_SUCCESS,
  DELETE_YATCH_SERVICE_BOATS_FAILURE,
  DELETE_MARINA_BOATS,
  DELETE_MARINA_BOATS_SUCCESS,
  DELETE_MARINA_BOATS_FAILURE,
  GET_ACTIVITY_LOGS,
  GET_ACTIVITY_LOGS_SUCCESS,
  GET_ACTIVITY_LOGS_FAILURE,
  CREATE_PAYMENT_METHODS,
  CREATE_PAYMENT_METHODS_SUCCESS,
  CREATE_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_FAILURE,
  GET_PAYMENT_METHODS_SUCCESS,
  DELETE_PAYMENT_METHODS,
  DELETE_PAYMENT_METHODS_FAILURE,
  DELETE_PAYMENT_METHODS_SUCCESS,
  UPDATE_PAYMENT_METHODS,
  UPDATE_PAYMENT_METHODS_SUCCESS,
  UPDATE_PAYMENT_METHODS_FAILURE,
  CLEAR_PAYMENT_METHODS,
  GET_RENT_PAYMENTS_RECEIVERS,
  GET_RENT_PAYMENTS_RECEIVERS_SUCCESS,
  GET_RENT_PAYMENTS_RECEIVERS_FAILURE,
  CLEAR_RENT_PAYMENTS_RECEIVERS_FLAG,
  GET_RENT_PAYMENTS_RECEIVER_BOATS,
  GET_RENT_PAYMENTS_RECEIVER_BOATS_SUCCESS,
  GET_RENT_PAYMENTS_RECEIVER_BOATS_FAILURE,
  CLEAR_RENT_PAYMENTS_RECEIVER_BOATS_FLAG,
  GET_RENT_PAYMENTS_BOAT_SLOTS,
  GET_RENT_PAYMENTS_BOAT_SLOTS_SUCCESS,
  GET_RENT_PAYMENTS_BOAT_SLOTS_FAILURE,
  CLEAR_RENT_PAYMENTS_BOAT_SLOTS_FLAG,
  GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS,
  GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_SUCCESS,
  GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FAILURE,
  CLEAR_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FLAG,
  GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FAILURE,
  GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS,
  GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_SUCCESS,
  CLEAR_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FLAG,
  RENT_PAYMENTS_RELEASE_INTENT,
  RENT_PAYMENTS_RELEASE_INTENT_SUCCESS,
  RENT_PAYMENTS_RELEASE_INTENT_FAILURE,
  CLEAR_RENT_PAYMENTS_RELEASE_INTENT_FLAG,
  RENT_PAYMENTS_REFUND_INTENT,
  RENT_PAYMENTS_REFUND_INTENT_SUCCESS,
  RENT_PAYMENTS_REFUND_INTENT_FAILURE,
  CLEAR_RENT_PAYMENTS_REFUND_INTENT_FLAG,
  SEARCH_BOAT,
  SEARCH_BOAT_SUCCESS,
  SEARCH_BOAT_FAILURE,
  SEARCH_BOAT_SEARVICE,
  SEARCH_BOAT_SEARVICE_SUCCESS,
  SEARCH_BOAT_SEARVICE_FAILURE,
  GET_REPORTED_ADS,
  GET_REPORTED_ADS_SUCCESS,
  GET_REPORTED_ADS_FAILURE,
  CLEAR__GET_REPORTED_ADS,
  GET_AD_REPORTS,
  GET_AD_REPORTS_SUCCESS,
  GET_AD_REPORTS_FAILURE,
  CLEAR__GET_AD_REPORTS,
  GET_REPORTED_REVIEWS,
  GET_REPORTED_REVIEWS_SUCCESS,
  GET_REPORTED_REVIEWS_FAILURE,
  CLEAR__GET_REPORTED_REVIEWS,
  GET_REVIEW_REPORTS,
  GET_REVIEW_REPORTS_SUCCESS,
  GET_REVIEW_REPORTS_FAILURE,
  CLEAR__GET_REVIEW_REPORTS,
  DELETE_REVIEW,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAILURE,
  CLEAR__DELETE_REVIEW,
  GET_ADMIN_ACTIVITY_LOGS_SUCCESS,
  GET_ADMIN_ACTIVITY_LOGS,
  GET_ADMIN_ACTIVITY_LOGS_FAILURE,
} from './Types'
import NotificationWithIcon from '../../component/notification/Notification'
import { emptyState } from '../../helpers/helper'

const initialState: pendingAdsState = {
  paymentMethodError: false,
  paymentMethods: [],
  paymentMethodTotal: 0,
  paymentMethodSuccess: false,
  updatePaymentMethodSuccess: false,
  activityLogSuccess: false,
  activityLogError: false,
  activityLog: [],
  adminActivityLogSuccess: false,
  adminActivityLogError: false,
  adminActivityLog: [],
  totalActivityLogRow: 0,
  success: false,
  error: false,
  isLoading: false,
  statusSuccess: false,
  statusError: false,
  singleSellerPendingBoats: [],
  pendingRentalBoats: [],
  pendingMarinaAndStorages: [],
  totalAdminRow: 0,
  totalBoatByType: 0,
  boatIsLoading: false,
  boatSuccess: false,
  boatError: false,
  boats: [],
  deleteYatchSuccess: false,
  deleteYatchError: false,
  deleteSuccess: false,
  deleteError: false,
  totalPendingBoats: false,
  changeRentStatusSuccess: false,
  changeRentStatusError: false,
  totalPendingMarinaAndStorages: 0,
  totalPendingRentalBoats: 0,
  marinaSuccess: false,
  marinaError: false,
  allMarina: [],
  totalMarinaRow: 0,
  rentSuccess: false,
  rentError: false,
  allRent: [],
  totalRentRow: 0,
  boatServiceSuccess: false,
  boatServiceError: false,
  allBoatService: [],
  totalBoatServiceRow: 0,
  moreServiceSuccess: false,
  moreServiceError: false,
  moreBoatService: [],
  totalMoreBoatServiceRow: 0,
  liveExperienceSuccess: false,
  liveExperienceError: false,
  liveExperienceMarina: [],
  totalLiveExperienceMarinaRow: 0,
  nearestMarinaSuccess: false,
  nearestMarinaError: false,
  nearestMarina: [],
  totalNearestMarinaRow: 0,
  mostPopularSuccess: false,
  mostPopularError: false,
  mostPopularMarina: [],
  totalMostPopularMarinaRow: 0,
  nearestBoatYachtSuccess: false,
  nearestBoatYachtError: false,
  nearestBoatYacht: [],
  totalNearestBoatYachtRow: 0,
  topRatedYachtSuccess: false,
  topRatedYachtError: false,
  topRatedYacht: [],
  totalTopRatedYachtRow: 0,
  liveExperienceRentSuccess: false,
  liveExperienceRentError: false,
  liveExperienceRent: [],
  totalLiveExperienceRentRow: 0,
  mostPopularRentSuccess: false,
  mostPopularRentError: false,
  mostPopularRent: [],
  totalMostPopularRentRow: 0,
  recommendedRentBoatSuccess: false,
  recommendedRentBoatError: false,
  recommendedRentBoat: [],
  totalRecommendedRentBoatRow: 0,
  changeYatchStatusError: false,
  changeYatchStatusSuccess: false,
  changeYatchNullStatusError: false,
  changeYatchNullStatusSuccess: false,
  changeMarinaNullStatusError: false,
  changeMarinaNullStatusSuccess: false,
  changeBoatNullStatusError: false,
  changeBoatNullStatusSuccess: false,
  changeRentNullStatusError: false,
  changeRentNullStatusSuccess: false,
  boatServicePendingBoats: [],
  totalBoatServicePendingBoats: 0,
  deleteMarinaSuccess: false,
  deleteMarinaError: false,
  paymentLoader: false,
  rentPaymentsReceivers: emptyState(),
  rentPaymentsReceiverBoats: emptyState(),
  rentPaymentsBoatSlots: emptyState(),
  rentPaymentsBoatSlotBookings: emptyState(),
  rentPaymentsPrivateBoatSlots: emptyState(),
  rentPaymentsReleaseIntent: emptyState(),
  rentPaymentsRefundIntent: emptyState(),
  reportedAds: emptyState(),
  adReports: emptyState(),
  reportedReviews: emptyState(),
  reviewReports: emptyState(),
  deletedReview: emptyState(),
}

export function pendingAdsReducer(state = initialState, action: ActionType): pendingAdsState {
  switch (action.type) {
    case GET_SINGLE_SELLER_BOATS:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      }
    case GET_SINGLE_SELLER_BOATS_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
        error: false,
        singleSellerPendingBoats:
          action.payload &&
          action.payload.data &&
          action.payload.data.getPendingBoats &&
          action.payload.data.getPendingBoats.items,
        totalPendingBoats:
          action.payload &&
          action.payload.data &&
          action.payload.data.getPendingBoats &&
          action.payload.data.getPendingBoats.total,
      }
    case GET_SINGLE_SELLER_BOATS_FAILURE:
      return {
        ...state,
        success: false,
        isLoading: false,
        error: true,
      }
    case GET_PENDING_BOAT_SERVICE:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      }
    case GET_PENDING_BOAT_SERVICE_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
        error: false,
        boatServicePendingBoats:
          action.payload &&
          action.payload.data &&
          action.payload.data.getPendingBoatServices &&
          action.payload.data.getPendingBoatServices.items,
        totalBoatServicePendingBoats:
          action.payload &&
          action.payload.data &&
          action.payload.data.getPendingBoatServices &&
          action.payload.data.getPendingBoatServices.total,
      }
    case GET_PENDING_BOAT_SERVICE_FAILURE:
      return {
        ...state,
        success: false,
        isLoading: false,
        error: true,
      }
    case CLEAR_PENDING_ADS_FLAG:
      return {
        ...state,
        success: false,
        isLoading: false,
        error: false,
        statusSuccess: false,
        statusError: false,
        changeRentStatusError: false,
        changeRentStatusSuccess: false,
      }
    case CHANGE_APPROVAL_STATUS:
      return {
        ...state,
        statusSuccess: false,
        isLoading: false,
        statusError: false,
      }
    case CHANGE_APPROVAL_STATUS_SUCCESS:
      let changedApprovalArray
      if (action.payload.columnName === 'adStatus') {
        changedApprovalArray = action.payload.fieldArray.filter((item: any) => item.id !== action.payload.id)
        NotificationWithIcon('success', 'Status Changed Successfully')
      } else {
        changedApprovalArray = action.payload.fieldArray.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              [action.payload.columnName]: action.payload.value,
            }
          }
          return item
        })
        NotificationWithIcon('success', 'Status Changed Successfully')
      }
      return {
        ...state,
        statusSuccess: true,
        success: true,
        isLoading: false,
        statusError: false,
        [action.payload.fieldName]: [...changedApprovalArray],
      }
    case CHANGE_APPROVAL_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        statusSuccess: false,
        isLoading: false,
        statusError: true,
      }
    case GET_PENDING_RENTAL_BOATS:
      return {
        ...state,
        success: false,
        isLoading: true,
        error: false,
      }
    case GET_PENDING_RENTAL_BOATS_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
        error: false,
        pendingRentalBoats: action.payload.data.pendingRentBoats.items,
        totalPendingRentalBoats: action.payload.data.pendingRentBoats.total,
      }
    case GET_PENDING_RENTAL_BOATS_FAILURE:
      return {
        ...state,
        success: false,
        isLoading: false,
        error: true,
      }
    case GET_ALL_PENDING_MARINA_STORAGE:
      return {
        ...state,
        success: false,
        isLoading: true,
        error: false,
      }
    case GET_ALL_PENDING_MARINA_STORAGE_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
        error: false,
        pendingMarinaAndStorages: action.payload.data.pendingMarinas.items,
        totalPendingMarinaAndStorages: action.payload.data.pendingMarinas.total,
      }
    case GET_ALL_PENDING_MARINA_STORAGE_FAILURE:
      return {
        ...state,
        success: false,
        isLoading: false,
        error: true,
      }
    case CHANGE_MARINA_STATUS:
      return {
        ...state,
        statusSuccess: false,
        statusError: false,
      }
    case CHANGE_MARINA_STATUS_SUCCESS:
      const { pendingMarinaAndStorages } = state
      let changedArray
      if (action.payload.columnName === 'adStatus' || action.payload.fieldName === 'pendingMarinaAndStorages') {
        changedArray = action.payload.fieldArray.filter((item: any) => item.id !== action.payload.id)
        NotificationWithIcon('success', 'Status Changed Successfully')
      } else {
        changedArray = action.payload.fieldArray.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              [action.payload.columnName]: action.payload.value,
            }
          }
          return item
        })
        NotificationWithIcon('success', 'Status Changed Successfully')
      }

      return {
        ...state,
        statusSuccess: true,
        statusError: false,
        [action.payload.fieldName]: changedArray,
      }
    case CHANGE_MARINA_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        statusSuccess: false,
        statusError: true,
      }

    // boat reducer

    case GET_BOATS:
      return {
        ...state,
        boatIsLoading: true,
        boatSuccess: false,
        boatError: false,
        boats: [],
      }

    case GET_BOATS_SUCCESS:
      return {
        ...state,
        boatIsLoading: false,
        boatSuccess: true,
        boatError: false,
        boats: action.payload.data.getAllBoat.items,
        totalAdminRow: action.payload.data.getAllBoat.total,
      }

    case GET_BOATS_FAILURE:
      return {
        ...state,
        boatIsLoading: false,
        boatSuccess: false,
        boatError: true,
        boats: [],
      }

    case DELETE_BOATS:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_BOATS_SUCCESS:
      const filterBoats = action.payload.record.payload.fieldArray.filter(
        (item: any) => item.id !== action.payload.id.deleteBoat.id
      )
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
        totalAdminRow: action.payload.record.payload.fieldArray.length - 1,
        [action.payload.record.payload.fieldName]: [...filterBoats],
      }

    case DELETE_BOATS_FAILURE:
      action.error.map((item: any) => NotificationWithIcon('error', item.message))

      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case DELETE_YATCH_SERVICE_BOATS:
      return {
        ...state,
        deleteYatchSuccess: false,
        deleteYatchError: false,
      }

    case DELETE_YATCH_SERVICE_BOATS_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteYatchSuccess: true,
        deleteYatchError: false,
      }

    case DELETE_YATCH_SERVICE_BOATS_FAILURE:
      return {
        ...state,
        deleteYatchSuccess: false,
        deleteYatchError: true,
      }

    case DELETE_MARINA_BOATS:
      return {
        ...state,
        deleteMarinaSuccess: false,
        deleteMarinaError: false,
      }

    case DELETE_MARINA_BOATS_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteMarinaSuccess: true,
        deleteMarinaError: false,
      }

    case DELETE_MARINA_BOATS_FAILURE:
      return {
        ...state,
        deleteMarinaSuccess: false,
        deleteMarinaError: true,
      }

    case CLEAR_BOATS_FLAG:
      return {
        ...state,
        boatIsLoading: false,
        deleteMarinaSuccess: false,
        deleteMarinaError: false,
        boatSuccess: false,
        boatError: false,
        deleteSuccess: false,
        deleteError: false,
        isLoading: false,
        marinaSuccess: false,
        marinaError: false,
        rentSuccess: false,
        rentError: false,
        boatServiceSuccess: false,
        boatServiceError: false,
        deleteYatchSuccess: false,
        deleteYatchError: false,
      }

    case CHANGE_RENTAL_BOAT_STATUS:
      return {
        ...state,
        changeRentStatusError: false,
        changeRentStatusSuccess: false,
      }
    case CHANGE_RENTAL_BOAT_STATUS_SUCCESS:
      NotificationWithIcon('success', 'Status Changed Successfully')
      return {
        ...state,
        changeRentStatusError: false,
        [action.payload.fieldName]: action.payload.fieldArray.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              [action.payload.columnName]: action.payload.value,
            }
          }
          return item
        }),
        changeRentStatusSuccess: true,
      }
    case CHANGE_RENTAL_BOAT_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        changeRentStatusError: true,
        changeRentStatusSuccess: false,
      }
    case GET_ALL_MARINA:
      return {
        ...state,
        isLoading: true,
        marinaSuccess: false,
        marinaError: false,
        allMarina: [],
      }

    case GET_ALL_MARINA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        marinaSuccess: true,
        marinaError: false,
        allMarina: action.payload.data.getMarinaList.items,
        totalMarinaRow: action.payload.data.getMarinaList.total,
      }

    case GET_ALL_MARINA_FAILURE:
      return {
        ...state,
        isLoading: false,
        marinaSuccess: false,
        marinaError: true,
        allMarina: [],
      }
    case GET_ALL_RENT:
      return {
        ...state,
        isLoading: true,
        rentSuccess: false,
        rentError: false,
        allRent: [],
      }

    case GET_ALL_RENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rentSuccess: true,
        rentError: false,
        allRent: action.payload.data.getAllRentBoat.items,
        totalRentRow: action.payload.data.getAllRentBoat.total,
      }

    case GET_ALL_RENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        rentSuccess: false,
        rentError: true,
        allRent: [],
      }
    case GET_ALL_BOAT_SERVICE:
      return {
        ...state,
        isLoading: true,
        boatServiceSuccess: false,
        boatServiceError: false,
        allBoatService: [],
      }

    case GET_ALL_BOAT_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        boatServiceSuccess: true,
        boatServiceError: false,
        allBoatService: action.payload.data.getAllYachtServiceList.items,
        totalBoatServiceRow: action.payload.data.getAllYachtServiceList.total,
      }

    case GET_ALL_BOAT_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        boatServiceSuccess: false,
        boatServiceError: true,
        allBoatService: [],
      }
    case GET_MARINA_MORE_SERVICE:
      return {
        ...state,
        isLoading: true,
        moreServiceSuccess: false,
        moreServiceError: false,
        moreBoatService: [],
      }

    case GET_MARINA_MORE_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        moreServiceSuccess: true,
        moreServiceError: false,
        moreBoatService: action.payload.data.getMoreServices.items,
        totalMoreBoatServiceRow: action.payload.data.getMoreServices.total,
      }

    case GET_MARINA_MORE_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        moreServiceSuccess: false,
        moreServiceError: true,
        moreBoatService: [],
      }
    case GET_MOST_POPULAR_MARINA:
      return {
        ...state,
        isLoading: true,
        mostPopularSuccess: false,
        mostPopularError: false,
        mostPopularMarina: [],
      }

    case GET_MOST_POPULAR_MARINA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mostPopularSuccess: true,
        mostPopularError: false,
        mostPopularMarina: action.payload.data.getMostPopularMarina.items,
        totalMostPopularMarinaRow: action.payload.data.getMostPopularMarina.total,
      }

    case GET_MOST_POPULAR_MARINA_FAILURE:
      return {
        ...state,
        isLoading: false,
        mostPopularSuccess: false,
        mostPopularError: true,
        mostPopularMarina: [],
      }
    case GET_NEAREST_MARINA:
      return {
        ...state,
        isLoading: true,
        nearestMarinaSuccess: false,
        nearestMarinaError: false,
        nearestMarina: [],
      }
    case GET_NEAREST_MARINA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nearestMarinaSuccess: true,
        nearestMarinaError: false,
        nearestMarina: action.payload.data.nearestMarinaByCountry.items,
        totalNearestMarinaRow: action.payload.data.nearestMarinaByCountry.total,
      }

    case GET_NEAREST_MARINA_FAILURE:
      return {
        ...state,
        isLoading: false,
        nearestMarinaSuccess: false,
        nearestMarinaError: true,
        nearestMarina: [],
      }
    case GET_LIVE_EXPERIENCE_MARINA:
      return {
        ...state,
        isLoading: true,
        liveExperienceSuccess: false,
        liveExperienceError: false,
        liveExperienceMarina: [],
      }

    case GET_LIVE_EXPERIENCE_MARINA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        liveExperienceSuccess: true,
        liveExperienceError: false,
        liveExperienceMarina: action.payload.data.getLiveExperienceMarina.items,
        totalLiveExperienceMarinaRow: action.payload.data.getLiveExperienceMarina.total,
      }

    case GET_LIVE_EXPERIENCE_MARINA_FAILURE:
      return {
        ...state,
        isLoading: false,
        liveExperienceSuccess: false,
        liveExperienceError: true,
        liveExperienceMarina: [],
      }
    case GET_TOP_RATED_BOAT_SERVICE:
      return {
        ...state,
        isLoading: true,
        topRatedYachtSuccess: false,
        topRatedYachtError: false,
        topRatedYacht: [],
      }
    case GET_TOP_RATED_BOAT_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        topRatedYachtSuccess: true,
        topRatedYachtError: false,
        topRatedYacht: action.payload.data.getTopRatedYachtService.items,
        totalTopRatedYachtRow: action.payload.data.getTopRatedYachtService.total,
      }
    case GET_TOP_RATED_BOAT_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        topRatedYachtSuccess: false,
        topRatedYachtError: true,
        topRatedYacht: [],
      }
    case GET_RECOMMENDED_RENT:
      return {
        ...state,
        isLoading: true,
        recommendedRentBoatSuccess: false,
        recommendedRentBoatError: false,
        recommendedRentBoat: [],
      }
    case GET_RECOMMENDED_RENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        recommendedRentBoatSuccess: true,
        recommendedRentBoatError: false,
        recommendedRentBoat: action.payload.data.recommendedBoatRents.items,
        totalRecommendedRentBoatRow: action.payload.data.recommendedBoatRents.total,
      }

    case GET_RECOMMENDED_RENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        recommendedRentBoatSuccess: false,
        recommendedRentBoatError: true,
        recommendedRentBoat: [],
      }
    case GET_MOST_POPULAR_RENT:
      return {
        ...state,
        isLoading: true,
        mostPopularRentSuccess: false,
        mostPopularRentError: false,
        mostPopularRent: [],
      }

    case GET_MOST_POPULAR_RENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mostPopularRentSuccess: true,
        mostPopularRentError: false,
        mostPopularRent: action.payload.data.getMostPopularBoatRents.items,
        totalMostPopularRentRow: action.payload.data.getMostPopularBoatRents.total,
      }

    case GET_MOST_POPULAR_RENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        mostPopularRentSuccess: false,
        mostPopularRentError: true,
        mostPopularRent: [],
      }
    case GET_LIVE_EXPERIENCE_RENT:
      return {
        ...state,
        isLoading: true,
        liveExperienceRentSuccess: false,
        liveExperienceRentError: false,
        liveExperienceRent: [],
      }

    case GET_LIVE_EXPERIENCE_RENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        liveExperienceRentSuccess: true,
        liveExperienceRentError: false,
        liveExperienceRent: action.payload.data.getLiveExperienceBoatRents.items,
        totalLiveExperienceRentRow: action.payload.data.getLiveExperienceBoatRents.total,
      }

    case GET_LIVE_EXPERIENCE_RENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        liveExperienceRentSuccess: false,
        liveExperienceRentError: true,
        liveExperienceRent: [],
      }
    case GET_ACTIVITY_LOGS:
      return {
        ...state,
        isLoading: true,
        activityLogSuccess: false,
        activityLogError: false,
      }
    case GET_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activityLogSuccess: true,
        activityLogError: false,
        activityLog: action.payload.data.getAllAuditLogs.items,
        totalActivityLogRow: action.payload.data.getAllAuditLogs.total,
      }

    case GET_ACTIVITY_LOGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        activityLogSuccess: false,
        activityLogError: true,
        activityLog: [],
      }

      case GET_ADMIN_ACTIVITY_LOGS:
        return {
          ...state,
          isLoading: true,
          activityLogSuccess: false,
          activityLogError: false,
        }
      case GET_ADMIN_ACTIVITY_LOGS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          activityLogSuccess: true,
          activityLogError: false,
          adminActivityLog: action.payload.data.getAdminAuditLogs.items,
          totalActivityLogRow: action.payload.data.getAdminAuditLogs.total,
        }
  
      case GET_ADMIN_ACTIVITY_LOGS_FAILURE:
        return {
          ...state,
          isLoading: false,
          activityLogSuccess: false,
          activityLogError: true,
          adminActivityLog: [],
        }  
    case GET_NEAREST_BOAT_SERVICE:
      return {
        ...state,
        isLoading: true,
        nearestBoatYachtSuccess: false,
        nearestBoatYachtError: false,
        nearestBoatYacht: [],
      }
    case GET_NEAREST_BOAT_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        nearestBoatYachtSuccess: true,
        nearestBoatYachtError: false,
        nearestBoatYacht: action.payload.data.nearestBoatServiceByCountry.items,
        totalNearestBoatYachtRow: action.payload.data.nearestBoatServiceByCountry.total,
      }

    case GET_NEAREST_BOAT_SERVICE_FAILURE:
      return {
        ...state,
        isLoading: false,
        nearestBoatYachtSuccess: false,
        nearestBoatYachtError: true,
        nearestBoatYacht: [],
      }
    case CHANGE_YATCH_SERVICE_STATUS:
      return {
        ...state,
        changeYatchStatusError: false,
        changeYatchStatusSuccess: false,
      }
    case CHANGE_YATCH_SERVICE_STATUS_SUCCESS: {
      let changedYatchApprovalArray: any = []
      if (action.payload.columnName === 'adStatus') {
        changedYatchApprovalArray = action.payload.fieldArray.filter((item: any) => item.id !== action.payload.id)
        NotificationWithIcon('success', 'Status Changed Successfully')
      } else {
        changedYatchApprovalArray = action.payload.fieldArray.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              [action.payload.columnName]: action.payload.value,
            }
          }
          return item
        })
        NotificationWithIcon('success', 'Status Changed Successfully')
      }

      return {
        ...state,
        changeYatchStatusError: false,
        [action.payload.fieldName]: [...changedYatchApprovalArray],
        changeYatchStatusSuccess: true,
      }
    }
    case CREATE_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethodSuccess: false,
        paymentMethodError: false,
      }
    case CREATE_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethodSuccess: true,
        paymentMethodError: false,
      }
    case CREATE_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        paymentMethodSuccess: false,
        paymentMethodError: true,
      }
    case GET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethodError: false,
        paymentLoader: true,
      }
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethodError: false,
        paymentLoader: false,
        paymentMethods: action.payload.data.getAllPaymentMethodTypes.items,
        paymentMethodTotal: action.payload.data.getAllPaymentMethodTypes.total,
      }
    case GET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        paymentMethodError: true,
        paymentLoader: false,
      }
    case CLEAR_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethodSuccess: false,
        updatePaymentMethodSuccess: false,
        paymentMethodError: false,
        deleteError: false,
        deleteSuccess: false,
      }
    case UPDATE_PAYMENT_METHODS:
      return {
        ...state,
        updatePaymentMethodSuccess: false,
        paymentMethodError: true,
      }
    case UPDATE_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        updatePaymentMethodSuccess: true,
        paymentMethodError: false,
      }
    case UPDATE_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        updatePaymentMethodSuccess: false,
        paymentMethodError: true,
      }
    case DELETE_PAYMENT_METHODS:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_PAYMENT_METHODS_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }

    case DELETE_PAYMENT_METHODS_FAILURE:
      action.payload.map((item: any) => NotificationWithIcon('error', item.message))
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case GET_RENT_PAYMENTS_RECEIVERS:
      return {
        ...state,
        rentPaymentsReceivers: {
          ...state.rentPaymentsReceivers,
          loading: true,
          success: false,
          failure: false,
        },
      }

    case GET_RENT_PAYMENTS_RECEIVERS_SUCCESS:
      return {
        ...state,
        rentPaymentsReceivers: {
          ...state.rentPaymentsReceivers,
          data: action.payload,
          success: true,
        },
      }

    case GET_RENT_PAYMENTS_RECEIVERS_FAILURE:
      return {
        ...state,
        rentPaymentsReceivers: {
          ...state.rentPaymentsReceivers,
          failure: true,
        },
      }

    case CLEAR_RENT_PAYMENTS_RECEIVERS_FLAG:
      return {
        ...state,
        rentPaymentsReceivers: {
          ...state.rentPaymentsReceivers,
          loading: false,
          success: false,
          failure: false,
        },
      }

    case GET_RENT_PAYMENTS_RECEIVER_BOATS:
      return {
        ...state,
        rentPaymentsReceiverBoats: {
          ...emptyState(),
          data: state.rentPaymentsReceiverBoats.data,
          loading: true,
        },
      }

    case GET_RENT_PAYMENTS_RECEIVER_BOATS_SUCCESS:
      return {
        ...state,
        rentPaymentsReceiverBoats: {
          ...state.rentPaymentsReceiverBoats,
          data: action.payload,
          success: true,
        },
      }

    case GET_RENT_PAYMENTS_RECEIVER_BOATS_FAILURE:
      return {
        ...state,
        rentPaymentsReceiverBoats: {
          ...state.rentPaymentsReceiverBoats,
          failure: true,
        },
      }

    case CLEAR_RENT_PAYMENTS_RECEIVER_BOATS_FLAG:
      return {
        ...state,
        rentPaymentsReceiverBoats: {
          ...emptyState(),
          data: state.rentPaymentsReceiverBoats.data,
        },
      }

    case GET_RENT_PAYMENTS_BOAT_SLOTS:
      return {
        ...state,
        rentPaymentsBoatSlots: {
          ...emptyState(),
          data: state.rentPaymentsBoatSlots.data,
          loading: true,
        },
      }

    case GET_RENT_PAYMENTS_BOAT_SLOTS_SUCCESS:
      return {
        ...state,
        rentPaymentsBoatSlots: {
          ...state.rentPaymentsBoatSlots,
          data: action.payload,
          success: true,
        },
      }

    case GET_RENT_PAYMENTS_BOAT_SLOTS_FAILURE:
      return {
        ...state,
        rentPaymentsBoatSlots: {
          ...state.rentPaymentsBoatSlots,
          failure: true,
        },
      }

    case CLEAR_RENT_PAYMENTS_BOAT_SLOTS_FLAG:
      return {
        ...state,
        rentPaymentsBoatSlots: {
          ...emptyState(),
          data: state.rentPaymentsBoatSlots.data,
        },
      }

    case GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS:
      return {
        ...state,
        rentPaymentsBoatSlotBookings: {
          ...emptyState(),
          data: state.rentPaymentsBoatSlotBookings.data,
          loading: true,
        },
      }

    case GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_SUCCESS:
      return {
        ...state,
        rentPaymentsBoatSlotBookings: {
          ...state.rentPaymentsBoatSlotBookings,
          data: action.payload,
          success: true,
        },
      }

    case GET_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FAILURE:
      return {
        ...state,
        rentPaymentsBoatSlotBookings: {
          ...state.rentPaymentsBoatSlotBookings,
          failure: true,
        },
      }

    case CLEAR_RENT_PAYMENTS_BOAT_SLOT_BOOKINGS_FLAG:
      return {
        ...state,
        rentPaymentsBoatSlotBookings: {
          ...emptyState(),
          data: state.rentPaymentsBoatSlotBookings.data,
        },
      }

    case GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS:
      return {
        ...state,
        rentPaymentsPrivateBoatSlots: {
          ...emptyState(),
          data: state.rentPaymentsPrivateBoatSlots.data,
          loading: true,
        },
      }

    case GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_SUCCESS:
      return {
        ...state,
        rentPaymentsPrivateBoatSlots: {
          ...state.rentPaymentsPrivateBoatSlots,
          data: action.payload,
          success: true,
        },
      }

    case GET_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FAILURE:
      return {
        ...state,
        rentPaymentsPrivateBoatSlots: {
          ...state.rentPaymentsPrivateBoatSlots,
          failure: true,
        },
      }

    case CLEAR_RENT_PAYMENTS_PRIVATE_BOAT_SLOTS_FLAG:
      return {
        ...state,
        rentPaymentsPrivateBoatSlots: {
          ...emptyState(),
          data: state.rentPaymentsPrivateBoatSlots.data,
        },
      }

    case RENT_PAYMENTS_RELEASE_INTENT:
      return {
        ...state,
        rentPaymentsReleaseIntent: {
          ...emptyState(),
          data: null,
          loading: true,
        },
      }

    case RENT_PAYMENTS_RELEASE_INTENT_SUCCESS:
      return {
        ...state,
        rentPaymentsReleaseIntent: {
          ...state.rentPaymentsReleaseIntent,
          data: action.payload || state.rentPaymentsReleaseIntent.data,
          success: true,
        },
      }

    case RENT_PAYMENTS_RELEASE_INTENT_FAILURE:
      return {
        ...state,
        rentPaymentsReleaseIntent: {
          ...state.rentPaymentsReleaseIntent,
          data: action.payload || state.rentPaymentsReleaseIntent.data,
          failure: true,
        },
      }

    case CLEAR_RENT_PAYMENTS_RELEASE_INTENT_FLAG:
      return {
        ...state,
        rentPaymentsReleaseIntent: {
          ...emptyState(),
          data: state.rentPaymentsReleaseIntent.data,
        },
      }

    case RENT_PAYMENTS_REFUND_INTENT:
      return {
        ...state,
        rentPaymentsRefundIntent: {
          ...emptyState(),
          data: null,
          loading: true,
        },
      }

    case RENT_PAYMENTS_REFUND_INTENT_SUCCESS:
      return {
        ...state,
        rentPaymentsRefundIntent: {
          ...state.rentPaymentsRefundIntent,
          data: action.payload || state.rentPaymentsRefundIntent.data,
          success: true,
        },
      }

    case RENT_PAYMENTS_REFUND_INTENT_FAILURE:
      return {
        ...state,
        rentPaymentsRefundIntent: {
          ...state.rentPaymentsRefundIntent,
          data: action.payload || state.rentPaymentsRefundIntent.data,
          failure: true,
        },
      }

    case CLEAR_RENT_PAYMENTS_REFUND_INTENT_FLAG:
      return {
        ...state,
        rentPaymentsRefundIntent: {
          ...emptyState(),
          data: state.rentPaymentsRefundIntent.data,
        },
      }

    case SEARCH_BOAT:
      return {
        ...state,
        boatIsLoading: true,
      }

    case SEARCH_BOAT_SUCCESS:
      console.log(action.payload)
      return {
        ...state,
        boats: action.payload.items,
        totalAdminRow: action.payload.total,
        boatIsLoading: false,
      }

    case SEARCH_BOAT_FAILURE:
      return {
        ...state,
        boats: [],
        boatIsLoading: false,
      }

    case SEARCH_BOAT_SEARVICE:
      return {
        ...state,
        isLoading: true,
      }

    case SEARCH_BOAT_SEARVICE_SUCCESS:
      return {
        ...state,
        allBoatService: action.payload.items,
        totalBoatServiceRow: action.payload.total,
        isLoading: false,
      }

    case SEARCH_BOAT_SEARVICE_FAILURE:
      return {
        ...state,
        allBoatService: [],
        isLoading: false,
      }

    case GET_REPORTED_ADS:
      return {
        ...state,
        reportedAds: {
          ...emptyState(),
          data: null,
          loading: true,
        },
      }

    case GET_REPORTED_ADS_SUCCESS:
      return {
        ...state,
        reportedAds: {
          ...state.rentPaymentsRefundIntent,
          data: action.payload,
          success: true,
        },
      }

    case GET_REPORTED_ADS_FAILURE:
      return {
        ...state,
        reportedAds: {
          ...state.reportedAds,
          failure: true,
        },
      }

    case CLEAR__GET_REPORTED_ADS:
      return {
        ...state,
        reportedAds: {
          ...emptyState(),
          data: state.reportedAds.data,
        },
      }

    case GET_AD_REPORTS:
      return {
        ...state,
        adReports: {
          ...emptyState(),
          data: null,
          loading: true,
        },
      }

    case GET_AD_REPORTS_SUCCESS:
      return {
        ...state,
        adReports: {
          ...state.rentPaymentsRefundIntent,
          data: action.payload,
          success: true,
        },
      }

    case GET_AD_REPORTS_FAILURE:
      return {
        ...state,
        adReports: {
          ...state.adReports,
          failure: true,
        },
      }

    case CLEAR__GET_AD_REPORTS:
      return {
        ...state,
        adReports: {
          ...emptyState(),
          data: state.adReports.data,
        },
      }

    case GET_REPORTED_REVIEWS:
      return {
        ...state,
        reportedReviews: {
          ...emptyState(),
          data: null,
          loading: true,
        },
      }

    case GET_REPORTED_REVIEWS_SUCCESS:
      return {
        ...state,
        reportedReviews: {
          ...state.reportedReviews,
          data: action.payload,
          success: true,
        },
      }

    case GET_REPORTED_REVIEWS_FAILURE:
      return {
        ...state,
        reportedReviews: {
          ...state.reportedReviews,
          failure: true,
        },
      }

    case CLEAR__GET_REPORTED_REVIEWS:
      return {
        ...state,
        reportedReviews: {
          ...emptyState(),
          data: state.reportedReviews.data,
        },
      }

    case GET_REVIEW_REPORTS:
      return {
        ...state,
        reviewReports: {
          ...emptyState(),
          data: null,
          loading: true,
        },
      }

    case GET_REVIEW_REPORTS_SUCCESS:
      return {
        ...state,
        reviewReports: {
          ...state.reviewReports,
          data: action.payload,
          success: true,
        },
      }

    case GET_REVIEW_REPORTS_FAILURE:
      return {
        ...state,
        reviewReports: {
          ...state.reviewReports,
          failure: true,
        },
      }

    case CLEAR__GET_REVIEW_REPORTS:
      return {
        ...state,
        reviewReports: {
          ...emptyState(),
          data: state.reviewReports.data,
        },
      }

    case DELETE_REVIEW: {
      return {
        ...state,
        deletedReview: {
          ...emptyState(),
          loading: true,
        },
      }
    }

    case DELETE_REVIEW_SUCCESS: {
      return {
        ...state,
        deletedReview: {
          ...state.deletedReview,
          success: true,
        },
      }
    }

    case DELETE_REVIEW_FAILURE: {
      return {
        ...state,
        deletedReview: {
          ...state.deletedReview,
          failure: true,
        },
      }
    }

    case CLEAR__DELETE_REVIEW: {
      return {
        ...state,
        deletedReview: {
          ...emptyState(),
        },
      }
    }

    default:
      return state
  }
}
