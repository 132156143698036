import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import FormTable, { tableRecords } from './FormTable'
import { displayDefaultValue } from '../../helpers/helper'
import { getContact } from '../../store/form/Actions'

interface Props {
  getContact: typeof getContact
}

class AdminLeads extends Component<Props> {
  columns = [
    {
      title: 'Contact Lead',
      dataIndex: 'lead',
      key: 'lead',
    },
    {
      title: 'Ad ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'address',
      render: (text: any, record: tableRecords, index: number) => {
        return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: any, record: tableRecords) => {
        return <div>{`${text} ${record.lastName}`}</div>
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]

  componentDidMount() {
    const { getContact } = this.props
    // getContact()
  }

  render() {
    return <FormTable tableTitle="Contact Lead - Admin" renderColumn={this.columns} />
  }
}

const mapStateToProps = (state: any) => {
  return {
    contactUsers: state.feedbackReducer.contactUsers,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    // getContact: () => dispatch(getContact())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLeads)
