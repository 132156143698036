import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../..'
import { createDownload, updateDownload, deleteDownload, getDownloadByModule } from './Schema'
import {
  ActionType,
  GET_ALL_DOWNLOADS_SUCCESS,
  GET_ALL_DOWNLOADS_FAILURE,
  CREATE_DOWNLOADS_SUCCESS,
  CREATE_DOWNLOADS_FAILURE,
  UPDATE_DOWNLOADS_SUCCESS,
  UPDATE_DOWNLOADS_FAILURE,
  DELETE_DOWNLOADS_SUCCESS,
  DELETE_DOWNLOADS_FAILURE,
  GET_ALL_DOWNLOADS,
  CREATE_DOWNLOADS,
  UPDATE_DOWNLOADS,
  DELETE_DOWNLOADS,
} from './Type'
import { AnyAction } from 'redux'

function getDownloadByModuleApi(payload: any) {
  return client
    .query({
      query: getDownloadByModule,
      variables: {
        input: { status: false },
        page: payload.page,
        limit: payload.limit,
        searchTerm: payload.searchTerm
      },
      fetchPolicy: 'no-cache',
    })
}

function createDownloadsApi(input: any) {
  console.log(input, 'create input')

  return client
    .mutate({
      mutation: createDownload,
      variables: {
        input,
      },
    })
}

function updateDownloadsApi(data: any) {
  console.log(data, 'data update')

  const page = {
    id: data.id,
    ...data,
  }

  return client
    .mutate({
      mutation: updateDownload,
      variables: { input: page },
    })
}

function deleteDownloadApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteDownload,
      variables: { id },
    })
}

function* getDownloadByModuleData(action: any) {
  try {
    const res = yield getDownloadByModuleApi(action.payload)
    yield put({
      type: GET_ALL_DOWNLOADS_SUCCESS,
      payload: res.data.getAllDownloads,
    })
  } catch (error) {
    yield put({ type: GET_ALL_DOWNLOADS_FAILURE, error })
  }
}

function* createDownloadData(action: ActionType) {
  try {
    const res = yield createDownloadsApi(action.payload)
    yield put({ type: CREATE_DOWNLOADS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_DOWNLOADS_FAILURE, error })
  }
}

export function* updateDownloadsData(action: ActionType) {
  try {
    const data = yield updateDownloadsApi(action.payload)
    yield put({ type: UPDATE_DOWNLOADS_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_DOWNLOADS_FAILURE, error })
  }
}

function* deleteDownloadsData(action: ActionType) {
  try {
    const res = yield deleteDownloadApi(action)
    yield put({ type: DELETE_DOWNLOADS_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_DOWNLOADS_FAILURE, error })
  }
}

function* getDownloadByModuleSaga() {
  yield takeLatest(GET_ALL_DOWNLOADS, getDownloadByModuleData)
}

function* createDownloadsSaga() {
  yield takeLatest(CREATE_DOWNLOADS, createDownloadData)
}

function* updateDownloadsSaga() {
  yield takeLatest(UPDATE_DOWNLOADS, updateDownloadsData)
}

function* deleteDownloadsSaga() {
  yield takeLatest(DELETE_DOWNLOADS, deleteDownloadsData)
}

export default function* downloadsSaga() {
  yield all([getDownloadByModuleSaga(), deleteDownloadsSaga(), createDownloadsSaga(), updateDownloadsSaga()])
}
