import {
  CREATE_AGREEMENT,
  agreementInput,
  GET_ALL_AGREEMENTS,
  agreementContentInput,
  DELETE_AGREEMENT,
  UPDATE_AGREEMENT_CONTENT,
  GET_ALL_AGREEMENT_CONTENTS,
  CREATE_AGREEMENT_CONTENT,
  DELETE_AGREEMENT_CONTENT,
  GET_SINGLE_AGREEMENT_CONTENT,
  CHANGE_AGREEMENT_STATUS,
  UPDATE_AGREEMENT,
  CLEAR_AGREEMENT_CONTENT_FLAG,
  GET_ALL_PENDING_AGREEMENT_REQUESTS,
  APPROVE_PENDING_AGREEMENT,
} from './Types'

export const createAgreement = (data: agreementInput) => ({
  type: CREATE_AGREEMENT,
  payload: data,
})

export const getAllAgreements = (data: any) => ({
  type: GET_ALL_AGREEMENTS,
  payload: data,
})

export const deleteAgreement = (data: string) => ({
  type: DELETE_AGREEMENT,
  payload: data,
})

export const updateAgreement = (data: agreementInput) => ({
  type: UPDATE_AGREEMENT,
  payload: data,
})

export const changeAgreementStatus = (data: any) => ({
  type: CHANGE_AGREEMENT_STATUS,
  payload: data,
})

export const createAgreementContent = (data: agreementContentInput) => ({
  type: CREATE_AGREEMENT_CONTENT,
  payload: data,
})

export const updateAgreementContent = (data: agreementContentInput) => ({
  type: UPDATE_AGREEMENT_CONTENT,
  payload: data,
})

export const getAllAgreementContents = (data: any) => ({
  type: GET_ALL_AGREEMENT_CONTENTS,
  payload: data,
})

export const getSingleAgreementContent = (data: string) => ({
  type: GET_SINGLE_AGREEMENT_CONTENT,
  payload: data,
})

export const deleteAgreementContent = (data: string) => ({
  type: DELETE_AGREEMENT_CONTENT,
  payload: data,
})

export const clearAgreementFlag = () => ({
  type: CLEAR_AGREEMENT_CONTENT_FLAG,
})

export const getAllPendingRequests = (data: any) => ({
  type: GET_ALL_PENDING_AGREEMENT_REQUESTS,
  payload: data,
})

export const approvePendingAgreement = (data: any) => ({
  type: APPROVE_PENDING_AGREEMENT,
  payload: data,
})
