export interface emailTemplateState {
  template: template
  templates: template[]
  emailSuccess: boolean
  emailError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  totalTemplatesRow: number
  variableSuccess: boolean
  variableList: any[]
  variableError: boolean
}

export interface template {
  id: string
  type: string
  subject: string
  body: string
  updatedAt: string
}

export interface emailData {
  smtp?: any
  name?: any
  id?: string
  type?: string
  subject?: string
  body?: string
}

export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE'
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS'
export const ADD_EMAIL_TEMPLATE_FAILURE = 'ADD_EMAIL_TEMPLATE_FAILURE'

export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES'
export const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS'
export const GET_EMAIL_TEMPLATES_FAILURE = 'GET_EMAIL_TEMPLATES_FAILURE'

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE'
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_SUCCESS'
export const UPDATE_EMAIL_TEMPLATE_FAILURE = 'UPDATE_EMAIL_TEMPLATE_FAILURE'
export const CLEAR_EMAIL_TEMPLATE_FLAG = 'CLEAR_EMAIL_TEMPLATE_FLAG'

export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS'
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE'

export const GET_VARIABLE = 'GET_VARIABLE'
export const GET_VARIABLE_SUCCESS = 'GET_VARIABLE_SUCCESS'
export const GET_VARIABLE_FAILURE = 'GET_VARIABLE_FAILURE'

interface addEmailTemplate {
  type: typeof ADD_EMAIL_TEMPLATE
  payload: emailData
}

interface addEmailTemplateSuccess {
  type: typeof ADD_EMAIL_TEMPLATE_SUCCESS
  payload: emailData
}
interface addEmailTemplateFailure {
  type: typeof ADD_EMAIL_TEMPLATE_FAILURE
  payload: string
}

interface getEmailTemplates {
  type: typeof GET_EMAIL_TEMPLATES
  payload: any
}

interface getEmailTemplatesSuccess {
  type: typeof GET_EMAIL_TEMPLATES_SUCCESS
  payload: any
}

interface getEmailTemplatesFailure {
  type: typeof GET_EMAIL_TEMPLATES_FAILURE
  payload: any
}

interface getVariable {
  type: typeof GET_VARIABLE
  payload?: any
}

interface getVariableSuccess {
  type: typeof GET_VARIABLE_SUCCESS
  payload?: any
}

interface getVariableFailure {
  type: typeof GET_VARIABLE_FAILURE
  payload?: any
}

interface updateEmailTemplate {
  type: typeof UPDATE_EMAIL_TEMPLATE
  payload: emailData
}

interface updateEmailTemplateSuccess {
  type: typeof UPDATE_EMAIL_TEMPLATE_SUCCESS
  payload: emailData
}

interface updateEmailTemplateFailure {
  type: typeof UPDATE_EMAIL_TEMPLATE_FAILURE
  payload: emailData
}

interface clearFlag {
  type: typeof CLEAR_EMAIL_TEMPLATE_FLAG
  payload: any
}

interface deleteTemplate {
  type: typeof DELETE_TEMPLATE
  payload: emailData
}

interface deleteTemplateSuccess {
  type: typeof DELETE_TEMPLATE_SUCCESS
  payload: emailData
}

interface deleteTemplateFailure {
  type: typeof DELETE_TEMPLATE_FAILURE
  payload: emailData
}

export type ActionType =
  | addEmailTemplate
  | getEmailTemplates
  | getEmailTemplatesSuccess
  | updateEmailTemplate
  | updateEmailTemplateSuccess
  | updateEmailTemplateFailure
  | clearFlag
  | deleteTemplate
  | deleteTemplateSuccess
  | deleteTemplateFailure
  | addEmailTemplateSuccess
  | addEmailTemplateFailure
  | getEmailTemplatesFailure
  | getVariable
  | getVariableSuccess
  | getVariableFailure
