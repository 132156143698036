import {
  ActionType,
  frontUsersState,
  GET_FRONT_USERS_COUNT,
  GET_FRONT_USERS_COUNT_SUCCESS,
  GET_FRONT_USERS_COUNT_FAILURE,
  GET_FRONT_USERS_BOAT_COUNT,
  GET_FRONT_USERS_BOAT_COUNT_SUCCESS,
  GET_FRONT_USERS_PENDING_COUNT,
  GET_FRONT_USERS_PENDING_COUNT_SUCCESS,
  GET_FRONT_USERS_PENDING_COUNT_FAILURE,
  GO_TO_DASHBOARD,
  GO_TO_DASHBOARD_SUCCESS,
  GO_TO_DASHBOARD_FAILURE,
  CLEAR_GO_TO_DASHBOARD_FLAG,
  GET_FRONT_USERS_BOAT_COUNT_FAILURE,
  FRONT_USER_WISE_VERIFICATION,
  FRONT_USER_WISE_VERIFICATION_SUCCESS,
  FRONT_USER_WISE_VERIFICATION_FAILURE,
} from './Type'
import { getUserRoles, getRoleCountPath } from '../../../helpers/helper'

const initialState: frontUsersState = {
  frontUsers: [],
  frontUsersBoatCount: [],
  frontUserPendingCount: [],
  getSuccess: false,
  getError: false,

  loading: false,
  dashboardLink: '',
  dashboardLinkSuccess: false,
  dashboardLinkError: false,

  frontUserWiseVerificationCount: []
}

export const frontUsersCountReducer = (state = initialState, action: ActionType): frontUsersState => {
  switch (action.type) {
    case GET_FRONT_USERS_COUNT:
      return {
        ...state,
        loading: true,
        getSuccess: false,
        getError: false,
      }

    case GET_FRONT_USERS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        getSuccess: true,
        getError: false,
        frontUsers: getRoleCountPath(action.payload.getFrontUsersCount.roleCounts, getUserRoles),
      }

    case GET_FRONT_USERS_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        getSuccess: false,
        getError: true,
      }

    case GET_FRONT_USERS_BOAT_COUNT:
      return {
        ...state,
        loading: true,
        getSuccess: false,
        getError: false,
      }

    case GET_FRONT_USERS_BOAT_COUNT_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        loading: false,
        getError: false,
        frontUsersBoatCount: getRoleCountPath(action.payload.getFrontUserBoatsCount.roleCounts, getUserRoles),
      }

    case GET_FRONT_USERS_BOAT_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        getSuccess: false,
        getError: true,
      }

    case GET_FRONT_USERS_PENDING_COUNT:
      return {
        ...state,
        loading: true,
        getSuccess: false,
        getError: false,
      }

    case GET_FRONT_USERS_PENDING_COUNT_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        loading: false,
        getError: false,
        frontUserPendingCount: getRoleCountPath(action.payload.getFrontUserPendingCount.roleCounts, getUserRoles),
      }

    case GET_FRONT_USERS_PENDING_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        getSuccess: false,
        getError: true,
      }

    case GO_TO_DASHBOARD:
      return {
        ...state,
        loading: true,
        dashboardLinkSuccess: false,
        dashboardLinkError: false,
        dashboardLink: '',
      }

    case GO_TO_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardLink: action.payload.autoLoginToken,
        dashboardLinkSuccess: true,
      }

    case GO_TO_DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        dashboardLinkError: true,
      }

    case CLEAR_GO_TO_DASHBOARD_FLAG:
      return {
        ...state,
        loading: false,
        dashboardLinkSuccess: false,
        dashboardLinkError: false,
        dashboardLink: '',
      }
    
    case FRONT_USER_WISE_VERIFICATION:
      return{
        ...state
      }  

    case FRONT_USER_WISE_VERIFICATION_SUCCESS:
      return {
       ...state,
       frontUserWiseVerificationCount: action.payload.getFrontVerificationCount
      }  

    case FRONT_USER_WISE_VERIFICATION_FAILURE:
      return {
        ...state
      }    
    default:
      return state
  }
}
