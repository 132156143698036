import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card, Switch, Tag } from 'antd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import moment from 'moment'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { getSurveyorBranches, changeSurveyorStatus } from '../../../store/manageSurveyorBranch/allBranches/Action'
import { getBranchesData, allBranchesData } from '../../../store/manageSurveyorBranch/allBranches/Type'
import History from '../../../History'
import { getAllBoatPagination } from '../../../enums/enums'
import Loader from '../../../component/Loading'

interface Props {
  getSurveyorBranches: typeof getSurveyorBranches
  match: any
  surveyorBranches: any[]
  isLoading: boolean
  changeSurveyorStatus: typeof changeSurveyorStatus
}

interface State {
  expandKeys: string[]
}

class SurveyorBranches extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      expandKeys: [],
    }
  }

  componentDidMount() {
    const { match, getSurveyorBranches } = this.props
    getSurveyorBranches({
      user: match.params.id,
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    })
  }
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    return null
  }

  columns = [
    {
      title: 'Surveyor',
      dataIndex: 'user',
      key: 'user',
      render: (text: any, record: tableRecords, index: number) => {
        return <div>{text.companyName}</div>
      },
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
    },
    {
      title: 'Branch Name',
      dataIndex: 'branchName',
      key: 'branchName',
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      key: 'emailAddress',
    },
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'address',
      render: (text: any, record: tableRecords, index: number) => {
        return <div>{text?.length > 0 ? text[0].country : '-'}</div>
      },
    },
    {
      title: 'City',
      dataIndex: 'address',
      key: 'address',
      render: (text: any, record: tableRecords, index: number) => {
        return <div>{text?.length > 0 ? text[0].city : '-'}</div>
      },
    },
    {
      title: 'Status',
      dataIndex: 'branchStatus',
      key: 'branchStatus',
      render: (text: string, record: tableRecords) => (
        <span>
          {text ? (
            <span className="active-status-text">{'Active'}</span>
          ) : (
            <span className="inactive-status-text">{'Inactive'}</span>
          )}
        </span>
      ),
    },
    {
      title: 'Branch Verification Status',
      dataIndex: 'branchVerificationStatus',
      key: 'branchVerificationStatus',
      render: (text: string, record: tableRecords, index: number) => {
        const { changeSurveyorStatus } = this.props

        return (
          <Switch
            checked={record.branchVerificationStatus}
            onChange={() =>
              changeSurveyorStatus({
                value: record.branchVerificationStatus,
                column: 'branchVerificationStatus',
                id: record.id,
              })
            }
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        )
      },
    },
  ]

  render() {
    const { surveyorBranches, isLoading } = this.props
    const name = surveyorBranches?.length ? `${surveyorBranches[0].user.firstName} ${surveyorBranches[0].user.lastName}` : ''
    const { expandKeys } = this.state

    return (
      <>
        <Card title={`Manage All Branches of Surveyor: ${name}`}>
          {isLoading ? (
            <Loader />
          ) : (
            <ExpandTable
              totalUsersRow={2}
              columns={this.columns}
              tableData={surveyorBranches}
              expandHtml={false}
              isExpand={false}
              expandedRowRender={(record: any) => (
                <ul>
                  <li>Position: {record.position.alias}</li>
                  <li>Joined At: {moment(record.user.createdAt).format('LLLL')}</li>
                  <li>Contact Number: {record.mobileNumber}</li>
                  <li>Price per feet: {record.pricePerFt} USD</li>
                </ul>
              )}
              onExpand={(expanded: boolean, record: tableRecords) => {
                const { id } = record
                if (expanded && id) {
                  this.setState({ expandKeys: [id] })
                } else {
                  this.setState({ expandKeys: [] })
                }
              }}
              rowKey={(record: tableRecords) => record?.id}
              expandedRowKeys={expandKeys}
            />
          )}
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    surveyorBranches: state.allBranchesReducer?.surveyorBranches,
    isLoading: state.allBranchesReducer?.isLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getSurveyorBranches: (data: getBranchesData) => dispatch(getSurveyorBranches(data)),
    changeSurveyorStatus: (data: any) => dispatch(changeSurveyorStatus(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyorBranches)
