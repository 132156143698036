export interface initialStatesState {
  states: []
  isLoading: boolean
  stateSuccess: boolean
  stateError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  updateSuccess: boolean
  updateError: boolean
}

export interface stateData {
  id?: String
  name: String
  stateCode: String
  country: String
}

export const GET_STATE = 'GET_STATE'
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS'
export const GET_STATE_FAILURE = 'GET_STATE_FAILURE'

export const CREATE_STATE = 'CREATE_STATE'
export const CREATE_STATE_SUCCESS = 'CREATE_STATE_SUCCESS'
export const CREATE_STATE_FAILURE = 'CREATE_STATE_FAILURE'

export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_STATE_SUCCESS = 'UPDATE_STATE_SUCCESS'
export const UPDATE_STATE_FAILURE = 'UPDATE_STATE_FAILURE'

export const DELETE_STATE = 'DELETE_STATE'
export const DELETE_STATE_SUCCESS = 'DELETE_STATE_SUCCESS'
export const DELETE_STATE_FAILURE = 'DELETE_STATE_FAILURE'

export const CLEAR_STATE_FLAG = 'CLEAR_STATE_FLAG'

export const CHANGE_STATE_STATUS = 'CHANGE_STATE_STATUS'
export const CHANGE_STATE_STATUS_SUCCESS = 'CHANGE_STATE_STATUS_SUCCESS'
export const CHANGE_STATE_STATUS_FAILURE = 'CHANGE_STATE_STATUS_FAILURE'

interface getState {
  type: typeof GET_STATE
  payload: stateData
}

interface getStateSuccess {
  type: typeof GET_STATE_SUCCESS
  payload: any
}

interface getStateFailure {
  type: typeof GET_STATE_FAILURE
  payload: any
}

interface createManageState {
  type: typeof CREATE_STATE
  payload: stateData
}

interface createManageStateSuccess {
  type: typeof CREATE_STATE_SUCCESS
  payload: any
}

interface createManageStateFailure {
  type: typeof CREATE_STATE_FAILURE
  payload: any
}

interface updateManageState {
  type: typeof UPDATE_STATE
  payload: stateData
}

interface updateManageStateSuccess {
  type: typeof UPDATE_STATE_SUCCESS
  payload: stateData
}

interface updateManageStateFailure {
  type: typeof UPDATE_STATE_FAILURE
  payload: stateData
}

interface clearStateFlag {
  type: typeof CLEAR_STATE_FLAG
  payload: any
}

interface deleteManageState {
  type: typeof DELETE_STATE
  payload: stateData
}

interface deleteManageStateSuccess {
  type: typeof DELETE_STATE_SUCCESS
  payload: stateData
}

interface deleteManageStateFailure {
  type: typeof DELETE_STATE_FAILURE
  payload: stateData
}

interface changeStateStatus {
  type: typeof CHANGE_STATE_STATUS
  payload: any
}

interface changeStateStatusSuccess {
  type: typeof CHANGE_STATE_STATUS_SUCCESS
  payload: any
}

interface changeStateStatusFailure {
  type: typeof CHANGE_STATE_STATUS_FAILURE
  payload: any
}

export type ActionType =
  | getState
  | getStateSuccess
  | getStateFailure
  | createManageState
  | createManageStateSuccess
  | createManageStateFailure
  | updateManageState
  | updateManageStateSuccess
  | updateManageStateFailure
  | clearStateFlag
  | deleteManageState
  | deleteManageStateSuccess
  | deleteManageStateFailure
  | changeStateStatus
  | changeStateStatusSuccess
  | changeStateStatusFailure
