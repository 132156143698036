import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { client } from '../..'
import {
  LOGIN_USER,
  SUCCESS_LOGIN,
  ERROR_LOGIN,
  CLEAR_RECORD,
  ActionType,
  GET_ALL_LOOKUPS,
  GET_ALL_LOOKUPS_ERROR,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_TRIP_LOOKUPS,
  GET_TRIP_LOOKUPS_SUCCESS,
  GET_TRIP_LOOKUPS_ERROR,
  GET_ALL_TYPES,
  GET_ALL_TYPES_ERROR,
  GET_ALL_TYPES_SUCCESS,
  CREATE_LOOKUP,
  CREATE_LOOKUP_ERROR,
  CREATE_LOOKUP_SUCCESS,
  EDIT_LOOKUP,
  EDIT_LOOKUP_ERROR,
  EDIT_LOOKUP_SUCCESS,
  DELETE_LOOKUP,
  DELETE_LOOKUP_ERROR,
  DELETE_LOOKUP_SUCCESS,
} from './Types'
import {
  addUserMutation,
  changeUserStatus,
  getAllLookUps,
  getAllTypes,
  createLookUp,
  updateLookUp,
  changeLookUpStatus,
} from './UserSchema'
import { getUsersByCountry } from '../adminUserRole/Schema'
import {
  GET_FRONT_USERS,
  GET_FRONT_USERS_SUCCESS,
  GET_FRONT_USERS_FAILURE,
  CHANGE_ACCOUNT_STATUS_SUCCESS,
  CHANGE_ACCOUNT_STATUS_FAILURE,
  CHANGE_ACCOUNT_STATUS,
} from '../adminUserRole/Type'
import { get } from 'lodash'

/**
 * save bank.
 */
function* LoginUser() {
  yield takeEvery(LOGIN_USER, validUser)
}

function loginAsync({ payload }: any) {
  return client.mutate({
    mutation: addUserMutation,
    variables: {
      input: payload,
    },
  })
}

function* validUser({ payload }: AnyAction) {
  try {
    const response = yield loginAsync({ payload })
    if (response.data.adminLogin.token) {
      localStorage.setItem('auth_token', response.data.adminLogin.token)
      yield put({ type: SUCCESS_LOGIN, payload: response.data.adminLogin })
    }
  } catch (error) {
    yield put({ type: ERROR_LOGIN, payload: get(error, 'networkError.result.errors[0].message', 'Error!') })
    yield put({ type: CLEAR_RECORD })
  }
}

function getFrontUsersApi(data: any) {
  return client
    .query({
      query: getUsersByCountry,
      variables: data.payload,
      fetchPolicy: 'no-cache',
    })
}

function* getFrontUserData(action: ActionType) {
  try {
    const res = yield getFrontUsersApi(action)
    yield put({ type: GET_FRONT_USERS_SUCCESS, payload: res.data.getAllUsers })
  } catch (e) {
    yield put({ type: GET_FRONT_USERS_FAILURE, e })
  }
}

function* getFrontUsersSaga() {
  yield takeEvery(GET_FRONT_USERS, getFrontUserData)
}

/**
 *  Sagas default export
 */

function changeAccountStatusApi(data: any) {
  const newData = data.payload
  const { fromPage, ...withoutFromPage } = newData
  return client
    .mutate({
      mutation: changeUserStatus,
      variables: {
        ...withoutFromPage,
      },
    })
}

function* changeAccountStatusData(action: any) {
  try {
    const res = yield changeAccountStatusApi(action)
    const updateResponse = {
      ...res.data.changeUserStatus,
      fromPage: (action.payload && action.payload.fromPage) || '',
    }
    yield put({ type: CHANGE_ACCOUNT_STATUS_SUCCESS, payload: updateResponse })
  } catch (e) {
    yield put({ type: CHANGE_ACCOUNT_STATUS_FAILURE, e })
  }
}

function* changeAccountStatusSaga() {
  yield takeEvery(CHANGE_ACCOUNT_STATUS, changeAccountStatusData)
}

function getAllLookupsApi(data: any) {
  return client.query({
    query: getAllLookUps,
    variables: data.payload,
    fetchPolicy: 'no-cache',
  })
}

function* getAllLookupsData(action: ActionType) {
  try {
    const res = yield getAllLookupsApi(action)
    yield put({
      type: GET_ALL_LOOKUPS_SUCCESS,
      payload: res.data.getAllLookUps,
    })
  } catch (e) {
    yield put({ type: GET_ALL_LOOKUPS_ERROR, e })
  }
}
function* getAllLookupsSaga() {
  yield takeEvery(GET_ALL_LOOKUPS, getAllLookupsData)
}

function getTripLookupsApi(data: any) {
  return client.query({
    query: getAllLookUps,
    variables: data.payload,
    fetchPolicy: 'no-cache',
  })
}

function* getTripLookupsData(action: ActionType) {
  try {
    const res = yield getTripLookupsApi(action)
    yield put({
      type: GET_TRIP_LOOKUPS_SUCCESS,
      payload: res.data.getAllLookUps,
    })
  } catch (e) {
    yield put({ type: GET_TRIP_LOOKUPS_ERROR, e })
  }
}

function* getTripLookupsSaga() {
  yield takeEvery(GET_TRIP_LOOKUPS, getTripLookupsData)
}

function getAllTypesApi(data: any) {
  return client
    .query({
      query: getAllTypes,
      variables: data.payload,
      fetchPolicy: 'no-cache',
    })
}

function* getAllTypesData(action: ActionType) {
  try {
    const res = yield getAllTypesApi(action)

    yield put({ type: GET_ALL_TYPES_SUCCESS, payload: res.data.getAllTypes })
  } catch (e) {
    yield put({ type: GET_ALL_TYPES_ERROR, e })
  }
}

function* getAllTypesSaga() {
  yield takeEvery(GET_ALL_TYPES, getAllTypesData)
}

function createLookupApi(data: any) {
  return client
    .query({
      query: createLookUp,
      variables: { lookUp: data.payload },
      fetchPolicy: 'no-cache',
    })
}

function* createLookupData(action: ActionType) {
  try {
    const res = yield createLookupApi(action)

    yield put({ type: CREATE_LOOKUP_SUCCESS, payload: res.data.createLookUp })
  } catch (e) {
    yield put({ type: CREATE_LOOKUP_ERROR, e })
  }
}

function* createLookupSaga() {
  yield takeEvery(CREATE_LOOKUP, createLookupData)
}

function editLookupApi(data: any) {
  return client
    .query({
      query: updateLookUp,
      variables: { lookUp: data.payload },
      fetchPolicy: 'no-cache',
    })
}

function* editLookupData(action: ActionType) {
  try {
    const res = yield editLookupApi(action)

    yield put({ type: EDIT_LOOKUP_SUCCESS, payload: res.data.updateLookUp })
  } catch (e) {
    yield put({ type: EDIT_LOOKUP_ERROR, e })
  }
}

function* editLookupSaga() {
  yield takeEvery(EDIT_LOOKUP, editLookupData)
}

function deleteLookupApi(data: any) {
  return client
    .query({
      query: changeLookUpStatus,
      variables: { input: data.payload },
      fetchPolicy: 'no-cache',
    })
}

function* deleteLookupData(action: ActionType) {
  try {
    const res = yield deleteLookupApi(action)

    yield put({
      type: DELETE_LOOKUP_SUCCESS,
      payload: res.data.changeLookUpStatus,
    })
  } catch (e) {
    yield put({ type: DELETE_LOOKUP_ERROR, e })
  }
}

function* deleteLookupSaga() {
  yield takeEvery(DELETE_LOOKUP, deleteLookupData)
}

export default function* rootLogin() {
  yield all([
    fork(LoginUser),
    fork(getFrontUsersSaga),
    fork(changeAccountStatusSaga),
    fork(getAllLookupsSaga),
    fork(getTripLookupsSaga),
    fork(getAllTypesSaga),
    fork(createLookupSaga),
    fork(deleteLookupSaga),
    fork(editLookupSaga),
  ])
}
