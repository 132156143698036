import { CREATE_REGISTRATION_CONTENT, GET_ALL_PAGE_INFORMATION, CLEAR_CREATE_REGISTRATION_CONTENT_FLAG } from './Types'

export const getAllPageInformation = (data: any) => ({
  type: GET_ALL_PAGE_INFORMATION,
  payload: data,
})

export const createRegistrationContent = (data: any) => ({
  type: CREATE_REGISTRATION_CONTENT,
  payload: data,
})

export const clearCreateRegistrationContentFlag = () => ({
  type: CLEAR_CREATE_REGISTRATION_CONTENT_FLAG,
  payload: null,
})
