import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import moment from 'moment'
import { connect } from 'react-redux'
import { Card, Button, Switch } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import {
  getManageSalesEngine,
  deleteSalesEngine,
  clearSalesEngineFlag,
} from '../../../store/salesEngine/manageSalesEngine/Action'
import { pagination, salesEngineType } from '../../../enums/enums'
import { formateDate } from '../../../helpers/helper'

interface Props {
  getManageSalesEngine: typeof getManageSalesEngine
  salesEngine: []
  deleteSalesEngine: typeof deleteSalesEngine
  clearSalesEngineFlag: typeof clearSalesEngineFlag
  salesEnginSuccess: boolean
  deleteSalesEngineSuccess: boolean
}

interface State {}

class SalesEngineBlacklist extends Component<Props, State> {
  state = {
    columns: [
      {
        title: 'Boat Name',
        dataIndex: 'boat.boatName',
        key: 'boat.boatName',
        sorter: (a: any, b: any) => a.boat.boatName.length - b.boat.boatName.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Boat Status',
        dataIndex: 'boat.boatStatus.typeId.type',
        key: 'boatStatus',
        sorter: (a: any, b: any) => a.boat.boatStatus.typeId.type.length - b.boat.boatStatus.typeId.type.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Boat Buyer',
        dataIndex: 'buyer.firstName',
        key: 'buyer',
        sorter: (a: any, b: any) => a.buyer.firstName.length - b.buyer.firstName.length,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: tableRecords, index: number) => {
          return record && record.buyer && `${record.buyer.firstName} ${record.buyer.lastName}`
        },
      },
      {
        title: 'Boat Buyer Country',
        dataIndex: 'buyer.country',
        key: 'buyer',
        sorter: (a: any, b: any) => a.buyer.country.length - b.buyer.country.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Buy-Sell Process',
        dataIndex: 'buySellProcess',
        key: 'buySellProcess',
        sorter: (a: any, b: any) => a.buySellProcess.length - b.buySellProcess.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string, record: tableRecords, index: number) => <>{formateDate(record && record.createdAt)} </>,
        sorter: (a: any, b: any) => a.createdAt.length - b.createdAt.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Manage Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteSalesEngine } = this.props
          return (
            <>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteSalesEngine, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { salesEnginSuccess, clearSalesEngineFlag } = props

    if (salesEnginSuccess) {
      clearSalesEngineFlag()
    }
    return null
  }

  componentDidMount() {
    const { getManageSalesEngine } = this.props
    const data = {
      page: pagination.page,
      limit: pagination.limit,
      type: salesEngineType.blackList,
    }
    getManageSalesEngine(data)
  }

  render() {
    const { salesEngine } = this.props
    const { columns } = this.state

    return (
      <>
        <Card title=" Manage Black List of Buyer Under Sales Engine Process">
          <ExpandTable columns={columns} tableData={salesEngine} expandHtml={false} isExpand={false} />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  salesEngine: state.salesEngineReducer && state.salesEngineReducer.salesEngine,
  deleteSalesEngineSuccess: state.salesEngineReducer && state.salesEngineReducer.deleteSalesEngineSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getManageSalesEngine: (data: any) => dispatch(getManageSalesEngine(data)),
  deleteSalesEngine: (data: string) => dispatch(deleteSalesEngine(data)),
  clearSalesEngineFlag: () => dispatch(clearSalesEngineFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngineBlacklist)
