import { combineReducers } from 'redux'
import { userLoginReducer } from './login/Reducers'
import { emailTemplateReducer } from './staticContent/emailTemplates/Reducer'
// import { boatReducer } from './boat/Reducer';
import { roleReducer } from './role/Reducer'
import { pendingAdsReducer } from './pendingAds/Reducer'
import { homeVideoReducer } from './staticContent/manageVideos/Reducer'
import { manageArticleReducer } from './staticContent/manageArticles/Reducer'
import { siteConfigurationReducer } from './generalSetting/Reducer'
import { canadianTaxReducer } from './salesEngine/canadianTax/Reducer'
import { homeAdvertisementsReducer } from './staticContent/forgotPassword/Reducer'
import { smtpReducer } from './staticContent/manageSMTP/Reducer'
import { userGuideReducer } from './staticContent/manageUserGuide/Reducer'
import { faqReducer } from './staticContent/manageFAQ/Reducer'
import { pageReducer } from './staticContent/managePages/Reducer'
import { boatTypesReducer } from './staticContent/manageBoatTypes/Reducer'
import { bannerReducer } from './manageBannerImages/manageCommonBanner/reducers'
import { frontUsersCountReducer } from './dashboard/frontUsers/Reducer'
import { smsTemplateReducer } from './staticContent/smsTemplates/Reducer'
import { boatShowReducer } from './staticContent/manageBoatShows/Reducer'
import { agreementReducer } from './manageAgreements/Reducer'
import { countryReducer } from './country/manageCountry/Reducer'
import { stateReducer } from './country/manageState/Reducer'
import { auctionRoomReducer } from './manageAuctionRoom/Reducer'
import { allBranchesReducer } from './manageSurveyorBranch/allBranches/Reducer'
import { salesEngineReducer } from './salesEngine/manageSalesEngine/Reducer'
import { marinaTypesReducer } from './staticContent/manageMarinaTypes/Reducer'
import { yatchTypesReducer } from './staticContent/manageYatchServiceTypes/Reducer'
import { rentTypeReducer } from './staticContent/manageRentTypes/Reducer'
import { adminUserCountReducer } from './dashboard/adminUsers/Reducer'
import { adminOtherCountReducer } from './dashboard/others/Reducer'
import { agreementsCountReducer } from './dashboard/manageAgreements/Reducer'
import { adminUserRoleReducer } from './adminUserRole/Reducer'
import { pageInformationReducer } from './staticContent/manageRegistrationContent/Reducer'
import { jobReducer } from './staticContent/manageJobs/Reducer'
import { userDocumentReducer } from './dashboard/documentVerify/Reducer'
import { costEstimateReducer } from './salesEngine/costEstimate/Reducer'
import { auctionRoomCountReducer } from './dashboard/auctionRoom/Reducer'
import { downloadsReducer } from './staticContent/manageDownloads/Reducer'
import { salesEngineHelpContentReducer } from './salesEngineHelpContent/Reducer'
import { feedbackReducer } from './form/Reducers'
import { surveyDocumentReducer } from './salesEngine/surveyDocument/Reducer'
import { reviewsReducer } from './manageReviews/Reducer'
import { resetPasswordReducer } from './changePassword/Reducers'
import { seoReducer } from './seo/Reducer'
import { permissionsReducer } from './permissions/Reducer'
import { manageRentCountReducer } from './dashboard/manageRent/Reducer'
import { manageSalesEngineCountReducer } from './dashboard/manageSalesEngine/Reducer'

export default combineReducers({
  userLoginState: userLoginReducer,
  manageArticleReducer: manageArticleReducer,
  emailTemplateReducer: emailTemplateReducer,
  canadianTaxReducer: canadianTaxReducer,
  siteConfigurationReducer: siteConfigurationReducer,
  roleState: roleReducer,
  pendingAdsState: pendingAdsReducer,
  homeAdvertisementsReducer: homeAdvertisementsReducer,
  homeVideoReducer: homeVideoReducer,
  smtpReducer: smtpReducer,
  userGuideReducer: userGuideReducer,
  faqReducer: faqReducer,
  pageReducer: pageReducer,
  jobReducer: jobReducer,
  downloadsReducer: downloadsReducer,
  boatTypesReducer: boatTypesReducer,
  bannerReducer: bannerReducer,
  frontUsersCountReducer: frontUsersCountReducer,
  smsTemplateReducer: smsTemplateReducer,
  boatShowReducer,
  agreementReducer,
  countryReducer: countryReducer,
  stateReducer: stateReducer,
  auctionRoomReducer,
  allBranchesReducer: allBranchesReducer,
  salesEngineReducer: salesEngineReducer,
  marinaTypesReducer: marinaTypesReducer,
  yatchTypesReducer: yatchTypesReducer,
  rentTypeReducer: rentTypeReducer,
  adminUserCountReducer: adminUserCountReducer,
  adminOtherCountReducer: adminOtherCountReducer,
  agreementsCountReducer: agreementsCountReducer,
  adminUserRoleReducer: adminUserRoleReducer,
  pageInformationReducer: pageInformationReducer,
  userDocumentReducer: userDocumentReducer,
  costEstimateReducer: costEstimateReducer,
  auctionRoomCountReducer: auctionRoomCountReducer,
  salesEngineHelpContentReducer: salesEngineHelpContentReducer,
  feedbackReducer: feedbackReducer,
  surveyDocumentReducer,
  reviewsReducer,
  resetPasswordReducer,
  seo: seoReducer,
  permissionsState: permissionsReducer,
  manageRentCountReducer: manageRentCountReducer,
  manageSalesEngineCountReducer: manageSalesEngineCountReducer
})
