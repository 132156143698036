import { costEstimateData, CREATE_OR_UPDATE_COST_ESTIMATE, GET_COST_ESTIMATE, CLEAR_COST_ESTIMATE } from './Types'

export function createOrUpdateCostEstimate(data: costEstimateData) {
  return {
    type: CREATE_OR_UPDATE_COST_ESTIMATE,
    payload: data,
  }
}

export function getCostEstimate() {
  return {
    type: GET_COST_ESTIMATE,
  }
}

export function clearCostEstimate() {
  return {
    type: CLEAR_COST_ESTIMATE,
  }
}
