import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import History from '../../../History'
import { getAllRentTypes, deleteRentType, clearRentTypeFlag } from '../../../store/staticContent/manageRentTypes/Actions'
import { paginationType } from '../../../store/staticContent/manageRentTypes/Types'

import ListCategoryIndex from '../CategoryIndexPages/ListCategoryIndex'

export interface Props {
  history: typeof History
  getAllRentTypes: typeof getAllRentTypes

  rentTypes: []
  deleteRentType: typeof deleteRentType
  clearRentTypeFlag: typeof clearRentTypeFlag
  deleteRentTypeSuccess: boolean
  rentTotalRow: number
}

export interface State {
  searchedValue: string
}
class ManageRentTypes extends Component<Props, State> {
  render() {
    const { deleteRentType, history, rentTypes, rentTotalRow, getAllRentTypes, clearRentTypeFlag, deleteRentTypeSuccess } =
      this.props
    return (
      <ListCategoryIndex
        type={'rentType'}
        history={history}
        pageTitle="Manage Rent Types"
        createPageLink="/create-rent-type"
        createPageTitle="Add Rent Type"
        listArray={rentTypes}
        pageTotal={rentTotalRow}
        listAction={getAllRentTypes}
        clearFlagAction={clearRentTypeFlag}
        deleteSuccess={deleteRentTypeSuccess}
        deleteAction={deleteRentType}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  rentTypes: state.rentTypeReducer.rentTypes,
  rentTotalRow: state.rentTypeReducer.rentTotalRow,
  deleteRentTypeSuccess: state.rentTypeReducer.deleteRentTypeSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllRentTypes: (data: paginationType) => dispatch(getAllRentTypes(data)),
  deleteRentType: (data: string) => dispatch(deleteRentType(data)),
  clearRentTypeFlag: () => dispatch(clearRentTypeFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageRentTypes)
