import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button, Select } from 'antd'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { getJobsContent, createOrUpdateJobsContent } from '../../../store/staticContent/manageJobs/Action'
import { JobsContent } from '../../../store/staticContent/manageJobs/Type'

const { Text } = Typography
const { Option } = Select

interface Props {
  history: any
  loading: boolean
  getJobsContent: typeof getJobsContent
  createOrUpdateJobsContent: typeof createOrUpdateJobsContent
  jobsContent: JobsContent
}

interface State {
  careerContext: {
    title: string
    description: string
  }
}

class CareerContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      careerContext: {
        title: '',
        description: '',
      },
    }
  }
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    return null
  }

  componentDidMount() {
    const { getJobsContent } = this.props
    getJobsContent()
  }

  render() {
    const { history, loading, createOrUpdateJobsContent, jobsContent } = this.props
    const { careerContext } = this.state
    const inputType = jobsContent?.id ? { id: jobsContent?.id, title: jobsContent.title, description: jobsContent.description } : careerContext
    console.log(jobsContent)

    return (
      <Formik
        key={jobsContent?.description}
        initialValues={inputType}
        onSubmit={values => {
          createOrUpdateJobsContent(values)
        }}
        validationSchema={Yup.object().shape({
          // title: Yup.string().required("Title field is required."),
          description: Yup.string().required('Job description field is required.'),
        })}
        render={({ setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  Career content
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  {!loading ? (
                    <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                      Save
                    </button>
                  ) : (
                    <Button loading={loading} className="ml-15 ant-btn ant-btn-primary">
                      Save
                    </Button>
                  )}
                </>
              }
            >
              {/* <Row gutter={16}>
                                <Col className="gutter-box" md={12}>
                                    <span className="ant-form-item-required" title="title">Title</span>
                                    <Input
                                        name="title"
                                        placeholder="Job Title"
                                        value={values.title}
                                        onChange={e => setFieldValue("title", e.target.value)}
                                    />
                                    <Text type="danger">
                                        <ErrorMessage
                                            className="invalid-feedback ant-typography-danger"
                                            name={`title`}
                                            component="span"
                                        />
                                    </Text>
                                </Col>
                            </Row> */}

              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="description">
                    Description
                  </span>
                  <WSField
                    type="textArea"
                    defaultValue={values.description}
                    onChangeText={(evt: any) => {
                      setFieldValue('description', evt.editor.getData())
                    }}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`description`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  jobsContent: state.jobReducer?.jobsContent,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getJobsContent: () => dispatch(getJobsContent()),
  createOrUpdateJobsContent: (data: JobsContent) => dispatch(createOrUpdateJobsContent(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CareerContent)
