import React, { Fragment } from 'react'

import { Tabs, List, Button, Table } from 'antd'

const { TabPane } = Tabs
export interface Props {}
export interface State {}

const dataSource = [
  {
    key: '1',
    Boat: 'Boat Invoice Number',
    Information: 'N_INVOICE58B5JXYE',
  },
  {
    key: '2',
    Boat: 'Tax On Boat',
    Information: '0.00%',
  },
  {
    key: '3',
    Boat: 'Boat Quantity',
    Information: 1,
  },
  {
    key: '4',
    Boat: 'Boat Original Cost	',
    Information: '$8,373,920.00',
  },
  {
    key: '5',
    Boat: 'Negotiated Cost',
    Information: '$8,373,920.00',
  },
  {
    key: '6',
    Boat: 'Admin Fees',
    Information: '$25.00',
  },
  {
    key: '7',
    Boat: 'Boat Final Tax Price',
    Information: '$0.00',
  },
  {
    key: '8',
    Boat: 'Boat PayPal Fee ( 3.9% + $0.03 )',
    Information: '$0.00',
  },
  {
    key: '9',
    Boat: 'Boat Net Price',
    Information: '$8,373,920.00',
  },
  {
    key: '10',
    Boat: 'Payment Date',
    Information: '03, December, 2019',
  },
  {
    key: '11',
    Boat: 'Payment Done Via',
    Information: 'Bank (Free Service)',
  },
]
const columns = [
  {
    title: 'Boat',
    dataIndex: 'Boat',
    key: 'Boat',
  },
  {
    title: 'Information',
    dataIndex: 'Information',
    key: 'Information',
  },
]
class ViewSalesEngine extends React.Component<Props, State> {
  render() {
    return (
      <Fragment>
        <Tabs defaultActiveKey="1" tabPosition="top">
          <TabPane tab="Boat Information">
            <div className="mb-50">
              <h4>Survey Payment</h4>
              <hr />
              <p className="mb-5">member has opted for new boat. So no survey payment here.</p>
            </div>
            <div className="d-flex base-line">
              <h4 className="mr-10">Final Payment: </h4>

              <div className="d-flex base-line">
                <Button className="btn-edit mr-2 font14">Stop Process</Button>
                <Button className="btn-delete mr-2 font14">Send Notification </Button>
                <p>Last Modified: December 17, 2019 12:13 am</p>
              </div>
            </div>
            <hr />
            <div>
              <p className="mb-5">member has opted for new boat. So no survey payment here.</p>
              <Table dataSource={dataSource} columns={columns} bordered />
            </div>
          </TabPane>
        </Tabs>
      </Fragment>
    )
  }
}

export default ViewSalesEngine
