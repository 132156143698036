import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'

import { Card, Row, Col, Typography, Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { handleSingleFileUpload, handleSingleFileDelete } from '../../helpers/S3FileUpload'
import History from '../../History'
import { MEDIA_TYPE, MEDIA_NAME, imageExtensions } from '../../enums/enums'
import NotificationWithIcon from '../../component/notification/Notification'
import UploadImage from '../../component/UploadImage'
import WSIcon from '../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { getAddMedia, splitUrlFromUnderscore, trimmedValue } from '../../helpers/helper'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { updatePaymentMethods, createPaymentMethods, clearManagePaymentMethodsFlag } from '../../store/pendingAds/Action'
import { RouteComponentProps } from 'react-router-dom'

const { Text } = Typography

const formValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name field is required.'),
  icon: Yup.string().required('Image upload is required.'),
})

interface Props {
  location: any
  paymentMethodSuccess: boolean
  updatePaymentMethodSuccess: boolean
  clearManagePaymentMethodsFlag: typeof clearManagePaymentMethodsFlag
  history: typeof History
  updatePaymentMethods: typeof updatePaymentMethods
  createPaymentMethods: typeof createPaymentMethods
  editPaymentMethod?: any
}
interface State {
  ImageType: {
    name: string
    icon: any
    id: string
    addMedia: any[]
  }
  loading: boolean
}

class CreatePayment extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      ImageType: {
        name: '',
        icon: '',
        id: '',
        addMedia: [],
      },
      loading: false,
    }
  }

  componentDidMount() {
    const { clearManagePaymentMethodsFlag } = this.props
    clearManagePaymentMethodsFlag()

    this.prepareValue()
  }

  prepareValue = () => {
    const { location } = this.props
    if (location && location.state && location.state.editPaymentMethod) {
      const { editPaymentMethod } = location.state
      editPaymentMethod.icon = editPaymentMethod.icon
      editPaymentMethod.addMedia = []
      editPaymentMethod.id = editPaymentMethod.id
      editPaymentMethod.name = editPaymentMethod.name
      this.setState({ ImageType: editPaymentMethod })
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = props => {
    const { paymentMethodSuccess, clearManagePaymentMethodsFlag, history, updatePaymentMethodSuccess } = props
    if (paymentMethodSuccess) {
      NotificationWithIcon('success', `Payment method type created successfully.`)
      clearManagePaymentMethodsFlag()
      history.push('/payment-methods')
    }
    if (updatePaymentMethodSuccess) {
      NotificationWithIcon('success', `Payment method type Updated successfully.`)
      clearManagePaymentMethodsFlag()
      history.push('/payment-methods')
    }

    return null
  }

  handleFileUpload = async (file: any, setFieldValue: any, values: any, acceptType: string, imageType: any, name: string) => {
    const { addMedia } = values
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(file, name, setFieldValue, addMedia, MEDIA_NAME.PAYMENTMETHODTYPEICON, MEDIA_TYPE.IMAGE)
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleRemove = (file: any, setFieldValue: any, values: any, name: string) => {
    const { addMedia } = values

    handleSingleFileDelete(file, name, setFieldValue, addMedia, [values.icon], 'find')
    setFieldValue(name, '')
  }

  onSubmitValues = (values: any) => {
    const { location, updatePaymentMethods, createPaymentMethods } = this.props

    if (location && location.state && location.state.editPaymentMethod) {
      const { createdAt, updatedAt, __typename, icon, addMedia, ...newValue } = values
      console.log('editPaymentMethod')

      updatePaymentMethods({
        ...newValue,
        addMedia: getAddMedia(values.addMedia),
      })
    } else {
      const { icon, addMedia, id, ...noIcon } = values
      createPaymentMethods({
        ...noIcon,
        addMedia: getAddMedia(values.addMedia),
      })
    }
  }

  render() {
    const { ImageType, loading } = this.state
    const { history, location } = this.props
    return (
      <Fragment>
        <Formik
          initialValues={(location && location.state && location.state.editPaymentMethod) || ImageType}
          onSubmit={values => this.onSubmitValues(values)}
          validationSchema={formValidationSchema}
        >
          {props => {
            const { handleSubmit, setFieldValue, values } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      {location && location.state && location.state.editPaymentMethod ? 'Edit' : 'Add'} Payment Method
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      {!loading ? (
                        <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                          Save
                        </button>
                      ) : (
                        <Button loading={loading} className="ml-15 ant-btn ant-btn-primary">
                          Save
                        </Button>
                      )}
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Name">
                        Name
                      </span>
                      <Field
                        className="ant-input"
                        name="name"
                        placeholder="Name"
                        value={values.name}
                        onBlur={(e: { target: { value: string } }) => setFieldValue('name', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="name" component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={16}>
                      <span>Image</span>
                      <div className="d-flex flex-wrap justify-content-center">
                        <div className="addBoatShow-imgUploader">
                          {values.icon ? (
                            <UploadImage
                              defaultFileList={[
                                {
                                  uid: values.icon.id,
                                  name: values.icon.name || 'Thumbnail',
                                  status: 'done',
                                  url: values.icon.url,
                                },
                              ]}
                              openFileDialogOnClick={!values.icon}
                              accept={imageExtensions}
                              withIcon
                              buttonText="Image"
                              selectSiteIcon={(file: any) => {
                                this.handleFileUpload(file, setFieldValue, values, 'image', 'thumbnail', 'icon')
                              }}
                              onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values, 'icon')}
                            />
                          ) : (
                            <UploadImage
                              accept={imageExtensions}
                              openFileDialogOnClick={!values.icon}
                              withIcon
                              buttonText="Image"
                              selectSiteIcon={(file: any) => {
                                this.handleFileUpload(file, setFieldValue, values, 'image', 'thumbnail', 'icon')
                              }}
                              onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values, 'icon')}
                            />
                          )}
                        </div>
                      </div>
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name="icon" component="span" />
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  paymentMethodSuccess: state.pendingAdsState && state.pendingAdsState.paymentMethodSuccess,
  updatePaymentMethodSuccess: state.pendingAdsState && state.pendingAdsState.updatePaymentMethodSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createPaymentMethods: (data: any) => dispatch(createPaymentMethods(data)),
  updatePaymentMethods: (data: any) => dispatch(updatePaymentMethods(data)),
  clearManagePaymentMethodsFlag: () => dispatch(clearManagePaymentMethodsFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePayment)
