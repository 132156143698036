import React, { Component, Fragment } from 'react'
import { Card, Row, Col, Typography, Checkbox, Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import FieldElements from '../FieldElements'
import * as Yup from 'yup'
import { selectOption, inputEvent, inputChecked } from '../../constants/GlobalEventTypeScript'
import History from '../../History'
import WSIcon from '../WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'

const { Text } = Typography
export interface Props {
  title: string
  history: typeof History
}
export interface managePermission {
  manageSalesEngineProcess: boolean
  manageArchiveEngineProcess: boolean
  manageSalesReview: boolean
}
export interface State {
  formData: {
    firstName: string
    lastName: string
    country: selectOption[]
    email: string
    userId: string
    contactNumber: string
    password: string
    confirmPassword: string
    permission: managePermission
  }
}

const countryData = [
  {
    name: 'India',
    value: 'india',
  },
  {
    name: 'USA',
    value: 'usa',
  },
]
class UserAdminForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      formData: {
        firstName: '',
        lastName: '',
        country: [],
        email: '',
        userId: '',
        contactNumber: '',
        password: '',
        confirmPassword: '',
        permission: {
          manageSalesEngineProcess: false,
          manageArchiveEngineProcess: false,
          manageSalesReview: false,
        },
      },
    }
  }

  render() {
    const { formData } = this.state
    const { title, history } = this.props
    return (
      <Fragment>
        <Formik
          initialValues={formData}
          onSubmit={values => {}}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required('First name field is required.'),
            lastName: Yup.string().required('Last name field is required.'),
            email: Yup.string().email().required('Email field is required.'),
            country: Yup.array().min(1, 'Country field is required.'),
            userId: Yup.string().required('User Id field is required.'),
            password: Yup.string().required('Password field is required'),
            confirmPassword: Yup.string().oneOf([Yup.ref('password'), ''], 'Passwords must match'),
          })}
        >
          {props => {
            const { handleSubmit, setFieldValue } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} /> {title}
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                        Save
                      </button>
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="First Name">
                        First Name
                      </span>
                      <Field className="ant-input" name="firstName" placeholder="First Name" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`firstName`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Last Name">
                        Last Name
                      </span>
                      <Field className="ant-input" name="lastName" placeholder="Last Name" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback" name={`lastName`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Country">
                        Country
                      </span>
                      <Field
                        type="MultiSelect"
                        name="country"
                        placeholder="Select Customer"
                        options={countryData}
                        onChange={(value: selectOption[]) => {
                          setFieldValue('country', value)
                        }}
                        component={FieldElements}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback" name={`country`} component="span" />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Email">
                        Email
                      </span>
                      <Field className="ant-input" name="email" placeholder="Email" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback" name={`email`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="User ID">
                        User ID
                      </span>
                      <Field className="ant-input" name="userId" placeholder="User Id" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback " name={`userId`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span title="Contact Number">Contact Number</span>
                      <Field
                        type="InputNumber"
                        className="ant-input"
                        component={FieldElements}
                        name="contactNumber"
                        placeholder="Contact Number"
                        onChange={(e: inputEvent) => {
                          let value = e.target.value !== '' ? parseInt(e.target.value) : ''
                          setFieldValue('contactNumber', value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback " name={`contactNumber`} component="span" />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Password">
                        Password
                      </span>
                      <Field type="password" className="ant-input" name="password" placeholder="Password" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback " name={`password`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title=" Re-Type Password">
                        Re-Type Password 2
                      </span>
                      <Field type="password" className="ant-input" name="confirmPassword" placeholder="Password" />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback " name={`confirmPassword`} component="span" />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" span={24}>
                      <div title="Permissions">Permissions</div>

                      <Checkbox
                        name={`permission.manageSalesEngineProcess`}
                        onChange={(e: inputChecked) => {
                          setFieldValue('permission.manageSalesEngineProcess', e.target.checked)
                        }}
                      >
                        {' '}
                        Manage Sales Engine Process
                      </Checkbox>

                      <Checkbox
                        name={`permission.manageArchiveEngineProcess`}
                        onChange={(e: inputChecked) => {
                          setFieldValue('permission.manageArchiveEngineProcess', e.target.checked)
                        }}
                      >
                        {' '}
                        Manage Archive Sale Process
                      </Checkbox>

                      <Checkbox
                        name={`permission.manageSalesReview`}
                        onChange={(e: inputChecked) => {
                          setFieldValue('permission.manageSalesReview', e.target.checked)
                        }}
                      >
                        {' '}
                        Manage Sales Review
                      </Checkbox>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}
export default UserAdminForm
