import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { tableRecords } from './FormTable'
import { displayDefaultValue, getSearchedData } from '../../helpers/helper'
import { getSuggestionsAction } from '../../store/form/Actions'
import { getAllBoatPagination } from '../../enums/enums'
import { Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import ExpandTable from '../../component/ExpandTable'

interface State {
  columns: Array<Object>,
  searchedValue: string
}

interface Props {
  getSuggestionsAction: typeof getSuggestionsAction
  suggestionsTotalRow: number
  suggestions: any[]
}

class Suggestions extends Component<Props, State> {

  // componentDidMount() {
  //   const { getSuggestionsAction } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getSuggestionsAction(input)
  // }

  onSearch = (value: any) => {
    const { getSuggestionsAction } = this.props;

    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    };

    if (value) {
      this.setState({searchedValue: value})
      getSuggestionsAction({ ...input, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getSuggestionsAction({ ...input })
    }
  }

  state = {
    columns: [
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: (text: any) => {
          return <div>{displayDefaultValue(text)}</div>
        },
      },
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        render: (text: any) => {
          return <div>{`${text}`}</div>
        },
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Mobile Number',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
        render: (t: any) => t || 'N/A'
      },
      {
        title: 'Topic',
        dataIndex: 'topic',
        key: 'topic',
      },
      {
        title: 'Suggestion',
        dataIndex: 'description',
        key: 'description',
      }
    ],
    searchedValue: '',
  }

  render() {
    const { columns, searchedValue } = this.state
    const { suggestionsTotalRow, suggestions, getSuggestionsAction } = this.props
    return (
      <Card title="Suggestions" extra={<TitleSearch onSearch={this.onSearch} />}>
        <div className='no-white-space'>
          <ExpandTable
            columns={columns}
            tableData={suggestions}
            expandHtml={false}
            isExpand={false}
            page="boat"
            totalUsersRow={suggestionsTotalRow}
            pageAction={getSuggestionsAction}
            extraInput={{ page_type: '0', searchTerm: searchedValue }}
            rowKey={(record: tableRecords) => record?.id}
          />
        </div>
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    suggestions: state.feedbackReducer.suggestions,
    suggestionsTotalRow: state.feedbackReducer.suggestionsTotalRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getSuggestionsAction: (data: any) => dispatch(getSuggestionsAction(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions)
