import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Button, Card, Switch } from 'antd'
import moment from 'moment'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { History } from 'history'
import {
  getAllBoatShows,
  changeBoatShowStatus,
  deleteBoatShow,
  clearBoatShowFlag,
} from '../../../store/staticContent/manageBoatShows/Action'
import {
  getBoatShowInput,
  boatShow,
  BoatShowStatusInput,
  deleteBoatShowInput,
} from '../../../store/staticContent/manageBoatShows/Types'

import './ManageBoatShows.scss'
import {
  getAllBoatPagination,
  paginationPage,
  defaultImage,
  activeInactive,
  rejectBoatShow,
  rejectErrorMessage,
} from '../../../enums/enums'
import { getSearchedData, formateDate, displayDefaultValue, removePTag } from '../../../helpers/helper'
import { TitleSearch } from '../../../component/SearchBar'
import FilterButton from '../../filters/FilterButton'
import CommonModal from '../../../component/modal/CommonModal'
import NotificationWithIcon from '../../../component/notification/Notification'
import { RejectionMailEditor } from '../../../component/RejectionMailEditor'

interface Props {
  history: History
  getAllBoatShows: typeof getAllBoatShows
  boatShows: boatShow[]
  changeBoatShowStatus: typeof changeBoatShowStatus
  boatShowsTotal: number
  isLoading: boolean
  deleteBoatShow: typeof deleteBoatShow
  clearBoatShowFlag: typeof clearBoatShowFlag
  deleteSuccess: boolean
}
let pageFilterArray: any = []
interface State {
  columns: Array<Object>
  searchedValue: string
  expandKeys: string[]
  currentRecord: tableRecords
  modalVisible: boolean
  error: boolean
  rejectMessage: string
}

class ManageBoatShows extends Component<Props, State> {
  componentDidMount() {
    // const { getAllBoatShows } = this.props
    pageFilterArray = [
      {
        title: 'Status',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'status',
      },
      {
        title: 'Approval Status',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'adStatus',
      },
    ]
    // getAllBoatShows({
    //   city: '',
    //   country: '',
    //   page: getAllBoatPagination.page,
    //   limit: getAllBoatPagination.limit,
    //   isAdmin: true,
    // })
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { clearBoatShowFlag, deleteSuccess } = props

    if (deleteSuccess) {
      clearBoatShowFlag()
      return {
        modalVisible: false,
        rejectMessage: '',
      }
    }

    return null
  }

  setModalVisible = (data: boolean, record: tableRecords) => {
    this.setState({
      modalVisible: data,
      currentRecord: record,
    })
  }

  handleChange = (data: string) => {
    this.setState({
      rejectMessage: data,
    })
  }

  showHideError = (data: boolean) => {
    this.setState({
      error: data,
    })
  }

  onSubmit = () => {
    const { deleteBoatShow, boatShows } = this.props
    const { currentRecord, rejectMessage } = this.state
    if (rejectMessage.length) {
      deleteBoatShow({
        id: currentRecord.id,
        note: removePTag(rejectMessage),
        fieldName: 'boatShows',
        fieldArray: boatShows,
      })
    } else {
      NotificationWithIcon('error', 'Fill the field')
    }
  }

  state = {
    searchedValue: '',
    expandKeys: [],
    currentRecord: { id: '' },
    modalVisible: false,
    error: false,
    rejectMessage: '',

    columns: [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a: any, b: any) => a.title.length - b.title.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Image',
        dataIndex: 'boatshowImages',
        key: 'boatshowImages',
        render: (text: any) => (
          <img
            src={(text && text.length && text[0].url) || defaultImage.image}
            alt="category showLogo"
            className="logo-icon"
          ></img>
        ),
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text: any) => {
          return (
            <div>
              {text.firstName} {text.lastName}
            </div>
          )
        },
      },
      {
        title: 'Country',
        dataIndex: 'address',
        key: 'address',
        render: (text: any) => {
          return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
        },
      },
      {
        title: 'Approval Status',
        dataIndex: 'adStatus',
        key: 'adStatus',
        render: (text: string, record: tableRecords, index: number) => {
          const { changeBoatShowStatus } = this.props
          return (
            <Switch
              checked={record.adStatus}
              onChange={() =>
                changeBoatShowStatus({
                  id: record.id,
                  column: 'adStatus',
                  value: record.adStatus,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Block"
            />
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a: any, b: any) => a.status.length - b.status.length,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: tableRecords, index: number) => {
          const { changeBoatShowStatus } = this.props
          return (
            <Switch
              checked={record.status}
              onChange={() =>
                changeBoatShowStatus({
                  id: record.id,
                  column: 'status',
                  value: record.status,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Block"
            />
          )
        },
      },
      {
        title: 'Date',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text: string, record: tableRecords, index: number) => <>{formateDate(record && record.createdAt)}</>,
        sorter: (a: any, b: any) => a.updatedAt.length - b.updatedAt.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Action',
        dataIndex: 'delete',
        key: 'delete',
        render: (text: string, record: any) => (
          <Button onClick={() => this.setModalVisible(true, record)} className="btn-delete">
            Reject
          </Button>
        ),
      },
    ],
  }

  onSearch = (value: any) => {
    const { getAllBoatShows } = this.props
    if (value) {
      this.setState({searchedValue: value})
      getAllBoatShows({ searchTerm: value, page: 1, limit: 10, isAdmin: true, city: '', country: '' })
    } else {
      this.setState({searchedValue: ''})
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
        isAdmin: true, city: '', country: ''
      }
      getAllBoatShows(input)
    }
  }

  render() {
    const { columns, searchedValue, expandKeys, modalVisible, error, rejectMessage } = this.state
    const { boatShows, boatShowsTotal, getAllBoatShows, isLoading } = this.props

    return (
      <>
        <Card title="Manage Boat Shows" extra={<TitleSearch onSearch={this.onSearch} />}>
          <FilterButton filterArray={pageFilterArray} triggerAction={getAllBoatShows} haveCountry />
          <ExpandTable
            columns={columns}
            tableData={boatShows}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={boatShowsTotal}
            pageAction={getAllBoatShows}
            extraInput={{ city: '', country: '', isAdmin: true, searchTerm: searchedValue }}
            page={paginationPage.boat}
            expandedRowRender={(record: any) => (
              <div className="column-width">
                <p>
                  <b>Description:</b> {record.showDescription}
                </p>
              </div>
            )}
            onExpand={(expanded: boolean, record: tableRecords) => {
              const { id } = record
              if (expanded && id) {
                this.setState({ expandKeys: [id] })
              } else {
                this.setState({ expandKeys: [] })
              }
            }}
            rowKey={(record: tableRecords) => record?.id}
            expandedRowKeys={expandKeys}
            tableLoading={isLoading}
          />
        </Card>
        {modalVisible && (
          <CommonModal
            customWidth={'60%'}
            okText="Submit"
            isFlag={modalVisible}
            title="Reason Of Rejection"
            handleCancel={() => this.setModalVisible(false, { id: '' })}
            footerProp={{ footer: null }}
          >
            <RejectionMailEditor
              onClick={() => (rejectMessage ? this.onSubmit() : this.showHideError(true))}
              errorMessage={error}
              onChangeText={(evt: any) => {
                error && this.showHideError(false)
                this.handleChange(evt.editor.getData())
              }}
            />
          </CommonModal>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  boatShows: state.boatShowReducer.boatShows,
  isLoading: state.boatShowReducer.isLoading,
  boatShowsTotal: state.boatShowReducer.boatShowsTotal,
  deleteSuccess: state.boatShowReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllBoatShows: (data: getBoatShowInput) => dispatch(getAllBoatShows(data)),
  changeBoatShowStatus: (data: BoatShowStatusInput) => dispatch(changeBoatShowStatus(data)),
  deleteBoatShow: (data: deleteBoatShowInput) => dispatch(deleteBoatShow(data)),
  clearBoatShowFlag: () => dispatch(clearBoatShowFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageBoatShows)
