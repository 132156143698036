import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'antd'
import { displayDefaultValue } from '../helpers/helper'
import Title from 'antd/lib/typography/Title'

const UserCountCard = (props: any) => {
  const { item, loading } = props

  return (
    <Link to={item.path}>
      <Card loading={loading} title={displayDefaultValue(item.role)} bordered={false} className="cursor-pointer">
        <div className="d-flex justify-content-between">
          <img src={item.imgPath || require('../assets/image/user.png')} height="50px" width="50px" />

          <Title level={2} type="secondary">
            {displayDefaultValue(item.count)}
          </Title>
        </div>
      </Card>
    </Link>
  )
}
export default UserCountCard
