import {
  faqData,
  GET_ALL_FAQ_LIST,
  CREATE_FAQ_DETAIL,
  UPDATE_FAQ_DETAIL,
  DELETE_FAQ_DETAIL,
  CLEAR_FAQ_FLAG,
  CHANGE_FAQ_DETAIL_STATUS,
} from './Type'

export function getManageFaq(data: any) {
  return {
    type: GET_ALL_FAQ_LIST,
    payload: data,
  }
}

export function createManageFaq(data: faqData) {
  return {
    type: CREATE_FAQ_DETAIL,
    payload: data,
  }
}

export function updateManageFaq(data: faqData) {
  return {
    type: UPDATE_FAQ_DETAIL,
    payload: data,
  }
}

export function deleteManageFaq(data: string) {
  return {
    type: DELETE_FAQ_DETAIL,
    payload: data,
  }
}

export function clearFaqFlag() {
  return {
    type: CLEAR_FAQ_FLAG,
  }
}

export function changeFaqDetailStatus(data: any) {
  return {
    type: CHANGE_FAQ_DETAIL_STATUS,
    payload: data,
  }
}
