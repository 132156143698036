import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch } from 'antd'
import { Link } from 'react-router-dom'
import { getAllBoatPagination, pagination } from '../../../enums/enums'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { getCountries, deleteCountry, clearCountryFlag, changeCountryStatus, searchCountry } from '../../../store/country/manageCountry/Action'
import { countryStatus } from '../../../store/country/manageCountry/Type'
import WSIcon from '../../../component/WSIcon'
import { TitleSearch } from '../../../component/SearchBar'
import { PlusOutlined } from '@ant-design/icons'
import { getSearchedData } from '../../../helpers/helper'

export interface Props {
  getCountries: typeof getCountries
  changeCountryStatus: typeof changeCountryStatus
  countries: []
  history: typeof History
  deleteCountry: typeof deleteCountry
  clearCountryFlag: typeof clearCountryFlag
  emailSuccess: boolean
  deleteSuccess: boolean
  countriesTotalRow: number
  searchCountry: typeof searchCountry
}

interface State {
  searchedValue: string
}

class ManageCountries extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { deleteSuccess, getCountries, clearCountryFlag } = props

    if (deleteSuccess) {
      const data = {
        page: pagination.page,
        limit: pagination.limit,
      }
      getCountries(data)
      clearCountryFlag()
    }
    return null
  }

  // componentDidMount() {
  //   const { getCountries, countries } = this.props
  //   const data = {
  //     page: pagination.page,
  //     limit: pagination.limit,
  //   }
  //   if (!countries.length) {
  //     getCountries(data)
  //   }
  // }

  editCountry = (record: tableRecords) => {
    const { history } = this.props
    history.push('/add-country', { country: record, isEdit: true })
  }

  state = {
    searchedValue: '',
    columns: [
      {
        title: 'Title',
        dataIndex: 'name',
        key: 'name',
        sorter: (a: any, b: any) => a.name.length - b.name.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Boat Owner',
        dataIndex: 'boatOwerStatus',
        key: 'boatOwerStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.boatOwerStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.boatOwerStatus === null ? false : record.boatOwerStatus,
                  column: 'boatOwerStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Boat Manufacturer',
        dataIndex: 'boatManufacturerStatus',
        key: 'boatManufacturerStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.boatManufacturerStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.boatManufacturerStatus === null ? false : record.boatManufacturerStatus,
                  column: 'boatManufacturerStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Broker And Dealer',
        dataIndex: 'brokerAndDealerStatus',
        key: 'brokerAndDealerStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.brokerAndDealerStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.brokerAndDealerStatus === null ? false : record.brokerAndDealerStatus,
                  column: 'brokerAndDealerStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Surveyor',
        dataIndex: 'surveyorStatus',
        key: 'surveyorStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.surveyorStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.surveyorStatus === null ? false : record.surveyorStatus,
                  column: 'surveyorStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Shipper',
        dataIndex: 'ShipperStatus',
        key: 'ShipperStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.ShipperStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.ShipperStatus === null ? false : record.ShipperStatus,
                  column: 'ShipperStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Service and Maintenance',
        dataIndex: 'serviceStatus',
        key: 'serviceStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.serviceStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.serviceStatus === null ? false : record.serviceStatus,
                  column: 'serviceStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Marina and Storage',
        dataIndex: 'marinaStorageStatus',
        key: 'marinaStorageStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.marinaStorageStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.marinaStorageStatus === null ? false : record.marinaStorageStatus,
                  column: 'marinaStorageStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Rent',
        dataIndex: 'rentStatus',
        key: 'rentStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.rentStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.rentStatus === null ? false : record.rentStatus,
                  column: 'rentStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'AS Agent',
        dataIndex: 'agentStatus',
        key: 'agentStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.agentStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.agentStatus === null ? false : record.agentStatus,
                  column: 'agentStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Member',
        dataIndex: 'mamberStatus',
        key: 'mamberStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.mamberStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.mamberStatus === null ? false : record.mamberStatus,
                  column: 'mamberStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      // {
      //   title: 'Seller',
      //   dataIndex: 'sellerStatus',
      //   key: 'sellerStatus',
      //   render: (text: string, record: tableRecords, index: number) => {
      //     const { changeCountryStatus } = this.props

      //     return (
      //       <Switch
      //         checked={!record.sellerStatus}
      //         onChange={() =>
      //           changeCountryStatus({
      //             value: record.sellerStatus,
      //             column: 'sellerStatus',
      //             id: record.id,
      //           })
      //         }
      //         checkedChildren="Active"
      //         unCheckedChildren="Blocked"
      //       />
      //     )
      //   },
      // },
      // {
      //   title: 'Builder',
      //   dataIndex: 'builderStatus',
      //   key: 'builderStatus',
      //   render: (text: string, record: tableRecords, index: number) => {
      //     const { changeCountryStatus } = this.props

      //     return (
      //       <Switch
      //         checked={!record.builderStatus}
      //         onChange={() =>
      //           changeCountryStatus({
      //             value: record.builderStatus,
      //             column: 'builderStatus',
      //             id: record.id,
      //           })
      //         }
      //         checkedChildren="Active"
      //         unCheckedChildren="Blocked"
      //       />
      //     )
      //   },
      // },
      // {
      //   title: 'Surveyor',
      //   dataIndex: 'surveyorStatus',
      //   key: 'surveyorStatus',
      //   render: (text: string, record: tableRecords, index: number) => {
      //     const { changeCountryStatus } = this.props

      //     return (
      //       <Switch
      //         checked={!record.surveyorStatus}
      //         onChange={() =>
      //           changeCountryStatus({
      //             value: record.surveyorStatus,
      //             column: 'surveyorStatus',
      //             id: record.id,
      //           })
      //         }
      //         checkedChildren="Active"
      //         unCheckedChildren="Blocked"
      //       />
      //     )
      //   },
      // },
      // {
      //   title: 'Member/Service/Rental/Marina/ShareContact',
      //   dataIndex: 'commonModuleStatus',
      //   key: 'commonModuleStatus',
      //   render: (text: string, record: tableRecords, index: number) => {
      //     const { changeCountryStatus } = this.props

      //     return (
      //       <Switch
      //         checked={!record.commonModuleStatus}
      //         onChange={() =>
      //           changeCountryStatus({
      //             value: record.commonModuleStatus,
      //             column: 'commonModuleStatus',
      //             id: record.id,
      //           })
      //         }
      //         checkedChildren="Active"
      //         unCheckedChildren="Blocked"
      //       />
      //     )
      //   },
      // },
      {
        title: 'Sales Engine',
        dataIndex: 'salesEngineStatus',
        key: 'salesEngineStatus',
        render: (text: string, record: tableRecords, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.salesEngineStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.salesEngineStatus,
                  column: 'salesEngineStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Book Now',
        dataIndex: 'bookNowStatus',
        key: 'bookNowStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.bookNowStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.bookNowStatus === null ? false : record.bookNowStatus,
                  column: 'bookNowStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Bid Now',
        dataIndex: 'auctionStatus',
        key: 'auctionStatus',
        render: (text: string, record: any, index: number) => {
          const { changeCountryStatus } = this.props

          return (
            <Switch
              checked={!record.auctionStatus}
              onChange={() =>
                changeCountryStatus({
                  value: record.auctionStatus === null ? false : record.auctionStatus,
                  column: 'auctionStatus',
                  id: record.id,
                })
              }
              checkedChildren="Active"
              unCheckedChildren="Blocked"
            />
          )
        },
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          // const { deleteTemplate } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editCountry(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(this.props.deleteCountry, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  onSearch = (value: any) => {
    const { searchCountry, getCountries } = this.props
    if (value) {
      this.setState({searchedValue: value})
      searchCountry({ query: value, page: 1, limit: 10 })
    } else {
      this.setState({searchedValue: ''})
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getCountries(input)
    }
  }

  render() {
    const { columns, searchedValue } = this.state
    const { countries, countriesTotalRow, getCountries } = this.props

    return (
      <Card
        title="Manage Countries"
        extra={
          <div className="d-flex flex-wrap">
            {/* <div className="mr-10 mt-8"> */}
            <TitleSearch onSearch={this.onSearch} />
            {/* </div> */}
            <Button className="btn-add" style={{ marginLeft: '20px' }}>
              <Link to="/add-country">
                <WSIcon type={<PlusOutlined />} /> Add Country
              </Link>
            </Button>
          </div>
        }
      >
        <ExpandTable
          columns={columns}
          tableData={countries}
          expandHtml={false}
          isExpand={false}
          page="boat"
          pageAction={searchedValue ? searchCountry : getCountries}
          totalUsersRow={countriesTotalRow}
          extraInput={{ query: searchedValue }}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  countries: state.countryReducer && state.countryReducer.countries,
  deleteSuccess: state.countryReducer && state.countryReducer.deleteSuccess,
  countriesTotalRow: state.countryReducer && state.countryReducer.countriesTotalRow,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCountries: (data: any) => dispatch(getCountries(data)),
  deleteCountry: (data: string) => dispatch(deleteCountry(data)),
  changeCountryStatus: (data: countryStatus) => dispatch(changeCountryStatus(data)),
  clearCountryFlag: () => dispatch(clearCountryFlag()),
  searchCountry: (data: any) => dispatch(searchCountry(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageCountries)
