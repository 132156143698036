import {
  GET_ALL_SMS_TEMPLATES,
  ADD_SMS_TEMPLATE,
  GET_SINGLE_SMS_TEMPLATES,
  UPDATE_SMS_TEMPLATE,
  DELETE_SMS_TEMPLATE,
  smsTemplateInput,
  CLEAR_SMS_TEMPLATE_FLAG,
  SEARCH_TEMPLATE,
} from './Types'

export const createSmsTemplate = (data: any) => ({
  type: ADD_SMS_TEMPLATE,
  payload: data,
})

export const getAllSmsTemplates = (data: any) => ({
  type: GET_ALL_SMS_TEMPLATES,
  payload: data,
})

export const editSmsTemplate = (data: string) => ({
  type: GET_SINGLE_SMS_TEMPLATES,
  payload: data,
})

export const updateSmsTemplate = (data: smsTemplateInput) => ({
  type: UPDATE_SMS_TEMPLATE,
  payload: data,
})

export const deleteSmsTemplate = (data: string) => ({
  type: DELETE_SMS_TEMPLATE,
  payload: data,
})

export const clearSmsTemplateFlag = () => ({
  type: CLEAR_SMS_TEMPLATE_FLAG,
})

export const searchTemplate = (payload: any) => ({
  type: SEARCH_TEMPLATE,
  payload
})