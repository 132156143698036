export interface smsTemplateState {
  smsTemplate: smsTemplate
  smsTemplates: smsTemplate[]
  smsTemplateSuccess: boolean
  smsTemplateError: boolean
  deleteSmsTemplateSuccess: boolean
  deleteSmsTemplateError: boolean
  smsTemplatesTotalRow: number
  isLoading: boolean
}

export interface smsTemplate {
  id: string
  type: string
  body: string
  updatedAt: Date
}

export interface smsTemplateInput {
  type: string
  body: string
}

export const ADD_SMS_TEMPLATE = 'ADD_SMS_TEMPLATE'
export const ADD_SMS_TEMPLATE_SUCCESS = 'ADD_SMS_TEMPLATE_SUCCESS'
export const ADD_SMS_TEMPLATE_FAILURE = 'ADD_SMS_TEMPLATE_FAILURE'

export const GET_ALL_SMS_TEMPLATES = 'GET_ALL_SMS_TEMPLATES'
export const GET_ALL_SMS_TEMPLATES_SUCCESS = 'GET_ALL_SMS_TEMPLATES_SUCCESS'
export const GET_ALL_SMS_TEMPLATES_FAILURE = 'GET_ALL_SMS_TEMPLATES_FAILURE'

export const GET_SINGLE_SMS_TEMPLATES = 'GET_SINGLE_SMS_TEMPLATES'
export const GET_SINGLE_SMS_TEMPLATES_SUCCESS = 'GET_SINGLE_SMS_TEMPLATES_SUCCESS'
export const GET_SINGLE_SMS_TEMPLATES_FAILURE = 'GET_SINGLE_SMS_TEMPLATES_FAILURE'

export const UPDATE_SMS_TEMPLATE = 'UPDATE_SMS_TEMPLATE'
export const UPDATE_SMS_TEMPLATE_SUCCESS = 'UPDATE_SMS_TEMPLATE_SUCCESS'
export const UPDATE_SMS_TEMPLATE_FAILURE = 'UPDATE_SMS_TEMPLATE_FAILURE'

export const DELETE_SMS_TEMPLATE = 'DELETE_SMS_TEMPLATE'
export const DELETE_SMS_TEMPLATE_SUCCESS = 'DELETE_SMS_TEMPLATE_SUCCESS'
export const DELETE_SMS_TEMPLATE_FAILURE = 'DELETE_SMS_TEMPLATE_FAILURE'

export const CLEAR_SMS_TEMPLATE_FLAG = 'CLEAR_SMS_TEMPLATE_FLAG'

export const SEARCH_TEMPLATE = 'SEARCH_TEMPLATE';
export const SEARCH_TEMPLATE_SUCCESS = 'SEARCH_TEMPLATE_SUCCESS';
export const SEARCH_TEMPLATE_FAILURE = 'SEARCH_TEMPLATE_FAILURE';

interface getAllSmsTemplates {
  type: typeof GET_ALL_SMS_TEMPLATES
  payload: any
}
interface getAllSmsTemplatesSuccess {
  type: typeof GET_ALL_SMS_TEMPLATES_SUCCESS
  payload: any
}
interface getAllSmsTemplatesFailure {
  type: typeof GET_ALL_SMS_TEMPLATES_FAILURE
  payload: any
}

interface createSmsTemplate {
  type: typeof ADD_SMS_TEMPLATE
  payload: smsTemplateInput
}
interface createSmsTemplateSuccess {
  type: typeof ADD_SMS_TEMPLATE_SUCCESS
  payload: smsTemplate
}
interface createSmsTemplateFailure {
  type: typeof ADD_SMS_TEMPLATE_FAILURE
  payload: any
}

interface editSmsTemplate {
  type: typeof GET_SINGLE_SMS_TEMPLATES
  payload: string
}
interface editSmsTemplateSuccess {
  type: typeof GET_SINGLE_SMS_TEMPLATES_SUCCESS
  payload: smsTemplate
}
interface editSmsTemplateFailure {
  type: typeof GET_SINGLE_SMS_TEMPLATES_FAILURE
  payload: any
}

interface updateSmsTemplate {
  type: typeof UPDATE_SMS_TEMPLATE
  payload: smsTemplateInput
}
interface updateSmsTemplateSuccess {
  type: typeof UPDATE_SMS_TEMPLATE_SUCCESS
  payload: smsTemplate
}
interface updateSmsTemplateFailure {
  type: typeof UPDATE_SMS_TEMPLATE_FAILURE
  payload: any
}

interface deleteSmsTemplate {
  type: typeof DELETE_SMS_TEMPLATE
  payload: string
}
interface deleteSmsTemplateSuccess {
  type: typeof DELETE_SMS_TEMPLATE_SUCCESS
  payload: smsTemplate
}
interface deleteSmsTemplateFailure {
  type: typeof DELETE_SMS_TEMPLATE_FAILURE
  payload: any
}

interface clearSmsTemplateFlag {
  type: typeof CLEAR_SMS_TEMPLATE_FLAG
  payload: any
}

interface searchTemplate {
  type: typeof SEARCH_TEMPLATE
  payload: any
}

interface searchTemplateSuccess {
  type: typeof SEARCH_TEMPLATE_SUCCESS,
  payload: any
}

interface searchTemplateFailure {
  type: typeof SEARCH_TEMPLATE_FAILURE
}

export type ActionType =
  | getAllSmsTemplates
  | getAllSmsTemplatesSuccess
  | getAllSmsTemplatesFailure
  | createSmsTemplate
  | createSmsTemplateSuccess
  | createSmsTemplateFailure
  | editSmsTemplate
  | editSmsTemplateSuccess
  | editSmsTemplateFailure
  | updateSmsTemplate
  | updateSmsTemplateSuccess
  | updateSmsTemplateFailure
  | deleteSmsTemplate
  | deleteSmsTemplateSuccess
  | deleteSmsTemplateFailure
  | clearSmsTemplateFlag
  | searchTemplate
  | searchTemplateSuccess
  | searchTemplateFailure
