import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { tableRecords } from './FormTable'
import { getSearchedData } from '../../helpers/helper'
import { getAllBoatPagination } from '../../enums/enums'
import { Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import ExpandTable from '../../component/ExpandTable'
import { getComplaintAction } from '../../store/form/Actions'

interface Props {
  getComplaintAction: typeof getComplaintAction
  complaintTotalRow: number
  complaint: any[]
}

interface State {
  columns: Array<Object>,
  searchedValue: string
}

class Complaint extends Component<Props, State> {

  // componentDidMount() {
  //   const { getComplaintAction } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getComplaintAction(input)
  // }

  onSearch = (value: any) => {
    const { getComplaintAction } = this.props
    if (value) {
      this.setState({searchedValue: value})
      getComplaintAction({ searchTerm: value, page: 1, limit: 10, isAdmin: true })
    } else {
      this.setState({searchedValue: ''})
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getComplaintAction(input)
    }
  }

  state = {
    columns: [
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
        render: (text: any) => {
          return <div>{`${text}`}</div>
        },
      },
      {
        title: 'User Type',
        dataIndex: 'userRole',
        key: 'userRole',
        render: (text: any) => {
          return <div>{text.role}</div>
        },
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Mobile Number',
        dataIndex: 'mobileNumber',
        key: 'mobileNumber',
        render: (t: any) => t || 'N/A'
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
      },
      {
        title: 'Is Market Transaction',
        dataIndex: 'isMarketTransaction',
        key: 'isMarketTransaction',
        render: (text: any) => {
          return <div>{text ? 'Yes' : 'No'}</div>
        },
      },
      {
        title: 'Complaint Text',
        dataIndex: 'description',
        key: 'description',
        render: (text: any) => {
          return <div style={{ minWidth: 250 }}>{text}</div>
        }
      },
      {
        title: 'Complaint About',
        dataIndex: 'complaintAbout',
        key: 'complaintAbout',
      },
      {
        title: 'Desired Outcome',
        dataIndex: 'desiredOutcome',
        key: 'desiredOutcome',
        render: (text: any) => {
          return <div style={{ minWidth: 250 }}>{text}</div>
        }
      },
    ],
    searchedValue: '',
  }

  render() {
    const { getComplaintAction, complaintTotalRow, complaint } = this.props
    const { columns, searchedValue } = this.state
    return (
      <Card title="Manage Complaint" extra={<TitleSearch onSearch={this.onSearch} />}>
        <div className='no-white-space'>
          <ExpandTable
            columns={columns}
            tableData={complaint}
            expandHtml={false}
            isExpand={false}
            page="boat"
            totalUsersRow={complaintTotalRow}
            pageAction={getComplaintAction}
            extraInput={{ page_type: '0', searchTerm: searchedValue }}
            rowKey={(record: tableRecords) => record?.id}
          />
        </div>
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    complaint: state.feedbackReducer.complaint,
    complaintTotalRow: state.feedbackReducer.complaintTotalRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getComplaintAction: (data: any) => dispatch(getComplaintAction(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Complaint)
