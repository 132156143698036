import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { clearRentPaymentsReceiverBoatsFlag, getRentPaymentsReceiverBoats } from '../../store/pendingAds/Action'
import ExpandTable from '../../component/ExpandTable'
import { Button, Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import { formatPriceinUSD, getSearchedData, Sort } from '../../helpers/helper'

const RentPaymentsReceiverBoats = ({ history, location: { state: receiver }, getBoats, boats, clearBoatsFlag }: any) => {
  const [tableData, setTableData] = useState([] as any[])
  const [pagination, setPagination] = useState({ page: 1, limit: 10 })
  const [searchedValue, setSearchedValue] = useState('')

  useEffect(() => {
    getBoats({ receiverId: receiver.id })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (boats.success) setTableData(boats.data)
    if (boats.success || boats.failure) clearBoatsFlag()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boats])

  const getPaginated = (data: any[]) => {
    return data.slice((pagination.page - 1) * pagination.limit, pagination.page * pagination.limit)
  }

  const viewSlots = (id: string, data: any) => {
    console.log(data)
    history.push({
      pathname: /private/i.test(data.trip)
        ? '/view-rent-payments-private-boat-slots/' + id
        : '/view-rent-payments-boat-slots/' + id,
      state: { receiver, boat: data },
    })
  }

  const tableCols = () => [
    {
      title: 'Ad Id',
      dataIndex: 'adId',
      key: 'adId',
      render: (v: string) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.adId, b.adId),
    },
    {
      title: 'Trip',
      dataIndex: 'trip',
      key: 'trip',
      render: (v: string) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.trip, b.trip),
    },
    {
      title: 'Trip Type',
      dataIndex: 'tripType',
      key: 'tripType',
      render: (v: string) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.tripType, b.tripType),
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      render: (v: string) => v || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.model, b.model),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.totalAmount, b.totalAmount),
    },
    {
      title: 'Service Fees',
      dataIndex: 'serviceFees',
      key: 'serviceFees',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.serviceFees, b.serviceFees),
    },
    {
      title: 'Stripe Fees',
      dataIndex: 'stripeFees',
      key: 'stripeFees',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.stripeFees, b.stripeFees),
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (v: number) => formatPriceinUSD(v),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.commission, b.commission),
    },
    {
      title: `Amount to Release`,
      dataIndex: 'amountToRelease',
      key: 'amountToRelease',
      render: (v: number) => formatPriceinUSD(v),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.amountToRelease, b.amountToRelease),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (v: string, row: any) => (
        <Button className={row.amountToRelease ? 'btn-danger' : 'btn-view'} onClick={() => viewSlots(v, row)}>
          View Slots
        </Button>
      ),
      sorter: (a: any, b: any) => Sort.Boolean(a.amountToRelease, b.amountToRelease),
    },
  ]

  return (
    <>
      <Card title="Rent & Charter Details" extra={<Button onClick={() => history.goBack()}>Back</Button>} className="mb-10">
        {receiver && (
          <>
            <h3>
              <strong>Rent & Charter Name -</strong> {receiver.name}
            </h3>
            <h3>
              <strong>Company Name -</strong> {receiver.companyName}
            </h3>
            <h3>
              <strong>Stripe Account Id -</strong> {receiver.stripeAccountId || 'N/A'}
            </h3>
            {boats.loading || (
              <h3>
                <strong>Total no. of Boats -</strong> {boats.data.length || 0}
              </h3>
            )}
          </>
        )}
      </Card>

      <Card title="View Boats" extra={<TitleSearch onSearch={setSearchedValue} />}>
        {(() => {
          const data = getSearchedData(tableData, searchedValue)

          return (
            <ExpandTable
              columns={tableCols()}
              tableData={getPaginated(data)}
              expandHtml={false}
              isExpand={false}
              totalUsersRow={data.length}
              tableLoading={boats.loading}
              pageAction={setPagination}
            />
          )
        })()}
      </Card>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    salesEnginCommision: state.salesEngineReducer.salesEnginCommision,
    boats: state.pendingAdsState.rentPaymentsReceiverBoats,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getBoats: (data: any) => dispatch(getRentPaymentsReceiverBoats(data)),
  clearBoatsFlag: () => dispatch(clearRentPaymentsReceiverBoatsFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentPaymentsReceiverBoats)
