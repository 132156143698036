import {
  adminUsersState,
  ActionType,
  GET_ADMIN_USERS_COUNT,
  GET_ADMIN_USERS_COUNT_SUCCESS,
  GET_ADMIN_USERS_COUNT_FAILURE,
  CLEAR_ADMIN_USERS_COUNT_FLAG,
} from './Type'
import { adminUserRole, getRoleCountPath } from '../../../helpers/helper'

const initialState: adminUsersState = {
  adminUsers: [],
  adminCountSuccess: false,
  adminCountFailure: false,
}

export const adminUserCountReducer = (state = initialState, action: ActionType): adminUsersState => {
  switch (action.type) {
    case GET_ADMIN_USERS_COUNT:
      return {
        ...state,
        adminCountSuccess: false,
        adminCountFailure: false,
      }

    case GET_ADMIN_USERS_COUNT_SUCCESS:
      return {
        ...state,
        adminCountSuccess: true,
        adminCountFailure: false,
        adminUsers: getRoleCountPath(action.payload.getAdminUsersCount.roleCounts, adminUserRole),
      }

    case GET_ADMIN_USERS_COUNT_FAILURE:
      return {
        ...state,
        adminCountSuccess: false,
        adminCountFailure: true,
      }

    case CLEAR_ADMIN_USERS_COUNT_FLAG:
      return {
        ...state,
        adminCountSuccess: false,
        adminCountFailure: false,
      }

    default:
      return state
  }
}
