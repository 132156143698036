import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { client } from '../..'
import {
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  GET_ROLES,
  ActionType,
  GET_USERS_COUNT_ROLE_WISE_SUCCESS,
  GET_USERS_COUNT_ROLE_WISE_FAILURE,
  GET_USERS_COUNT_ROLE_WISE,
} from './Types'
import { getAllRoles, getUsersCountRoleWise } from './RoleSchema'

function getAllRoleCall({ payload }: any) {
  return client
    .query({
      query: getAllRoles,
      fetchPolicy: 'no-cache',
    })
}

function* getAllRoleData({ payload }: AnyAction) {
  try {
    const data = yield getAllRoleCall({ payload })
    yield put({ type: GET_ROLES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: GET_ROLES_FAILURE, error })
  }
}

function* getAllRoleSaga() {
  yield takeEvery(GET_ROLES, getAllRoleData)
}

function getUsersCountRoleWiseApi(data: any) {
  return client
    .query({
      query: getUsersCountRoleWise,
      variables: {
        id: data.payload,
      },
      fetchPolicy: 'no-cache',
    })
}

function* getUsersCountRoleWiseData(action: ActionType) {
  try {
    const res = yield getUsersCountRoleWiseApi(action)
    yield put({ type: GET_USERS_COUNT_ROLE_WISE_SUCCESS, payload: res.data })
  } catch (e) {
    yield put({ type: GET_USERS_COUNT_ROLE_WISE_FAILURE, e })
  }
}

function* getUsersCountRoleWiseSaga() {
  yield takeEvery(GET_USERS_COUNT_ROLE_WISE, getUsersCountRoleWiseData)
}

export default function* roleSaga() {
  yield all([fork(getAllRoleSaga), fork(getUsersCountRoleWiseSaga)])
}
