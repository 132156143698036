import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import {
  ActionType,
  GET_ALL_TRIP_TYPES,
  GET_ALL_TRIP_TYPES_SUCCESS,
  GET_ALL_TRIP_TYPES_FAILURE,
  GET_ALL_RENT_TYPES,
  GET_ALL_RENT_TYPES_SUCCESS,
  GET_ALL_RENT_TYPES_FAILURE,
  ADD_RENT_TYPE,
  ADD_RENT_TYPE_SUCCESS,
  ADD_RENT_TYPE_FAILURE,
  GET_SINGLE_RENT_TYPE,
  GET_SINGLE_RENT_TYPE_SUCCESS,
  GET_SINGLE_RENT_TYPE_FAILURE,
  UPDATE_RENT_TYPE,
  UPDATE_RENT_TYPE_SUCCESS,
  UPDATE_RENT_TYPE_FAILURE,
  DELETE_RENT_TYPE,
  DELETE_RENT_TYPE_SUCCESS,
  DELETE_RENT_TYPE_FAILURE,
  rentTypeInput,
  paginationType,
} from './Types'
import { getAllRentTypes, createRentType, getSingleRentType, updateRentType, deleteRentType, getTypeWiseLookup } from './Schema'

function getTripTypesApi(input: string) {
  return client
    .query({
      query: getTypeWiseLookup,
      variables: { type: input },
      fetchPolicy: 'no-cache',
    })
}

function getRentTypesApi(input: paginationType) {
  return client
    .query({
      query: getAllRentTypes,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function addRentTypeApi(input: rentTypeInput) {
  return client
    .mutate({
      mutation: createRentType,
      variables: { input },
    })
}

function getRentTypeByIdApi(input: string) {
  return client
    .query({
      query: getSingleRentType,
      variables: { id: input },
      fetchPolicy: 'no-cache',
    })
}

function updatesRentTypeApi(input: rentTypeInput) {
  return client
    .mutate({
      mutation: updateRentType,
      variables: { input },
    })
}

function deletesRentTypeApi(input: string) {
  return client
    .mutate({
      mutation: deleteRentType,
      variables: { id: input },
    })
}

function* getTripTypes(action: ActionType) {
  try {
    const res = yield getTripTypesApi(action.payload)
    yield put({
      type: GET_ALL_TRIP_TYPES_SUCCESS,
      payload: res.data.getTypeWiseLookup,
    })
  } catch (error) {
    yield put({ type: GET_ALL_TRIP_TYPES_FAILURE, error })
  }
}

function* getRentTypes(action: ActionType) {
  try {
    const res = yield getRentTypesApi({ ...action.payload, isAdmin: true })
    yield put({
      type: GET_ALL_RENT_TYPES_SUCCESS,
      payload: res.data.getAllRentTypes,
    })
  } catch (error) {
    yield put({ type: GET_ALL_RENT_TYPES_FAILURE, error })
  }
}

function* addRentType(action: ActionType) {
  try {
    const res = yield addRentTypeApi(action.payload)
    yield put({
      type: ADD_RENT_TYPE_SUCCESS,
      payload: res.data.createRentType,
    })
  } catch (error) {
    yield put({ type: ADD_RENT_TYPE_FAILURE, error })
  }
}

function* getRentTypeById(action: ActionType) {
  try {
    const res = yield getRentTypeByIdApi(action.payload)
    yield put({
      type: GET_SINGLE_RENT_TYPE_SUCCESS,
      payload: res.data.getSingleRentType,
    })
  } catch (error) {
    yield put({ type: GET_SINGLE_RENT_TYPE_FAILURE, error })
  }
}

function* updatesRentType(action: ActionType) {
  try {
    const res = yield updatesRentTypeApi(action.payload)
    yield put({
      type: UPDATE_RENT_TYPE_SUCCESS,
      payload: res.data.updateRentType,
    })
  } catch (error) {
    yield put({ type: UPDATE_RENT_TYPE_FAILURE, error })
  }
}

function* deletesRentType(action: ActionType) {
  try {
    const res = yield deletesRentTypeApi(action.payload)
    yield put({
      type: DELETE_RENT_TYPE_SUCCESS,
      payload: res.data.deleteRentType,
    })
  } catch (error) {
    yield put({ type: DELETE_RENT_TYPE_FAILURE, error })
  }
}

function* getTripTypesSaga() {
  yield takeLatest(GET_ALL_TRIP_TYPES, getTripTypes)
}

function* getRentTypesSaga() {
  yield takeLatest(GET_ALL_RENT_TYPES, getRentTypes)
}

function* addRentTypeSaga() {
  yield takeLatest(ADD_RENT_TYPE, addRentType)
}

function* getRentTypeByIdSaga() {
  yield takeLatest(GET_SINGLE_RENT_TYPE, getRentTypeById)
}

function* updatesRentTypeSaga() {
  yield takeLatest(UPDATE_RENT_TYPE, updatesRentType)
}

function* deletesRentTypeSaga() {
  yield takeLatest(DELETE_RENT_TYPE, deletesRentType)
}

export default function* rentTypeSaga() {
  yield all([
    getTripTypesSaga(),
    getRentTypesSaga(),
    addRentTypeSaga(),
    getRentTypeByIdSaga(),
    updatesRentTypeSaga(),
    deletesRentTypeSaga(),
  ])
}
