import React, { Component } from 'react'
// import Select from 'react-select';
import { objectToSingleDataArray } from '../helpers/helper'
import { Select } from 'antd'
const { Option } = Select

interface Props {
  value?: any
  onChangeText?: any
  listOptions?: any
  defaultValue?: any
  arrayList?: any
  passed?: any
  isMulti?: any
  closeMenuOnSelect?: any
  fieldIS?: any
  mode?: any
  name?: string
  addAny?:boolean
}

interface State {}

class WSSelect extends Component<Props, State> {
  render() {
    const { onChangeText, listOptions, arrayList, passed, fieldIS, addAny } = this.props
    const options = fieldIS === 'country' ? arrayList : objectToSingleDataArray(arrayList, listOptions, passed)
    const renderOptions = addAny ? [ {value:"",label:"Any"}, ...options] : options
   
    return (
      <Select
        showSearch
        {...this.props}
        style={{ width: '100%' }}
        placeholder="Select a option"
        optionFilterProp="children"
        onChange={onChangeText}
      >
        {renderOptions && renderOptions.length ? (
          renderOptions.map((item: any) => (
            <Option key={item.label} value={item.value}>
              {item.label}
            </Option>
          ))
        ) : (
          <></>
        )}
      </Select>
    )
  }
}
export default WSSelect
