import {
  salesEngineState,
  GET_SALES_ENGINE,
  GET_SALES_ENGINE_SUCCESS,
  DELETE_SALES_ENGINE_SUCCESS,
  ActionType,
  DELETE_SALES_ENGINE_FAILURE,
  CLEAR_SALES_ENGINE_FLAG,
  GET_SALES_ENGINE_FAILURE,
  GET_SALES_ENGINE_STATIC_CONTENT,
  GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS,
  GET_SALES_ENGINE_STATIC_CONTENT_FAILURE,
  DELETE_SALES_ENGINE,
  CREATE_SALES_ENGINE_STATIC_CONTENT_SUCCESS,
  CREATE_SALES_ENGINE_STATIC_CONTENT_FAILURE,
  CREATE_SALES_ENGINE_STATIC_CONTENT,
  GET_SALES_ENGINE_PAYMENT,
  GET_SALES_ENGINE_PAYMENT_SUCCESS,
  GET_SALES_ENGINE_PAYMENT_FAILURE,
  GET_SALES_ENGINE_COMMISION,
  GET_SALES_ENGINE_COMMISION_SUCCESS,
  GET_SALES_ENGINE_COMMISION_FAILURE,
  EDIT_COMMISION,
  EDIT_COMMISION_SUCCESS,
  EDIT_COMMISION_ERROR,
  GET_SALES_ENGINE_RELEASE,
  GET_SALES_ENGINE_RELEASE_SUCCESS,
  GET_SALES_ENGINE_RELEASE_FAILURE,
  RELEASE_PAYMENT,
  RELEASE_PAYMENT_SUCCESS,
  RELEASE_PAYMENT_FAILURE,
  REFUND_PAYMENT_SUCCESS,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'
import { getUniqueArray } from '../../../helpers/helper'

const initialState: salesEngineState = {
  salesEngine: [],
  salesEnginSuccess: false,
  salesEngineFailure: false,
  deleteSalesEngineSuccess: false,
  deleteSalesEngineFailure: false,
  salesEnginStaticSuccess: false,
  salesEngineStaticFailure: false,
  salesEngineStaticContent: [],
  createdSalesEngine: [],
  createSalesEngineStaticContentSuccess: false,
  createSalesEngineStaticContentFailure: false,
  totalSalesEngineRow: 0,
  salesEnginPayment: [],
  salesEnginPaymentTotalRow: 0,
  salesEnginCommision: [],
  salesEnginCommisionTotalRow: 0,
  salesEnginRelease: [],
  editSuccess: false,
  editError: false,
  isReleaseMoneySuccess: false,
  isReleaseMoneyFailure: false,
  releasePaymentErrorMsg: ''
}

export const salesEngineReducer = (state = initialState, action: ActionType): salesEngineState => {
  const { salesEngine, salesEnginRelease } = state
  switch (action.type) {
    case GET_SALES_ENGINE:
      return {
        ...state,
        salesEnginSuccess: false,
        salesEngineFailure: false,
      }

    case GET_SALES_ENGINE_SUCCESS:
      return {
        ...state,
        salesEngine: action.payload.items,
        totalSalesEngineRow: action.payload.total,
        salesEnginSuccess: true,
        salesEngineFailure: false,
      }

    case GET_SALES_ENGINE_FAILURE:
      return {
        ...state,
        salesEnginSuccess: false,
        salesEngineFailure: true,
      }

    // GET SALES ENGIN PAYMENT
    case GET_SALES_ENGINE_PAYMENT:
      return {
        ...state,
      }

    case GET_SALES_ENGINE_PAYMENT_SUCCESS:
      return {
        ...state,
        salesEnginPayment: action.payload.items,
        salesEnginPaymentTotalRow: action.payload.total,
      }

    case GET_SALES_ENGINE_PAYMENT_FAILURE:
      return {
        ...state,
        salesEnginPayment: [],
      }

    // GET SALES ENGIN COMMISION
    case GET_SALES_ENGINE_COMMISION:
      return {
        ...state,
      }

    case GET_SALES_ENGINE_COMMISION_SUCCESS:
      return {
        ...state,
        salesEnginCommision: action.payload.items,
        salesEnginCommisionTotalRow: action.payload.total,
      }

    case GET_SALES_ENGINE_COMMISION_FAILURE:
      return {
        ...state,
        salesEnginCommision: [],
      }

    // GET SALES ENGIN RELEASE
    case GET_SALES_ENGINE_RELEASE:
      return {
        ...state,
        isReleaseMoneySuccess: false,
        isReleaseMoneyFailure: false,
        releasePaymentErrorMsg: ''
      }

    case GET_SALES_ENGINE_RELEASE_SUCCESS:
      return {
        ...state,
        salesEnginRelease: action.payload.items,
      }

    case GET_SALES_ENGINE_RELEASE_FAILURE:
      return {
        ...state,
        salesEnginRelease: [],
      }

    case REFUND_PAYMENT_SUCCESS:
      const releaseData = salesEnginRelease.map((item) => {
        if (item.paymentId === action.input.id) {
          return { ...item, isRefunded: true, isRefundable: false }
        }
        return item;
      })

      return {
        ...state,
        salesEnginRelease: releaseData
      }

    case DELETE_SALES_ENGINE:
      return {
        ...state,
        deleteSalesEngineSuccess: false,
        deleteSalesEngineFailure: false,
      }

    case DELETE_SALES_ENGINE_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        salesEngine: salesEngine.filter(item => item.id !== action.payload.id),
        deleteSalesEngineSuccess: true,
        deleteSalesEngineFailure: false,
      }

    case DELETE_SALES_ENGINE_FAILURE:
      return {
        ...state,
        deleteSalesEngineSuccess: false,
        deleteSalesEngineFailure: true,
      }

    case CLEAR_SALES_ENGINE_FLAG:
      return {
        ...state,
        salesEnginSuccess: false,
        salesEngineFailure: false,
        deleteSalesEngineSuccess: false,
        deleteSalesEngineFailure: false,
        salesEnginStaticSuccess: false,
      }

    case GET_SALES_ENGINE_STATIC_CONTENT:
      return {
        ...state,
        salesEnginStaticSuccess: false,
        salesEngineStaticFailure: false,
      }

    case GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS:
      return {
        ...state,
        salesEngineStaticContent: action.payload,
        salesEnginStaticSuccess: true,
        salesEngineStaticFailure: false,
      }

    case GET_SALES_ENGINE_STATIC_CONTENT_FAILURE:
      return {
        ...state,
        salesEnginStaticSuccess: false,
        salesEngineStaticFailure: true,
      }

    case CREATE_SALES_ENGINE_STATIC_CONTENT:
      return {
        ...state,
        createSalesEngineStaticContentSuccess: false,
        createSalesEngineStaticContentFailure: false,
      }

    case CREATE_SALES_ENGINE_STATIC_CONTENT_SUCCESS:
      return {
        ...state,
        createdSalesEngine: action.payload,
        createSalesEngineStaticContentSuccess: true,
        createSalesEngineStaticContentFailure: false,
      }

    case CREATE_SALES_ENGINE_STATIC_CONTENT_FAILURE:
      return {
        ...state,
        createSalesEngineStaticContentSuccess: false,
        createSalesEngineStaticContentFailure: true,
      }

    case RELEASE_PAYMENT:
      return {
        ...state,
        isReleaseMoneySuccess: false,
        isReleaseMoneyFailure: false,
        releasePaymentErrorMsg: ''
      }

    case RELEASE_PAYMENT_SUCCESS:
      if (action.payload.statusCode === 401) {
        return {
          ...state,
          isReleaseMoneyFailure: true,
          isReleaseMoneySuccess: false,
          releasePaymentErrorMsg: action.payload.message
        }
      }
      return {
        ...state,
        isReleaseMoneySuccess: true,
        isReleaseMoneyFailure: false,
        releasePaymentErrorMsg: ''
      }

    case RELEASE_PAYMENT_FAILURE:
      return {
        ...state,
        isReleaseMoneyFailure: true,
        isReleaseMoneySuccess: false,
        releasePaymentErrorMsg: 'Something went wrong.'
      }

    case EDIT_COMMISION:
      return {
        ...state,
        editSuccess: false,
        editError: false,
      }
    case EDIT_COMMISION_SUCCESS:
      NotificationWithIcon('success', 'Update Successfully')
      return {
        ...state,
        editSuccess: true,
        editError: false,
        salesEnginCommision: getUniqueArray([action.payload, ...state.salesEnginCommision], (it: any) => it.role),
      }
    case EDIT_COMMISION_ERROR:
      return {
        ...state,
        editSuccess: true,
        editError: false,
      }

    default:
      return state
  }
}
