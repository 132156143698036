import { ActionType, resetPasswordTypeState, RESET_PASSWORD, RESET_PASSWORD_FAILURE, RESET_PASSWORD_SUCCESS } from './Types'
import NotificationWithIcon from '../../component/notification/Notification'
import { errorNotification, resetPasswordSuccess, successNotification } from '../../enums/enums'

const initialState: resetPasswordTypeState = {
  success: false,
  error: false,
}

export function resetPasswordReducer(state = initialState, action: ActionType): resetPasswordTypeState {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...state,
        success: false,
        error: false,
      }
    case RESET_PASSWORD_SUCCESS:
      NotificationWithIcon(successNotification, resetPasswordSuccess)
      return {
        ...state,
        success: true,
      }
    case RESET_PASSWORD_FAILURE:
      const errorMessage = action.payload
      NotificationWithIcon(errorNotification, errorMessage)
      return {
        ...state,
        error: true,
      }
    default:
      return state
  }
}
