import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card } from 'antd'

import ExpandTable from '../../component/ExpandTable'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData } from '../../helpers/helper'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { clearFormsFlagAction } from '../../store/form/Actions'
import NotificationWithIcon from '../../component/notification/Notification'
import { paginationPage } from '../../enums/enums'
import { deletePartnerRelation } from '../../store/form/UserSchema'
export interface Props {
  deleteAction?: any
  clearFormsFlagAction: typeof clearFormsFlagAction
  tableList?: any
  totalUsersRow?: any
  getListAction?: any
  renderColumn?: any
  tableTitle: any
  formModule?: string
  contactDeleteSuccess: boolean
}

export interface State {
  searchedUsers: string
}

export interface tableRecords {
  id?: any
  lastName?: string
}

class FormTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchedUsers: '',
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = props => {
    const { clearFormsFlagAction, contactDeleteSuccess } = props
    if (contactDeleteSuccess) {
      NotificationWithIcon('success', 'Successfully Deleted')
      clearFormsFlagAction()
    }
    return null
  }

  columns = [
    ...this.props.renderColumn,
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      render: (text: string, record: tableRecords) => (
        <Button
          className="btn-delete"
          onClick={() => {
            const { deleteAction } = this.props

            record &&
              record.id &&
              confirmSubmitHandler(deleteAction, {
                input: { ids: [record.id], formModule: this.props.formModule },
              })
          }}
        >
          Delete
        </Button>
      ),
    },
  ]

  onSearch = (value: any) => {
    this.setState({ searchedUsers: value })
  }

  render() {
    const { tableList, totalUsersRow, getListAction, tableTitle } = this.props
    const { searchedUsers } = this.state

    return (
      <Card title={tableTitle} extra={<TitleSearch onSearch={this.onSearch} />}>
        <ExpandTable
          columns={this.columns}
          tableData={getSearchedData(tableList, searchedUsers)}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={totalUsersRow}
          pageAction={getListAction}
          page={paginationPage.boat}
        />
      </Card>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    contactDeleteSuccess: state.feedbackReducer.contactDeleteSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearFormsFlagAction: () => dispatch(clearFormsFlagAction()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormTable)
