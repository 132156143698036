import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { Card, Row, Col, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { getManageRentCount } from '../../../store/dashboard/manageRent/Action'
import ManageRentSkeleton from './components/ManageRentSkeleton'
const { Title } = Typography

export interface Props {
  getManageRentCount: typeof getManageRentCount
  manageRentCountSuccess: boolean
  manageRentCount: any
}
export interface State {}

class ManageRent extends Component<Props, State> {
  state = {}

  componentDidMount() {
    console.log('component mounted')
    const { getManageRentCount } = this.props
    getManageRentCount()
  }

  render() {
    const { manageRentCount } = this.props
    const totalBoats = manageRentCount?.getManageRentDashboardCount?.totalBoats
    const bookingAndRent = manageRentCount?.getManageRentDashboardCount?.availableRentBooking
    const purchasedTrips = manageRentCount?.getManageRentDashboardCount?.purchasedTrips
    const completedTrips = manageRentCount?.getManageRentDashboardCount?.completedTrips
    const expiredTrips = manageRentCount?.getManageRentDashboardCount?.expiredTrips

    return (
      <>

        <div style={{ background: '#ECECEC', padding: '30px' }}>
            <Title level={3}>Boats</Title>
          <Row gutter={16}>
            {
              totalBoats?.length ? totalBoats?.map(({ count, _id }: { count: number; _id: string }) => (
                <Col span={7}>
                  <Card title={_id?.replace('Trip', '').toUpperCase()} bordered={false}>
                    <div className="d-flex justify-content-between">
                      <Col span={8}>
                        <img
                          src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                          height="50px"
                          width="50px"
                        />
                      </Col>
                      <Col span={8}>
                        <Title style={{margin:0}} level={2} >
                        {count}
                        </Title>
                      </Col>
                    </div>
                  </Card>
              </Col>
              ))
              : <ManageRentSkeleton/>

            }

          </Row>
        </div>

        <div style={{ background: '#ECECEC', padding: '30px' }}>
            <Title level={3}>Booking and rent (Available for rent)</Title>
          <Row gutter={16}>
            {
              bookingAndRent?.length ? bookingAndRent?.map(({ count, _id, totalSharedTickets, totalRphRides }: { count: number; _id: string, totalSharedTickets:any, totalRphRides:any }) =>(
                <Col span={7}>
                  <Card title={_id?.replace('Trip', '').toUpperCase()} bordered={false}>
                    <div className="d-flex justify-content-between">
                      <Col span={8}>
                        <img
                          src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                          height="50px"
                          width="50px"
                        />
                      </Col>
                      <Col span={8}>
                      <Title level={4} type="secondary">
                          Trips
                        </Title>
                        <Title style={{margin:0}} level={2} >
                        {count}
                        </Title>
                      </Col>
                      {_id !== 'Private Trip' 
                      &&  <Col span={8}>
                        <Title level={4} type="secondary">
                        {_id == 'Shared Trip' ? 'Tickets' : 'Rides'} 
                        </Title>
                      <Title style={{margin:0}} level={2} >
                      {_id == 'Shared Trip' ? totalSharedTickets : totalRphRides}
                      </Title>
                    </Col>}

                    </div>
                  </Card>
              </Col>
              ))
              : <ManageRentSkeleton/>
            }

          </Row>
        </div>
        <div style={{ background: '#ECECEC', padding: '30px' }}>
            <Title level={3}>Purchased Trips</Title>
            <Row gutter={16}>
            {
              purchasedTrips?.length ? purchasedTrips?.map(({ count, _id }: { count: number; _id: string }) =>(
                <Col span={7}>
                  <Card  title={_id?.replace('Trip', '').toUpperCase()} bordered={false}>
                    <div className="d-flex justify-content-between">
                      <Col span={8}>
                        <img
                          src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                          height="50px"
                          width="50px"
                        />
                      </Col>
                      <Col span={8}>
                      <Title level={4} type="secondary">
                          Trips
                        </Title>
                        <Title style={{margin:0}} level={2} >
                        {count}
                        </Title>
                      </Col>
                    </div>
                  </Card>
              </Col>
              ))
              : <ManageRentSkeleton/>
            }

          </Row>
        </div>

        <div style={{ background: '#ECECEC', padding: '30px' }}>
            <Title level={3}>Completed Trips(Scanned Tickets)</Title>
            <Row gutter={16}>
            {
              completedTrips?.length ? completedTrips?.map(({ count, _id }: { count: number; _id: string }) =>(
                <Col span={7}>
                  <Card title={_id?.replace('Trip', '').toUpperCase()} bordered={false}>
                    <div className="d-flex justify-content-between">
                      <Col span={8}>
                        <img
                          src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                          height="50px"
                          width="50px"
                        />
                      </Col>
                      <Col span={8}>
                      <Title level={4} type="secondary">
                          Trips
                        </Title>
                        <Title style={{margin:0}} level={2} >
                        {count}
                        </Title>
                      </Col>
                    </div>
                  </Card>
              </Col>
              ))
              : <ManageRentSkeleton/>
            }

          </Row>
        </div>

        <div style={{ background: '#ECECEC', padding: '30px' }}>
            <Title level={3}>Expired Trips(Purchased, but not been Scanned) </Title>
            <Row gutter={16}>
            {
              expiredTrips?.length ? expiredTrips?.map(({ count, _id }: { count: number; _id: string }) =>(
                <Col span={7}>
                  <Card title={_id?.replace('Trip', '').toUpperCase()} bordered={false}>
                    <div className="d-flex justify-content-between">
                      <Col span={8}>
                        <img
                          src={require('../../../assets/image/manageAuctionRoom/auction-room.png')}
                          height="50px"
                          width="50px"
                        />
                      </Col>
                      <Col span={8}>
                      <Title level={4} type="secondary">
                          Trips
                        </Title>
                        <Title style={{margin:0}} level={2} >
                        {count}
                        </Title>
                      </Col>
                    </div>
                  </Card>
              </Col>
              ))
              : <ManageRentSkeleton/>
            }

          </Row>
        </div>
        <br />
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  console.log(state,'state')
  return{
  manageRentCount: state.manageRentCountReducer && state.manageRentCountReducer.manageRentCount,
  manageRentCountSuccess: state.manageRentCountReducer && state.manageRentCountReducer.manageRentCountSuccess,
}}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
  getManageRentCount: () => dispatch(getManageRentCount()),
}}

export default connect(mapStateToProps, mapDispatchToProps)(ManageRent)
