import React, { Component } from 'react'
import { VerticalGraph } from '../../../component/charts/VerticalGraph'

import { Card, Row, Col, Typography, Avatar } from 'antd'
import { CircularGraph } from '../../../component/charts/CircularGraph'
const { Title } = Typography

export interface Props {}
export interface State {}

export default class ManageDashboardAdvertisements extends Component<Props, State> {
  state = {}

  render() {
    return (
      <>
        <div style={{ background: '#ECECEC', padding: '30px' }}>
          <Row gutter={16}>
            <Col span={6}>
              <Card title="ADVERTISEMENTS (Added by Admin)" bordered={false}>
                <div>
                  <Col span={8}>
                    <img
                      src={require('../../../assets/image/manageAdvertisements/Ad_Admin_Big@3x.png')}
                      height="50px"
                      width="50px"
                    />
                  </Col>
                  <Col span={8}>
                    <Title level={2} type="secondary">
                      14
                    </Title>
                  </Col>
                </div>
              </Card>
            </Col>
            <Col span={6}>
              <Card title="ADVERTISE REQUESTS" bordered={false}>
                <div>
                  <Col span={8}>
                    <img
                      src={require('../../../assets/image/manageAdvertisements/Ad_Req_Big@3x.png')}
                      height="50px"
                      width="50px"
                    />
                  </Col>
                  <Col span={8}>
                    <Title level={2} type="secondary">
                      14
                    </Title>
                  </Col>
                </div>
              </Card>
            </Col>
          </Row>
        </div>

        <br />

        <Row gutter={16} className="mb-20">
          <Col span={12}>
            <CircularGraph
              chartTitle={'ADVERTISEMENTS'}
              labels={['ADVERTISEMENTS BY ADMIN', 'ADVERTISE REQUESTS', 'ADVERTISE NEW REQUESTS']}
              data={[40, 20, 40]}
            />
          </Col>
        </Row>
      </>
    )
  }
}
