import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card } from 'antd'

import ExpandTable from './ExpandTable'
import { confirmSubmitHandler } from './ConfirmSubmitHandler'
import { TitleSearch } from './SearchBar'
import {
  getSearchedData,
  viewServiceBoatHandler,
  viewMarinaDetails,
  viewRentBoatHandler,
  displayDefaultValue,
} from '../helpers/helper'

import { paginationPage, getAllBoatPagination, activeInactive, nullableFilters } from '../enums/enums'
import FilterButton from '../container/filters/FilterButton'
import { connect } from 'react-redux'

export interface Props {
  deleteAction?: any
  tableList?: any[]
  totalUsersRow?: number
  getListAction?: any
  renderColumn?: any
  tableTitle: string
  deleteSuccess?: boolean
  clearAction?: any
  view?: string
  isLoading?: boolean
  searchAction?: any
  isQuery?: boolean
}

export interface State {
  searchedUsers: string
}

export interface tableRecords {
  lastName: any
  id?: any
  adStatus: boolean
}

let pageFilterArray: any = []

class ManageBoatTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchedUsers: '',
    }
  }

  componentDidMount() {
    const { view } = this.props

    if (view === 'marina') {
      pageFilterArray = [
        {
          title: 'Status',
          filedArray: activeInactive,
          listOptions: 'label',
          passed: 'value',
          actionField: 'status',
        },
        {
          title: 'More Services',
          filedArray: nullableFilters,
          listOptions: 'label',
          passed: 'value',
          actionField: 'moreServices',
        },
        {
          title: 'Nearest Marina',
          filedArray: nullableFilters,
          listOptions: 'label',
          passed: 'value',
          actionField: 'nearest',
        },
        {
          title: 'Live Experience Marina',
          filedArray: nullableFilters,
          listOptions: 'label',
          passed: 'value',
          actionField: 'liveExperience',
        },
      ]
    } else if (view === 'service') {
      pageFilterArray = [
        {
          title: 'Status',
          filedArray: activeInactive,
          listOptions: 'label',
          passed: 'value',
          actionField: 'status',
        },
        {
          title: 'Nearest Boat Service',
          filedArray: nullableFilters,
          listOptions: 'label',
          passed: 'value',
          actionField: 'nearest',
        },
        {
          title: 'Most Popular Page',
          filedArray: nullableFilters,
          listOptions: 'label',
          passed: 'value',
          actionField: 'popular',
        },
      ]
    } else if (view === 'rent') {
      pageFilterArray = [
        {
          title: 'Status',
          filedArray: activeInactive,
          listOptions: 'label',
          passed: 'value',
          actionField: 'status',
        },
        {
          title: 'Feature Status',
          filedArray: nullableFilters,
          listOptions: 'label',
          passed: 'value',
          actionField: 'featureStatus',
        },
        {
          title: 'Best Deal Status',
          filedArray: nullableFilters,
          listOptions: 'label',
          passed: 'value',
          actionField: 'bestDealStatus',
        },
        {
          title: 'Must Buy Status',
          filedArray: nullableFilters,
          listOptions: 'label',
          passed: 'value',
          actionField: 'mustBuyStatus',
        },
      ]
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = props => {
    const { deleteSuccess, getListAction, clearAction } = props

    if (deleteSuccess) {
      getListAction({ ...getAllBoatPagination, adStatus: true })
      clearAction()
    }
    return null
  }

  redirectToViewPage = (record: any) => {
    const { view } = this.props
    if (view === 'marina') {
      viewMarinaDetails(record)
    } else if (view === 'service') {
      viewServiceBoatHandler(record)
    } else if (view === 'rent') {
      viewRentBoatHandler(record)
    }
  }

  columns = [
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'address',
      render: (text: any) => {
        return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
      },
    },
    ...this.props.renderColumn,
    {
      title: 'View',
      dataIndex: 'View',
      key: 'view',
      render: (text: string, record: tableRecords, index: number) => (
        <Button className="btn-view" onClick={() => this.redirectToViewPage(record)}>
          View
        </Button>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      render: (text: string, record: tableRecords) => (
        <Button
          className="btn-delete"
          onClick={() => {
            const { deleteAction } = this.props
            console.log(deleteAction, 'deleteBoatTypes')

            record && record.id && confirmSubmitHandler(deleteAction, record.id)
          }}
        >
          Delete
        </Button>
      ),
    },
  ]

  onSearch = (value: any) => {
    const { searchAction, getListAction, isQuery } = this.props
    if (value) {
      const searchObject = isQuery ? { query: value,  } : {searchTerm: value,}
      this.setState({searchedUsers: value})
      searchAction({ ...searchObject, page: 1, limit: 10 })
    } else {
      this.setState({searchedUsers: ''})
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getListAction(input)
    }
  }

  render() {
    const { tableList, totalUsersRow, getListAction, tableTitle, isLoading, searchAction } = this.props
    const { searchedUsers } = this.state

    return (
      <Card title={tableTitle} extra={<TitleSearch onSearch={this.onSearch} />}>
        <FilterButton filterArray={pageFilterArray} haveCountry triggerAction={getListAction} />
        <ExpandTable
          columns={this.columns}
          tableData={tableList}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={totalUsersRow}
          pageAction={searchedUsers ? searchAction : getListAction}
          extraInput={{ adStatus: true, searchTerm: searchedUsers }}
          page={paginationPage.boat}
          tableLoading={isLoading}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.pendingAdsState.isLoading,
  }
}

export default connect(mapStateToProps, null)(ManageBoatTable)
