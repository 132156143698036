import {
  GET_ALL_SURVEYORS,
  DELETE_SURVEYORS,
  CLEAR_BRANCHES_FLAG,
  CHANGE_SURVEYOR_STATUS,
  GET_ALL_SURVEYOR_BRANCHES,
} from './Type'

export function getManageAllSurveyors(data: any) {
  return {
    type: GET_ALL_SURVEYORS,
    payload: data,
  }
}

export function deleteSurveyors(data: string) {
  return {
    type: DELETE_SURVEYORS,
    payload: data,
  }
}

export function clearBranchesFlag() {
  return {
    type: CLEAR_BRANCHES_FLAG,
  }
}

export function changeSurveyorStatus(data: any) {
  return {
    type: CHANGE_SURVEYOR_STATUS,
    payload: data,
  }
}

export function getSurveyorBranches(data: any) {
  return {
    type: GET_ALL_SURVEYOR_BRANCHES,
    payload: data,
  }
}
