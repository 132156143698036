const colors = {
  skyBlue: {
    dark: '#d3eaef',
    light: '#dcedf3',
    lightest: '#f0f8fa',
  },
  transparent: {
    white: 'rgba(255, 255, 255, 0.7)',
  },
  blue: {
    dark: '#2c334a',
    light: '#aec1cc',
    lightest: '#12B3F9',
  },
  grey: {
    dark: '#7c8290',
    light: '#bdbdbd',
    lightest: '#eaeae5',
  },
  cyan: '#008088',
  white: '#FFFFFF',
  black: '#000000',
  red: '#FF0000',
  yellow: '#fec107',
  lightSkyBlue: '#bddee5',
}

const padding = {
  p2: 2,
  xsm: 5,
  sm: 10,
  p15: 15,
  md: 20,
  p25: 25,
  lg: 30,
  xlg: 35,
  xl: 40,
  xxl: 50,
  p55: 55,
  p80: 80,
}

const margin = {
  xsm: 5,
  sm: 10,
  m15: 15,
  md: 20,
  m25: 25,
  lg: 30,
  xlg: 35,
  xl: 40,
  m45: 45,
  xxl: 50,
  m95: 95,
}

const fonts = {
  f6: 6,
  f8: 8,
  f10: 10,
  f12: 12,
  f14: 14,
  f16: 16,
  f18: 18,
  f20: 20,
}

const size = {
  sn40: -40,
  sn35: -35,
  sn5: -5,
  none: 0,
  sp5: 0.5,
  s1: 1,
  s2: 2,
  s3: 3,
  s4: 4,
  xsm: 5,
  sm: 10,
  s15: 15,
  md: 20,
  s25: 25,
  s28: 28,
  lg: 30,
  s35: 35,
  s37: 37,
  s40: 40,
  s50: 50,
  s55: 55,
  xmd: 60,
  s65: 65,
  s70: 70,
  s75: 75,
  s80: 80,
  s100: 100,
  s115: 115,
  s140: 140,
  xl: 120,
  xxl: 150,
  s200: 200,
  s350: 350,
  s400: 400,
}

const flex = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
}

export { flex, size, fonts, margin, padding, colors }
