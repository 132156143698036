import gql from 'graphql-tag'

export const getAllFeedBack = gql`
  query getAllFeedBack(
    $page: Int
    $limit: Int
    $country: String
    $status: Boolean
    $adStatus: Boolean
    $isAdamSeaMember: Boolean
    $searchTerm: String
  ) {
    getAllFeedBack(
      page: $page
      limit: $limit
      country: $country
      status: $status
      adStatus: $adStatus
      isAdamSeaMember: $isAdamSeaMember
      searchTerm: $searchTerm
    ) {
      items {
        id
        isAdamSeaMember
        feedBackAboutSite
        feedBackTopic
        feedSubTopic
        description
        createdAt
        user {
          firstName
          lastName
        }
      }
      total
    }
  }
`

export const getAllContactUs = gql`
  query getAllContactUs($page: Int, $limit: Int) {
    getAllContactUs(page: $page, limit: $limit) {
      items {
        id
        country {
          id
          name
        }
        firstName
        lastName
        email
        createdAt
      }
      total
    }
  }
`

export const getAllNewsMultiMedia = gql`
  query getAllNewsMultiMedia($page: Int, $limit: Int) {
    getAllNewsMultiMedia(page: $page, limit: $limit) {
      items {
        id
        country {
          name
        }
        firstName
        lastName
        email
        mediaWebsite
        createdAt
      }
      total
    }
  }
`

export const getAllPartnerRelation = gql`
  query getAllPartnerRelation($page: Int, $limit: Int) {
    getAllPartnerRelation(page: $page, limit: $limit) {
      items {
        id
        country {
          id
          name
        }
        organizationName
        firstName
        lastName
        email
        createdAt
      }
      total
    }
  }
`

export const getAllApplyJob = gql`
  query getAllApplyJob($page: Int, $limit: Int) {
    getAllApplyJob(page: $page, limit: $limit) {
      items {
        id
        country {
          id
          name
        }

      }
      total
    }
  }
`

export const getAllComplaints = gql`
  query getComplaintsForAdmin($page: Int, $limit: Int, $searchTerm: String) {
    getComplaintsForAdmin(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        country
        fullName
        email
        mobileNumber
        isMarketTransaction
        complaintAbout
        desiredOutcome
        userRole {
          role
        }
        description
      }
      total
    }
  }
`

export const getAllSuggestions = gql`
  query getSuggestionsForAdmin($page: Int, $limit: Int, $searchTerm: String) {
    getSuggestionsForAdmin(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        country
        fullName
        email
        mobileNumber
        topic
        description
      }
      total
    }
  }
`

export const getAllCustomerService = gql`
  query getCustomerServiceForAdmin($page: Int, $limit: Int, $searchTerm: String) {
    getCustomerServiceForAdmin(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        id
        userRole {
          role
        }
        fullName
        description
        email
        mobileNumber
      }
      total
    }
  }
`

export const deleteContactUs = gql`
  mutation deleteContactUs($input: ManageFormInput!) {
    deleteContactUs(input: $input) {
      ids
    }
  }
`

export const deleteNewsMultiMedia = gql`
  mutation deleteNewsMultiMedia($input: ManageFormInput!) {
    deleteNewsMultiMedia(input: $input) {
      ids
    }
  }
`

export const deletePartnerRelation = gql`
  mutation deletePartnerRelation($input: ManageFormInput!) {
    deletePartnerRelation(input: $input) {
      ids
    }
  }
`

export const deleteApplyJob = gql`
  mutation deleteApplyJob($input: ManageFormInput!) {
    deleteApplyJob(input: $input) {
      ids
    }
  }
`

export const deleteComplaint = gql`
  mutation removeFlagreport($id: String) {
    removeFlagreport(id: $id) {
      id
    }
  }
`

export const deleteSuggestion = gql`
  mutation deleteSuggestion($input: ManageFormInput!) {
    deleteSuggestion(input: $input) {
      ids
    }
  }
`

export const deleteCustomerService = gql`
  mutation deleteCustomerService($input: ManageFormInput!) {
    deleteCustomerService(input: $input) {
      ids
    }
  }
`
