import React from 'react'
import { Input } from 'antd'
import { pagination } from '../enums/enums'

const Search = Input.Search

interface Props {
  onSearch?: any
  searchTextRef?: any
  searchMethod?: any
  isUserSearch?: boolean
  roleType?: string
  placeHolder?: string
  className?:string
  allowClear?:boolean
  listMethod?: (details: any) => void
}
export const TitleSearch = (props: Props) => (
  <Search
    className={props.className}
    ref={props.searchTextRef}
    placeholder={props.placeHolder || "Search"}
    onSearch={props.onSearch}
    allowClear={props.allowClear}
    onChange={e => {
      if (props.isUserSearch) {
        if (e.target.value) {
          props.searchMethod({
            searchTerm: e.target.value,
            page: pagination.page,
            limit: pagination.limit,
            roleType: props.roleType,
          })
        } else {
          props.listMethod &&
            props.listMethod({
              page: pagination.page,
              limit: pagination.limit,
              roleType: props.roleType,
            })
        }
      }
    }}
  />
)
