import {
  GET_MEMBER_FEEDBACK,
  GET_MEMBER_FEEDBACK_SUCCESS,
  GET_MEMBER_FEEDBACK_FAILURE,
  feedbackState,
  ActionType,
  GET_MEMBER_CONTACT,
  GET_MEMBER_CONTACT_SUCCESS,
  GET_MEMBER_CONTACT_FAILURE,
  DELETE_MEMBER_CONTACT,
  DELETE_MEMBER_CONTACT_SUCCESS,
  DELETE_MEMBER_CONTACT_FAILURE,
  CLEAR_FORMS_FLAG,
  GET_NEWS_MULTIMEDIA,
  GET_NEWS_MULTIMEDIA_SUCCESS,
  GET_NEWS_MULTIMEDIA_FAILURE,
  GET_PARTNER_RELATION,
  GET_PARTNER_RELATION_SUCCESS,
  GET_PARTNER_RELATION_FAILURE,
  GET_CAREER,
  GET_CAREER_SUCCESS,
  GET_CAREER_FAILURE,
  GET_COMPLAINT,
  GET_COMPLAINT_SUCCESS,
  GET_COMPLAINT_FAILURE,
  GET_SUGGESTIONS,
  GET_SUGGESTIONS_SUCCESS,
  GET_SUGGESTIONS_FAILURE,
  GET_CUSTOMER_SERVICES,
  GET_CUSTOMER_SERVICES_SUCCESS,
  GET_CUSTOMER_SERVICES_FAILURE,
  DELETE_NEWS_MULTI,
  DELETE_NEWS_MULTI_SUCCESS,
  DELETE_NEWS_MULTI_FAILURE,
  DELETE_PARTNER_RELATIONS,
  DELETE_PARTNER_RELATIONS_SUCCESS,
  DELETE_PARTNER_RELATIONS_FAILURE,
  DELETE_CAREER,
  DELETE_CAREER_SUCCESS,
  DELETE_CAREER_FAILURE,
  DELETE_SUGGESTIONS,
  DELETE_SUGGESTIONS_SUCCESS,
  DELETE_SUGGESTIONS_FAILURE,
  DELETE_CUSTOMER_SERVICES,
  DELETE_CUSTOMER_SERVICES_SUCCESS,
  DELETE_CUSTOMER_SERVICES_FAILURE,
} from './Types'
import NotificationWithIcon from '../../component/notification/Notification'

const initialState: feedbackState = {
  feedbackUser: [],
  contactUsers: [],
  contactLoad: false,
  contactSuccess: false,
  contactFail: false,
  feedbackTotalRow: 0,
  contactUsersTotalRow: 0,
  deleteContactLoad: false,
  contactDeleteSuccess: false,
  contactDeleteFail: false,
  newsAndMultimedia: [],
  newsMultimediaLoad: false,
  newsMultimediaSuccess: false,
  newsMultimediaFail: false,
  newsAndMultimediaTotalRow: 0,
  partnerRelation: [],
  partnerRelationTotalRow: 0,
  partnerRelationLoad: false,
  partnerRelationSuccess: false,
  partnerRelationFail: false,
  career: [],
  careerTotalRow: 0,
  careerLoad: false,
  careerSuccess: false,
  careerFail: false,
  complaint: [],
  complaintTotalRow: 0,
  complaintLoad: false,
  complaintSuccess: false,
  complaintFail: false,
  suggestions: [],
  suggestionsTotalRow: 0,
  suggestionsLoad: false,
  suggestionsSuccess: false,
  suggestionsFail: false,
  customerService: [],
  customerServiceTotalRow: 0,
  customerServiceLoad: false,
  customerServiceSuccess: false,
  customerServiceFail: false,
  deleteNewsLoad: false,
  newsDeleteSuccess: false,
  newsDeleteFail: false,
  deleteCareerLoad: false,
  careerDeleteSuccess: false,
  careerDeleteFail: false,

  deleteSuggestLoad: false,
  suggestionsDeleteSuccess: false,
  suggestionsDeleteFail: false,
  deleteCustomerServiceLoad: false,
  customerServicesSuccess: false,
  customerServicesFail: false,
}

export function feedbackReducer(state = initialState, action: ActionType): feedbackState {
  switch (action.type) {
    case GET_MEMBER_FEEDBACK:
      return {
        ...state,
        feedbackUser: [],
      }
    case GET_MEMBER_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackUser: action.payload.items,
        feedbackTotalRow: action.payload.total,
      }
    case GET_MEMBER_FEEDBACK_FAILURE:
      return {
        ...state,
        feedbackUser: [],
      }
    case GET_MEMBER_CONTACT:
      return {
        ...state,
        contactUsers: [],
        contactLoad: true,
        contactSuccess: false,
        contactFail: false,
      }
    case GET_MEMBER_CONTACT_SUCCESS:
      return {
        ...state,
        contactUsers: action.payload.items,
        contactUsersTotalRow: action.payload.total,
        contactLoad: false,
        contactSuccess: true,
        contactFail: false,
      }
    case GET_MEMBER_CONTACT_FAILURE:
      return {
        ...state,
        contactUsers: [],
        contactLoad: false,
        contactSuccess: false,
        contactFail: true,
      }
    case GET_NEWS_MULTIMEDIA:
      return {
        ...state,
        newsAndMultimedia: [],
        newsMultimediaLoad: true,
        newsMultimediaSuccess: false,
        newsMultimediaFail: false,
      }
    case GET_NEWS_MULTIMEDIA_SUCCESS:
      return {
        ...state,
        newsAndMultimedia: action.payload.items,
        newsAndMultimediaTotalRow: action.payload.total,
        newsMultimediaLoad: false,
        newsMultimediaSuccess: true,
        newsMultimediaFail: false,
      }
    case GET_NEWS_MULTIMEDIA_FAILURE:
      return {
        ...state,
        newsAndMultimedia: [],
        newsMultimediaLoad: false,
        newsMultimediaSuccess: false,
        newsMultimediaFail: true,
      }
    case GET_PARTNER_RELATION:
      return {
        ...state,
        partnerRelation: [],
        partnerRelationLoad: true,
        partnerRelationSuccess: false,
        partnerRelationFail: false,
      }
    case GET_PARTNER_RELATION_SUCCESS:
      return {
        ...state,
        partnerRelation: action.payload.items,
        partnerRelationTotalRow: action.payload.total,
        partnerRelationLoad: false,
        partnerRelationSuccess: true,
        partnerRelationFail: false,
      }
    case GET_PARTNER_RELATION_FAILURE:
      return {
        ...state,
        partnerRelation: [],
        partnerRelationLoad: false,
        partnerRelationSuccess: false,
        partnerRelationFail: true,
      }
    case GET_CAREER:
      return {
        ...state,
        career: [],
        careerLoad: true,
        careerSuccess: false,
        careerFail: false,
      }
    case GET_CAREER_SUCCESS:
      return {
        ...state,
        career: action.payload.items,
        careerTotalRow: action.payload.total,
        careerLoad: false,
        careerSuccess: true,
        careerFail: false,
      }
    case GET_CAREER_FAILURE:
      return {
        ...state,
        career: [],
        careerLoad: false,
        careerSuccess: false,
        careerFail: true,
      }
    case GET_COMPLAINT:
      return {
        ...state,
        complaint: [],
        complaintLoad: true,
        complaintSuccess: false,
        complaintFail: false,
      }
    case GET_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaint: action.payload.items,
        complaintTotalRow: action.payload.total,
        complaintLoad: false,
        complaintSuccess: true,
        complaintFail: false,
      }
    case GET_COMPLAINT_FAILURE:
      return {
        ...state,
        complaint: [],
        complaintLoad: false,
        complaintSuccess: false,
        complaintFail: true,
      }
    case GET_SUGGESTIONS:
      return {
        ...state,
        suggestions: [],
        suggestionsLoad: true,
        suggestionsSuccess: false,
        suggestionsFail: false,
      }
    case GET_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: action.payload.items,
        suggestionsTotalRow: action.payload.total,
        suggestionsLoad: false,
        suggestionsSuccess: true,
        suggestionsFail: false,
      }
    case GET_SUGGESTIONS_FAILURE:
      return {
        ...state,
        suggestions: [],
        suggestionsLoad: false,
        suggestionsSuccess: false,
        suggestionsFail: true,
      }
    case GET_CUSTOMER_SERVICES:
      return {
        ...state,
        customerService: [],
        customerServiceLoad: true,
        customerServiceSuccess: false,
        customerServiceFail: false,
      }
    case GET_CUSTOMER_SERVICES_SUCCESS:
      return {
        ...state,
        customerService: action.payload.items,
        customerServiceTotalRow: action.payload.total,
        customerServiceLoad: false,
        customerServiceSuccess: true,
        customerServiceFail: false,
      }
    case GET_CUSTOMER_SERVICES_FAILURE:
      return {
        ...state,
        customerService: [],
        customerServiceLoad: false,
        customerServiceSuccess: false,
        customerServiceFail: true,
      }
    case DELETE_MEMBER_CONTACT:
      return {
        ...state,
        deleteContactLoad: true,
        contactDeleteSuccess: false,
        contactDeleteFail: false,
      }
    case DELETE_MEMBER_CONTACT_SUCCESS:
      const changedContactData = state.contactUsers.filter(value => !action.payload.ids.includes(value.id))
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        contactUsers: changedContactData,
        deleteContactLoad: false,
        contactDeleteSuccess: true,
        contactDeleteFail: false,
      }
    case DELETE_MEMBER_CONTACT_FAILURE:
      return {
        ...state,
        deleteContactLoad: false,
        contactDeleteSuccess: false,
        contactDeleteFail: true,
      }
    case DELETE_NEWS_MULTI:
      return {
        ...state,
        deleteNewsLoad: true,
        newsDeleteSuccess: false,
        newsDeleteFail: false,
      }
    case DELETE_NEWS_MULTI_SUCCESS:
      const changedNewsData = state.newsAndMultimedia.filter(value => !action.payload.ids.includes(value.id))
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        newsAndMultimedia: changedNewsData,
        deleteNewsLoad: false,
        newsDeleteSuccess: true,
        newsDeleteFail: false,
      }
    case DELETE_NEWS_MULTI_FAILURE:
      return {
        ...state,
        deleteNewsLoad: false,
        newsDeleteSuccess: false,
        newsDeleteFail: true,
      }
    case DELETE_PARTNER_RELATIONS:
      return {
        ...state,
        partnerRelationLoad: true,
        partnerRelationSuccess: false,
        partnerRelationFail: false,
      }
    case DELETE_PARTNER_RELATIONS_SUCCESS:
      const changedPartnerData = state.partnerRelation.filter(value => !action.payload.ids.includes(value.id))
      NotificationWithIcon('success', 'Record Deleted Successfully')

      return {
        ...state,
        partnerRelation: changedPartnerData,
        partnerRelationLoad: false,
        partnerRelationSuccess: true,
        partnerRelationFail: false,
      }
    case DELETE_PARTNER_RELATIONS_FAILURE:
      return {
        ...state,
        partnerRelationLoad: false,
        partnerRelationSuccess: false,
        partnerRelationFail: true,
      }
    case DELETE_CAREER:
      return {
        ...state,
        deleteCareerLoad: true,
        careerDeleteSuccess: false,
        careerDeleteFail: false,
      }
    case DELETE_CAREER_SUCCESS:
      const changedCareerData = state.career.filter(value => !action.payload.ids.includes(value.id))
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        career: changedCareerData,
        deleteCareerLoad: false,
        careerDeleteSuccess: true,
        careerDeleteFail: false,
      }
    case DELETE_CAREER_FAILURE:
      return {
        ...state,
        deleteCareerLoad: false,
        careerDeleteSuccess: false,
        careerDeleteFail: true,
      }

    case DELETE_SUGGESTIONS:
      return {
        ...state,
        deleteSuggestLoad: true,
        suggestionsDeleteSuccess: false,
        suggestionsDeleteFail: false,
      }
    case DELETE_SUGGESTIONS_SUCCESS:
      const changedSuggestData = state.suggestions.filter(value => !action.payload.ids.includes(value.id))
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        suggestions: changedSuggestData,
        deleteSuggestLoad: false,
        suggestionsDeleteSuccess: true,
        suggestionsDeleteFail: false,
      }
    case DELETE_SUGGESTIONS_FAILURE:
      return {
        ...state,
        deleteSuggestLoad: false,
        suggestionsDeleteSuccess: false,
        suggestionsDeleteFail: true,
      }
    case DELETE_CUSTOMER_SERVICES:
      return {
        ...state,
        deleteCustomerServiceLoad: true,
        customerServicesSuccess: false,
        customerServicesFail: false,
      }
    case DELETE_CUSTOMER_SERVICES_SUCCESS:
      const changedCustomerServiceData = state.customerService.filter(value => !action.payload.ids.includes(value.id))
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        customerService: changedCustomerServiceData,
        deleteCustomerServiceLoad: false,
        customerServicesSuccess: true,
        customerServicesFail: false,
      }
    case DELETE_CUSTOMER_SERVICES_FAILURE:
      return {
        ...state,
        deleteCustomerServiceLoad: false,
        customerServicesSuccess: false,
        customerServicesFail: true,
      }
    case CLEAR_FORMS_FLAG:
      return {
        ...state,
        deleteContactLoad: false,
        contactDeleteSuccess: false,
        contactDeleteFail: false,
        contactLoad: false,
        contactSuccess: false,
        contactFail: false,
        deleteNewsLoad: false,
        newsDeleteSuccess: false,
        newsDeleteFail: false,
        deleteCareerLoad: false,
        careerDeleteSuccess: false,
        careerDeleteFail: false,
        deleteSuggestLoad: false,
        suggestionsDeleteSuccess: false,
        suggestionsDeleteFail: false,
        deleteCustomerServiceLoad: false,
        customerServicesSuccess: false,
        customerServicesFail: false,
      }
    default:
      return state
  }
}
