export interface manageRentCountState {
    manageRentCount: []
    manageRentCountSuccess: boolean
    manageRentCountFailure: boolean
}
// export manageRentData {

// }

export const GET_MANAGE_RENT_COUNT = 'GET_MANAGE_RENT_COUNT' 
export const GET_MANAGE_RENT_COUNT_SUCCESS = 'GET_MANAGE_RENT_COUNT_SUCCESS'
export const GET_MANAGE_RENT_COUNT_FAILURE = 'GET_MANAGE_RENT_COUNT_FAILURE'

interface getManageRentCount {
    type: typeof GET_MANAGE_RENT_COUNT
    payload: any
}

interface getManageRentCountSuccess {
    type: typeof GET_MANAGE_RENT_COUNT_SUCCESS
    payload: any
}

interface getManageRentCountFailure {
    type: typeof GET_MANAGE_RENT_COUNT_FAILURE
    payload: any
}

export type ActionType = getManageRentCount | getManageRentCountSuccess | getManageRentCountFailure