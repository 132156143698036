import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { VerticalGraph } from '../../../component/charts/VerticalGraph'
import { Card, Row, Col, Typography, Avatar, Space, Spin } from 'antd'
import { CircularGraph } from '../../../component/charts/CircularGraph'
import { getManageSalesCount } from '../../../store/dashboard/manageSalesEngine/Action'
import { connect } from 'react-redux'
const { Title } = Typography

export interface Props {
  getManageSalesCount: typeof getManageSalesCount
  manageSalesEngineCount: any
  manageSalesEngineCountSuccess:boolean
}
export interface State {}

 class ManageSalesEngine extends Component<Props, State> {
  state = {}
  componentDidMount() {
    console.log('component mounted')
    const { getManageSalesCount } = this.props
    getManageSalesCount()
  }

  render() {
    const { manageSalesEngineCount } = this.props
    function sortDataBasedOnDesiredOrder(data:any) {
      const commonDesiredOrder = [
          "My Boat",
          "Surveyor Payment Completed",
          "Surveyor Report Uploaded",
          "Boat Payment Completed",
          "Shipper Uploaded Document",
          "Boat Received"
      ];
  
      return data?.sort((a:any, b:any) => {
          const indexA = commonDesiredOrder.indexOf(a._id);
          const indexB = commonDesiredOrder.indexOf(b._id);
          return indexA - indexB;
      });
  }
    const boatOwner = sortDataBasedOnDesiredOrder(manageSalesEngineCount?.boatOwner)
    const boatManufacturer = sortDataBasedOnDesiredOrder(manageSalesEngineCount?.boatManufacturer)
    const brokerAndDealer = sortDataBasedOnDesiredOrder(manageSalesEngineCount?.brokerAndDealer)

    const nameFormatter = (type:any) =>{
      switch(type){
        case 'My Boat':
          return 'Buy It Now'
        case 'Surveyor Payment Completed':
          return 'Survery My Boat'
        case 'Surveyor Report Uploaded':
          return 'Survery Report'
        case 'Boat Payment Completed':
          return 'Boat Payment'  
        case 'Shipper Uploaded Document':
          return 'Shipment'  
        case 'Boat Received':
          return 'Pick Up Boat'     
        default:
          return ''   
      }
    }
    return (
      <>
        <div style={{ background: '#ECECEC', padding: '30px'}}>
          <Spin spinning={manageSalesEngineCount?.length==0}>
          <Row gutter={16}>
                  <Col span={8}>
                  <Title style={{textAlign:'center'}} level={3}>Boat Owner</Title>
                    {
                      boatOwner && boatOwner?.map((elem:any) => (
                        <Card style={{margin:'1rem'}} title={nameFormatter(elem?._id)} bordered={false}>
                        <div className="d-flex justify-content-between">
                          <Col span={8}>
                            {
                              (elem?._id == 'Surveyor Payment Completed' || elem?._id == 'Boat Payment Completed')
                              ? <img src={require('../../../assets/image/salesEngine/PendingPayment_Big@3x.png')} height="50px" width="50px" />
                              : elem?._id == 'My Boat'
                              ? <img src={require('../../../assets/image/salesEngine/BuyItNow_Big@3x.png')} height="50px" width="50px" />
                              : <img src={require('../../../assets/image/salesEngine/Allocate_Big@3x.png')} height="50px" width="50px" />
                            }
                            
                          </Col>
                          <Col span={8}>
                            <Title level={2} type="secondary">
                              {elem?.count}
                            </Title>
                          </Col>
                        </div>
                      </Card>
                      ))
                    }
                </Col>
                <Col span={8}>
                  <Title style={{textAlign:'center'}} level={3}>Broker And Dealer</Title>
                    {
                      brokerAndDealer && brokerAndDealer?.map((elem:any) => (
                        <Card style={{margin:'1rem'}} title={nameFormatter(elem?._id)} bordered={false}>
                        <div className="d-flex justify-content-between">
                          <Col span={8}>
                            {
                              (elem?._id == 'Surveyor Payment Completed' || elem?._id == 'Boat Payment Completed')
                              ? <img src={require('../../../assets/image/salesEngine/PendingPayment_Big@3x.png')} height="50px" width="50px" />
                              : elem?._id == 'My Boat'
                              ? <img src={require('../../../assets/image/salesEngine/BuyItNow_Big@3x.png')} height="50px" width="50px" />
                              : <img src={require('../../../assets/image/salesEngine/Allocate_Big@3x.png')} height="50px" width="50px" />
                            }
                            
                          </Col>
                          <Col span={8}>
                            <Title level={2} type="secondary">
                              {elem?.count}
                            </Title>
                          </Col>
                        </div>
                      </Card>
                      ))
                    }
                </Col>
                <Col span={8}>
                  <Title style={{textAlign:'center'}} level={3}>Boat Manufacturer</Title>
                    {
                      boatManufacturer && boatManufacturer?.map((elem:any) => (
                        <Card style={{margin:'1rem'}} title={nameFormatter(elem?._id)} bordered={false}>
                        <div className="d-flex justify-content-between">
                          <Col span={8}>
                            {
                              (elem?._id == 'Surveyor Payment Completed' || elem?._id == 'Boat Payment Completed')
                              ? <img src={require('../../../assets/image/salesEngine/PendingPayment_Big@3x.png')} height="50px" width="50px" />
                              : elem?._id == 'My Boat'
                              ? <img src={require('../../../assets/image/salesEngine/BuyItNow_Big@3x.png')} height="50px" width="50px" />
                              : <img src={require('../../../assets/image/salesEngine/Allocate_Big@3x.png')} height="50px" width="50px" />
                            }
                            
                          </Col>
                          <Col span={8}>
                            <Title level={2} type="secondary">
                              {(elem?._id == 'Surveyor Payment Completed' || elem?._id == 'Surveyor Report Uploaded') ? '0' : elem?.count}
                            </Title>
                          </Col>
                        </div>
                      </Card>
                      ))
                    }
                </Col>
          </Row>
          </Spin>
        </div>
        <br />

        {/* <Row gutter={16} className="mb-20">
          <Col span={12}>
            <CircularGraph
              title={'SALES ENGINE'}
              labels={['SALES ENGINE', 'NEW SALES ENGINE', 'BUY IT NOW', 'SURVEYOR ALLOCATE REQUEST']}
              data={[52, 10, 82, 15]}
            />
          </Col>

          <Col span={12}>
            <CircularGraph
              title={'SALES ENGINE BREAKDOWN'}
              labels={['TOTAL SALES ENGINE', 'SINGLE SELLER SALES ENGINE', 'BROKER SALES ENGINE', 'NEW BUILD SALES ENGINE']}
              data={[52, 13, 17, 82]}
            />
          </Col>
        </Row> */}
      </>
    )
  }
}

const mapStateToProps = (state:any) => {
  return{
    manageSalesEngineCount: state.manageSalesEngineCountReducer?.manageSalesEngineCount,
    manageSalesEngineCountSuccess: state.manageSalesEngineCountReducer?.manageSalesEngineCountSuccess
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getManageSalesCount: () => dispatch(getManageSalesCount())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSalesEngine)
