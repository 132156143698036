import React, { Component } from 'react'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'

import { Button, Card, Switch } from 'antd'
import { Link } from 'react-router-dom'
import ManageAccount from '../../../component/modal/ManageAccount'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'

const data = [
  {
    id: '',
    key: 1,
    userId: 'John_Brown',
    userEmail: 'jonh@test.com',
    firstName: 'Ghanshyam',
    lastName: 'Prajapati',
    status: true,
    accessAccountPermission: true,
    address: 'New York No. 1 Lake Park',
    ContactNumber: 9898989898,
    description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
    permission: ['Manage Sales Engine Process', 'Manage Archive Sale Process'],
    countries: ['India', 'Usa'],
  },
  {
    id: '',
    key: 2,
    userId: 'John_Brown',
    userEmail: 'jonh@test.com',
    firstName: 'Karan',
    lastName: 'Khimani',
    status: false,
    accessAccountPermission: true,
    address: 'New York No. 1 Lake Park',
    ContactNumber: 9898989898,
    description: 'My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.',
    permission: ['Manage Sales Engine Process'],
    countries: ['Japan', 'UK'],
  },
]
export interface Props {}

export interface State {
  singleDetail: tableRecords
  isSingleView: boolean
}
class SalesManDashboard extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isSingleView: false,
      singleDetail: {
        id: '',
      },
    }
  }
  viewModal = (viewDetail: tableRecords) => {
    this.setState({ isSingleView: true, singleDetail: viewDetail })
  }
  showModal = () => {
    this.setState({
      isSingleView: true,
    })
  }
  handleOk = () => {
    this.setState({
      isSingleView: false,
    })
  }
  handleCancel = () => {
    this.setState({
      isSingleView: false,
    })
  }
  render() {
    const { isSingleView, singleDetail } = this.state
    const columns = [
      {
        title: 'User Id',
        dataIndex: 'userId',
        key: 'userId',
      },
      {
        title: 'User Email',
        dataIndex: 'userEmail',
        key: 'userEmail',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string, record: tableRecords, index: number) => (
          <Switch checked={record.status} onChange={value => {}} checkedChildren="Active" unCheckedChildren="Block" />
        ),
      },
      {
        title: 'Delete Permission',
        dataIndex: 'deletePermission',
        key: 'deletePermission',
        render: (text: string, record: any, index: number) => (
          <Switch checked={record.status} onChange={value => {}} checkedChildren="Active" unCheckedChildren="Block" />
        ),
      },
      {
        title: 'Access Account Permission',
        dataIndex: 'accessAccountPermission',
        key: 'accessAccountPermission',
        render: (text: string, record: tableRecords, index: number) => (
          <Switch
            checked={record.accessAccountPermission}
            onChange={value => {}}
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        ),
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => (
          <>
            {' '}
            <Button onClick={() => {}}>Edit</Button> <Button onClick={() => this.viewModal(record)}>View</Button>
          </>
        ),
      },
    ]
    return (
      <>
        {/* Single Detail Component */}
        {isSingleView && (
          <ManageAccount
            isFlag={isSingleView}
            title={`${singleDetail.firstName} ${singleDetail.lastName} (Sales Man)`}
            handleCancel={this.handleCancel}
            handleOk={this.handleOk}
            viewData={singleDetail}
          />
        )}

        <Card
          title="Manage Sales Man"
          extra={
            <Button>
              <WSIcon type={<PlusOutlined />} /> <Link to="/create-sales-man">Add Sales Man</Link>
            </Button>
          }
        >
          <ExpandTable columns={columns} tableData={data} isExpand={true} />
        </Card>
      </>
    )
  }
}

export default SalesManDashboard
