export interface canadianTaxState {
  canadianTax: []
  success: boolean
  error: boolean
}

export interface canadianTaxData {
  canadaProvince: string
  name: string
  GSTorHST: string
  PST: string
}

export interface canadianTax {
  id: string
  canadaProvince: string
  name: string
  GSTorHST: string
  PST: string
}

export const CREATE_CANADIAN_TAX = 'CREATE_CANADIAN_TAX'
export const CREATE_CANADIAN_TAX_SUCCESS = 'CREATE_CANADIAN_TAX_SUCCESS'
export const CREATE_CANADIAN_TAX_FAILURE = 'CREATE_CANADIAN_TAX_FAILURE'

export const GET_ALL_CANADIAN_TAX = 'GET_ALL_CANADIAN_TAX'
export const GET_ALL_CANADIAN_TAX_SUCCESS = 'GET_ALL_CANADIAN_TAX_SUCCESS'
export const GET_ALL_CANADIAN_TAX_FAILURE = 'GET_ALL_CANADIAN_TAX_FAILURE'

export const UPDATE_CANADIAN_TAX = 'UPDATE_CANADIAN_TAX'
export const UPDATE_CANADIAN_TAX_SUCCESS = 'UPDATE_CANADIAN_TAX_SUCCESS'
export const UPDATE_CANADIAN_TAX_FAILURE = 'UPDATE_CANADIAN_TAX_FAILURE'

export const CLEAR_CANADIAN_TAX_FLAG = 'CLEAR_CANADIAN_TAX_FLAG'

export const DELETE_CANADIAN_TAX = 'DELETE_CANADIAN_TAX'
export const DELETE_CANADIAN_TAX_SUCCESS = 'DELETE_CANADIAN_TAX_SUCCESS'
export const DELETE_CANADIAN_TAX_FAILURE = 'DELETE_CANADIAN_TAX_FAILURE'

export const CHANGE_TAX_STATUS = 'CHANGE_TAX_STATUS'
export const CHANGE_TAX_STATUS_SUCCESS = 'CHANGE_TAX_STATUS_SUCCESS'
export const CHANGE_TAX_STATUS_FAILURE = 'CHANGE_TAX_STATUS_FAILURE'

interface createCanadianTax {
  type: typeof CREATE_CANADIAN_TAX
  payload: canadianTaxData
}

interface createCanadianTaxSuccess {
  type: typeof CREATE_CANADIAN_TAX_SUCCESS
  payload: canadianTaxData
}

interface getCanadianTax {
  type: typeof GET_ALL_CANADIAN_TAX
  payload: any
}

interface getCanadianTaxSuccess {
  type: typeof GET_ALL_CANADIAN_TAX_SUCCESS
  payload: any
}

interface updateCanadianTax {
  type: typeof UPDATE_CANADIAN_TAX
  payload: canadianTaxData
}

interface updateCanadianTaxSuccess {
  type: typeof UPDATE_CANADIAN_TAX_SUCCESS
  payload: canadianTaxData
}

interface updateCanadianTaxFailure {
  type: typeof UPDATE_CANADIAN_TAX_FAILURE
  payload: canadianTaxData
}

interface deleteCanadianTax {
  type: typeof DELETE_CANADIAN_TAX
  payload: canadianTaxData
}

interface deleteCanadianTaxSuccess {
  type: typeof DELETE_CANADIAN_TAX_SUCCESS
  payload: canadianTaxData
}

interface deleteCanadianTaxFailure {
  type: typeof DELETE_CANADIAN_TAX_FAILURE
  payload: canadianTaxData
}

interface clearFlag {
  type: typeof CLEAR_CANADIAN_TAX_FLAG
  payload: any
}

interface changeTaxStatus {
  type: typeof CHANGE_TAX_STATUS
  payload: string
}

interface changeTaxStatusSuccess {
  type: typeof CHANGE_TAX_STATUS_SUCCESS
  payload: canadianTaxData
}

interface changeTaxStatusFailure {
  type: typeof CHANGE_TAX_STATUS_FAILURE
  payload: string
}

export type ActionType =
  | createCanadianTax
  | createCanadianTaxSuccess
  | getCanadianTax
  | getCanadianTaxSuccess
  | updateCanadianTax
  | updateCanadianTaxSuccess
  | updateCanadianTaxFailure
  | deleteCanadianTax
  | deleteCanadianTaxSuccess
  | deleteCanadianTaxFailure
  | clearFlag
  | changeTaxStatus
  | changeTaxStatusSuccess
  | changeTaxStatusFailure
