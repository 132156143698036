import { all, fork, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { client } from '../..'
import { getUsersByCountry, createUser, deleteUser, flagReportList, searchUsers } from './Schema'
import {
  GET_ADMIN_USER_ROLE_SUCCESS,
  GET_ADMIN_USER_ROLE_FAILURE,
  GET_ADMIN_USER_ROLE,
  ActionType,
  CREATE_ADMIN_USER_ROLE_SUCCESS,
  CREATE_ADMIN_USER_ROLE_FAILURE,
  CREATE_ADMIN_USER_ROLE,
  DELETE_ADMIN_USER_ROLE_SUCCESS,
  DELETE_ADMIN_USER_ROLE_FAILURE,
  DELETE_ADMIN_USER_ROLE,
  GET_FLAG_COMPLAINT,
  GET_FLAG_COMPLAINT_FAILURE,
  GET_FLAG_COMPLAINT_SUCCESS,
  GET_FRONT_USERS,
  GET_FRONT_USERS_FAILURE,
  GET_FRONT_USERS_SUCCESS,
  SEARCH_USER,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
} from './Type'

function getAdminUserRoleApi(data: any) {
  return client
    .query({
      query: getUsersByCountry,
      variables: { ...data.payload },
    })
    
}

function* getAdminUserRoleData({ payload }: AnyAction) {
  try {
    const res = yield getAdminUserRoleApi({ payload })
    yield put({
      type: GET_ADMIN_USER_ROLE_SUCCESS,
      payload: res.data.getUsersByCountry || res.data.getAllUsers,
    })
  } catch (error) {
    yield put({ type: GET_ADMIN_USER_ROLE_FAILURE, error })
  }
}

function* getAdminUserRoleSaga() {
  yield takeLatest(GET_ADMIN_USER_ROLE, getAdminUserRoleData)
}

function getFlagComplaintApi(data: any) {
  return client
    .query({
      query: flagReportList,
      variables: data,
    })
    
}

function* getFlagComplaintData({ payload }: AnyAction) {
  try {
    const res = yield getFlagComplaintApi(payload)

    yield put({
      type: GET_FLAG_COMPLAINT_SUCCESS,
      payload: res.data.flagReportList,
    })
  } catch (error) {
    yield put({ type: GET_FLAG_COMPLAINT_FAILURE, error })
  }
}

function* getFlagComplaintSaga() {
  yield takeLatest(GET_FLAG_COMPLAINT, getFlagComplaintData)
}

function createAdminUserRoleApi({ isUpdate, ...input}: any = {}) {
  return client
    .mutate({
      mutation: createUser,
      variables: { input, isUpdate },
    })
    
}

function* createAdminUserRoleData(action: any) {
  try {
    const res = yield createAdminUserRoleApi(action.payload)
    yield put({ type: CREATE_ADMIN_USER_ROLE_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_ADMIN_USER_ROLE_FAILURE })
  }
}

function* createAdminUserRoleSaga() {
  yield takeLatest(CREATE_ADMIN_USER_ROLE, createAdminUserRoleData)
}

async function deleteAdminUserRoleApi(data: any) {
  const id = data.payload
  return await client
    .mutate({
      mutation: deleteUser,
      variables: { id },
    })
    
}

function* deleteAdminUserRoleData(action: ActionType) {
  try {
    const res = yield deleteAdminUserRoleApi(action)

    yield put({ type: DELETE_ADMIN_USER_ROLE_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: DELETE_ADMIN_USER_ROLE_FAILURE, error })
  }
}

function* deleteAdminUserRoleSaga() {
  yield takeLatest(DELETE_ADMIN_USER_ROLE, deleteAdminUserRoleData)
}

function searchUserApi(data: any) {
  return client
    .query({
      query: searchUsers,
      variables: data.payload,
      fetchPolicy: 'no-cache',
    })
    
}

function* searchUserData(action: ActionType) {
  try {
    const res = yield searchUserApi(action)
    yield put({ type: SEARCH_USER_SUCCESS, payload: res.data.searchUsers })
  } catch (e) {
    yield put({ type: SEARCH_USER_FAILURE, e })
  }
}

function* searchUserSaga() {
  yield takeEvery(SEARCH_USER, searchUserData)
}

export default function* adminUserRoleSaga() {
  yield all([
    fork(getAdminUserRoleSaga),
    fork(createAdminUserRoleSaga),
    fork(deleteAdminUserRoleSaga),
    fork(getFlagComplaintSaga),
    fork(searchUserSaga),
  ])
}
