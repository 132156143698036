import {
  roleState,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  CLEAR_ROLES_FLAG,
  ActionType,
  GET_USERS_COUNT_ROLE_WISE,
  GET_USERS_COUNT_ROLE_WISE_SUCCESS,
  GET_USERS_COUNT_ROLE_WISE_FAILURE,
} from './Types'

const initialState: roleState = {
  success: false,
  error: false,
  roles: [],
  roleCounts: [],
  roleWiseCountSuccess: false,
  roleWiseCountError: false,
}

export function roleReducer(state = initialState, action: ActionType): roleState {
  switch (action.type) {
    case GET_ROLES:
      return {
        ...state,
        success: false,
        error: false,
      }
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        roles: action.payload.data.getAllRoles,
      }
    case GET_ROLES_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }
    case CLEAR_ROLES_FLAG:
      return {
        ...state,
        success: false,
        error: false,
        roleWiseCountSuccess: false,
        roleWiseCountError: false,
      }
    case GET_USERS_COUNT_ROLE_WISE:
      return {
        ...state,
        roleWiseCountSuccess: false,
        roleWiseCountError: false,
      }
    case GET_USERS_COUNT_ROLE_WISE_SUCCESS:
      return {
        ...state,
        roleWiseCountSuccess: true,
        roleWiseCountError: false,
        roleCounts: action.payload.getUsersCountRoleWise,
      }
    case GET_USERS_COUNT_ROLE_WISE_FAILURE:
      return {
        ...state,
        roleWiseCountSuccess: false,
        roleWiseCountError: true,
      }

    default:
      return state
  }
}
