import gql from 'graphql-tag'

export const getAdminUsersCount = gql`
  {
    getAdminUsersCount {
      roleCounts
      total
    }
  }
`
