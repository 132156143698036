import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form, Input, Select, Spin } from 'antd'
import { editLookupsAction, getTripLookupsAction } from '../../store/login/Actions'
import { Field, Formik } from 'formik'
import { fileType, iconType, removeFileType, valueType } from '../../store/login/Types'
import WSField from '../../component/Field'
import UploadImage from '../../component/UploadImage'
import { errorNotification, imageExtensions, invalidFile, MEDIA_NAME, MEDIA_TYPE } from '../../enums/enums'
import { splitUrlFromUnderscore, trimmedValue } from '../../helpers/helper'
import { handleSingleFileDeleteObj, handleSingleFileUploadObj } from '../../helpers/S3FileUpload'
import NotificationWithIcon from '../../component/notification/Notification'
import { getAllBoatPagination } from '../../enums/enums'
import Image from '../../component/Image'

interface Icon {
  id: string
  url: string
}

interface AddMedia {
  key: string
  name: string
  type: string
  mediaName: string
  mediaType: string
}

interface Record {
  id: string
  alias: string
  tripId?: { id: string; alias: string }[]
  icon?: Icon
}

interface ModalProps {
  typeId: string
  isTypeIdTripType: boolean
  trip_typeId?: string
  record?: Record
  getTripLookupsAction: typeof getTripLookupsAction
  tripLookups: any[]
  buttonName: string
  editLookupsAction: typeof editLookupsAction
}

interface ModalData {
  id?: string
  typeId: string
  alias: string
  tripId?: string[]
  icon?: Icon
  addMedia?: AddMedia
  removeMedia?: string
}

interface ModalState {
  loading: boolean
  visible: boolean
}

interface FormikValues {
  id?: string
  typeId: string
  alias: string
  tripId?: string[]
  icon?: Icon
  addMedia?: AddMedia | null
  removeMedia?: string | null
}

interface LookupInput {
  id?: string
  typeId: string
  alias: string
  tripId?: string[]
  icon?: AddMedia
  removeMedia?: string
}

class CreateEditModal extends React.PureComponent<ModalProps, ModalState> {
  state: ModalState = {
    loading: false,
    visible: false,
  }

  componentDidMount() {
    const { isTypeIdTripType, trip_typeId, getTripLookupsAction } = this.props

    if (isTypeIdTripType) {
      getTripLookupsAction({ ...getAllBoatPagination, typeId: trip_typeId })
    }
  }

  showModal = (visible: boolean) => {
    this.setState({
      visible,
      loading: false,
    })
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  getInitialValues = (): FormikValues => {
    const { typeId, record } = this.props

    let initialValues: FormikValues = {
      typeId,
      alias: record ? record.alias : '',
      addMedia: null,
      removeMedia: null,
    }

    if (record) {
      if (record.id) initialValues.id = record.id
      if (record.tripId) initialValues.tripId = record.tripId.map(trip => trip.id)
      if (record.icon) initialValues.icon = record.icon
    }

    return initialValues
  }

  handleFileUpload = async (file: fileType, setFieldValue: (field: string, value: any) => void, acceptType: string) => {
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUploadObj(file, 'addMedia', setFieldValue, MEDIA_NAME.BOATFEATURES, MEDIA_TYPE.IMAGE)
      this.setState({ loading: false })
    } else {
      NotificationWithIcon(errorNotification, invalidFile)
    }
  }

  handleRemove = async (
    file: removeFileType,
    name: string,
    setFieldValue: (field: string, value: any) => void,
    { addMedia }: FormikValues
  ) => {
    await handleSingleFileDeleteObj(file, name, setFieldValue, addMedia as AddMedia)
  }

  handleSubmit = (values: FormikValues) => {
    const { editLookupsAction } = this.props

    let input: LookupInput = {
      typeId: values.typeId,
      alias: trimmedValue(values.alias),
    }

    if (values.id) input.id = values.id
    if (values.tripId) input.tripId = values.tripId
    if (values.addMedia) input.icon = values.addMedia as AddMedia
    if (values.removeMedia) input.removeMedia = values.removeMedia as string

    editLookupsAction(input)
    this.showModal(false)
  }

  render() {
    const { isTypeIdTripType, tripLookups, buttonName } = this.props
    const { visible } = this.state

    return (
      <Formik initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} enableReinitialize={true}>
        {props => {
          const { handleSubmit, setFieldValue, values } = props

          return (
            <div>
              <Button onClick={() => this.showModal(true)} className="btn-view">
                {buttonName}
              </Button>

              <Modal visible={visible} title={`${buttonName} Type`} footer={false} closable={false}>
                <form onSubmit={handleSubmit}>
                  <Field
                    className="ant-input"
                    name="alias"
                    placeholder="Alias"
                    value={values.alias}
                    onChange={(e: any) => setFieldValue('alias', e.target.value)}
                  />

                  {isTypeIdTripType && (
                    <Field name="tripId">
                      {() => (
                        <select
                          multiple
                          style={{ width: '100%' }}
                          placeholder="Select Trip"
                          defaultValue={values.tripId}
                          onChange={e =>
                            setFieldValue(
                              'tripId',
                              [].slice.call(e.target.selectedOptions).map((i: HTMLOptionElement) => i.value)
                            )
                          }
                        >
                          {tripLookups?.map(trip => (
                            <option key={trip.id} value={trip.id}>
                              {trip.alias}
                            </option>
                          ))}
                        </select>
                      )}
                    </Field>
                  )}

                  {values.icon ? (
                    <div style={{ margin: '1em 0' }}>
                      <Image
                        src={values.icon.url}
                        height={100}
                        removeable
                        onRemove={() => {
                          if (values.icon) {
                            setFieldValue('icon', undefined)
                            setFieldValue('removeMedia', values.icon.id)
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <UploadImage
                      accept={imageExtensions}
                      buttonText="Upload Icon"
                      withIcon
                      openFileDialogOnClick
                      selectSiteIcon={(file: any) => {
                        this.handleFileUpload(file.file || file, setFieldValue, 'image')
                      }}
                      onRemove={(rem: any) => this.handleRemove(rem, 'addMedia', setFieldValue, values as FormikValues)}
                    />
                  )}

                  <Button key="back" className="mr-2" onClick={this.handleCancel}>
                    Return
                  </Button>

                  <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                    {this.state.loading ? <Spin /> : 'Save'}
                  </button>
                </form>
              </Modal>
            </div>
          )
        }}
      </Formik>
    )
  }
}

const mapStateToProps = (state: any) => ({
  tripLookups: state.userLoginState.tripLookups,
})

const mapDispatchToProps = (dispatch: any) => ({
  getTripLookupsAction: (data: string) => dispatch(getTripLookupsAction(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditModal)
