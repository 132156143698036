import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button, Select } from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ReactQuill from 'react-quill'
import * as Yup from 'yup'
import { emailData } from '../../../store/staticContent/emailTemplates/Types'
import { Dispatch } from 'redux'
import {
  addEmailTemplate,
  updateEmailTemplate,
  clearFlag,
  getVariableAction,
} from '../../../store/staticContent/emailTemplates/Actions'
import { connect } from 'react-redux'
import { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { CKEditorWrapper } from '../../../component/CKEditor'
import { templateName, TEMPLATENAME, emailTemplate } from '../../../enums/enums'
import { getManageSmtp } from '../../../store/staticContent/manageSMTP/Action'
import { getUniqueArray, removePTag, trimmedValue } from '../../../helpers/helper'
import NotificationWithIcon from '../../../component/notification/Notification'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import WSSelect from '../../../component/WSSelect'
import { PlusCircleOutlined } from '@ant-design/icons'
const { Text } = Typography
const { Option } = Select

interface Props {
  addEmailTemplate: typeof addEmailTemplate
  location: any
  updateEmailTemplate: typeof updateEmailTemplate
  history: typeof History
  emailSuccess: boolean
  clearFlag: typeof clearFlag
  getVariableAction: typeof getVariableAction
  smtps: any
  getManageSmtp: typeof getManageSmtp
  variableList: any[]
}
interface State {
  editEmail: any
  manageEmail: {
    type: string
    subject: string
    body: string
    smtp: any
    name: string
  }
}

class CreateEmailTemplate extends Component<Props, State> {
  private variableRef = React.createRef<any>()

  formSubmitHandler = (values: any) => {
    const { editEmail } = this.state
    const { addEmailTemplate, updateEmailTemplate } = this.props
    console.log('editEmail out')

    if (editEmail) {
      const { smtp, createdAt, type, updatedAt, name, body, __typename, ...other } = values
      const nameIs = typeof values.name === 'number' ? emailTemplate[values.name] : values.name
      const smtpIs = typeof values.smtp === 'string' ? values.smtp : values.smtp.id
      const input = {
        smtp: smtpIs,
        name: nameIs,
        body: removePTag(values.body),
        ...other,
      }

      updateEmailTemplate(input)
    } else {
      const input = {
        subject: values.subject,
        smtp: values.smtp,
        name: values.name,
        body: removePTag(values.body),
      }

      addEmailTemplate(input)
    }
  }

  componentDidMount() {
    const { clearFlag, smtps, getManageSmtp, getVariableAction } = this.props
    const { editEmail } = this.state
    if (!smtps.length) {
      getManageSmtp()
    }

    if (editEmail) {
      getVariableAction({ template: emailTemplate[editEmail.name] })
    }
    clearFlag()
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      editEmail: props.location.state ? props.location.state.template : null,
      manageEmail: {
        type: '',
        subject: '',
        body: '',
        name: '',
        smtp: '',
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { emailSuccess, clearFlag, history } = props

    if (emailSuccess) {
      clearFlag()
      history.push('/email-templates')
    }
    return null
  }

  copyCodeToClipboard = (text: any) => {
    console.log(text, 'text')

    navigator.clipboard.writeText(text)
    NotificationWithIcon('success', 'Copied!')
  }

  render() {
    const { manageEmail, editEmail } = this.state
    const { history, getVariableAction, smtps, variableList } = this.props

    return (
      <Formik
        initialValues={editEmail || manageEmail}
        onSubmit={values => this.formSubmitHandler(values)}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Email Name field is required.'),
          subject: Yup.string().required('Email subject field is required.'),
        })}
        render={({ errors, status, touched, setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  {editEmail ? 'Edit Email Template' : 'Create Email Template'}
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                    Save
                  </button>
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="Email Subject">
                    Email Subject
                  </span>
                  <Input
                    name="subject"
                    placeholder="Email Subject"
                    value={values.subject}
                    onChange={e => setFieldValue('subject', e.target.value)}
                    onBlur={e => setFieldValue('subject', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`subject`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="Email Name">
                    Email Name
                  </span>

                  <WSSelect
                    defaultValue={TEMPLATENAME[values.name]}
                    onChangeText={(value: any) => {
                      setFieldValue('name', value)
                      getVariableAction({ template: value })
                    }}
                    arrayList={templateName && templateName}
                    listOptions="name"
                    passed="aliasName"
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`name`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="SMTP">
                    SMTP
                  </span>
                  <WSSelect
                    defaultValue={values.smtp && values.smtp.email}
                    onChangeText={(value: any) => {
                      setFieldValue('smtp', value)
                    }}
                    arrayList={getUniqueArray(smtps, (it: any) => it.email)}
                    listOptions="email"
                    passed="id"
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`smtp`} component="span" />
                  </Text>
                </Col>
                {variableList && variableList.length ? (
                  <>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="variable">
                        Select Variable
                      </span>
                      <WSSelect
                        defaultValue={values.metatype}
                        onChangeText={(e: any) => this.copyCodeToClipboard(e)}
                        arrayList={getUniqueArray(variableList, (it: any) => it.name)}
                        listOptions="name"
                        passed="name"
                      />
                    </Col>
                    <Text type="danger">
                      <ErrorMessage className="invalid-feedback ant-typography-danger" name={`smtp`} component="span" />
                    </Text>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span title="Email Body">Email Body</span>
                  <WSField
                    type="textArea"
                    defaultValue={values.body}
                    onChangeText={(evt: any) => {
                      setFieldValue('body', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('body', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`body`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  smtps: state.smtpReducer.smtps,
  emailSuccess: state.emailTemplateReducer && state.emailTemplateReducer.emailSuccess,
  variableList: state.emailTemplateReducer && state.emailTemplateReducer.variableList,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addEmailTemplate: (data: emailData) => dispatch(addEmailTemplate(data)),
  getManageSmtp: (data?: any) => dispatch(getManageSmtp(data)),
  getVariableAction: (data: any) => dispatch(getVariableAction(data)),
  updateEmailTemplate: (data: any) => dispatch(updateEmailTemplate(data)),
  clearFlag: () => dispatch(clearFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmailTemplate)
