import { Card, Switch } from 'antd'
import React, { Component } from 'react'
import ExpandTable from '../../../component/ExpandTable'

class AllCountryBoat extends Component {
  columns = [
    {
      title: 'Country Name',
      dataIndex: 'country',
      key: 'countryName',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: 'Toggle',
      key: 'toggle',
      render: (text: any) => <Switch checked={text} checkedChildren="Active" unCheckedChildren="Block" />,
    },
  ]

  render() {
    return (
      <>
        <Card title="All Country Boats">
          <ExpandTable columns={this.columns} expandHtml={false} isExpand={false} />
        </Card>
      </>
    )
  }
}

export default AllCountryBoat
