import { Button, Col, Row, Typography } from 'antd'
import { ErrorMessage, Field, Formik } from 'formik'
import React, { Component } from 'react'
import * as Yup from 'yup'
import History from '../../History'
import { resetPasswordType } from '../../store/changePassword/Types'
import './RecoverAccount.scss'
import { CheckCircleOutlined, CloseCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { passwordStrengthEnum } from '../../enums/enums'
import { get } from 'lodash'
import CommonModal from '../../component/modal/CommonModal'
import OTPInput from 'react-otp-input'
import NotificationWithIcon from '../../component/notification/Notification'
import { client } from '../..'
import { sendOTPAndGetToken, verifyOTPAndChangePassword } from '../../store/changePassword/Schema'

const { Text } = Typography

export interface Props {
  history: typeof History
}

export interface State {
  formData: {
    email: string
    newPassword: string
    confirmPassword: string
  }
  newPasswordView: boolean
  confirmPasswordView: boolean
  errorMessages: any
  passwordStrength: string
  isDisplayHint: boolean
  isOTPModalOpen: boolean
  otpValue: string
  otpToken: string
}

class RecoverAccount extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      formData: {
        email: '',
        newPassword: '',
        confirmPassword: '',
      },
      newPasswordView: false,
      confirmPasswordView: false,
      errorMessages: {},
      passwordStrength: '',
      isDisplayHint: false,
      isOTPModalOpen: false,
      otpValue: '',
      otpToken: '',
    }
  }

  handleSubmit = async (values: resetPasswordType, formik: any) => {
    if (Object.values(this.state.errorMessages).some(v => v))
      return formik.setFieldError('newPassword', 'Invalid Password type!')

    const { email, newPassword } = values

    try {
      const res = await client.mutate({
        mutation: sendOTPAndGetToken,
        variables: { email, newPassword },
      })

      const data = get(res, 'data.sendOTPAndGetToken', '')

      if (data) {
        NotificationWithIcon('success', 'OTP send to your registered email address')
        this.setState({ otpToken: data, isOTPModalOpen: true })
      } else {
        console.log(res)
        NotificationWithIcon('error', 'Cannot send OTP')
      }
    } catch (err) {
      console.log(err)
      const msg = get(err, 'networkError.result.errors[0].message', '') || 'Cannot send OTP'
      NotificationWithIcon('error', msg)
    }
  }

  validatePassword = (password: string) => {
    let errorMessages: any = {
      passwordLength: false,
      capsCount: false,
      smallCount: false,
      errorMessages: false,
    }
    let capsCount, smallCount, symbolCount, numberCount, uniqPassword
    if (password.length < 8) {
      errorMessages.passwordLength = true
    }
    uniqPassword = password.toLowerCase()

    capsCount = (password.match(/[A-Z]/g) || []).length
    smallCount = (password.match(/[a-z]/g) || []).length
    symbolCount = (password.match(/[-!$%@^&*()_+|~=`{}\[\]:";'<>?,.\/]/) || []).length
    numberCount = (password.match(/[0-9]/g) || []).length

    if (capsCount < 1) {
      errorMessages.capsCount = true
    }
    if (smallCount < 1) {
      errorMessages.smallCount = true
    }
    if ((symbolCount && numberCount) < 1) {
      errorMessages.symbolOrNumberCount = true
    }

    return errorMessages
  }

  passwordStrength = (password: string, setValue: any) => {
    let score = 0
    let passwordStrength
    let regexPositive = [/[A-Z]/g, /[a-z]/g, /[0-9]/g, /[-!$%@^&*()_+|~=`{}\[\]:";'<>?,.\/]/]
    regexPositive.forEach((regex, index) => {
      if (new RegExp(regex).test(password)) {
        score += 1
      }
    })
    switch (score) {
      case 1:
        passwordStrength = passwordStrengthEnum.POOR
        break

      case 2:
        passwordStrength = passwordStrengthEnum.POOR
        break

      case 3:
        passwordStrength = passwordStrengthEnum.AVERAGE
        setValue(password)
        break

      case 4:
        passwordStrength = password.length >= 8 ? passwordStrengthEnum.STRONG : passwordStrengthEnum.AVERAGE
        setValue(password)
        break

      default:
        passwordStrength = passwordStrengthEnum.WEAK
    }

    return passwordStrength
  }

  validate = (e: any, setValue: any, setError: any) => {
    const password = e.target.value
    const errors = this.validatePassword(password)

    this.setState({
      errorMessages: errors,
    })

    const pwdStrength = this.passwordStrength(password, setValue)

    this.setState({
      passwordStrength: pwdStrength,
      isDisplayHint: true,
    })
  }

  renderErrorMessage = (message: string, className: string, isError: boolean, passwordStrength = '') => {
    return (
      <div className={className}>
        <span className={`mr-2 register-password-hint-icon psw--icon--${passwordStrength}`}>
          {isError ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
        </span>
        <span className={`register-password-hint-text  psw--${passwordStrength}`}>{message} </span>
        {passwordStrength && (
          <span className={`password-strength-type psw--${passwordStrength} font-weight-bold`}>{passwordStrength}</span>
        )}
      </div>
    )
  }

  verifyOTP = async () => {
    if (this.state.otpValue.length !== 6) {
      NotificationWithIcon('error', 'Please enter a valid OTP')
      return
    }

    try {
      const res = await client.mutate({
        mutation: verifyOTPAndChangePassword,
        variables: { token: this.state.otpToken, otp: this.state.otpValue },
      })

      const data = get(res, 'data.verifyOTPAndChangePassword', false)

      if (data) {
        NotificationWithIcon('success', 'OTP verified and password changed successfully!')
        window.location.href = '/'
      } else {
        console.log(res)
        NotificationWithIcon('error', 'Cannot verify OTP!')
      }
    } catch (err) {
      console.log(err)
      const msg = get(err, 'networkError.result.errors[0].message', '') || 'Cannot verify OTP!'
      NotificationWithIcon('error', msg)
    }
  }

  render() {
    const { history } = this.props
    const { formData, newPasswordView, confirmPasswordView, passwordStrength, errorMessages } = this.state

    return (
      <>
        <div className="change--password--main--section recover-account-cont">
          <div className="d-flex change--password--title">
            <p className="change--password--main--title">Recover Account</p>
            <p className="change--password--sub--title">You can recover your account here.</p>
          </div>
          <div className="change--password--content--div">
            <Row gutter={16}>
              <Col span={9} className="change--password--left--div">
                <div className="change--password--left--section">
                  <div className="change--password--top--img--section">
                    <img src={require('../../assets/image/change-password/change-password.png')} />
                  </div>
                  <div className="change--password--left--bottom--section">
                    <p className="change--password--left--div--title">Recover Account</p>
                    <p className="change--password--left--div--desc">
                      For security and cyber protection, you are required to update your password at least once a month.
                    </p>
                  </div>
                </div>
              </Col>
              <Col span={15} className="change--password--right--div">
                <div className="change--password--right--section">
                  <Formik
                    initialValues={formData}
                    onSubmit={this.handleSubmit}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().required('Email is required'),
                      newPassword: Yup.string().required('New Password is required'),
                      confirmPassword: Yup.string()
                        .oneOf([Yup.ref('newPassword'), ''], 'Confirm Password must match')
                        .required('Confirm Password is required'),
                    })}
                  >
                    {props => {
                      const { values, handleSubmit, setFieldValue, setFieldTouched, setFieldError } = props
                      return (
                        <form onSubmit={handleSubmit}>
                          <Row gutter={16} className="change--password--form--div">
                            <Col className="gutter-box password--div--spacing" md={24}>
                              <p className="ant-form-item-required change--password--field--title" title="Password">
                                Email
                              </p>
                              <div className="d-flex">
                                <Field type={'email'} className="ant-input" name="email" placeholder="Enter Email" />
                              </div>
                              <Text type="danger">
                                <ErrorMessage className="invalid-feedback " name={`email`} component="span" />
                              </Text>
                            </Col>
                            <Col className="gutter-box password--div--spacing" md={24}>
                              <p className="ant-form-item-required change--password--field--title" title="Password">
                                New Password
                              </p>
                              <div className="d-flex">
                                <input
                                  type={newPasswordView ? 'text' : 'password'}
                                  className="ant-input"
                                  name="newPassword"
                                  placeholder="Enter New Password"
                                  onChange={e => {
                                    setFieldValue('newPassword', e.target.value)
                                    this.validate(
                                      e,
                                      (v: string) => setFieldValue('newPassword', v),
                                      (e: string) => setFieldError('newPassword', e)
                                    )
                                  }}
                                  onBlur={() => {
                                    setFieldTouched('newPassword', true, true)
                                  }}
                                />
                                <button type="button" onClick={() => this.setState({ newPasswordView: !newPasswordView })}>
                                  {newPasswordView ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                                </button>
                              </div>
                              <Text type="danger">
                                <ErrorMessage className="invalid-feedback " name={`newPassword`} component="span" />
                              </Text>
                              {this.state.isDisplayHint && (
                                <div className="password-hint">
                                  {passwordStrength
                                    ? passwordStrength === passwordStrengthEnum.POOR ||
                                      passwordStrength === passwordStrengthEnum.WEAK
                                      ? this.renderErrorMessage(
                                          `Password Strength:`,
                                          'error-message mt-0',
                                          true,
                                          passwordStrength
                                        )
                                      : this.renderErrorMessage(
                                          `Password Strength:`,
                                          'error-message-fullfill',
                                          false,
                                          passwordStrength
                                        )
                                    : ''}
                                  {errorMessages && errorMessages.passwordLength
                                    ? this.renderErrorMessage('At least 8 characters', 'error-message mt-0', true)
                                    : this.renderErrorMessage('At least 8 characters', 'error-message-fullfill', false)}
                                  {errorMessages && errorMessages.symbolOrNumberCount
                                    ? this.renderErrorMessage('At least 1 symbol & Number', 'error-message mt-0', true)
                                    : this.renderErrorMessage('At least 1 symbol & Number', 'error-message-fullfill', false)}
                                  {errorMessages && errorMessages.capsCount
                                    ? this.renderErrorMessage('password must contain one capital', 'error-message mt-0', true)
                                    : this.renderErrorMessage(
                                        'password must contain one capital',
                                        'error-message-fullfill',
                                        false
                                      )}
                                </div>
                              )}
                            </Col>
                            <Col className="gutter-box password--div--spacing" md={24}>
                              <p className="ant-form-item-required change--password--field--title" title=" Re-Type Password">
                                Confirm Password
                              </p>
                              <div className="d-flex">
                                <Field
                                  type={confirmPasswordView ? 'text' : 'password'}
                                  className="ant-input"
                                  name="confirmPassword"
                                  placeholder="Re-enter new password"
                                />
                                <button
                                  type="button"
                                  onClick={() => this.setState({ confirmPasswordView: !confirmPasswordView })}
                                >
                                  {confirmPasswordView ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                                </button>
                              </div>
                              <Text type="danger">
                                <ErrorMessage className="invalid-feedback " name={`confirmPassword`} component="span" />
                              </Text>
                            </Col>
                          </Row>
                          <div className="change--password--btn--section">
                            <Button
                              onClick={() => history.goBack()}
                              className="change--password--back--button change--password--button"
                            >
                              Back
                            </Button>
                            <button
                              className="ant-btn ant-btn-primary reset--password--btn change--password--button"
                              type="submit"
                            >
                              Reset Password
                            </button>
                          </div>
                        </form>
                      )
                    }}
                  </Formik>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {this.state.isOTPModalOpen && (
          <CommonModal
            okText="Verify"
            isFlag={this.state.isOTPModalOpen}
            title="Change Password"
            handleCancel={() => this.setState({ isOTPModalOpen: false })}
            handleOk={this.verifyOTP}
          >
            <div className="otp-input-modal-content">
              <p className="otp-input-modal-text">Enter OTP sent to your email:</p>
              <div className="otp-input-modal-otp-cont">
                <OTPInput
                  value={this.state.otpValue}
                  onChange={otpValue => this.setState({ otpValue })}
                  numInputs={6}
                  renderSeparator={<span>&nbsp;-&nbsp;</span>}
                  renderInput={props => <input {...props} style={{ ...props.style, width: 30, height: 30 }} />}
                />
              </div>
            </div>
          </CommonModal>
        )}
      </>
    )
  }
}

export default RecoverAccount
