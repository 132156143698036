import { imageSchema } from './../../schemaTypes'
import gql from 'graphql-tag'

const LookUp = `{
    id
    alias
}`

const RentType = `{
    id
    name
    icon ${imageSchema}
    thumbnailIcon ${imageSchema}
    tripId ${LookUp}
    createdAt
    updatedAt
}`

export const getTypeWiseLookup = gql`
    query getTypeWiseLookup($type: String!) {
        getTypeWiseLookup(type: $type)
            ${LookUp}
}`

export const getAllRentTypes = gql`
    query getAllRentTypes($limit: Int, $page: Int, $searchTerm: String) {
        getAllRentTypes(limit: $limit, page: $page, searchTerm: $searchTerm) {
            items ${RentType}
            total
    }
}`

export const createRentType = gql`
mutation createRentType($input: RentTypeInput!) {
    createRentType(input: $input)
        ${RentType}
}`

export const getSingleRentType = gql`
    query getSingleRentType($id: String!) {
        getSingleRentType(id: $id)
            ${RentType}
}`

export const updateRentType = gql`
    mutation updateRentType($input: RentTypeInput!){
        updateRentType(input: $input)
            ${RentType}
}`

export const deleteRentType = gql`
    mutation deleteRentType($id: String!){
        deleteRentType(id: $id)
            ${RentType}
}`
