export interface marinaTypesState {
  marinaTypes: any
  marinaTypeSuccess: boolean
  boatTypeError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  updateMarinaTypeSuccess: boolean
  totalMarinaRow: number
}

export interface BoatTypesData {
  icon: String
  name: String
  id: String
}

export interface BoatTypes {
  icon: String
  name: String
  id: String
}

//create boat types

export const CREATE_MARINA_TYPES = 'CREATE_MARINA_TYPES'
export const CREATE_MARINA_TYPES_SUCCESS = 'CREATE_MARINA_TYPES_SUCCESS'
export const CREATE_MARINA_TYPES_FAILURE = 'CREATE_MARINA_TYPES_FAILURE'

//get all boat types

export const GET_MARINA_TYPES = 'GET_MARINA_TYPES'
export const GET_MARINA_TYPES_SUCCESS = 'GET_MARINA_TYPES_SUCCESS'
export const GET_MARINA_TYPES_FAILURE = 'GET_MARINA_TYPES_FAILURE'

//clear flag

export const CLEAR_MARINA_TYPES_FLAG = 'CLEAR_MARINA_TYPES_FLAG'

//update boat types

export const UPDATE_MARINA_TYPES = 'UPDATE_MARINA_TYPES'
export const UPDATE_MARINA_TYPES_SUCCESS = 'UPDATE_MARINA_TYPES_SUCCESS'
export const UPDATE_MARINA_TYPES_FAILURE = 'UPDATE_MARINA_TYPES_FAILURE'

// delete boat types

export const DELETE_MARINA_TYPES = 'DELETE_MARINA_TYPES'
export const DELETE_MARINA_TYPES_SUCCESS = 'DELETE_MARINA_TYPES_SUCCESS'
export const DELETE_MARINA_TYPES_FAILURE = 'DELETE_MARINA_TYPES_FAILURE'

// edit boat types

export const EDIT_BOAT_TYPES = 'EDIT_BOAT_TYPES'
export const EDIT_BOAT_TYPES_SUCCESS = 'EDIT_BOAT_TYPES_SUCCESS'
export const EDIT_BOAT_TYPES_FAILURE = 'EDIT_BOAT_TYPES_FAILURE'

interface createBoatTypes {
  type: typeof CREATE_MARINA_TYPES
  payload: BoatTypesData
}

interface createBoatTypesSuccess {
  type: typeof CREATE_MARINA_TYPES_SUCCESS
  payload: BoatTypes
}

interface createBoatTypesFailure {
  type: typeof CREATE_MARINA_TYPES_FAILURE
  payload: any
}

interface getAllBoatTypes {
  type: typeof GET_MARINA_TYPES
  payload: BoatTypesData
}

interface getAllBoatTypesSuccess {
  type: typeof GET_MARINA_TYPES_SUCCESS
  payload: any
}

interface getAllBoatTypesFailure {
  type: typeof GET_MARINA_TYPES_FAILURE
  payload: any
}

interface clearManageBoatTypesFlag {
  type: typeof CLEAR_MARINA_TYPES_FLAG
  payload: any
}

interface updateBoatTypes {
  type: typeof UPDATE_MARINA_TYPES
  payload: BoatTypes
}

interface updateBoatTypesSuccess {
  type: typeof UPDATE_MARINA_TYPES_SUCCESS
  payload: BoatTypes
}

interface updateBoatTypesFailure {
  type: typeof UPDATE_MARINA_TYPES_FAILURE
  payload: any
}

interface editBoatTypes {
  type: typeof UPDATE_MARINA_TYPES
  payload: string
}

interface editBoatTypesSuccess {
  type: typeof UPDATE_MARINA_TYPES_SUCCESS
  payload: string
}

interface editBoatTypesFailure {
  type: typeof UPDATE_MARINA_TYPES_FAILURE
  payload: any
}

interface deleteBoatTypes {
  type: typeof DELETE_MARINA_TYPES
  payload: string
}

interface deleteBoatTypesSuccess {
  type: typeof DELETE_MARINA_TYPES_SUCCESS
  payload: any
}

interface deleteBoatTypesFailure {
  type: typeof DELETE_MARINA_TYPES_FAILURE
  payload: string
  error: any
}

export type ActionType =
  | createBoatTypes
  | createBoatTypesSuccess
  | createBoatTypesFailure
  | getAllBoatTypes
  | getAllBoatTypesSuccess
  | getAllBoatTypesFailure
  | clearManageBoatTypesFlag
  | deleteBoatTypes
  | deleteBoatTypesSuccess
  | deleteBoatTypesFailure
  | updateBoatTypes
  | updateBoatTypesSuccess
  | updateBoatTypesFailure
  | editBoatTypes
  | editBoatTypesSuccess
  | editBoatTypesFailure
