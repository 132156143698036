import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button } from 'antd'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import History from '../../../History'

import { createDownloads, updateDownloads, clearDownloadsFlag } from '../../../store/staticContent/manageDownloads/Action'
import { downloadsData } from '../../../store/staticContent/manageDownloads/Type'
import { pagination, imageExtensions, MEDIA_NAME, MEDIA_TYPE } from '../../../enums/enums'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { splitUrlFromUnderscore, getAddMedia, trimmedValue, removePTag } from '../../../helpers/helper'
import UploadImage from '../../../component/UploadImage'
import { handleSingleFileUpload, handleSingleFileDelete } from '../../../helpers/S3FileUpload'
import NotificationWithIcon from '../../../component/notification/Notification'
import WSField from '../../../component/Field'

const { Text } = Typography
const { TextArea } = Input

interface Props {
  createDownloads: typeof createDownloads
  location: any
  updateDownloads: typeof updateDownloads
  history: typeof History
  downloadsSuccess: boolean
  clearDownloadsFlag: typeof clearDownloadsFlag
}

interface State {
  loading: boolean
  editDownload: any
  isEdit: boolean
}

class CreateDownloads extends Component<Props, State> {
  formSubmitHandler = (values: downloadsData) => {
    const { isEdit } = this.state
    const { createDownloads, updateDownloads } = this.props
    const { downloadUrl, ...noTypeName } = values

    values.addMedia = getAddMedia(values.addMedia)
    if (isEdit) {
      updateDownloads(noTypeName)
    } else {
      const { downloadUrl, ...submitValues } = values
      createDownloads(submitValues)
    }
  }

  componentDidMount() {
    const { clearDownloadsFlag } = this.props
    clearDownloadsFlag()
  }

  selectDownloadUrl = async (file: any, setFieldValue: any, values: any, acceptType: string) => {
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(
        file,
        'downloadUrl',
        setFieldValue,
        values.addMedia,
        MEDIA_NAME.MANAGEDOWNLOADURL,
        MEDIA_TYPE.DOCUMENT
      )
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleRemove = (file: any, setFieldValue: any, values: any) => {
    const { addMedia } = values

    handleSingleFileDelete(file, 'downloadUrl', setFieldValue, addMedia, values.downloadUrl, 'filter', values)
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      isEdit: props.location.state ? props.location.state.input.isEdit : null,
      editDownload: {
        title: props.location.state?.input?.record?.title || '',
        description: props.location.state?.input?.record?.description || '',
        downloadUrl: [props.location.state?.input?.record?.downloadUrl].filter(Boolean),
        addMedia: [],
        removeMedia: [],
        status: props.location.state?.input?.record?.status,
        id: props.location.state?.input?.record?.id
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { downloadsSuccess, clearDownloadsFlag, history } = props

    if (downloadsSuccess) {
      clearDownloadsFlag()
      history.push('/manage-downloads')
    }
    return null
  }


  render() {
    const { editDownload, loading } = this.state
    const { history } = this.props
    return (
      <Formik
        initialValues={editDownload}
        onSubmit={values => {
          this.formSubmitHandler(values)
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title field is required.'),
          description: Yup.string().required('Downloads description field is required.'),
          downloadUrl: Yup.string().required('Select document.'),
        })}
        render={({ setFieldValue, values }) => (

          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  Create Downloads
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  {!loading ? (
                    <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                      Save
                    </button>
                  ) : (
                    <Button loading={loading} disabled={true} className="ml-15 ant-btn ant-btn-primary">
                      Save
                    </Button>
                  )}
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="title">
                    Downloads Title
                  </span>
                  <Input
                    name="title"
                    placeholder="Downloads Title"
                    value={values.title}
                    onChange={e => setFieldValue('title', e.target.value)}
                    onBlur={e => setFieldValue('title', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`title`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" xs={24}>
                  <span className="ant-form-item-required" title="description">
                    Downloads Description
                  </span>
                  <WSField
                    type="textArea"
                    defaultValue={values.description}
                    onChangeText={(evt: any) => {
                      setFieldValue('description', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('description', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`description`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box">
                  <span title="downloadUrl">Upload</span>
                  <br></br>
                  {values.downloadUrl?.length ? (
                    <UploadImage
                      defaultFileList={values.downloadUrl.map((item: any) => {
                        return {
                          uid: item.id,
                          name: 'Upload document',
                          status: 'done',
                          url: item.url,
                        }
                      })}
                      accept=".doc, .docx, .pdf"
                      buttonText="Uploaded Document"
                      withIcon
                      openFileDialogOnClick={!values.downloadUrl?.length}
                      selectSiteIcon={(file: any) => this.selectDownloadUrl(file, setFieldValue, values, 'application')}
                      onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                    />
                  ) : (
                    <UploadImage
                      accept=".doc, .docx, .pdf"
                      withIcon
                      buttonText="Upload Document"
                      openFileDialogOnClick={!values.downloadUrl?.length}
                      selectSiteIcon={(file: any) => this.selectDownloadUrl(file, setFieldValue, values, 'application')}
                      onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                    />
                  )}
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  downloadsSuccess: state.downloadsReducer && state.downloadsReducer.downloadsSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createDownloads: (data: downloadsData) => dispatch(createDownloads(data)),
  updateDownloads: (data: downloadsData) => dispatch(updateDownloads(data)),
  clearDownloadsFlag: () => dispatch(clearDownloadsFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateDownloads)
