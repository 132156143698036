import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Sort, getSearchedData } from '../../helpers/helper'
import { Button, Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import ExpandTable from '../../component/ExpandTable'
import NotificationWithIcon from '../../component/notification/Notification'
import { client } from '../..'
import gql from 'graphql-tag'
import { useHistory, useParams } from 'react-router-dom'
import { AddressType } from '../../store/schemaTypes'

const PostReportReasons = {
  SpamOrMisleading: 'Spam or Misleading Content',
  NudityOrSexual: 'Nudity or Sexual Activity',
  HateOrDiscrimation: 'Hate Speech or Discrimination',
  Copyright: 'Copyright Infringment',
  Harrassment: 'Harrassment or Bullying',
  Other: 'Other',
}

export default function PostReports() {
  const history = useHistory()
  const params = useParams<{ postId: string }>()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([] as any[])
  const [total, setTotal] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')

  const paginationRef = useRef({ page: 1, limit: 10 })

  const getPostReports = useCallback(
    async pagination => {
      setLoading(true)

      try {
        const res = await client.query({
          query: gql`
            query sm_getPostReports($postId: String!, $pagination: PaginationInput!) {
              sm_getPostReports(postId: $postId, pagination: $pagination) {
                page
                limit
                total
                items {
                  _id
                  reason
                  otherReason
                  post {
                    _id
                  }
                  user {
                    _id
                    firstName
                    lastName
                    address ${AddressType}
                  }
                }
              }
            }
          `,
          variables: { postId: params.postId, pagination },
        })

        paginationRef.current.page = res.data.sm_getPostReports.page
        paginationRef.current.limit = res.data.sm_getPostReports.limit

        setTotal(res.data.sm_getPostReports.total)
        setData(res.data.sm_getPostReports.items)
      } catch (err) {
        console.log(err)
        NotificationWithIcon('error', 'Something went wrong')
      }

      setLoading(false)
    },
    [params]
  )

  useEffect(() => {
    getPostReports({})
  }, [getPostReports])

  const tableCols = () => [
    {
      title: 'Reported By',
      dataIndex: 'user',
      key: 'user',
      render: (v: any) => v.firstName + ' ' + v.lastName,
    },
    {
      title: 'User Country',
      dataIndex: 'user',
      key: 'userAddress',
      render: (v: any) => v.address?.[0]?.country,
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (v: keyof typeof PostReportReasons) => PostReportReasons[v],
    },
    {
      title: 'Other Reason',
      dataIndex: 'otherReason',
      key: 'otherReason',
      render: (v: string) => v,
    },
  ]

  return (
    <Card
      title="Manage Post Reports"
      extra={
        <div className="d-flex align-items-center">
          <TitleSearch onSearch={setSearchTerm} />
          <Button className="ml-3" onClick={() => history.goBack()}>
            Back
          </Button>
        </div>
      }
    >
      {(() => {
        return (
          <ExpandTable
            columns={tableCols()}
            tableData={data}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={total}
            tableLoading={loading}
            pageAction={console.log}
          />
        )
      })()}
    </Card>
  )
}
