import gql from 'graphql-tag'
import { imageSchema } from '../../schemaTypes'

export const createVideo = gql`
  mutation createVideo($input: MarketMediaInput!) {
    createVideo(input: $input) {
      id
      type
      metatype
      url ${imageSchema}
      title
      description
      mediaOrder
      thumbnail ${imageSchema}
      status
    }
  }
`

export const getAllVideosList = gql`
  query getAllVideosList($input: GetMediaInput!, $searchTerm: String) {
    getAllVideosList(input: $input, searchTerm: $searchTerm) {
      id
      type
      metatype
      url
      title
      description
      mediaOrder
      thumbnail
      createdAt
      updatedAt
      status
      isBanner
    }
  }
`

export const getAllVideos = gql`
  query getAdminVideosList($page: Int, $limit: Int, $searchTerm: String, $type: String) {
    getAdminVideosList(page: $page, limit: $limit, searchTerm: $searchTerm, type: $type) {
      items {
        id
        type
        url {
          id
          url
          meta
          name
        }
        title
        description
        mediaOrder
        thumbnail {
          id
          url
          meta
          name
        }
        createdAt
        updatedAt
        status
        isBanner
      }
      total
    }
  }
`

export const updateVideo = gql`
  mutation updateVideo($input: MarketMediaInput!) {
    updateVideo(input: $input) {
      id
      type
      metatype
      url ${imageSchema}
      title
      description
      mediaOrder
      thumbnail {
        id
        url
        name
      }
      status
    }
  }
`

export const deleteVideo = gql`
  mutation deleteVideo($id: String!) {
    deleteVideo(id: $id) {
      id
    }
  }
`
