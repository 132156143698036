import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Radio, Popover } from 'antd'
import moment from 'moment'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import { History } from 'history'
import { getAllAuctionRoom, changeAuctionRoomStatus } from '../../store/manageAuctionRoom/Action'
import {
  getAuctionRoomInput,
  AuctionRoom,
  AuctionRoomStatusInput,
  AuctionStatusType,
} from '../../store/manageAuctionRoom/Types'
import { TitleSearch } from '../../component/SearchBar'
import { displayDefaultValue, formatPrice } from '../../helpers/helper'
import { getAllBoatPagination, sortEnums } from '../../enums/enums'
import FilterButton from '../filters/FilterButton'

let pageFilterArray: any = []
interface Props {
  history: History
  getAllAuctionRoom: typeof getAllAuctionRoom
  changeAuctionRoomStatus: typeof changeAuctionRoomStatus
  auctionRooms: AuctionRoom[]
  totalAuctionRooms: any
}

interface State {
  columns: Array<Object>
  searchedUsers: any[]
  searchedValue: string
  expandKeys: string[]
}

class ManageAuctionRoomPayment extends Component<Props, State> {
  componentDidMount() {
    // const { getAllAuctionRoom } = this.props
    pageFilterArray = [
      {
        title: 'Auction Start Price',
        filedArray: sortEnums,
        listOptions: 'label',
        passed: 'value',
        actionField: 'startPriceSort',
      },
    ]
    // const input = {
    //   page: getAllBoatPagination.page,
    //   limit: getAllBoatPagination.limit,
    //   status: 'SOLDOUT',
    // }
    // getAllAuctionRoom(input)
  }

  onSearch = (value: any) => {
    const { getAllAuctionRoom } = this.props
    if (value) {
      this.setState({ searchedValue: value })
      getAllAuctionRoom({ ...getAllBoatPagination, searchTerm: value })
    } else {
      this.setState({ searchedValue: '' })
      getAllAuctionRoom(getAllBoatPagination)
    }
  }

  state = {
    searchedUsers: [],
    searchedValue: '',
    expandKeys: [],
    columns: [
      {
        title: 'Boat Model',
        dataIndex: 'boat',
        key: 'boatName',
        render: (text: any) => <span>{displayDefaultValue(text.boatName)}</span>,
      },
      {
        title: 'Ad ID',
        dataIndex: 'boat',
        key: 'adId',
        render: (text: any) => <span>{displayDefaultValue(text.adId)}</span>,
      },
      {
        title: 'Boat Seller',
        dataIndex: 'boat',
        key: 'seller',
        render: (text: any) => (
          <span>
            {text?.seller?.firstName && text?.seller?.lastName
              ? displayDefaultValue(`${text.seller.firstName} ${text.seller.lastName}`)
              : '-'}
          </span>
        ),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Auction Winner',
        dataIndex: 'auctionWinner',
        key: 'auctionWinner',
        render: (text: any) => (
          <span>{text?.firstName && text?.lastName ? `${text.firstName} ${text.firstName}` : 'Not Assigned'}</span>
        ),
      },
      {
        title: 'Original Price',
        dataIndex: 'boat',
        key: 'price',
        render: (text: any) => <span>$ {displayDefaultValue(text.price)}</span>,
      },
      {
        title: 'Deposit Amount',
        dataIndex: 'depositAmount',
        key: 'price',
        render: (text: any) => <span>$ {displayDefaultValue(text)}</span>,
      },
      {
        title: 'Auction Latest Bid Price',
        dataIndex: 'auctionCurrentBid',
        key: 'auctionCurrentBid',
        render: (text: string) => (
          <span>
            {' '}
            {text && '$'} {displayDefaultValue(text)}
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string, record: tableRecords) =>
          text && text !== 'Approval Pending' ? (
            <span className={text === 'Declined' ? 'inactive-status-text' : 'active-status-text'}>{text}</span>
          ) : (
            record.id && (
              <Radio.Group
                defaultValue={1}
                buttonStyle="solid"
                onChange={e =>
                  this.props.changeAuctionRoomStatus({
                    id: record.id ? record.id : '',
                    column: 'status',
                    value: e.target.value,
                  })
                }
              >
                <Popover content="Approve">
                  <Radio.Button value={AuctionStatusType.APPROVE}>Approve</Radio.Button>
                </Popover>
                <Popover content="Reject">
                  <Radio.Button value={AuctionStatusType.DECLINE}>Decline</Radio.Button>
                </Popover>
              </Radio.Group>
            )
          ),
      },
      {
        title: 'Actions',
        dataIndex: 'auctionBids',
        key: 'auctionBids',
        render: (text: string, record: tableRecords) => {
          return (
            <>
              <Button
                className="btn-primary mr-2"
                onClick={() =>
                  this.props.history.push('/manage-auction-room-bids', {
                    auctionId: record.id,
                  })
                }
              >
                {`View bids (${text.length})`}
              </Button>
              <Button
                className="btn-primary mr-2"
                onClick={() =>
                  this.props.history.push('/manage-auction-room-depositors', {
                    auctionId: record.id,
                  })
                }
              >
                {`View depositors (${record?.auctionDepositors?.length})`}
              </Button>
              <Button
                className="btn-view"
                onClick={() =>
                  this.props.history.push('/manage-auction-room-transactions', {
                    auctionId: record.id,
                    adID: displayDefaultValue(record?.boat?.adId),
                  })
                }
              >
                View Transactions
              </Button>
            </>
          )
        },
      },
    ],
  }

  render() {
    const { columns, expandKeys } = this.state
    const { auctionRooms, getAllAuctionRoom, totalAuctionRooms } = this.props

    return (
      <>
        <Card title="Manage Auction Room Payment" extra={<TitleSearch onSearch={this.onSearch} />}>
          <FilterButton filterArray={pageFilterArray} triggerAction={getAllAuctionRoom} />
          <ExpandTable
            columns={columns}
            tableData={auctionRooms}
            expandHtml={false}
            isExpand={false}
            page="boat"
            totalUsersRow={totalAuctionRooms}
            extraInput={{ status: 'SOLDOUT' }}
            pageAction={getAllAuctionRoom}
            expandedRowRender={(record: any) => (
              <div>
                <p>
                  <b>Min. Raised Amount:</b> {`$ ${displayDefaultValue(record.minimumRaisedAmount)}`}
                </p>
                <p>
                  <b>Auction Starting Bid:</b> {`$ ${displayDefaultValue(formatPrice(Number(record.auctionStartingBid), 2))}`}
                </p>
                <p>
                  <b>Auction Current Bid:</b> {`$ ${displayDefaultValue(formatPrice(Number(record.auctionCurrentBid), 2))}`}
                </p>
                <p>
                  <b>Auction Start Date:</b> {`${displayDefaultValue(moment(record.startTime).format('LLL'))}`}
                </p>
                <p>
                  <b>Auction End Date:</b> {`${displayDefaultValue(moment(record.endTime).format('LLL'))}`}
                </p>
                <p>
                  <b>Bid Duration:</b> {`${displayDefaultValue(record.bidDuration)}`}
                </p>
              </div>
            )}
            onExpand={(expanded: boolean, record: tableRecords) => {
              const { id } = record
              if (expanded && id) {
                this.setState({ expandKeys: [id] })
              } else {
                this.setState({ expandKeys: [] })
              }
            }}
            rowKey={(record: tableRecords) => record?.id}
            expandedRowKeys={expandKeys}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  auctionRooms: state.auctionRoomReducer.auctionRooms,
  totalAuctionRooms: state.auctionRoomReducer.totalAuctionRooms,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllAuctionRoom: (data: getAuctionRoomInput) => dispatch(getAllAuctionRoom(data)),
  changeAuctionRoomStatus: (data: AuctionRoomStatusInput) => dispatch(changeAuctionRoomStatus(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAuctionRoomPayment)
