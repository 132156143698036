import gql from 'graphql-tag'

export const getAllUserGuidList = gql`
  query getAllUserGuidList($page: Int, $limit: Int, $isAdmin: Boolean! = false, $searchTerm: String, $status: Boolean) {
    getAllUserGuidList(page: $page, limit: $limit, isAdmin: $isAdmin, searchTerm: $searchTerm, status: $status) {
      items {
        id
        title
        description
        status
      }
      total
    }
  }
`

export const createUserGuidDetail = gql`
  mutation createUserGuidDetail($input: UserGuidInput!) {
    createUserGuidDetail(input: $input) {
      title
      description
    }
  }
`

export const updateUserGuidDetail = gql`
  mutation updateUserGuidDetail($input: UserGuidInput!) {
    updateUserGuidDetail(input: $input) {
      title
      description
    }
  }
`

export const deleteUserGuidDetail = gql`
  mutation deleteUserGuidDetail($id: String!) {
    deleteUserGuidDetail(id: $id) {
      id
    }
  }
`

export const changeUserGuidStatus = gql`
  mutation changeUserGuidStatus($value: Boolean!, $column: String!, $id: String!) {
    changeUserGuidStatus(value: $value, column: $column, id: $id) {
      id
      title
      description
      status
    }
  }
`
