import React from 'react'
import { Divider } from 'antd'

const ShowTitle = (props: any) => {
  const { title } = props
  return (
    <React.Fragment>
      <h3>{title}</h3>
      <Divider className="margin-top-0" />
    </React.Fragment>
  )
}

export default ShowTitle
