import {
  ForgotPasswordState,
  CREATE_FORGOT_PASSWORD,
  GET_FORGOT_PASSWORD,
  CLEAR_FORGOT_PASSWORD_FLAG,
  ActionType,
  GET_FORGOT_PASSWORD_SUCCESS,
  GET_FORGOT_PASSWORD_FAILURE,
  CREATE_FORGOT_PASSWORD_SUCCESS,
  CREATE_FORGOT_PASSWORD_FAILURE,
} from './Type'

const initialState: ForgotPasswordState = {
  passwordContent: [],
  addSuccess: false,
  error: false,
  isLoading: false,
}

export const homeAdvertisementsReducer = (state = initialState, action: ActionType): ForgotPasswordState => {
  switch (action.type) {
    case CREATE_FORGOT_PASSWORD:
      return {
        ...state,
        addSuccess: false,
        error: false,
      }

    case CREATE_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        addSuccess: true,
        error: false,
      }

    case CREATE_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        addSuccess: false,
        error: true,
      }

    case GET_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        passwordContent: action.payload.getManagePasswordContent,
      }

    case GET_FORGOT_PASSWORD:
      return {
        ...state,
        addSuccess: false,
        isLoading: true,
        error: false,
      }

    case GET_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        addSuccess: false,
        isLoading: false,
        error: true,
      }

    case CLEAR_FORGOT_PASSWORD_FLAG:
      return {
        ...state,
        addSuccess: false,
        error: false,
      }

    default:
      return state
  }
}
