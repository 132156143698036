export interface rentTypeState {
  rentTotalRow: number
  tripTypes: LookUp[]

  rentType: rentType
  rentTypes: rentType[]

  rentTypeSuccess: boolean
  rentTypeError: boolean

  deleteRentTypeSuccess: boolean
  deleteRentTypeError: boolean
}

export interface rentType {
  id: string
  name: string
  thumbnailIcon: { url: string }
  tripId: LookUp[]
  createdAt: Date
  updatedAt: Date
}

export interface rentTypeInput {
  id?: string
  name: string
  addMedia: any[]
  removeMedia: string[]
  tripId: string[]
}

export interface paginationType {
  limit?: number
  page?: number
}

export interface LookUp {
  id: string
  alias: string
}

export const GET_ALL_TRIP_TYPES = 'GET_ALL_TRIP_TYPES'
export const GET_ALL_TRIP_TYPES_SUCCESS = 'GET_ALL_TRIP_TYPES_SUCCESS'
export const GET_ALL_TRIP_TYPES_FAILURE = 'GET_ALL_TRIP_TYPES_FAILURE'

export const GET_ALL_RENT_TYPES = 'GET_ALL_RENT_TYPES'
export const GET_ALL_RENT_TYPES_SUCCESS = 'GET_ALL_RENT_TYPES_SUCCESS'
export const GET_ALL_RENT_TYPES_FAILURE = 'GET_ALL_RENT_TYPES_FAILURE'

export const ADD_RENT_TYPE = 'ADD_RENT_TYPE'
export const ADD_RENT_TYPE_SUCCESS = 'ADD_RENT_TYPE_SUCCESS'
export const ADD_RENT_TYPE_FAILURE = 'ADD_RENT_TYPE_FAILURE'

export const GET_SINGLE_RENT_TYPE = 'GET_SINGLE_RENT_TYPE'
export const GET_SINGLE_RENT_TYPE_SUCCESS = 'GET_SINGLE_RENT_TYPE_SUCCESS'
export const GET_SINGLE_RENT_TYPE_FAILURE = 'GET_SINGLE_RENT_TYPE_FAILURE'

export const UPDATE_RENT_TYPE = 'UPDATE_RENT_TYPE'
export const UPDATE_RENT_TYPE_SUCCESS = 'UPDATE_RENT_TYPE_SUCCESS'
export const UPDATE_RENT_TYPE_FAILURE = 'UPDATE_RENT_TYPE_FAILURE'

export const DELETE_RENT_TYPE = 'DELETE_RENT_TYPE'
export const DELETE_RENT_TYPE_SUCCESS = 'DELETE_RENT_TYPE_SUCCESS'
export const DELETE_RENT_TYPE_FAILURE = 'DELETE_RENT_TYPE_FAILURE'

export const CLEAR_RENT_TYPE_FLAG = 'CLEAR_RENT_TYPE_FLAG'

interface getAllTripTypes {
  type: typeof GET_ALL_TRIP_TYPES
  payload: string
}
interface getAllTripTypesSuccess {
  type: typeof GET_ALL_TRIP_TYPES_SUCCESS
  payload: LookUp[]
}
interface getAllTripTypesFailure {
  type: typeof GET_ALL_TRIP_TYPES_FAILURE
  payload: any
}

interface getAllRentTypes {
  type: typeof GET_ALL_RENT_TYPES
  payload: paginationType
}
interface getAllRentTypesSuccess {
  type: typeof GET_ALL_RENT_TYPES_SUCCESS
  payload: any
}
interface getAllRentTypesFailure {
  type: typeof GET_ALL_RENT_TYPES_FAILURE
  payload: any
}

interface createRentType {
  type: typeof ADD_RENT_TYPE
  payload: rentTypeInput
}
interface createRentTypeSuccess {
  type: typeof ADD_RENT_TYPE_SUCCESS
  payload: rentType
}
interface createRentTypeFailure {
  type: typeof ADD_RENT_TYPE_FAILURE
  payload: any
}

interface editRentType {
  type: typeof GET_SINGLE_RENT_TYPE
  payload: string
}
interface editRentTypeSuccess {
  type: typeof GET_SINGLE_RENT_TYPE_SUCCESS
  payload: rentType
}
interface editRentTypeFailure {
  type: typeof GET_SINGLE_RENT_TYPE_FAILURE
  payload: any
}

interface updateRentType {
  type: typeof UPDATE_RENT_TYPE
  payload: rentTypeInput
}
interface updateRentTypeSuccess {
  type: typeof UPDATE_RENT_TYPE_SUCCESS
  payload: rentType
}
interface updateRentTypeFailure {
  type: typeof UPDATE_RENT_TYPE_FAILURE
  payload: any
}

interface deleteRentType {
  type: typeof DELETE_RENT_TYPE
  payload: string
}
interface deleteRentTypeSuccess {
  type: typeof DELETE_RENT_TYPE_SUCCESS
  payload: rentType
}
interface deleteRentTypeFailure {
  type: typeof DELETE_RENT_TYPE_FAILURE
  payload: any
}

interface clearRentTypeFlag {
  type: typeof CLEAR_RENT_TYPE_FLAG
  payload: any
}

export type ActionType =
  | getAllTripTypes
  | getAllTripTypesSuccess
  | getAllTripTypesFailure
  | getAllRentTypes
  | getAllRentTypesSuccess
  | getAllRentTypesFailure
  | createRentType
  | createRentTypeSuccess
  | createRentTypeFailure
  | editRentType
  | editRentTypeSuccess
  | editRentTypeFailure
  | updateRentType
  | updateRentTypeSuccess
  | updateRentTypeFailure
  | deleteRentType
  | deleteRentTypeSuccess
  | deleteRentTypeFailure
  | clearRentTypeFlag
