import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import FormTable, { tableRecords } from './FormTable'
import { displayDefaultValue } from '../../helpers/helper'
import { getNewsMultimediaAction, deleteMultiMediaAction } from '../../store/form/Actions'
import { getAllBoatPagination } from '../../enums/enums'

interface Props {
  getNewsMultimediaAction: typeof getNewsMultimediaAction
  deleteMultiMediaAction: typeof deleteMultiMediaAction
  newsAndMultimedia: any[]
  newsAndMultimediaTotalRow: number
}

class Multimedia extends Component<Props> {
  columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: any) => {
        return <div>{displayDefaultValue(text && text.name)}</div>
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: any, record: tableRecords) => {
        return <div>{`${text} ${record.lastName}`}</div>
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Media Website',
      dataIndex: 'mediaWebsite',
      key: 'mediaWebsite',
      render: (text: any) => {
        return <div>{text.alias}</div>
      },
    },
    {
      title: 'Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]

  componentDidMount() {
    const { getNewsMultimediaAction } = this.props
    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    }
    getNewsMultimediaAction(input)
  }

  render() {
    const { deleteMultiMediaAction, getNewsMultimediaAction, newsAndMultimediaTotalRow, newsAndMultimedia } = this.props
    return (
      <FormTable
        tableTitle="News & Multimedia"
        renderColumn={this.columns}
        getListAction={getNewsMultimediaAction}
        totalUsersRow={newsAndMultimediaTotalRow}
        tableList={newsAndMultimedia}
        deleteAction={deleteMultiMediaAction}
        formModule="multimediaModule"
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    newsAndMultimedia: state.feedbackReducer.newsAndMultimedia,
    newsAndMultimediaTotalRow: state.feedbackReducer.newsAndMultimediaTotalRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getNewsMultimediaAction: (data: any) => dispatch(getNewsMultimediaAction(data)),
    deleteMultiMediaAction: (data: any) => dispatch(deleteMultiMediaAction(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Multimedia)
