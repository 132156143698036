import gql from 'graphql-tag'

export const createManageBanner = gql`
  mutation createBanner($input: MarketMediaInput!) {
    createBanner(input: $input) {
      id
      type
      description
      mediaPosition
      metatype
      url {
        id
        url
      }
      thumbnail {
        id
        url
      }
      mediaOrder
    }
  }
`

export const getBannerHome = gql`
  query getBanner($input: MarketMediaInput!) {
    getBanner(input: $input) {
      id
      type

      metatype
      url {
        id
        url
      }
      title
      mediaOrder
    }
  }
`

export const updateHomeBannerQuery = gql`
  mutation updateBanner($input: MarketMediaInput!) {
    updateBanner(input: $input) {
      id
      type
      mediaPosition
      metatype
      url {
        id
        url
      }
      title
      mediaOrder
    }
  }
`
export const deleteHomeBannerQuery = gql`
  mutation removeBanner($id: String!) {
    removeBanner(id: $id) {
      id
    }
  }
`

export const getBannerByModuleQuery = gql`
  query getBannerByModule($input: GetMarketMediaInput!, $searchTerm: String, $page: Int, $limit: Int) {
    getBannerByModule(input: $input, searchTerm: $searchTerm, page: $page, limit: $limit) {
      items {
        id
        type
        description
        mediaPosition
        metatype
        link
        url {
          id
          url
          name
          documentType {
            typeId {
              type
              id
            }
            alias
            id
            lookUpName
            isActive
          }
          meta
        }
        role
        title
        mediaOrder
        thumbnail {
          id
          url
          name
          documentType {
            typeId {
              type
              id
            }
            alias
            id
            lookUpName
            isActive
          }
          meta
        }
        isBanner
      }
      total
    }
  }
`
