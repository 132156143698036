import gql from 'graphql-tag'

export const getAllPageInformations = gql`
  query getAllPageInformationByType($input: PageGetInput!) {
    getAllPageInformationByType(input: $input) {
      id
      uiType
      module
      header
      content
    }
  }
`

export const createPageInformations = gql`
  mutation createPageInformations($input: PageInformationsInput!) {
    createPageInformations(input: $input) {
      uiType
      module
      header
      content
      id
    }
  }
`
