import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch } from 'antd'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import {
  getManageArticles,
  changeArticleStatus,
  deleteArticle,
  clearArticleFlag,
} from '../../../store/staticContent/manageArticles/Actions'

import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData, formateDate, removePTag } from '../../../helpers/helper'
import {
  getAllBoatPagination,
  paginationPage,
  defaultImage,
  rejectBoatAd,
  fillField,
  activeInactive,
} from '../../../enums/enums'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import CommonModal from '../../../component/modal/CommonModal'
import WSField from '../../../component/Field'
import WSToolTip from '../../../component/WsToolTip'
import Text from 'antd/lib/typography/Text'
import NotificationWithIcon from '../../../component/notification/Notification'
import { RejectionMailEditor } from '../../../component/RejectionMailEditor'
import { articleInput } from '../../../store/staticContent/manageArticles/Types'
import FilterButton from '../../filters/FilterButton'

export interface Props {
  getManageArticles: typeof getManageArticles
  changeArticleStatus: typeof changeArticleStatus
  articles: []
  totalArticlesRow: number
  success: boolean
  deleteSuccess: boolean
  error: boolean
  deleteArticle: typeof deleteArticle
  clearArticleFlag: typeof clearArticleFlag
}
let pageFilterArray: any = []
export interface State {
  columns: []
  searchedValue: string
  expandKeys: string[]
  currentRecord: any
  modalVisible: boolean
  error: boolean
  rejectMessage: string
}

class ManageArticles extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      columns: [],
      searchedValue: '',
      expandKeys: [],
      currentRecord: {},
      modalVisible: false,
      error: false,
      rejectMessage: '',
    }
  }

  componentDidMount() {
    // const { getManageArticles } = this.props
    // const input = {
    //   page: getAllBoatPagination.page,
    //   limit: getAllBoatPagination.limit,
    // }
    pageFilterArray = [
      {
        title: 'Approval Status',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'articleApproved',
      },
    ]
    // getManageArticles(input)
  }

  setModalVisible = (data: boolean, record: tableRecords) => {
    this.setState({
      modalVisible: data,
      currentRecord: record,
    })
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (nextProps, prevState) => {
    const { clearArticleFlag, deleteSuccess } = nextProps
    if (deleteSuccess) {
      clearArticleFlag()
      return { modalVisible: false, rejectMessage: '' }
    }
    return null
  }

  handleChange = (data: string) => {
    this.setState({
      rejectMessage: data,
    })
  }

  showHideError = (data: boolean) => {
    this.setState({
      error: data,
    })
  }

  onSubmit = () => {
    const { deleteArticle } = this.props
    const { currentRecord, rejectMessage } = this.state
    if (rejectMessage.length) {
      deleteArticle({
        id: currentRecord.id,
        columnName: 'isDeleted',
        value: true,
        note: removePTag(rejectMessage),
      })
    } else {
      NotificationWithIcon('error', fillField)
    }
  }

  columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text: string) => <div className="column-width">{text}</div>,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: 'Image',
      dataIndex: 'files',
      key: 'files',
      render: (text: any[]) => (
        <img src={text && text.length ? text[0].url : defaultImage.image} alt="article" style={{ height: 80, width: 80 }}></img>
      ),
    },
    {
      title: 'Added By',
      dataIndex: 'user',
      key: 'user',
      render: (text: any) => <div>{text && `${text.firstName} ${text.lastName}`}</div>,
    },
    {
      title: 'Added On',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any) => <div>{formateDate(text)}</div>,
    },
    {
      title: 'Approval',
      dataIndex: 'articleApproved',
      key: 'articleApproved',
      render: (text: string, record: tableRecords, index: number) => (
        <Switch
          checked={record.articleApproved}
          onChange={() => {
            record &&
              record.id &&
              this.props.changeArticleStatus({
                id: record.id,
                value: !record.articleApproved,
                columnName: 'articleApproved',
              })
          }}
          checkedChildren="Active"
          unCheckedChildren="Block"
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'delete',
      key: 'delete',
      render: (text: string, record: any) => (
        <Button onClick={() => this.setModalVisible(true, record)} className="btn-delete">
          Delete
        </Button>
      ),
    },
  ]

  onSearch = (value: any) => {
    console.log('on search', value)
    const { getManageArticles } = this.props
    if (value) {
      this.setState({searchedValue: value})
      console.log('in if')
      getManageArticles({ ...getAllBoatPagination, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getManageArticles({ ...getAllBoatPagination })
    }
  }

  createMarkup = (text: string) => {
    return { __html: text }
  }

  render() {
    const { articles, totalArticlesRow, getManageArticles } = this.props
    const { searchedValue, expandKeys, modalVisible, error, rejectMessage } = this.state

    return (
      <>
        <Card title="Manage Articles" extra={<TitleSearch onSearch={this.onSearch} />}>
          <FilterButton filterArray={pageFilterArray} triggerAction={getManageArticles} />
          <ExpandTable
            columns={this.columns}
            tableData={articles}
            isExpand={false}
            totalUsersRow={totalArticlesRow}
            pageAction={getManageArticles}
            page={paginationPage.boat}
            expandable={{
              expandedRowRender: (record: any) => (
                <div className="column-width">
                  <b>Description:</b> <div dangerouslySetInnerHTML={this.createMarkup(record.description)} />
                  <b>Article:</b> <div>{record.shortDescription}</div>
                </div>
              ),
            }}
            onExpand={(expanded: boolean, record: tableRecords) => {
              const { id } = record
              if (expanded && id) {
                this.setState({ expandKeys: [id] })
              } else {
                this.setState({ expandKeys: [] })
              }
            }}
            rowKey={(record: tableRecords) => record?.id}
            expandedRowKeys={expandKeys}
            extraInput={{ searchTerm: searchedValue }}

          />
        </Card>
        {modalVisible && (
          <CommonModal
            customWidth={'60%'}
            okText="Submit"
            isFlag={modalVisible}
            title="Reason Of Rejection"
            handleCancel={() => this.setModalVisible(false, { id: '' })}
            footerProp={{ footer: null }}
          >
            <RejectionMailEditor
              onClick={() => (rejectMessage ? this.onSubmit() : this.showHideError(true))}
              errorMessage={error}
              onChangeText={(evt: any) => {
                error && this.showHideError(false)
                this.handleChange(evt.editor.getData())
              }}
            />
          </CommonModal>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  articles: state.manageArticleReducer.articles,
  totalArticlesRow: state.manageArticleReducer.totalArticlesRow,
  success: state.manageArticleReducer.success,
  error: state.manageArticleReducer.error,
  deleteSuccess: state.manageArticleReducer.deleteSuccess,
  deleteError: state.manageArticleReducer.deleteError,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getManageArticles: (data: any) => dispatch(getManageArticles(data)),
  changeArticleStatus: (data: string) => dispatch(changeArticleStatus(data)),
  deleteArticle: (data: articleInput) => dispatch(deleteArticle(data)),
  clearArticleFlag: () => dispatch(clearArticleFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageArticles)
