export interface jobState {
  job: jobData[]
  jobSuccess: boolean
  jobError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  jobTotalRow: number
  statusChangeSuccess: boolean
  statusChangeFailure: boolean
  jobsContent: JobsContent
  jobsApplication?: appliedJobs[]
  totalApplications: number
}

export interface getApplicationInput {
  jobId: string
}

export interface appliedJobs {
  id: string
  country?: {
    id?: string
    name?: string
  }
  appliedFor?: string
  jobReference?: string
  fullName?: string
  coverLetter?: {
    id?: string
    url?: string
  }
  cvUrl?: {
    id?: string
    url?: string
  }
  email?: string
  experience?: string
}

export interface JobsContent {
  id?: string
  title?: string
  description: string
}
export interface jobData {
  addMedia: any[] | undefined
  country?: string
  city?: string
  title: string
  description: string
  reference: string
  salaryPackage: string
  candidateProfile: string
  applicationInformation: string
  documentUrl?: string
  status: boolean
  __typename?: string
  cvCount?: string
  id?: string
}

export interface jobStatusInput {
  value: boolean
  column: string
  id: string
}

export const GET_ALL_JOBS = 'GET_ALL_JOBS'
export const GET_ALL_JOBS_SUCCESS = 'GET_ALL_JOBS_SUCCESS'
export const GET_ALL_JOBS_FAILURE = 'GET_ALL_JOBS_FAILURE'

export const CREATE_JOBS = 'CREATE_JOBS'
export const CREATE_JOBS_SUCCESS = 'CREATE_JOBS_SUCCESS'
export const CREATE_JOBS_FAILURE = 'CREATE_JOBS_FAILURE'

export const UPDATE_JOBS = 'UPDATE_JOBS'
export const UPDATE_JOBS_SUCCESS = 'UPDATE_JOBS_SUCCESS'
export const UPDATE_JOBS_FAILURE = 'UPDATE_JOBS_FAILURE'

export const DELETE_JOBS = 'DELETE_JOBS'
export const DELETE_JOBS_SUCCESS = 'DELETE_JOBS_SUCCESS'
export const DELETE_JOBS_FAILURE = 'DELETE_JOBS_FAILURE'

export const CLEAR_JOBS_FLAG = 'CLEAR_JOBS_FLAG'

export const CHANGE_JOB_STATUS = 'CHANGE_JOB_STATUS'
export const CHANGE_JOB_STATUS_SUCCESS = 'CHANGE_JOB_STATUS_SUCCESS'
export const CHANGE_JOB_STATUS_FAILURE = 'CHANGE_JOB_STATUS_FAILURE'

export const GET_JOBS_CONTENT = 'GET_JOBS_CONTENT'
export const GET_JOBS_CONTENT_SUCCESS = 'GET_JOBS_CONTENT_SUCCESS'
export const GET_JOBS_CONTENT_FAILURE = 'GET_JOBS_CONTENT_FAILURE'

export const CREATE_OR_UPDATE_JOB_CONTENT = 'CREATE_OR_UPDATE_JOB_CONTENT'
export const CREATE_OR_UPDATE_JOB_CONTENT_SUCCESS = 'CREATE_OR_UPDATE_JOB_CONTENT_SUCCESS'
export const CREATE_OR_UPDATE_JOB_CONTENT_FAILURE = 'CREATE_OR_UPDATE_JOB_CONTENT_FAILURE'

export const GET_JOBS_APPLICATIONS = 'GET_JOBS_APPLICATIONS'
export const GET_JOBS_APPLICATIONS_SUCCESS = 'GET_JOBS_APPLICATIONS_SUCCESS'
export const GET_JOBS_APPLICATIONS_FAILURE = 'GET_JOBS_APPLICATIONS_FAILURE'

interface getAllJobs {
  type: typeof GET_ALL_JOBS
  payload: jobData
}

interface getAllJobsSuccess {
  type: typeof GET_ALL_JOBS_SUCCESS
  payload: any
}

interface getAllJobsFailure {
  type: typeof GET_ALL_JOBS_FAILURE
  payload: any
}

interface createJobs {
  type: typeof CREATE_JOBS
  payload: jobData
}

interface createJobsSuccess {
  type: typeof CREATE_JOBS_SUCCESS
  payload: any
}

interface createJobsFailure {
  type: typeof CREATE_JOBS_FAILURE
  payload: any
}

interface updateJobs {
  type: typeof UPDATE_JOBS
  payload: jobData
}

interface updateJobsSuccess {
  type: typeof UPDATE_JOBS_SUCCESS
  payload: jobData
}

interface updateJobsFailure {
  type: typeof UPDATE_JOBS_FAILURE
  payload: jobData
}

interface deleteJobs {
  type: typeof DELETE_JOBS
  payload: jobData
}

interface deleteJobsSuccess {
  type: typeof DELETE_JOBS_SUCCESS
  payload: jobData
}

interface deleteJobsFailure {
  type: typeof DELETE_JOBS_FAILURE
  payload: jobData
}

interface clearJobsFlag {
  type: typeof CLEAR_JOBS_FLAG
  payload: any
}

interface changeJobStatus {
  type: typeof CHANGE_JOB_STATUS
  payload: jobStatusInput
}

interface changeJobStatusSuccess {
  type: typeof CHANGE_JOB_STATUS_SUCCESS
  payload: jobData
}

interface changeJobStatusFailure {
  type: typeof CHANGE_JOB_STATUS_FAILURE
  payload: any
}

interface getJobsContent {
  type: typeof GET_JOBS_CONTENT
  payload: any
}
interface getJobsContentSuccess {
  type: typeof GET_JOBS_CONTENT_SUCCESS
  payload: JobsContent
}
interface getJobsContentFailure {
  type: typeof GET_JOBS_CONTENT_FAILURE
  payload: any
}

interface createOrUpdateJobContent {
  type: typeof CREATE_OR_UPDATE_JOB_CONTENT
  payload: JobsContent
}
interface createOrUpdateJobContentSuccess {
  type: typeof CREATE_OR_UPDATE_JOB_CONTENT_SUCCESS
  payload: JobsContent
}
interface createOrUpdateJobContentFailure {
  type: typeof CREATE_OR_UPDATE_JOB_CONTENT_FAILURE
  payload: any
}

interface getJobApplications {
  type: typeof GET_JOBS_APPLICATIONS
  payload?: String
}

interface getJobApplicationSuccess {
  type: typeof GET_JOBS_APPLICATIONS_SUCCESS
  payload: { items: appliedJobs[]; total: number }
}

interface getJobApplicationFailure {
  type: typeof GET_JOBS_APPLICATIONS_FAILURE
  payload?: any
}

export type ActionType =
  | getAllJobs
  | getAllJobsSuccess
  | getAllJobsFailure
  | createJobs
  | createJobsSuccess
  | createJobsFailure
  | updateJobs
  | updateJobsSuccess
  | updateJobsFailure
  | deleteJobs
  | deleteJobsSuccess
  | deleteJobsFailure
  | clearJobsFlag
  | changeJobStatus
  | changeJobStatusSuccess
  | changeJobStatusFailure
  | getJobsContent
  | getJobsContentSuccess
  | getJobsContentFailure
  | createOrUpdateJobContent
  | createOrUpdateJobContentSuccess
  | createOrUpdateJobContentFailure
  | getJobApplications
  | getJobApplicationSuccess
  | getJobApplicationFailure
