import React, { Component } from 'react'

import CreateBannerIndex from '../CreateBannerIndex'
import History from '../../../History'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  location: any
  history: typeof History
}

class CreateMarketBoatMedia extends Component<Props> {
  render() {
    const { location, history } = this.props
    return (
      <CreateBannerIndex
        history={history}
        backLink="/boat-media-banners"
        location={location}
        pageTitle="Boat Media Banner"
        titlePage="Boat Media"
        type={mediaCategory.boatMedia.type}
      />
    )
  }
}

export default CreateMarketBoatMedia
