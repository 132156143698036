import gql from 'graphql-tag'

export const getSalesEngines = gql`
  query getSalesEngines($type: salesEngineTypeArg!, $limit: Int, $page: Int) {
    getSalesEngines(type: $type, page: $page, limit: $limit) {
      items {
        id
        boat {
          boatName
          boatStatus {
            id
            alias
          }
        }
        buyer {
          firstName
          lastName
          country
        }
        buySellProcess

        buyerReviewSurveyReport

        requestedSurveyor
        surveyor {
          contactName
          user {
            firstName
            lastName
          }
        }
        createdAt
      }
      total
    }
  }
`

export const getAllSalesEnginPayment = gql`
  query getSalesEngineList($page: Int, $limit: Int, $page_type: String, $searchTerm: String) {
    getSalesEngineList(page: $page, limit: $limit, page_type: $page_type, searchTerm: $searchTerm) {
      items {
        id
        boat {
          adId
          boatName
          boatStatus {
            id
            alias
          }
          price
        }
        buyer {
          firstName
          lastName
          country
        }
        seller {
          firstName
          lastName
        }
        salesEngineStatus
        negotiatedBoatPrice
        hasAmountToRelease
        createdAt
        isAuctionSalesEngine
      }
      total
    }
  }
`

export const getAllSalesEnginCommision = gql`
  query getComissionByModule($searchTerm: String) {
    getComissionByModule(searchTerm: $searchTerm) {
      Manufacture
      BoatOwner
      Survevour
      Shipper
      Agent
      Dealer
      Rent
      Boat_Service_Fee
      Rent_Service_Fee
    }
  }
`

export const getAllSalesEnginReleasePayment = gql`
  query getTransactionsToReleasesMoney($page: Int, $limit: Int, $sales_engine_id: String!) {
    getTransactionsToReleasesMoney(page: $page, limit: $limit, sales_engine_id: $sales_engine_id) {
      items {
        id
        createdAt
        status
        country
        category_id
        sub_category_id
        externalModuleType
        externalId
        transactionType
        paymentId
        isRefundable
        isRefunded
        senderId {
          id
          firstName
          lastName
        }
        receiverId {
          id
          firstName
          lastName
        }
        amount
        depositAmount
        totalAmount
        comission
        amount_to_release
        is_release_amount_paid
        is_release_transaction
        stripeFee
        serviceFee
      }
      total
    }
  }
`

export const releaseMoneyToUser = gql`
  query releaseMoneyToUser($user_id: String!, $transaction_id: String!, $amount: String!) {
    releaseMoneyToUser(user_id: $user_id, transaction_id: $transaction_id, amount: $amount) {
      statusCode
      message
      data
    }
  }
`

export const getAllSalesEngineStaticContent = gql`
  query getAllSalesEngineStaticContent {
    getAllSalesEngineStaticContent {
      id
      content
      contentFor
      stepsFor
      createdAt
    }
  }
`

export const deleteSalesEngine = gql`
  mutation deleteSalesEngine($id: String!) {
    deleteSalesEngine(id: $id) {
      id
    }
  }
`

export const editCommisionSchema = gql`
  mutation UpdateComission($payload: ComissionInputType!) {
    UpdateComission(payload: $payload) {
      success
    }
  }
`

export const createSalesaleEngineStaticContent = gql`
  mutation createSalesEngineStaticContent($input: SaleEngineStaticContextsInformationInput!) {
    createSalesEngineStaticContent(input: $input) {
      id
      content
      contentFor
      stepsFor
      createdAt
    }
  }
`

export const salesEngineRefundPayment = gql`
  mutation refundPayment($id: String!) {
    refundPayment(id: $id) {
      statusCode
      message
      data
    }
  }
`;
