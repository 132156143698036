export const GeometricLocation = `{
    type
    coordinates
}`

export const AddressType = `{
    type
    address1
    address2
    street
    city
    state
    country
    zip
    geometricLocation ${GeometricLocation}
}`

export const imageSchema = `{
    id
    url
}`
