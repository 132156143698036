import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button, Select } from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ReactQuill from 'react-quill'
import * as Yup from 'yup'
import { emailData } from '../../../store/staticContent/emailTemplates/Types'
import { Dispatch } from 'redux'
import { createSmsTemplate, updateSmsTemplate, clearSmsTemplateFlag } from '../../../store/staticContent/smsTemplates/Actions'
import { connect } from 'react-redux'
import { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { CKEditorWrapper } from '../../../component/CKEditor'
import { templateSmsName, TEMPLATESMSNAME, smsTemplate } from '../../../enums/enums'
import { getUniqueArray, removePTag, trimmedValue } from '../../../helpers/helper'
import NotificationWithIcon from '../../../component/notification/Notification'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import WSSelect from '../../../component/WSSelect'
import { PlusCircleOutlined } from '@ant-design/icons'
import { getVariableAction } from '../../../store/staticContent/emailTemplates/Actions'
const { Text } = Typography
const { Option } = Select

interface Props {
  createSmsTemplate: typeof createSmsTemplate
  location: any
  updateSmsTemplate: typeof updateSmsTemplate
  history: typeof History
  smsTemplateSuccess: boolean
  clearSmsTemplateFlag: typeof clearSmsTemplateFlag
  getVariableAction: typeof getVariableAction

  variableList: any[]
}
interface State {
  editEmail: any
  manageEmail: {
    type: string
    subject: string
    body: string
    name: string
  }
}

class CreateSmsTemplate extends Component<Props, State> {
  private variableRef = React.createRef<any>()

  formSubmitHandler = (values: any) => {
    const { editEmail } = this.state
    const { createSmsTemplate, updateSmsTemplate } = this.props
    if (editEmail) {
      const { createdAt, type, updatedAt, name, body, __typename, smtp, ...other } = values

      const nameIs = typeof values.name === 'number' ? smsTemplate[values.name] : values.name
      const input = {
        name: nameIs,
        body: removePTag(values.body),
        ...other,
      }

      updateSmsTemplate(input)
    } else {
      const removeNbsp = values.body.replace('&nbsp;', ' ')
      const input = {
        subject: values.subject,
        name: values.name,
        body: removePTag(removeNbsp),
      }

      createSmsTemplate(input)
    }
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      editEmail: props.location.state ? props.location.state.template : null,
      manageEmail: {
        type: '',
        subject: '',
        body: '',
        name: '',
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { smsTemplateSuccess, clearSmsTemplateFlag, history } = props

    if (smsTemplateSuccess) {
      clearSmsTemplateFlag()
      history.push('/sms-templates')
    }
    return null
  }

  copyCodeToClipboard = (text: any) => {
    navigator.clipboard.writeText(text)
    NotificationWithIcon('success', 'Copied!')
  }

  componentDidMount() {
    const { editEmail } = this.state
    const { getVariableAction } = this.props
    if (editEmail) {
      getVariableAction({ template: smsTemplate[editEmail.name] })
    }
  }

  render() {
    const { manageEmail, editEmail } = this.state
    const { history, getVariableAction, variableList } = this.props

    return (
      <Formik
        initialValues={editEmail || manageEmail}
        onSubmit={values => this.formSubmitHandler(values)}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('SMS Name field is required.'),
          subject: Yup.string().required('SMS subject field is required.'),
        })}
        render={({ errors, status, touched, setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  {editEmail ? 'Edit SMS Template' : 'Create SMS Template'}
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                    Save
                  </button>
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="SMS Subject">
                    SMS Subject
                  </span>
                  <Input
                    name="subject"
                    placeholder="SMS Subject"
                    value={values.subject}
                    onChange={e => setFieldValue('subject', e.target.value)}
                    onBlur={e => setFieldValue('subject', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`subject`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="SMS Name">
                    SMS Name
                  </span>

                  <WSSelect
                    defaultValue={TEMPLATESMSNAME[values.name]}
                    onChangeText={(value: any) => {
                      setFieldValue('name', value)
                      getVariableAction({ template: value })
                    }}
                    arrayList={templateSmsName && templateSmsName}
                    listOptions="name"
                    passed="aliasName"
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`name`} component="span" />
                  </Text>
                </Col>
                {variableList && variableList.length ? (
                  <>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="variable">
                        Select Variable
                      </span>
                      <WSSelect
                        defaultValue={values.metatype}
                        onChangeText={(e: string) => this.copyCodeToClipboard(e)}
                        arrayList={getUniqueArray(variableList, (it: any) => it.name)}
                        listOptions="name"
                        passed="name"
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span title="SMS Body">SMS Body</span>
                  <textarea style={{display:'block',width:'100%',height:100,resize:'none'}} onChange={(e) => setFieldValue('body', e.target.value)}>{values.body}</textarea>
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`body`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  smsTemplate: state.smsTemplateReducer.smsTemplate,
  smsTemplateSuccess: state.smsTemplateReducer.smsTemplateSuccess,
  smsTemplateError: state.smsTemplateReducer.smsTemplateError,
  variableList: state.emailTemplateReducer && state.emailTemplateReducer.variableList,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createSmsTemplate: (data: any) => dispatch(createSmsTemplate(data)),
  getVariableAction: (data: any) => dispatch(getVariableAction(data)),
  updateSmsTemplate: (data: any) => dispatch(updateSmsTemplate(data)),
  clearSmsTemplateFlag: () => dispatch(clearSmsTemplateFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSmsTemplate)
