import {
  CREATE_COUNTRY,
  CREATE_COUNTRY_SUCCESS,
  countryState,
  ActionType,
  CREATE_COUNTRY_FAILURE,
  GET_SINGLE_COUNTRY,
  GET_SINGLE_COUNTRY_SUCCESS,
  GET_SINGLE_COUNTRY_FAILURE,
  CLEAR_COUNTRY_FLAG,
  UPDATE_COUNTRY,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAILURE,
  DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAILURE,
  CHANGE_COUNTRY_STATUS,
  CHANGE_COUNTRY_STATUS_SUCCESS,
  CHANGE_COUNTRY_STATUS_FAILURE,
  SEARCH_COUNTRY,
  SEARCH_COUNTRY_SUCCESS,
} from './Type'
import { stateReducer } from '../manageState/Reducer'
import NotificationWithIcon from '../../../component/notification/Notification'
import { dynamicError } from '../../../helpers/helper'

const initialState: countryState = {
  isLoading: false,
  countrySuccess: false,
  countryError: false,
  countries: [],
  deleteError: false,
  deleteSuccess: false,
  updateError: false,
  updateSuccess: false,
  statusSuccess: false,
  statusError: false,
  countriesTotalRow: 0,
}

export const countryReducer = (state = initialState, action: ActionType): countryState => {
  switch (action.type) {
    case GET_SINGLE_COUNTRY:
      return {
        ...state,
        isLoading: true,
        // countrySuccess: false,
        // countryError: false,
      }
    case GET_SINGLE_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload.data.getAllCountries.items,
        countriesTotalRow: action.payload.data.getAllCountries.total,
        // countrySuccess: false,
        // countryError: false,
      }
    case GET_SINGLE_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        // countries:[]
        // countrySuccess: false,
        // countryError: false,
      }
    case CREATE_COUNTRY:
      return {
        ...state,
        isLoading: true,
        countrySuccess: false,
        countryError: false,
      }
    case CREATE_COUNTRY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        countrySuccess: true,
        countryError: false,
        countries: [action.payload.createCountry, ...state.countries],
      }
    }
    case CREATE_COUNTRY_FAILURE: {
      const errorMessage = dynamicError(action.payload.networkError.result.errors)
      NotificationWithIcon('error', errorMessage.message)
      return {
        ...state,
        isLoading: false,
        countrySuccess: false,
        countryError: true,
        // countries: action.payload
      }
    }
    case CLEAR_COUNTRY_FLAG: {
      return {
        ...state,
        isLoading: false,
        countrySuccess: false,
        countryError: false,
        updateSuccess: false,
        deleteError: false,
        deleteSuccess: false,
      }
    }
    case UPDATE_COUNTRY: {
      return {
        ...state,
        isLoading: true,
        updateError: false,
        updateSuccess: false,
      }
    }
    case UPDATE_COUNTRY_SUCCESS: {
      NotificationWithIcon('success', 'Record Updated.')
      const filterData = state.countries.filter((item: any) => action.payload.data.updateCountry.id !== item.id)

      return {
        ...state,
        isLoading: true,
        updateError: false,
        updateSuccess: true,
        countries: [action.payload.data.updateCountry, ...filterData],
      }
    }
    case UPDATE_COUNTRY_FAILURE: {
      return {
        ...state,
        isLoading: true,
        updateError: true,
        updateSuccess: false,
      }
    }
    case DELETE_COUNTRY: {
      return {
        ...state,
        isLoading: true,
        deleteError: false,
        deleteSuccess: false,
      }
    }
    case DELETE_COUNTRY_SUCCESS: {
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        isLoading: true,
        deleteError: false,
        deleteSuccess: true,
      }
    }
    case DELETE_COUNTRY_FAILURE: {
      return {
        ...state,
        isLoading: true,
        deleteError: true,
        deleteSuccess: false,
      }
    }
    case CHANGE_COUNTRY_STATUS:
      return {
        ...state,
        statusSuccess: false,
        isLoading: false,
        statusError: false,
      }
    case CHANGE_COUNTRY_STATUS_SUCCESS:
      const { countries } = state
      NotificationWithIcon('success', 'Status Changed Successfully')
      return {
        ...state,
        statusSuccess: true,
        isLoading: false,
        statusError: false,
        countries: countries.map((item: any) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              [action.payload.column]: action.payload.status,
            }
          }
          return item
        }),
      }
    case CHANGE_COUNTRY_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        statusSuccess: false,
        isLoading: false,
        statusError: true,
      }

    case SEARCH_COUNTRY:
      return {
        ...state,
        isLoading: true,
      }
    
    case SEARCH_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: action.payload.items,
        countriesTotalRow: action.payload.total,
        isLoading: false
      }
    
    case SEARCH_COUNTRY_SUCCESS:
      return {
          ...state,
          isLoading: false
        }

    default:
      return state
  }
}
