import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { selectOption } from '../../../constants/GlobalEventTypeScript'
import FieldElements from '../../../component/FieldElements'
import { createState, clearStateFlag, updateState } from '../../../store/country/manageState/Action'
import History from '../../../History'
import { stateData } from '../../../store/country/manageState/Type'
import { getCountries } from '../../../store/country/manageCountry/Action'
import { pagination } from '../../../enums/enums'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import WSSelect from '../../../component/WSSelect'
import { trimmedValue } from '../../../helpers/helper'
const { Text } = Typography

export interface Props {
  getCountries: typeof getCountries
  countries: []
  createState: typeof createState
  stateSuccess: boolean
  clearStateFlag: typeof clearStateFlag
  history: typeof History
  updateState: typeof updateState
  location: any
  updateSuccess: boolean
}
export interface State {
  stateProvince: {
    id?: string
    name: string
    stateCode: string
    country: string
  }
  isEdit: boolean
}
const countryData = [
  {
    name: 'India',
    value: 'india',
  },
  {
    name: 'USA',
    value: 'usa',
  },
]
class AddStateProvince extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      stateProvince:
        props.location && props.location.state && props.location.state.stateProvince
          ? {
              id: props.location.state.stateProvince.id,
              name: props.location.state.stateProvince.name,
              stateCode: props.location.state.stateProvince.stateCode,
              country: props.location.state.stateProvince.country.id,
            }
          : {
              name: '',
              stateCode: '',
              country: '',
            },
      isEdit: props.location && props.location.state && props.location.state.isEdit,
    }
  }

  componentDidMount() {
    const { getCountries, countries } = this.props
    const data = {
      page: pagination.page,
      limit: pagination.limit,
    }
    if (!countries.length) {
      getCountries(data)
    }
  }

  formSubmitHandler = (values: stateData) => {
    const { isEdit } = this.state
    const { createState, updateState } = this.props
    if (isEdit) {
      updateState(values)
    } else {
      createState(values)
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { stateSuccess, clearStateFlag, history, updateSuccess } = props

    if (stateSuccess || updateSuccess) {
      clearStateFlag()
      history.push('/manage-state')
    }
    return null
  }

  render() {
    const { stateProvince, isEdit } = this.state
    const { countries, history } = this.props
    return (
      <Fragment>
        <Formik
          initialValues={stateProvince}
          onSubmit={(values: stateData) => {
            this.formSubmitHandler(values)
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('State name field is required.'),
            stateCode: Yup.string().required('State Code field is required.'),
            country: Yup.string().required('Country  field is required.'),
          })}
        >
          {props => {
            const { handleSubmit, setFieldValue, values } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} /> Add State Detail
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                        Save
                      </button>
                    </>
                  }
                >
                  <Row gutter={16}>
                    {countries && countries.length && (
                      <Col className="gutter-box" md={8}>
                        <span className="ant-form-item-required" title="Country">
                          Country
                        </span>
                        <WSSelect
                          name="country"
                          listOptions="name"
                          passed="id"
                          arrayList={countries}
                          onChangeText={(countryId: selectOption[]) => {
                            setFieldValue('country', countryId)
                          }}
                        />
                        <Text type="danger">
                          <ErrorMessage className="invalid-feedback" name={`country`} component="span" />
                        </Text>
                      </Col>
                    )}
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="State Name">
                        State Name
                      </span>
                      <Field
                        className="ant-input"
                        name="name"
                        placeholder="State Name"
                        onBlur={(e: { target: { value: string } }) => setFieldValue('name', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`name`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="State Code">
                        State Code
                      </span>

                      <Field
                        className="ant-input"
                        name="stateCode"
                        placeholder="stateCode"
                        onBlur={(e: { target: { value: string } }) => setFieldValue('stateCode', trimmedValue(e.target.value))}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`stateCode`} component="span" />
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}
const mapStateToProps = (state: any) => ({
  stateSuccess: state.stateReducer && state.stateReducer.stateSuccess,
  updateSuccess: state.stateReducer && state.stateReducer.updateSuccess,
  countries: state.countryReducer && state.countryReducer.countries,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createState: (data: stateData) => dispatch(createState(data)),
  getCountries: (data: any) => dispatch(getCountries(data)),
  clearStateFlag: () => dispatch(clearStateFlag()),
  updateState: (data: stateData) => dispatch(updateState(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddStateProvince)
