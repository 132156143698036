import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../..'
import {
  ActionType,
  CREATE_STATE_SUCCESS,
  CREATE_STATE_FAILURE,
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAILURE,
  CREATE_STATE,
  UPDATE_STATE,
  UPDATE_STATE_SUCCESS,
  UPDATE_STATE_FAILURE,
  DELETE_STATE,
  DELETE_STATE_SUCCESS,
  DELETE_STATE_FAILURE,
} from './Type'
import { createState, getAllState, updateState, deleteState } from './Schema'

function deleteStateApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteState,
      variables: { id },
    })
    
}

function createStateApi(input: any) {
  return client
    .mutate({
      mutation: createState,
      variables: { input },
    })
    
}

function getStatesApi(data: any) {
  return client
    .query({
      query: getAllState,
      variables: data,
      fetchPolicy: 'no-cache',
    })
    
}

function updateStateApi(data: any) {
  const input = {
    id: data.id,
    country: data.country,
    name: data.name,
    stateCode: data.stateCode,
  }
  return client
    .mutate({
      mutation: updateState,
      variables: { state: input },
    })
    
}

function* deleteStateData(action: ActionType) {
  try {
    const res = yield deleteStateApi(action)
    yield put({ type: DELETE_STATE_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_STATE_FAILURE, error })
  }
}

function* createCountryData(action: ActionType) {
  try {
    const res = yield createStateApi(action.payload)
    yield put({ type: CREATE_STATE_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_STATE_FAILURE, error })
  }
}

function* getStatesData(data: any) {
  try {
    const res = yield getStatesApi(data.payload)
    yield put({ type: GET_STATE_SUCCESS, payload: res })
  } catch (error) {
    console.log(error)
    yield put({ type: GET_STATE_FAILURE, error })
  }
}

export function* updateStateData(action: ActionType) {
  try {
    const data = yield updateStateApi(action.payload)
    yield put({ type: UPDATE_STATE_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_STATE_FAILURE, error })
  }
}

function* deleteStateSaga() {
  yield takeLatest(DELETE_STATE, deleteStateData)
}

function* createStateSaga() {
  yield takeLatest(CREATE_STATE, createCountryData)
}

function* getStates() {
  yield takeLatest(GET_STATE, getStatesData)
}

function* updateStateSaga() {
  yield takeLatest(UPDATE_STATE, updateStateData)
}

export default function* stateSaga() {
  yield all([deleteStateSaga(), createStateSaga(), getStates(), updateStateSaga()])
}
