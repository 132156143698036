import { GET_ADMIN_USERS_COUNT, CLEAR_ADMIN_USERS_COUNT_FLAG } from './Type'

export function getAdminUsersCount() {
  return {
    type: GET_ADMIN_USERS_COUNT,
  }
}

export function clearAdminUsersFlag() {
  return {
    type: CLEAR_ADMIN_USERS_COUNT_FLAG,
  }
}
