export const GET_ADMIN_USER_ROLE = 'GET_ADMIN_USER_ROLE'
export const GET_ADMIN_USER_ROLE_SUCCESS = 'GET_ADMIN_USER_ROLE_SUCCESS'
export const GET_ADMIN_USER_ROLE_FAILURE = 'GET_ADMIN_USER_ROLE_FAILURE'

export const CREATE_ADMIN_USER_ROLE = 'CREATE_ADMIN_USER_ROLE'
export const CREATE_ADMIN_USER_ROLE_SUCCESS = 'CREATE_ADMIN_USER_ROLE_SUCCESS'
export const CREATE_ADMIN_USER_ROLE_FAILURE = 'CREATE_ADMIN_USER_ROLE_FAILURE'

export const CLEAR_ADMIN_USER_ROLE_FLAG = 'CLEAR_ADMIN_USER_ROLE_FLAG'

export const DELETE_ADMIN_USER_ROLE = 'DELETE_ADMIN_USER_ROLE'
export const DELETE_ADMIN_USER_ROLE_SUCCESS = 'DELETE_ADMIN_USER_ROLE_SUCCESS'
export const DELETE_ADMIN_USER_ROLE_FAILURE = 'DELETE_ADMIN_USER_ROLE_FAILURE'

//get users by type
export const GET_FRONT_USERS = 'GET_FRONT_USERS'
export const GET_FRONT_USERS_SUCCESS = 'GET_FRONT_USERS_SUCCESS'
export const GET_FRONT_USERS_FAILURE = 'GET_FRONT_USERS_FAILURE'

export const CHANGE_ACCOUNT_STATUS = 'CHANGE_ACCOUNT_STATUS'
export const CHANGE_ACCOUNT_STATUS_SUCCESS = 'CHANGE_ACCOUNT_STATUS_SUCCESS'
export const CHANGE_ACCOUNT_STATUS_FAILURE = 'CHANGE_ACCOUNT_STATUS_FAILURE'

//get review
export const GET_FLAG_COMPLAINT = 'GET_FLAG_COMPLAINT'
export const GET_FLAG_COMPLAINT_SUCCESS = 'GET_FLAG_COMPLAINT_SUCCESS'
export const GET_FLAG_COMPLAINT_FAILURE = 'GET_FLAG_COMPLAINT_FAILURE'

//delete COMPLAINT
export const DELETE_COMPLAINT = 'DELETE_COMPLAINT'
export const DELETE_COMPLAINT_SUCCESS = 'DELETE_COMPLAINT_SUCCESS'
export const DELETE_COMPLAINT_FAILURE = 'DELETE_COMPLAINT_FAILURE'

export const SEARCH_USER = 'SEARCH_USER'
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS'
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE'

export interface adminUserRoleState {
  adminUserRole: any[]
  isLoading: boolean
  deleteComplaintLoad: boolean
  complaintDeleteSuccess: boolean
  complaintDeleteFail: boolean
  frontLoad: boolean
  totalUserRow: any
  adminUserRoleSuccess: boolean
  adminUserRoleFailure: boolean
  deleteAdminUserSuccess: boolean
  deleteAdminUserFailure: boolean
  success: boolean
  accountStatusSuccess: boolean
  error: boolean
  accountStatusError: boolean
  users: any[]
  totalUsersRow: number
  usersReview: any[]
  adminLoad: boolean
  totalUsersReviewRow: number
}

export interface adminUserRoleData {
  id?: string
  firstName: string
  lastName: string
  address: any[]
  email: string
  password?: string
  userType: string
  mobileNumber: string
  permissions: [string]
}

interface deleteComplaint {
  type: typeof DELETE_COMPLAINT
  payload: any
}
interface deleteComplaintSuccess {
  type: typeof DELETE_COMPLAINT_SUCCESS
  payload: any
}
interface deleteComplaintFailure {
  type: typeof DELETE_COMPLAINT_FAILURE
  payload: string
}

interface createAdminUserRole {
  type: typeof CREATE_ADMIN_USER_ROLE
  payload: adminUserRoleData
}

interface createAdminUserRoleSuccess {
  type: typeof CREATE_ADMIN_USER_ROLE_SUCCESS
  payload: adminUserRoleData
}

interface createAdminUserRoleFailure {
  error: any
  type: typeof CREATE_ADMIN_USER_ROLE_FAILURE
  payload: string
}

interface getAdminUserRole {
  type: typeof GET_ADMIN_USER_ROLE
  payload: any
}

interface getAdminUserRoleSuccess {
  type: typeof GET_ADMIN_USER_ROLE_SUCCESS
  payload: any
}

interface getAdminUserRoleFailure {
  type: typeof GET_ADMIN_USER_ROLE_FAILURE
  payload: any
}

interface clearAdminUserRoleFlag {
  type: typeof CLEAR_ADMIN_USER_ROLE_FLAG
  payload: any
}

interface deleteAdminUserRole {
  type: typeof DELETE_ADMIN_USER_ROLE
  payload: any
}

interface deleteAdminUserRoleSuccess {
  type: typeof DELETE_ADMIN_USER_ROLE_SUCCESS
  payload: any
}

interface deleteAdminUserRoleFailure {
  type: typeof DELETE_ADMIN_USER_ROLE_FAILURE
  payload: any
}

interface getFrontUsers {
  type: typeof GET_FRONT_USERS
  payload: string
}
interface getFrontUsersSuccess {
  type: typeof GET_FRONT_USERS_SUCCESS
  payload: any
}
interface getFrontUsersFailure {
  type: typeof GET_FRONT_USERS_FAILURE
  payload: string
}

interface getFlagComplaint {
  type: typeof GET_FLAG_COMPLAINT
  payload: string
}
interface getFlagComplaintSuccess {
  type: typeof GET_FLAG_COMPLAINT_SUCCESS
  payload: any
}
interface getFlagComplaintFailure {
  type: typeof GET_FLAG_COMPLAINT_FAILURE
  payload: string
}

interface changeAccountStatus {
  type: typeof CHANGE_ACCOUNT_STATUS
  payload: any
}

interface changeAccountStatusSuccess {
  type: typeof CHANGE_ACCOUNT_STATUS_SUCCESS
  payload: any
}

interface changeAccountStatusFailure {
  type: typeof CHANGE_ACCOUNT_STATUS_FAILURE
}

interface searchUser {
  type: typeof SEARCH_USER
  payload: any
}

interface searchUserSuccess {
  type: typeof SEARCH_USER_SUCCESS
  payload: any
}

interface searchUserFailure {
  type: typeof SEARCH_USER_FAILURE
  payload: any
}

export type ActionType =
  | getAdminUserRole
  | getAdminUserRoleSuccess
  | getAdminUserRoleFailure
  | createAdminUserRole
  | createAdminUserRoleSuccess
  | createAdminUserRoleFailure
  | clearAdminUserRoleFlag
  | deleteAdminUserRole
  | deleteAdminUserRoleSuccess
  | deleteAdminUserRoleFailure
  | getFrontUsers
  | getFrontUsersSuccess
  | getFrontUsersFailure
  | changeAccountStatus
  | changeAccountStatusSuccess
  | changeAccountStatusFailure
  | getFlagComplaint
  | getFlagComplaintSuccess
  | getFlagComplaintFailure
  | deleteComplaint
  | deleteComplaintSuccess
  | deleteComplaintFailure
  | searchUser
  | searchUserSuccess
  | searchUserFailure
