import {
  agreementsCountState,
  ActionType,
  GET_AGREEMENTS_COUNT,
  GET_AGREEMENTS_COUNT_SUCCESS,
  GET_AGREEMENTS_COUNT_FAILURE,
} from './Type'

const initialState: agreementsCountState = {
  agreementsCount: {},
  agreementCountSuccess: false,
  agreementCountFailure: false,
}

export const agreementsCountReducer = (state = initialState, action: ActionType): agreementsCountState => {
  switch (action.type) {
    case GET_AGREEMENTS_COUNT:
      return {
        ...state,
        agreementCountSuccess: false,
        agreementCountFailure: false,
      }

    case GET_AGREEMENTS_COUNT_SUCCESS:
      let all: any = action.payload.getAgreementsCounts.agreementCounts.all.sort(function (x: any, y: any) {
        return x.counts - y.counts
      })
      let pending: any = action.payload.getAgreementsCounts.agreementCounts.pending

      return {
        ...state,
        agreementCountSuccess: true,
        agreementCountFailure: false,
        agreementsCount: { all, pending },
      }

    case GET_AGREEMENTS_COUNT_FAILURE:
      return {
        ...state,
        agreementCountSuccess: false,
        agreementCountFailure: true,
      }

    default:
      return state
  }
}
