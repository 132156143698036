import gql from 'graphql-tag'
import { AddressType } from '../../schemaTypes'

export const getUserDocuments = gql`
  query getUserDocuments($page: Int, $limit: Int, $role: String, $country: String, $governmentIdVerified: Boolean, $commercialLicenceVerified: Boolean, $isAdmin: Boolean = true) {
    getUserDocuments(page: $page, limit: $limit, role: $role, country: $country, governmentIdVerified: $governmentIdVerified, commercialLicenceVerified: $commercialLicenceVerified, isAdmin: $isAdmin) {
      items {
        address ${AddressType}
        id
        firstName
        lastName
        isVerified
        userStatus
        accountActivated
        email
        governmentId {
          id
          url
          largeUrl
          meta
        }
        role {
          id
          role
          aliasName
        }
        updatedAt
        commercialLicence {
          id
          url
          largeUrl
          meta
        }
        documentVerification {
          emailVerified
          mobileVerified
          governmentIdVerified
          governmentIdRejected
          commercialLicenceVerified
          commercialLicenceRejected
        }
      }
      total
    }
  }
`

export const verifyUserDocuments = gql`
  mutation verifyUserDocuments($value: Boolean!, $document: String!, $id: String!) {
    verifyUserDocuments(value: $value, document: $document, id: $id) {
      id
      documentVerification {
        emailVerified
        mobileVerified
        governmentIdVerified
        governmentIdRejected
        commercialLicenceVerified
        commercialLicenceRejected
      }
    }
  }
`

export const getDocumentUrl = gql`
  query getDocumentUrl($mediaId: String!) {
    getDocumentUrl(mediaId: $mediaId)
  }
`
