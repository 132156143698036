import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import { History } from 'history'
import { getAllSalesEnginPayment } from '../../store/salesEngine/manageSalesEngine/Action'
import { getSalesEnginPaymentInput, SalesEnginPayment } from '../../store/salesEngine/manageSalesEngine/Type'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData, displayDefaultValue, formatPrice } from '../../helpers/helper'
import { getAllBoatPagination } from '../../enums/enums'

interface Props {
  history: History
  getAllSalesEnginPayment: typeof getAllSalesEnginPayment
  salesEnginPayment: SalesEnginPayment[]
  salesEnginPaymentTotalRow: any
}

interface State {
  columns: Array<Object>
  searchedUsers: any[]
  searchedValue: string
}

class SalesEnginProgress extends Component<Props, State> {
  // componentDidMount() {
  //   const { getAllSalesEnginPayment } = this.props

  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //     page_type: '0',
  //   }
  //   getAllSalesEnginPayment(input)
  // }

  onSearch = (value: any) => {
    this.setState({
      searchedValue: value,
    })
  }

  state = {
    searchedUsers: [],
    searchedValue: '',
    columns: [
      {
        title: 'Boat Model',
        dataIndex: 'boat',
        key: 'boat',
        render: (text: any) => <span>{text.boatName}</span>,
      },
      {
        title: 'Ad ID',
        dataIndex: 'boat',
        key: 'boat',
        render: (text: any) => <span>{text.adId}</span>,
      },
      {
        title: 'Sales Engin ID',
        dataIndex: 'id',
        key: 'id',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: 'Boat Seller',
        dataIndex: 'seller',
        key: 'seller',
        render: (text: any) => {
          return text && `${text.firstName} ${text.lastName}`
        },
      },
      {
        title: 'Buyer',
        dataIndex: 'buyer',
        key: 'buyer',
        render: (text: any) => {
          return text && `${text.firstName} ${text.lastName}`
        },
      },
      {
        title: 'Original Price',
        dataIndex: 'boat',
        key: 'boat',
        render: (text: any) => <span>$ {formatPrice(Number(text.price), 2)}</span>,
      },
      {
        title: 'Final Price',
        dataIndex: 'negotiatedBoatPrice',
        key: 'negotiatedBoatPrice',
        render: (text: any, record: any) => {
          return <span>$ {formatPrice(Number(text === null ? record?.boat?.price : text), 2)}</span>
        },
      },
      {
        title: 'Status',
        dataIndex: 'salesEngineStatus',
        key: 'salesEngineStatus',
        render: (text: any) => <span className="sales-engine-status-table">{text}</span>,
      },
    ],
  }

  render() {
    const { columns, searchedValue } = this.state
    const { salesEnginPayment, getAllSalesEnginPayment, salesEnginPaymentTotalRow } = this.props

    return (
      <>
        <Card title="Sales Engin Progress" extra={<TitleSearch onSearch={this.onSearch} />}>
          <ExpandTable
            columns={columns}
            tableData={getSearchedData(salesEnginPayment, searchedValue)}
            expandHtml={false}
            isExpand={false}
            page="boat"
            totalUsersRow={salesEnginPaymentTotalRow}
            pageAction={getAllSalesEnginPayment}
            extraInput={{ page_type: '0' }}
            rowKey={(record: tableRecords) => record?.id}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  salesEnginPayment: state.salesEngineReducer.salesEnginPayment,
  salesEnginPaymentTotalRow: state.salesEngineReducer.salesEnginPaymentTotalRow,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllSalesEnginPayment: (data: getSalesEnginPaymentInput) => dispatch(getAllSalesEnginPayment(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEnginProgress)
