import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card } from 'antd'

import ExpandTable from '../../component/ExpandTable'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData } from '../../helpers/helper'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import NotificationWithIcon from '../../component/notification/Notification'
import { paginationPage, defaultImage, mediaCategory, getAllBoatPagination } from '../../enums/enums'
import History from '../../History'
import { Link } from 'react-router-dom'
import { getAllPaymentMethods, deletePaymentMethods, clearManagePaymentMethodsFlag } from '../../store/pendingAds/Action'

export interface Props {
  getAllPaymentMethods: typeof getAllPaymentMethods
  deletePaymentMethods: typeof deletePaymentMethods
  clearManagePaymentMethodsFlag: typeof clearManagePaymentMethodsFlag
  tableList?: any
  totalUsersRow?: any
  history: typeof History
  getListAction?: any
  paymentLoader: boolean
  renderColumn?: any
  tableTitle: any
  formModule?: string
  createBtnText: string
  createLink: string
  paymentMethodTotal: number
  paymentMethods: any[]
  deleteSuccess: boolean
  isDeletedError: boolean
  category: string
}

export interface State {
  searchedValue: string
}

export interface tableRecords {
  id?: any
  lastName?: string
}

class BannerListIndex extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchedValue: '',
    }
  }

  // componentDidMount() {
  //   const { getAllPaymentMethods } = this.props

  //   getAllPaymentMethods({ ...getAllBoatPagination })
  // }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { deleteSuccess, getAllPaymentMethods, isDeletedError, category, clearManagePaymentMethodsFlag } = props

    if (deleteSuccess) {
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
        data: mediaCategory[category as keyof typeof mediaCategory],
      }
      getAllPaymentMethods(input)
      clearManagePaymentMethodsFlag()
    }
    if (isDeletedError) {
      NotificationWithIcon('error', 'Problem while deleting method.')
    }
    return null
  }

  editBoatTypes = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-payment-methods', { editPaymentMethod: record })
  }

  columns = [
    {
      title: 'Method Image',
      dataIndex: 'icon',
      key: 'icon',
      render: (text: any) => (
        <>
          {text ? (
            <img key={text.id} src={text.url || defaultImage.image} alt="category icon" className="category-icon"></img>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Method Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      dataIndex: 'title',
      key: 'action',
      render: (text: string, record: any) => {
        return (
          <>
            <Button className="mr-2 btn-edit" onClick={() => this.editBoatTypes(record)}>
              Edit
            </Button>
            <Button
              className="btn-delete"
              onClick={() => {
                const { deletePaymentMethods } = this.props
                record && record.id && confirmSubmitHandler(deletePaymentMethods, record.id)
              }}
            >
              Delete
            </Button>
          </>
        )
      },
    },
  ]

  onSearch = (value: any) => {
    const { getAllPaymentMethods } = this.props
    if (value) {
      this.setState({searchedValue: value})
      getAllPaymentMethods({ searchTerm: value, page: 1, limit: 10, isAdmin: true, city: '', country: '' })
    } else {
      this.setState({searchedValue: ''})
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getAllPaymentMethods(input)
    }
  }

  render() {
    const { paymentMethods, paymentMethodTotal, paymentLoader, getAllPaymentMethods } = this.props
    const { searchedValue } = this.state

    return (
      <Card
        title="Payment Method"
        extra={
          <div className="d-flex flex-wrap">
            <div className="mr-10 mt-8">
              <TitleSearch onSearch={this.onSearch} />
            </div>
            <Button className="btn-add mt-8">
              <Link to="/create-payment-methods">Add Payment Method</Link>
            </Button>
          </div>
        }
      >
        <ExpandTable
          columns={this.columns}
          tableData={paymentMethods}
          totalUsersRow={paymentMethodTotal}
          isExpand={false}
          pageAction={getAllPaymentMethods}
          page={paginationPage.boat}
          tableLoading={paymentLoader}
          extraInput={{ searchTerm: searchedValue }}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  paymentMethodTotal: state.pendingAdsState.paymentMethodTotal,
  paymentMethods: state.pendingAdsState.paymentMethods,
  deleteSuccess: state.pendingAdsState.deleteSuccess,
  deleteError: state.pendingAdsState.deleteError,
  paymentLoader: state.pendingAdsState.paymentLoader,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllPaymentMethods: (data: any) => dispatch(getAllPaymentMethods(data)),
  clearManagePaymentMethodsFlag: () => dispatch(clearManagePaymentMethodsFlag()),
  deletePaymentMethods: (id: string) => dispatch(deletePaymentMethods(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BannerListIndex)
