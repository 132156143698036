import { GET_ALL_REVIEWS, GetInput, ChangeReviewStatusInput, CHANGE_REVIEW_STATUS, REJECT_REVIEW } from './Types'

export const getAllReviews = (data: GetInput) => ({
  type: GET_ALL_REVIEWS,
  payload: data,
})

export const changeReviewStatus = (data: ChangeReviewStatusInput) => ({
  type: CHANGE_REVIEW_STATUS,
  payload: data,
})

export const rejectReview = (data: ChangeReviewStatusInput) => ({
  type: REJECT_REVIEW,
  payload: data,
})
