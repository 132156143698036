import {
  BoatTypesData,
  CREATE_BOAT_TYPES,
  GET_ALL_BOAT_TYPES,
  CLEAR_MANAGE_BOAT_TYPES_FLAG,
  BoatTypes,
  UPDATE_BOAT_TYPES,
  DELETE_BOAT_TYPES,
  EDIT_BOAT_TYPES,
} from './Types'

export function createBoatTypes(data: BoatTypesData) {
  return {
    type: CREATE_BOAT_TYPES,
    payload: data,
  }
}

export function getAllBoatTypes(data: any) {
  return {
    type: GET_ALL_BOAT_TYPES,
    payload: data,
  }
}

export function clearManageBoatTypesFlag() {
  return {
    type: CLEAR_MANAGE_BOAT_TYPES_FLAG,
  }
}

export function updateBoatTypes(data: BoatTypes) {
  return {
    type: UPDATE_BOAT_TYPES,
    payload: data,
  }
}
export function deleteBoatTypes(data: string) {
  return {
    type: DELETE_BOAT_TYPES,
    payload: data,
  }
}
export function editBoatTypes(data: string) {
  return {
    type: EDIT_BOAT_TYPES,
    payload: data,
  }
}
