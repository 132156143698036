import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import FormTable, { tableRecords } from './FormTable'
import { displayDefaultValue } from '../../helpers/helper'
import { getCareerAction, deleteCareerAction } from '../../store/form/Actions'
import { getAllBoatPagination } from '../../enums/enums'

interface Props {
  getCareerAction: typeof getCareerAction
  deleteCareerAction: typeof deleteCareerAction
  career: any[]
  careerTotalRow: number
}

class Career extends Component<Props> {
  columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: any) => {
        return <div>{displayDefaultValue(text && text.name)}</div>
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text: any, record: tableRecords) => {
        return <div>{`${text} ${record.lastName}`}</div>
      },
    },
    {
      title: 'Position',
      dataIndex: 'appliedFor',
      key: 'appliedFor',
    },
    {
      title: 'Job Reference',
      dataIndex: 'jobReference',
      key: 'jobReference',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]

  componentDidMount() {
    const { getCareerAction } = this.props
    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    }
    getCareerAction(input)
  }

  render() {
    const { deleteCareerAction, getCareerAction, career, careerTotalRow } = this.props
    return (
      <FormTable
        tableTitle="Career"
        renderColumn={this.columns}
        getListAction={getCareerAction}
        totalUsersRow={careerTotalRow}
        tableList={career}
        deleteAction={deleteCareerAction}
        formModule="careerModule"
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    career: state.feedbackReducer.career,
    careerTotalRow: state.feedbackReducer.careerTotalRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getCareerAction: (data: any) => dispatch(getCareerAction(data)),
    deleteCareerAction: (data: any) => dispatch(deleteCareerAction(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Career)
