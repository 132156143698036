import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'

import { Card, Row, Col, Typography, Button, Input } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import FieldSelect from '../../../component/FieldElements'
import { inputEvent } from '../../../constants/GlobalEventTypeScript'
import { getCostEstimate, createOrUpdateCostEstimate, clearCostEstimate } from '../../../store/salesEngine/costEstimate/Action'
import History from '../../../History'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import NotificationWithIcon from '../../../component/notification/Notification'
import { trimmedValue } from '../../../helpers/helper'

const { Text } = Typography

export interface Props {
  getCostEstimate: typeof getCostEstimate
  costEstimation: any
  createOrUpdateCostEstimate: typeof createOrUpdateCostEstimate
  history: typeof History
  costEstimateSuccess: boolean
  costEstimateError: boolean
  getSuccess: boolean
  clearCostEstimate: typeof clearCostEstimate
}
export interface State {
  costEstimation: {
    payPalFee: number
    payPalFixedPrice: number
    payPalFeeOther: number
    estimatedCostNote: String
    bankAccountDetail: String
    sitePayPalEmail: String
    payPalUserName: String
    payPalPassword: String
    payPalSignature: String
    surveyorReportSubmissionDays: number
    stopSalesEngineDays: number
    daysOfAutoDeleteBoat: number
    daysCounterForInspection: number
    daysCounterInactiveBuyers: number
    daysCounterSurveyorRespond: number
    id: any
  }
}

class CreateCostEstimate extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      costEstimation: {
        id: props.costEstimation._id || undefined,
        payPalFee: 0,
        payPalFixedPrice: 0,
        payPalFeeOther: 0,
        estimatedCostNote: '',
        bankAccountDetail: '',
        sitePayPalEmail: '',
        payPalUserName: '',
        payPalPassword: '',
        payPalSignature: '',
        surveyorReportSubmissionDays: 0,
        stopSalesEngineDays: 0,
        daysOfAutoDeleteBoat: 0,
        daysCounterForInspection: 0,
        daysCounterInactiveBuyers: 0,
        daysCounterSurveyorRespond: 0,
      },
    }
  }

  componentDidMount() {
    const { getCostEstimate } = this.props
    getCostEstimate()
  }

  static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (nextProps, prevState) => {
    const { costEstimation, costEstimateSuccess, costEstimateError, getSuccess, clearCostEstimate } = nextProps
    if (getSuccess) {
      clearCostEstimate()

      const { _id, updatedAt, __v, ...noId } = costEstimation
      return { costEstimation: { id: _id, ...noId } }
    }
    if (costEstimateSuccess) {
      NotificationWithIcon('success', 'Cost Estimate Successfully Updated')
    }
    if (costEstimateError) {
      NotificationWithIcon('error', 'Something went wrong in Updated')
    }
    return null
  }

  render() {
    const { costEstimation } = this.state
    const { createOrUpdateCostEstimate, history } = this.props

    return (
      <Fragment>
        <Formik
          enableReinitialize
          initialValues={costEstimation}
          onSubmit={(values: any) => {
            const { __typename, createdAt, updatedAt, __v, nexmoSmsForm, nexmoSmsKey, nexmoSmsSecret, ...newValues } = values
            createOrUpdateCostEstimate(newValues)
          }}
          validationSchema={Yup.object().shape({
            payPalFee: Yup.number().required('PayPal fee in Canada field is required.'),
            payPalFixedPrice: Yup.number().required('PayPal fixed price field is required.'),
            payPalFeeOther: Yup.number().required('PayPal fee other than canada  field is required.'),
          })}
        >
          {props => {
            const { handleSubmit, setFieldValue, values, errors } = props
            return (
              <form onSubmit={handleSubmit}>
                <Card
                  title={
                    <span>
                      <WSIcon type={<PlusCircleOutlined />} />
                      Cost Estimate
                    </span>
                  }
                  extra={
                    <>
                      <Button onClick={() => history.goBack()}>Back</Button>
                      <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                        Save
                      </button>
                    </>
                  }
                >
                  <Row gutter={16}>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="PayPal Fee In Canada (%)">
                        PayPal Fee In Canada (%)
                      </span>

                      <Field
                        className="ant-input"
                        name="payPalFee"
                        placeholder="PayPal Fee In Canada (%) "
                        onChange={(e: any) => {
                          setFieldValue('payPalFee', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage className="invalid-feedback ant-typography-danger" name={`payPalFee`} component="span" />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="PayPal Fee Other Than Canada (%)">
                        PayPal Fee Other Than Canada (%)
                      </span>

                      <Field
                        className="ant-input"
                        name="payPalFeeOther"
                        placeholder="PayPal Fee Other Than Canada (%)"
                        onChange={(e: any) => {
                          setFieldValue('payPalFeeOther', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`payPalFeeOther`}
                          component="span"
                        />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="PayPal Fixed Price ($)">
                        PayPal Fixed Price ($)
                      </span>

                      <Field
                        className="ant-input"
                        name="payPalFixedPrice"
                        placeholder="PayPal Fixed Price ($)"
                        onChange={(e: any) => {
                          setFieldValue('payPalFixedPrice', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`payPalFixedPrice`}
                          component="span"
                        />
                      </Text>
                    </Col>
                    {/* <Col className="gutter-box" md={12}>
                                            <span
                                                className="ant-form-item-required"
                                                title="Escrow Payment ($)"
                                            >
                                              Escrow Payment ($)
                                            </span>

                                            <Field
                                                className="ant-input"
                                                name="escrowPayment"
                                                placeholder="Escrow Payment ($)"
                                            />
                                            <Text type="danger">
                                                <ErrorMessage
                                                    className="invalid-feedback ant-typography-danger"
                                                    name={`escrowPayment`}
                                                    component="span"
                                                />
                                            </Text>
                                        </Col> */}
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="Estimated Cost Note">
                        Estimated Cost Note
                      </span>

                      <Field
                        type="InputTextArea"
                        className="ant-input"
                        name="estimatedCostNote"
                        placeholder="Estimated Cost Note"
                        component={FieldSelect}
                        onChange={(e: inputEvent) => {
                          setFieldValue('estimatedCostNote', e.target.value)
                        }}
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('estimatedCostNote', trimmedValue(e.target.value))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`estimatedCostNote`}
                          component="span"
                        />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={12}>
                      <span className="ant-form-item-required" title="Bank Account Detail">
                        Bank Account Detail
                      </span>

                      <Field
                        type="InputTextArea"
                        className="ant-input"
                        name="bankAccountDetail"
                        placeholder="Bank Account Detail"
                        component={FieldSelect}
                        onChange={(e: inputEvent) => {
                          setFieldValue('bankAccountDetail', e.target.value)
                        }}
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('bankAccountDetail', trimmedValue(e.target.value))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`estimatedCostNote`}
                          component="span"
                        />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Site Paypal Email">
                        Site Paypal Email
                      </span>

                      <Field
                        className="ant-input"
                        name="sitePayPalEmail"
                        placeholder="Site Paypal Email"
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('sitePayPalEmail', trimmedValue(e.target.value))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`sitePayPalEmail`}
                          component="span"
                        />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Paypal Username">
                        Paypal Username
                      </span>

                      <Field
                        className="ant-input"
                        name="payPalUserName"
                        placeholder="Paypal Username"
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('payPalUserName', trimmedValue(e.target.value))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`payPalUserName`}
                          component="span"
                        />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Paypal Password">
                        Paypal Password
                      </span>

                      <Field
                        className="ant-input"
                        name="payPalPassword"
                        placeholder="Paypal Password"
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('payPalPassword', trimmedValue(e.target.value))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`payPalPassword`}
                          component="span"
                        />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Paypal Signature">
                        Paypal Signature
                      </span>

                      <Field
                        className="ant-input"
                        name="payPalSignature"
                        placeholder="Paypal Signature"
                        onBlur={(e: { target: { value: string } }) =>
                          setFieldValue('payPalSignature', trimmedValue(e.target.value))
                        }
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`payPalSignature`}
                          component="span"
                        />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Surveyor Report Submission Days">
                        Surveyor Report Submission Days
                      </span>

                      <Field
                        className="ant-input"
                        name="surveyorReportSubmissionDays"
                        placeholder="Surveyor Report Submission Days"
                        onChange={(e: any) => {
                          setFieldValue('surveyorReportSubmissionDays', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`surveyorReportSubmissionDays`}
                          component="span"
                        />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Stop Sales Engine Days ( For Admin )">
                        Stop Sales Engine Days ( For Admin )
                      </span>

                      <Field
                        className="ant-input"
                        name="stopSalesEngineDays"
                        placeholder="Stop Sales Engine Days ( For Admin )"
                        onChange={(e: any) => {
                          setFieldValue('stopSalesEngineDays', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`stopSalesEngineDays`}
                          component="span"
                        />
                      </Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Days Of Auto Delete Boat After Shipment">
                        Days Of Auto Delete Boat After Shipment
                      </span>

                      <Field
                        className="ant-input"
                        name="daysOfAutoDeleteBoat"
                        placeholder="Days Of Auto Delete Boat After Shipment"
                        onChange={(e: any) => {
                          setFieldValue('daysOfAutoDeleteBoat', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`daysOfAutoDeleteBoat`}
                          component="span"
                        />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span className="ant-form-item-required" title="Days Counter For Inspection Payment">
                        Days Counter For Inspection Payment
                      </span>

                      <Field
                        className="ant-input"
                        name="daysCounterForInspection"
                        placeholder="Days Counter For Inspection Payment"
                        onChange={(e: any) => {
                          setFieldValue('daysCounterForInspection', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`daysCounterForInspection`}
                          component="span"
                        />
                      </Text>
                    </Col>
                    <Col className="gutter-box" md={8}>
                      <span
                        className="ant-form-item-required"
                        title="Days Counter For Check Inactive Buyers Under Auction Sale Engine"
                      >
                        Days Counter For Check Inactive Buyers Under Auction Sale Engine
                      </span>

                      <Field
                        className="ant-input"
                        name="daysCounterInactiveBuyers"
                        placeholder="Days Counter For Check Inactive Buyers Under Auction Sale Engine"
                        onChange={(e: any) => {
                          setFieldValue('daysCounterInactiveBuyers', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`daysCounterInactiveBuyers`}
                          component="span"
                        />
                      </Text>
                    </Col>
                  </Row>
                  {/* <Row gutter={16} >
                                    <Col className="gutter-box" md={12}>
                                            <span
                                                className="ant-form-item-required"
                                                title="Escrow Bank Account Detail"
                                            >
                                             Escrow Bank Account Detail
                                            </span>

                                            <Field
                                                type="InputTextArea"
                                                className="ant-input"
                                                name="escrowBankAccountDetail"
                                                placeholder="Escrow Bank Account Detail"
                                                component={FieldSelect}
                                                onChange={(e: inputEvent) => {
                                                    setFieldValue('escrowBankAccountDetail', e.target.value)
                                                }}
                                            />
                                            <Text type="danger">
                                                <ErrorMessage
                                                    className="invalid-feedback ant-typography-danger"
                                                    name={`escrowBankAccountDetail`}
                                                    component="span"
                                                />
                                            </Text>
                                        </Col>
                                    <Col className="gutter-box" md={12}>
                                            <span
                                                className="ant-form-item-required"
                                                title="Escrow Bank Account Tooltip Content"
                                            >
                                             Escrow Bank Account Tooltip Content
                                            </span>

                                            <Field
                                                className="ant-input"
                                                name="escrowBankAccountTooltipContent"
                                                placeholder="Escrow Bank Account Tooltip Content"
                                            />
                                            <Text type="danger">
                                                <ErrorMessage
                                                    className="invalid-feedback ant-typography-danger"
                                                    name={`escrowBankAccountTooltipContent`}
                                                    component="span"
                                                />
                                            </Text>
                                        </Col>
                                    </Row> */}
                  <Row gutter={16}>
                    {/* <Col className="gutter-box" md={6}>
                                            <span
                                                className="ant-form-item-required"
                                                title="Nexmo SMS Key"
                                            >
                                                Nexmo SMS Key
                                            </span>
                                            <Field
                                                className="ant-input"
                                                name="nexmoSmsKey"
                                                placeholder="Nexmo SMS Key"
                                            />
                                            <Text type="danger">
                                                <ErrorMessage
                                                    className="invalid-feedback ant-typography-danger"
                                                    name={`nexmoSmsKey`}
                                                    component="span"
                                                />
                                            </Text>
                                        </Col>
                                        <Col className="gutter-box" md={6}>
                                            <span
                                                className="ant-form-item-required"
                                                title="Nexmo SMS Secret"
                                            >
                                                Nexmo SMS Secret
                                            </span>

                                            <Field
                                                className="ant-input"
                                                name="nexmoSmsSecret"
                                                placeholder="Nexmo SMS Secret"
                                            />
                                            <Text type="danger">
                                                <ErrorMessage
                                                    className="invalid-feedback ant-typography-danger"
                                                    name={`nexmoSmsSecret`}
                                                    component="span"
                                                />
                                            </Text>
                                        </Col> */}
                    {/* <Col className="gutter-box" md={6}>
                                            <span
                                                className="ant-form-item-required"
                                                title="Nexmo SMS From"
                                            >
                                                Nexmo SMS From
                                            </span>

                                            <Field
                                                className="ant-input"
                                                name="nexmoSmsForm"
                                                placeholder="Nexmo SMS From"
                                            />
                                            <Text type="danger">
                                                <ErrorMessage
                                                    className="invalid-feedback ant-typography-danger"
                                                    name={`nexmoSmsForm`}
                                                    component="span"
                                                />
                                            </Text>
                                        </Col> */}
                    <Col className="gutter-box" md={6}>
                      <span className="ant-form-item-required" title="Days Counter To Check Surveyor's Respond">
                        Days Counter To Check Surveyor's Respond
                      </span>

                      <Field
                        className="ant-input"
                        name="daysCounterSurveyorRespond"
                        placeholder="Days Counter To Check Surveyor's Respond"
                        onChange={(e: any) => {
                          setFieldValue('daysCounterSurveyorRespond', +e.target.value)
                        }}
                      />
                      <Text type="danger">
                        <ErrorMessage
                          className="invalid-feedback ant-typography-danger"
                          name={`daysCounterSurveyorRespond`}
                          component="span"
                        />
                      </Text>
                    </Col>
                  </Row>
                </Card>
              </form>
            )
          }}
        </Formik>
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => ({
  costEstimation: state.costEstimateReducer && state.costEstimateReducer.costEstimation,
  costEstimateSuccess: state.costEstimateReducer.costEstimateSuccess,
  costEstimateError: state.costEstimateReducer.costEstimateError,
  getSuccess: state.costEstimateReducer.getSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCostEstimate: () => dispatch(getCostEstimate()),
  clearCostEstimate: () => dispatch(clearCostEstimate()),
  createOrUpdateCostEstimate: (data: any) => dispatch(createOrUpdateCostEstimate(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCostEstimate)
