import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card } from 'antd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { clearBoatFlag, deleteBoats, getAllBoats, searchBoats } from '../../../store/pendingAds/Action'
import history from '../../../History'
import Loader from '../../../component/Loading'
import { changeBoatApproveStatus } from '../../../store/pendingAds/Action'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { getBoatFormateData, viewBoatHandler, displayDefaultValue } from '../../../helpers/helper'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { getAllBoatPagination, pagination, paginationPage, statusValues } from '../../../enums/enums'
import { TitleSearch } from '../../../component/SearchBar'
import ThreeToggleSwitch from '../../../component/ThreeToggleSwitch'
import ApproveButton from '../../../component/ApproveButton'

interface Props {
  getAllBoats: typeof getAllBoats
  clearBoatFlag: typeof clearBoatFlag
  boatSuccess: Boolean
  boats: []
  history: typeof history
  boatIsLoading: boolean
  changeBoatApproveStatus: typeof changeBoatApproveStatus
  deleteBoats: typeof deleteBoats
  deleteSuccess: boolean
  totalAdminRow: any
  tableLoading: boolean
  searchBoats: typeof searchBoats
}

interface State {
  boats: []
  searchedUsers: any[]
  searchedValue: ''
}

interface boatStateData {
  boatIsLoading: Boolean
  boatSuccess: Boolean
  boats: []
}

interface boatState {
  boatState: boatStateData
}

class FeaturedBoats extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      boats: [],
      searchedUsers: [],
      searchedValue: '',
    }
  }
  // componentDidMount() {
  //   const { getAllBoats } = this.props
  //   const data = {
  //     page: pagination.page,
  //     limit: pagination.limit,
  //     featureStatus: statusValues.APPROVEORCRITERIA,
  //     adStatus: true,
  //   }
  //   getAllBoats(data)
  // }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { boatSuccess, clearBoatFlag, boats, deleteSuccess } = props

    if (boatSuccess) {
      getBoatFormateData(boats)
      clearBoatFlag()
    }
    if (deleteSuccess) {
      clearBoatFlag()
    }
    return null
  }

  boatDetail = (record: any) => {
    if (record && record.hasOwnProperty('id')) {
      viewBoatHandler(record)
    }
  }

  changeBoatStatus = (columnName: any, record: any, changedValue: any) => {
    const { changeBoatApproveStatus, boats } = this.props
    changeBoatApproveStatus({
      id: record.id,
      columnName,
      value: changedValue,
      fieldName: 'boats',
      fieldArray: boats,
    })
  }
  onSearch = (value: any) => {
    const { getAllBoats } = this.props
    const input = {
      ...getAllBoatPagination,
      adStatus: true,
      featureStatus: statusValues.APPROVEORCRITERIA,
    }

    if (value) {
      this.setState({ searchedValue: value })
      getAllBoats({ ...input, searchTerm: value })
    } else {
      this.setState({ searchedValue: '' })
      getAllBoats(input)
    }
  }

  render() {
    const columns = [
      {
        title: 'Country',
        dataIndex: 'address',
        key: 'address',
        render: (text: any) => {
          return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
        },
      },
      {
        title: 'Ad Seller',
        dataIndex: 'seller',
        key: 'seller',
        sorter: (a: any, b: any) => a.seller.firstName.length - b.seller.firstName.length,
        sortDirections: ['descend', 'ascend'],
        render: (text: any) => <span>{`${text?.firstName} ${text?.lastName}`}</span>,
      },
      {
        title: 'Ad ID',
        dataIndex: 'adId',
        key: 'adId',
      },
      {
        title: 'Boat Name',
        dataIndex: 'boatName',
        key: 'boatName',
        sorter: (a: any, b: any) => a.boatName.length - b.boatName.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Date Added',
        dataIndex: 'added_on',
        key: 'added_on',
      },
      {
        title: 'Featured Status',
        dataIndex: 'featureStatus',
        key: 'featureStatus',
        render: (text: string, record: tableRecords) => (
          <ThreeToggleSwitch
            toggleMarketStatus={(e: any) => this.changeBoatStatus('featureStatus', record, e.target.value)}
            status={text}
          />
        ),
      },
      {
        title: 'Approval Status',
        dataIndex: 'adStatus',
        key: 'adStatus',
        render: (text: string, record: tableRecords) => {
          const { changeBoatApproveStatus, boats } = this.props
          return (
            <ApproveButton
              action={changeBoatApproveStatus}
              input={{
                id: record.id,
                columnName: 'adStatus',
                value: !record.adStatus,
                fieldName: 'boats',
                fieldArray: boats,
              }}
              buttonTitle="Decline"
              className="btn-delete"
            />
          )
        },
      },
      {
        title: 'View Ad',
        dataIndex: 'ViewAd',
        key: 'view',
        render: (text: string, record: tableRecords, index: number) => (
          <>
            <Button onClick={() => this.boatDetail(record)} className="btn-view">
              View
            </Button>
          </>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => (
          <>
            <Button
              onClick={() => {
                confirmSubmitHandler(this.props.deleteBoats, {
                  id: record.id,
                  fieldName: 'boats',
                  fieldArray: this.props.boats,
                })
              }}
              className="btn-delete"
            >
              Delete
            </Button>
          </>
        ),
      },
    ]
    const { boatIsLoading, boats, totalAdminRow, getAllBoats } = this.props

    return (
      <>
        <Card title="Featured Boats" extra={<TitleSearch onSearch={this.onSearch} />}>
          <ExpandTable
            columns={columns}
            tableData={boats}
            expandHtml={false}
            isExpand={false}
            pageAction={getAllBoats}
            page={paginationPage.boat}
            totalUsersRow={totalAdminRow}
            extraInput={{
              featureStatus: statusValues.APPROVEORCRITERIA,
              adStatus: true,
            }}
            tableLoading={boatIsLoading}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    boatSuccess: state.pendingAdsState.boatSuccess,
    boats: state.pendingAdsState.boats,
    boatIsLoading: state.pendingAdsState.boatIsLoading,
    totalAdminRow: state.pendingAdsState.totalAdminRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllBoats: (data: any) => dispatch(getAllBoats(data)),
    clearBoatFlag: () => dispatch(clearBoatFlag()),
    changeBoatApproveStatus: (data: any) => dispatch(changeBoatApproveStatus(data)),
    deleteBoats: (data: string) => dispatch(deleteBoats(data)),
    searchBoats: (data: any) => dispatch(searchBoats(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedBoats)
