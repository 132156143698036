import React, { Component } from 'react'

import CreateBannerIndex from '../CreateBannerIndex'
import History from '../../../History'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  location: any
  history: typeof History
}

class CreateFaqBanner extends Component<Props> {
  render() {
    const { location, history } = this.props
    return (
      <CreateBannerIndex
        history={history}
        backLink="/manage-faq-banners"
        location={location}
        pageTitle="FAQ Banner"
        titlePage="FAQ"
        type={mediaCategory.faq.type}
      />
    )
  }
}

export default CreateFaqBanner
