import React, { Component, GetDerivedStateFromProps } from 'react'
import { Table, Button } from 'antd'
import { handlePageChange } from '../helpers/helper'
import PaginationBox from './PaginationBox'
import { redirectUrl } from '../constants/GlobalConstants'
import { goToDashboardAction, clearGoToDashboard } from '../store/dashboard/frontUsers/Action'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { withRouter } from 'react-router-dom'

export interface tableRecords {
  key?: number
  agreementStep?: number
  isDeleted?: boolean
  userId?: string
  userEmail?: string
  email?: string
  isAuctionSalesEngine?: boolean
  address?: string
  description?: string
  videoName?: string
  videoPath?: string
  lastModified?: string
  agreementId?: string
  companyName?: string
  country?: string
  countries?: string[]
  firstName?: string
  lastName?: string
  date?: string
  status?: boolean | any
  userStatus?: boolean
  accountStatus?: string
  ContactNumber?: number
  fullName?: string
  accessAccountPermission?: boolean
  featureStatus?: boolean
  bestDealStatus?: boolean
  mustBuyStatus?: boolean
  adStatus?: boolean
  approvalStatus?: boolean
  sellerStatus?: boolean
  builderStatus?: boolean
  surveyorStatus?: boolean
  commonModuleStatus?: boolean
  salesEngineStatus?: boolean
  added_on?: string
  title?: string
  image?: string
  id: string
  imageUrl?: string
  thumbnail?: string
  accountActivated?: boolean
  user?: {
    firstName?: string
    lastName?: string
  }
  createdAt?: Date
  boatRentStatus?: boolean
  updatedAt?: Date
  type?: string
  auctionWinner?: string
  startPrice?: number
  branchStatus?: boolean
  branchVerificationStatus?: boolean
  buyer?: {
    firstName?: string
    lastName?: string
  }
  documentVerification?: {
    governmentIdVerified?: boolean
    commercialLicenceVerified?: boolean
  }
  isVerified?: boolean
  articleApproved?: boolean
  column?: string
  buySellProcess?: string[]
  goToDashboard?: any
  buyerReviewSurveyReport?: boolean
  isSurveyorSkip?: boolean
  auctionDepositors?: any[]
  rating?: number
  reviews?: string
  reviewStatus?: boolean
  hasAmountToRelease?: boolean
  moduleId?: {
    firstName?: string
    lastName?: string
    address?: {
      country?: string
    }
  }
  boat?: {
    adId?: string
  }
  searchedValue?: string
}
export interface Column {
  title: string
  dataIndex?: string
  key: string
}
export interface Props {
  history?: any
  columns: Column[]
  goToDashboardAction: typeof goToDashboardAction
  clearGoToDashboard: typeof clearGoToDashboard
  // make tableData compulsory when api for boat sell around the world is available.
  tableData?: tableRecords[]
  expandHtml?: any
  isExpand: boolean
  isPagination?: boolean
  totalUsersRow?: Number
  page?: any
  extraInput?: any
  roles?: any
  role?: any
  handlePageChange?: any
  pageAction?: any
  expandable?: any
  expandedRowRender?: any
  goToDashboard?: any
  dashboardLink?: string
  dashboardLinkSuccess?: boolean
  filterArray?: any[]
  isFilter?: boolean
  tableLoading?: boolean
  expandedRowKeys?: any
  className?: string
  rowKey?: (record: tableRecords) => string
  onExpand?: (expanded: boolean, record: tableRecords) => void
}

class ExpandTable extends Component<Props> {
  componentDidMount() {
    const { history, pageAction, roles, role, page, extraInput } = this.props
    handlePageChange(history.location.state?.pageNumber || 1, pageAction, roles, role, page, extraInput)
  }

  componentDidUpdate() {
    const { dashboardLinkSuccess, dashboardLink, clearGoToDashboard } = this.props

    if (dashboardLinkSuccess) {
      window.open(`${redirectUrl}?autoLoginToken=${dashboardLink}`)
      clearGoToDashboard()
    }
  }

  onPageChange = (pageNumber: any) => {
    const { history, pageAction, roles, role, page, extraInput } = this.props
    history.push({
      pathname: history.location.pathname,
      search: history.location.search,
      state: { ...(history.location.state as any), pageNumber },
    })
    handlePageChange(pageNumber, pageAction, roles, role, page, extraInput)
  }

  goToDashboard = (id: any) => {
    const { goToDashboardAction } = this.props
    goToDashboardAction(id)
  }

  render() {
    const { columns, tableData, isExpand, totalUsersRow, goToDashboard, tableLoading, isPagination, history } = this.props
    const currentPage = (history.location.state as any)?.pageNumber || 1

    const addColumn = [
      {
        title: 'Sr.no',
        dataIndex: 'Sr.number',
        key: 'Sr.number',
        render: (text: string, record: tableRecords, index: number) => (
          <div className="d-flex justify-content-between align-items-center">
            <span>{(currentPage - 1) * 10 + (index + 1)}</span>
            {goToDashboard ? (
              <Button type="link" onClick={() => this.goToDashboard(record.id)}>
                Go To Dashboard
              </Button>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      ...columns,
    ]

    return (
      <div className="expand-table">
        {/* {isFilter ? <WSSelect
          defaultValue={""}
          onChangeText={(value: any) => pageAction(value)}
          arrayList={filterArray}
          fieldIS="country"
        /> : <></>} */}
        {isExpand && (
          <>
            <Table
              loading={tableLoading}
              {...this.props}
              bordered
              pagination={false}
              columns={addColumn}
              dataSource={tableData}
            />
            {(isPagination === undefined || isPagination === true) && (
              <div>
                Showing {tableData?.length} of {tableData?.length} entries
              </div>
            )}
          </>
        )}
        {!isExpand && (
          <>
            <Table
              {...this.props}
              loading={tableLoading}
              bordered
              pagination={false}
              columns={addColumn}
              dataSource={tableData}
            />
            {(isPagination === undefined || isPagination === true) && (
              <>
                <div className="d-flex justify-content-end align-items-end">
                  <PaginationBox totalRow={totalUsersRow} onPageChange={this.onPageChange} currentPage={currentPage} />
                </div>
                <div className="mt-8">
                  Showing {tableData && tableData.length} of {totalUsersRow} entries
                </div>
              </>
            )}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    dashboardLink: state.frontUsersCountReducer.dashboardLink,
    dashboardLinkSuccess: state.frontUsersCountReducer.dashboardLinkSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    goToDashboardAction: (data: any) => dispatch(goToDashboardAction(data)),
    clearGoToDashboard: () => dispatch(clearGoToDashboard()),
  }
}

export default withRouter<any, any>(connect(mapStateToProps, mapDispatchToProps)(ExpandTable))
