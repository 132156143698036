import {
  agreementState,
  CREATE_AGREEMENT,
  GET_ALL_AGREEMENTS,
  GET_ALL_AGREEMENTS_SUCCESS,
  GET_ALL_AGREEMENTS_FAILURE,
  ActionType,
  DELETE_AGREEMENT,
  DELETE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT_FAILURE,
  CREATE_AGREEMENT_CONTENT,
  CREATE_AGREEMENT_CONTENT_SUCCESS,
  CREATE_AGREEMENT_CONTENT_FAILURE,
  GET_ALL_AGREEMENT_CONTENTS,
  GET_ALL_AGREEMENT_CONTENTS_SUCCESS,
  GET_ALL_AGREEMENT_CONTENTS_FAILURE,
  GET_SINGLE_AGREEMENT_CONTENT,
  GET_SINGLE_AGREEMENT_CONTENT_SUCCESS,
  GET_SINGLE_AGREEMENT_CONTENT_FAILURE,
  UPDATE_AGREEMENT_CONTENT,
  UPDATE_AGREEMENT_CONTENT_SUCCESS,
  UPDATE_AGREEMENT_CONTENT_FAILURE,
  DELETE_AGREEMENT_CONTENT,
  DELETE_AGREEMENT_CONTENT_SUCCESS,
  DELETE_AGREEMENT_CONTENT_FAILURE,
  CHANGE_AGREEMENT_STATUS,
  CHANGE_AGREEMENT_STATUS_SUCCESS,
  CHANGE_AGREEMENT_STATUS_FAILURE,
  UPDATE_AGREEMENT,
  UPDATE_AGREEMENT_SUCCESS,
  UPDATE_AGREEMENT_FAILURE,
  CLEAR_AGREEMENT_CONTENT_FLAG,
  CREATE_AGREEMENT_SUCCESS,
  CREATE_AGREEMENT_FAILURE,
  GET_ALL_PENDING_AGREEMENT_REQUESTS,
  GET_ALL_PENDING_AGREEMENT_REQUESTS_SUCCESS,
  GET_ALL_PENDING_AGREEMENT_REQUESTS_FAILURE,
  APPROVE_PENDING_AGREEMENT,
  APPROVE_PENDING_AGREEMENT_SUCCESS,
  APPROVE_PENDING_AGREEMENT_FAILURE,
} from './Types'
import NotificationWithIcon from '../../component/notification/Notification'

export const initialState: agreementState = {
  agreements: [],
  agreementCreateSuccess: false,
  agreementCreateError: false,
  agreementDeleteSuccess: false,
  agreementDeleteError: false,
  agreementUpdateSuccess: false,
  agreementUpdateError: false,
  totalAgreement: 0,
  agreementContentsTotalRow: 0,
  pendingAgreementsTotal: 0,
  agreementContent: {
    id: '',
    title: '',
    titleSlug: '',
    fileTitle: '',
    body: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  },
  agreementContents: [],
  agreementContentSuccess: false,
  agreementContentError: false,
  agreementContentDeleteSuccess: false,
  agreementContentDeleteError: false,
  pendingAgreements: [],
  agreementStatusSuccess: false,

  approveSuccess: false,
  approveError: false,
}

export const agreementReducer = (state = initialState, action: ActionType): agreementState => {
  const { agreements } = state

  switch (action.type) {
    case CREATE_AGREEMENT:
      return {
        ...state,
        agreementCreateSuccess: false,
        agreementCreateError: false,
      }

    case CREATE_AGREEMENT_SUCCESS:
      NotificationWithIcon('success', 'Agreement Created Successfully')
      return {
        ...state,
        agreementCreateSuccess: true,
      }

    case CREATE_AGREEMENT_FAILURE:
      action.error.map((item: any) => NotificationWithIcon('error', item.message))

      return {
        ...state,
        agreementCreateError: true,
      }

    case GET_ALL_AGREEMENTS:
      return {
        ...state,
      }

    case GET_ALL_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: action.payload.items,
        totalAgreement: action.payload.total,
      }

    case GET_ALL_AGREEMENTS_FAILURE:
      return {
        ...state,
        agreements: [],
      }

    case CHANGE_AGREEMENT_STATUS:
      return {
        ...state,
        agreementStatusSuccess: false,
      }

    case CHANGE_AGREEMENT_STATUS_SUCCESS:
      let filterData
      if (action.column === 'isDeleted') {
        filterData = agreements.filter(item => action.payload.id !== item.id)
        NotificationWithIcon('success', 'Record Deleted')
      } else {
        filterData = agreements.map(item => {
          return {
            ...item,
            status: item.id === action.payload.id ? !item.status : item.status,
          }
        })
        NotificationWithIcon('success', 'Status Changed Successfully')
      }
      return {
        ...state,
        agreementStatusSuccess: true,
        agreements: [...filterData],
      }

    case CHANGE_AGREEMENT_STATUS_FAILURE:
      action.error.map((item: any) => NotificationWithIcon('error', item.message))
      return {
        ...state,
        agreementStatusSuccess: false,
      }

    case DELETE_AGREEMENT:
      return {
        ...state,
        agreementDeleteSuccess: false,
        agreementDeleteError: false,
      }

    case DELETE_AGREEMENT_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        agreements: agreements.filter(item => item.id !== action.payload.id),
        agreementDeleteSuccess: true,
      }

    case DELETE_AGREEMENT_FAILURE:
      return {
        ...state,
        agreementDeleteError: true,
      }

    case CREATE_AGREEMENT_CONTENT:
      return {
        ...state,
        agreementContentSuccess: false,
        agreementContentError: false,
      }

    case CREATE_AGREEMENT_CONTENT_SUCCESS:
      return {
        ...state,
        agreementContentSuccess: true,
      }

    case CREATE_AGREEMENT_CONTENT_FAILURE:
      return {
        ...state,
        agreementContentError: true,
      }

    case GET_ALL_AGREEMENT_CONTENTS:
      return {
        ...state,
        agreementContents: [],
      }

    case GET_ALL_AGREEMENT_CONTENTS_SUCCESS:
      return {
        ...state,
        agreementContents: action.payload.items,
        agreementContentsTotalRow: action.payload.total,
      }

    case GET_ALL_AGREEMENT_CONTENTS_FAILURE:
      return {
        ...state,
        agreementContents: [],
      }

    case GET_SINGLE_AGREEMENT_CONTENT:
      return {
        ...state,
        agreementContent: {
          id: '',
          title: '',
          titleSlug: '',
          fileTitle: '',
          body: '',
          createdAt: new Date(),
          updatedAt: new Date(),
        },
      }

    case GET_SINGLE_AGREEMENT_CONTENT_SUCCESS:
      return {
        ...state,
        agreementContent: action.payload,
      }

    case GET_SINGLE_AGREEMENT_CONTENT_FAILURE:
      return {
        ...state,
        agreementContent: {
          id: '',
          title: '',
          titleSlug: '',
          fileTitle: '',
          body: '',
          createdAt: new Date(),
          updatedAt: new Date(),
        },
      }

    case UPDATE_AGREEMENT_CONTENT:
      return {
        ...state,
        agreementContentSuccess: false,
        agreementContentError: false,
      }

    case UPDATE_AGREEMENT_CONTENT_SUCCESS:
      return {
        ...state,
        agreementContentSuccess: true,
      }

    case UPDATE_AGREEMENT_CONTENT_FAILURE:
      return {
        ...state,
        agreementContentError: true,
      }

    case DELETE_AGREEMENT_CONTENT:
      return {
        ...state,
        agreementContentDeleteSuccess: false,
        agreementContentDeleteError: false,
      }

    case DELETE_AGREEMENT_CONTENT_SUCCESS:
      const { agreementContents } = state
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        agreementContents: agreementContents.filter(item => item.id !== action.payload.id),
        agreementContentDeleteSuccess: true,
      }

    case DELETE_AGREEMENT_CONTENT_FAILURE:
      return {
        ...state,
        agreementContentDeleteError: true,
      }

    case UPDATE_AGREEMENT:
      return {
        ...state,
        agreementUpdateSuccess: false,
        agreementUpdateError: false,
      }

    case UPDATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        agreementUpdateSuccess: true,
        agreementUpdateError: false,
      }

    case UPDATE_AGREEMENT_FAILURE:
      action.error.map((item: any) => NotificationWithIcon('error', item.message))
      return {
        ...state,
        agreementUpdateSuccess: false,
        agreementUpdateError: true,
      }

    case GET_ALL_PENDING_AGREEMENT_REQUESTS:
      return {
        ...state,
        pendingAgreements: [],
      }

    case GET_ALL_PENDING_AGREEMENT_REQUESTS_SUCCESS:
      return {
        ...state,
        pendingAgreements: action.payload.items,
        pendingAgreementsTotal: action.payload.total,
      }

    case GET_ALL_PENDING_AGREEMENT_REQUESTS_FAILURE:
      return {
        ...state,
        pendingAgreements: [],
      }

    case APPROVE_PENDING_AGREEMENT:
      return {
        ...state,
      }

    case APPROVE_PENDING_AGREEMENT_SUCCESS:
      const filterdData = state.agreements.filter((item: any) => action.payload.id !== item.id)
      NotificationWithIcon('success', 'Request Accepted Successfully')

      return {
        ...state,
        approveSuccess: true,
        agreements: [...filterdData],
      }

    case APPROVE_PENDING_AGREEMENT_FAILURE:
      action.error.map((item: any) => NotificationWithIcon('error', item.message))
      return {
        ...state,
        approveError: true,
      }

    case CLEAR_AGREEMENT_CONTENT_FLAG:
      return {
        ...state,
        agreementStatusSuccess: false,
        agreementCreateSuccess: false,
        agreementCreateError: false,
        agreementDeleteSuccess: false,
        agreementDeleteError: false,
        agreementUpdateSuccess: false,
        agreementUpdateError: false,
        agreementContentSuccess: false,
        agreementContentError: false,
        agreementContentDeleteSuccess: false,
        agreementContentDeleteError: false,
        approveSuccess: false,
        approveError: false,
      }

    default:
      return state
  }
}
