import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Space, Tag } from 'antd'

import History from '../../History'
import ExpandTable from '../../component/ExpandTable'
import { getAllSalesEnginRelease, refundPayment, releasePayment } from '../../store/salesEngine/manageSalesEngine/Action'
import { AuctionRoomDeposit, getAuctionRoomTransactionInput } from '../../store/manageAuctionRoom/Types'
import { formateDateTime, formatPrice, getSearchedData } from '../../helpers/helper'
import { getAllBoatPagination, transactionSubCategory } from '../../enums/enums'
import { TitleSearch } from '../../component/SearchBar'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import NotificationWithIcon from '../../component/notification/Notification'

interface Props {
  history: typeof History
  getAllSalesEnginRelease: typeof getAllSalesEnginRelease
  location: any
  salesEnginRelease: AuctionRoomDeposit[]
  releasePayment: any
  refundPayment: typeof refundPayment
}

interface State {
  sales_engine_id: string
  adId: string
  searchedValue: string
  isAuctionSalesEngine: boolean
}

class SalesEnginReleaseTransactions extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      sales_engine_id: props.location.state.sales_engine_id,
      adId: props.location.state.adID,
      isAuctionSalesEngine: props.location.state.isAuctionSalesEngine,
      searchedValue: '',
    }
  }

  componentDidMount() {
    const { getAllSalesEnginRelease } = this.props
    const { sales_engine_id } = this.state

    if (sales_engine_id) {
      getAllSalesEnginRelease({
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
        sales_engine_id: sales_engine_id,
      })
    }
  }

  static getDerivedStateFromProps(nextProps: any, state: any) {
    const { isReleaseMoneySuccess, isReleaseMoneyFailure, releasePaymentErrorMsg, getAllSalesEnginRelease } = nextProps
    const { sales_engine_id } = state
    if (isReleaseMoneySuccess) {
      NotificationWithIcon('success', 'Released money successfully.')
      if (sales_engine_id) {
        getAllSalesEnginRelease({
          page: getAllBoatPagination.page,
          limit: getAllBoatPagination.limit,
          sales_engine_id: sales_engine_id,
        })
      }
    } else if (isReleaseMoneyFailure) {
      NotificationWithIcon('error', releasePaymentErrorMsg)
      if (sales_engine_id) {
        getAllSalesEnginRelease({
          page: getAllBoatPagination.page,
          limit: getAllBoatPagination.limit,
          sales_engine_id: sales_engine_id,
        })
      }
    }
    return null
  }

  getWithTransactionPayoutMerged = (transactions: any[]) => {
    transactions = transactions || []

    const transactionsMod = transactions.filter(t => !t.is_release_transaction)

    for (const transaction of transactionsMod) {
      if (transaction.is_release_amount_paid) {
        const releasedTransaction = transactions.find(t => t.transactionType === transaction.transactionType)

        if (releasedTransaction) {
          transaction.transactionIdPayout = releasedTransaction.paymentId
        }
      }
    }

    return transactionsMod
  }

  onSearch = (value: any) => {
    this.setState({
      searchedValue: value,
    })
  }

  render() {
    const { salesEnginRelease, getAllSalesEnginRelease, history, releasePayment, refundPayment } = this.props
    const { sales_engine_id, searchedValue, isAuctionSalesEngine } = this.state

    console.log(salesEnginRelease)

    const boatColumns = [
      {
        title: 'Transaction ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: 'Buyer Name',
        dataIndex: 'senderId',
        key: 'senderId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Seller Name',
        dataIndex: 'receiverId',
        key: 'receiverId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Date of Payment',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => <> {formateDateTime(text)} </>,
      },
      {
        title: 'Price in USD',
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Service Fee',
        dataIndex: 'serviceFee',
        key: 'serviceFee',
        render: (text: string) => (+text === 0 ? <span>-</span> : <span>$ {formatPrice(Number(text), 2)}</span>),
      },
      {
        title: 'Stripe Fee',
        dataIndex: 'stripeFee',
        key: 'stripeFee',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'AS Commision',
        dataIndex: 'comission',
        key: 'comission',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Amount to be release',
        dataIndex: 'amount_to_release',
        key: 'amount_to_release',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Country Seller',
        dataIndex: 'country',
        key: 'country',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Payment Status (Buyer)',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Transaction Id Payout',
        dataIndex: 'transactionIdPayout',
        key: 'transactionIdPayout',
        render: (text: string) => <span>{text || '-'}</span>,
      },
      {
        title: 'Actions',
        dataIndex: 'auctionBids',
        fixed: 'right',
        key: 'auctionBids',
        render: (text: string, record: any) => {
          return (
            <Button
              className={`${record.is_release_amount_paid ? 'btn-view mr-2 btn-release' : 'btn-view mr-2'}`}
              onClick={() => {
                if (record.senderId) {
                  confirmSubmitHandler(() => {
                    releasePayment({
                      user_id: record.receiverId.id,
                      transaction_id: record.id,
                      amount: parseInt(record.amount_to_release).toString(),
                    })
                  }, text)
                } else {
                  NotificationWithIcon('error', `Sender ID should not be blank`)
                }
              }}
              disabled={record.is_release_amount_paid !== null ? record.is_release_amount_paid : false}
            >
              Release
            </Button>
          )
        },
      },
    ]

    const auctionBoatColumns = [
      {
        title: 'Transaction ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: 'Buyer Name',
        dataIndex: 'senderId',
        key: 'senderId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Seller Name',
        dataIndex: 'receiverId',
        key: 'receiverId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Date of Payment',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => <> {formateDateTime(text)} </>,
      },
      {
        title: 'Price in USD',
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Deposit Amount',
        dataIndex: 'depositAmount',
        key: 'depositAmount',
        render: (text: string) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        render: (text: string) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Total Stripe Fee',
        dataIndex: 'stripeFee',
        key: 'stripeFee',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Total Service Fee',
        dataIndex: 'serviceFee',
        key: 'serviceFee',
        render: (text: string) => (+text === 0 ? <span>-</span> : <span>$ {formatPrice(Number(text), 2)}</span>),
      },
      {
        title: 'AS Commision',
        dataIndex: 'comission',
        key: 'comission',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Amount to be release',
        dataIndex: 'amount_to_release',
        key: 'amount_to_release',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Country Seller',
        dataIndex: 'country',
        key: 'country',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Payment Status (Buyer)',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Transaction Id Payout',
        dataIndex: 'transactionIdPayout',
        key: 'transactionIdPayout',
        render: (text: string) => <span>{text || '-'}</span>,
      },
      {
        title: 'Actions',
        dataIndex: 'auctionBids',
        fixed: 'right',
        key: 'auctionBids',
        render: (text: string, record: any) => {
          return (
            <Button
              className={`${record.is_release_amount_paid ? 'btn-view mr-2 btn-release' : 'btn-view mr-2'}`}
              onClick={() => {
                if (record.senderId) {
                  confirmSubmitHandler(() => {
                    releasePayment({
                      user_id: record.receiverId.id,
                      transaction_id: record.id,
                      amount: parseInt(record.amount_to_release).toString(),
                    })
                  }, text)
                } else {
                  NotificationWithIcon('error', `Sender ID should not be blank`)
                }
              }}
              disabled={record.is_release_amount_paid !== null ? record.is_release_amount_paid : false}
            >
              Release
            </Button>
          )
        },
      },
    ]

    const surveyorolumns = [
      {
        title: 'Transaction ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: 'Buyer Name',
        dataIndex: 'senderId',
        key: 'senderId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Surveyor Name',
        dataIndex: 'receiverId',
        key: 'receiverId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Date of Payment',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => <> {formateDateTime(text)} </>,
      },
      {
        title: 'Price in USD',
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Service Fee',
        dataIndex: 'serviceFee',
        key: 'serviceFee',
        render: (text: string) => (+text === 0 ? <span>-</span> : <span>$ {formatPrice(Number(text), 2)}</span>),
      },
      {
        title: 'Stripe Fee',
        dataIndex: 'stripeFee',
        key: 'stripeFee',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'AS Commision',
        dataIndex: 'comission',
        key: 'comission',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Amount to be release',
        dataIndex: 'amount_to_release',
        key: 'amount_to_release',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Country Surveyor',
        dataIndex: 'country',
        key: 'country',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Payment Status (Surveyor)',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Transaction Id Payout',
        dataIndex: 'transactionIdPayout',
        key: 'transactionIdPayout',
        render: (text: string) => <span>{text || '-'}</span>,
      },
      {
        title: 'Actions',
        dataIndex: 'auctionBids',
        fixed: 'right',
        key: 'auctionBids',
        render: (text: string, record: any) => {
          return (
            <div>
              <Button
                className={`${record.is_release_amount_paid ? 'btn-view mr-2 btn-release' : 'btn-view mr-2'}`}
                onClick={() => {
                  if (record.senderId) {
                    confirmSubmitHandler(() => {
                      releasePayment({
                        user_id: record.receiverId.id,
                        transaction_id: record.id,
                        amount: parseInt(record.amount_to_release).toString(),
                      })
                    }, text)
                  } else {
                    NotificationWithIcon('error', `Sender ID should not be blank`)
                  }
                }}
                disabled={record.is_release_amount_paid !== null ? record.is_release_amount_paid : false}
              >
                Release
              </Button>
              {record?.isRefundable && (
                <Button className={'btn-danger mr-2'} onClick={() => refundPayment({ id: record.paymentId })}>
                  Refund
                </Button>
              )}
              {record?.isRefunded && <Tag color={'rgb(0, 176, 80)'}>Refund initiated</Tag>}
            </div>
          )
        },
      },
    ]

    const shiiperColumns = [
      {
        title: 'Transaction ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: 'Buyer Name',
        dataIndex: 'senderId',
        key: 'senderId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Shipper Name',
        dataIndex: 'receiverId',
        key: 'receiverId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Date of Payment',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => <> {formateDateTime(text)} </>,
      },
      {
        title: 'Price in USD',
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Service Fee',
        dataIndex: 'serviceFee',
        key: 'serviceFee',
        render: (text: string) => (+text === 0 ? <span>-</span> : <span>$ {formatPrice(Number(text), 2)}</span>),
      },
      {
        title: 'Stripe Fee',
        dataIndex: 'stripeFee',
        key: 'stripeFee',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'AS Commision',
        dataIndex: 'comission',
        key: 'comission',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Amount to be release',
        dataIndex: 'amount_to_release',
        key: 'amount_to_release',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'Country Shipper',
        dataIndex: 'country',
        key: 'country',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Payment Status (Shipper)',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Transaction Id Payout',
        dataIndex: 'transactionIdPayout',
        key: 'transactionIdPayout',
        render: (text: string) => <span>{text || '-'}</span>,
      },
      {
        title: 'Actions',
        dataIndex: 'auctionBids',
        fixed: 'right',
        key: 'auctionBids',
        render: (text: string, record: any) => {
          return (
            <Button
              className={`${record.is_release_amount_paid ? 'btn-view mr-2 btn-release' : 'btn-view mr-2'}`}
              onClick={() => {
                if (record.senderId) {
                  confirmSubmitHandler(() => {
                    releasePayment({
                      user_id: record.receiverId.id,
                      transaction_id: record.id,
                      amount: parseInt(record.amount_to_release).toString(),
                    })
                  }, text)
                } else {
                  NotificationWithIcon('error', `Sender ID should not be blank`)
                }
              }}
              disabled={record.is_release_amount_paid !== null ? record.is_release_amount_paid : false}
            >
              Release
            </Button>
          )
        },
      },
    ]

    const depositCols = () => [
      {
        title: 'Transaction ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: 'Depositor Name',
        dataIndex: 'senderId',
        key: 'senderId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Date of Payment',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => <> {formateDateTime(text)} </>,
      },
      {
        title: 'Deposit Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Stripe Fee',
        dataIndex: 'stripeFee',
        key: 'stripeFee',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
      {
        title: 'AS Comission',
        dataIndex: 'comission',
        key: 'comission',
        render: (text: string) => <span>$ {formatPrice(Number(text), 2)}</span>,
      },
    ]

    return (
      <div>
        <Card title={`Release Payment`} extra={<Button onClick={() => history.goBack()}> {'Back'}</Button>} className="mb-10">
          <h3>Sales Engine ID - {this.state.sales_engine_id}</h3>
          <h3>Ad Id - {this.state.adId}</h3>
        </Card>
        {salesEnginRelease &&
          this.getWithTransactionPayoutMerged(salesEnginRelease).map((item: any) => {
            return (
              <Card
                // title={`${transactionSubCategory.filter((el: any) => el.value === item.sub_category_id)[0].label} Payment`}
                title={`${
                  item.transactionType === 'boatPayment'
                    ? 'Boat'
                    : item.transactionType === 'surveyorPayment'
                    ? 'Surveyor'
                    : item.transactionType === 'bidDeposit'
                    ? 'Deposit'
                    : 'Shipper'
                } Payment`}
                className="mb-10"
              >
                <ExpandTable
                  // pageAction={getAllSalesEnginRelease}
                  columns={
                    item.transactionType === 'boatPayment'
                      ? isAuctionSalesEngine ? auctionBoatColumns : boatColumns
                      : item.transactionType === 'surveyorPayment'
                      ? surveyorolumns
                      : item.transactionType === 'bidDeposit'
                      ? depositCols()
                      : shiiperColumns
                  }
                  isPagination={false}
                  tableData={getSearchedData([item], searchedValue)}
                  expandHtml={false}
                  isExpand={false}
                  page="boat"
                />
              </Card>
            )
          })}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  salesEnginRelease: state.salesEngineReducer && state.salesEngineReducer.salesEnginRelease,
  isReleaseMoneySuccess: state.salesEngineReducer && state.salesEngineReducer.isReleaseMoneySuccess,
  isReleaseMoneyFailure: state.salesEngineReducer && state.salesEngineReducer.isReleaseMoneyFailure,
  releasePaymentErrorMsg: state.salesEngineReducer && state.salesEngineReducer.releasePaymentErrorMsg,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllSalesEnginRelease: (data: getAuctionRoomTransactionInput) => dispatch(getAllSalesEnginRelease(data)),
  releasePayment: (data: any) => dispatch(releasePayment(data)),
  refundPayment: (data: any) => dispatch(refundPayment(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEnginReleaseTransactions)
