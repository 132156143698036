import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  createMarinaType,
  clearManageMarinaTypesFlag,
  updateMarinaTypes,
} from '../../../store/staticContent/manageMarinaTypes/Action'
import { BoatTypesData } from '../../../store/staticContent/manageMarinaTypes/Types'
import './ManageBoatTypes.scss'
import History from '../../../History'
import CreateCategoryIndex from '../CategoryIndexPages/CreateCategoryIndex'
import { MEDIA_NAME } from '../../../enums/enums'

interface Props {
  marinaTypeSuccess: boolean
  updateMarinaTypeSuccess: boolean
  location: any
  clearManageMarinaTypesFlag: typeof clearManageMarinaTypesFlag
  updateMarinaTypes: typeof updateMarinaTypes
  history: typeof History
  createMarinaType: typeof createMarinaType
}

class CreateMarinTypes extends Component<Props> {
  render() {
    const {
      marinaTypeSuccess,
      updateMarinaTypeSuccess,
      createMarinaType,
      updateMarinaTypes,
      history,
      location,
      clearManageMarinaTypesFlag,
    } = this.props
    return (
      <CreateCategoryIndex
        history={history}
        pageTitle="Marina Type"
        mediaName={MEDIA_NAME.MARINATYPEICON}
        iconMediaName={MEDIA_NAME.MARINATHUMBNAILTYPEICON}
        imageTitle="Marina Image"
        popupTitle="Marina"
        iconTitle="Marina Icon"
        editedData={location.state ? location.state.marinaType : null}
        successType={marinaTypeSuccess}
        updateSuccessType={updateMarinaTypeSuccess}
        createAction={createMarinaType}
        updateAction={updateMarinaTypes}
        clearTypesFlag={clearManageMarinaTypesFlag}
        listPageLink="/manage-marina-types"
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  marinaTypeSuccess: state.marinaTypesReducer && state.marinaTypesReducer.marinaTypeSuccess,
  updateMarinaTypeSuccess: state.marinaTypesReducer && state.marinaTypesReducer.updateMarinaTypeSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createMarinaType: (data: BoatTypesData) => dispatch(createMarinaType(data)),
  updateMarinaTypes: (data: BoatTypesData) => dispatch(updateMarinaTypes(data)),
  clearManageMarinaTypesFlag: () => dispatch(clearManageMarinaTypesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateMarinTypes)
