import gql from 'graphql-tag'
import { AddressType } from '../../schemaTypes'

export const getAllBoatShow = gql`
   query  getAllBoatShow($page: Int, $limit: Int, $country: String, $status: Boolean, $adStatus: Boolean,$isAdmin: Boolean, $searchTerm: String) {
    getAllBoatShow(page: $page, limit: $limit, country: $country, status: $status, adStatus: $adStatus, isAdmin: $isAdmin, searchTerm: $searchTerm) {
        items {
            address ${AddressType}
            id
            title
            showDescription
            adId
            boatshowImages {
              id
              url
            }
            status
            adStatus
            createdAt
            startDate
            endDate
            user {
                id
                firstName
                middleName
                lastName
            }
        }
        total
    }    
 }
 `

export const changeBoatShowStatus = gql`
  mutation changeStatusBoatShow($id: String!, $column: String!, $value: Boolean!) {
    changeStatusBoatShow(id: $id, column: $column, value: $value) {
      id
      message
      statusCode
    }
  }
`

export const deleteBoatShow = gql`
  mutation deleteBoatShow($id: String!, $note: String!) {
    deleteBoatShow(id: $id, note: $note) {
      id
    }
  }
`
