import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Switch, Button } from 'antd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import history from '../../History'
import { getAllRoles, clearRoleFlag } from '../../store/role/Action'
import moment from 'moment'

import { clearPendingAdFlag, getPendingRentalBoats, changeRentStatus, deleteBoats } from '../../store/pendingAds/Action'
import Loader from '../../component/Loading'
import { pagination, getAllBoatPagination, paginationPage, rejectBoatAd, fillField } from '../../enums/enums'
import { pendingAdsState } from '../../store/pendingAds/Types'
import {
  getSearchedData,
  formateDate,
  viewBoatHandler,
  viewRentBoatHandler,
  displayDefaultValue,
  removePTag,
} from '../../helpers/helper'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import { TitleSearch } from '../../component/SearchBar'
import CommonModal from '../../component/modal/CommonModal'
import WSField from '../../component/Field'
import WSToolTip from '../../component/WsToolTip'
import Text from 'antd/lib/typography/Text'
import NotificationWithIcon from '../../component/notification/Notification'
import { RejectionMailEditor } from '../../component/RejectionMailEditor'

interface Props {
  getPendingRentalBoats: typeof getPendingRentalBoats
  pendingRentalBoats: []
  isLoading: boolean
  changeRentStatus: typeof changeRentStatus
  getAllRoles: typeof getAllRoles
  success: Boolean
  roles: []
  clearPendingAdFlag: typeof clearPendingAdFlag
  clearRoleFlag: typeof clearRoleFlag
  statusSuccess: Boolean
  history: any
  deleteBoats: typeof deleteBoats
  totalPendingRentalBoats: any
  getRentalBoatSuccess: Boolean
  changeRentStatusSuccess: boolean
  deleteSuccess: boolean
}

interface State {
  rentalBoats: []
  searchedUsers: any[]
  searchedValue: string
  currentRecord: any
  modalVisible: boolean
  error: boolean
  rejectMessage: string
}

class PendingBoatRents extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      rentalBoats: [],
      searchedUsers: [],
      searchedValue: '',
      currentRecord: { id: '' },
      modalVisible: false,
      error: false,
      rejectMessage: '',
    }
  }

  // componentDidMount() {
  //   const { getPendingRentalBoats, roles } = this.props

  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getPendingRentalBoats(input)
  // }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { getRentalBoatSuccess, clearPendingAdFlag, deleteSuccess } = props

    if (getRentalBoatSuccess) {
      clearPendingAdFlag()
    }

    if (deleteSuccess) {
      clearPendingAdFlag()
      return {
        modalVisible: false,
        rejectMessage: '',
      }
    }

    return null
  }

  changeBoatStatus = (column: any, record: any, action:string) => {
    const { changeRentStatus, pendingRentalBoats } = this.props
    changeRentStatus({
      id: record.id,
      column,
      value: !record[column],
      adType: 'RENT_ADS',
      action: action,
      fieldName: 'pendingRentalBoats',
      fieldArray: pendingRentalBoats,
    })
  }

  onSearch = (value: any) => {
    this.setState({
      searchedValue: value,
    })
  }

  boatDetail = (record: any) => {
    viewRentBoatHandler(record)
  }

  setModalVisible = (data: boolean, record: tableRecords) => {
    this.setState({
      modalVisible: data,
      currentRecord: record,
    })
  }

  handleChange = (data: string) => {
    this.setState({
      rejectMessage: data,
    })
  }

  showHideError = (data: boolean) => {
    this.setState({
      error: data,
    })
  }

  onSubmit = () => {
    const { deleteBoats, pendingRentalBoats } = this.props
    const { currentRecord, rejectMessage } = this.state
    if (rejectMessage.length) {
      deleteBoats({
        id: currentRecord.id,
        note: removePTag(rejectMessage),
        adType: 'RENT_ADS',
        action:'REJECTED',
        fieldName: 'pendingRentalBoats',
        fieldArray: pendingRentalBoats,
        isRent: true,
      })
    } else {
      NotificationWithIcon('error', fillField)
    }
  }

  render() {
    const columns = [
      {
        title: 'Country',
        dataIndex: 'address',
        key: 'address',
        render: (text: any) => {
          return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
        },
      },
      {
        title: 'User Name',
        dataIndex: 'seller',
        key: 'seller',
        render: (text: string, record: tableRecords, index: number) => (
          <> {`${record.user && record.user.firstName} ${record.user && record.user.lastName}`} </>
        ),
      },
      {
        title: 'Trip Type',
        dataIndex: 'tripType',
        key: 'boatType',
        render: (text: any) => <span>{text?.alias}</span>,
      },
      {
        title: ' Date Added',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string, record: tableRecords, index: number) => <> {formateDate(record && record.createdAt)}</>,
      },
      {
        title: 'Boat Status',
        dataIndex: 'boatRentStatus',
        key: 'boatRentStatus',
        render: (text: string, record: tableRecords, index: number) => (
          <Switch
            checked={record.boatRentStatus}
            onChange={() => this.changeBoatStatus('boatRentStatus', record, record ? 'ENABLED' : 'DISABLED')}
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        ),
      },
      {
        title: 'Approval Status',
        dataIndex: 'adStatus',
        key: 'adStatus',
        render: (text: string, record: tableRecords, index: number) => (
          <Switch
            checked={record.adStatus}
            onChange={() => this.changeBoatStatus('adStatus', record, record ? 'APPROVED' : 'REJECTED')}
            checkedChildren="Approved"
            unCheckedChildren="Unapproved"
          />
        ),
      },
      {
        title: 'View Ad',
        dataIndex: 'ViewAd',
        key: 'view',
        render: (text: string, record: tableRecords, index: number) => (
          <Button onClick={() => this.boatDetail(record)} className="btn-view">
            View
          </Button>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => (
          <>
            <Button onClick={() => this.setModalVisible(true, record)} className="btn-delete">
              Reject
            </Button>
          </>
        ),
      },
    ]
    const { isLoading, pendingRentalBoats, totalPendingRentalBoats, getPendingRentalBoats } = this.props
    const { searchedValue, modalVisible, error, rejectMessage } = this.state

    return (
      <>
        <Card title="Pending Ads - Rental Boats" extra={<TitleSearch onSearch={this.onSearch} />}>
          <ExpandTable
            columns={columns}
            tableData={getSearchedData(pendingRentalBoats, searchedValue)}
            expandHtml={false}
            isExpand={false}
            page={paginationPage.boat}
            totalUsersRow={totalPendingRentalBoats}
            pageAction={getPendingRentalBoats}
            tableLoading={isLoading}
          />
        </Card>
        {modalVisible && (
          <CommonModal
            customWidth={'60%'}
            okText="Submit"
            isFlag={modalVisible}
            title="Reason Of Rejection"
            handleCancel={() => this.setModalVisible(false, { id: '' })}
            footerProp={{ footer: null }}
          >
            <RejectionMailEditor
              onClick={() => (rejectMessage ? this.onSubmit() : this.showHideError(true))}
              errorMessage={error}
              onChangeText={(evt: any) => {
                error && this.showHideError(false)
                this.handleChange(evt.editor.getData())
              }}
            />
          </CommonModal>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: pendingAdsState | any) => {
  return {
    pendingRentalBoats: state.pendingAdsState && state.pendingAdsState.pendingRentalBoats,
    isLoading: state.pendingAdsState && state.pendingAdsState.isLoading,
    success: state.roleState.success,
    roles: state.roleState.roles,
    totalPendingRentalBoats: state.pendingAdsState && state.pendingAdsState.totalPendingRentalBoats,
    getRentalBoatSuccess: state.pendingAdsState.success,
    statusSuccess: state.pendingAdsState && state.pendingAdsState.statusSuccess,
    changeRentStatusSuccess: state.pendingAdsState && state.pendingAdsState.changeRentStatusSuccess,
    deleteSuccess: state.pendingAdsState.deleteSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getPendingRentalBoats: (data: any) => dispatch(getPendingRentalBoats(data)),
    changeRentStatus: (data: any) => dispatch(changeRentStatus(data)),
    getAllRoles: () => dispatch(getAllRoles()),
    clearPendingAdFlag: () => dispatch(clearPendingAdFlag()),
    clearRoleFlag: () => dispatch(clearRoleFlag()),
    deleteBoats: (data: string) => dispatch(deleteBoats(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingBoatRents)
