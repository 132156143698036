import styled from 'styled-components'
import { margin } from './Variables'
const ContentContainer = styled.div`
  margin: ${margin.m25}px ${margin.md}px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
`
const CreateCustomer = styled.div`
  float: Right;
`
const FromControl = styled.div`
  margin-bottom: 15px;
`
const CustomerSubmit = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`
const ContactPerson = styled.div`
  margin-bottom: 15px;
`
const Back = styled.div`
  margin-top: 15px;
`
export { ContentContainer, CreateCustomer, FromControl, CustomerSubmit, ContactPerson, Back }
