import gql from 'graphql-tag'

export const createSmtp = gql`
  mutation createSmtp($input: SmtpInput!) {
    createSmtp(input: $input) {
      pass
      id
      provider
      host
      ipAddress
      userName
      email
      port
      type
      user {
        id
        firstName
        lastName
      }
      isActive
    }
  }
`

export const updateSmtp = gql`
  mutation updateSmtp($input: SmtpInput!) {
    updateSmtp(input: $input) {
      pass
      id
      provider
      host
      ipAddress
      userName
      email
      port
      type
      user {
        id
        firstName
        lastName
      }
      isActive
    }
  }
`

export const deleteSmtp = gql`
  mutation deleteSmtp($id: String!) {
    deleteSmtp(id: $id) {
      id
    }
  }
`

export const getAllSmtp = gql`
  query getAllSmtp($page: Int, $limit: Int, $country: String, $status: Boolean, $adStatus: Boolean, $searchTerm: String) {
    getAllSmtp(page: $page, limit: $limit, country: $country, status: $status, adStatus: $adStatus, searchTerm: $searchTerm) {
      items {
        id
        type
        host
        ipAddress
        userName
        port
        email
        provider
        user {
          firstName
          lastName
          country
        }
        pass
      }
      total
    }
  }
`
