import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { getAllSmsTemplates, deleteSmsTemplate, clearSmsTemplateFlag, searchTemplate } from '../../../store/staticContent/smsTemplates/Actions'
import { smsTemplate } from '../../../store/staticContent/smsTemplates/Types'
import { History } from 'history'
import { getSearchedData, formateDate } from '../../../helpers/helper'
import { TitleSearch } from '../../../component/SearchBar'
import { getAllBoatPagination, paginationPage, TEMPLATESMSNAME } from '../../../enums/enums'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'

interface Props {
  history: History
  getAllSmsTemplates: typeof getAllSmsTemplates
  smsTemplates: smsTemplate[]
  deleteSmsTemplate: typeof deleteSmsTemplate
  deleteSmsTemplateSuccess: boolean
  deleteSmsTemplateError: boolean
  clearSmsTemplateFlag: typeof clearSmsTemplateFlag
  smsTemplatesTotalRow: number
  searchTemplate: typeof searchTemplate
}

interface State {
  searchedValue: string
}

class SmsTemplates extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = nextProps => {
    const { deleteSmsTemplateSuccess, clearSmsTemplateFlag } = nextProps

    if (deleteSmsTemplateSuccess) {
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getAllSmsTemplates(input)
      clearSmsTemplateFlag()
    }

    return null
  }

  // componentDidMount() {
  //   const { getAllSmsTemplates } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getAllSmsTemplates(input)
  // }

  editTemplates = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-sms-template', { template: record })
  }

  state = {
    searchedValue: '',
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: any) => <span>{TEMPLATESMSNAME[text]}</span>,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
      },
      {
        title: 'Modified',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text: string, record: tableRecords, index: number) => <>{formateDate(record && record.updatedAt)}</>,
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteSmsTemplate } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editTemplates(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteSmsTemplate, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  onSearch = (value: any) => {
    const { searchTemplate, getAllSmsTemplates } = this.props
    if (value) {
      this.setState({searchedValue: value})
      searchTemplate({ query: value, templateType:2, page: 1, limit: 10 })
    } else {
      this.setState({searchedValue: ''})
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getAllSmsTemplates(input)
    }
  }

  render() {
    const { columns, searchedValue } = this.state
    const { smsTemplates, getAllSmsTemplates, smsTemplatesTotalRow, searchTemplate } = this.props

    return (
      <>
        <Card
          title="SMS Templates"
          extra={
            <div className="d-flex flex-wrap">
              <div className="mr-10 mt-8">
                <TitleSearch onSearch={this.onSearch} />
              </div>
              <Button className="btn-add mt-8">
                <Link to="/create-sms-template">
                  <WSIcon type={<PlusOutlined />} /> Add Template
                </Link>
              </Button>
            </div>
          }
        >
          <ExpandTable
            columns={columns}
            tableData={smsTemplates}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={smsTemplatesTotalRow}
            pageAction={searchedValue ? searchTemplate:  getAllSmsTemplates}
            page={paginationPage.boat}
            extraInput={{ query: searchedValue }}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  smsTemplates: state.smsTemplateReducer.smsTemplates,
  smsTemplatesTotalRow: state.smsTemplateReducer.smsTemplatesTotalRow,
  deleteSmsTemplateSuccess: state.smsTemplateReducer.deleteSmsTemplateSuccess,
  deleteSmsTemplateError: state.smsTemplateReducer.deleteSmsTemplateError,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllSmsTemplates: (data: any) => dispatch(getAllSmsTemplates(data)),
  deleteSmsTemplate: (data: string) => dispatch(deleteSmsTemplate(data)),
  clearSmsTemplateFlag: () => dispatch(clearSmsTemplateFlag()),
  searchTemplate: (data: any) => dispatch(searchTemplate(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SmsTemplates)
