import { ActionType, GET_MANAGE_SALES_ENGINE_COUNT, GET_MANAGE_SALES_ENGINE_COUNT_FAILURE, GET_MANAGE_SALES_ENGINE_COUNT_SUCCESS, manageSalesEngineCountState } from "./Type";

const initialState: manageSalesEngineCountState = {
    manageSalesEngineCount:[],
    manageSalesEngineCountSuccess:false,
    manageSalesEngineCountFailure:false
}

export const manageSalesEngineCountReducer = (state = initialState, action: ActionType): manageSalesEngineCountState => {
    switch(action.type){
        case GET_MANAGE_SALES_ENGINE_COUNT:
            return {
                ...state,
                manageSalesEngineCountSuccess:false,
                manageSalesEngineCountFailure:false,
            }
        case GET_MANAGE_SALES_ENGINE_COUNT_SUCCESS:
            return {
                ...state,
                manageSalesEngineCountSuccess:true,
                manageSalesEngineCountFailure:false,
                manageSalesEngineCount: action.payload.getSalesEngineDashboardCount
            }   
        case GET_MANAGE_SALES_ENGINE_COUNT_FAILURE:
            return {
                ...state,
                manageSalesEngineCountSuccess:false,
                manageSalesEngineCountFailure:true,
            }  
        default:
            return state    
    }
}