export interface faqState {
  faqs: []
  totalFAQRows: number
  faqSuccess: boolean
  faqError: boolean
  deleteSuccess: boolean
  deleteError: boolean
}

export interface faqData {
  id: string
  question: string
  description: string
  status: boolean
}

export const GET_ALL_FAQ_LIST = 'GET_ALL_FAQ_LIST'
export const GET_ALL_FAQ_LIST_SUCCESS = 'GET_ALL_FAQ_LIST_SUCCESS'
export const GET_ALL_FAQ_LIST_FAILURE = 'GET_ALL_FAQ_LIST_FAILURE'

export const CREATE_FAQ_DETAIL = 'CREATE_FAQ_DETAIL'
export const CREATE_FAQ_DETAIL_SUCCESS = 'CREATE_FAQ_DETAIL_SUCCESS'
export const CREATE_FAQ_DETAIL_FAILURE = 'CREATE_FAQ_DETAIL_FAILURE'

export const UPDATE_FAQ_DETAIL = 'UPDATE_FAQ_DETAIL'
export const UPDATE_FAQ_DETAIL_SUCCESS = 'UPDATE_FAQ_DETAIL_SUCCESS'
export const UPDATE_FAQ_DETAIL_FAILURE = 'UPDATE_FAQ_DETAIL_FAILURE'

export const DELETE_FAQ_DETAIL = 'DELETE_FAQ_DETAIL'
export const DELETE_FAQ_DETAIL_SUCCESS = 'DELETE_FAQ_DETAIL_SUCCESS'
export const DELETE_FAQ_DETAIL_FAILURE = 'DELETE_FAQ_DETAIL_FAILURE'

export const CLEAR_FAQ_FLAG = 'CLEAR_FAQ_FLAG'

export const CHANGE_FAQ_DETAIL_STATUS = 'CHANGE_FAQ_DETAIL_STATUS'
export const CHANGE_FAQ_DETAIL_STATUS_SUCCESS = 'CHANGE_FAQ_DETAIL_STATUS_SUCCESS'
export const CHANGE_FAQ_DETAIL_STATUS_FAILURE = 'CHANGE_FAQ_DETAIL_STATUS_FAILURE'

interface getManageFaq {
  type: typeof GET_ALL_FAQ_LIST
  payload: faqData
}

interface getManageFaqSuccess {
  type: typeof GET_ALL_FAQ_LIST_SUCCESS
  payload: any
}

interface getManageFaqFailure {
  type: typeof GET_ALL_FAQ_LIST_FAILURE
  payload: any
}

interface createManageFaq {
  type: typeof CREATE_FAQ_DETAIL
  payload: faqData
}

interface createManageFaqSuccess {
  type: typeof CREATE_FAQ_DETAIL_SUCCESS
  payload: any
}

interface createManageFaqFailure {
  type: typeof CREATE_FAQ_DETAIL_FAILURE
  payload: any
}

interface updateManageFaq {
  type: typeof UPDATE_FAQ_DETAIL
  payload: faqData
}

interface updateManageFaqSuccess {
  type: typeof UPDATE_FAQ_DETAIL_SUCCESS
  payload: faqData
}

interface updateManageFaqFailure {
  type: typeof UPDATE_FAQ_DETAIL_FAILURE
  payload: faqData
}

interface deleteManageFaq {
  type: typeof DELETE_FAQ_DETAIL
  payload: faqData
}

interface deleteManageFaqSuccess {
  type: typeof DELETE_FAQ_DETAIL_SUCCESS
  payload: faqData
}

interface deleteManageFaqFailure {
  type: typeof DELETE_FAQ_DETAIL_FAILURE
  payload: faqData
}

interface clearFaqFlag {
  type: typeof CLEAR_FAQ_FLAG
  payload: any
}

interface changeFaqDetailStatus {
  type: typeof CHANGE_FAQ_DETAIL_STATUS
  payload: any
}

interface changeFaqDetailStatusSuccess {
  type: typeof CHANGE_FAQ_DETAIL_STATUS_SUCCESS
  payload: any
}

interface changeFaqDetailStatusFailure {
  type: typeof CHANGE_FAQ_DETAIL_STATUS_FAILURE
  payload: any
}

export type ActionType =
  | getManageFaq
  | getManageFaqSuccess
  | getManageFaqFailure
  | createManageFaq
  | createManageFaqSuccess
  | createManageFaqFailure
  | updateManageFaq
  | updateManageFaqSuccess
  | updateManageFaqFailure
  | deleteManageFaq
  | deleteManageFaqSuccess
  | deleteManageFaqFailure
  | clearFaqFlag
  | changeFaqDetailStatus
  | changeFaqDetailStatusSuccess
  | changeFaqDetailStatusFailure
