export interface AuctionRoomState {
  auctionRoom: AuctionRoom
  auctionRooms: AuctionRoom[]
  totalAuctionRooms: any
  auctionRoomBids: AuctionRoomBid[]
  auctionRoomBidsTotalRow: number
  auctionRoomDepositors: AuctionRoomDeposit[]
  auctionRoomDepositorsTotalRow: number
  auctionRoomTransactions: AuctionRoomTransaction[]
  auctionRoomTransactionsTotalRow: number
}

export interface AuctionRoom {
  boat: any
  startTime: Date
  endTime: Date
  bidDuration: string
  auctionBids: AuctionRoomBid[]
  id: string
  startPrice: number
  minimumRaisedAmount: number
  auctionWinner: any
  status: string
  createdAt: Date
  depositAmount?: number | any
}

export interface AuctionRoomBid {
  id: string
  price: number
  status: boolean
  user: any
  createdAt: Date
  auctionRoom: AuctionRoom
}

export interface getAuctionRoomInput {
  page?: number
  limit?: number
  searchTerm?: string
}

export interface getAuctionRoomBidsInput {
  page?: number
  limit?: number
  auctionId: string
}

export enum AuctionStatusType {
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINE',
  AUCTIONENABLED = 'AUCTIONENABLED',
  AWARDED = 'AWARDED',
}

export interface AuctionRoomStatusInput {
  id: string
  value: AuctionStatusType
  column: string
}

export interface getAuctionRoomDepositInput {
  page?: number
  limit?: number
  auctionId: string
}

export interface getAuctionRoomTransactionInput {
  page?: number
  limit?: number
  auction_id?: string
  sales_engine_id?: string
}

export interface AuctionRoomDeposit {
  id: string
  user: any
  createdAt: Date
  isDepositAdded: boolean
  auctionRoom: AuctionRoom
}

export interface AuctionRoomTransaction {
  id: string
  createdAt: Date
  status: string
  category_id: string
  sub_category_id: string
  externalModuleType: string
  externalId: string
  transactionType: string
  paymentId: string
  senderId: any
  receiverId: any
  amount: number
}

export const GET_ALL_AUCTION_ROOM = 'GET_ALL_AUCTION_ROOM'
export const GET_ALL_AUCTION_ROOM_SUCCESS = 'GET_ALL_AUCTION_ROOM_SUCCESS'
export const GET_ALL_AUCTION_ROOM_FAILURE = 'GET_ALL_AUCTION_ROOM_FAILURE'

export const CHANGE_AUCTION_ROOM_STATUS = 'CHANGE_AUCTION_ROOM_STATUS'
export const CHANGE_AUCTION_ROOM_STATUS_SUCCESS = 'CHANGE_AUCTION_ROOM_STATUS_SUCCESS'
export const CHANGE_AUCTION_ROOM_STATUS_FAILURE = 'CHANGE_AUCTION_ROOM_STATUS_FAILURE'

export const DELETE_AUCTION_ROOM = 'DELETE_AUCTION_ROOM'
export const DELETE_AUCTION_ROOM_SUCCESS = 'DELETE_AUCTION_ROOM_SUCCESS'
export const DELETE_AUCTION_ROOM_FAILURE = 'DELETE_AUCTION_ROOM_FAILURE'

export const GET_ALL_AUCTION_ROOM_BIDS = 'GET_ALL_AUCTION_ROOM_BIDS'
export const GET_ALL_AUCTION_ROOM_BIDS_SUCCESS = 'GET_ALL_AUCTION_ROOM_BIDS_SUCCESS'
export const GET_ALL_AUCTION_ROOM_BIDS_FAILURE = 'GET_ALL_AUCTION_ROOM_BIDS_FAILURE'

export const GET_ALL_AUCTION_ROOM_DEPOSITORS = 'GET_ALL_AUCTION_ROOM_DEPOSITORS'
export const GET_ALL_AUCTION_ROOM_DEPOSITORS_SUCCESS = 'GET_ALL_AUCTION_ROOM_DEPOSITORS_SUCCESS'
export const GET_ALL_AUCTION_ROOM_DEPOSITORS_FAILURE = 'GET_ALL_AUCTION_ROOM_DEPOSITORS_FAILURE'

export const GET_ALL_AUCTION_ROOM_TRANSACTION = 'GET_ALL_AUCTION_ROOM_TRANSACTION'
export const GET_ALL_AUCTION_ROOM_TRANSACTION_SUCCESS = 'GET_ALL_AUCTION_ROOM_TRANSACTION_SUCCESS'
export const GET_ALL_AUCTION_ROOM_TRANSACTION_FAILURE = 'GET_ALL_AUCTION_ROOM_TRANSACTION_FAILURE'

interface getAllAuctionRoom {
  type: typeof GET_ALL_AUCTION_ROOM
  payload: getAuctionRoomInput
}
interface getAllAuctionRoomSuccess {
  type: typeof GET_ALL_AUCTION_ROOM_SUCCESS
  payload: {
    items: AuctionRoom[]
    total: number
  }
}
interface getAllAuctionRoomFailure {
  type: typeof GET_ALL_AUCTION_ROOM_FAILURE
  payload: any
}

interface changeAuctionRoomStatus {
  type: typeof CHANGE_AUCTION_ROOM_STATUS
  payload: AuctionRoomStatusInput
}
interface changeAuctionRoomStatusSuccess {
  type: typeof CHANGE_AUCTION_ROOM_STATUS_SUCCESS
  payload: AuctionRoom
}
interface changeAuctionRoomStatusFailure {
  type: typeof CHANGE_AUCTION_ROOM_STATUS_FAILURE
  payload: any
}

interface deleteAuctionRoom {
  type: typeof DELETE_AUCTION_ROOM
  payload: string
}
interface deleteAuctionRoomSuccess {
  type: typeof DELETE_AUCTION_ROOM_SUCCESS
  payload: AuctionRoom
}
interface deleteAuctionRoomFailure {
  type: typeof DELETE_AUCTION_ROOM_FAILURE
  payload: any
}

interface getAllAuctionRoomBids {
  type: typeof GET_ALL_AUCTION_ROOM_BIDS
  payload: getAuctionRoomBidsInput
}
interface getAllAuctionRoomBidsSuccess {
  type: typeof GET_ALL_AUCTION_ROOM_BIDS_SUCCESS
  payload: {
    items: AuctionRoomBid[]
    total: number
  }
}
interface getAllAuctionRoomBidsFailure {
  type: typeof GET_ALL_AUCTION_ROOM_BIDS_FAILURE
  payload: any
}

interface getAllAuctionRoomDepositors {
  type: typeof GET_ALL_AUCTION_ROOM_DEPOSITORS
  payload: getAuctionRoomDepositInput
}
interface getAllAuctionRoomDepositorsSuccess {
  type: typeof GET_ALL_AUCTION_ROOM_DEPOSITORS_SUCCESS
  payload: {
    items: AuctionRoomDeposit[]
    total: number
  }
}
interface getAllAuctionRoomDepositorsFailure {
  type: typeof GET_ALL_AUCTION_ROOM_DEPOSITORS_FAILURE
  payload: any
}

interface getAllAuctionRoomTransactions {
  type: typeof GET_ALL_AUCTION_ROOM_TRANSACTION
  payload: getAuctionRoomDepositInput
}
interface getAllAuctionRoomTransactionsSuccess {
  type: typeof GET_ALL_AUCTION_ROOM_TRANSACTION_SUCCESS
  payload: {
    items: AuctionRoomTransaction[]
    total: number
  }
}
interface getAllAuctionRoomTransactionsFailure {
  type: typeof GET_ALL_AUCTION_ROOM_TRANSACTION_FAILURE
  payload: any
}

export type ActionType =
  | getAllAuctionRoom
  | getAllAuctionRoomSuccess
  | getAllAuctionRoomFailure
  | changeAuctionRoomStatus
  | changeAuctionRoomStatusSuccess
  | changeAuctionRoomStatusFailure
  | deleteAuctionRoom
  | deleteAuctionRoomSuccess
  | deleteAuctionRoomFailure
  | getAllAuctionRoomBids
  | getAllAuctionRoomBidsSuccess
  | getAllAuctionRoomBidsFailure
  | getAllAuctionRoomDepositors
  | getAllAuctionRoomDepositorsSuccess
  | getAllAuctionRoomDepositorsFailure
  | getAllAuctionRoomTransactions
  | getAllAuctionRoomTransactionsSuccess
  | getAllAuctionRoomTransactionsFailure
