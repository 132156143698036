import React, { Fragment } from 'react'

import { Tabs, List } from 'antd'

const { TabPane } = Tabs
export interface Props {}
export interface State {}
const tabData = [
  {
    title: 'Contact Info',
    list: [
      {
        title: 'Agreement ID',
        value: 1,
      },
      {
        title: 'Country',
        value: 'India',
      },
      {
        title: 'company Name',
        value: 'abc',
      },
    ],
  },
  {
    title: 'Point Of Contact',
    list: [
      {
        title: 'Agreement ID',
        value: 2,
      },
      {
        title: 'Country',
        value: 'Usa',
      },
      {
        title: 'company Name',
        value: 'xyz',
      },
    ],
  },
]

class ViewMember extends React.Component<Props, State> {
  render() {
    return (
      <Fragment>
        <Tabs defaultActiveKey="1" tabPosition="top" style={{ height: 220 }}>
          {tabData.map((tab: any, index: any) => (
            <TabPane tab={tab.title} key={tab.id}>
              {tab.list.map((item: any) => {
                return (
                  <List key={item.id}>
                    <List.Item>
                      {item.title} : {item.value}
                    </List.Item>
                  </List>
                )
              })}
            </TabPane>
          ))}
        </Tabs>
      </Fragment>
    )
  }
}

export default ViewMember
