import gql from "graphql-tag";

export const manageRentDashboard = gql`
        query {
            getManageRentDashboardCount {
            availableRentBooking{
                count
                _id
                totalSharedTickets
                totalRphRides
              }
              ongoingTrips{
                count
                _id
              }
              completedTrips{
                count
                _id
              }
              expiredTrips{
                count
                _id
              }
              purchasedTrips{
                count
                _id
              }
              totalBoats{
                count
                _id
              }
            }
          }
`