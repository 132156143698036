import gql from 'graphql-tag'

export const getAllJobs = gql`
  query getAllJobs($page: Int, $limit: Int, $searchTerm: String) {
    getAllJobs(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        id
        country {
          id
          name
        }
        city
        title
        description
        reference
        salaryPackage
        candidateProfile
        applicationInformation
        experience
        documentUrl {
          id
          url
        }
        status
        cvCount
      }
      total
    }
  }
`

export const createJob = gql`
  mutation createJob($input: ManageJobInput!) {
    createJob(input: $input) {
      id
      country {
        id
        name
      }
      city
      title
      description
      reference
      salaryPackage
      experience
      candidateProfile
      applicationInformation
      documentUrl {
        id
        url
      }
      status
    }
  }
`

export const updateJob = gql`
  mutation updateJob($input: ManageJobInput!) {
    updateJob(input: $input) {
      id
    }
  }
`

export const deleteJob = gql`
  mutation deleteJob($id: String!) {
    deleteJob(id: $id) {
      id
    }
  }
`

export const changeJobStatus = gql`
  mutation jobChangeStatus($id: String!, $column: String!, $value: Boolean!) {
    jobChangeStatus(id: $id, column: $column, value: $value) {
      id
      country {
        id
        name
      }
      city
      title
      description

      reference
      experience
      salaryPackage
      candidateProfile
      applicationInformation
      documentUrl {
        id
        url
      }
      status
    }
  }
`

export const getJobsContent = gql`
  query getCareerContent {
    getCareerContent {
      id
      title
      description
    }
  }
`

export const createOrUpdateJobContent = gql`
  mutation createOrUpdateCareerContent($input: CareerContentInput!) {
    createOrUpdateCareerContent(input: $input) {
      id
      title
      description
    }
  }
`

export const getAppliedJobsByJob = gql`
  query getAppliedJobsByJob($jobId: String!) {
    getAppliedJobsByJob(jobId: $jobId) {
      items {
        id
        cvUrl {
          id
        }
        coverLetter {
          id
        }
        fullName
        experience
      }
    }
  }
`
