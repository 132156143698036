import {
  GET_STATE,
  GET_STATE_SUCCESS,
  GET_STATE_FAILURE,
  stateData,
  CREATE_STATE,
  CREATE_STATE_SUCCESS,
  CHANGE_STATE_STATUS,
  CHANGE_STATE_STATUS_SUCCESS,
  CHANGE_STATE_STATUS_FAILURE,
  CLEAR_STATE_FLAG,
  DELETE_STATE,
  UPDATE_STATE,
} from './Type'

export function getStates(data: any) {
  return {
    type: GET_STATE,
    payload: data,
  }
}
export function getStatesSuccess(data: any) {
  return {
    type: GET_STATE_SUCCESS,
    payload: data,
  }
}
export function getStatesFailure(data: any) {
  return {
    type: GET_STATE_FAILURE,
    payload: data,
  }
}

export function createState(data: stateData) {
  return {
    type: CREATE_STATE,
    payload: data,
  }
}

export function createSTATESuccess(data: stateData) {
  return {
    type: CREATE_STATE_SUCCESS,
    payload: data,
  }
}

export function changeStateStatus() {
  return {
    type: CHANGE_STATE_STATUS,
  }
}

export function changeStateStatusSuccess(data: any) {
  return {
    type: CHANGE_STATE_STATUS_SUCCESS,
    payload: data,
  }
}

export function changeStateStatusFailure() {
  return {
    type: CHANGE_STATE_STATUS_FAILURE,
  }
}

export function clearStateFlag() {
  return {
    type: CLEAR_STATE_FLAG,
  }
}

export function updateState(data: stateData) {
  return {
    type: UPDATE_STATE,
    payload: data,
  }
}

export function deleteState(data: string) {
  return {
    type: DELETE_STATE,
    payload: data,
  }
}
