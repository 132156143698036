export interface ForgotPasswordState {
  passwordContent: []
  addSuccess: boolean
  error: boolean
  isLoading: boolean
}

export interface passCreateData {
  id?: string
  title: string
  content: string
  buttonText: string
  otherDescription: string
  addMedia?: any
  removeMedia?: any
}

export const CREATE_FORGOT_PASSWORD = 'CREATE_FORGOT_PASSWORD'
export const CREATE_FORGOT_PASSWORD_SUCCESS = 'CREATE_FORGOT_PASSWORD_SUCCESS'
export const CREATE_FORGOT_PASSWORD_FAILURE = 'CREATE_FORGOT_PASSWORD_FAILURE'

export const GET_FORGOT_PASSWORD = 'GET_FORGOT_PASSWORD'
export const GET_FORGOT_PASSWORD_SUCCESS = 'GET_FORGOT_PASSWORD_SUCCESS'
export const GET_FORGOT_PASSWORD_FAILURE = 'GET_FORGOT_PASSWORD_FAILURE'

export const CLEAR_FORGOT_PASSWORD_FLAG = 'CLEAR_FORGOT_PASSWORD_FLAG'

interface createForgotPassword {
  type: typeof CREATE_FORGOT_PASSWORD
  payload: passCreateData
}

interface getForgotPassword {
  type: typeof GET_FORGOT_PASSWORD
  payload: any
}

interface getForgotPasswordSuccess {
  type: typeof GET_FORGOT_PASSWORD_SUCCESS
  payload: any
}

interface clearFlag {
  type: typeof CLEAR_FORGOT_PASSWORD_FLAG
  payload: any
}

interface getForgotPasswordFailure {
  type: typeof GET_FORGOT_PASSWORD_FAILURE
  payload: string
}

interface createForgotPasswordSuccess {
  type: typeof CREATE_FORGOT_PASSWORD_SUCCESS
  payload: passCreateData
}

interface createForgotPasswordFailure {
  type: typeof CREATE_FORGOT_PASSWORD_FAILURE
  payload: string
}

export type ActionType =
  | createForgotPassword
  | getForgotPassword
  | getForgotPasswordSuccess
  | clearFlag
  | getForgotPasswordFailure
  | createForgotPasswordSuccess
  | createForgotPasswordFailure
