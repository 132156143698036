import {
  AuctionRoomState,
  ActionType,
  GET_ALL_AUCTION_ROOM,
  GET_ALL_AUCTION_ROOM_SUCCESS,
  GET_ALL_AUCTION_ROOM_FAILURE,
  CHANGE_AUCTION_ROOM_STATUS,
  CHANGE_AUCTION_ROOM_STATUS_SUCCESS,
  CHANGE_AUCTION_ROOM_STATUS_FAILURE,
  GET_ALL_AUCTION_ROOM_BIDS,
  GET_ALL_AUCTION_ROOM_BIDS_SUCCESS,
  GET_ALL_AUCTION_ROOM_BIDS_FAILURE,
  DELETE_AUCTION_ROOM,
  DELETE_AUCTION_ROOM_SUCCESS,
  DELETE_AUCTION_ROOM_FAILURE,
  GET_ALL_AUCTION_ROOM_DEPOSITORS,
  GET_ALL_AUCTION_ROOM_DEPOSITORS_SUCCESS,
  GET_ALL_AUCTION_ROOM_DEPOSITORS_FAILURE,
  GET_ALL_AUCTION_ROOM_TRANSACTION,
  GET_ALL_AUCTION_ROOM_TRANSACTION_SUCCESS,
  GET_ALL_AUCTION_ROOM_TRANSACTION_FAILURE,
} from './Types'
import NotificationWithIcon from '../../component/notification/Notification'

const initialState: AuctionRoomState = {
  auctionRoom: {
    boat: null,
    startTime: new Date(),
    endTime: new Date(),
    bidDuration: '',
    auctionBids: [],
    id: '',
    startPrice: 0,
    minimumRaisedAmount: 0,
    auctionWinner: null,
    status: '',
    createdAt: new Date(),
  },
  auctionRooms: [],
  auctionRoomBidsTotalRow: 0,
  totalAuctionRooms: 0,
  auctionRoomBids: [],

  auctionRoomDepositors: [],
  auctionRoomDepositorsTotalRow: 0,

  auctionRoomTransactions: [],
  auctionRoomTransactionsTotalRow: 0,
}

export const auctionRoomReducer = (state = initialState, action: ActionType): AuctionRoomState => {
  const { auctionRooms } = state

  switch (action.type) {
    // GET AUCTION ROOMS
    case GET_ALL_AUCTION_ROOM:
      return {
        ...state,
      }

    case GET_ALL_AUCTION_ROOM_SUCCESS:
      return {
        ...state,
        auctionRooms: action.payload.items,
        totalAuctionRooms: action.payload.total,
      }

    case GET_ALL_AUCTION_ROOM_FAILURE:
      return {
        ...state,
        auctionRooms: [],
      }

    // CHANGE AUCTION ROOM STATUS
    case CHANGE_AUCTION_ROOM_STATUS:
      return {
        ...state,
      }

    case CHANGE_AUCTION_ROOM_STATUS_SUCCESS:
      NotificationWithIcon('success', 'Status Changed Successfully')
      return {
        ...state,
        auctionRooms: auctionRooms.map(item => {
          if (item.id == action.payload.id) {
            return action.payload
          }
          return item
        }),
      }

    case CHANGE_AUCTION_ROOM_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
      }

    // DELETE AUCTION ROOM
    case DELETE_AUCTION_ROOM:
      return {
        ...state,
        auctionRooms: [],
      }

    case DELETE_AUCTION_ROOM_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        auctionRooms: auctionRooms.filter(item => item.id !== action.payload.id),
      }

    case DELETE_AUCTION_ROOM_FAILURE:
      return {
        ...state,
        auctionRooms: [],
      }

    // GET AUCTION ROOM BIDS
    case GET_ALL_AUCTION_ROOM_BIDS:
      return {
        ...state,
        auctionRoomBids: [],
      }

    case GET_ALL_AUCTION_ROOM_BIDS_SUCCESS:
      return {
        ...state,
        auctionRoomBids: action.payload.items,
        auctionRoomBidsTotalRow: action.payload.total,
      }

    case GET_ALL_AUCTION_ROOM_BIDS_FAILURE:
      return {
        ...state,
        auctionRoomBids: [],
      }

    // GET AUCTION ROOM DEPOSITORS
    case GET_ALL_AUCTION_ROOM_DEPOSITORS:
      return {
        ...state,
        auctionRoomDepositors: [],
      }

    case GET_ALL_AUCTION_ROOM_DEPOSITORS_SUCCESS:
      return {
        ...state,
        auctionRoomDepositors: action.payload.items,
        auctionRoomDepositorsTotalRow: action.payload.total,
      }

    case GET_ALL_AUCTION_ROOM_DEPOSITORS_FAILURE:
      return {
        ...state,
        auctionRoomDepositors: [],
      }

    // GET AUCTION ROOM TRANSACTIONS
    case GET_ALL_AUCTION_ROOM_TRANSACTION:
      return {
        ...state,
        auctionRoomTransactions: [],
      }

    case GET_ALL_AUCTION_ROOM_TRANSACTION_SUCCESS:
      return {
        ...state,
        auctionRoomTransactions: action.payload.items,
        auctionRoomTransactionsTotalRow: action.payload.total,
      }

    case GET_ALL_AUCTION_ROOM_TRANSACTION_FAILURE:
      return {
        ...state,
        auctionRoomTransactions: [],
      }

    default:
      return state
  }
}
