import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import {
  ActionType,
  GET_ALL_SMS_TEMPLATES,
  GET_ALL_SMS_TEMPLATES_SUCCESS,
  GET_ALL_SMS_TEMPLATES_FAILURE,
  ADD_SMS_TEMPLATE,
  ADD_SMS_TEMPLATE_SUCCESS,
  ADD_SMS_TEMPLATE_FAILURE,
  smsTemplateInput,
  GET_SINGLE_SMS_TEMPLATES,
  GET_SINGLE_SMS_TEMPLATES_SUCCESS,
  GET_SINGLE_SMS_TEMPLATES_FAILURE,
  UPDATE_SMS_TEMPLATE,
  UPDATE_SMS_TEMPLATE_SUCCESS,
  UPDATE_SMS_TEMPLATE_FAILURE,
  DELETE_SMS_TEMPLATE,
  DELETE_SMS_TEMPLATE_SUCCESS,
  DELETE_SMS_TEMPLATE_FAILURE,
  SEARCH_TEMPLATE,
  SEARCH_TEMPLATE_SUCCESS,
  SEARCH_TEMPLATE_FAILURE,
} from './Types'
import { getAllSmsTemplates, createSmsTemplate, editSmsTemplate, deleteSmsTemplate, updateSmsTemplate, searchTemplate } from './Schema'

function addSmsTemplateApi(input: smsTemplateInput) {
  return client
    .mutate({
      mutation: createSmsTemplate,
      variables: { input },
    })
}

function getSmsTemplatesApi(payload: any) {
  return client
    .query({
      query: getAllSmsTemplates,
      variables: {
        page: payload.page,
        limit: payload.limit,
      },
      fetchPolicy: 'no-cache',
    })
}

function getSmsTemplateByIdApi(input: string) {
  return client
    .query({
      query: editSmsTemplate,
      variables: { id: input },
      fetchPolicy: 'no-cache',
    })
}

function updateSmsApi(input: smsTemplateInput) {
  return client
    .mutate({
      mutation: updateSmsTemplate,
      variables: { input },
    })
}

function deleteSmsApi(input: string) {
  return client
    .mutate({
      mutation: deleteSmsTemplate,
      variables: { id: input },
    })
}

function searchTemplateApi(input: string) {
  return client
    .query({
      query: searchTemplate,
      variables: input,
    })
}

function* addSmsTemplate(action: any) {
  try {
    const res = yield addSmsTemplateApi(action.payload)
    yield put({
      type: ADD_SMS_TEMPLATE_SUCCESS,
      payload: res.data.createSmsTemplate,
    })
  } catch (error) {
    yield put({ type: ADD_SMS_TEMPLATE_FAILURE, error })
  }
}

function* getSmsTemplates(action: any) {
  try {
    const res = yield getSmsTemplatesApi(action.payload)
    yield put({
      type: GET_ALL_SMS_TEMPLATES_SUCCESS,
      payload: res.data.getAllSmsTemplates,
    })
  } catch (error) {
    yield put({ type: GET_ALL_SMS_TEMPLATES_FAILURE, error })
  }
}

function* getSmsTemplateById(action: any) {
  try {
    const res = yield getSmsTemplateByIdApi(action.payload)
    yield put({
      type: GET_SINGLE_SMS_TEMPLATES_SUCCESS,
      payload: res.data.EditSmsTemplate,
    })
  } catch (error) {
    yield put({ type: GET_SINGLE_SMS_TEMPLATES_FAILURE, error })
  }
}

function* updateSms(action: any) {
  try {
    const res = yield updateSmsApi(action.payload)
    yield put({
      type: UPDATE_SMS_TEMPLATE_SUCCESS,
      payload: res.data.updateSmsTemplate,
    })
  } catch (error) {
    yield put({ type: UPDATE_SMS_TEMPLATE_FAILURE, error })
  }
}

function* deleteSms(action: any) {
  try {
    const res = yield deleteSmsApi(action.payload)
    yield put({
      type: DELETE_SMS_TEMPLATE_SUCCESS,
      payload: res.data.deleteSmsTemplate,
    })
  } catch (error) {
    yield put({ type: DELETE_SMS_TEMPLATE_FAILURE, error })
  }
}

function* searchTemplateData(action: any) {
  try {
    const res = yield searchTemplateApi(action.payload)
    yield put({
      type: SEARCH_TEMPLATE_SUCCESS,
      payload: res.data.searchTemplate,
    })
  } catch (error) {
    yield put({ type: SEARCH_TEMPLATE_FAILURE, error })
  }
}

function* addSmsTemplateSaga() {
  yield takeLatest(ADD_SMS_TEMPLATE, addSmsTemplate)
}

function* getSmsTemplatesSaga() {
  yield takeLatest(GET_ALL_SMS_TEMPLATES, getSmsTemplates)
}

function* getSmsTemplateByIdSaga() {
  yield takeLatest(GET_SINGLE_SMS_TEMPLATES, getSmsTemplateById)
}

function* updateSmsSaga() {
  yield takeLatest(UPDATE_SMS_TEMPLATE, updateSms)
}

function* deleteSmsSaga() {
  yield takeLatest(DELETE_SMS_TEMPLATE, deleteSms)
}

function* searchTemplateSaga() {
  yield takeLatest(SEARCH_TEMPLATE, searchTemplateData)
}

export default function* smsSaga() {
  yield all([addSmsTemplateSaga(), getSmsTemplatesSaga(), getSmsTemplateByIdSaga(), updateSmsSaga(), deleteSmsSaga(), searchTemplateSaga()])
}
