import { GET_PERMISSIONS, GET_PERMISSIONS_SUCCESS, GET_PERMISSIONS_FAILURE, ActionType, permissionsState } from './Types'

const initialState: permissionsState = {
  success: false,
  error: false,
  isSuperAdmin: false,
  role: '',
  permissions: [],
}

export function permissionsReducer(state = initialState, action: ActionType): permissionsState {
  switch (action.type) {
    case GET_PERMISSIONS:
      return {
        ...state,
        success: false,
        error: false,
      }
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        isSuperAdmin: action.payload.data.getAdminPermissions.isSuperAdmin,
        role: action.payload.data.getAdminPermissions.role.aliasName,
        permissions: action.payload.data.getAdminPermissions.permissions,
      }
    case GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }

    default:
      return state
  }
}
