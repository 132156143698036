import React from 'react'
import './Image.css'

interface Props {
  src: string
  alt?: string
  height?: number
  removeable?: boolean
  onRemove?: () => void
}

export default function Image({ src, alt, height, removeable, onRemove }: Props) {
  return (
    <div className="image-container" style={{ height }}>
      <img src={src} alt={alt || ''} />
      {removeable && (
        <div className="image-remove-button" onClick={onRemove}>
          &times;
        </div>
      )}
    </div>
  )
}
