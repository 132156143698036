import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { createOrUpdateCostEstimate, getCostEstimate } from './Schema'
import {
  ActionType,
  CREATE_OR_UPDATE_COST_ESTIMATE_SUCCESS,
  CREATE_OR_UPDATE_COST_ESTIMATE_FAILURE,
  CREATE_OR_UPDATE_COST_ESTIMATE,
  GET_COST_ESTIMATE_SUCCESS,
  GET_COST_ESTIMATE_FAILURE,
  GET_COST_ESTIMATE,
} from './Types'

function createOrUpdateCostEstimateApi(input: any) {
  return client
    .mutate({
      mutation: createOrUpdateCostEstimate,
      variables: { input },
    })
}

function* createOrUpdateCostEstimateData(action: ActionType) {
  try {
    const res = yield createOrUpdateCostEstimateApi(action.payload)
    yield put({
      type: CREATE_OR_UPDATE_COST_ESTIMATE_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    yield put({ type: CREATE_OR_UPDATE_COST_ESTIMATE_FAILURE, error })
  }
}

function* createOrUpdateCostEstimateSaga() {
  yield takeLatest(CREATE_OR_UPDATE_COST_ESTIMATE, createOrUpdateCostEstimateData)
}

function getCostEstimateApi() {
  return client
    .query({
      query: getCostEstimate,
    })
}

function* getCostEstimateData() {
  try {
    const res = yield getCostEstimateApi()
    yield put({
      type: GET_COST_ESTIMATE_SUCCESS,
      payload: res.data.getCostEstimate,
    })
  } catch (error) {
    yield put({ type: GET_COST_ESTIMATE_FAILURE, error })
  }
}

function* getCostEstimateSaga() {
  yield takeLatest(GET_COST_ESTIMATE, getCostEstimateData)
}

export default function* costEstimateSaga() {
  yield all([createOrUpdateCostEstimateSaga(), getCostEstimateSaga()])
}
