import { nameFormatter } from './helper'

export const getBoatDetailData = (boat: any) => {
  let boatObject: any = []
  let boatLocation,
    boatOwenerShip,
    boatInformation,
    boatEngine,
    michanicalSystem,
    electricalSystem,
    dimensions,
    commercial,
    amenities,
    accessories,
    navigationEquipments,
    other

  console.log(boat, 'boat.address')

  boatLocation = {
    title: 'Boat Location',
    country: (boat && boat.address && boat.address.length && boat.address[0] && boat.address[0].country) || '-',
    state: (boat && boat.address && boat.address.length && boat.address[0] && boat.address[0].state) || '-',
    address: (boat && boat.address && boat.address.length && boat.address[0] && boat.address[0].address1) || '-',
    zip: (boat && boat.address && boat.address.length && boat.address[0] && boat.address[0].zip) || '-',
    city: (boat && boat.address && boat.address.length && boat.address[0] && boat.address[0].city) || '-',
  }
  boatOwenerShip = {
    title: 'Boat Ownership',
    complianceLabelNumber: (boat && boat.complianceLabelNumber) || '-',
    hullSerialNumber: (boat && boat.hullSerialNumber) || '-',
    vehicleIdentificationNumber: (boat && boat.vehicleIdentificationNumber) || '-',
    licenceNo: (boat && boat.licenceNo) || '-',
    attachId: (boat && boat.attachId) || '-',
    ownershipAttachment: (boat && boat.ownershipAttachment) || '-',
  }
  boatInformation = {
    title: 'Boat Information',
    listedBy: (boat && boat.listedBy) || '-',
    boatType: (boat && boat.boatType && boat.boatType.name) || '-',
    boatStatus: (boat && boat.boatStatus && boat.boatStatus.alias) || '-',
    boatName: (boat && boat.boatName) || '-',
    boatParking: (boat && boat.boatParking && boat.boatParking.alias) || '-',
    trailer: (boat && boat.trailer) || '-',
    yearBuilt: (boat && boat.yearBuilt) || '-',
    manufacturedBy: (boat && boat.manufacturedBy) || '-',
    hullMaterial: (boat && boat.hullMaterial && boat.hullMaterial.alias) || '-',
    hullColor: (boat && boat.hullColor) || '-',
    usedHours: (boat && boat.usedHours) || '-',
  }
  boatEngine = {
    title: 'Boat Engine',
    noOfEngines: (boat && boat.noOfEngines) || '-',
    modelYear: (boat && boat.modelYear) || '-',
    fuelType: (boat && boat.fuelType && boat.fuelType.alias) || '-',
    fuelCapacity: (boat && boat.fuelCapacity) || '-',
    holdingCapacity: (boat && boat.holdingCapacity) || '-',
    freshWater: (boat && boat.freshWater) || '-',
    engineManufacturer: (boat && boat.engineManufacturer) || '-',
    // engineModel: boat && boat.engineModel || '-',
    engineHp: (boat && boat.engineHp) || '-',
    engineDrives: (boat && boat.engineDrives && boat.engineDrives.alias) || '-',
    engineStroke: (boat && boat.engineStroke && boat.engineStroke.alias) || '-',
  }
  michanicalSystem = {
    title: 'Michanical Sysyem',
    waterMarker: (boat && boat.waterMarker) || '-',
    bowThruster: (boat && boat.bowThruster) || '-',
    steeringSystem: (boat && boat.steeringSystem) || '-',
    stabilizerSystem: (boat && boat.stabilizerSystem) || '-',
    oilWaterSeparator: (boat && boat.oilWaterSeparator) || '-',
    fireBilgePump: (boat && boat.fireBilgePump) || '-',
  }

  electricalSystem = {
    title: 'Electrical System',
    output: (boat && boat.output) || '-',
    generators: (boat && boat.generators) || '-',
    emergencyGenerator: (boat && boat.emergencyGenerator) || '-',
    batteriesCount: (boat && boat.batteriesCount) || '-',
    batteryType: (boat && boat.batteryType) || '-',
  }
  dimensions = {
    title: 'Dimensions',
    decks: (boat && boat.decks) || '-',
    heightInFt: (boat && boat.heightInFt) || '-',
    lengthInFt: (boat && boat.lengthInFt) || '-',
    widthInFt: (boat && boat.widthInFt) || '-',
    weightInKg: (boat && boat.weightInKg) || '-',
    beam: (boat && boat.beam) || '-',
    draft: (boat && boat.draft) || '-',
    displacement: (boat && boat.displacement) || '-',
    mainSaloon: (boat && boat.mainSaloon && boat.mainSaloon.alias) || '-',
    mainSaloonConvertible: (boat && boat.mainSaloonConvertible && boat.mainSaloonConvertible.alias) || '-',
    numberOfHeads: (boat && boat.numberOfHeads) || '-',
    crewCabinCount: (boat && boat.crewCabinCount) || '-',
    crewBerthCount: (boat && boat.crewBerthCount) || '-',
    crewHeadsCount: (boat && boat.crewHeadsCount) || '-',
  }
  commercial = {
    title: 'Commercial',
    usage: (boat && boat.usage) || '-',
    accidentHistory: (boat && boat.accidentHistory) || '-',
    accidentDescribe: (boat && boat.accidentDescribe) || '-',
    boatReview: (boat && boat.boatReview) || '-',
    repairHistory: (boat && boat.repairHistory) || '-',
    lastMaintenance: (boat && boat.lastMaintenance) || '-',
    priceInUSD: (boat && boat.price) || '-',
    description: (boat && boat.description) || '-',
  }
  amenities = {
    title: 'Amenities',
    amenities:
      (boat &&
        boat.amenities &&
        boat.amenities.length &&
        nameFormatter(
          boat.amenities.map((item: any, index: number) => item.alias),
          ', '
        )) ||
      '-',
  }
  accessories = {
    title: 'Accessories',
    accessories:
      (boat &&
        boat.accessories &&
        boat.accessories.length &&
        nameFormatter(
          boat.accessories.map((item: any, index: number) => item.alias),
          ', '
        )) ||
      '-',
  }
  navigationEquipments = {
    title: 'Navigation Equipments',
    navigationEquipments:
      (boat &&
        boat.navigationEquipments &&
        boat.navigationEquipments.length &&
        nameFormatter(
          boat.navigationEquipments.map((item: any, index: number) => item.alias),
          ', '
        )) ||
      '-',
  }
  other = {
    title: 'Other Information',
    image: boat && boat.images,
    layout: boat && boat.layout,
    video: boat && boat.video,
  }

  boatObject.push(
    boatLocation,
    boatOwenerShip,
    boatInformation,
    boatEngine,
    michanicalSystem,
    electricalSystem,
    dimensions,
    commercial,
    amenities,
    accessories,
    navigationEquipments,
    other
  )
  return boatObject
}
