import { put, takeLatest, all } from 'redux-saga/effects'
import { client, websiteClient } from '../../../'
import {
  getSalesEngines,
  deleteSalesEngine,
  getAllSalesEngineStaticContent,
  createSalesaleEngineStaticContent,
  getAllSalesEnginPayment,
  getAllSalesEnginCommision,
  editCommisionSchema,
  getAllSalesEnginReleasePayment,
  releaseMoneyToUser,
  salesEngineRefundPayment,
} from './Schema'
import {
  GET_SALES_ENGINE_SUCCESS,
  GET_SALES_ENGINE_FAILURE,
  GET_SALES_ENGINE,
  ActionType,
  DELETE_SALES_ENGINE_SUCCESS,
  DELETE_SALES_ENGINE,
  DELETE_SALES_ENGINE_FAILURE,
  GET_SALES_ENGINE_STATIC_CONTENT,
  GET_SALES_ENGINE_STATIC_CONTENT_FAILURE,
  GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS,
  CREATE_SALES_ENGINE_STATIC_CONTENT,
  CREATE_SALES_ENGINE_STATIC_CONTENT_FAILURE,
  CREATE_SALES_ENGINE_STATIC_CONTENT_SUCCESS,
  CLEAR_SALES_ENGINE_FLAG,
  GET_SALES_ENGINE_PAYMENT,
  GET_SALES_ENGINE_PAYMENT_SUCCESS,
  GET_SALES_ENGINE_PAYMENT_FAILURE,
  getSalesEnginPaymentInput,
  GET_SALES_ENGINE_COMMISION,
  GET_SALES_ENGINE_COMMISION_SUCCESS,
  GET_SALES_ENGINE_COMMISION_FAILURE,
  EDIT_COMMISION,
  EDIT_COMMISION_SUCCESS,
  EDIT_COMMISION_ERROR,
  GET_SALES_ENGINE_RELEASE,
  GET_SALES_ENGINE_RELEASE_SUCCESS,
  GET_SALES_ENGINE_RELEASE_FAILURE,
  RELEASE_PAYMENT,
  RELEASE_PAYMENT_SUCCESS,
  RELEASE_PAYMENT_FAILURE,
  REFUND_PAYMENT,
  REFUND_PAYMENT_SUCCESS,
  REFUND_PAYMENT_FAILURE,
} from './Type'

function getSalesEngineApi(data: any) {
  return client
    .query({
      query: getSalesEngines,
      variables: {
        page: data.payload.page,
        limit: data.payload.limit,
        type: data.payload.type,
        buyItNow: data.payload.buyItNow,
      },
      fetchPolicy: 'no-cache',
    })
}

function getSalesEnginPaymentApi(input: getSalesEnginPaymentInput) {
  return client
    .query({
      query: getAllSalesEnginPayment,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function getSalesEnginCommisionApi(input: getSalesEnginPaymentInput) {
  return client
    .query({
      query: getAllSalesEnginCommision,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getSalesEngineData(data: any) {
  try {
    const res = yield getSalesEngineApi(data)
    yield put({
      type: GET_SALES_ENGINE_SUCCESS,
      payload: res.data.getSalesEngines,
    })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_FAILURE, error })
  }
}

function* getSalesEnginPayment(action: any) {
  try {
    const res = yield getSalesEnginPaymentApi(action.payload)
    yield put({
      type: GET_SALES_ENGINE_PAYMENT_SUCCESS,
      payload: res.data.getSalesEngineList,
    })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_PAYMENT_FAILURE, error })
  }
}

function* getSalesEngineSaga() {
  yield takeLatest(GET_SALES_ENGINE, getSalesEngineData)
}

function* getSalesEnginePaymentSaga() {
  yield takeLatest(GET_SALES_ENGINE_PAYMENT, getSalesEnginPayment)
}

// Commision
function* getSalesEngineCommision(action: any) {
  try {
    const res = yield getSalesEnginCommisionApi(action.payload)
    const resposne = res.data.getComissionByModule
    const arr = []
    for (let i in resposne) {
      if (i !== '__typename') {
        arr.push({ role: i, commision: resposne[i] })
      }
    }
    const result: any = {
      items: arr,
      total: arr.length,
    }

    yield put({ type: GET_SALES_ENGINE_COMMISION_SUCCESS, payload: result })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_COMMISION_FAILURE, error })
  }
}

function* getSalesEngineCommisionSaga() {
  yield takeLatest(GET_SALES_ENGINE_COMMISION, getSalesEngineCommision)
}

// Edit Commision
function editCommisionApi(input: any) {
  let obj = {
    payload: {
      [input.role]: +input.commision,
    },
  }
  return client
    .query({
      query: editCommisionSchema,
      variables: obj,
      fetchPolicy: 'no-cache',
    })
}

function* editCommisionData(action: any) {
  try {
    const res = yield editCommisionApi(action.payload)
    yield put({ type: EDIT_COMMISION_SUCCESS, payload: action.payload })
  } catch (error) {
    yield put({ type: EDIT_COMMISION_ERROR, error })
  }
}

function* editCommisionSaga() {
  yield takeLatest(EDIT_COMMISION, editCommisionData)
}

// Release Payment
function salesEngineReleasePaymentApi(input: any) {
  return client
    .query({
      query: getAllSalesEnginReleasePayment,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* salesEngineReleasePaymentData(action: any) {
  try {
    const res = yield salesEngineReleasePaymentApi(action.payload)
    yield put({ type: GET_SALES_ENGINE_RELEASE_SUCCESS, payload: res.data.getTransactionsToReleasesMoney })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_RELEASE_FAILURE, error })
  }
}

function* salesEngineReleasePaymentSaga() {
  yield takeLatest(GET_SALES_ENGINE_RELEASE, salesEngineReleasePaymentData)
}

function releasePaymentApi(input: any) {
  return websiteClient
    .query({
      query: releaseMoneyToUser,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* releasePaymentData(action: any) {
  try {
    const res = yield releasePaymentApi(action.payload)
    yield put({ type: RELEASE_PAYMENT_SUCCESS, payload: res.data.releaseMoneyToUser })
  } catch (error) {
    yield put({ type: RELEASE_PAYMENT_FAILURE, error })
  }
}

function* releasePaymentSaga() {
  yield takeLatest(RELEASE_PAYMENT, releasePaymentData)
}

function getSalesEngineStaticContentApi(data: any) {
  return client
    .query({
      query: getAllSalesEngineStaticContent,
      fetchPolicy: 'no-cache',
    })
}

function* getSalesEngineStaticContentData(data: any) {
  try {
    const res = yield getSalesEngineStaticContentApi(data)
    yield put({
      type: GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS,
      payload: res.data.getAllSalesEngineStaticContent,
    })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_STATIC_CONTENT_FAILURE, error })
  }
  yield put({ type: CLEAR_SALES_ENGINE_FLAG })
}

function* getSalesEngineStaticContentSaga() {
  yield takeLatest(GET_SALES_ENGINE_STATIC_CONTENT, getSalesEngineStaticContentData)
}

function deleteSalesEngineApi(data: any) {
  const id = data
  return client
    .mutate({
      mutation: deleteSalesEngine,
      variables: { id },
    })
}

function* deleteSalesEngineData(action: any) {
  try {
    const res = yield deleteSalesEngineApi(action.payload)
    yield put({
      type: DELETE_SALES_ENGINE_SUCCESS,
      payload: res.data.deleteSalesEngine,
    })
  } catch (error) {
    yield put({ type: DELETE_SALES_ENGINE_FAILURE, error })
  }
}

function* deleteSalesEngineSaga() {
  yield takeLatest(DELETE_SALES_ENGINE, deleteSalesEngineData)
}

function createSalesEngineStaticContentApi(data: any) {
  return client
    .mutate({
      mutation: createSalesaleEngineStaticContent,
      variables: { input: { contexts: [...data] } },
    })
}

function* createSalesEngineStaticContentData(action: any) {
  try {
    const res = yield createSalesEngineStaticContentApi(action.payload)
    yield put({
      type: CREATE_SALES_ENGINE_STATIC_CONTENT_SUCCESS,
      payload: res.data.createSalesaleEngineStaticContent,
    })
  } catch (error) {
    yield put({ type: CREATE_SALES_ENGINE_STATIC_CONTENT_FAILURE, error })
  }
}

function* createSalesEngineStaticContentSaga() {
  yield takeLatest(CREATE_SALES_ENGINE_STATIC_CONTENT, createSalesEngineStaticContentData)
}


// refund Payment
function salesEngineRefundPaymentApi(input: any) {
  return websiteClient
    .mutate({
      mutation: salesEngineRefundPayment,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* salesEngineRefundPaymentData(action: any) {
  try {
    const res = yield salesEngineRefundPaymentApi(action.payload)
    yield put({ type: REFUND_PAYMENT_SUCCESS, payload: res.data.getTransactionsToReleasesMoney, input: action.payload })
  } catch (error) {
    yield put({ type: REFUND_PAYMENT_FAILURE, error })
  }
}

function* salesEngineRefundPaymentSaga() {
  yield takeLatest(REFUND_PAYMENT, salesEngineRefundPaymentData)
}

export default function* salesEngineSaga() {
  yield all([
    getSalesEngineSaga(),
    getSalesEnginePaymentSaga(),
    getSalesEngineCommisionSaga(),
    salesEngineReleasePaymentSaga(),
    editCommisionSaga(),
    deleteSalesEngineSaga(),
    getSalesEngineStaticContentSaga(),
    createSalesEngineStaticContentSaga(),
    releasePaymentSaga(),
    salesEngineRefundPaymentSaga()
  ])
}
