import {
  ADD_BANNER,
  ActionType,
  SUCCESS_BANNER,
  CLEAR_RECORD,
  addBannerState,
  ERROR_BANNER,
  GET_CATEGORIES_WISE_BANNER,
  GET_CATEGORIES_WISE_BANNER_SUCCESS,
  DELETE_CATEGORY_ID_BANNER,
  DELETE_CATEGORY_ID_BANNER_SUCCESS,
  DELETE_CATEGORY_ID_BANNER_FAILURE,
  UPDATE_BANNER_SUCCESS,
  GET_CATEGORIES_WISE_BANNER_FAILURE,
} from './types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: addBannerState = {
  type: 'admin_banner',
  metatype: '',
  url: '',
  isHomeBannerCreated: false,
  isHomeBannerError: false,
  deleteHomeBannerSuccess: false,
  deleteHomeBannerError: false,
  isHomeBannerUpdateSuccess: false,
  isHomeBannerUpdateError: false,
  homeBanner: [],
  bannerLoader: false,
}

export function bannerReducer(state = initialState, action: ActionType): addBannerState {
  switch (action.type) {
    case GET_CATEGORIES_WISE_BANNER:
      const fieldName = action.payload.fieldName

      return {
        ...state,
        [fieldName]: [],
        bannerLoader: true,
        isHomeBannerError: false,
        isHomeBannerCreated: false,
        deleteHomeBannerSuccess: false,
        deleteHomeBannerError: false,
        isHomeBannerUpdateSuccess: false,
        isHomeBannerUpdateError: false,
      }
    case GET_CATEGORIES_WISE_BANNER_SUCCESS:
      const fieldNameSuccess = action.payload.fieldName

      return {
        ...state,
        bannerLoader: false,
        [fieldNameSuccess]: action.payload.data.getBannerByModule.items,
        [`${fieldNameSuccess}TotalRow`]: action.payload.data.getBannerByModule.total,
        isHomeBannerError: false,
        isHomeBannerCreated: false,
        deleteHomeBannerSuccess: false,
        deleteHomeBannerError: false,
      }
    case GET_CATEGORIES_WISE_BANNER_FAILURE:
      const fieldNameFail = action.payload
      return {
        ...state,
        [fieldNameFail]: [],
        bannerLoader: false,
        isHomeBannerError: false,
        isHomeBannerCreated: false,
        deleteHomeBannerSuccess: false,
        deleteHomeBannerError: false,
      }
    /** Add manege banner */
    case ADD_BANNER:
      return {
        ...state,
        bannerLoader: true,
      }
    case SUCCESS_BANNER:
      return {
        ...state,
        bannerLoader: false,
        isHomeBannerCreated: action.payload ? true : false,
        isHomeBannerError: false,
      }
    case ERROR_BANNER:
      return {
        ...state,
        bannerLoader: false,
        isHomeBannerError: action.payload ? true : false,
        isHomeBannerCreated: false,
      }
    case DELETE_CATEGORY_ID_BANNER:
      return {
        ...state,
        deleteHomeBannerSuccess: false,
        deleteHomeBannerError: false,
      }

    case DELETE_CATEGORY_ID_BANNER_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteHomeBannerSuccess: true,
        deleteHomeBannerError: false,
      }

    case DELETE_CATEGORY_ID_BANNER_FAILURE:
      return {
        ...state,
        deleteHomeBannerSuccess: false,
        deleteHomeBannerError: true,
      }
    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        isHomeBannerUpdateSuccess: action.payload ? true : false,
        isHomeBannerUpdateError: false,
      }
    case ERROR_BANNER:
      return {
        ...state,
        isHomeBannerError: action.payload ? true : false,
        isHomeBannerCreated: false,
      }
    /**
     * Reset flag
     */
    case CLEAR_RECORD:
      return {
        ...state,
        isHomeBannerCreated: false,
        isHomeBannerError: false,
      }
    default:
      return state
  }
}
