import {
  ActionType,
  userDocumentState,
  GET_USER_DOCUMENTS,
  GET_USER_DOCUMENTS_SUCCESS,
  GET_USER_DOCUMENTS_FAILURE,
  CHANGE_DOCUMENT_STATUS_SUCCESS,
  CHANGE_DOCUMENT_STATUS,
  CHANGE_DOCUMENT_STATUS_ERROR,
  CLEAR_DOCUMENT_STATUS,
  GET_DOCUMENT_URL,
  GET_DOCUMENT_URL_SUCCESS,
  GET_DOCUMENT_URL_ERROR,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: userDocumentState = {
  AdminUsersDocument: [],
  getSuccess: false,
  getError: false,
  AdminUsersDocumentTotalRow: 0,
  verifySuccess: false,
  verifyFail: false,
  verifyData: {},
  getDocumentUrlSuccess: false,
  getDocumentUrlError: false,
  getDocumentLink: '',
  governmentIdVerifiedLoad: false,
  urlLoader: false,
  getDocLoad: false,
}

export const userDocumentReducer = (state = initialState, action: ActionType): userDocumentState => {
  switch (action.type) {
    case GET_USER_DOCUMENTS:
      return {
        ...state,
        getDocLoad: true,
        getSuccess: false,
        getError: false,
      }

    case GET_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        getDocLoad: false,
        getError: false,
        AdminUsersDocument: action.payload && action.payload.getUserDocuments && action.payload.getUserDocuments.items,
        AdminUsersDocumentTotalRow: action.payload && action.payload.getUserDocuments && action.payload.getUserDocuments.total,
      }

    case GET_USER_DOCUMENTS_FAILURE:
      return {
        ...state,
        AdminUsersDocument: [],
        getDocLoad: false,
        getSuccess: false,
        getError: true,
      }

    case CHANGE_DOCUMENT_STATUS:
      return {
        ...state,
        verifySuccess: false,
        verifyFail: false,
        [action.payload.document]: true,
        verifyData: {},
      }

    case CHANGE_DOCUMENT_STATUS_SUCCESS: {
      const getRecord: any = state.AdminUsersDocument.find((item: any) => action.payload.res.id === item.id)

      let renderFilterData
      if (!getRecord.commercialLicence.length || !getRecord.governmentId.length) {
        renderFilterData = state.AdminUsersDocument.filter((item: any) => action.payload.res.id !== item.id)
      } else {
        const documentFilter = state.AdminUsersDocument.map((item: any) => {
          return {
            ...item,
            documentVerification:
              action.payload.res.id === item.id ? { ...action.payload.res.documentVerification } : item.documentVerification,
          }
        })
        const verifyCondition =
          (getRecord.commercialLicence.length && getRecord.documentVerification.commercialLicenceVerified) ||
          (getRecord.governmentId.length && getRecord.documentVerification.governmentIdVerified)
        renderFilterData = verifyCondition
          ? documentFilter.filter((item: any) => action.payload.res.id !== item.id)
          : documentFilter
      }
      console.log(getRecord, 'getRecord')

      NotificationWithIcon('success', 'Status Changed successfully')

      return {
        ...state,
        verifySuccess: true,
        [action.payload.document]: false,
        verifyFail: false,
        AdminUsersDocument: [...renderFilterData],
        verifyData: action.payload.verifyUserDocuments,
      }
    }

    case CHANGE_DOCUMENT_STATUS_ERROR:
      return {
        ...state,
        [action.payload.document]: false,
        verifySuccess: false,
        verifyFail: true,
        verifyData: action.payload,
      }

    case GET_DOCUMENT_URL:
      return {
        ...state,
        urlLoader: true,
        getDocumentUrlSuccess: false,
        getDocumentUrlError: false,
      }

    case GET_DOCUMENT_URL_SUCCESS:
      return {
        ...state,
        urlLoader: false,
        getDocumentUrlSuccess: true,
        getDocumentUrlError: false,
        getDocumentLink: action.payload.url,
      }

    case GET_DOCUMENT_URL_ERROR:
      return {
        ...state,
        urlLoader: false,
        getDocumentUrlSuccess: false,
        getDocumentUrlError: true,
      }

    case CLEAR_DOCUMENT_STATUS:
      return {
        ...state,
        getDocumentUrlSuccess: false,
        getDocumentUrlError: false,
        verifySuccess: false,
        verifyFail: false,
      }
    default:
      return state
  }
}
