import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'
import { Link } from 'react-router-dom'
import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import History from '../../History'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import { pagination, paginationPage } from '../../enums/enums'
import { getAllPages, deletePages, clearPagesFlag } from '../../store/staticContent/managePages/Action'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData, Sort } from '../../helpers/helper'
import WSIcon from '../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import { get } from 'lodash'

interface Props {
  type: string
  getAllPages: typeof getAllPages
  page: []
  history: typeof History
  deletePages: typeof deletePages
  clearPagesFlag: typeof clearPagesFlag
  pageSuccess: boolean
  deleteSuccess: boolean
  totalPage: number
}

interface State {
  getPageInfo: {
    page: number
    limit: number
    type: string
  }
  searchedValue: string
}

interface PageState {
  pageReducer: pageReducer
}

interface pageReducer {
  page: []
  pageSuccess: boolean
  deleteSuccess: boolean
  totalPage: number
}

class ManageHelpSection extends Component<Props, State> {
  state = {
    getPageInfo: {
      page: pagination.page,
      limit: pagination.limit,
      type: 'help-section',
    },
    searchedValue: '',
    columns: [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a: any, b: any) => Sort.StrOrNum(get(a, 'title', ''), get(b, 'title', '')),
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deletePages } = this.props
          return (
            <>
              <Button className="btn-edit mr-2" onClick={() => this.editPage(record)}>
                Edit
              </Button>
              <Button className="btn-delete" onClick={() => record?.id && confirmSubmitHandler(deletePages, record.id)}>
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { pageSuccess, clearPagesFlag, getAllPages, deleteSuccess } = props
    const { getPageInfo } = state

    if (pageSuccess) {
      clearPagesFlag()
    }

    if (deleteSuccess) {
      getAllPages(getPageInfo)
      clearPagesFlag()
    }

    return {}
  }

  // componentDidMount() {
  //   const { getAllPages } = this.props
  //   const { getPageInfo } = this.state
  //   getAllPages(getPageInfo)
  // }

  editPage = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-help-section', { page: { record, isEdit: true } })
  }

  onSearch = (value: any) => {
    this.setState({ searchedValue: value })
  }

  render() {
    const { columns, searchedValue } = this.state
    const { page, totalPage, getAllPages } = this.props

    return (
      <Card
        title="Manage Help Section"
        extra={
          <div className="d-flex flex-wrap">
            <div className="mr-10 mt-8">
              <TitleSearch onSearch={this.onSearch} />
            </div>
            <Button className="btn-add mt-8">
              <Link to="/create-help-section">
                <WSIcon type={<PlusOutlined />} /> Add Page
              </Link>
            </Button>
          </div>
        }
      >
        <ExpandTable
          columns={columns}
          tableData={getSearchedData(page, searchedValue)}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={totalPage}
          pageAction={getAllPages}
          page={paginationPage.boat}
          extraInput={{ type: 'help-section' }}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: PageState) => ({
  page: state.pageReducer.page,
  totalPage: state.pageReducer.totalPage,
  pageSuccess: state.pageReducer && state.pageReducer.pageSuccess,
  deleteSuccess: state.pageReducer && state.pageReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllPages: (data: any) => dispatch(getAllPages(data)),
  deletePages: (data: string) => dispatch(deletePages(data)),
  clearPagesFlag: () => dispatch(clearPagesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageHelpSection)
