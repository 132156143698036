import * as React from 'react'
import { Radio, Popover } from 'antd'
import WSIcon from './WSIcon'
import { CheckCircleOutlined, CloseCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'

const ThreeToggleSwitch = (props: any) => {
  const { status, toggleMarketStatus } = props

  return (
    <Radio.Group defaultValue={status} buttonStyle="solid" onChange={toggleMarketStatus}>
      <Popover content="Pending">
        <Radio.Button value={null}>
          <WSIcon type={<PauseCircleOutlined />} />
        </Radio.Button>
      </Popover>
      <Popover content="Approve">
        <Radio.Button value={true}>
          <WSIcon type={<CheckCircleOutlined />} />
        </Radio.Button>
      </Popover>
      <Popover content="Decline">
        <Radio.Button value={false}>
          <WSIcon type={<CloseCircleOutlined />} />
        </Radio.Button>
      </Popover>
    </Radio.Group>
  )
}

export default ThreeToggleSwitch
