import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card, Switch, Tag } from 'antd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { clearRoleFlag } from '../../../store/role/Action'
import { clearLoginFlag, changeAccountStatus } from '../../../store/login/Actions'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { getAdminUserRole, deleteAdminUserRole, clearAdminUserRoleFlag } from '../../../store/adminUserRole/Action'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { dateStringFormate, getSearchedData, displayDefaultValue } from '../../../helpers/helper'
import { TitleSearch } from '../../../component/SearchBar'
import { getAllBoatPagination, paginationPage, activeInactive, verifyOrNot, AdminPermissions } from '../../../enums/enums'
import FilterButton from '../../filters/FilterButton'
import { Link } from 'react-router-dom'

let pageFilterArray: any = []

interface Props {
  roles: []
  success: Boolean
  adminLoad: boolean
  getAdminUserRole: typeof getAdminUserRole
  users: []
  clearFlag: typeof clearLoginFlag
  clearRoleFlag: typeof clearLoginFlag
  location: Location
  changeAccountStatus: typeof changeAccountStatus
  deleteAdminUserRole: typeof deleteAdminUserRole
  clearAdminUserRoleFlag: typeof clearAdminUserRoleFlag
  deleteAdminUserSuccess: boolean
  role: string
  isFlag: boolean
  match: any
  history: any
  totalUserRow: any
}

export interface State {
  isRole: boolean
  role: string
  searchedUsers: any[]
  expandKeys: string[]
}

interface Location {
  state: any
}
interface TypeId {
  id: string
  type: string
}

interface Role {
  id: string
  alias: string
  role: string
  typeId: TypeId
}

class AccountUser extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isRole: false,
      role: '',
      searchedUsers: [],
      expandKeys: [],
    }
  }

  componentDidMount() {
    pageFilterArray = [
      {
        title: 'User Status',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'userStatus',
      },
    ]
  }

  static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { role } = state
    const { roles, getAdminUserRole, deleteAdminUserSuccess, clearAdminUserRoleFlag } = props

    if (props.match && props.match.params && props.match.params.role && role !== props.match.params.role) {
      const adminRole: any = roles && roles.find((item: any) => item.aliasName === props.match.params.role)
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
        roleType: props.match.params.role,
      }
      adminRole && getAdminUserRole(input)

      return {
        role: props.match.params.role,
      }
    }
    if (deleteAdminUserSuccess) {
      clearAdminUserRoleFlag()
      window.location.reload()
    }
    return null
  }

  columns = [
    {
      title: 'User Name',
      dataIndex: 'firstName',
      key: 'name',
      render(text: string, record: tableRecords) {
        return [record.firstName || '', record.lastName || ''].join(' ')
      },
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
      render: (text: string, record: tableRecords, index: number) => {
        const { changeAccountStatus } = this.props

        return (
          <Switch
            checked={record.userStatus}
            onChange={() =>
              changeAccountStatus({
                fromPage: 'admin',
                value: record.userStatus,
                column: 'userStatus',
                id: record.id,
              })
            }
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        )
      },
    },
    {
      title: 'Registered Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: tableRecords, index: number) => <> {dateStringFormate(text)} </>,
      sorter: (a: any, b: any) => a.createdAt.length - b.createdAt.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      render: (text: string, record: tableRecords, index: number) => (
        <Button
          className="btn-delete"
          onClick={() => {
            record && record.id && confirmSubmitHandler(this.props.deleteAdminUserRole, record.id)
          }}
        >
          Delete
        </Button>
      ),
    },
  ]

  onSearch = (value: any) => {
    const { users } = this.props
    const searchedUsers = getSearchedData(users, value)

    this.setState({
      searchedUsers,
    })
  }

  render() {
    const { users, history, getAdminUserRole, roles, match, totalUserRow, adminLoad } = this.props
    const { searchedUsers, expandKeys } = this.state

    return (
      <Card
        title={displayDefaultValue(history && history.location && history.location.state && history.location.state.titleValue)}
        extra={<TitleSearch onSearch={this.onSearch} />}
      >
        {users && users.length ? (
          <FilterButton
            filterArray={pageFilterArray}
            triggerAction={getAdminUserRole}
            extraInput={{ roleType: match.params.role }}
          />
        ) : (
          <></>
        )}
        <ExpandTable
          columns={this.columns}
          tableData={searchedUsers?.length ? searchedUsers : users}
          expandHtml={false}
          totalUsersRow={totalUserRow}
          isExpand={false}
          pageAction={getAdminUserRole}
          roles={roles}
          role={match.params.role}
          page={paginationPage.adminUser}
          extraInput={{ roleType: match.params.role }}
          tableLoading={adminLoad}
          expandedRowRender={(record: any) => (
            <div>
              <p>
                <b>Country:</b> {record.address[0].country}
              </p>
              <p className="admin-perms-tags">
                <b className="mr-2">Permissions:</b>{' '}
                {record.permissions?.map((p: keyof typeof AdminPermissions) => (
                  <Tag color="geekblue">{AdminPermissions[p].label}</Tag>
                ))}
              </p>
              <p>
                <b className="mr-2">Actions:</b>{' '}
                <Button type="primary">
                  <Link
                    to={{
                      pathname: '/edit-admin-user',
                      state: record,
                    }}
                  >
                    Edit
                  </Link>
                </Button>
              </p>
            </div>
          )}
          onExpand={(expanded: boolean, record: tableRecords) => {
            const { id } = record
            if (expanded && id) {
              this.setState({ expandKeys: [id] })
            } else {
              this.setState({ expandKeys: [] })
            }
          }}
          rowKey={(record: tableRecords) => record?.id}
          expandedRowKeys={expandKeys}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    roles: state.roleState.roles,
    success: state.userLoginState.success,
    users: state.adminUserRoleReducer.adminUserRole,
    deleteAdminUserSuccess: state.adminUserRoleReducer && state.adminUserRoleReducer.deleteAdminUserSuccess,
    totalUserRow: state.adminUserRoleReducer && state.adminUserRoleReducer.totalUserRow,
    adminLoad: state.adminUserRoleReducer.adminLoad,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAdminUserRole: (data: string) => dispatch(getAdminUserRole(data)),
    clearFlag: () => dispatch(clearLoginFlag()),
    clearRoleFlag: () => dispatch(clearRoleFlag()),
    changeAccountStatus: (data: any) => dispatch(changeAccountStatus(data)),
    deleteAdminUserRole: (data: string) => dispatch(deleteAdminUserRole(data)),
    clearAdminUserRoleFlag: () => dispatch(clearAdminUserRoleFlag()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountUser)
