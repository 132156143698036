export interface userGuideState {
  userGuides: []
  userGuideSuccess: boolean
  userGuideError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  userGuidesTotalRow: number
}

export interface userGuideData {
  id: string
  title: string
  description: string
  status: boolean
}

export const GET_ALL_USER_GUIDE_LIST = 'GET_ALL_USER_GUIDE_LIST'
export const GET_ALL_USER_GUIDE_LIST_SUCCESS = 'GET_ALL_USER_GUIDE_LIST_SUCCESS'
export const GET_ALL_USER_GUIDE_LIST_FAILURE = 'GET_ALL_USER_GUIDE_LIST_FAILURE'

export const CREATE_USER_GUIDE_DETAIL = 'CREATE_USER_GUIDE_DETAIL'
export const CREATE_USER_GUIDE_DETAIL_SUCCESS = 'CREATE_USER_GUIDE_DETAIL_SUCCESS'
export const CREATE_USER_GUIDE_DETAIL_FAILURE = 'CREATE_USER_GUIDE_DETAIL_FAILURE'

export const UPDATE_USER_GUIDE_DETAIL = 'UPDATE_USER_GUIDE_DETAIL'
export const UPDATE_USER_GUIDE_DETAIL_SUCCESS = 'UPDATE_USER_GUIDE_DETAIL_SUCCESS'
export const UPDATE_USER_GUIDE_DETAIL_FAILURE = 'UPDATE_USER_GUIDE_DETAIL_FAILURE'

export const DELETE_USER_GUIDE_DETAIL = 'DELETE_USER_GUIDE_DETAIL'
export const DELETE_USER_GUIDE_DETAIL_SUCCESS = 'DELETE_USER_GUIDE_DETAIL_SUCCESS'
export const DELETE_USER_GUIDE_DETAIL_FAILURE = 'DELETE_USER_GUIDE_DETAIL_FAILURE'

export const CLEAR_USER_GUIDE_FLAG = 'CLEAR_USER_GUIDE_FLAG'

export const CHANGE_USER_GUIDE_STATUS = 'CHANGE_USER_GUIDE_STATUS'
export const CHANGE_USER_GUIDE_STATUS_SUCCESS = 'CHANGE_USER_GUIDE_STATUS_SUCCESS'
export const CHANGE_USER_GUIDE_STATUS_FAILURE = 'CHANGE_USER_GUIDE_STATUS_FAILURE'

interface getManageUserGuide {
  type: typeof GET_ALL_USER_GUIDE_LIST
  payload: userGuideData
}

interface getManageUserGuideSuccess {
  type: typeof GET_ALL_USER_GUIDE_LIST_SUCCESS
  payload: any
}

interface getManageUserGuideFailure {
  type: typeof GET_ALL_USER_GUIDE_LIST_FAILURE
  payload: any
}

interface createManageUserGuide {
  type: typeof CREATE_USER_GUIDE_DETAIL
  payload: userGuideData
}

interface createManageUserGuideSuccess {
  type: typeof CREATE_USER_GUIDE_DETAIL_SUCCESS
  payload: any
}

interface createManageUserGuideFailure {
  type: typeof CREATE_USER_GUIDE_DETAIL_FAILURE
  payload: any
}

interface updateManageUserGuide {
  type: typeof UPDATE_USER_GUIDE_DETAIL
  payload: userGuideData
}

interface updateManageUserGuideSuccess {
  type: typeof UPDATE_USER_GUIDE_DETAIL_SUCCESS
  payload: userGuideData
}

interface updateManageUserGuideFailure {
  type: typeof UPDATE_USER_GUIDE_DETAIL_FAILURE
  payload: userGuideData
}

interface deleteManageUserGuide {
  type: typeof DELETE_USER_GUIDE_DETAIL
  payload: userGuideData
}

interface deleteManageUserGuideSuccess {
  type: typeof DELETE_USER_GUIDE_DETAIL_SUCCESS
  payload: userGuideData
}

interface deleteManageUserGuideFailure {
  type: typeof DELETE_USER_GUIDE_DETAIL_FAILURE
  payload: userGuideData
}

interface clearUserGuideFlag {
  type: typeof CLEAR_USER_GUIDE_FLAG
  payload: any
}

interface changeUserGuideStatus {
  type: typeof CHANGE_USER_GUIDE_STATUS
  payload: any
}

interface changeUserGuideStatusSuccess {
  type: typeof CHANGE_USER_GUIDE_STATUS_SUCCESS
  payload: any
}

interface changeUserGuideStatusFailure {
  type: typeof CHANGE_USER_GUIDE_STATUS_FAILURE
  payload: any
}

export type ActionType =
  | getManageUserGuide
  | getManageUserGuideSuccess
  | getManageUserGuideFailure
  | createManageUserGuide
  | createManageUserGuideSuccess
  | createManageUserGuideFailure
  | updateManageUserGuide
  | updateManageUserGuideSuccess
  | updateManageUserGuideFailure
  | deleteManageUserGuide
  | deleteManageUserGuideSuccess
  | deleteManageUserGuideFailure
  | clearUserGuideFlag
  | changeUserGuideStatus
  | changeUserGuideStatusSuccess
  | changeUserGuideStatusFailure
