import gql from 'graphql-tag'

export const sendOTPAndGetToken = gql`
  mutation sendOTPAndGetToken($newPassword: String!, $oldPassword: String, $email: String) {
    sendOTPAndGetToken(newPassword: $newPassword, oldPassword: $oldPassword, email: $email)
  }
`

export const verifyOTPAndChangePassword = gql`
  mutation verifyOTPAndChangePassword($otp: String!, $token: String!) {
    verifyOTPAndChangePassword(otp: $otp, token: $token)
  }
`
