import gql from 'graphql-tag'

export const createCountry = gql`
  mutation createCountry($country: CountryInput!) {
    createCountry(country: $country) {
      id
    }
  }
`

export const getAllCountries = gql`
  query getAllCountries($page: Int, $limit: Int) {
    getAllCountries(page: $page, limit: $limit) {
      items {
        id
        name
        salesEngineStatus
        ISOCode
        countryPhoneCode
        boatOwerStatus
        brokerAndDealerStatus
        boatManufacturerStatus
        serviceStatus
        marinaStorageStatus
        rentStatus
        sellerStatus
        ShipperStatus
        mamberStatus
        bookNowStatus
        surveyorStatus
        agentStatus
        auctionStatus
        images {
          id
          url
          name
        }
      }
      total
    }
  }
`

export const searchCountry = gql`
  query searchCountry( $query: String!, $page: Int, $limit: Int) {
    searchCountry(query: $query, page: $page, limit: $limit) {
      items {
        id
        name
        salesEngineStatus
        ISOCode
        countryPhoneCode
        boatOwerStatus
        brokerAndDealerStatus
        boatManufacturerStatus
        serviceStatus
        marinaStorageStatus
        rentStatus
        sellerStatus
        ShipperStatus
        mamberStatus
        bookNowStatus
        surveyorStatus
        agentStatus
        auctionStatus
        images {
          id
          url
          name
        }
      }
      total
    }
  }
`

export const updateCountry = gql`
  mutation updateCountry($country: CountryInput!) {
    updateCountry(country: $country) {
      id
    }
  }
`

export const deleteCountry = gql`
  mutation deleteCountry($id: String!) {
    deleteCountry(id: $id) {
      id
    }
  }
`

export const changeCountryStatus = gql`
  mutation changeCountryStatus($id: String!, $column: String!, $value: Boolean!) {
    changeCountryStatus(id: $id, column: $column, value: $value) {
      id
      name
      salesEngineStatus
      ISOCode
      countryPhoneCode
      boatOwerStatus
      brokerAndDealerStatus
      boatManufacturerStatus
      serviceStatus
      marinaStorageStatus
      rentStatus
      sellerStatus
      ShipperStatus
      mamberStatus
      bookNowStatus
      surveyorStatus
      agentStatus
      auctionStatus
    }
  }
`
