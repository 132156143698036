export interface pageInformationState {
  pageInfoSuccess: boolean
  allPageInformation: pageInformation[]
  registrationContents: pageInformation[]
  storedType: string
  registrationContentSuccess: boolean
  registrationContentError: boolean
  isLoading: boolean
}

export interface pageInformation {
  id: string
  uiType?: string
  module: string
  header: string
  content: string
}

export const GET_ALL_PAGE_INFORMATION = 'GET_ALL_PAGE_INFORMATION'
export const GET_ALL_PAGE_INFORMATION_SUCCESS = 'GET_ALL_PAGE_INFORMATION_SUCCESS'
export const GET_ALL_PAGE_INFORMATION_FAILURE = 'GET_ALL_PAGE_INFORMATION_FAILURE'

export const CREATE_REGISTRATION_CONTENT = 'CREATE_REGISTRATION_CONTENT'
export const CREATE_REGISTRATION_CONTENT_SUCCESS = 'CREATE_REGISTRATION_CONTENT_SUCCESS'
export const CREATE_REGISTRATION_CONTENT_FAILURE = 'CREATE_REGISTRATION_CONTENT_FAILURE'

export const CLEAR_CREATE_REGISTRATION_CONTENT_FLAG = 'CLEAR_CREATE_REGISTRATION_CONTENT_FLAG'

interface getAllPageInformation {
  type: typeof GET_ALL_PAGE_INFORMATION
  payload: any
}
interface getAllPageInformationSuccess {
  [x: string]: string
  type: typeof GET_ALL_PAGE_INFORMATION_SUCCESS
  payload: any
}
interface getAllPageInformationFailure {
  type: typeof GET_ALL_PAGE_INFORMATION_FAILURE
  payload: any
}

interface createRegistrationContent {
  type: typeof CREATE_REGISTRATION_CONTENT
  payload: any
}
interface createRegistrationContentSuccess {
  type: typeof CREATE_REGISTRATION_CONTENT_SUCCESS
  payload: pageInformation[]
}
interface createRegistrationContentFailure {
  type: typeof CREATE_REGISTRATION_CONTENT_FAILURE
  payload: any
}

interface clearCreateRegistrationContentFlag {
  type: typeof CLEAR_CREATE_REGISTRATION_CONTENT_FLAG
  payload: any
}

export type ActionType =
  | getAllPageInformation
  | getAllPageInformationSuccess
  | getAllPageInformationFailure
  | createRegistrationContent
  | createRegistrationContentSuccess
  | createRegistrationContentFailure
  | clearCreateRegistrationContentFlag
