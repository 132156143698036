import {
  LOGIN_USER,
  ActionType,
  SUCCESS_LOGIN,
  CLEAR_RECORD,
  userLoginState,
  ERROR_LOGIN,
  ERROR_COMMON,
  GET_ALL_LOOKUPS,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_ERROR,
  GET_TRIP_LOOKUPS,
  GET_TRIP_LOOKUPS_SUCCESS,
  GET_TRIP_LOOKUPS_ERROR,
  CLEAR_ALL_LOOKUPS,
  lookupState,
  GET_ALL_TYPES,
  GET_ALL_TYPES_SUCCESS,
  GET_ALL_TYPES_ERROR,
  CREATE_LOOKUP,
  CREATE_LOOKUP_SUCCESS,
  CREATE_LOOKUP_ERROR,
  EDIT_LOOKUP,
  EDIT_LOOKUP_SUCCESS,
  EDIT_LOOKUP_ERROR,
  DELETE_LOOKUP,
  DELETE_LOOKUP_SUCCESS,
  DELETE_LOOKUP_ERROR,
} from './Types'
import { objectToSingleDataArray, getUniqueArray } from '../../helpers/helper'
import { Item } from 'rc-menu'
import NotificationWithIcon from '../../component/notification/Notification'

const initialState: userLoginState | lookupState = {
  login: {
    username: '',
    password: '',
  },
  isAuth: '',
  successMessage: false,
  errorMessage: '',
  isCreated: false,
  success: false,
  error: false,
  users: [],
  accountStatusError: false,
  totalUsersRow: 0,
  getLookupSuccess: false,
  getLookupError: false,
  lookupsData: [],
  totalLookupsRow: 0,
  getTypeSuccess: false,
  getTypeError: false,
  typesData: [],
  totalTypesRow: 0,
  createSuccess: false,
  createError: false,
  deleteSuccess: false,
  deleteError: false,
}

export function userLoginReducer(state = initialState, action: ActionType): userLoginState | lookupState {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
      }
    case SUCCESS_LOGIN:
      return {
        ...state,
        successMessage: action.payload ? true : false,
        isCreated: true,
        isAuth: action.payload,
      }
    case ERROR_LOGIN:
      return {
        ...state,
        errorMessage: action.payload || 'Error!',
        isCreated: true,
      }

    case ERROR_COMMON:
      return {
        ...state,
        errorMessage: action.payload || 'Error!',
      }

    case CLEAR_RECORD:
      return {
        ...state,
        successMessage: false,
        isCreated: false,
        errorMessage: '',
        success: false,
        error: false,
        accountStatusError: false,
      }
    case GET_ALL_LOOKUPS:
      return {
        ...state,
        getLookupSuccess: false,
        getLookupError: false,
      }
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        getLookupSuccess: true,
        getLookupError: false,
        lookupsData: action.payload.items,
        totalLookupsRow: action.payload.total,
      }
    case GET_ALL_LOOKUPS_ERROR:
      return {
        ...state,
        getLookupSuccess: true,
        getLookupError: false,
      }
    case GET_TRIP_LOOKUPS:
      return {
        ...state,
        tripLookups: [],
        tripLookupsSuccess: false,
        tripLookupsError: false,
      }
    case GET_TRIP_LOOKUPS_SUCCESS:
      return {
        ...state,
        tripLookups: action.payload.items,
        tripLookupsSuccess: true,
        tripLookupsError: false,
      }
    case GET_TRIP_LOOKUPS_ERROR:
      return {
        ...state,
        tripLookupsSuccess: false,
        tripLookupsError: true,
      }

    case GET_ALL_TYPES:
      return {
        ...state,
        getTypeSuccess: false,
        getTypeError: false,
      }
    case GET_ALL_TYPES_SUCCESS:
      return {
        ...state,
        getTypeSuccess: true,
        getTypeError: false,
        typesData: action.payload.items,
        totalTypesRow: action.payload.total,
      }
    case GET_ALL_TYPES_ERROR:
      return {
        ...state,
        getTypeSuccess: true,
        getTypeError: false,
      }
    case CREATE_LOOKUP:
      return {
        ...state,
        createSuccess: false,
        createError: false,
      }
    case CREATE_LOOKUP_SUCCESS:
      NotificationWithIcon('success', 'Create Successfully')
      return {
        ...state,
        createSuccess: true,
        createError: false,
        lookupsData: getUniqueArray([action.payload, ...state.lookupsData], (it: any) => it.id),
      }
    case CREATE_LOOKUP_ERROR:
      return {
        ...state,
        createSuccess: true,
        createError: false,
      }
    case EDIT_LOOKUP:
      return {
        ...state,
        editSuccess: false,
        editError: false,
      }
    case EDIT_LOOKUP_SUCCESS:
      NotificationWithIcon('success', 'Update Successfully')
      let ar = [...state.lookupsData]
      ar.map((item, index) => {
        if (item.id === action.payload.id) {
          ar[index] = action.payload
        }
        return item
      })
      return {
        ...state,
        editSuccess: true,
        editError: false,
        // lookupsData: getUniqueArray(
        //   [...state.lookupsData, action.payload],
        //   (it: any) => it.id
        // ),
        lookupsData: ar,
      }
    case EDIT_LOOKUP_ERROR:
      return {
        ...state,
        editSuccess: true,
        editError: false,
      }
    case DELETE_LOOKUP:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }
    case DELETE_LOOKUP_SUCCESS:
      NotificationWithIcon('success', 'Status changed')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
        lookupsData: state.lookupsData.map((item: any) => {
          return {
            ...item,
            isActive: item.id === action.payload.id ? action.payload.isActive : item.isActive,
          }
        }),
      }
    case DELETE_LOOKUP_ERROR:
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }
    case CLEAR_ALL_LOOKUPS:
      return {
        ...state,
        getLookupSuccess: false,
        getLookupError: false,
        getTypeSuccess: false,
        getTypeError: false,
        deleteSuccess: false,
        deleteError: false,
        editSuccess: false,
        editError: false,
        createSuccess: false,
        createError: false,
      }
    default:
      return state
  }
}
