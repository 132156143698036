import {
  canadianTaxData,
  CREATE_CANADIAN_TAX,
  GET_ALL_CANADIAN_TAX,
  UPDATE_CANADIAN_TAX,
  DELETE_CANADIAN_TAX,
  CLEAR_CANADIAN_TAX_FLAG,
  CHANGE_TAX_STATUS,
} from './Type'

export function createCanadianTax(data: canadianTaxData) {
  return {
    type: CREATE_CANADIAN_TAX,
    payload: data,
  }
}

export function getCanadianTax() {
  return {
    type: GET_ALL_CANADIAN_TAX,
  }
}

export function updateCanadianTax(data: canadianTaxData) {
  return {
    type: UPDATE_CANADIAN_TAX,
    payload: data,
  }
}

export function deleteCanadianTax(data: canadianTaxData) {
  return {
    type: DELETE_CANADIAN_TAX,
    payload: data,
  }
}

export function clearFlag() {
  return {
    type: CLEAR_CANADIAN_TAX_FLAG,
  }
}

export function changeTaxStatus(data: any) {
  return {
    type: CHANGE_TAX_STATUS,
    payload: data,
  }
}
