import gql from 'graphql-tag'

export const getManagePasswordContent = gql`
  query getManagePasswordContent {
    getManagePasswordContent {
      id
      title
      content
      buttonText
      otherDescription
      icon {
        id
        url
      }
    }
  }
`

export const createOrUpdateManagePasswordContent = gql`
  mutation createOrUpdateManagePasswordContent($input: ManagePasswordContentInput!) {
    createOrUpdateManagePasswordContent(input: $input) {
      id
      title
      content
      buttonText
      otherDescription
      icon {
        id
        url
      }
    }
  }
`
