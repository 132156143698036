import {
  ActionType,
  smsTemplateState,
  GET_ALL_SMS_TEMPLATES,
  GET_ALL_SMS_TEMPLATES_SUCCESS,
  GET_ALL_SMS_TEMPLATES_FAILURE,
  ADD_SMS_TEMPLATE,
  ADD_SMS_TEMPLATE_SUCCESS,
  ADD_SMS_TEMPLATE_FAILURE,
  GET_SINGLE_SMS_TEMPLATES,
  GET_SINGLE_SMS_TEMPLATES_SUCCESS,
  GET_SINGLE_SMS_TEMPLATES_FAILURE,
  UPDATE_SMS_TEMPLATE,
  UPDATE_SMS_TEMPLATE_SUCCESS,
  UPDATE_SMS_TEMPLATE_FAILURE,
  DELETE_SMS_TEMPLATE,
  DELETE_SMS_TEMPLATE_SUCCESS,
  DELETE_SMS_TEMPLATE_FAILURE,
  CLEAR_SMS_TEMPLATE_FLAG,
  SEARCH_TEMPLATE,
  SEARCH_TEMPLATE_SUCCESS,
  SEARCH_TEMPLATE_FAILURE,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: smsTemplateState = {
  smsTemplate: {
    id: '',
    type: '',
    body: '',
    updatedAt: new Date(),
  },
  smsTemplatesTotalRow: 0,
  smsTemplates: [],
  smsTemplateSuccess: false,
  smsTemplateError: false,
  deleteSmsTemplateSuccess: false,
  deleteSmsTemplateError: false,
  isLoading: false,
}

export const smsTemplateReducer = (state = initialState, action: ActionType): smsTemplateState => {
  switch (action.type) {
    case ADD_SMS_TEMPLATE:
      return {
        ...state,
        smsTemplateSuccess: false,
        smsTemplateError: false,
      }

    case ADD_SMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        smsTemplateSuccess: true,
      }

    case ADD_SMS_TEMPLATE_FAILURE:
      return {
        ...state,
        smsTemplateError: true,
      }

    case GET_ALL_SMS_TEMPLATES:
      return {
        ...state,
        isLoading: true,
        smsTemplates: [],
      }

    case GET_ALL_SMS_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        smsTemplates: action.payload.items,
        smsTemplatesTotalRow: action.payload.total,
      }

    case GET_ALL_SMS_TEMPLATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        smsTemplates: [],
      }

    case GET_SINGLE_SMS_TEMPLATES:
      return {
        ...state,
        smsTemplate: {
          id: '',
          type: '',
          body: '',
          updatedAt: new Date(),
        },
      }

    case GET_SINGLE_SMS_TEMPLATES_SUCCESS:
      return {
        ...state,
        smsTemplate: action.payload,
      }

    case GET_SINGLE_SMS_TEMPLATES_FAILURE:
      return {
        ...state,
        smsTemplate: {
          id: '',
          type: '',
          body: '',
          updatedAt: new Date(),
        },
      }

    case UPDATE_SMS_TEMPLATE:
      return {
        ...state,
        smsTemplateSuccess: false,
        smsTemplateError: false,
      }

    case UPDATE_SMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        smsTemplateSuccess: true,
      }

    case UPDATE_SMS_TEMPLATE_FAILURE:
      return {
        ...state,
        smsTemplateError: true,
      }

    case DELETE_SMS_TEMPLATE:
      return {
        ...state,
        deleteSmsTemplateSuccess: false,
        deleteSmsTemplateError: false,
      }

    case DELETE_SMS_TEMPLATE_SUCCESS:
      const { smsTemplates } = state
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        smsTemplates: smsTemplates.filter(item => item.id !== action.payload.id),
        deleteSmsTemplateSuccess: true,
      }

    case DELETE_SMS_TEMPLATE_FAILURE:
      return {
        ...state,
        deleteSmsTemplateError: true,
      }

    case CLEAR_SMS_TEMPLATE_FLAG:
      return {
        ...state,
        smsTemplateSuccess: false,
        smsTemplateError: false,
        deleteSmsTemplateSuccess: false,
        deleteSmsTemplateError: false,
      }

    case SEARCH_TEMPLATE:
      return {
        ...state, 
        isLoading: true,
      }

    case SEARCH_TEMPLATE_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        smsTemplates: action.payload.items,
        smsTemplatesTotalRow: action.payload.total,
      }

    case SEARCH_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}
