import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { VerticalGraph } from '../../../component/charts/VerticalGraph'
import { Row, Col } from 'antd'
import { getAdminUsersCount, clearAdminUsersFlag } from '../../../store/dashboard/adminUsers/Action'
import { getDataFromArray } from '../../../helpers/helper'
import UserTypeCountCard from '../../../component/UserTypeCountCard'

export interface Props {
  getAdminUsersCount: typeof getAdminUsersCount
  adminUsers: []
  adminCountSuccess: boolean
  clearAdminUsersFlag: typeof clearAdminUsersFlag
}

class AdminUsers extends Component<Props> {
  componentDidMount() {
    const { getAdminUsersCount, clearAdminUsersFlag } = this.props
    getAdminUsersCount()
  }

  render() {
    const { adminUsers } = this.props

    return (
      <>
        <div style={{ background: '#ECECEC', padding: '30px' }}>
          <Row gutter={16}>
            {adminUsers.map((element: any) => {
              return (
                <Col sm={6} className="mb-20">
                  <UserTypeCountCard item={element} />
                </Col>
              )
            })}
          </Row>
        </div>

        <br />

        <VerticalGraph
          width={8}
          height={3}
          mainLabel={'ADMIN USERS'}
          labels={getDataFromArray(adminUsers, 'role')}
          data={getDataFromArray(adminUsers, 'count')}
        />
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  adminUsers: state.adminUserCountReducer && state.adminUserCountReducer.adminUsers,
  adminCountSuccess: state.adminUserCountReducer && state.adminUserCountReducer.adminCountSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdminUsersCount: () => dispatch(getAdminUsersCount()),
  clearAdminUsersFlag: () => dispatch(getAdminUsersCount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers)
