import {
  ActionType,
  downloadsState,
  GET_ALL_DOWNLOADS,
  GET_ALL_DOWNLOADS_SUCCESS,
  GET_ALL_DOWNLOADS_FAILURE,
  CREATE_DOWNLOADS,
  CREATE_DOWNLOADS_SUCCESS,
  CREATE_DOWNLOADS_FAILURE,
  UPDATE_DOWNLOADS,
  UPDATE_DOWNLOADS_SUCCESS,
  UPDATE_DOWNLOADS_FAILURE,
  DELETE_DOWNLOADS_SUCCESS,
  DELETE_DOWNLOADS_FAILURE,
  CLEAR_DOWNLOADS_FLAG,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: downloadsState = {
  downloads: [],
  downloadsSuccess: false,
  downloadsError: false,
  deleteSuccess: false,
  deleteError: false,
  downloadsTotal: 0,
}

export const downloadsReducer = (state = initialState, action: ActionType): downloadsState => {
  switch (action.type) {
    case GET_ALL_DOWNLOADS:
      return {
        ...state,
        downloadsSuccess: false,
        downloadsError: false,
      }
    case GET_ALL_DOWNLOADS_SUCCESS:
      return {
        ...state,
        downloadsSuccess: true,
        downloadsError: false,
        downloads: action.payload.items,
        downloadsTotal: action.payload.total,
      }
    case GET_ALL_DOWNLOADS_FAILURE:
      return {
        ...state,
        downloadsSuccess: false,
        downloadsError: true,
      }

    case CREATE_DOWNLOADS:
      return {
        ...state,
        downloadsSuccess: false,
        downloadsError: false,
      }
    case CREATE_DOWNLOADS_SUCCESS:
      return {
        ...state,
        downloadsSuccess: true,
        downloadsError: false,
      }
    case CREATE_DOWNLOADS_FAILURE:
      return {
        ...state,
        downloadsSuccess: false,
        downloadsError: true,
      }

    case UPDATE_DOWNLOADS:
      return {
        ...state,
        downloadsSuccess: false,
        downloadsError: false,
      }
    case UPDATE_DOWNLOADS_SUCCESS:
      return {
        ...state,
        downloadsSuccess: true,
        downloadsError: false,
      }
    case UPDATE_DOWNLOADS_FAILURE:
      return {
        ...state,
        downloadsSuccess: false,
        downloadsError: true,
      }

    case DELETE_DOWNLOADS_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }
    case DELETE_DOWNLOADS_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case CLEAR_DOWNLOADS_FLAG:
      return {
        ...state,
        downloadsSuccess: false,
        downloadsError: false,
        deleteSuccess: false,
        deleteError: false,
      }
    default:
      return state
  }
}
