import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import Login from '../container/login'
import RecoverAccount from '../container/login/RecoverAccount'

const LoginRoute = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/recover-account" exact component={RecoverAccount} />
        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
export default LoginRoute
