import gql from 'graphql-tag'

export const getAdminPermissions = gql`
  {
    getAdminPermissions {
      id
      role{
        aliasName
      }
      permissions
      isSuperAdmin
    }
  }
`
