import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card, Switch } from 'antd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import { getAllBoatServiceAction, changeYatchServiceAction, clearBoatFlag } from '../../store/pendingAds/Action'
import { pendingAdsState } from '../../store/pendingAds/Types'
import { TitleSearch } from '../../component/SearchBar'
import { formateDate, viewServiceBoatHandler, displayDefaultValue, removePTag } from '../../helpers/helper'
import { fillField, getAllBoatPagination, paginationPage } from '../../enums/enums'
import ApproveButton from '../../component/ApproveButton'
import { goToDashboardAction, clearGoToDashboard } from '../../store/dashboard/frontUsers/Action'
import CommonModal from '../../component/modal/CommonModal'
import NotificationWithIcon from '../../component/notification/Notification'
import { RejectionMailEditor } from '../../component/RejectionMailEditor'
import { LogType, PendingAds } from './type/pending-ads.type'

interface Props {
  getAllBoatServiceAction: typeof getAllBoatServiceAction
  allBoatService: []
  history: any
  changeYatchServiceAction: typeof changeYatchServiceAction
  goToDashboardAction: typeof goToDashboardAction
  clearGoToDashboard: typeof clearGoToDashboard
  dashboardLink?: string
  dashboardLinkSuccess?: boolean
  isLoading: boolean
  totalBoatServiceRow: number
  changeYatchStatusSuccess: boolean
  clearBoatFlag: typeof clearBoatFlag
}
interface State {
  searchedUsers: any[]
  currentRecord: any
  searchedValue: string
  modalVisible: boolean
  error: boolean
  rejectMessage: string
}

class PendingBoatService extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchedUsers: [],
      currentRecord: {},
      searchedValue: '',
      modalVisible: false,
      error: false,
      rejectMessage: '',
    }
  }

  // componentDidMount() {
  //   const { getAllBoatServiceAction } = this.props
  //   getAllBoatServiceAction({ ...getAllBoatPagination, pendingAd: true })
  // }

  boatDetail = (record: any) => {
    const { goToDashboardAction } = this.props
    this.setState({
      currentRecord: record,
    })
    goToDashboardAction(record.user.id)
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { dashboardLinkSuccess, dashboardLink, clearGoToDashboard, clearBoatFlag, changeYatchStatusSuccess } = props
    const { currentRecord } = state

    if (dashboardLinkSuccess) {
      viewServiceBoatHandler(currentRecord, 'pending', dashboardLink)
      clearGoToDashboard()
    }

    if (changeYatchStatusSuccess) {
      clearBoatFlag()
      return {
        modalVisible: false,
        rejectMessage: '',
      }
    }
    return null
  }

  changeBoatStatus = (columnName: any, id: any, value: any, note: string, action: string) => {
    const { changeYatchServiceAction, allBoatService } = this.props
    const boatObject: any = {
      id,
      columnName,
      value: value,
      fieldName: 'allBoatService',
      adType: PendingAds.BOAT_SERVICE,
      action: action,
      fieldArray: allBoatService,
      note,
    }
    changeYatchServiceAction(boatObject)
  }

  onSearch = (value: any) => {
    const { getAllBoatServiceAction } = this.props
    const input = {
      ...getAllBoatPagination,
      pendingAd: true,
    }

    if (value) {
      this.setState({ searchedValue: value })
      getAllBoatServiceAction({ ...input, searchTerm: value })
    } else {
      this.setState({ searchedValue: '' })
      getAllBoatServiceAction(input)
    }
  }

  setModalVisible = (data: boolean, record: tableRecords) => {
    this.setState({
      modalVisible: data,
      currentRecord: record,
    })
  }

  handleChange = (data: string) => {
    this.setState({
      rejectMessage: data,
    })
  }

  showHideError = (data: boolean) => {
    this.setState({
      error: data,
    })
  }

  onSubmit = () => {
    const { currentRecord, rejectMessage } = this.state
    if (rejectMessage.length) {
      this.changeBoatStatus('adStatus', currentRecord.id, true, removePTag(rejectMessage), LogType.REJECTED)
    } else {
      NotificationWithIcon('error', fillField)
    }
  }

  render() {
    const columns = [
      {
        title: 'Country',
        dataIndex: 'address',
        key: 'address',
        render: (text: any) => {
          return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
        },
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text: any, record: tableRecords) => <div>{`${text.firstName} ${text.lastName}`}</div>,
      },
      {
        title: 'Company Name',
        dataIndex: 'user',
        key: 'companyName',
        render: (text: any, record: tableRecords) => <div>{text.companyName}</div>,
      },
      {
        title: 'Ad ID',
        dataIndex: 'adId',
        key: 'adId',
      },
      {
        title: 'Date Added',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => <> {formateDate(text)} </>,
      },
      {
        title: 'Boat Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: any, record: tableRecords) => (
          <Switch
            checked={text}
            onChange={() => {
              this.changeBoatStatus('status', record.id, !text, removePTag(rejectMessage), !text ? LogType.ENABLED : LogType.DISABLED)
            }}
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        ),
      },
      {
        title: 'Approval Status',
        dataIndex: 'adStatus',
        key: 'adStatus',
        render: (text: string, record: tableRecords) => {
          const { changeYatchServiceAction, allBoatService } = this.props
          return (
            <ApproveButton
              action={changeYatchServiceAction}
              input={{
                id: record.id,
                columnName: 'adStatus',
                value: !record.adStatus,
                adType: 'BOAT_SERVICE',
                action:'APPROVED',
                fieldArray: allBoatService,
                fieldName: 'allBoatService',
              }}
              buttonTitle="Approve"
              className="btn-view"
            />
          )
        },
      },
      {
        title: 'View Ad',
        dataIndex: 'ViewAd',
        key: 'view',
        render: (text: string, record: tableRecords) => (
          <Button className="btn-view" onClick={() => this.boatDetail(record)}>
            View
          </Button>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: tableRecords) => (
          <>
            <Button onClick={() => this.setModalVisible(true, record)} className="btn-delete">
              Reject
            </Button>
          </>
        ),
      },
    ]

    const { isLoading, totalBoatServiceRow, getAllBoatServiceAction, allBoatService } = this.props
    const { modalVisible, error, rejectMessage } = this.state

    return (
      <>
        <Card title="Pending Ads - Boat Service" extra={<TitleSearch onSearch={this.onSearch} />}>
          <ExpandTable
            columns={columns}
            tableData={allBoatService}
            expandHtml={false}
            isExpand={false}
            extraInput={{ pendingAd: true }}
            page={paginationPage.boat}
            totalUsersRow={totalBoatServiceRow}
            pageAction={getAllBoatServiceAction}
            tableLoading={isLoading}
          />
        </Card>
        {modalVisible && (
          <CommonModal
            customWidth={'60%'}
            okText="Submit"
            isFlag={modalVisible}
            title="Reason Of Rejection"
            handleCancel={() => this.setModalVisible(false, { id: '' })}
            footerProp={{ footer: null }}
          >
            <RejectionMailEditor
              onClick={() => (rejectMessage ? this.onSubmit() : this.showHideError(true))}
              errorMessage={error}
              onChangeText={(evt: any) => {
                error && this.showHideError(false)
                this.handleChange(evt.editor.getData())
              }}
            />
          </CommonModal>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: pendingAdsState | any) => {
  return {
    allBoatService: state.pendingAdsState.allBoatService,
    isLoading: state.pendingAdsState.isLoading,
    totalBoatServiceRow: state.pendingAdsState.totalBoatServiceRow,
    dashboardLink: state.frontUsersCountReducer.dashboardLink,
    dashboardLinkSuccess: state.frontUsersCountReducer.dashboardLinkSuccess,
    changeYatchStatusSuccess: state.pendingAdsState.changeYatchStatusSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllBoatServiceAction: (data: String) => dispatch(getAllBoatServiceAction(data)),
    changeYatchServiceAction: (data: any) => dispatch(changeYatchServiceAction(data)),
    goToDashboardAction: (data: any) => dispatch(goToDashboardAction(data)),
    clearGoToDashboard: () => dispatch(clearGoToDashboard()),
    clearBoatFlag: () => dispatch(clearBoatFlag()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingBoatService)
