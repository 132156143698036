import {
  userGuideData,
  GET_ALL_USER_GUIDE_LIST,
  CREATE_USER_GUIDE_DETAIL,
  UPDATE_USER_GUIDE_DETAIL,
  DELETE_USER_GUIDE_DETAIL,
  CLEAR_USER_GUIDE_FLAG,
  CHANGE_USER_GUIDE_STATUS,
} from './Type'

export function getManageUserGuide(data: any) {
  return {
    type: GET_ALL_USER_GUIDE_LIST,
    payload: data,
  }
}

export function createManageUserGuide(data: userGuideData) {
  return {
    type: CREATE_USER_GUIDE_DETAIL,
    payload: data,
  }
}

export function updateManageUserGuide(data: userGuideData) {
  return {
    type: UPDATE_USER_GUIDE_DETAIL,
    payload: data,
  }
}

export function deleteManageUserGuide(data: string) {
  return {
    type: DELETE_USER_GUIDE_DETAIL,
    payload: data,
  }
}

export function clearUserGuideFlag() {
  return {
    type: CLEAR_USER_GUIDE_FLAG,
  }
}

export function changeUserGuideStatus(data: any) {
  return {
    type: CHANGE_USER_GUIDE_STATUS,
    payload: data,
  }
}
