import * as React from 'react'
import { Modal } from 'antd'
import ShowDocumentModal from '../container/frontUser/documentVerify/ShowDocumentModal'

const ImageModal = (props: any) => {
  const { title, visible, onCloseFunc, image } = props

  return (
    <Modal
      title={title}
      width={['pdf', 'doc', 'docx'].includes(image?.split('?')[0].split('.').pop()) ? '1000px' : '700px'}
      bodyStyle={{ height: 480, overflow: 'scroll', display: 'flex', justifyContent: 'center' }}
      visible={visible}
      onCancel={onCloseFunc}
      onOk={onCloseFunc}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <ShowDocumentModal filePath={image} />
    </Modal>
  )
}

export default ImageModal
