import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Space } from 'antd'

import History from '../../History'
import ExpandTable from '../../component/ExpandTable'
import { getAllAuctionRoomTransactions } from '../../store/manageAuctionRoom/Action'
import { AuctionRoomDeposit, getAuctionRoomTransactionInput } from '../../store/manageAuctionRoom/Types'
import { formateDateTime, formatPrice, getSearchedData } from '../../helpers/helper'
import { transactionSubCategory } from '../../enums/enums'
import FilterButton from '../filters/FilterButton'
import { TitleSearch } from '../../component/SearchBar'

let pageFilterArray: any = []
interface Props {
  history: typeof History
  getAllAuctionRoomTransactions: typeof getAllAuctionRoomTransactions
  location: any
  auctionRoomTransactions: AuctionRoomDeposit[]
  auctionRoomTransactionsTotalRow: number
}

interface State {
  sales_engine_id: string
  adId: string
  searchedValue: string
}

class SalesEnginPaymentTransactions extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      sales_engine_id: props.location.state.sales_engine_id,
      adId: props.location.state.adID,
      searchedValue: '',
    }
  }

  componentDidMount() {
    // const { getAllAuctionRoomTransactions } = this.props
    // const { sales_engine_id } = this.state

    pageFilterArray = [
      {
        title: 'Category',
        filedArray: transactionSubCategory.filter((el: any) => el.parent === 'sales_engine'),
        listOptions: 'label',
        passed: 'value',
        actionField: 'sub_category_id',
      },
    ]

    // if (sales_engine_id) {
    //   getAllAuctionRoomTransactions({
    //     sales_engine_id: sales_engine_id,
    //     auction_id: '',
    //   })
    // }
  }

  onSearch = (value: any) => {
    this.setState({
      searchedValue: value,
    })
  }

  render() {
    const { auctionRoomTransactions, auctionRoomTransactionsTotalRow, getAllAuctionRoomTransactions, history } = this.props
    const { sales_engine_id, searchedValue } = this.state

    const columns = [
      {
        title: 'Transaction ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any) => <span>{text}</span>,
      },
      {
        title: 'Buyer Name',
        dataIndex: 'senderId',
        key: 'senderId',
        render: (text: any) => (
          <span>
            {text !== null && text.firstName} {text !== null && text.lastName}
          </span>
        ),
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => <> {formateDateTime(text)} </>,
      },
      {
        title: 'Price in USD',
        dataIndex: 'amount',
        key: 'amount',
        render: (text: string) => <> $ {formatPrice(Number(text), 2)} </>,
      },
      {
        title: 'Category',
        dataIndex: 'sub_category_id',
        key: 'sub_category_id',
        render: (text: any) => <span>{text && transactionSubCategory.filter((el: any) => el.value === text)[0].label}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string) => <span>{text}</span>,
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: (text: string) => <span>{text}</span>,
      },

      // {
      //     title: 'Status',
      //     dataIndex: 'isDepositAdded',
      //     key: 'isDepositAdded',
      //     render: (text: string) => (
      //         text ? <span className="active-status-text">Deposit Added</span>
      //             : <span className="inactive-status-text">Deposit Remaining</span>
      //     )
      // },
      // {
      //     title: 'Bid Status',
      //     dataIndex: 'auctionRoom',
      //     key: 'auctionRoom',
      //     render: (text: any, record: any) => (
      //         text?.auctionWinner ?
      //             (record?.user?.id === text.auctionWinner?.id ?
      //                 <span className="active-status-text">Win</span>
      //                 :
      //                 <span className="inactive-status-text">Lose</span>)
      //             :
      //             <span className="active-status-text">In Progress</span>
      //     )
      // },
    ]

    return (
      <Card
        title={`Sales Engine Transactions - (Ad ID - ${this.state.adId})`}
        extra={
          <Space>
            <TitleSearch onSearch={this.onSearch} />
            <Button onClick={() => history.goBack()}> {'Back'}</Button>
          </Space>
        }
      >
        <FilterButton
          filterArray={pageFilterArray}
          triggerAction={getAllAuctionRoomTransactions}
          extraInput={{ sales_engine_id: sales_engine_id, auction_id: '' }}
        />
        <ExpandTable
          pageAction={getAllAuctionRoomTransactions}
          columns={columns}
          // tableData={}
          tableData={getSearchedData(auctionRoomTransactions, searchedValue)}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={auctionRoomTransactionsTotalRow}
          extraInput={{ sales_engine_id: sales_engine_id, auction_id: '' }}
          page="boat"
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  auctionRoomTransactions: state.auctionRoomReducer && state.auctionRoomReducer.auctionRoomTransactions,
  auctionRoomTransactionsTotalRow: state.auctionRoomReducer.auctionRoomTransactionsTotalRow,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllAuctionRoomTransactions: (data: getAuctionRoomTransactionInput) => dispatch(getAllAuctionRoomTransactions(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEnginPaymentTransactions)
