export interface frontUsersState {
  frontUsers: any[]
  frontUsersBoatCount: any[]
  frontUserPendingCount: any[]
  getSuccess: boolean
  getError: boolean
  dashboardLink: string
  dashboardLinkSuccess: boolean
  dashboardLinkError: boolean
  loading: boolean
  frontUserWiseVerificationCount:any[]
}

export interface frontUsersData {
  roleCounts: [string]
  total: number
}

export interface userRole {
  aliasName: string
  id: string
  role: string
  type: string
}

export const GET_FRONT_USERS_COUNT = 'GET_FRONT_USERS_COUNT'
export const GET_FRONT_USERS_COUNT_SUCCESS = 'GET_FRONT_USERS_COUNT_SUCCESS'
export const GET_FRONT_USERS_COUNT_FAILURE = 'GET_FRONT_USERS_COUNT_FAILURE'

export const GET_FRONT_USERS_BOAT_COUNT = 'GET_FRONT_USERS_BOAT_COUNT'
export const GET_FRONT_USERS_BOAT_COUNT_SUCCESS = 'GET_FRONT_USERS_BOAT_COUNT_SUCCESS'
export const GET_FRONT_USERS_BOAT_COUNT_FAILURE = 'GET_FRONT_USERS_BOAT_COUNT_FAILURE'

export const GET_FRONT_USERS_PENDING_COUNT = 'GET_FRONT_USERS_PENDING_COUNT'
export const GET_FRONT_USERS_PENDING_COUNT_SUCCESS = 'GET_FRONT_USERS_PENDING_COUNT_SUCCESS'
export const GET_FRONT_USERS_PENDING_COUNT_FAILURE = 'GET_FRONT_USERS_PENDING_COUNT_FAILURE'

export const CLEAR_FRONT_USERS_COUNT_FLAG = 'CLEAR_FRONT_USERS_COUNT_FLAG'

export const GO_TO_DASHBOARD = 'GO_TO_DASHBOARD'
export const GO_TO_DASHBOARD_SUCCESS = 'GO_TO_DASHBOARD_SUCCESS'
export const GO_TO_DASHBOARD_FAILURE = 'GO_TO_DASHBOARD_FAILURE'

export const FRONT_USER_WISE_VERIFICATION = 'FRONT_USER_WISE_VERIFICATION'
export const FRONT_USER_WISE_VERIFICATION_SUCCESS = 'FRONT_USER_WISE_VERIFICATION_SUCCESS'
export const FRONT_USER_WISE_VERIFICATION_FAILURE = 'FRONT_USER_WISE_VERIFICATION_FAILURE'


export const CLEAR_GO_TO_DASHBOARD_FLAG = 'CLEAR_GO_TO_DASHBOARD_FLAG'

interface getFrontUsersCount {
  type: typeof GET_FRONT_USERS_COUNT
  payload: any
}

interface getFrontUsersCountSuccess {
  type: typeof GET_FRONT_USERS_COUNT_SUCCESS
  payload: any
}

interface getFrontUsersCountFailure {
  type: typeof GET_FRONT_USERS_COUNT_FAILURE
  payload: any
}

interface getFrontUsersBoatCount {
  type: typeof GET_FRONT_USERS_BOAT_COUNT
  payload: any
}

interface getFrontUsersBoatCountSuccess {
  type: typeof GET_FRONT_USERS_BOAT_COUNT_SUCCESS
  payload: any
}

interface getFrontUsersBoatCountFailure {
  type: typeof GET_FRONT_USERS_BOAT_COUNT_FAILURE
  payload: any
}

interface getFrontUsersPendingCount {
  type: typeof GET_FRONT_USERS_PENDING_COUNT
  payload: any
}

interface getFrontUsersPendingCountSuccess {
  type: typeof GET_FRONT_USERS_PENDING_COUNT_SUCCESS
  payload: any
}

interface getFrontUsersPendingCountFailure {
  type: typeof GET_FRONT_USERS_PENDING_COUNT_FAILURE
  payload: any
}

interface clearFrontUserCountFlag {
  type: typeof CLEAR_FRONT_USERS_COUNT_FLAG
  payload: any
}

interface goToDashboard {
  type: typeof GO_TO_DASHBOARD
  payload: string
}

interface goToDashboardSuccess {
  type: typeof GO_TO_DASHBOARD_SUCCESS
  payload: any
}

interface goToDashboardFailure {
  type: typeof GO_TO_DASHBOARD_FAILURE
  payload: any
}
interface clearGoToDashboardFlag {
  type: typeof CLEAR_GO_TO_DASHBOARD_FLAG
}

interface getUserWiseVerificationCount {
  type: typeof FRONT_USER_WISE_VERIFICATION
  payload: any
}

interface getUserWiseVerificationCountSuccess {
  type: typeof FRONT_USER_WISE_VERIFICATION_SUCCESS
  payload: any
}

interface getUserWiseVerificationCountuFailure {
  type: typeof FRONT_USER_WISE_VERIFICATION_FAILURE
  payload: any
}

export type ActionType =
  | getFrontUsersCount
  | getFrontUsersCountSuccess
  | getFrontUsersCountFailure
  | getFrontUsersBoatCount
  | getFrontUsersBoatCountSuccess
  | getFrontUsersBoatCountFailure
  | getFrontUsersPendingCount
  | getFrontUsersPendingCountSuccess
  | getFrontUsersPendingCountFailure
  | clearFrontUserCountFlag
  | goToDashboard
  | goToDashboardSuccess
  | goToDashboardFailure
  | clearGoToDashboardFlag
  | getUserWiseVerificationCount
  | getUserWiseVerificationCountSuccess
  | getUserWiseVerificationCountuFailure
