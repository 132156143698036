import {
  smtpState,
  ActionType,
  CREATE_SMTP,
  CREATE_SMTP_SUCCESS,
  CREATE_SMTP_FAILURE,
  GET_ALL_SMTP,
  GET_ALL_SMTP_SUCCESS,
  GET_ALL_SMTP_FAILURE,
  UPDATE_SMTP,
  UPDATE_SMTP_SUCCESS,
  UPDATE_SMTP_FAILURE,
  CLEAR_SMTP_FLAG,
  DELETE_SMTP_SUCCESS,
  DELETE_SMTP_FAILURE,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: smtpState = {
  smtps: [],
  smtpSuccess: false,
  smtpError: false,
  deleteSuccess: false,
  deleteError: false,
  totalSmtpRow: 0,
}

export const smtpReducer = (state = initialState, action: ActionType): smtpState => {
  switch (action.type) {
    case CREATE_SMTP:
      return {
        ...state,
        smtpSuccess: false,
        smtpError: false,
      }
    case CREATE_SMTP_SUCCESS:
      return {
        ...state,
        smtpSuccess: true,
        smtpError: false,
      }

    case CREATE_SMTP_FAILURE:
      return {
        ...state,
        smtpSuccess: false,
        smtpError: true,
      }

    case GET_ALL_SMTP_SUCCESS:
      return {
        ...state,
        smtps: action.payload.items,
        totalSmtpRow: action.payload.total,
      }
    case GET_ALL_SMTP:
      return {
        ...state,
        smtpSuccess: false,
        smtpError: false,
      }

    case GET_ALL_SMTP_FAILURE:
      return {
        ...state,
        smtpSuccess: false,
        smtpError: true,
      }

    case UPDATE_SMTP:
      return {
        ...state,
        smtpSuccess: false,
        smtpError: true,
      }

    case UPDATE_SMTP_SUCCESS:
      return {
        ...state,
        smtpSuccess: true,
        smtpError: false,
      }
    case UPDATE_SMTP_FAILURE:
      return {
        ...state,
        smtpSuccess: false,
        smtpError: true,
      }

    case CLEAR_SMTP_FLAG:
      return {
        ...state,
        smtpSuccess: false,
        smtpError: false,
        deleteSuccess: false,
        deleteError: false,
      }
    case DELETE_SMTP_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }

    case DELETE_SMTP_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }
    default:
      return state
  }
}
