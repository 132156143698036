import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { formateDate } from '../../helpers/helper'
import ManageBoatTable, { tableRecords } from '../../component/ManageBoatTable'
import { Switch } from 'antd'
import { getMostPopularRentAction, changeRentStatus } from '../../store/pendingAds/Action'
import { getAllBoatPagination } from '../../enums/enums'
import ThreeToggleSwitch from '../../component/ThreeToggleSwitch'

interface Props {
  getMostPopularRentAction: typeof getMostPopularRentAction
  mostPopularRent: any[]
  totalMostPopularRentRow: number
  changeRentStatus: typeof changeRentStatus
}
class MostPopularRent extends Component<Props> {
  changeRentStatus = (columnName: any, record: any) => {
    const { changeRentStatus, mostPopularRent } = this.props
    changeRentStatus({
      id: record.id,
      columnName,
      value: record[columnName],
      fieldName: 'mostPopularRent',
      fieldArray: mostPopularRent,
    })
  }

  columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text: any, record: tableRecords) => <div>{`${text.firstName} ${text.lastName}`}</div>,
    },
    {
      title: 'Company Name',
      dataIndex: 'user',
      key: 'user1',
      render: (text: any) => <div>{text.companyName}</div>,
    },
    {
      title: 'Ad ID',
      dataIndex: 'adId',
      key: 'adId',
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <> {formateDate(text)} </>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Most Popular Page',
      dataIndex: 'popular',
      key: 'popular',
      render: (text: boolean, record: tableRecords) => (
        <ThreeToggleSwitch toggleMarketStatus={(e: any) => this.changeRentStatus('popular', record)} status={text} />
      ),
    },
    {
      title: 'Ad Approval',
      dataIndex: 'adStatus',
      key: 'adStatus',
      render: (text: boolean, record: tableRecords) => (
        <Switch
          checked={text}
          onChange={() => this.changeRentStatus('adStatus', record)}
          checkedChildren="Approved"
          unCheckedChildren="Unapproved"
        />
      ),
    },
  ]

  componentDidMount() {
    const { getMostPopularRentAction } = this.props

    getMostPopularRentAction(getAllBoatPagination)
  }

  render() {
    const { mostPopularRent, totalMostPopularRentRow, getMostPopularRentAction } = this.props
    return (
      <ManageBoatTable
        tableTitle="Manage Most Popular Rent Boats"
        renderColumn={this.columns}
        tableList={mostPopularRent}
        totalUsersRow={totalMostPopularRentRow}
        getListAction={getMostPopularRentAction}
        view="rent"
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    mostPopularRent: state.pendingAdsState.mostPopularRent,
    totalMostPopularRentRow: state.pendingAdsState.totalMostPopularRentRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getMostPopularRentAction: (data: any) => dispatch(getMostPopularRentAction(data)),
    changeRentStatus: (data: any) => dispatch(changeRentStatus(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MostPopularRent)
