import React, { Component } from 'react'
import { Formik, Field, ErrorMessage } from 'formik'
import { Row, Col, Card } from 'antd'

import { FromControl, CustomerSubmit } from '../../constants/StyleComponent'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { userLogin, clearLoginFlag } from '../../store/login/Actions'
import { inputEvent } from '../../constants/GlobalEventTypeScript'
import NotificationWithIcon from '../../component/notification/Notification'
import history from '../../History'
import { bannerType } from '../../store/manageBannerImages/manageCommonBanner/types'
import { getCategoriesWiseBanners } from '../../store/manageBannerImages/manageCommonBanner/actions'
import { mediaCategory, defaultImage, getAllBoatPagination } from '../../enums/enums'
import Video from '../../component/VideoPlayer'
import { getBannerImageUrl } from '../../helpers/helper'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
export interface State {
  login: {
    username: string
    password: string
  }
  isFlag: boolean
  isAuth: string
}
export interface mapUserLoginStateData {
  errorMessage: string
  isAuth: AuthDetail
  login: userSaveData
  successMessage: boolean
}
export interface mapUserLoginState {
  userLoginState: mapUserLoginStateData
}

export interface AuthDetail {
  token: string
  firstName: string
  lastName: string
}

export interface Props {
  userLogin: typeof userLogin
  adminLoginBanners: Array<any>
  isAuth: AuthDetail
  errorMessage: string
  successMessage: boolean
  history: typeof history
  clearLogin: typeof clearLoginFlag
  getCategoriesWiseBanners: typeof getCategoriesWiseBanners
}
export interface userSaveData {
  username: string
  password: string
}

class SignIn extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      login: {
        username: '',
        password: '',
      },
      isFlag: true,
      isAuth: '',
    }
  }

  componentDidMount() {
    const { getCategoriesWiseBanners } = this.props
    getCategoriesWiseBanners({ data: mediaCategory.adminLogin })
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.errorMessage) {
      NotificationWithIcon('error', props.errorMessage)
      props.clearLogin()
    }
    if (props.successMessage) {
      localStorage.setItem('auth_token', props.isAuth.token)
      localStorage.setItem('userName', `${props.isAuth.firstName} ${props.isAuth.lastName}`)
      localStorage.setItem('role', `${get(props, 'isAuth.role.role', '')}`)
      props.clearLogin()
      window.location.href = '/front-users'
      // return {
      //   isAuth: props.isAuth.token,
      // }
    }
    // if (props.isAuth) {
    //   localStorage.setItem("auth_token", props.isAuth.token)
    //   localStorage.setItem("userName", props.isAuth.username)
    //   return {
    //     isAuth: props.isAuth.token,
    //   }
    // }
    // props.clearLoginFlag();
    return null
  }

  render() {
    const { login } = this.state
    const { userLogin, adminLoginBanners } = this.props

    return (
      <div className="w-100 float-left h-100">
        {adminLoginBanners && adminLoginBanners.length && adminLoginBanners[0] && adminLoginBanners[0].metatype !== 'video' ? (
          <img className="admin-login-banner" src={getBannerImageUrl(adminLoginBanners) || defaultImage} />
        ) : (
          <Video
            videoUrl={adminLoginBanners && adminLoginBanners.length && adminLoginBanners[0] ? adminLoginBanners[0].url : ''}
            thumbnail={
              adminLoginBanners && adminLoginBanners.length && adminLoginBanners[0]
                ? adminLoginBanners[0].thumbnail
                : defaultImage
            }
            isOpen={true}
          />
        )}
        <Row className="h-100" align="middle" justify="center">
          <Col
            lg={6}
            md={12}
            sm={14}
            xs={18}
            style={{
              position: 'absolute',
              top: '20%',
              paddingBottom: 0,
            }}
          >
            <Card title={'Login'}>
              <Formik
                initialValues={login}
                onSubmit={values => {
                  userLogin(values)
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string().required('User name field is required.'),
                  password: Yup.string().required('Password field is required.'),
                })}
              >
                {props => {
                  const { handleSubmit, setFieldValue } = props
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row align="middle" justify="center">
                        <Col span={24}>
                          <FromControl>
                            <span className="ant-form-item-required" title="Name">
                              Username
                            </span>
                            <Field
                              type="text"
                              className="ant-input"
                              placeholder="Name"
                              name="username"
                              onChange={(e: inputEvent) => {
                                setFieldValue('username', e.target.value)
                              }}
                            />
                            <ErrorMessage className="error-message-text" name="username" component="span" />
                          </FromControl>
                        </Col>
                        <Col span={24}>
                          <FromControl>
                            <span className="ant-form-item-required" title="Password">
                              Password
                            </span>
                            <Field
                              type="password"
                              className="ant-input"
                              placeholder="Password"
                              name="password"
                              onChange={(e: inputEvent) => {
                                setFieldValue('password', e.target.value)
                              }}
                            />
                            <ErrorMessage className="error-message-text" name="password" component="span" />
                          </FromControl>
                        </Col>
                        <Col span={24}>
                          <CustomerSubmit>
                            <button className="ant-btn ant-btn-primary" type="submit">
                              Login
                            </button>
                            <Link to="/recover-account">Forgot Password?</Link>
                          </CustomerSubmit>
                        </Col>
                      </Row>
                    </form>
                  )
                }}
              </Formik>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    adminLoginBanners: state.bannerReducer && state.bannerReducer[mediaCategory.adminLogin.fieldName],
    successMessage: state.userLoginState.successMessage,
    errorMessage: state.userLoginState.errorMessage,
    isAuth: state.userLoginState.isAuth,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    userLogin: (data: userSaveData) => dispatch(userLogin(data)),
    clearLogin: () => dispatch(clearLoginFlag()),
    getCategoriesWiseBanners: (data: bannerType) => dispatch(getCategoriesWiseBanners(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
