import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card } from 'antd'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import { History } from 'history'
import { editCommisionAction, getAllSalesEnginCommision } from '../../store/salesEngine/manageSalesEngine/Action'
import { getSalesEnginPaymentInput, SalesEnginCommision } from '../../store/salesEngine/manageSalesEngine/Type'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData, displayDefaultValue } from '../../helpers/helper'
import { getAllBoatPagination } from '../../enums/enums'
import CreateEditCommisionModal from './CreateEditCommisionModal'

interface Props {
  history: History
  getAllSalesEnginCommision: typeof getAllSalesEnginCommision
  editCommisionAction: typeof editCommisionAction
  salesEnginCommision: SalesEnginCommision[]
  salesEnginCommisionTotalRow: any
}

interface State {
  columns: Array<Object>
  searchedUsers: any[]
  searchedValue: string
}

const commissionNames = {
  Manufacture: 'Boat Manufacturer',
  BoatOwner: 'Boat Owner',
  Survevour: 'Surveyor',
  Shipper: 'Boat Shipper',
  Agent: 'AdamSea Agent',
  Dealer: 'Broker & Dealer',
  Rent: 'Rent & Charter',
  Boat_Service_Fee: 'Boat Service Fee',
  Rent_Service_Fee: 'Rent Service Fee',
};

class SalesEnginCommisionComponent extends Component<Props, State> {
  // componentDidMount() {
  //   const { getAllSalesEnginCommision } = this.props

  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getAllSalesEnginCommision(input)
  // }

  onSearch = (value: any) => {
    const { getAllSalesEnginCommision } = this.props;

    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
    };

    if (value) {
      this.setState({searchedValue: value})
      getAllSalesEnginCommision({ ...input, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getAllSalesEnginCommision(input)
    }
  }

  state = {
    searchedUsers: [],
    searchedValue: '',
    columns: [
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (text: string) => <span>{commissionNames[text as keyof typeof commissionNames]}</span>,
      },
      {
        title: 'Commision',
        dataIndex: 'commision',
        key: '  ',
        render: (text: any) => <span>{parseFloat(text).toFixed(2)} %</span>,
      },
      {
        title: 'Actions',
        dataIndex: 'edit',
        key: 'edit',
        render: (text: string, record: any) => {
          return (
            <CreateEditCommisionModal
              record={record}
              editCommisionAction={(el: any) => {
                this.props.editCommisionAction(el)
              }}
              buttonName="Edit"
            />
          )
        },
      },
    ],
  }

  render() {
    const { columns, searchedValue } = this.state
    const { salesEnginCommision, getAllSalesEnginCommision, salesEnginCommisionTotalRow } = this.props

    return (
      <>
        <Card title="Sales Engine Commision" extra={<TitleSearch onSearch={this.onSearch} />}>
          <ExpandTable
            columns={columns}
            tableData={salesEnginCommision}
            expandHtml={false}
            isExpand={false}
            page="boat"
            totalUsersRow={salesEnginCommisionTotalRow}
            pageAction={getAllSalesEnginCommision}
            rowKey={(record: tableRecords) => record?.id}
            extraInput={{ searchTerm: searchedValue }}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  salesEnginCommision: state.salesEngineReducer.salesEnginCommision,
  salesEnginCommisionTotalRow: state.salesEngineReducer.salesEnginCommisionTotalRow,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllSalesEnginCommision: (data: getSalesEnginPaymentInput) => dispatch(getAllSalesEnginCommision(data)),
  editCommisionAction: (data: any) => dispatch(editCommisionAction(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEnginCommisionComponent)
