import React from 'react'

import { Modal } from 'antd'

export interface Props {
  isFlag: boolean
  title: string
  okText?: string
  okDisabled?: boolean
  handleOk?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  handleCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  customWidth?: string | number
  footerProp?: { footer: null }
}
export interface State {
  visible: boolean
}
class CommonModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      visible: false,
    }
  }
  componentDidMount() {
    this.setState({ visible: this.props.isFlag })
  }
  render() {
    const { visible } = this.state
    const { title, handleCancel, handleOk, okText, okDisabled, customWidth, footerProp } = this.props

    return (
      <div>
        <Modal
          width={customWidth}
          title={title}
          visible={visible}
          onOk={handleOk}
          okText={okText}
          onCancel={handleCancel}
          cancelButtonProps={{ style: { display: 'none' }, disabled: okDisabled }}
          footer={footerProp?.footer}
        >
          {this.props.children}
        </Modal>
      </div>
    )
  }
}

export default CommonModal
