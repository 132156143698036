import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import {
  getUserDocumentAction,
  changeDocumentStatus,
  clearDocumentStatus,
  getDocumentUrlAction,
} from '../../../store/dashboard/documentVerify/Action'
import { getAllBoatPagination, paginationPage, defaultImage, activeInactive } from '../../../enums/enums'
import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData, displayDefaultValue, splitUrlFromDocumentUrl } from '../../../helpers/helper'
import ImageModal from '../../../component/ImageModal'
import FilterButton from '../../filters/FilterButton'
import ApproveButton from '../../../component/ApproveButton'
import RejectButton from '../../../component/RejectButton'

interface Props {
  getUserDocumentAction: typeof getUserDocumentAction
  changeDocumentStatus: typeof changeDocumentStatus
  AdminUsersDocument: []
  history: typeof History
  roles: any
  verifySuccess: boolean
  AdminUsersDocumentTotalRow: number
  clearDocumentStatus: typeof clearDocumentStatus
  getDocumentUrlAction: typeof getDocumentUrlAction
  getDocumentLink: string
  getDocumentUrlSuccess: boolean
  governmentIdVerified: boolean
  urlLoader: boolean
  commercialLicenceVerified: boolean
  getDocLoad: boolean
}

interface State {}

let pageFilterArray: any = []

class DocumentList extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = props => {
    const { getDocumentUrlSuccess, getDocumentLink, clearDocumentStatus } = props

    if (getDocumentUrlSuccess) {
      clearDocumentStatus()
      const types = ['sheet', 'document']
      const type = splitUrlFromDocumentUrl(getDocumentLink)
      if (types.includes(type)) {
        window.open(getDocumentLink, '_blank')
      } else {
        return { viewFlag: true, viewDocumentData: getDocumentLink }
      }
    }

    return null
  }

  componentDidMount() {
    const { getUserDocumentAction, roles } = this.props
    // const input = {
    //   page: getAllBoatPagination.page,
    //   limit: getAllBoatPagination.limit,
    // }
    // getUserDocumentAction(input)
    pageFilterArray = [
      {
        title: 'Role',
        filedArray: roles,
        listOptions: 'role',
        passed: 'id',
        actionField: 'role',
      },
      {
        title: 'Commercial Licence',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'commercialLicenceVerified',
      },
      {
        title: 'Government ID',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'governmentIdVerified',
      },
    ]
  }

  viewDocument = (data: string) => {
    this.setState({ viewFlag: true, viewDocumentData: data })
  }

  closeDocument = () => {
    this.setState({ viewFlag: false, viewDocumentData: '' })
  }

  state = {
    searchedValue: '',
    viewFlag: false,
    viewDocumentData: '',
    isFilterCall: true,
    verifiedUserId: '',
  }

  columns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text: any) => {
        return <div className="text-capitalize">{text.role}</div>
      },
    },
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'address',
      render: (text: any, record: tableRecords, index: number) => {
        return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a: any, b: any) => a.firstName.length - b.firstName.length,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: any) => {
        return <div>{`${text} ${record.lastName}`}</div>
      },
    },
    {
      title: 'Government Id',
      dataIndex: 'governmentId',
      key: 'governmentId',
      render: (text: any) => {
        const { getDocumentUrlAction, urlLoader } = this.props
        return (
          <>
            {text && text.length ? (
              text.map((item: any) => {
                return (
                  <Button
                    loading={urlLoader}
                    onClick={() => {
                      getDocumentUrlAction({
                        mediaId: item.id,
                        isDownload: true,
                      })
                    }}
                    type="link"
                  >
                    Show Document
                  </Button>
                )
              })
            ) : (
              <span className="d-flex justify-content-center">-</span>
            )}
          </>
        )
      },
    },
    {
      title: 'Commercial Licence',
      dataIndex: 'commercialLicence',
      key: 'commercialLicence',
      render: (text: any) => {
        const { getDocumentUrlAction, urlLoader } = this.props
        return (
          <>
            {text && text.length ? (
              text.map((item: any) => {
                return (
                  <Button
                    loading={urlLoader}
                    onClick={() => {
                      getDocumentUrlAction({
                        mediaId: item.id,
                        isDownload: true,
                      })
                    }}
                    type="link"
                  >
                    Show Document
                  </Button>
                )
              })
            ) : (
              <span className="d-flex justify-content-center">-</span>
            )}
          </>
        )
      },
    },
    {
      title: 'GovernmentID Verified',
      dataIndex: 'documentVerification.governmentIdVerified',
      key: 'governmentIdVerified',
      render: (text: string, record: any, index: number) => {
        const { changeDocumentStatus, governmentIdVerified } = this.props

        return (
          <>
            {record.governmentId && record.governmentId.length ? (
              <>
                <ApproveButton
                  loading={governmentIdVerified && record.id == this.state.verifiedUserId}
                  visibility={
                    record.documentVerification.governmentIdVerified || record.documentVerification.governmentIdRejected
                  }
                  action={(data: any) => {
                    this.setState({ verifiedUserId: data.id })
                    changeDocumentStatus(data)
                  }}
                  input={{
                    record,
                    value: true,
                    document: 'governmentIdVerified',
                    id: record.id,
                    role: record.role.aliasName,
                  }}
                  buttonTitle={record.documentVerification.governmentIdVerified ? 'Approved' : 'Approve'}
                  className={'btn-view'}
                />

                <RejectButton
                  loading={governmentIdVerified && record.id == this.state.verifiedUserId}
                  visibility={
                    record.documentVerification.governmentIdVerified || record.documentVerification.governmentIdRejected
                  }
                  action={(data: any) => {
                    this.setState({ verifiedUserId: data.id })
                    changeDocumentStatus(data)
                  }}
                  input={{
                    record,
                    value: true,
                    document: 'governmentIdRejected',
                    id: record.id,
                    role: record.role.aliasName,
                  }}
                  buttonTitle={record.documentVerification.governmentIdRejected ? 'Rejected' : 'Reject'}
                  className={'btn-danger'}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )
      },
    },
    {
      title: 'Commercial Licence Verified',
      dataIndex: 'documentVerification.commercialLicenceVerified',
      key: 'commercialLicenceVerified',
      render: (text: string, record: any, index: number) => {
        const { changeDocumentStatus, commercialLicenceVerified } = this.props

        return (
          <>
            {record.commercialLicence && record.commercialLicence.length ? (
              <>
                <ApproveButton
                  loading={commercialLicenceVerified}
                  visibility={
                    record.documentVerification.commercialLicenceVerified ||
                    record.documentVerification.commercialLicenceRejected
                  }
                  action={changeDocumentStatus}
                  input={{
                    record,
                    value: true,
                    document: 'commercialLicenceVerified',
                    id: record.id,
                    role: record.role.aliasName,
                  }}
                  buttonTitle={record.documentVerification.commercialLicenceVerified ? 'Approved' : 'Approve'}
                  className={'btn-view'}
                />

                <RejectButton
                  loading={commercialLicenceVerified}
                  visibility={
                    record.documentVerification.commercialLicenceVerified ||
                    record.documentVerification.commercialLicenceRejected
                  }
                  action={changeDocumentStatus}
                  input={{
                    record,
                    value: true,
                    document: 'commercialLicenceRejected',
                    id: record.id,
                    role: record.role.aliasName,
                  }}
                  buttonTitle={record.documentVerification.commercialLicenceRejected ? 'Rejected' : 'Reject'}
                  className={'btn-danger'}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )
      },
    },
  ]

  onSearch = (value: any) => {
    this.setState({ searchedValue: value })
  }

  render() {
    const { viewFlag, viewDocumentData, searchedValue } = this.state
    const { AdminUsersDocument, AdminUsersDocumentTotalRow, getDocLoad, getUserDocumentAction } = this.props

    return (
      <Card title="Pending Document Verification" extra={<TitleSearch onSearch={this.onSearch} />}>
        <ImageModal image={viewDocumentData} title="Detail Document" visible={viewFlag} onCloseFunc={this.closeDocument} />
        <FilterButton filterArray={pageFilterArray} triggerAction={getUserDocumentAction} haveCountry />
        <ExpandTable
          columns={this.columns}
          tableData={getSearchedData(AdminUsersDocument, searchedValue)}
          expandHtml={false}
          isExpand={false}
          page={paginationPage.boat}
          pageAction={getUserDocumentAction}
          totalUsersRow={AdminUsersDocumentTotalRow}
          isFilter
          tableLoading={getDocLoad}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  roles: state.roleState.roles,
  getSuccess: state.userDocumentReducer.AdminUsersDocument,
  AdminUsersDocument: state.userDocumentReducer.AdminUsersDocument,
  AdminUsersDocumentTotalRow: state.userDocumentReducer.AdminUsersDocumentTotalRow,
  urlLoader: state.userDocumentReducer.urlLoader,
  verifySuccess: state.userDocumentReducer.verifySuccess,
  getDocumentLink: state.userDocumentReducer.getDocumentLink,
  getDocumentUrlSuccess: state.userDocumentReducer.getDocumentUrlSuccess,
  governmentIdVerified: state.userDocumentReducer.governmentIdVerified,
  commercialLicenceVerified: state.userDocumentReducer.commercialLicenceVerified,
  getDocLoad: state.userDocumentReducer.getDocLoad,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUserDocumentAction: (data: any) => dispatch(getUserDocumentAction(data)),
  changeDocumentStatus: (data: any) => dispatch(changeDocumentStatus(data)),
  clearDocumentStatus: () => dispatch(clearDocumentStatus()),
  getDocumentUrlAction: (data: any) => dispatch(getDocumentUrlAction(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList)
