import {
  CREATE_REGISTRATION_CONTENT,
  pageInformationState,
  ActionType,
  CREATE_REGISTRATION_CONTENT_SUCCESS,
  CREATE_REGISTRATION_CONTENT_FAILURE,
  GET_ALL_PAGE_INFORMATION,
  GET_ALL_PAGE_INFORMATION_SUCCESS,
  GET_ALL_PAGE_INFORMATION_FAILURE,
  CLEAR_CREATE_REGISTRATION_CONTENT_FLAG,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: pageInformationState = {
  allPageInformation: [],
  pageInfoSuccess: false,
  registrationContents: [],
  registrationContentSuccess: false,
  registrationContentError: false,
  storedType: '',
  isLoading: false,
}

export const pageInformationReducer = (state = initialState, action: ActionType): pageInformationState => {
  switch (action.type) {
    // GET ALL PAGE INFO
    case GET_ALL_PAGE_INFORMATION:
      return {
        ...state,
        pageInfoSuccess: false,
        allPageInformation: [],
        isLoading: true,
      }

    case GET_ALL_PAGE_INFORMATION_SUCCESS:
      return {
        ...state,
        pageInfoSuccess: true,
        allPageInformation: action.payload,
        storedType: action.uiType,
        isLoading: false,
      }

    case GET_ALL_PAGE_INFORMATION_FAILURE:
      return {
        ...state,
        pageInfoSuccess: false,
        allPageInformation: [],
        isLoading: false,
      }

    // CREATE REGISTRATION CONTENT
    case CREATE_REGISTRATION_CONTENT:
      return {
        ...state,
        registrationContents: [],
        registrationContentSuccess: false,
        registrationContentError: false,
      }

    case CREATE_REGISTRATION_CONTENT_SUCCESS:
      NotificationWithIcon('success', 'Update Successfully')
      return {
        ...state,
        registrationContents: action.payload,
        registrationContentSuccess: true,
      }

    case CREATE_REGISTRATION_CONTENT_FAILURE:
      NotificationWithIcon('error', 'Something Went Wrong')
      return {
        ...state,
        registrationContents: [],
        registrationContentError: true,
      }

    case CLEAR_CREATE_REGISTRATION_CONTENT_FLAG:
      return {
        ...state,
        pageInfoSuccess: false,
        registrationContentSuccess: false,
        registrationContentError: false,
        isLoading: false,
      }

    default:
      return state
  }
}
