import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button, Switch, Pagination } from 'antd'
import { Link } from 'react-router-dom'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import {
  getManageFaq,
  deleteManageFaq,
  clearFaqFlag,
  changeFaqDetailStatus,
} from '../../../store/staticContent/manageFAQ/Action'
import { pagination, paginationPage } from '../../../enums/enums'
import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData } from '../../../helpers/helper'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'
import FilterButton from '../../filters/FilterButton'

interface Props {
  getManageFaq: typeof getManageFaq
  faqs: []
  totalFAQRows: number
  history: typeof History
  deleteManageFaq: typeof deleteManageFaq
  clearFaqFlag: typeof clearFaqFlag
  faqSuccess: boolean
  deleteSuccess: boolean
  changeFaqDetailStatus: typeof changeFaqDetailStatus
}

export interface State {
  getInfo: {
    page: number
    limit: number
  }
  searchedValue: string
}

class ManageFAQ extends Component<Props, State> {
  filterArray = [
    {
      title: 'Status',
      filedArray: [
        { label: 'Active', value: true },
        { label: 'Blocked', value: false },
      ],
      listOptions: 'label',
      passed: 'value',
      actionField: 'status',
    },
  ]

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { faqSuccess, clearFaqFlag, getManageFaq, deleteSuccess } = props
    const { getInfo } = state
    if (faqSuccess) {
      clearFaqFlag()
    }
    if (deleteSuccess) {
      getManageFaq(getInfo)
      clearFaqFlag()
    }
    return null
  }

  // componentDidMount() {
  //   const { getManageFaq } = this.props
  //   const { getInfo } = this.state

  //   getManageFaq(getInfo)
  // }

  editFaq = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-faqs', { faq: { record: record, isEdit: true } })
  }

  changeFaqStatus = (column: any, record: any) => {
    const { changeFaqDetailStatus } = this.props
    changeFaqDetailStatus({ id: record.id, column, value: record[column] })
  }

  state = {
    searchedValue: '',
    getInfo: {
      page: pagination.page,
      limit: pagination.limit,
    },
    columns: [
      {
        title: 'Faq Question',
        dataIndex: 'question',
        key: 'question',
        sorter: (a: any, b: any) => a.question.length - b.question.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        // sorter: (a: any, b: any) => a.status - b.status,
        // sortDirections: ['descend', 'ascend'],
        render: (text: string, record: tableRecords, index: number) => (
          <Switch
            checked={record.status}
            onChange={() => this.changeFaqStatus('status', record)}
            checkedChildren="Active"
            unCheckedChildren="Blocked"
          />
        ),
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteManageFaq } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editFaq(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteManageFaq, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  onSearch = (value: any) => {
    const { getManageFaq } = this.props;
    const { getInfo } = this.state

    if (value) {
      this.setState({searchedValue: value})
      getManageFaq({ ...getInfo, searchTerm: value })
    } else {
      this.setState({searchedValue: ''})
      getManageFaq({ ...getInfo })
    }
  }

  render() {
    const { columns, searchedValue } = this.state
    const { faqs, totalFAQRows, getManageFaq } = this.props

    return (
      <>
        <Card
          title="Manage Frequently Asked Questions"
          extra={
            <div className="d-flex flex-wrap">
              <div className="mr-10 mt-8">
                <TitleSearch onSearch={this.onSearch} />
              </div>
              <Button className="btn-add mt-8">
                <Link to="/create-faqs">
                  <WSIcon type={<PlusOutlined />} /> Add FAQs
                </Link>
              </Button>
            </div>
          }
        >
          <FilterButton filterArray={this.filterArray} triggerAction={(data: any) => getManageFaq({ ...this.state.getInfo, searchTerm: this.state.searchedValue, ...data })} />
          <ExpandTable
            columns={columns}
            tableData={faqs}
            expandHtml={false}
            isExpand={false}
            totalUsersRow={totalFAQRows}
            pageAction={getManageFaq}
            page={paginationPage.boat}
            extraInput={{ searchTerm: searchedValue }}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  faqs: state.faqReducer.faqs,
  totalFAQRows: state.faqReducer.totalFAQRows,
  faqSuccess: state.faqReducer && state.faqReducer.faqSuccess,
  deleteSuccess: state.faqReducer && state.faqReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getManageFaq: (data: any) => dispatch(getManageFaq(data)),
  deleteManageFaq: (data: string) => dispatch(deleteManageFaq(data)),
  clearFaqFlag: () => dispatch(clearFaqFlag()),
  changeFaqDetailStatus: (data: any) => dispatch(changeFaqDetailStatus(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageFAQ)
