import { all, fork, put, takeEvery } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { client } from '../..'
import {
  GET_SALES_ENGINE_HELP_CONTENTS,
  GET_SALES_ENGINE_HELP_CONTENTS_SUCCESS,
  GET_SALES_ENGINE_HELP_CONTENTS_FAILURE,
  CREATE_SALES_ENGINE_HELP_CONTENTS_SUCCESS,
  CREATE_SALES_ENGINE_HELP_CONTENTS_FAILURE,
  CREATE_SALES_ENGINE_HELP_CONTENTS,
} from './Types'
import { getSalesEngineHelpContent, createSalesEngineHelpContent } from './Schema'

function getAllHelpContentApi({ payload }: any) {
  return client
    .query({
      query: getSalesEngineHelpContent,
      fetchPolicy: 'no-cache',
    })
}

function* getAllHelpContentData({ payload }: AnyAction) {
  try {
    const data = yield getAllHelpContentApi({ payload })
    yield put({
      type: GET_SALES_ENGINE_HELP_CONTENTS_SUCCESS,
      payload: data.data.getAllsaleEngineContexts,
    })
  } catch (error) {
    yield put({ type: GET_SALES_ENGINE_HELP_CONTENTS_FAILURE, error })
  }
}

function* getAllHelpContentSaga() {
  yield takeEvery(GET_SALES_ENGINE_HELP_CONTENTS, getAllHelpContentData)
}

function createHelpContentApi({ payload }: any) {
  return client
    .mutate({
      mutation: createSalesEngineHelpContent,
      variables: { input: payload },
      fetchPolicy: 'no-cache',
    })
}

function* createHelpContentData({ payload }: AnyAction) {
  try {
    const data = yield createHelpContentApi({ payload })
    yield put({
      type: CREATE_SALES_ENGINE_HELP_CONTENTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    yield put({ type: CREATE_SALES_ENGINE_HELP_CONTENTS_FAILURE, error })
  }
}

function* createHelpContentSaga() {
  yield takeEvery(CREATE_SALES_ENGINE_HELP_CONTENTS, createHelpContentData)
}

export default function* salesEngineHelpContentSaga() {
  yield all([fork(getAllHelpContentSaga), fork(createHelpContentSaga)])
}
