import { put, takeLatest, all } from 'redux-saga/effects'
import {
  ActionType,
  ADD_EMAIL_TEMPLATE,
  ADD_EMAIL_TEMPLATE_SUCCESS,
  ADD_EMAIL_TEMPLATE_FAILURE,
  UPDATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_FAILURE,
  GET_EMAIL_TEMPLATES,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATES_FAILURE,
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE,
  GET_VARIABLE,
  GET_VARIABLE_FAILURE,
  GET_VARIABLE_SUCCESS,
} from './Types'
import { client } from '../../../'
import { createEmailTemplate, getAllEmailTemplates, updateTemplate, deleteEmailTemplate, getVariables } from './Schema'

function deleteTemplateApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteEmailTemplate,
      variables: { id },
    })
}

function addEmailTemplateApi(input: any) {
  return client
    .mutate({
      mutation: createEmailTemplate,
      variables: { input },
    })
}

function getEmailTemplatesApi(payload: any) {
  return client
    .query({
      query: getAllEmailTemplates,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

function updateEmailTemplateApi(data: any) {
  const input = {
    ...data,
  }
  return client
    .mutate({
      mutation: updateTemplate,
      variables: { input },
    })
}

function getVariableApi(payload: any) {
  console.log(payload)

  return client
    .query({
      query: getVariables,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

function* deleteTemplate(action: ActionType) {
  try {
    const res = yield deleteTemplateApi(action)
    yield put({
      type: DELETE_TEMPLATE_SUCCESS,
      payload: res.data.deleteTemplate,
    })
  } catch (error) {
    yield put({ type: DELETE_TEMPLATE_FAILURE, error })
  }
}

function* addEmailTemplate(action: ActionType) {
  try {
    const res = yield addEmailTemplateApi(action.payload)
    yield put({ type: ADD_EMAIL_TEMPLATE_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: ADD_EMAIL_TEMPLATE_FAILURE, error })
  }
}

function* getEmailTemplate(action: any) {
  try {
    const res = yield getEmailTemplatesApi(action.payload)
    yield put({
      type: GET_EMAIL_TEMPLATES_SUCCESS,
      payload: res.data.getEmailTemplates,
    })
  } catch (error) {
    yield put({ type: GET_EMAIL_TEMPLATES_FAILURE, error })
  }
}

export function* updateEmailTemplate(action: ActionType) {
  try {
    const data = yield updateEmailTemplateApi(action.payload)
    yield put({ type: UPDATE_EMAIL_TEMPLATE_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_EMAIL_TEMPLATE_FAILURE, error })
  }
}

function* getVariableData(action: any) {
  try {
    const res = yield getVariableApi(action.payload)
    console.log(res, 'resres')

    yield put({ type: GET_VARIABLE_SUCCESS, payload: res.data.getVariables })
  } catch (error) {
    yield put({ type: GET_VARIABLE_FAILURE, error })
  }
}

function* deleteTemplateSaga() {
  yield takeLatest(DELETE_TEMPLATE, deleteTemplate)
}

function* addEmailTemplateSaga() {
  yield takeLatest(ADD_EMAIL_TEMPLATE, addEmailTemplate)
}

function* getEmailTemplatesSaga() {
  yield takeLatest(GET_EMAIL_TEMPLATES, getEmailTemplate)
}

function* updateEmailTemplateSaga() {
  yield takeLatest(UPDATE_EMAIL_TEMPLATE, updateEmailTemplate)
}

function* getVariableSaga() {
  yield takeLatest(GET_VARIABLE, getVariableData)
}

export default function* emailSaga() {
  yield all([
    addEmailTemplateSaga(),
    getEmailTemplatesSaga(),
    updateEmailTemplateSaga(),
    deleteTemplateSaga(),
    getVariableSaga(),
  ])
}
