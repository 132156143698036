import {
  boatShowState,
  ActionType,
  GET_ALL_BOAT_SHOWS,
  GET_ALL_BOAT_SHOWS_SUCCESS,
  GET_ALL_BOAT_SHOWS_FAILURE,
  CHANGE_BOAT_SHOW_STATUS,
  CHANGE_BOAT_SHOW_STATUS_SUCCESS,
  CHANGE_BOAT_SHOW_STATUS_FAILURE,
  DELETE_BOAT_SHOW,
  DELETE_BOAT_SHOW_SUCCESS,
  DELETE_BOAT_SHOW_FAILURE,
  CLEAR_BOAT_SHOW_FLAG,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: boatShowState = {
  boatShows: [],
  boatShowsTotal: 0,
  isLoading: false,
  deleteSuccess: false,
  deleteError: false,
}

export const boatShowReducer = (state = initialState, action: ActionType): boatShowState => {
  const { boatShows } = state

  switch (action.type) {
    case GET_ALL_BOAT_SHOWS:
      return {
        ...state,
        isLoading: true,
        boatShows: [],
      }

    case GET_ALL_BOAT_SHOWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        boatShows: action.payload.items,
        boatShowsTotal: action.payload.total,
      }

    case GET_ALL_BOAT_SHOWS_FAILURE:
      return {
        ...state,
        isLoading: false,
        boatShows: [],
      }

    case CHANGE_BOAT_SHOW_STATUS:
      return {
        ...state,
      }

    case CHANGE_BOAT_SHOW_STATUS_SUCCESS:
      NotificationWithIcon('success', 'Status Changed Successfully')

      return {
        ...state,
        boatShows: boatShows.map((item: any) => {
          if (item.id == action.payload.id) {
            item[action.column] = !item[action.column]
          }
          return item
        }),
      }

    case CHANGE_BOAT_SHOW_STATUS_FAILURE:
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
      }

    case DELETE_BOAT_SHOW:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_BOAT_SHOW_SUCCESS:
      const filterBoatShow = action.payload.record.fieldArray.filter((item: any) => item.id !== action.payload.id)
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
        [action.payload.record.fieldName]: [...filterBoatShow],
      }

    case DELETE_BOAT_SHOW_FAILURE:
      action.error.map((item: any) => NotificationWithIcon('error', item.message))

      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case CLEAR_BOAT_SHOW_FLAG:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    default:
      return state
  }
}
