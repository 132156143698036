export const GET_SALES_ENGINE_HELP_CONTENTS = 'GET_SALES_ENGINE_HELP_CONTENTS'
export const GET_SALES_ENGINE_HELP_CONTENTS_SUCCESS = 'GET_SALES_ENGINE_HELP_CONTENTS_SUCCESS'
export const GET_SALES_ENGINE_HELP_CONTENTS_FAILURE = 'GET_SALES_ENGINE_HELP_CONTENTS_FAILURE'

export const CREATE_SALES_ENGINE_HELP_CONTENTS = 'CREATE_SALES_ENGINE_HELP_CONTENTS'
export const CREATE_SALES_ENGINE_HELP_CONTENTS_SUCCESS = 'CREATE_SALES_ENGINE_HELP_CONTENTS_SUCCESS'
export const CREATE_SALES_ENGINE_HELP_CONTENTS_FAILURE = 'CREATE_SALES_ENGINE_HELP_CONTENTS_FAILURE'

export const CLEAR_SALES_ENGINE_HELP_CONTENTS_FLAG = 'CLEAR_SALES_ENGINE_HELP_CONTENTS_FLAG'

export interface helpState {
  success: Boolean
  error: Boolean
  createSuccess: Boolean
  createError: Boolean
  salesEngineHelpContent: [helpStateData]
}

export interface helpStateData {
  id?: string
  member?: string
  content?: string
  createdAt?: string
}

interface getHelpContents {
  type: typeof GET_SALES_ENGINE_HELP_CONTENTS
}

interface getHelpContentSuccess {
  type: typeof GET_SALES_ENGINE_HELP_CONTENTS_SUCCESS
  payload: [helpStateData]
}

interface getHelpContentFailure {
  type: typeof GET_SALES_ENGINE_HELP_CONTENTS_FAILURE
}

interface createHelpContents {
  type: typeof CREATE_SALES_ENGINE_HELP_CONTENTS
  payload: any
}

interface createHelpContentSuccess {
  type: typeof CREATE_SALES_ENGINE_HELP_CONTENTS_SUCCESS
  payload: [helpStateData]
}

interface createHelpContentFailure {
  type: typeof CREATE_SALES_ENGINE_HELP_CONTENTS_FAILURE
}

interface clearHelpContentFlag {
  type: typeof CLEAR_SALES_ENGINE_HELP_CONTENTS_FLAG
}

export type ActionType =
  | getHelpContents
  | getHelpContentSuccess
  | getHelpContentFailure
  | clearHelpContentFlag
  | createHelpContents
  | createHelpContentSuccess
  | createHelpContentFailure
