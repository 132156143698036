import { all, put, takeLatest } from "redux-saga/effects";
import { client } from "../../..";
import { manageRentDashboard } from "./Schema";
import { GET_MANAGE_RENT_COUNT, GET_MANAGE_RENT_COUNT_FAILURE, GET_MANAGE_RENT_COUNT_SUCCESS } from "./Type";

function getManageRentCountApi() {
    return client
    .query({
      query: manageRentDashboard,
      fetchPolicy: 'no-cache',
    })
}

function* getManageRentCountData() {
  try {
    const res = yield getManageRentCountApi()
    yield put({type: GET_MANAGE_RENT_COUNT_SUCCESS, payload: res.data})
  } catch (error) {
    yield put({type: GET_MANAGE_RENT_COUNT_FAILURE, error})
  }
}

function* getManageRentSaga(){
  yield takeLatest(GET_MANAGE_RENT_COUNT, getManageRentCountData)
}

export default function* getManageRentCountSaga() {
  yield all([getManageRentSaga()])
}