import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card, Switch } from 'antd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { userState } from '../../../store/login/Types'

import { getAllRoles, clearRoleFlag, getUserCountByRoleWise } from '../../../store/role/Action'
import { getFrontUser, clearLoginFlag, changeAccountStatus } from '../../../store/login/Actions'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { dateStringFormate, displayDefaultValue } from '../../../helpers/helper'
import { TitleSearch } from '../../../component/SearchBar'
import UserCountCard from '../../../component/UserCountCard'
import { deleteAdminUserRole, clearAdminUserRoleFlag, searchUser } from '../../../store/adminUserRole/Action'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { getAllBoatPagination, activeInactive, verifyOrNot, paginationPage, userAllRoles, roleName } from '../../../enums/enums'
import VerifyDocumentCollapse from '../../../component/VerifyDocumentCollapse'
import FilterButton from '../../filters/FilterButton'
import { getDocumentUrlAction, clearDocumentStatus } from '../../../store/dashboard/documentVerify/Action'
import { userRole } from '../../../store/dashboard/frontUsers/Type'

let pageFilterArray: any = []

interface Props {
  roles: any[]
  frontLoad: boolean
  getFrontUser: typeof getFrontUser
  users: any[]
  roleCounts: any
  clearFlag: typeof clearLoginFlag
  location: Location
  changeAccountStatus: typeof changeAccountStatus
  clearAdminUserRoleFlag: typeof clearAdminUserRoleFlag
  deleteAdminUserSuccess: boolean
  deleteAdminUserRole: typeof deleteAdminUserRole
  getUserCountByRoleWise: typeof getUserCountByRoleWise
  totalUsersRow: Number
  getDocumentUrlAction: typeof getDocumentUrlAction
  getDocumentUrlSuccess: boolean
  getDocumentLink: string
  clearDocumentStatus: typeof clearDocumentStatus
  searchUser: typeof searchUser
}

interface State {
  searchedUsers: any[]
  searchedValue: string
  expandKeys: string[]
}

interface Location {
  state: Object
}

class CorporateSeller extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchedUsers: [],
      searchedValue: '',
      expandKeys: [],
    }
  }

  componentDidMount() {
    const { getFrontUser, roles, getUserCountByRoleWise, getDocumentUrlSuccess, clearDocumentStatus } = this.props
    const role: any = roles.find((item: any) => item.role === 'BROKER AND DEALER')
    pageFilterArray = [
      {
        title: 'User Status',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'userStatus',
      },
      {
        title: 'Activated Account',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'accountActivated',
      },
      {
        title: 'Verified Status',
        filedArray: verifyOrNot,
        listOptions: 'label',
        passed: 'value',
        actionField: 'isVerified',
      },
    ]
    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
      roleType: role && role.aliasName,
    }
    role && getUserCountByRoleWise(role.id)
    // role && getFrontUser(input)
    if (getDocumentUrlSuccess) {
      clearDocumentStatus()
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { getFrontUser, deleteAdminUserSuccess, clearAdminUserRoleFlag, roles } = props
    const role: any = roles.find((item: any) => item.role === 'BROKER AND DEALER')
    const input = {
      page: getAllBoatPagination.page,
      limit: getAllBoatPagination.limit,
      roleType: role && role.aliasName,
    }
    if (deleteAdminUserSuccess) {
      clearAdminUserRoleFlag()
      setTimeout(() => {
        role && getFrontUser(input)
      }, 100);
    }
    return null
  }

  columns = [
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'address',
      render: (text: any, record: tableRecords, index: number) => {
        return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
      },
    },
    {
      title: 'Joining Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: tableRecords, index: number) => <> {dateStringFormate(text)} </>,
      sorter: (a: any, b: any) => a.createdAt.length - b.createdAt.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'User Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
      render: (text: string, record: tableRecords, index: number) => {
        const { changeAccountStatus } = this.props

        return (
          <Switch
            checked={record.userStatus}
            onChange={() =>
              changeAccountStatus({
                value: record.userStatus,
                column: 'userStatus',
                id: record.id,
              })
            }
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        )
      },
    },
    {
      title: 'Account Status',
      dataIndex: 'accountActivated',
      key: 'accountActivated',
      render: (text: string, record: tableRecords, index: number) => (
        <span>
          {record.accountActivated ? (
            <span className="active-status-text">{'Active'}</span>
          ) : (
            <span className="inactive-status-text">{'Inactive'}</span>
          )}
        </span>
      ),
    },
    {
      title: 'Verified Status',
      dataIndex: 'isVerified',
      key: 'isVerified',
      render: (text: string, record: tableRecords, index: number) => (
        <span>
          {record.isVerified ? (
            <span className="active-status-text"> {'Verified'}</span>
          ) : (
            <span className="inactive-status-text"> {'Unverified'}</span>
          )}
        </span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'name',
      key: 'action',
      render: (text: string, record: tableRecords, index: number) => (
        <>
          <Button
            className="btn-delete"
            onClick={() => {
              record && record.id && confirmSubmitHandler(this.props.deleteAdminUserRole, record.id)
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ]

  onSearch = (value: any, role: any) => {
    const { searchUser, getFrontUser } = this.props
    if (value) {
      this.setState({ searchedValue: value })
      searchUser({ searchTerm: value, page: 1, limit: 10, roleType: role?.aliasName })
    } else {
      this.setState({ searchedValue: '' })
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
        roleType: role && role.aliasName,
      }
      role && getFrontUser(input)
    }
  }

  render() {
    const {
      users,
      roleCounts,
      totalUsersRow,
      roles,
      getFrontUser,
      getDocumentUrlAction,
      getDocumentUrlSuccess,
      frontLoad,
      getDocumentLink,
      searchUser,
    } = this.props
    const role: userRole = roles.find((item: userRole) => item.role === roleName.BROKER_DEALER)!
    const { searchedValue, expandKeys } = this.state

    return (
      <>
        <Card title="Broker And Dealer" extra={<TitleSearch onSearch={(value: any) => this.onSearch(value, role)} />}>
          <UserCountCard
            activeUsersCount={roleCounts && roleCounts.activeUsers}
            blockedUsersCount={roleCounts && roleCounts.blockedUsers}
            deActiveAccountCount={roleCounts && roleCounts.deActiveAccount}
            verifiedUsersCount={roleCounts && roleCounts.verifiedUsers}
            unVerifiedUsersCount={roleCounts && roleCounts.unVerifiedUsers}
          />
          <FilterButton
            filterArray={pageFilterArray}
            triggerAction={getFrontUser}
            extraInput={{ roleType: userAllRoles.BROKER_AND_DEALER }}
            haveCountry
          />
          <ExpandTable
            columns={this.columns}
            totalUsersRow={totalUsersRow}
            tableData={users}
            expandHtml={false}
            isExpand={false}
            pageAction={searchedValue ? searchUser : getFrontUser}
            page={paginationPage.boat}
            extraInput={{ roleType: userAllRoles.BROKER_AND_DEALER, searchTerm: searchedValue }}
            expandedRowRender={(record: any) => (
              <VerifyDocumentCollapse
                buttonTitle="Download"
                record={record}
                getDocumentUrlSuccess={getDocumentUrlSuccess}
                getDocumentLink={getDocumentLink}
                getDocumentUrlAction={getDocumentUrlAction}
              />
            )}
            onExpand={(expanded: boolean, record: tableRecords) => {
              const { id } = record
              if (expanded && id) {
                this.setState({ expandKeys: [id] })
              } else {
                this.setState({ expandKeys: [] })
              }
            }}
            rowKey={(record: tableRecords) => record?.id}
            expandedRowKeys={expandKeys}
            goToDashboard
            tableLoading={frontLoad}
          />
        </Card>
      </>
    )
  }
}

const mapStateToProps = (state: userState) => {
  return {
    roles: state.roleState.roles,
    users: state.adminUserRoleReducer.users,
    getDocumentLink: state.userDocumentReducer.getDocumentLink,
    getDocumentUrlSuccess: state.userDocumentReducer.getDocumentUrlSuccess,
    roleCounts: state.roleState && state.roleState.roleCounts,
    totalUsersRow: state.adminUserRoleReducer.totalUsersRow,
    deleteAdminUserSuccess: state.adminUserRoleReducer && state.adminUserRoleReducer.deleteAdminUserSuccess,
    frontLoad: state.adminUserRoleReducer.frontLoad,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getFrontUser: (data: string) => dispatch(getFrontUser(data)),
    clearFlag: () => dispatch(clearLoginFlag()),
    clearRoleFlag: () => dispatch(clearRoleFlag()),
    clearAdminUserRoleFlag: () => dispatch(clearAdminUserRoleFlag()),
    changeAccountStatus: (data: any) => dispatch(changeAccountStatus(data)),
    getUserCountByRoleWise: (data: string) => dispatch(getUserCountByRoleWise(data)),
    getAllRoles: () => dispatch(getAllRoles()),
    clearDocumentStatus: () => dispatch(clearDocumentStatus()),
    deleteAdminUserRole: (data: string) => dispatch(deleteAdminUserRole(data)),
    getDocumentUrlAction: (data: any) => dispatch(getDocumentUrlAction(data)),
    searchUser: (data: any) => dispatch(searchUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateSeller)
