export interface SurveyDocument {
  id?: string
  title?: string
  description?: string
  addMedia?: any[]
  document?: any
  removeMedia?: []
  __typename?: any
}

export interface SurveyDocumentState {
  surveyDocument: SurveyDocument
  getSuccess: boolean
  getError: boolean
  createSuccess: boolean
  createError: boolean
}

export const GET_SURVEY_DOCUMENT = 'GET_SURVEY_DOCUMENT'
export const GET_SURVEY_DOCUMENT_SUCCESS = 'GET_SURVEY_DOCUMENT_SUCCESS'
export const GET_SURVEY_DOCUMENT_FAILURE = 'GET_SURVEY_DOCUMENT_FAILURE'

export const CREATE_SURVEY_DOCUMENT = 'CREATE_SURVEY_DOCUMENT'
export const CREATE_SURVEY_DOCUMENT_SUCCESS = 'CREATE_SURVEY_DOCUMENT_SUCCESS'
export const CREATE_SURVEY_DOCUMENT_FAILURE = 'CREATE_SURVEY_DOCUMENT_FAILURE'

export const CLEAR_SURVEY_DOCUMENT_FLAG = 'CLEAR_SURVEY_DOCUMENT_FLAG'

interface getSurveyDocument {
  type: typeof GET_SURVEY_DOCUMENT
}

interface getSurveyDocumentSuccess {
  type: typeof GET_SURVEY_DOCUMENT_SUCCESS
  payload: SurveyDocument
}

interface getSurveyDocumentFailure {
  type: typeof GET_SURVEY_DOCUMENT_FAILURE
  payload?: any
}

interface createSurveyDocument {
  type: typeof CREATE_SURVEY_DOCUMENT
  payload: SurveyDocument
}

interface createSurveyDocumentSuccess {
  type: typeof CREATE_SURVEY_DOCUMENT_SUCCESS
  payload: SurveyDocument
}

interface createSurveyDocumentFailure {
  type: typeof CREATE_SURVEY_DOCUMENT_FAILURE
  payload?: any
}

interface clearFlag {
  type: typeof CLEAR_SURVEY_DOCUMENT_FLAG
}

export type ActionType =
  | getSurveyDocument
  | getSurveyDocumentSuccess
  | getSurveyDocumentFailure
  | createSurveyDocument
  | createSurveyDocumentSuccess
  | createSurveyDocumentFailure
  | clearFlag
