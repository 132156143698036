import { AuthDetail } from '../../container/login'
import { roleStateData } from '../role/Types'
import { frontUsersState } from '../dashboard/frontUsers/Type'

// Describing the shape of the chat's slice of state
export interface userLoginState {
  login: {
    username: string
    password: string
  }
  isAuth: AuthDetail | string
  successMessage: boolean
  errorMessage: string
  isCreated: boolean
  success: boolean
  error: boolean
  lookupsData: any[]
  users: []
  accountStatusError: boolean
  totalUsersRow: number
}

export interface lookupState {
  id?: string
  isActive?: number
  getLookupSuccess: boolean
  getLookupError: boolean
  lookupsData: any[]
  tripLookups: any[]
  tripLookupsSuccess: boolean
  tripLookupsError: boolean
  totalLookupsRow: number
  getTypeSuccess: boolean
  getTypeError: boolean
  typesData: any[]
  totalTypesRow: number
  items: any[]
  total: number
  createSuccess: boolean
  editSuccess: boolean
  editError: boolean
  createError: boolean
  deleteSuccess: boolean
  deleteError: boolean
}

export interface userState {
  userDocumentReducer: any
  frontUsersCountReducer: frontUsersState
  adminUserRoleReducer: any
  roleState: roleStateData
  userLoginState: userLoginState
}

export interface userSaveData {
  username: string
  password: string
}

export interface manageTypeText {
  id: string
  name: string
  url: string
}

export interface typeId {
  id: string
  type: string
}

export interface manageType {
  alias: string
  icon: manageTypeText
  id: string
  isActive: boolean
  lookUpName: string
  typeId: typeId
}

export interface fileType {
  lastModified: number
  name: string
  size: number
  type: string
  uid: string
  webkitRelativePath: string
}

export interface iconType {
  filter?: any
  length?: iconType
  key: string
  mediaName: string
  mediaType: string
  name: string
  type: string
  url?: string
}

export interface valueType {
  aliasName?: string
  icon: iconType
  id: string
  name: string
  tripId?: string[]
}

export interface removeFileType {
  name: string
  percent: number
  response: string
  size: number
  status: string
  thumbUrl: string
  type: string
  uid: string
}

// Describing the different ACTION NAMES available
export const LOGIN_USER = 'LOGIN_USER'
export const CLEAR_RECORD = 'CLEAR_RECORD'
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN'
export const ERROR_LOGIN = 'ERROR_LOGIN'
export const ERROR_COMMON = 'ERROR_COMMON'

export const GET_ALL_LOOKUPS = 'GET_ALL_LOOKUPS'
export const GET_ALL_LOOKUPS_SUCCESS = 'GET_ALL_LOOKUPS_SUCCESS'
export const GET_ALL_LOOKUPS_ERROR = 'GET_ALL_LOOKUPS_ERROR'

export const GET_TRIP_LOOKUPS = 'GEY_TRIP_TYPE_LOOKUPS'
export const GET_TRIP_LOOKUPS_SUCCESS = 'GEY_TRIP_TYPE_LOOKUPS_SUCCESS'
export const GET_TRIP_LOOKUPS_ERROR = 'GEY_TRIP_TYPE_LOOKUPS_ERROR'

export const GET_ALL_TYPES = 'GET_ALL_TYPES'
export const GET_ALL_TYPES_SUCCESS = 'GET_ALL_TYPES_SUCCESS'
export const GET_ALL_TYPES_ERROR = 'GET_ALL_TYPES_ERROR'

export const EDIT_LOOKUP = 'EDIT_LOOKUP'
export const EDIT_LOOKUP_SUCCESS = 'EDIT_LOOKUP_SUCCESS'
export const EDIT_LOOKUP_ERROR = 'EDIT_LOOKUP_ERROR'

export const CREATE_LOOKUP = 'CREATE_LOOKUP'
export const CREATE_LOOKUP_SUCCESS = 'CREATE_LOOKUP_SUCCESS'
export const CREATE_LOOKUP_ERROR = 'CREATE_LOOKUP_ERROR'

export const DELETE_LOOKUP = 'DELETE_LOOKUP'
export const DELETE_LOOKUP_SUCCESS = 'DELETE_LOOKUP_SUCCESS'
export const DELETE_LOOKUP_ERROR = 'DELETE_LOOKUP_ERROR'

export const CLEAR_ALL_LOOKUPS = 'CLEAR_ALL_LOOKUPS'

interface getAllTypes {
  type: typeof GET_ALL_TYPES
  payload: lookupState
}
interface getAllTypesSuccess {
  type: typeof GET_ALL_TYPES_SUCCESS
  payload: lookupState
}
interface getAllTypesError {
  type: typeof GET_ALL_TYPES_ERROR
  payload: lookupState
}

interface editLookUp {
  type: typeof EDIT_LOOKUP
  payload: lookupState
}
interface editLookUpSuccess {
  type: typeof EDIT_LOOKUP_SUCCESS
  payload: lookupState
}
interface editLookUpError {
  type: typeof EDIT_LOOKUP_ERROR
  payload: lookupState
}

interface createLookUpType {
  type: typeof CREATE_LOOKUP
  payload: lookupState
}
interface createLookUpTypeSuccess {
  type: typeof CREATE_LOOKUP_SUCCESS
  payload: lookupState
}
interface createLookUpTypeError {
  type: typeof CREATE_LOOKUP_ERROR
  payload: lookupState
}

interface deleteLookUpType {
  type: typeof DELETE_LOOKUP
  payload: lookupState
}
interface deleteLookUpTypeSuccess {
  type: typeof DELETE_LOOKUP_SUCCESS
  payload: lookupState
}
interface deleteLookUpTypeError {
  type: typeof DELETE_LOOKUP_ERROR
  payload: lookupState
}
interface getAllLookups {
  type: typeof GET_ALL_LOOKUPS
  payload: lookupState
}
interface getAllLookupsSuccess {
  type: typeof GET_ALL_LOOKUPS_SUCCESS
  payload: lookupState
}
interface getAllLookupsError {
  type: typeof GET_ALL_LOOKUPS_ERROR
  payload: lookupState
}
interface getTripLookups {
  type: typeof GET_TRIP_LOOKUPS
  payload: lookupState
}
interface getTripLookupsSuccess {
  type: typeof GET_TRIP_LOOKUPS_SUCCESS
  payload: lookupState
}
interface getTripLookupsError {
  type: typeof GET_TRIP_LOOKUPS_ERROR
  payload: lookupState
}
interface clearAllLookups {
  type: typeof CLEAR_ALL_LOOKUPS
}
interface loginUser {
  type: typeof LOGIN_USER
  payload: userLoginState
}
interface successLogin {
  type: typeof SUCCESS_LOGIN
  payload: string
}
interface clearMessage {
  type: typeof CLEAR_RECORD
}
interface errorLogin {
  type: typeof ERROR_LOGIN
  payload: string
}
interface errorCommon {
  type: typeof ERROR_COMMON
  payload: string
}

export type ActionType =
  | loginUser
  | clearMessage
  | successLogin
  | errorCommon
  | errorLogin
  | getAllLookups
  | getAllLookupsSuccess
  | getAllLookupsError
  | getTripLookups
  | getTripLookupsSuccess
  | getTripLookupsError
  | clearAllLookups
  | getAllTypes
  | getAllTypesSuccess
  | getAllTypesError
  | editLookUp
  | editLookUpSuccess
  | editLookUpError
  | createLookUpType
  | createLookUpTypeSuccess
  | createLookUpTypeError
  | deleteLookUpType
  | deleteLookUpTypeSuccess
  | deleteLookUpTypeError
