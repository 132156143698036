import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import {
  createPage,
  updatePage,
  deletePage,
  getAllPages,
  // getSinglePage,
  // getPagesByTitle
} from './Schema'
import {
  ActionType,
  GET_ALL_PAGES_SUCCESS,
  GET_ALL_PAGES_FAILURE,
  CREATE_PAGES_SUCCESS,
  CREATE_PAGES_FAILURE,
  UPDATE_PAGES_SUCCESS,
  UPDATE_PAGES_FAILURE,
  DELETE_PAGES_SUCCESS,
  DELETE_PAGES_FAILURE,
  GET_ALL_PAGES,
  CREATE_PAGES,
  UPDATE_PAGES,
  DELETE_PAGES,
  // GET_SINGLE_PAGE_SUCCESS,
  // GET_SINGLE_PAGE_FAILURE,
  // GET_PAGES_BY_TITLE_SUCCESS,
  // GET_PAGES_BY_TITLE_FAILURE
} from './Type'
import { AnyAction } from 'redux'

function getAllPagesApi({ payload }: any) {
  return client
    .query({
      query: getAllPages,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

// function getSinglePageApi(data: any) {
//     const id = data.payload;
//     return client
//       .query({
//         query: getSinglePage,
//         variables: {
//             id
//         },
//         fetchPolicy: "no-cache"
//       })
//       .then(res => res)
//       .catch(error => {
//         throw error;
//       });
//   }

//   function getPagesByTitleApi(data: any) {
//     const title = data.payload;
//     return client
//       .query({
//         query: getPagesByTitle,
//         variables: {
//             title
//         },
//         fetchPolicy: "no-cache"
//       })
//       .then(res => res)
//       .catch(error => {
//         throw error;
//       });
//   }

function createPagesApi(input: any) {
  return client
    .mutate({
      mutation: createPage,
      variables: {
        page: input,
      },
    })
}

function updatePagesApi(data: any) {
  const { id, title, pageContent } = data
  const page = {
    id,
    title,
    pageContent,
  }

  return client
    .mutate({
      mutation: updatePage,
      variables: { page },
    })
}

function deletePageApi({ payload }: any) {
  return client
    .mutate({
      mutation: deletePage,
      variables: { id: payload },
    })
}

function* getAllPagesData({ payload }: AnyAction) {
  try {
    const res = yield getAllPagesApi({ payload })

    yield put({
      type: GET_ALL_PAGES_SUCCESS,
      payload: res.data.getAllPages,
    })
  } catch (error) {
    yield put({ type: GET_ALL_PAGES_FAILURE, error })
  }
}

// function* getSinglePageData({ payload }: AnyAction) {
//     try {
//       const res = yield getSinglePageApi({ payload });
//       yield put({
//         type: GET_SINGLE_PAGE_SUCCESS,
//         payload: res.data.getSinglePage
//       });
//     } catch (error) {
//       yield put({ type: GET_SINGLE_PAGE_FAILURE, error });
//     }
//   }

//   function* getPagesByTitleData({ payload }: AnyAction) {
//     try {
//       const res = yield getPagesByTitleApi({ payload });
//       yield put({
//         type: GET_PAGES_BY_TITLE_SUCCESS,
//         payload: res.data.getPagesByTitle
//       });
//     } catch (error) {
//       yield put({ type: GET_PAGES_BY_TITLE_FAILURE, error });
//     }
//   }

function* createPageData(action: ActionType) {
  try {
    const res = yield createPagesApi(action.payload)
    yield put({ type: CREATE_PAGES_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: CREATE_PAGES_FAILURE, error })
  }
}

export function* updatePagesData(action: ActionType) {
  try {
    const data = yield updatePagesApi(action.payload)
    yield put({ type: UPDATE_PAGES_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_PAGES_FAILURE, error })
  }
}

function* deletePagesData(action: ActionType) {
  try {
    const res = yield deletePageApi(action)
    yield put({ type: DELETE_PAGES_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_PAGES_FAILURE, error })
  }
}

function* getAllPagesSaga() {
  yield takeLatest(GET_ALL_PAGES, getAllPagesData)
}

function* createPagesSaga() {
  yield takeLatest(CREATE_PAGES, createPageData)
}

function* updatePagesSaga() {
  yield takeLatest(UPDATE_PAGES, updatePagesData)
}

function* deletePagesSaga() {
  yield takeLatest(DELETE_PAGES, deletePagesData)
}

export default function* pageSaga() {
  yield all([getAllPagesSaga(), deletePagesSaga(), createPagesSaga(), updatePagesSaga()])
}
