import { Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { Component } from 'react'
import { rejectBoatAd } from '../enums/enums'
import WSField from './Field'
import WSToolTip from './WsToolTip'

export interface Props {
  onClick?: () => void
  onChangeText?: any
  errorMessage?: boolean
}

export class RejectionMailEditor extends Component<Props> {
  render() {
    const { onClick, errorMessage, onChangeText } = this.props
    return (
      <div>
        <WSField onChangeText={onChangeText} type="textArea" />
        {errorMessage && <Text type="danger">Rejection Message Required</Text>}

        <WSToolTip title={rejectBoatAd}>
          <Button className="btn-view mt-3" onClick={onClick}>
            Submit
          </Button>
        </WSToolTip>
      </div>
    )
  }
}
