export interface yatchTypesState {
  yatchTypes: any[]
  updateYatchTypeSuccess: boolean
  getYatchLoader: boolean
  yatchTypeSuccess: boolean
  yatchTypeError: boolean
  deleteSuccess: boolean
  deleteError: boolean
  getYatchTypeSuccess: boolean
  yatchTypesTableRow: number
}

export interface YatchTypesData {
  icon: String
  name: String
  id: String
}

export interface yatchTypes {
  icon: String
  name: String
  id: String
}

//create yatch type

export const CREATE_YATCH_TYPES = 'CREATE_YATCH_TYPES'
export const CREATE_YATCH_TYPES_SUCCESS = 'CREATE_YATCH_TYPES_SUCCESS'
export const CREATE_YATCH_TYPES_FAILURE = 'CREATE_YATCH_TYPES_FAILURE'

//get all yatch type

export const GET_ALL_YATCH_TYPES = 'GET_ALL_YATCH_TYPES'
export const GET_ALL_YATCH_TYPES_SUCCESS = 'GET_ALL_YATCH_TYPES_SUCCESS'
export const GET_ALL_YATCH_TYPES_FAILURE = 'GET_ALL_YATCH_TYPES_FAILURE'

//clear flag

export const CLEAR_MANAGE_YATCH_TYPES_FLAG = 'CLEAR_MANAGE_YATCH_TYPES_FLAG'

//update yatch type

export const UPDATE_YATCH_TYPES = 'UPDATE_YATCH_TYPES'
export const UPDATE_YATCH_TYPES_SUCCESS = 'UPDATE_YATCH_TYPES_SUCCESS'
export const UPDATE_YATCH_TYPES_FAILURE = 'UPDATE_YATCH_TYPES_FAILURE'

// delete yatch type

export const DELETE_YATCH_TYPES = 'DELETE_YATCH_TYPES'
export const DELETE_YATCH_TYPES_SUCCESS = 'DELETE_YATCH_TYPES_SUCCESS'
export const DELETE_YATCH_TYPES_FAILURE = 'DELETE_YATCH_TYPES_FAILURE'

// edit yatch type

export const EDIT_YATCH_TYPES = 'EDIT_YATCH_TYPES'
export const EDIT_YATCH_TYPES_SUCCESS = 'EDIT_YATCH_TYPES_SUCCESS'
export const EDIT_YATCH_TYPES_FAILURE = 'EDIT_YATCH_TYPES_FAILURE'

interface createYatchTypes {
  type: typeof CREATE_YATCH_TYPES
  payload: YatchTypesData
}

interface createYatchTypesSuccess {
  type: typeof CREATE_YATCH_TYPES_SUCCESS
  payload: yatchTypes
}

interface createYatchTypesFailure {
  type: typeof CREATE_YATCH_TYPES_FAILURE
  payload: any
}

interface getAllYatchTypes {
  type: typeof GET_ALL_YATCH_TYPES
  payload: YatchTypesData
}

interface getAllYatchTypesSuccess {
  type: typeof GET_ALL_YATCH_TYPES_SUCCESS
  payload: any
}

interface getAllYatchTypesFailure {
  type: typeof GET_ALL_YATCH_TYPES_FAILURE
  payload: any
}

interface clearManageYatchTypesFlag {
  type: typeof CLEAR_MANAGE_YATCH_TYPES_FLAG
  payload: any
}

interface updateYatchTypes {
  type: typeof UPDATE_YATCH_TYPES
  payload: yatchTypes
}

interface updateYatchTypesSuccess {
  type: typeof UPDATE_YATCH_TYPES_SUCCESS
  payload: yatchTypes
}

interface updateYatchTypesFailure {
  type: typeof UPDATE_YATCH_TYPES_FAILURE
  payload: any
}

interface editYatchTypes {
  type: typeof UPDATE_YATCH_TYPES
  payload: string
}

interface editYatchTypesSuccess {
  type: typeof UPDATE_YATCH_TYPES_SUCCESS
  payload: string
}

interface editYatchTypesFailure {
  type: typeof UPDATE_YATCH_TYPES_FAILURE
  payload: any
}

interface deleteYatchTypes {
  type: typeof DELETE_YATCH_TYPES
  payload: string
}

interface deleteYatchTypesSuccess {
  type: typeof DELETE_YATCH_TYPES_SUCCESS
  payload: any
}

interface deleteYatchTypesFailure {
  type: typeof DELETE_YATCH_TYPES_FAILURE
  payload: string
}

export type ActionType =
  | createYatchTypes
  | createYatchTypesSuccess
  | createYatchTypesFailure
  | getAllYatchTypes
  | getAllYatchTypesSuccess
  | getAllYatchTypesFailure
  | clearManageYatchTypesFlag
  | deleteYatchTypes
  | deleteYatchTypesSuccess
  | deleteYatchTypesFailure
  | updateYatchTypes
  | updateYatchTypesSuccess
  | updateYatchTypesFailure
  | editYatchTypes
  | editYatchTypesSuccess
  | editYatchTypesFailure
