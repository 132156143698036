import {
  yatchTypesState,
  ActionType,
  CREATE_YATCH_TYPES,
  CREATE_YATCH_TYPES_SUCCESS,
  CREATE_YATCH_TYPES_FAILURE,
  UPDATE_YATCH_TYPES,
  UPDATE_YATCH_TYPES_SUCCESS,
  UPDATE_YATCH_TYPES_FAILURE,
  GET_ALL_YATCH_TYPES,
  GET_ALL_YATCH_TYPES_SUCCESS,
  GET_ALL_YATCH_TYPES_FAILURE,
  CLEAR_MANAGE_YATCH_TYPES_FLAG,
  DELETE_YATCH_TYPES,
  DELETE_YATCH_TYPES_SUCCESS,
  DELETE_YATCH_TYPES_FAILURE,
} from './Types'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: yatchTypesState = {
  yatchTypes: [],
  yatchTypeSuccess: false,
  getYatchLoader: false,
  yatchTypeError: false,
  deleteSuccess: false,
  deleteError: false,
  getYatchTypeSuccess: false,
  yatchTypesTableRow: 0,
  updateYatchTypeSuccess: false,
}

export const yatchTypesReducer = (state = initialState, action: ActionType): yatchTypesState => {
  switch (action.type) {
    case CREATE_YATCH_TYPES:
      return {
        ...state,
        getYatchTypeSuccess: false,
        yatchTypeError: false,
      }
    case CREATE_YATCH_TYPES_SUCCESS:
      return {
        ...state,
        getYatchTypeSuccess: true,
        yatchTypeError: false,
      }
    case CREATE_YATCH_TYPES_FAILURE:
      return {
        ...state,
        getYatchTypeSuccess: false,
        yatchTypeError: true,
      }
    case GET_ALL_YATCH_TYPES:
      return {
        ...state,
        yatchTypes: [],
        getYatchLoader: true,
        yatchTypeSuccess: false,
        yatchTypeError: false,
      }
    case GET_ALL_YATCH_TYPES_SUCCESS:
      return {
        ...state,
        yatchTypeSuccess: true,
        getYatchLoader: false,
        yatchTypeError: false,
        yatchTypes: action.payload.data.getAllBoatServiceTypes.items,
        yatchTypesTableRow: action.payload.data.getAllBoatServiceTypes.total,
      }
    case GET_ALL_YATCH_TYPES_FAILURE:
      return {
        ...state,
        yatchTypes: [],
        getYatchLoader: false,
        yatchTypeSuccess: false,
        yatchTypeError: true,
      }
    case CLEAR_MANAGE_YATCH_TYPES_FLAG:
      return {
        ...state,
        yatchTypeSuccess: false,
        yatchTypeError: false,
        deleteError: false,
        deleteSuccess: false,
        getYatchTypeSuccess: false,
        updateYatchTypeSuccess: false,
      }
    case UPDATE_YATCH_TYPES:
      return {
        ...state,
        updateYatchTypeSuccess: false,
        yatchTypeError: true,
      }
    case UPDATE_YATCH_TYPES_SUCCESS:
      return {
        ...state,
        updateYatchTypeSuccess: true,
        yatchTypeError: false,
      }
    case UPDATE_YATCH_TYPES_FAILURE:
      return {
        ...state,
        updateYatchTypeSuccess: false,
        yatchTypeError: true,
      }
    case CREATE_YATCH_TYPES:
      return {
        ...state,
        getYatchTypeSuccess: false,
        yatchTypeError: false,
      }
    case CREATE_YATCH_TYPES_SUCCESS:
      return {
        ...state,
        getYatchTypeSuccess: true,
        yatchTypeError: false,
      }
    case CREATE_YATCH_TYPES_FAILURE:
      return {
        ...state,
        getYatchTypeSuccess: false,
        yatchTypeError: true,
      }
    case DELETE_YATCH_TYPES:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: false,
      }

    case DELETE_YATCH_TYPES_SUCCESS:
      NotificationWithIcon('success', 'Record Remove Successfully.')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }

    case DELETE_YATCH_TYPES_FAILURE:
      NotificationWithIcon('error', 'Something went wrong.')
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }
    default:
      return state
  }
}
