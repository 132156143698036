import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { getAdminOtherCount } from '../../../store/dashboard/others/Action'
import { CircularGraph } from '../../../component/charts/CircularGraph'
import UserTypeCountCard from '../../../component/UserTypeCountCard'
import { getDataFromArray } from '../../../helpers/helper'

export interface Props {
  getAdminOtherCount: typeof getAdminOtherCount
  otherCounts: []
  otherCountSuccess: boolean
}

class OtherUsers extends Component<Props> {
  componentDidMount() {
    const { getAdminOtherCount } = this.props
    getAdminOtherCount()
  }

  render() {
    const { otherCounts } = this.props
    return (
      <>
        <div style={{ background: '#ECECEC', padding: '30px' }}>
          <Row gutter={16}>
            {otherCounts.map((element: any) => {
              return (
                <Col sm={6} className="mb-20">
                  <UserTypeCountCard item={element} />
                </Col>
              )
            })}
          </Row>
        </div>

        <br />

        <Col sm={12}>
          <CircularGraph
            chartTitle={'FRONT USERS'}
            labels={getDataFromArray(otherCounts, 'role')}
            data={getDataFromArray(otherCounts, 'count')}
          />
        </Col>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  otherCounts: state.adminOtherCountReducer && state.adminOtherCountReducer.otherCounts,
  otherCountSuccess: state.adminOtherCountReducer && state.adminOtherCountReducer.otherCountSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAdminOtherCount: () => dispatch(getAdminOtherCount()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OtherUsers)
