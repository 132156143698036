import * as React from 'react'
import { camelCaseToNormalCase, mobileNumberFormat, getLocalStorageItem } from '../helpers/helper'
import { Col, Radio } from 'antd'
import TextBox from './TextBox'
import UploadImage from './UploadImage'
import { CKEditorWrapper } from './CKEditor'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface Props {
  type: string
  defaultValue?: any
  uploadFunction?: any
  placeholder?: string
  accepted?: string
  onChange?: any
  onChangeText?: any
  value?: any
  disabled?: any
  name?: string
  countryField?: string
  defaultFileList?: any[]
  onRemove?: any
  openFileDialogOnClick?: boolean
  mobDisabled?: boolean
  covertCase?: boolean
  onBlur?: (e: any) => void
}
class WSField extends React.Component<Props> {
  renderItem = () => {
    const {
      type,
      defaultValue,
      openFileDialogOnClick,
      mobDisabled,
      placeholder,
      uploadFunction,
      accepted,
      onChangeText,
      value,
      countryField,
      defaultFileList,
      onRemove,
      onBlur,
      covertCase = true,
    } = this.props

    switch (type) {
      case 'textbox':
        console.log(placeholder)
        return <TextBox {...this.props} placeholder={placeholder} required defaultValue={defaultValue} onBlur={onBlur} />
      case 'checkbox':
        return (
          <Radio.Group value={defaultValue}>
            <Radio value={'Enabled'}>Enabled</Radio>
            <Radio value={'Disabled'}>Disabled</Radio>
          </Radio.Group>
        )
      case 'imageUpload':
        return (
          <UploadImage
            openFileDialogOnClick={openFileDialogOnClick}
            defaultFileList={defaultFileList}
            accept={accepted}
            withIcon
            buttonText={covertCase ? camelCaseToNormalCase(defaultValue) : defaultValue}
            selectSiteIcon={uploadFunction}
            onRemove={onRemove}
          />
        )
      case 'textArea':
        return (
          <CKEditorWrapper
            onBeforeLoad={(CKEDITOR: any) => (CKEDITOR.disableAutoInline = true)}
            value={defaultValue}
            onChange={onChangeText}
            onBlur={onBlur}
          />
        )
      case 'mobile-number':
        return (
          <PhoneInput
            disabled={mobDisabled}
            country={(countryField && countryField.toLowerCase()) || 'us'}
            value={value}
            onChange={onChangeText}
          />
        )
      default:
        break
    }
  }
  render() {
    return this.renderItem()
  }
}

export default WSField
