import { put, takeLatest, all } from 'redux-saga/effects'
import {
  ActionType,
  DELETE_ARTICLE,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAILURE,
  GET_MANAGE_ARTICLES,
  GET_MANAGE_ARTICLES_SUCCESS,
  GET_MANAGE_ARTICLES_FAILURE,
  CHANGE_ARTICLE_STATUS,
  CHANGE_ARTICLE_STATUS_SUCCESS,
  CHANGE_ARTICLE_STATUS_FAILURE,
  articleInput,
} from './Types'
import { client } from '../../../'
import { getAllManageArticles, deleteManageArticle, changeStatus } from './Schema'

function deleteArticleApi(data: articleInput) {
  const id = data.id
  const columnName = data.columnName
  const value = data.value
  const note = data.note
  return client
    .mutate({
      mutation: changeStatus,
      variables: { id, columnName, value, note },
    })
}

function getManageArticlesApi(payload: any) {
  return client
    .query({
      query: getAllManageArticles,
      variables: {
        page: payload.page,
        limit: payload.limit,
        articleApproved: payload.articleApproved,
        searchTerm: payload.searchTerm
      },
      fetchPolicy: 'no-cache'
    })
}

function* deleteArticle(action: ActionType) {
  try {
    const res = yield deleteArticleApi(action.payload)
    yield put({ type: DELETE_ARTICLE_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_ARTICLE_FAILURE, error })
  }
}

function* getManageArticles(action: any) {
  try {
    const res = yield getManageArticlesApi(action.payload)
    yield put({
      type: GET_MANAGE_ARTICLES_SUCCESS,
      payload: res.data.getAllArticles,
    })
  } catch (error) {
    yield put({ type: GET_MANAGE_ARTICLES_FAILURE, error })
  }
}

function* deleteArticleSaga() {
  yield takeLatest(DELETE_ARTICLE, deleteArticle)
}

function* getManageArticlesSaga() {
  yield takeLatest(GET_MANAGE_ARTICLES, getManageArticles)
}

function changeArticleStatusApi(input: any) {
  return client
    .mutate({
      mutation: changeStatus,
      variables: input,
    })
}

function* changeArticleStatusData(action: ActionType) {
  try {
    const data = yield changeArticleStatusApi(action.payload)
    yield put({ type: CHANGE_ARTICLE_STATUS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CHANGE_ARTICLE_STATUS_FAILURE, e })
  }
}

function* changeArticleStatusSaga() {
  yield takeLatest(CHANGE_ARTICLE_STATUS, changeArticleStatusData)
}

export default function* articleSaga() {
  yield all([getManageArticlesSaga(), deleteArticleSaga(), changeArticleStatusSaga()])
}
