import gql from 'graphql-tag'
import { imageSchema } from '../../schemaTypes'

export const createBoatServiceType = gql`
 mutation createBoatServiceType($input: BoatServiceTypeInput!) {
    createBoatServiceType(input: $input) {
        id
        icon ${imageSchema}
        thumbnailIcon ${imageSchema}
        name
    }
 }
 `

export const getAllBoatServiceTypes = gql`
  query getAllBoatServiceTypes($limit: Int, $page: Int, $searchTerm: String) {
    getAllBoatServiceTypes(limit: $limit, page: $page, searchTerm: $searchTerm) {
        items {
            id
            name
            thumbnailIcon ${imageSchema}
            icon ${imageSchema}
            createdAt
            updatedAt
        }
        total
    }
 }
 `

export const updateBoatServiceTypes = gql`
 mutation updateBoatServiceType($input: BoatServiceTypeInput!) {
    updateBoatServiceType(input: $input) { 
        id
        name
        thumbnailIcon ${imageSchema}
        icon ${imageSchema}
    }
 }
 `

export const deleteBoatServiceType = gql`
  mutation deleteBoatServiceType($id: String!) {
    deleteBoatServiceType(id: $id) {
      id
    }
  }
`

// export const editBoatTypes = gql`
// query editBoatType($id: String!) {
//     editBoatType(id: $id) {
//         id
//         name
//         icon
//     }
// }
// `;
