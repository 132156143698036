import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import FormTable, { tableRecords } from './FormTable'
import { getContact, deleteContactAction } from '../../store/form/Actions'
import { getAllBoatPagination } from '../../enums/enums'
import { dateStringFormate } from '../../helpers/helper'

interface Props {
  getContact: typeof getContact
  contactUsersTotalRow: number
  contactUsers: any[]
  deleteContactAction: typeof deleteContactAction
}

class ContactUs extends Component<Props> {
  columns = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: any) => {
        return <div>{text && text.name}</div>
      },
    },
    {
      title: 'Full Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: any, record: tableRecords) => {
        return <div>{`${text} ${record.lastName}`}</div>
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any) => {
        return <div>{dateStringFormate(text.createdAt)}</div>
      },
    },
  ]

  // componentDidMount() {
  //   const { getContact } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getContact(input)
  // }

  render() {
    const { contactUsersTotalRow, contactUsers, getContact, deleteContactAction } = this.props
    return (
      <FormTable
        tableTitle="Contact us"
        renderColumn={this.columns}
        getListAction={getContact}
        totalUsersRow={contactUsersTotalRow}
        tableList={contactUsers}
        deleteAction={deleteContactAction}
        formModule="contactModule"
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    contactUsers: state.feedbackReducer.contactUsers,
    contactUsersTotalRow: state.feedbackReducer.contactUsersTotalRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getContact: (data: any) => dispatch(getContact(data)),
    deleteContactAction: (data: any) => dispatch(deleteContactAction(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs)
