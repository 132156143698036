import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card, Switch } from 'antd'
import { connect } from 'react-redux'

import { getAllBoats, clearBoatFlag, deleteBoats, changeBoatApproveStatus, searchBoats } from '../../../store/pendingAds/Action'
import { Dispatch } from 'redux'
import history from '../../../History'
import Loader from '../../../component/Loading'
import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import {
  pagination,
  paginationPage,
  activeInactive,
  nullableFilters,
  tooltipText,
  getAllBoatPagination,
} from '../../../enums/enums'
import { TitleSearch } from '../../../component/SearchBar'
import { formateDate, viewBoatHandler, displayDefaultValue } from '../../../helpers/helper'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import ThreeToggleSwitch from '../../../component/ThreeToggleSwitch'
import ApproveButton from '../../../component/ApproveButton'
import FilterButton from '../../filters/FilterButton'
import WSToolTip from '../../../component/WsToolTip'

let pageFilterArray: any = []

export interface Props {
  getAllBoats: typeof getAllBoats
  clearBoatFlag: typeof clearBoatFlag
  boatSuccess: Boolean
  boats: []
  totalAdminRow: any
  history: typeof history
  deleteBoats: typeof deleteBoats
  boatIsLoading: boolean
  changeBoatApproveStatus: typeof changeBoatApproveStatus
  searchBoats: typeof searchBoats
}

export interface State {
  boats: []
  searchedValue: string
}
export interface boatStateData {
  boatIsLoading: boolean
  boatSuccess: Boolean
  boats: []
}

export interface boatState {
  boatState: boatStateData
}

class AllBoat extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      boats: [],
      searchedValue: '',
    }
  }

  componentDidMount() {
    // const { getAllBoats } = this.props
    pageFilterArray = [
      {
        title: 'Status',
        filedArray: activeInactive,
        listOptions: 'label',
        passed: 'value',
        actionField: 'status',
      },
      {
        title: 'Feature Status',
        filedArray: nullableFilters,
        listOptions: 'label',
        passed: 'value',
        actionField: 'featureStatus',
      },
      {
        title: 'Best Deal Status',
        filedArray: nullableFilters,
        listOptions: 'label',
        passed: 'value',
        actionField: 'bestDealStatus',
      },
      {
        title: 'Little Boat Status',
        filedArray: nullableFilters,
        listOptions: 'label',
        passed: 'value',
        actionField: 'mustBuyStatus',
      },
    ]
    // getAllBoats({
    //   limit: pagination.allBoatsLimit,
    //   page: pagination.page,
    //   adStatus: true,
    // })
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { boatSuccess, clearBoatFlag } = props

    if (boatSuccess) {
      clearBoatFlag()
    }
    return null
  }

  boatDetail = (record: any) => {
    if (record && record.hasOwnProperty('id')) {
      viewBoatHandler(record)
    }
  }

  changeBoatStatus = (columnName: any, record: any, changedValue: any) => {
    console.log(record, 'record')

    const { changeBoatApproveStatus, boats } = this.props
    changeBoatApproveStatus({
      id: record.id,
      columnName,
      value: changedValue,
      fieldName: 'boats',
      fieldArray: boats,
    })
  }

  onSearch = (value: any) => {
    const { getAllBoats } = this.props
    const input = {
      ...getAllBoatPagination,
      adStatus: true,
    }

    if (value) {
      this.setState({ searchedValue: value })
      getAllBoats({ ...input, searchTerm: value })
    } else {
      this.setState({ searchedValue: '' })
      getAllBoats(input)
    }
  }

  columns = [
    {
      title: 'Country',
      dataIndex: 'address',
      key: 'address',
      render: (text: any) => {
        return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
      },
    },
    {
      title: 'Ad Seller',
      dataIndex: 'seller',
      key: 'seller',
      sorter: (a: any, b: any) => a.seller.firstName.length - b.seller.firstName.length,
      sortDirections: ['descend', 'ascend'],
      render: (text: any) => <span>{`${text.firstName} ${text.lastName}`}</span>,
    },
    {
      title: 'Ad ID',
      dataIndex: 'adId',
      key: 'adID',
    },
    {
      title: 'Boat Name',
      dataIndex: 'boatName',
      key: 'boatName',
      sorter: (a: any, b: any) => a.boatName.length - b.boatName.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: ' Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: tableRecords, index: number) => <> {formateDate(record && record.createdAt)} </>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a: any, b: any) => a.status.length - b.status.length,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: tableRecords, index: number) => (
        <Switch
          checked={record.status}
          onChange={e => this.changeBoatStatus('status', record, e)}
          checkedChildren="Active"
          unCheckedChildren="Block"
        />
      ),
    },
    {
      title: 'Featured Status',
      dataIndex: 'featureStatus',
      key: 'featureStatus',
      render: (text: string, record: tableRecords, index: number) => (
        <ThreeToggleSwitch
          toggleMarketStatus={(e: any) => this.changeBoatStatus('featureStatus', record, e.target.value)}
          status={text}
        />
      ),
    },
    {
      title: 'Best Deal Status',
      dataIndex: 'bestDealStatus',
      key: 'bestDealStatus',
      render: (text: string, record: tableRecords, index: number) => (
        <ThreeToggleSwitch
          toggleMarketStatus={(e: any) => this.changeBoatStatus('bestDealStatus', record, e.target.value)}
          status={text}
        />
      ),
    },
    {
      title: 'Little Boat Status',
      dataIndex: 'mustBuyStatus',
      key: 'mustBuyStatus',
      render: (text: string, record: tableRecords, index: number) => (
        <ThreeToggleSwitch
          toggleMarketStatus={(e: any) => this.changeBoatStatus('mustBuyStatus', record, e.target.value)}
          status={text}
        />
      ),
    },
    {
      title: 'Approval Status',
      dataIndex: 'adStatus',
      key: 'adStatus',
      render: (text: string, record: tableRecords) => {
        const { changeBoatApproveStatus, boats } = this.props
        return (
          <ApproveButton
            action={changeBoatApproveStatus}
            input={{
              id: record.id,
              columnName: 'adStatus',
              value: !record.adStatus,
              fieldName: 'boats',
              fieldArray: boats,
            }}
            buttonTitle="Decline"
            className="btn-delete"
            tooltip={tooltipText.boat.declineButton}
          />
        )
      },
    },
    {
      title: 'View Ad',
      dataIndex: 'ViewAd',
      key: 'view',
      render: (text: string, record: tableRecords, index: number) => (
        <Button onClick={() => this.boatDetail(record)} className="btn-view">
          {'View'}
        </Button>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text: string, record: tableRecords, index: number) => (
        <WSToolTip title={tooltipText.boat.deleteButton}>
          <Button
            onClick={() => {
              confirmSubmitHandler(this.props.deleteBoats, {
                id: record.id,
                fieldName: 'boats',
                fieldArray: this.props.boats,
              })
            }}
            className="btn-delete"
          >
            {'Delete'}
          </Button>
        </WSToolTip>
      ),
    },
  ]

  render() {
    const { boatIsLoading, boats, getAllBoats, totalAdminRow } = this.props

    return (
      <Card title="All Boats" extra={<TitleSearch onSearch={this.onSearch} />}>
        <FilterButton filterArray={pageFilterArray} triggerAction={getAllBoats} haveCountry />
        <ExpandTable
          columns={this.columns}
          tableData={boats}
          expandHtml={false}
          isExpand={false}
          pageAction={getAllBoats}
          page={paginationPage.boat}
          totalUsersRow={totalAdminRow}
          tableLoading={boatIsLoading}
          extraInput={{ adStatus: true }}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    boatSuccess: state.pendingAdsState.boatSuccess,
    boats: state.pendingAdsState.boats,
    totalAdminRow: state.pendingAdsState.totalAdminRow,
    boatIsLoading: state.pendingAdsState.boatIsLoading,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllBoats: (data: any) => dispatch(getAllBoats(data)),
    clearBoatFlag: () => dispatch(clearBoatFlag()),
    changeBoatApproveStatus: (data: any) => dispatch(changeBoatApproveStatus(data)),
    deleteBoats: (data: string) => dispatch(deleteBoats(data)),
    searchBoats: (data: any) => dispatch(searchBoats(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllBoat)
