import {
  ADD_SITE_CONFIGURATION,
  GET_SITE_CONFIGURATION,
  ADD_SITE_CONFIGURATION_SUCCESS,
  UPDATE_SITE_CONFIGURATION_SUCCESS,
  UPDATE_SITE_CONFIGURATION,
  CLEAR_CONFIG_FLAG,
} from './Types'

export function addSiteConfiguration(data: any) {
  return {
    type: ADD_SITE_CONFIGURATION,
    payload: data,
  }
}

export function addSiteConfigurationSuccess(data: any) {
  return {
    type: ADD_SITE_CONFIGURATION_SUCCESS,
    payload: data,
  }
}

export function updateSiteConfiguration(data: any) {
  return {
    type: UPDATE_SITE_CONFIGURATION,
    payload: data,
  }
}

export function updateSiteConfigurationSuccess(data: any) {
  return {
    type: UPDATE_SITE_CONFIGURATION_SUCCESS,
    payload: data,
  }
}

export function getSiteConfiguration() {
  return {
    type: GET_SITE_CONFIGURATION,
  }
}

export function clearSiteConfigurationAction() {
  return {
    type: CLEAR_CONFIG_FLAG,
  }
}
