export interface salesEngineState {
  salesEngine: salesEngineData[]
  salesEnginSuccess: boolean
  salesEngineFailure: boolean
  deleteSalesEngineSuccess: boolean
  deleteSalesEngineFailure: boolean
  salesEnginStaticSuccess: boolean
  salesEngineStaticFailure: boolean
  salesEngineStaticContent: any[]
  createdSalesEngine: any[]
  createSalesEngineStaticContentSuccess: boolean
  createSalesEngineStaticContentFailure: boolean
  totalSalesEngineRow: number
  salesEnginPayment: SalesEnginPayment[]
  salesEnginPaymentTotalRow: number
  salesEnginCommision: SalesEnginCommision[]
  salesEnginCommisionTotalRow: number
  salesEnginRelease: SalesEnginRelease[]
  editSuccess: boolean
  editError: boolean,
  isReleaseMoneySuccess: boolean,
  isReleaseMoneyFailure: boolean,
  releasePaymentErrorMsg: string
}

export interface salesEngineData {
  id: string
  boat: {
    boatName: string
    boatStatus: {
      typeId: {
        type: string
      }
    }
  }
  buyer: {
    firstName: string
    lastName: string
    country: string
  }
  buySellProcess: [string]
  requestedSurveyor: string
  createdAt: string
}

export interface SalesEnginPayment {
  id: string
}
export interface SalesEnginCommision {
  id: string
}
export interface SalesEnginRelease {
  id: string
  paymentId: string
}

export const GET_SALES_ENGINE = 'GET_SALES_ENGINE'
export const GET_SALES_ENGINE_SUCCESS = 'GET_SALES_ENGINE_SUCCESS'
export const GET_SALES_ENGINE_FAILURE = 'GET_SALES_ENGINE_FAILURE'

export const GET_SALES_ENGINE_PAYMENT = 'GET_SALES_ENGINE_PAYMENT'
export const GET_SALES_ENGINE_PAYMENT_SUCCESS = 'GET_SALES_ENGINE_PAYMENT_SUCCESS'
export const GET_SALES_ENGINE_PAYMENT_FAILURE = 'GET_SALES_ENGINE_PAYMENT_FAILURE'

// Sales engine commision
export const GET_SALES_ENGINE_COMMISION = 'GET_SALES_ENGINE_COMMISION'
export const GET_SALES_ENGINE_COMMISION_SUCCESS = 'GET_SALES_ENGINE_COMMISION_SUCCESS'
export const GET_SALES_ENGINE_COMMISION_FAILURE = 'GET_SALES_ENGINE_COMMISION_FAILURE'

// Sales engine release
export const GET_SALES_ENGINE_RELEASE = 'GET_SALES_ENGINE_RELEASE'
export const GET_SALES_ENGINE_RELEASE_SUCCESS = 'GET_SALES_ENGINE_RELEASE_SUCCESS'
export const GET_SALES_ENGINE_RELEASE_FAILURE = 'GET_SALES_ENGINE_RELEASE_FAILURE'

export const RELEASE_PAYMENT = 'RELEASE_PAYMENT'
export const RELEASE_PAYMENT_SUCCESS = 'RELEASE_PAYMENT_SUCCESS'
export const RELEASE_PAYMENT_FAILURE = 'RELEASE_PAYMENT_FAILURE'

export const GET_SALES_ENGINE_STATIC_CONTENT = 'GET_SALES_ENGINE_STATIC_CONTENT'
export const GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS = 'GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS'
export const GET_SALES_ENGINE_STATIC_CONTENT_FAILURE = 'GET_SALES_ENGINE_STATIC_CONTENT_FAILURE'

export const CREATE_SALES_ENGINE_STATIC_CONTENT = 'CREATE_SALES_ENGINE_STATIC_CONTENT'
export const CREATE_SALES_ENGINE_STATIC_CONTENT_SUCCESS = 'CREATE_SALES_ENGINE_STATIC_CONTENT_SUCCESS'
export const CREATE_SALES_ENGINE_STATIC_CONTENT_FAILURE = 'CREATE_SALES_ENGINE_STATIC_CONTENT_FAILURE'

export const DELETE_SALES_ENGINE = 'DELETE_SALES_ENGINE'
export const DELETE_SALES_ENGINE_SUCCESS = 'DELETE_SALES_ENGINE_SUCCESS'
export const DELETE_SALES_ENGINE_FAILURE = 'DELETE_SALES_ENGINE_FAILURE'

export const EDIT_COMMISION = 'EDIT_COMMISION'
export const EDIT_COMMISION_SUCCESS = 'EDIT_COMMISION_SUCCESS'
export const EDIT_COMMISION_ERROR = 'EDIT_COMMISION_ERROR'

export const CLEAR_SALES_ENGINE_FLAG = 'CLEAR_SALES_ENGINE_FLAG';

export const REFUND_PAYMENT = 'REFUND_PAYMENT';
export const REFUND_PAYMENT_SUCCESS = 'REFUND_PAYMENT_SUCCESS';
export const REFUND_PAYMENT_FAILURE = 'REFUND_PAYMENT_FAILURE';

interface getManageSalesEngine {
  type: typeof GET_SALES_ENGINE
  payload: salesEngineData
}

interface getManageSalesEngineSuccess {
  type: typeof GET_SALES_ENGINE_SUCCESS
  payload: any
}

interface getManageSalesEngineFailure {
  type: typeof GET_SALES_ENGINE_FAILURE
  payload: any
}

export interface getSalesEnginPaymentInput {
  page?: number
  limit?: number
  page_type?: string
  searchTerm?: string
}

export interface getSalesEnginReleasePaymentInput {
  page?: number
  limit?: number
  sales_engine_id?: string
}

interface getAllSalesEnginPayment {
  type: typeof GET_SALES_ENGINE_PAYMENT
  payload: getSalesEnginPaymentInput
}

interface getAllSalesEnginPaymentSuccess {
  type: typeof GET_SALES_ENGINE_PAYMENT_SUCCESS
  payload: {
    items: SalesEnginPayment[]
    total: number
  }
}

interface getAllSalesEnginPaymentFailure {
  type: typeof GET_SALES_ENGINE_PAYMENT_FAILURE
  payload: any
}

// Interface for commision
interface getAllSalesEnginCommision {
  type: typeof GET_SALES_ENGINE_COMMISION
  payload: getSalesEnginPaymentInput
}

interface getAllSalesEnginCommisionSuccess {
  type: typeof GET_SALES_ENGINE_COMMISION_SUCCESS
  payload: {
    items: SalesEnginCommision[]
    total: number
  }
}

interface getAllSalesEnginCommisionFailure {
  type: typeof GET_SALES_ENGINE_COMMISION_FAILURE
  payload: any
}

// Interface for release
interface getAllSalesEnginRelease {
  type: typeof GET_SALES_ENGINE_RELEASE
  payload: getSalesEnginReleasePaymentInput
}

interface getAllSalesEnginReleaseSuccess {
  type: typeof GET_SALES_ENGINE_RELEASE_SUCCESS
  payload: {
    items: SalesEnginRelease[]
    total: number
  }
}

interface getAllSalesEnginReleaseFailure {
  type: typeof GET_SALES_ENGINE_RELEASE_FAILURE
  payload: any
}

interface getManageSalesEngineStaticContent {
  type: typeof GET_SALES_ENGINE_STATIC_CONTENT
}

interface getManageSalesEngineStaticContentSuccess {
  type: typeof GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS
  payload: any
}

interface getManageSalesEngineStaticContentFailure {
  type: typeof GET_SALES_ENGINE_STATIC_CONTENT_FAILURE
  payload: any
}

interface deleteSalesEngine {
  type: typeof DELETE_SALES_ENGINE
  payload: salesEngineData
}

interface deleteSalesEngineSuccess {
  type: typeof DELETE_SALES_ENGINE_SUCCESS
  payload: any
}
interface deleteSalesEngineFailure {
  type: typeof DELETE_SALES_ENGINE_FAILURE
  payload: any
}

interface createSalesEngineStaticContent {
  type: typeof CREATE_SALES_ENGINE_STATIC_CONTENT
  payload: salesEngineData
}

interface createSalesEngineStaticContentSuccess {
  type: typeof CREATE_SALES_ENGINE_STATIC_CONTENT_SUCCESS
  payload: any
}
interface createSalesEngineStaticContentFailure {
  type: typeof CREATE_SALES_ENGINE_STATIC_CONTENT_FAILURE
  payload: any
}

interface releasePayment {
  type: typeof RELEASE_PAYMENT
  payload: any
}

interface releasePaymentSuccess {
  type: typeof RELEASE_PAYMENT_SUCCESS
  payload: any
}
interface releasePaymentFailure {
  type: typeof RELEASE_PAYMENT_FAILURE
  payload: any
}

interface clearSalesEngineFlag {
  type: typeof CLEAR_SALES_ENGINE_FLAG
  payload: any
}

interface editCommision {
  type: typeof EDIT_COMMISION
  payload: any
}
interface editCommisionSuccess {
  type: typeof EDIT_COMMISION_SUCCESS
  payload: any
}
interface editCommisionError {
  type: typeof EDIT_COMMISION_ERROR
  payload: any
}

interface getRefundPaymentFuccess {
  type: typeof REFUND_PAYMENT_SUCCESS
  payload: any
  input: any
}

export type ActionType =
  | getManageSalesEngine
  | getManageSalesEngineSuccess
  | getManageSalesEngineFailure
  | getAllSalesEnginPayment
  | getAllSalesEnginPaymentSuccess
  | getAllSalesEnginPaymentFailure
  | getAllSalesEnginCommision
  | getAllSalesEnginCommisionSuccess
  | getAllSalesEnginCommisionFailure
  | getAllSalesEnginRelease
  | getAllSalesEnginReleaseSuccess
  | getAllSalesEnginReleaseFailure
  | deleteSalesEngine
  | deleteSalesEngineSuccess
  | deleteSalesEngineFailure
  | clearSalesEngineFlag
  | getManageSalesEngineStaticContent
  | getManageSalesEngineStaticContentSuccess
  | getManageSalesEngineStaticContentFailure
  | createSalesEngineStaticContent
  | createSalesEngineStaticContentSuccess
  | createSalesEngineStaticContentFailure
  | releasePayment
  | releasePaymentSuccess
  | releasePaymentFailure
  | editCommision
  | editCommisionSuccess
  | editCommisionError
  | getRefundPaymentFuccess
