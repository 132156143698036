export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS'
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE'

export interface permissionsState {
  success: boolean
  error: boolean
  isSuperAdmin: boolean
  role: string
  permissions: string[]
}

interface getPermissions {
  type: typeof GET_PERMISSIONS
}

interface getPermissionsSuccess {
  type: typeof GET_PERMISSIONS_SUCCESS
  payload: any
}

interface getPermissionsFailure {
  type: typeof GET_PERMISSIONS_FAILURE
}

export type ActionType =
  | getPermissions
  | getPermissionsSuccess
  | getPermissionsFailure
