import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button, Select, Radio } from 'antd'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import History from '../../../History'

import { createManageSmtp, updateManageSmtp, clearSmtpFlag } from '../../../store/staticContent/manageSMTP/Action'
import { smtpData } from '../../../store/staticContent/manageSMTP/Type'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import WSCheckbox from '../../../component/WSCheckbox'
import { SMTPTYPE, DOMAINTYPE } from '../../../enums/enums'
import { trimmedValue } from '../../../helpers/helper'
const { Option } = Select

const { Text } = Typography

interface Props {
  createManageSmtp: typeof createManageSmtp
  location: any
  updateManageSmtp: typeof updateManageSmtp
  history: typeof History
  smtpSuccess: boolean
  clearSmtpFlag: typeof clearSmtpFlag
}
interface State {
  modalVisible: boolean
  editSmtp: any
  manageSmtp: {
    provider: number
    ipAddress: string
    host: string
    pass: string
    email: string
    port: number
    userName: string
    type: number
  }
}

class CreateSMTP extends Component<Props, State> {
  formSubmitHandler = (values: any) => {
    const { editSmtp } = this.state
    const { createManageSmtp, updateManageSmtp } = this.props

    if (editSmtp) {
      const { user, __typename, ...noUser } = values

      updateManageSmtp({ ...noUser })
    } else {
      createManageSmtp(values)
    }
  }

  componentDidMount() {
    const { clearSmtpFlag } = this.props
    clearSmtpFlag()
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      modalVisible: false,
      editSmtp: props.location.state ? props.location.state.smtp : null,
      manageSmtp: {
        provider: 1,
        host: '',
        ipAddress: '',
        userName: '',
        email: '',
        port: 0,
        pass: '',
        type: 1,
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { smtpSuccess, clearSmtpFlag, history } = props

    if (smtpSuccess) {
      clearSmtpFlag()
      history.push('/manage-smtp')
    }
    return null
  }

  setModalVisibility = (data: any) => {
    this.setState({
      modalVisible: data,
    })
  }

  render() {
    const { manageSmtp, editSmtp, modalVisible } = this.state
    const { history } = this.props
    return (
      <Formik
        initialValues={editSmtp || manageSmtp}
        onSubmit={values => {
          const portIs = Number(values.port)
          const { port, type, provider, ...noPort } = values

          const input: any = {
            port: portIs,
            type: SMTPTYPE[values.type],
            provider: DOMAINTYPE[values.provider],
            ...noPort,
          }

          this.formSubmitHandler(input)
        }}
        validationSchema={Yup.object().shape({
          ipAddress: Yup.string().matches(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            'Enter correct ipAddress!'
          ),
          email: Yup.string().email().required('Email field is required.'),
          port: Yup.number(),
          userName: Yup.string(),
          pass: Yup.string().label('Password').min(2, 'Seems a bit short...'),
        })}
        render={({ setFieldValue, values, errors }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  {editSmtp ? 'Edit SMTP' : 'Create SMTP'}
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                    Save
                  </button>
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" lg={8} md={8} sm={16}>
                  <span className="ant-form-item-required" title="Type">
                    Email Provider
                  </span>
                  <Select
                    defaultValue={DOMAINTYPE[1]}
                    style={{ width: '100%' }}
                    onChange={(e: any) => setFieldValue('provider', e)}
                  >
                    <Option value={1}>AWS SES</Option>
                    <Option value={2}>Gmail</Option>
                  </Select>

                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`provider`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" lg={8} md={8} sm={16}>
                  <span className="ant-form-item-required" title="host">
                    Host Name
                  </span>
                  <Input
                    type="text"
                    name="subject"
                    placeholder="Host Name"
                    value={values.host}
                    onChange={e => setFieldValue('host', e.target.value)}
                    onBlur={e => setFieldValue('host', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`host`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" lg={8} md={8} sm={16}>
                  <span className="ant-form-item-required" title="ipAddress">
                    Server IP Address Address
                  </span>
                  <Input
                    name="subject"
                    placeholder="Host Name"
                    value={values.ipAddress}
                    onChange={e => setFieldValue('ipAddress', e.target.value)}
                    onBlur={e => setFieldValue('ipAddress', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`ipAddress`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" lg={8} md={8} sm={16}>
                  <span className="ant-form-item-required" title="Host">
                    Username
                  </span>
                  <Input
                    name="Username"
                    placeholder="Username"
                    value={values.userName}
                    onChange={e => setFieldValue('userName', e.target.value)}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`userName`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" lg={8} md={8} sm={16}>
                  <span className="ant-form-item-required" title="E mail">
                    Sender E-mail Address
                  </span>
                  <Input
                    name="email"
                    placeholder="E mail"
                    value={values.email}
                    onChange={e => setFieldValue('email', e.target.value)}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`email`} component="span" />
                  </Text>
                </Col>
                <Col className="gutter-box" lg={8} md={8} sm={16}>
                  <span className="ant-form-item-required" title="Port">
                    Port
                  </span>
                  <Input
                    type="number"
                    name="port"
                    placeholder="Port"
                    value={values.port}
                    onChange={e => setFieldValue('port', e.target.value)}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`port`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" lg={8} md={8} sm={16}>
                  <span className="ant-form-item-required" title="Password">
                    Password
                  </span>
                  <Input.Password
                    name="pass"
                    placeholder="Password"
                    value={values.pass}
                    onChange={e => setFieldValue('pass', e.target.value)}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`pass`} component="span" />
                  </Text>
                </Col>

                <Col className="gutter-box" lg={8} md={8} sm={16}>
                  <Radio.Group
                    style={{ marginTop: 20 }}
                    defaultValue={1}
                    onChange={e => setFieldValue('type', e.target.value)}
                    value={values.type}
                    name="type"
                  >
                    <Radio value={1}>None</Radio>
                    <Radio value={2}>Enabled SSL</Radio>
                    <Radio value={3}>TLS</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  smtpSuccess: state.smtpReducer && state.smtpReducer.smtpSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createManageSmtp: (data: smtpData) => dispatch(createManageSmtp(data)),
  updateManageSmtp: (data: any) => dispatch(updateManageSmtp(data)),
  clearSmtpFlag: () => dispatch(clearSmtpFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSMTP)
