import { put, takeLatest, all } from 'redux-saga/effects'
import { client, websiteClient } from '../../'
import {
  changeAuctionRoomStatus,
  getAllAuctionRoom,
  deleteAuctionRoom,
  getAuctionBids,
  getAuctionDepositorsById,
  getAuctionTransactionsById,
} from './Schema'
import {
  ActionType,
  CHANGE_AUCTION_ROOM_STATUS,
  CHANGE_AUCTION_ROOM_STATUS_SUCCESS,
  CHANGE_AUCTION_ROOM_STATUS_FAILURE,
  GET_ALL_AUCTION_ROOM,
  GET_ALL_AUCTION_ROOM_SUCCESS,
  getAuctionRoomInput,
  AuctionRoomStatusInput,
  GET_ALL_AUCTION_ROOM_FAILURE,
  DELETE_AUCTION_ROOM,
  DELETE_AUCTION_ROOM_SUCCESS,
  DELETE_AUCTION_ROOM_FAILURE,
  GET_ALL_AUCTION_ROOM_BIDS,
  GET_ALL_AUCTION_ROOM_BIDS_SUCCESS,
  GET_ALL_AUCTION_ROOM_BIDS_FAILURE,
  getAuctionRoomBidsInput,
  GET_ALL_AUCTION_ROOM_DEPOSITORS,
  GET_ALL_AUCTION_ROOM_DEPOSITORS_SUCCESS,
  GET_ALL_AUCTION_ROOM_DEPOSITORS_FAILURE,
  getAuctionRoomDepositInput,
  GET_ALL_AUCTION_ROOM_TRANSACTION,
  GET_ALL_AUCTION_ROOM_TRANSACTION_SUCCESS,
  GET_ALL_AUCTION_ROOM_TRANSACTION_FAILURE,
  getAuctionRoomTransactionInput,
} from './Types'

function getAuctionRoomApi(input: getAuctionRoomInput) {
  return client
    .query({
      query: getAllAuctionRoom,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function toggleAuctionRoomStatusApi(input: AuctionRoomStatusInput) {
  return client
    .mutate({
      mutation: changeAuctionRoomStatus,
      variables: input,
    })
}

function deleteAuctionRoomsApi(input: string) {
  return client
    .mutate({
      mutation: deleteAuctionRoom,
      variables: input,
    })
}

function getAuctionRoomBidsApi(input: getAuctionRoomBidsInput) {
  return client
    .query({
      query: getAuctionBids,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function getAuctionRoomDepositorsApi(input: getAuctionRoomDepositInput) {
  return client
    .query({
      query: getAuctionDepositorsById,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function getAuctionRoomTransactionsApi(input: getAuctionRoomTransactionInput) {
  return websiteClient
    .query({
      query: getAuctionTransactionsById,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function* getAuctionRoom(action: ActionType) {
  try {
    const res = yield getAuctionRoomApi(action.payload)
    yield put({
      type: GET_ALL_AUCTION_ROOM_SUCCESS,
      payload: res.data.getAllAuctionRoom,
    })
  } catch (error) {
    yield put({ type: GET_ALL_AUCTION_ROOM_FAILURE, error })
  }
}

function* toggleAuctionRoomStatus(action: ActionType) {
  try {
    const res = yield toggleAuctionRoomStatusApi(action.payload)
    yield put({
      type: CHANGE_AUCTION_ROOM_STATUS_SUCCESS,
      payload: res.data.changeAuctionRoomStatus,
    })
  } catch (error) {
    yield put({ type: CHANGE_AUCTION_ROOM_STATUS_FAILURE, error })
  }
}

function* deleteAuctionRooms(action: ActionType) {
  try {
    const res = yield deleteAuctionRoomsApi(action.payload)
    yield put({
      type: DELETE_AUCTION_ROOM_SUCCESS,
      payload: res.data.deleteAuctionRoom,
    })
  } catch (error) {
    yield put({ type: DELETE_AUCTION_ROOM_FAILURE, error })
  }
}

function* getAuctionRoomBids(action: ActionType) {
  try {
    const res = yield getAuctionRoomBidsApi(action.payload)
    yield put({
      type: GET_ALL_AUCTION_ROOM_BIDS_SUCCESS,
      payload: res.data.getAuctionBids,
    })
  } catch (error) {
    yield put({ type: GET_ALL_AUCTION_ROOM_BIDS_FAILURE, error })
  }
}

function* getAuctionRoomDepositorsData(action: ActionType) {
  try {
    const res = yield getAuctionRoomDepositorsApi(action.payload)
    yield put({
      type: GET_ALL_AUCTION_ROOM_DEPOSITORS_SUCCESS,
      payload: res.data.getAuctionDepositorsById,
    })
  } catch (error) {
    yield put({ type: GET_ALL_AUCTION_ROOM_DEPOSITORS_FAILURE, error })
  }
}

function* getAuctionRoomSaga() {
  yield takeLatest(GET_ALL_AUCTION_ROOM, getAuctionRoom)
}

function* toggleAuctionRoomStatusSaga() {
  yield takeLatest(CHANGE_AUCTION_ROOM_STATUS, toggleAuctionRoomStatus)
}

function* deleteAuctionRoomsSaga() {
  yield takeLatest(DELETE_AUCTION_ROOM, deleteAuctionRooms)
}

function* getAuctionRoomBidsSaga() {
  yield takeLatest(GET_ALL_AUCTION_ROOM_BIDS, getAuctionRoomBids)
}

function* getAuctionRoomTransactionsData(action: ActionType) {
  try {
    const res = yield getAuctionRoomTransactionsApi(action.payload)
    yield put({
      type: GET_ALL_AUCTION_ROOM_TRANSACTION_SUCCESS,
      payload: res.data.getTransactions,
    })
  } catch (error) {
    yield put({ type: GET_ALL_AUCTION_ROOM_TRANSACTION_FAILURE, error })
  }
}

function* getAuctionRoomDepositorsSaga() {
  yield takeLatest(GET_ALL_AUCTION_ROOM_DEPOSITORS, getAuctionRoomDepositorsData)
}

function* getAuctionRoomTransactionsSaga() {
  yield takeLatest(GET_ALL_AUCTION_ROOM_TRANSACTION, getAuctionRoomTransactionsData)
}

export default function* auctionRoomSaga() {
  yield all([
    getAuctionRoomSaga(),
    toggleAuctionRoomStatusSaga(),
    deleteAuctionRoomsSaga(),
    getAuctionRoomBidsSaga(),
    getAuctionRoomDepositorsSaga(),
    getAuctionRoomTransactionsSaga(),
  ])
}
