import NotificationWithIcon from '../../component/notification/Notification'
import {
  GET_SEO_TAGS,
  GET_SEO_TAGS_FAILURE,
  GET_SEO_TAGS_SUCCESS,
  DELETE_SEO_TAG,
  DELETE_SEO_TAG_SUCCESS,
  DELETE_SEO_TAG_FAILURE,
  CREATE_SEO_TAG,
  CREATE_SEO_TAG_FAILURE,
  CREATE_SEO_TAG_SUCCESS,
  UPDATE_SEO_TAG,
  UPDATE_SEO_TAG_FAILURE,
  UPDATE_SEO_TAG_SUCCESS,
  SEARCH_SEO_TAG,
  SEARCH_SEO_TAG_FAILURE,
  SEARCH_SEO_TAG_SUCCESS,
  CLEAR_FLAGS,
  ActionTypes,
  InitialStateType,
} from './Types'

const initFlags = {
  fetchError: null,
  fetchLoading: false,
  fetchSuccess: false,

  deleteError: null,
  deleteLoading: false,
  deleteSuccess: false,

  createError: null,
  createLoading: false,
  createSuccess: false,

  updateError: null,
  updateLoading: false,
  updateSuccess: false,

  searchError: null,
  searchLoading: false,
  searchSuccess: false,
}

const initialState: InitialStateType = {
  metaTags: {
    items: [],
    total: 0,
  },
  ...initFlags,
}

export function seoReducer(state = initialState, action: ActionTypes): InitialStateType {
  switch (action.type) {
    // clear all flags
    case CLEAR_FLAGS:
      return {
        ...state,
        ...initFlags,
      }
    // get all
    case GET_SEO_TAGS:
      return {
        ...state,
        fetchError: false,
        fetchSuccess: false,
        fetchLoading: true,
      }

    case GET_SEO_TAGS_FAILURE:
      return {
        ...state,
        fetchSuccess: false,
        fetchLoading: false,
        fetchError: true,
      }

    case GET_SEO_TAGS_SUCCESS:
      return {
        ...state,
        fetchError: false,
        fetchLoading: false,
        fetchSuccess: true,
        metaTags: action.payload,
      }

    // delete
    case DELETE_SEO_TAG:
      return {
        ...state,
        deleteError: false,
        deleteSuccess: false,
        deleteLoading: true,
      }

    case DELETE_SEO_TAG_FAILURE:
      return {
        ...state,
        deleteError: true,
        deleteLoading: false,
        deleteSuccess: false,
      }

    case DELETE_SEO_TAG_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteLoading: false,
        deleteError: false,
        metaTags: {
          ...state.metaTags,
          items: state.metaTags.items.filter(item => item.id !== action.payload),
        },
      }
    // create
    case CREATE_SEO_TAG:
      return {
        ...state,
        createError: false,
        createSuccess: false,
        createLoading: true,
      }

    case CREATE_SEO_TAG_FAILURE:
      return {
        ...state,
        createError: true,
        createLoading: false,
        createSuccess: false,
      }

    case CREATE_SEO_TAG_SUCCESS:
      NotificationWithIcon('success', 'Record Created Successfully')
      return {
        ...state,
        createSuccess: true,
        createLoading: false,
        createError: true,
      }

    // update
    case UPDATE_SEO_TAG:
      return {
        ...state,
        updateError: false,
        updateSuccess: false,
        updateLoading: true,
      }

    case UPDATE_SEO_TAG_FAILURE:
      return {
        ...state,
        updateError: true,
        updateLoading: false,
        updateSuccess: false,
      }

    case UPDATE_SEO_TAG_SUCCESS:
      NotificationWithIcon('success', 'Record Updated Successfully')
      return {
        ...state,
        updateSuccess: true,
        updateLoading: false,
        updateError: false,
      }
    
    // search
    case SEARCH_SEO_TAG:
      return {
        ...state,
        searchError: false,
        searchSuccess: false,
        searchLoading: true,
      }

    case SEARCH_SEO_TAG_FAILURE:
      return {
        ...state,
        searchSuccess: false,
        searchLoading: false,
        searchError: true,
      }

    case SEARCH_SEO_TAG_SUCCESS:
      NotificationWithIcon('success', 'Filtered records Successfully')
      return {
        ...state,
        searchError: false,
        searchLoading: false,
        searchSuccess: true,
        metaTags: action.payload,
      }

    default:
      return state
  }
}
