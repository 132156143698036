import React, { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { clearRentPaymentsBoatSlotsFlag, getRentPaymentsBoatSlots } from '../../store/pendingAds/Action'
import ExpandTable from '../../component/ExpandTable'
import { Button, Card, Tag } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import { classNames, formatPriceinUSD, getSearchedData, Sort } from '../../helpers/helper'
import moment from 'moment'
import { RentBookingStatus, RentTripStatusFilterArray, RentTripStatusLabels, StatusColors } from '../../enums/enums'
import FilterButton from '../filters/FilterButton'

const RentPaymentsBoatSlots = ({
  history,
  location: {
    pathname,
    search,
    state: { receiver, boat, timeStatus = 'upcoming' },
  },
  getSlots,
  slots,
  clearSlotsFlag,
}: any) => {
  const [tableData, setTableData] = useState([] as any[])
  const [pagination, setPagination] = useState({ page: 1, limit: 10 })
  const [searchedValue, setSearchedValue] = useState('')
  const [timeStatusFilteredSlots, setTimeStatusFilteredSlots] = useState([] as any[])

  useEffect(() => {
    getSlots({ boatId: boat.id })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (slots.success || slots.failure) clearSlotsFlag()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slots])

  useEffect(() => {
    const filters =
      timeStatus === 'upcoming'
        ? [null, RentBookingStatus.Pending, RentBookingStatus.Started, RentBookingStatus.Ongoing]
        : [RentBookingStatus.Expired, RentBookingStatus.Settled]

    const filtered = slots.data?.filter((s: any) => filters.includes(s.status)) || []

    setTimeStatusFilteredSlots(filtered)
    setTableData(filtered)
  }, [slots, timeStatus])

  const getPaginated = (data: any[]) => {
    return data.slice((pagination.page - 1) * pagination.limit, pagination.page * pagination.limit)
  }

  const viewBookings = (slotId: string, data: any) => {
    history.push({
      pathname: '/view-rent-payments-boat-slot-bookings/' + slotId,
      state: { receiver, boat, slot: data, timeStatus },
    })
  }

  const filterSlots = ({ status }: any) => {
    if (status) {
      setTableData(timeStatusFilteredSlots.filter((s: any) => (status === 'Cancelled' ? s.isCancelled : s.status === status)))
    } else {
      setTableData(timeStatusFilteredSlots)
    }
  }

  const tableCols = () => [
    {
      title: 'Trip Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (v: string) => moment.utc(v).format('MMM DD, YYYY') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.DateOnly(moment.utc(a.startDate), moment.utc(b.startDate)),
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (v: string) => moment.utc(v).format('hh:mm A') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.TimeOnly(moment.utc(a.startTime), moment.utc(b.startTime)),
    },
    {
      title: 'Trip End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (v: string) => moment.utc(v).format('MMM DD, YYYY') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.DateOnly(moment.utc(a.endDate), moment.utc(b.endDate)),
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (v: string) => moment.utc(v).format('hh:mm A') || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.TimeOnly(moment.utc(a.endTime), moment.utc(b.endTime)),
    },
    {
      title: 'Price per ' + (/shared/i.test(boat.trip) ? 'Person' : 'Boat'),
      dataIndex: 'price',
      key: 'price',
      render: (v: string) => formatPriceinUSD(v) || <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.price, b.price),
    },
    {
      title: 'Total Bookings',
      dataIndex: 'totalBookings',
      key: 'totalBookings',
      render: (v: string) => v ?? <>&#8212;</>,
      sorter: (a: any, b: any) => Sort.StrOrNum(a.totalBookings, b.totalBookings),
    },
    {
      title: 'Trip Status',
      dataIndex: 'status',
      key: 'status',
      render: (v: keyof typeof RentTripStatusLabels, row: any) =>
        row.totalBookings ? (
          <Tag color={StatusColors[v]}>{RentTripStatusLabels[v]}</Tag>
        ) : (
          <Tag color="#212121">No Booking</Tag>
        ),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.status, b.status),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.totalAmount, b.totalAmount),
    },
    {
      title: 'Service Fees',
      dataIndex: 'serviceFees',
      key: 'serviceFees',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.serviceFees, b.serviceFees),
    },
    {
      title: 'Stripe Fees',
      dataIndex: 'stripeFees',
      key: 'stripeFees',
      render: (a: any) => formatPriceinUSD(a),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.stripeFees, b.stripeFees),
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      key: 'commission',
      render: (v: number) => formatPriceinUSD(v),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.commission, b.commission),
    },
    {
      title: `Amount to Release`,
      dataIndex: 'amountToRelease',
      key: 'amountToRelease',
      render: (v: number) => formatPriceinUSD(v),
      sorter: (a: any, b: any) => Sort.StrOrNum(a.amountToRelease, b.amountToRelease),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (v: string, row: any) =>
        row.totalBookings ? (
          <Button className={row.amountToRelease ? 'btn-danger' : 'btn-view'} onClick={() => viewBookings(v, row)}>
            View Bookings
          </Button>
        ) : (
          <Button className="btn-black" disabled>
            No Booking
          </Button>
        ),
      sorter: (a: any, b: any) => Sort.Boolean(a.amountToRelease, b.amountToRelease),
    },
  ]

  const statusFilterArray = [
    {
      title: 'Trip Status',
      filedArray: RentTripStatusFilterArray,
      listOptions: 'label',
      passed: 'value',
      actionField: 'status',
    },
  ]

  return (
    <>
      <Card title="Rent & Charter Details" extra={<Button onClick={() => history.goBack()}>Back</Button>} className="mb-10">
        {boat && (
          <>
            <h3>
              <strong>Rent & Charter Name -</strong> {receiver.name}
            </h3>
            <h3>
              <strong>Company Name -</strong> {receiver.companyName}
            </h3>
            <h3>
              <strong>Stripe Account Id -</strong> {receiver.stripeAccountId || 'N/A'}
            </h3>
            <h3>
              <strong>Ad Id -</strong> {boat.adId || 'N/A'}
            </h3>
            <h3>
              <strong>Trip -</strong> {boat.trip || 'N/A'}
            </h3>
            <h3>
              <strong>Trip Type -</strong> {boat.tripType || 'N/A'}
            </h3>
          </>
        )}
      </Card>

      <Card
        title="View Boats"
        extra={
          <div className="d-flex align-items-center">
            <TitleSearch onSearch={setSearchedValue} />
            <FilterButton filterArray={statusFilterArray} triggerAction={filterSlots} hideDivider />
          </div>
        }
      >
        <div className="mt-20 mb-20 text-center">
          <Button
            className={classNames('btn-clickable', timeStatus === 'upcoming' && 'active')}
            onClick={() => history.replace({ pathname, search, state: { receiver, boat, timeStatus: 'upcoming' } })}
          >
            Upcoming Trips
          </Button>
          <Button
            className={classNames('btn-clickable', timeStatus === 'past' && 'active')}
            onClick={() => history.replace({ pathname, search, state: { receiver, boat, timeStatus: 'past' } })}
          >
            Past Trips
          </Button>
        </div>

        {(() => {
          const data = getSearchedData(tableData, searchedValue)

          return (
            <ExpandTable
              columns={tableCols()}
              tableData={getPaginated(data)}
              expandHtml={false}
              isExpand={false}
              totalUsersRow={data.length}
              tableLoading={slots.loading}
              pageAction={setPagination}
            />
          )
        })()}
      </Card>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    salesEnginCommision: state.salesEngineReducer.salesEnginCommision,
    slots: state.pendingAdsState.rentPaymentsBoatSlots,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getSlots: (data: any) => dispatch(getRentPaymentsBoatSlots(data)),
  clearSlotsFlag: () => dispatch(clearRentPaymentsBoatSlotsFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RentPaymentsBoatSlots)
