import {
  ActionType,
  jobState,
  GET_ALL_JOBS,
  GET_ALL_JOBS_SUCCESS,
  GET_ALL_JOBS_FAILURE,
  CREATE_JOBS,
  CREATE_JOBS_SUCCESS,
  CREATE_JOBS_FAILURE,
  UPDATE_JOBS,
  UPDATE_JOBS_SUCCESS,
  UPDATE_JOBS_FAILURE,
  DELETE_JOBS_SUCCESS,
  DELETE_JOBS_FAILURE,
  CLEAR_JOBS_FLAG,
  CHANGE_JOB_STATUS,
  CHANGE_JOB_STATUS_SUCCESS,
  CHANGE_JOB_STATUS_FAILURE,
  jobData,
  GET_JOBS_CONTENT,
  GET_JOBS_CONTENT_SUCCESS,
  GET_JOBS_CONTENT_FAILURE,
  CREATE_OR_UPDATE_JOB_CONTENT_SUCCESS,
  CREATE_OR_UPDATE_JOB_CONTENT_FAILURE,
  CREATE_OR_UPDATE_JOB_CONTENT,
  GET_JOBS_APPLICATIONS,
  GET_JOBS_APPLICATIONS_SUCCESS,
  GET_JOBS_APPLICATIONS_FAILURE,
} from './Type'
import NotificationWithIcon from '../../../component/notification/Notification'

const initialState: jobState = {
  job: [],
  jobSuccess: false,
  jobError: false,
  deleteSuccess: false,
  deleteError: false,
  jobTotalRow: 0,
  statusChangeSuccess: false,
  statusChangeFailure: false,
  jobsContent: { description: '' },
  jobsApplication: [{ id: '' }],
  totalApplications: 0,
}

export const jobReducer = (state = initialState, action: ActionType): jobState => {
  switch (action.type) {
    case GET_ALL_JOBS:
      return {
        ...state,
        jobSuccess: false,
        jobError: false,
      }
    case GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        jobSuccess: true,
        jobError: false,
        job: action.payload.items,
        jobTotalRow: action.payload.total,
      }
    case GET_ALL_JOBS_FAILURE:
      return {
        ...state,
        jobSuccess: false,
        jobError: true,
      }

    case CREATE_JOBS:
      return {
        ...state,
        jobSuccess: false,
        jobError: false,
      }
    case CREATE_JOBS_SUCCESS:
      NotificationWithIcon('success', 'Job created Successfully')
      return {
        ...state,
        jobSuccess: true,
        jobError: false,
      }
    case CREATE_JOBS_FAILURE:
      return {
        ...state,
        jobSuccess: false,
        jobError: true,
      }

    case UPDATE_JOBS:
      return {
        ...state,
        jobSuccess: false,
        jobError: false,
      }
    case UPDATE_JOBS_SUCCESS:
      NotificationWithIcon('success', 'Job Updated Successfully')
      return {
        ...state,
        jobSuccess: true,
        jobError: false,
      }
    case UPDATE_JOBS_FAILURE:
      return {
        ...state,
        jobSuccess: false,
        jobError: true,
      }

    case DELETE_JOBS_SUCCESS:
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteSuccess: true,
        deleteError: false,
      }
    case DELETE_JOBS_FAILURE:
      return {
        ...state,
        deleteSuccess: false,
        deleteError: true,
      }

    case CLEAR_JOBS_FLAG:
      return {
        ...state,
        jobSuccess: false,
        jobError: false,
        deleteSuccess: false,
        deleteError: false,
        statusChangeFailure: false,
      }

    case CHANGE_JOB_STATUS:
      return {
        ...state,
      }
    case CHANGE_JOB_STATUS_SUCCESS:
      const { job } = state
      const updatedJob: any = job.map((item: any) => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })

      return {
        ...state,
        statusChangeSuccess: true,
        job: updatedJob,
      }

    case CHANGE_JOB_STATUS_FAILURE:
      return {
        ...state,
        statusChangeFailure: true,
      }

    case GET_JOBS_CONTENT:
      return {
        ...state,
      }

    case GET_JOBS_CONTENT_SUCCESS:
      return {
        ...state,
        jobsContent: action.payload,
      }

    case GET_JOBS_CONTENT_FAILURE:
      return {
        ...state,
      }

    case CREATE_OR_UPDATE_JOB_CONTENT:
      return {
        ...state,
      }

    case CREATE_OR_UPDATE_JOB_CONTENT_SUCCESS:
      NotificationWithIcon('success', 'Updated successfully.')
      return {
        ...state,
        jobsContent: action.payload,
      }

    case CREATE_OR_UPDATE_JOB_CONTENT_FAILURE:
      return {
        ...state,
      }

    case GET_JOBS_APPLICATIONS:
      return {
        ...state,
        jobsApplication: [],
      }

    case GET_JOBS_APPLICATIONS_SUCCESS:
      return {
        ...state,
        jobsApplication: action.payload.items,
        totalApplications: action.payload.total,
      }

    case GET_JOBS_APPLICATIONS_FAILURE:
      return {
        ...state,
        jobsApplication: [],
      }

    default:
      return state
  }
}
