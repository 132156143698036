import React, { Component, GetDerivedStateFromProps } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'
import { Link } from 'react-router-dom'
import moment from 'moment'

import ExpandTable, { tableRecords } from '../../../component/ExpandTable'
import { getEmailTemplates, deleteTemplate, clearFlag } from '../../../store/staticContent/emailTemplates/Actions'
import History from '../../../History'
import { confirmSubmitHandler } from '../../../component/ConfirmSubmitHandler'
import { TitleSearch } from '../../../component/SearchBar'
import { getSearchedData, formateDate } from '../../../helpers/helper'
import { getAllBoatPagination, paginationPage, TEMPLATENAME } from '../../../enums/enums'
import WSIcon from '../../../component/WSIcon'
import { PlusOutlined } from '@ant-design/icons'

export interface Props {
  getEmailTemplates: typeof getEmailTemplates
  templates: []
  history: typeof History
  deleteTemplate: typeof deleteTemplate
  clearFlag: typeof clearFlag
  emailSuccess: boolean
  deleteSuccess: boolean
  totalTemplatesRow: number
}

export interface State {
  searchedValue: String
}

class EmailTemplates extends Component<Props, State> {
  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { emailSuccess, clearFlag, deleteSuccess } = props
    if (emailSuccess) {
      clearFlag()
    }
    if (deleteSuccess) {
      clearFlag()
    }
    return null
  }

  // componentDidMount() {
  //   const { getEmailTemplates } = this.props
  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //   }
  //   getEmailTemplates(input)
  // }

  editTemplates = (record: tableRecords) => {
    const { history } = this.props
    history.push('/create-email-template', { template: record })
  }

  state = {
    searchedValue: '',
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: any) => <span>{TEMPLATENAME[text]}</span>,
      },
      {
        title: 'SMTP',
        dataIndex: 'smtp',
        key: 'smtp',
        render: (text: any) => <span>{text && text.email}</span>,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
      },
      {
        title: 'Modified',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text: string, record: tableRecords, index: number) => <>{formateDate(record && record.updatedAt)}</>,
      },
      {
        title: 'Action',
        dataIndex: 'name',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => {
          const { deleteTemplate } = this.props
          return (
            <>
              <Button
                onClick={() => {
                  this.editTemplates(record)
                }}
                className="btn-edit mr-2"
              >
                Edit
              </Button>
              <Button
                className="btn-delete"
                onClick={() => {
                  record && record.id && confirmSubmitHandler(deleteTemplate, record.id)
                }}
              >
                Delete
              </Button>
            </>
          )
        },
      },
    ],
  }

  onSearch = (value: any) => {
    const { getEmailTemplates } = this.props
    if (value) {
      this.setState({searchedValue: value})
      getEmailTemplates({ searchTerm: value, templateType:2, page: 1, limit: 10 })
    } else {
      this.setState({searchedValue: ''})
      const input = {
        page: getAllBoatPagination.page,
        limit: getAllBoatPagination.limit,
      }
      getEmailTemplates(input)
    }
  }

  render() {
    const { columns, searchedValue } = this.state
    const { templates, getEmailTemplates, totalTemplatesRow } = this.props

    return (
      <Card
        title="Email Templates"
        extra={
          <div className="d-flex flex-wrap">
            <div className="mr-10 mt-8">
              <TitleSearch onSearch={this.onSearch} />
            </div>
            <Button className="btn-add mt-8">
              <Link to="/create-email-template">
                <WSIcon type={<PlusOutlined />} /> Add Template
              </Link>
            </Button>
          </div>
        }
      >
        <ExpandTable
          columns={columns}
          tableData={templates}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={totalTemplatesRow}
          pageAction={getEmailTemplates}
          page={paginationPage.boat}
          extraInput={{ searchTerm: searchedValue }}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  templates: state.emailTemplateReducer.templates,
  totalTemplatesRow: state.emailTemplateReducer.totalTemplatesRow,
  emailSuccess: state.emailTemplateReducer && state.emailTemplateReducer.emailSuccess,
  deleteSuccess: state.emailTemplateReducer && state.emailTemplateReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getEmailTemplates: (data: any) => dispatch(getEmailTemplates(data)),
  deleteTemplate: (data: string) => dispatch(deleteTemplate(data)),
  clearFlag: () => dispatch(clearFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates)
