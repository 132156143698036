import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getAllBranches, deleteBranch, changeBranchStatus, getBranchesBySurveyor } from './Schema'
import {
  GET_ALL_SURVEYORS_FAILURE,
  GET_ALL_SURVEYORS_SUCCESS,
  GET_ALL_SURVEYORS,
  DELETE_SURVEYORS_SUCCESS,
  ActionType,
  DELETE_SURVEYORS_FAILURE,
  DELETE_SURVEYORS,
  CHANGE_SURVEYOR_STATUS_SUCCESS,
  CHANGE_SURVEYOR_STATUS_FAILURE,
  CHANGE_SURVEYOR_STATUS,
  GET_ALL_SURVEYOR_BRANCHES,
  GET_ALL_SURVEYOR_BRANCHES_SUCCESS,
  GET_ALL_SURVEYOR_BRANCHES_FAILURE,
} from './Type'

function getAllSurveyorApi(data: any) {
  return client
    .query({
      query: getAllBranches,
      variables: data,
      fetchPolicy: 'no-cache',
    })
}

function* getAllSurveyorData(action: any) {
  try {
    const res = yield getAllSurveyorApi(action.payload)
    yield put({
      type: GET_ALL_SURVEYORS_SUCCESS,
      payload: res.data.getAllBranches,
    })
  } catch (error) {
    yield put({ type: GET_ALL_SURVEYORS_FAILURE, error })
  }
}

function* getAllSurveyorSaga() {
  yield takeLatest(GET_ALL_SURVEYORS, getAllSurveyorData)
}
function deleteSurveyorApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteBranch,
      variables: { id },
    })
}

function* deleteSurveyorData(action: ActionType) {
  try {
    const res = yield deleteSurveyorApi(action)
    yield put({
      type: DELETE_SURVEYORS_SUCCESS,
      payload: res.data.deleteBranch,
    })
  } catch (error) {
    yield put({ type: DELETE_SURVEYORS_FAILURE, error })
  }
}

function* deleteSurveyorSaga() {
  yield takeLatest(DELETE_SURVEYORS, deleteSurveyorData)
}

function changeSurveyorStatusApi(data: any) {
  return client
    .mutate({
      mutation: changeBranchStatus,
      variables: data,
    })
}

function* changeSurveyorStatusData(action: ActionType) {
  try {
    const data = yield changeSurveyorStatusApi(action.payload)
    yield put({ type: CHANGE_SURVEYOR_STATUS_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: CHANGE_SURVEYOR_STATUS_FAILURE, e })
  }
}

function* changeSurveyorStatusSaga() {
  yield takeLatest(CHANGE_SURVEYOR_STATUS, changeSurveyorStatusData)
}

function getUserBranchesApi(data: any) {
  return client
    .mutate({
      mutation: getBranchesBySurveyor,
      variables: data,
    })
}

function* getUserBranchesData(action: ActionType) {
  try {
    const data = yield getUserBranchesApi(action.payload)
    yield put({ type: GET_ALL_SURVEYOR_BRANCHES_SUCCESS, payload: data })
  } catch (e) {
    yield put({ type: GET_ALL_SURVEYOR_BRANCHES_FAILURE, e })
  }
}

function* getUserBranchesSaga() {
  yield takeLatest(GET_ALL_SURVEYOR_BRANCHES, getUserBranchesData)
}

export default function* allBranchesSaga() {
  yield all([getAllSurveyorSaga(), deleteSurveyorSaga(), changeSurveyorStatusSaga(), getUserBranchesSaga()])
}
