export interface auctionRoomCountState {
  auctionRoomCount: []
  auctionRoomCountSuccess: boolean
  auctionRoomCountFailure: boolean
}

export interface auctionRoomData {
  auctionRoom: string
  total: number
}

export const GET_AUCTION_ROOM_COUNT = 'GET_AUCTION_ROOM_COUNT'
export const GET_AUCTION_ROOM_COUNT_SUCCESS = 'GET_AUCTION_ROOM_COUNT_SUCCESS'
export const GET_AUCTION_ROOM_COUNT_FAILURE = 'GET_AUCTION_ROOM_COUNT_FAILURE'

interface getAuctionRoomCount {
  type: typeof GET_AUCTION_ROOM_COUNT
  payload: any
}

interface getAuctionRoomCountSuccess {
  type: typeof GET_AUCTION_ROOM_COUNT_SUCCESS
  payload: any
}

interface getAuctionRoomCountFailure {
  type: typeof GET_AUCTION_ROOM_COUNT_FAILURE
  payload: any
}

export type ActionType = getAuctionRoomCount | getAuctionRoomCountSuccess | getAuctionRoomCountFailure
