import {
  ActionType,
  helpState,
  GET_SALES_ENGINE_HELP_CONTENTS,
  GET_SALES_ENGINE_HELP_CONTENTS_SUCCESS,
  GET_SALES_ENGINE_HELP_CONTENTS_FAILURE,
  CLEAR_SALES_ENGINE_HELP_CONTENTS_FLAG,
  CREATE_SALES_ENGINE_HELP_CONTENTS,
  CREATE_SALES_ENGINE_HELP_CONTENTS_SUCCESS,
  CREATE_SALES_ENGINE_HELP_CONTENTS_FAILURE,
} from './Types'
import NotificationWithIcon from '../../component/notification/Notification'

const initialState: helpState = {
  success: false,
  error: false,
  createSuccess: false,
  createError: false,
  salesEngineHelpContent: [
    {
      content: '',
      createdAt: '',
      id: '',
      member: '',
    },
  ],
}

export function salesEngineHelpContentReducer(state = initialState, action: ActionType): helpState {
  switch (action.type) {
    case GET_SALES_ENGINE_HELP_CONTENTS:
      return {
        ...state,
        success: false,
        error: false,
      }
    case GET_SALES_ENGINE_HELP_CONTENTS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        salesEngineHelpContent: action.payload,
      }
    case GET_SALES_ENGINE_HELP_CONTENTS_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
      }
    case CREATE_SALES_ENGINE_HELP_CONTENTS:
      return {
        ...state,
        createSuccess: false,
        createError: false,
      }
    case CREATE_SALES_ENGINE_HELP_CONTENTS_SUCCESS:
      NotificationWithIcon('success', 'Created Successfully')
      return {
        ...state,
        createSuccess: true,
        createError: false,
      }
    case CREATE_SALES_ENGINE_HELP_CONTENTS_FAILURE:
      return {
        ...state,
        createSuccess: false,
        createError: true,
      }
    case CLEAR_SALES_ENGINE_HELP_CONTENTS_FLAG:
      return {
        ...state,
        success: false,
        error: false,
        createSuccess: false,
        createError: false,
      }
    default:
      return state
  }
}
