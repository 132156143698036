import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../'
import { createManageSiteConfiguration, getAllManageSiteConfiguration, updateSiteConfigurationG } from './Schema'
import {
  ActionType,
  ADD_SITE_CONFIGURATION_SUCCESS,
  ADD_SITE_CONFIGURATION_FAILURE,
  GET_SITE_CONFIGURATION_SUCCESS,
  GET_SITE_CONFIGURATION_FAILURE,
  ADD_SITE_CONFIGURATION,
  GET_SITE_CONFIGURATION,
  UPDATE_SITE_CONFIGURATION_SUCCESS,
  UPDATE_SITE_CONFIGURATION,
  UPDATE_SITE_CONFIGURATION_FAILURE,
} from './Types'

function addSiteConfigurationApi(input: any) {
  return client
    .mutate({
      mutation: createManageSiteConfiguration,
      variables: { input },
    })
}

function updateSiteConfigurationApi(input: any) {
  return client
    .mutate({
      mutation: updateSiteConfigurationG,
      variables: { input },
    })
}

function getSiteConfigurationApi() {
  return client
    .query({
      query: getAllManageSiteConfiguration,
    })
}

function* addSiteConfiguration(action: ActionType) {
  try {
    const res = yield addSiteConfigurationApi(action.payload)
    yield put({ type: ADD_SITE_CONFIGURATION_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: ADD_SITE_CONFIGURATION_FAILURE, error })
  }
}

function* updateSiteConfiguration(action: ActionType) {
  try {
    const res = yield updateSiteConfigurationApi(action.payload)
    yield put({ type: UPDATE_SITE_CONFIGURATION_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: UPDATE_SITE_CONFIGURATION_FAILURE, error })
  }
}

function* getSiteConfiguration() {
  try {
    const res = yield getSiteConfigurationApi()
    console.log(res, 'res')

    yield put({
      type: GET_SITE_CONFIGURATION_SUCCESS,
      payload: res.data.getSiteConfigurations,
    })
  } catch (error) {
    yield put({ type: GET_SITE_CONFIGURATION_FAILURE, error })
  }
}

function* addSiteConfigurationSaga() {
  yield takeLatest(ADD_SITE_CONFIGURATION, addSiteConfiguration)
}

function* getSiteConfigurationSaga() {
  yield takeLatest(GET_SITE_CONFIGURATION, getSiteConfiguration)
}

function* updateSiteConfigurationSaga() {
  yield takeLatest(UPDATE_SITE_CONFIGURATION, updateSiteConfiguration)
}

export default function* siteConfigurationSaga() {
  yield all([addSiteConfigurationSaga(), getSiteConfigurationSaga(), updateSiteConfigurationSaga()])
}
