import {
  ReviewState,
  ActionType,
  GET_ALL_REVIEWS,
  GET_ALL_REVIEWS_SUCCESS,
  GET_ALL_REVIEWS_FAILURE,
  CHANGE_REVIEW_STATUS,
  CHANGE_REVIEW_STATUS_SUCCESS,
  Reviews,
  CHANGE_REVIEW_STATUS_FAILURE,
  REJECT_REVIEW,
  REJECT_REVIEW_SUCCESS,
  REJECT_REVIEW_FAILURE,
} from './Types'

const initialState: ReviewState = {
  reviews: [],
  isLoading: false,
}

export function reviewsReducer(state = initialState, action: ActionType): ReviewState {
  const { reviews } = state
  switch (action.type) {
    case GET_ALL_REVIEWS:
      return {
        ...state,
        reviews: [],
        isLoading: true,
      }

    case GET_ALL_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload?.items,
        isLoading: false,
      }

    case GET_ALL_REVIEWS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case CHANGE_REVIEW_STATUS:
      return {
        ...state,
      }

    case CHANGE_REVIEW_STATUS_SUCCESS:
      const reviewData = reviews.map((item: Reviews) => {
        if (item.id === action.payload.id) {
          return action.payload
        } else {
          return item
        }
      })

      return {
        ...state,
        reviews: reviewData,
      }

    case CHANGE_REVIEW_STATUS_FAILURE:
      return {
        ...state,
      }

    case REJECT_REVIEW:
      return {
        ...state,
      }

    case REJECT_REVIEW_SUCCESS:
      const rejectReviewIndex = reviews.findIndex((item: Reviews) => item.id === action.payload.id)
      const rejectReviewData = reviews
      rejectReviewData.splice(rejectReviewIndex, 1)
      return {
        ...state,
        reviews: rejectReviewData,
      }

    case REJECT_REVIEW_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}
