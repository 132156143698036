import { message as notification } from 'antd'

const NotificationWithIcon = (type: string, message: string) => {
  if (type === 'success') {
    notification.success(message, 4.5)
  } else if (type === 'error') {
    notification.error(message, 4.5)
  } else {
    notification.info(message, 4.5)
  }
}

export default NotificationWithIcon
