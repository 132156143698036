export interface homeVideoState {
  videos: []
  success: boolean
  error: boolean
  deleteSuccess: boolean
  deleteError: boolean
  allVideosList: Array<any>
  totalVideosRow: any
  loadVideo: boolean
}
export interface homeVideoData {
  title: string
  url: string
  description: string
}

export interface homeVideo {
  id: string
  title: string
  url: string
  createVideo: any
  description: string
  createdAt: string
}

//create home video

export const CREATE_CATEGORY_WISE_VIDEO = 'CREATE_CATEGORY_WISE_VIDEO'
export const CREATE_CATEGORY_WISE_VIDEO_SUCCESS = 'CREATE_CATEGORY_WISE_VIDEO_SUCCESS'
export const CREATE_CATEGORY_WISE_VIDEO_FAILURE = 'CREATE_CATEGORY_WISE_VIDEO_FAILURE'

//get all home videos

export const GET_CATEGORY_WISE_VIDEOS = 'GET_CATEGORY_WISE_VIDEOS'
export const GET_CATEGORY_WISE_VIDEOS_SUCCESS = 'GET_CATEGORY_WISE_VIDEOS_SUCCESS'
export const GET_CATEGORY_WISE_VIDEOS_FAILURE = 'GET_CATEGORY_WISE_VIDEOS_FAILURE'

//get all all videos

export const GET_ALL_VIDEOS_LIST = 'GET_ALL_VIDEOS_LIST'
export const GET_ALL_VIDEOS_LIST_SUCCESS = 'GET_ALL_VIDEOS_LIST_SUCCESS'
export const GET_ALL_VIDEOS_LIST_FAILURE = 'GET_ALL_VIDEOS_LIST_FAILURE'

//clear flag

export const CLEAR_MANAGE_VIDEO_FLAG = 'CLEAR_MANAGE_VIDEO_FLAG'

// update video

export const UPDATE_CATEGORY_WISE_VIDEO = 'UPDATE_CATEGORY_WISE_VIDEO'
export const UPDATE_CATEGORY_WISE_VIDEO_SUCCESS = 'UPDATE_CATEGORY_WISE_VIDEO_SUCCESS'
export const UPDATE_CATEGORY_WISE_VIDEO_FAILURE = 'UPDATE_CATEGORY_WISE_VIDEO_FAILURE'

// delete video

export const DELETE_CATEGORY_WISE_VIDEO = 'DELETE_CATEGORY_WISE_VIDEO'
export const DELETE_CATEGORY_WISE_VIDEO_SUCCESS = 'DELETE_CATEGORY_WISE_VIDEO_SUCCESS'
export const DELETE_CATEGORY_WISE_VIDEO_FAILURE = 'DELETE_CATEGORY_WISE_VIDEO_FAILURE'

interface createHomeVideo {
  type: typeof CREATE_CATEGORY_WISE_VIDEO
  payload: homeVideoData
}

interface createHomeVideoSuccess {
  type: typeof CREATE_CATEGORY_WISE_VIDEO_SUCCESS
  payload: homeVideo
}

interface createHomeVideoFailure {
  type: typeof CREATE_CATEGORY_WISE_VIDEO_FAILURE
  payload: any
}

interface getAllHomeVideo {
  type: typeof GET_CATEGORY_WISE_VIDEOS
  payload: any
}

interface getAllHomeVideoSuccess {
  type: typeof GET_CATEGORY_WISE_VIDEOS_SUCCESS
  payload: any
}

interface getAllHomeVideoFailure {
  type: typeof GET_CATEGORY_WISE_VIDEOS_FAILURE
  payload: any
}

interface getAllVideoList {
  type: typeof GET_ALL_VIDEOS_LIST
  payload: any
}

interface getAllVideoListSuccess {
  type: typeof GET_ALL_VIDEOS_LIST_SUCCESS
  payload: any
}

interface getAllVideoListFailure {
  type: typeof GET_ALL_VIDEOS_LIST_FAILURE
  payload: any
}

interface clearManageVideoFlag {
  type: typeof CLEAR_MANAGE_VIDEO_FLAG
  payload: any
}

interface updateVideo {
  type: typeof UPDATE_CATEGORY_WISE_VIDEO
  payload: homeVideo
}

interface updateVideoSuccess {
  type: typeof UPDATE_CATEGORY_WISE_VIDEO_SUCCESS
  payload: homeVideo
}

interface updateVideosFailure {
  type: typeof UPDATE_CATEGORY_WISE_VIDEO_FAILURE
  payload: any
}
interface deleteHomeVideo {
  type: typeof DELETE_CATEGORY_WISE_VIDEO
  payload: string
}

interface deleteHomeVideoSuccess {
  type: typeof DELETE_CATEGORY_WISE_VIDEO_SUCCESS
  payload: any
}

interface deleteHomeVideoFailure {
  type: typeof DELETE_CATEGORY_WISE_VIDEO_FAILURE
  payload: string
}
export type ActionType =
  | createHomeVideo
  | createHomeVideoSuccess
  | createHomeVideoFailure
  | getAllVideoList
  | getAllVideoListSuccess
  | getAllVideoListFailure
  | getAllHomeVideo
  | getAllHomeVideoSuccess
  | getAllHomeVideoFailure
  | clearManageVideoFlag
  | deleteHomeVideo
  | deleteHomeVideoSuccess
  | deleteHomeVideoFailure
  | updateVideo
  | updateVideoSuccess
  | updateVideosFailure
