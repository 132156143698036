import React, { Component } from 'react'

import CreateBannerIndex from '../CreateBannerIndex'
import History from '../../../History'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  location: any
  history: typeof History
}

class CreateMarketService extends Component<Props> {
  render() {
    const { location, history } = this.props
    return (
      <CreateBannerIndex
        history={history}
        backLink="/service-banners"
        location={location}
        pageTitle="Market Service Banner"
        titlePage="Boat service"
        type={mediaCategory.boatService.type}
      />
    )
  }
}

export default CreateMarketService
