import {
  addBannerState,
  ADD_BANNER,
  GET_CATEGORIES_WISE_BANNER,
  UPDATE_BANNER,
  DELETE_CATEGORY_ID_BANNER,
  bannerType,
} from './types'

export function addBannerImage(banner: addBannerState) {
  return {
    type: ADD_BANNER,
    payload: banner,
  }
}
export function updateBannerImage(banner: addBannerState) {
  return {
    type: UPDATE_BANNER,
    payload: banner,
  }
}
export function getCategoriesWiseBanners(data: any) {
  return {
    type: GET_CATEGORIES_WISE_BANNER,
    payload: data,
  }
}
export function deleteCategoryIdBanner(id: string) {
  return {
    type: DELETE_CATEGORY_ID_BANNER,
    payload: { id },
  }
}
