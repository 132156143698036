import React from "react";
import { Card, Col, Typography } from "antd";
import { Component } from "react";
const { Title } = Typography


class ManageRentSkeleton extends Component{
    render() {
        return (
            <>
            <Col span={7}>
              <Card loading title="PRIVATE" bordered={false}>
                <div className="d-flex justify-content-between">
                  <Col span={8}>
                    <img
                      src={require('../../../../assets/image/manageAuctionRoom/auction-room.png')}
                      height="50px"
                      width="50px"
                    />
                  </Col>
                  <Col span={8}>
                    <Title level={3} type="secondary">
                      0
                    </Title>
                  </Col>
                </div>
              </Card>
          </Col>
          <Col span={7}>
          <Card loading title="SHARED" bordered={false}>
                <div className="d-flex justify-content-between">
                  <Col span={8}>
                    <img
                      src={require('../../../../assets/image/manageAuctionRoom/auction-room.png')}
                      height="50px"
                      width="50px"
                    />
                  </Col>
                  <Col style={{padding:0}} span={8}>
                    <Title level={3} type="secondary">
                      0
                    </Title>
                  </Col>
                </div>
              </Card>
          </Col>
          <Col span={7}>
          <Card loading title="RENT PER HOUR" bordered={false}>
                <div className="d-flex justify-content-between">
                  <Col span={8}>
                    <img
                      src={require('../../../../assets/image/manageAuctionRoom/auction-room.png')}
                      height="50px"
                      width="50px"
                    />
                  </Col>
                  <Col span={8}>
                    <Title level={3} type="secondary">
                      0
                    </Title>
                  </Col>
                </div>
              </Card>
          </Col>
          </>
        )
    }
}

export default ManageRentSkeleton