import React, { Component, GetDerivedStateFromProps } from 'react'
import { Button, Card, Switch } from 'antd'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import { clearRoleFlag } from '../../store/role/Action'
import { getAllBoats, clearBoatFlag, changeBoatApproveStatus, deleteBoats } from '../../store/pendingAds/Action'
import { pendingAdsState } from '../../store/pendingAds/Types'
import { TitleSearch } from '../../component/SearchBar'
import { getSearchedData, formateDate, displayDefaultValue, viewBoatHandler, removePTag } from '../../helpers/helper'
import { confirmSubmitHandler } from '../../component/ConfirmSubmitHandler'
import { fillField, getAllBoatPagination, paginationPage, rejectBoatAd, userAllRoles } from '../../enums/enums'
import ApproveButton from '../../component/ApproveButton'
import { goToDashboardAction, clearGoToDashboard } from '../../store/dashboard/frontUsers/Action'
import CommonModal from '../../component/modal/CommonModal'
import WSField from '../../component/Field'
import WSToolTip from '../../component/WsToolTip'
import Text from 'antd/lib/typography/Text'
import NotificationWithIcon from '../../component/notification/Notification'
import { RejectionMailEditor } from '../../component/RejectionMailEditor'

interface Props {
  getAllBoats: typeof getAllBoats
  goToDashboardAction: typeof goToDashboardAction
  clearGoToDashboard: typeof clearGoToDashboard
  dashboardLink?: string
  dashboardLinkSuccess?: boolean
  roles: []
  success: Boolean
  boatSuccess: Boolean
  clearBoatFlag: typeof clearBoatFlag
  clearRoleFlag: typeof clearRoleFlag
  boats: []
  history: any
  deleteBoats: typeof deleteBoats
  changeBoatApproveStatus: typeof changeBoatApproveStatus
  boatIsLoading: boolean
  statusSuccess: Boolean
  totalAdminRow: any
  deleteSuccess: boolean
}

interface State {
  searchedUsers: any[]
  currentRecord: any
  searchedValue: string
  modalVisible: boolean
  error: boolean
  rejectMessage: string
}

class SingleSellerBoats extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      searchedUsers: [],
      currentRecord: { id: '' },
      searchedValue: '',
      modalVisible: false,
      error: false,
      rejectMessage: '',
    }
  }

  // componentDidMount() {
  //   const { getAllBoats } = this.props

  //   const input = {
  //     page: getAllBoatPagination.page,
  //     limit: getAllBoatPagination.limit,
  //     roleType: 'boat-owner',
  //   }
  //   getAllBoats(input)
  // }

  boatDetail = (record: any) => {
    const { goToDashboardAction } = this.props

    this.setState({
      currentRecord: record,
    })

    goToDashboardAction(record.seller.id)
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { boatSuccess, clearBoatFlag, dashboardLinkSuccess, dashboardLink, clearGoToDashboard, deleteSuccess } = props
    const { currentRecord } = state
    if (boatSuccess) {
      clearBoatFlag()
    }

    if (dashboardLinkSuccess) {
      viewBoatHandler(currentRecord, '', 'pending', dashboardLink)

      clearGoToDashboard()
    }

    if (deleteSuccess) {
      clearBoatFlag()
      return {
        modalVisible: false,
        rejectMessage: '',
      }
    }

    return null
  }

  changeBoatStatus = (columnName: any, id: any, value: any) => {
    const { changeBoatApproveStatus, boats } = this.props
    const boatObject: any = {
      id,
      columnName,
      value,
      adType: 'BOAT_OWNER_ADS',
      action: value ? 'ENABLED' : 'DISABLED',
      fieldName: 'boats',
      fieldArray: boats,
    }
    changeBoatApproveStatus(boatObject)
  }

  onSearch = (value: any) => {
    const { getAllBoats } = this.props
    const input = {
      ...getAllBoatPagination,
      roleType: userAllRoles.BOAT_OWNER,
    }

    if (value) {
      this.setState({ searchedValue: value })
      getAllBoats({ ...input, searchTerm: value })
    } else {
      this.setState({ searchedValue: '' })
      getAllBoats(input)
    }
  }

  setModalVisible = (data: boolean, record: tableRecords) => {
    this.setState({
      modalVisible: data,
      currentRecord: record,
    })
  }

  handleChange = (data: string) => {
    this.setState({
      rejectMessage: data,
    })
  }

  showHideError = (data: boolean) => {
    this.setState({
      error: data,
    })
  }

  onSubmit = () => {
    const { deleteBoats, boats } = this.props
    const { currentRecord, rejectMessage } = this.state
    if (rejectMessage.length) {
      deleteBoats({
        id: currentRecord.id,
        note: removePTag(rejectMessage),
        adType: 'BOAT_OWNER_ADS',
        fieldName: 'boats',
        fieldArray: boats,
      })
    } else {
      NotificationWithIcon('error', fillField)
    }
  }

  render() {
    const columns = [
      {
        title: 'Country',
        dataIndex: 'address',
        key: 'address',
        render: (text: any) => {
          return <div>{displayDefaultValue(text && text.length && text[0].country)}</div>
        },
      },
      {
        title: 'Seller',
        dataIndex: 'seller',
        key: 'seller',
        render: (text: any) => <span>{text && `${text.firstName} ${text.lastName}`}</span>,
      },
      {
        title: 'Boat Type',
        dataIndex: 'boatType',
        key: 'boatType',
        render: (text: any) => <span>{text && text.name}</span>,
      },
      {
        title: ' Price',
        dataIndex: 'price',
        key: 'price',
        render: (text: any) => <span>$ {text}</span>,
      },
      {
        title: ' Date Added',
        dataIndex: 'createdAt',
        key: 'date',
        render: (text: string) => <span>{formateDate(text)}</span>,
      },
      {
        title: 'Boat Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: any, record: tableRecords) => (
          <Switch
            checked={text}
            onChange={() => {
              this.changeBoatStatus('status', record.id, !text)
            }}
            checkedChildren="Active"
            unCheckedChildren="Block"
          />
        ),
      },
      {
        title: 'Approval Status',
        dataIndex: 'adStatus',
        key: 'adStatus',
        render: (text: string, record: tableRecords) => {
          const { changeBoatApproveStatus, boats } = this.props
          return (
            <ApproveButton
              action={changeBoatApproveStatus}
              input={{
                id: record.id,
                columnName: 'adStatus',
                value: !text,
                adType: 'BOAT_OWNER_ADS',
                action:'APPROVED',
                fieldName: 'boats',
                fieldArray: boats,
              }}
              buttonTitle="Approve"
              className="btn-view"
            />
          )
        },
      },
      {
        title: 'View Ad',
        dataIndex: 'ViewAd',
        key: 'view',
        render: (text: string, record: tableRecords, index: number) => (
          <>
            <Button className="btn-view" onClick={() => this.boatDetail(record)}>
              View
            </Button>
          </>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: tableRecords, index: number) => (
          <>
            <Button onClick={() => this.setModalVisible(true, record)} className="btn-delete">
              Reject
            </Button>
          </>
        ),
      },
    ]

    const { boatIsLoading, totalAdminRow, getAllBoats, roles, boats } = this.props
    const { searchedValue, modalVisible, error, rejectMessage } = this.state

    return (
      <>
        <Card title="Pending Ads - Boat Owner" extra={<TitleSearch onSearch={this.onSearch} />}>
          <ExpandTable
            columns={columns}
            tableData={getSearchedData(boats, searchedValue)}
            expandHtml={false}
            isExpand={false}
            page={paginationPage.boat}
            roles={roles}
            totalUsersRow={totalAdminRow}
            pageAction={getAllBoats}
            extraInput={{ roleType: userAllRoles.BOAT_OWNER }}
            role={userAllRoles.BOAT_OWNER}
            tableLoading={boatIsLoading}
          />
        </Card>
        {modalVisible && (
          <CommonModal
            customWidth={'60%'}
            okText="Submit"
            isFlag={modalVisible}
            title="Reason Of Rejection"
            handleCancel={() => this.setModalVisible(false, { id: '' })}
            footerProp={{ footer: null }}
          >
            <RejectionMailEditor
              onClick={() => (rejectMessage ? this.onSubmit() : this.showHideError(true))}
              errorMessage={error}
              onChangeText={(evt: any) => {
                error && this.showHideError(false)
                this.handleChange(evt.editor.getData())
              }}
            />
          </CommonModal>
        )}
      </>
    )
  }
}

const mapStateToProps = (state: pendingAdsState | any) => {
  return {
    success: state.roleState.success,
    roles: state.roleState.roles,
    boatSuccess: state.pendingAdsState.boatSuccess,
    boats: state.pendingAdsState.boats,
    totalAdminRow: state.pendingAdsState.totalAdminRow,
    boatIsLoading: state.pendingAdsState.boatIsLoading,
    dashboardLink: state.frontUsersCountReducer.dashboardLink,
    dashboardLinkSuccess: state.frontUsersCountReducer.dashboardLinkSuccess,
    deleteSuccess: state.pendingAdsState.deleteSuccess,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllBoats: (data: String) => dispatch(getAllBoats(data)),
    clearBoatFlag: () => dispatch(clearBoatFlag()),
    clearRoleFlag: () => dispatch(clearRoleFlag()),
    changeBoatApproveStatus: (data: any) => dispatch(changeBoatApproveStatus(data)),
    deleteBoats: (data: string) => dispatch(deleteBoats(data)),
    goToDashboardAction: (data: any) => dispatch(goToDashboardAction(data)),
    clearGoToDashboard: () => dispatch(clearGoToDashboard()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleSellerBoats)
