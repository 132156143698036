export const defaultCircularProps = (labels: any = [], data: any = []) => ({
  labels: labels,
  datasets: [
    {
      data: data,
      backgroundColor: ['#CCC', '#36A2EB', '#FFCE56', '#71B37C', '#b4c1d7', '#3a5199', '#896e69', '#d1b280', '#b3dbc0'],
      hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#71B37C'],
    },
  ],
})

export const defaultVerticalProps = (labels: any = [], mainLabel: String = 'Label', data: any = []) => ({
  labels: labels,
  datasets: [
    {
      label: mainLabel,
      backgroundColor: '#71B37C',
      borderColor: '#71B37C',
      hoverBackgroundColor: '#71B37C',
      hoverBorderColor: '#71B37C',
      fill: false,
      data: data,
    },
  ],
})
