import gql from "graphql-tag";

export const manageSalesEngineCountQuery = gql`
query {
	getSalesEngineDashboardCount{
    boatOwner{
      _id
      count
    }
    boatManufacturer{
      _id
      count
    }
    brokerAndDealer{
      _id
      count
    }
  }
}
`