import React, { Component, Fragment, GetDerivedStateFromProps } from 'react'
import 'react-quill/dist/quill.snow.css' // ES6
import { connect } from 'react-redux'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Card, Row, Col, Typography, Button, Divider } from 'antd'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import WSField from '../../component/Field'
import WSIcon from '../../component/WSIcon'
import { createSurveyDocumentData, getSurveyDocumentData } from '../../store/salesEngine/surveyDocument/Action'
import { SurveyDocument } from '../../store/salesEngine/surveyDocument/Types'
import UploadImage from '../../component/UploadImage'
import { splitUrlFromUnderscore, getAddMedia, trimmedValue, removePTag } from '../../helpers/helper'
import { handleSingleFileUpload, handleSingleFileDelete } from '../../helpers/S3FileUpload'
import NotificationWithIcon from '../../component/notification/Notification'
import { MEDIA_NAME, MEDIA_TYPE } from '../../enums/enums'

const { Text } = Typography

interface Props {
  createSurveyDocumentData: typeof createSurveyDocumentData
  getSurveyDocumentData: typeof getSurveyDocumentData
  getSuccess: boolean
  createSuccess: boolean
  surveyDocument: SurveyDocument
}
interface State {
  surveyData: SurveyDocument
  loading: boolean
}

class ManageSurveyorDocumentData extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      surveyData: {
        description: '',
        title: '',
        addMedia: [],
        document: '',
        removeMedia: [],
      },
    }
  }

  componentDidMount() {
    const { getSurveyDocumentData } = this.props
    getSurveyDocumentData()
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = props => {
    const { getSuccess, createSuccess, surveyDocument } = props
    if (getSuccess) {
      return {
        surveyData: { ...surveyDocument, addMedia: [], removeMedia: [] },
      }
    }

    if (createSuccess) {
      NotificationWithIcon('success', 'Successfully updated')
    }

    return null
  }

  selectSiteIcon = async (file: any, setFieldValue: any, values: any, acceptType: any) => {
    const getFileType = splitUrlFromUnderscore('/', file && file.type, '0')
    const { addMedia } = values

    if (acceptType === getFileType) {
      this.setState({ loading: true })
      await handleSingleFileUpload(
        file,
        'document',
        setFieldValue,
        addMedia,
        MEDIA_NAME.SURVEYDATADOCUMENT,
        MEDIA_TYPE.DOCUMENT
      )
      this.setState({ loading: false })
    } else {
      NotificationWithIcon('error', 'File Is Invalid.')
    }
  }

  handleRemove = (file: any, setFieldValue: any, values: any) => {
    const { addMedia } = values
    handleSingleFileDelete(file, 'document', setFieldValue, addMedia, values.document, 'filter', values)
  }

  render() {
    const { surveyData } = this.state
    const { createSurveyDocumentData } = this.props
    return (
      <Formik
        key={surveyData.id}
        initialValues={surveyData}
        onSubmit={values => {
          const { document, __typename, ...otherData } = values

          createSurveyDocumentData({
            ...otherData,
            addMedia: getAddMedia(otherData.addMedia),
          })
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title field is required.'),
          description: Yup.string().required('Description for members field is required.'),
        })}
      >
        {(props: any) => {
          const { handleSubmit, setFieldValue, values } = props

          return (
            <form onSubmit={handleSubmit}>
              <Card
                title={
                  <span>
                    <WSIcon type={<PlusCircleOutlined />} />
                    Manage Survey Document Data
                  </span>
                }
                extra={
                  <button disabled={this.state.loading} className="ml-15 ant-btn ant-btn-primary" type="submit">
                    Save
                  </button>
                }
              >
                {/* <Row gutter={16}>
                  <h3 className="ml-15">Survey Document Data</h3>
                  <Divider className="mt-0" />
                </Row> */}
                {/* <Row gutter={16}>
                  <WSField
                    type="textbox"
                    placeholder="Title"
                    value={values.title}
                    defaultValue={values.title}
                    onChange={(e: any) => setFieldValue('title', e.target.value)}
                    onBlur={(e: { target: { value: string } }) => setFieldValue('title', trimmedValue(e.target.value))}
                  />
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-box" span={24}>
                    <span className="ant-form-item-required" title="My Boat Info Text">
                      Description
                    </span>

                    <WSField
                      type="textArea"
                      defaultValue={values.description}
                      onChangeText={(evt: any) => setFieldValue('description', evt.editor.getData())}
                      onBlur={(evt: { editor: { getData: () => string } }) =>
                        setFieldValue('description', trimmedValue(removePTag(evt.editor.getData())))
                      }
                    />
                    <Text type="danger">
                      <ErrorMessage className="invalid-feedback ant-typography-danger" name={`description`} component="span" />
                    </Text>
                  </Col>
                </Row> */}
                <Row gutter={16}>
                  <Col className="gutter-box" span={24}>
                    {values?.document?.id && values?.document?.url ? (
                      <UploadImage
                        defaultFileList={[
                          {
                            uid: values.document.id,
                            name: 'Uploaded Document',
                            status: 'done',
                            url: values.document.url.split('?').shift(),
                          },
                        ]}
                        withIcon
                        accept=".doc,.docx,.pdf"
                        buttonText="Upload Document"
                        openFileDialogOnClick={false}
                        onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                        selectSiteIcon={(file: any) => this.selectSiteIcon(file, setFieldValue, values, 'application')}
                      />
                    ): (
                      <UploadImage
                        withIcon
                        accept=".doc,.docx,.pdf"
                        buttonText="Upload Document"
                        onRemove={(rem: any) => this.handleRemove(rem, setFieldValue, values)}
                        selectSiteIcon={(file: any) => this.selectSiteIcon(file, setFieldValue, values, 'application')}
                      />
                    )}
                  </Col>
                </Row>
              </Card>
            </form>
          )
        }}
      </Formik>
    )
  }
}

const mapStateToProps = (state: any) => ({
  surveyDocument: state.surveyDocumentReducer?.surveyDocument,
  getSuccess: state.surveyDocumentReducer?.getSuccess,
  createSuccess: state.surveyDocumentReducer?.createSuccess,
})

const mapDispatchToProps = (dispatch: any) => ({
  createSurveyDocumentData: (data: SurveyDocument) => dispatch(createSurveyDocumentData(data)),
  getSurveyDocumentData: () => dispatch(getSurveyDocumentData()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveyorDocumentData)
