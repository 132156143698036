import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Card, Button } from 'antd'

import History from '../../History'
import ExpandTable from '../../component/ExpandTable'
import { getAllAuctionRoomDepositors } from '../../store/manageAuctionRoom/Action'
import { AuctionRoomDeposit, getAuctionRoomDepositInput } from '../../store/manageAuctionRoom/Types'
import { formateDateTime } from '../../helpers/helper'

interface Props {
  history: typeof History
  getAllAuctionRoomDepositors: typeof getAllAuctionRoomDepositors
  location: any
  auctionRoomDepositors: AuctionRoomDeposit[]
  auctionRoomDepositorsTotalRow: number
}

interface State {
  auctionId: string
}

class ManageAuctionDepositors extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      auctionId: props.location.state.auctionId,
    }
  }

  // componentDidMount() {
  //   const { getAllAuctionRoomDepositors } = this.props
  //   const { auctionId } = this.state

  //   if (auctionId) {
  //     getAllAuctionRoomDepositors({ auctionId })
  //   }
  // }

  render() {
    const { auctionRoomDepositors, auctionRoomDepositorsTotalRow, getAllAuctionRoomDepositors, history } = this.props
    const { auctionId } = this.state

    const columns = [
      {
        title: 'Buyer Name',
        dataIndex: 'user',
        key: 'user',
        render: (text: any) => (
          <span>
            {text.firstName} {text.lastName}
          </span>
        ),
      },
      {
        title: 'Deposit Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: string) => <> {formateDateTime(text)} </>,
      },
      {
        title: 'Status',
        dataIndex: 'isDepositAdded',
        key: 'isDepositAdded',
        render: (text: string) =>
          text ? (
            <span className="active-status-text">Deposit Added</span>
          ) : (
            <span className="inactive-status-text">Deposit Remaining</span>
          ),
      },
      {
        title: 'Bid Status',
        dataIndex: 'auctionRoom',
        key: 'auctionRoom',
        render: (text: any, record: any) =>
          text?.auctionWinner ? (
            record?.user?.id === text.auctionWinner?.id ? (
              <span className="active-status-text">Win</span>
            ) : (
              <span className="inactive-status-text">Lose</span>
            )
          ) : (
            <span className="active-status-text">In Progress</span>
          ),
      },
    ]

    return (
      <Card title="Manage Auction's Depositors" extra={<Button onClick={() => history.goBack()}> {'Back'}</Button>}>
        <ExpandTable
          pageAction={getAllAuctionRoomDepositors}
          columns={columns}
          tableData={auctionRoomDepositors}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={auctionRoomDepositorsTotalRow}
          extraInput={{ auctionId: auctionId }}
          page="boat"
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => ({
  auctionRoomDepositors: state.auctionRoomReducer && state.auctionRoomReducer.auctionRoomDepositors,
  auctionRoomDepositorsTotalRow: state.auctionRoomReducer.auctionRoomDepositorsTotalRow,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllAuctionRoomDepositors: (data: getAuctionRoomDepositInput) => dispatch(getAllAuctionRoomDepositors(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageAuctionDepositors)
