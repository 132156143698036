import React from 'react'
import { Popover } from 'antd'

const WSToolTip = (props: any) => {
  const { title, children } = props

  return title ? <Popover content={title}>{children}</Popover> : children
}

export default WSToolTip
