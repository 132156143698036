import React, { Component, GetDerivedStateFromProps } from 'react'
import { Card, Row, Col, Typography, Input, Button } from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import History from '../../../History'

import { createPages, updatePages, clearPagesFlag } from '../../../store/staticContent/managePages/Action'
import { pageData } from '../../../store/staticContent/managePages/Type'
import WSField from '../../../component/Field'
import WSIcon from '../../../component/WSIcon'
import { PlusCircleOutlined } from '@ant-design/icons'
import { removePTag, trimmedValue } from '../../../helpers/helper'

const { Text } = Typography

interface Props {
  createPages: typeof createPages
  location: any
  updatePages: typeof updatePages
  history: typeof History
  pageSuccess: boolean
  clearPagesFlag: typeof clearPagesFlag
  match: any
}
interface State {
  editPage: any
  isEdit: boolean
  managePage: {
    title: string
    pageContent: string
  }
  type: string
}

class CreatePage extends Component<Props, State> {
  formSubmitHandler = (values: pageData) => {
    const { isEdit, type } = this.state
    const { createPages, updatePages } = this.props

    values.pageContent = removePTag(values.pageContent)
    if (type) values.type = type
    if (isEdit) {
      updatePages(values)
    } else {
      createPages(values)
    }
  }

  componentDidMount() {
    const { clearPagesFlag } = this.props
    clearPagesFlag()
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      editPage: props.location.state ? props.location.state.page.record : null,
      isEdit: props.location.state ? props.location.state.page.isEdit : null,
      type: props.match.params.type || '',
      managePage: {
        title: '',
        pageContent: '',
      },
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { pageSuccess, clearPagesFlag, history } = props

    if (pageSuccess) {
      clearPagesFlag()
      history.push(`/manage-pages/${state.type}`)
    }
    return null
  }

  render() {
    const { managePage, editPage } = this.state
    const { history } = this.props
    return (
      <Formik
        initialValues={editPage || managePage}
        onSubmit={values => this.formSubmitHandler(values)}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title field is required.'),
        })}
        render={({ setFieldValue, values }) => (
          <Form>
            <Card
              title={
                <span>
                  <WSIcon type={<PlusCircleOutlined />} />
                  Create Page
                </span>
              }
              extra={
                <>
                  <Button onClick={() => history.goBack()}>Back</Button>
                  <button className="ml-15 ant-btn ant-btn-primary" type="submit">
                    Save
                  </button>
                </>
              }
            >
              <Row gutter={16}>
                <Col className="gutter-box" md={12}>
                  <span className="ant-form-item-required" title="title">
                    Page Title
                  </span>
                  <Input
                    name="title"
                    placeholder="Page Title"
                    value={values.title}
                    onChange={e => setFieldValue('title', e.target.value)}
                    onBlur={e => setFieldValue('title', trimmedValue(e.target.value))}
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`title`} component="span" />
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col className="gutter-box" span={24}>
                  <span className="ant-form-item-required" title="pageContent">
                    Page Content
                  </span>

                  <WSField
                    type="textArea"
                    defaultValue={values.pageContent}
                    onChangeText={(evt: any) => {
                      setFieldValue('pageContent', evt.editor.getData())
                    }}
                    onBlur={(evt: { editor: { getData: () => string } }) =>
                      setFieldValue('pageContent', trimmedValue(removePTag(evt.editor.getData())))
                    }
                  />
                  <Text type="danger">
                    <ErrorMessage className="invalid-feedback ant-typography-danger" name={`pageContent`} component="span" />
                  </Text>
                </Col>
              </Row>
            </Card>
          </Form>
        )}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  pageSuccess: state.pageReducer && state.pageReducer.pageSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createPages: (data: pageData) => dispatch(createPages(data)),
  updatePages: (data: any) => dispatch(updatePages(data)),
  clearPagesFlag: () => dispatch(clearPagesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreatePage)
