import gql from 'graphql-tag'

export const getSurveyDocument = gql`
  {
    getSurveyData {
      id
      title
      description
      document {
        id
        url
      }
    }
  }
`

export const createSurveyDocument = gql`
  mutation createOrUpdateSurveyData($input: SurveyDataInput!) {
    createOrUpdateSurveyData(input: $input) {
      id
      title
      description
      document {
        id
        url
      }
    }
  }
`
