import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import History from '../../../History'

import ListCategoryIndex from '../CategoryIndexPages/ListCategoryIndex'
import { getAllBoatTypes, deleteBoatTypes, clearManageBoatTypesFlag } from '../../../store/staticContent/manageBoatTypes/Action'

export interface Props {
  history: typeof History
  getAllBoatTypes: typeof getAllBoatTypes

  boatTypes: []
  deleteBoatTypes: typeof deleteBoatTypes
  clearManageBoatTypesFlag: typeof clearManageBoatTypesFlag
  deleteSuccess: boolean
  boatTypeTotal: number
}

export interface State {
  searchedValue: string
}
class ManageBoatTypes extends Component<Props, State> {
  render() {
    const { deleteBoatTypes, history, boatTypes, boatTypeTotal, getAllBoatTypes, clearManageBoatTypesFlag, deleteSuccess } =
      this.props
    return (
      <ListCategoryIndex
        type={'boatType'}
        history={history}
        pageTitle="Manage Boat Type"
        createPageLink="/create-boat-types"
        createPageTitle="Add Boat Type"
        listArray={boatTypes}
        pageTotal={boatTypeTotal}
        listAction={getAllBoatTypes}
        clearFlagAction={clearManageBoatTypesFlag}
        deleteSuccess={deleteSuccess}
        deleteAction={deleteBoatTypes}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  boatTypes: state.boatTypesReducer && state.boatTypesReducer.boatTypes,
  boatTypeTotal: state.boatTypesReducer && state.boatTypesReducer.boatTypeTotal,
  deleteSuccess: state.boatTypesReducer && state.boatTypesReducer.deleteSuccess,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAllBoatTypes: (data: any) => dispatch(getAllBoatTypes(data)),
  deleteBoatTypes: (data: string) => dispatch(deleteBoatTypes(data)),
  clearManageBoatTypesFlag: () => dispatch(clearManageBoatTypesFlag()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageBoatTypes)
