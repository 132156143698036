import * as React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { getAllReviews, changeReviewStatus, rejectReview } from '../../store/manageReviews/Action'
import { pagination, rejectReviewTooltip } from '../../enums/enums'
import { GetInput, Reviews, ChangeReviewStatusInput, UserModule } from '../../store/manageReviews/Types'
import ExpandTable, { tableRecords } from '../../component/ExpandTable'
import { dateStringFormate, getRole } from '../../helpers/helper'
import ThreeToggleSwitch from '../../component/ThreeToggleSwitch'
import ApproveButton from '../../component/ApproveButton'
import { Role } from '../../store/role/Types'
import { nameFormatter } from '../../helpers/helper'
import Loader from '../../component/Loading'
import { Redirect } from 'react-router-dom'

interface Props {
  validType: string
  getAllReviews: typeof getAllReviews
  reviews: Reviews[]
  match: {
    params: {
      type: string
    }
  }
  roles: Role[]
  changeReviewStatus: typeof changeReviewStatus
  rejectReview: typeof rejectReview
  isLoading: boolean
}

interface State {
  invalidType: boolean
}

class ManageReviewRatings extends React.Component<Props, State> {
  state = {
    invalidType: false,
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props

    if (params?.type === this.props.validType) {
      this.getAllReviewsApi()
    } else {
      this.setState({ invalidType: true })
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      match: { params },
    } = this.props

    if (params?.type !== prevProps?.match?.params?.type) {
      this.getAllReviewsApi()

      if (params?.type === this.props.validType) {
        this.setState({ invalidType: true })
      }
    }
  }

  getAllReviewsApi = () => {
    const {
      getAllReviews,
      roles,
      match: { params },
    } = this.props

    const role = getRole(roles, params.type)
    getAllReviews({
      limit: pagination.limit,
      page: pagination.page,
      userType: role?.id,
    })
  }

  columns = [
    {
      title: 'Ratings',
      dataIndex: 'rating',
      key: 'rating',
    },
    {
      title: 'Reviews',
      dataIndex: 'reviews',
      key: 'reviews',
    },
    {
      title: 'Reviewed By',
      dataIndex: 'user',
      key: 'user',
      render: (text: UserModule, record: tableRecords, index: number) => (
        <> {nameFormatter([text.firstName, text.firstName])} </>
      ),
    },
    {
      title: 'Reviewed On',
      dataIndex: 'moduleId',
      key: 'moduleId',
      render: (text: UserModule, record: tableRecords, index: number) => (
        <> {nameFormatter([text.firstName, text.firstName])} </>
      ),
    },
    {
      title: 'Reviewed On User Country',
      dataIndex: 'moduleId',
      key: 'moduleId',
      render: (text: UserModule, record: tableRecords, index: number) => (
        <> {text?.address?.length ? text.address[0]?.country : ''} </>
      ),
    },
    {
      title: 'Reviewed By User Country',
      dataIndex: 'user',
      key: 'user',
      render: (text: UserModule, record: tableRecords, index: number) => (
        <> {text?.address?.length ? text.address[0]?.country : ''} </>
      ),
    },
    {
      title: 'Added Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: tableRecords, index: number) => <> {dateStringFormate(text)} </>,
    },
    {
      title: 'Status',
      dataIndex: 'reviewStatus',
      key: 'reviewStatus',
      render: (text: boolean, record: tableRecords, index: number) => {
        const { changeReviewStatus } = this.props
        return (
          <ThreeToggleSwitch
            toggleMarketStatus={(e: any) =>
              changeReviewStatus({
                id: record?.id,
                column: 'reviewStatus',
                value: e.target.value,
              })
            }
            status={text}
          />
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text: any, record: tableRecords, index: number) => {
        const { rejectReview } = this.props
        return (
          <ApproveButton
            action={rejectReview}
            tooltip={rejectReviewTooltip}
            input={{
              id: record.id,
            }}
            buttonTitle="Reject Review"
            className="btn-delete"
          />
        )
      },
    },
  ]

  render() {
    const { reviews, isLoading } = this.props
    const { invalidType } = this.state

    if (invalidType) {
      return <Redirect to="/" />
    }

    return (
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <ExpandTable columns={this.columns} tableData={reviews} expandHtml={false} isExpand={false} />
        )}
      </>
    )
  }
}
const mapStateToProps = (state: any) => {
  return {
    reviews: state?.reviewsReducer?.reviews,
    isLoading: state?.reviewsReducer?.isLoading,
    roles: state.roleState.roles,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllReviews: (data: GetInput) => dispatch(getAllReviews(data)),
    changeReviewStatus: (data: ChangeReviewStatusInput) => dispatch(changeReviewStatus(data)),
    rejectReview: (data: ChangeReviewStatusInput) => dispatch(rejectReview(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageReviewRatings)
