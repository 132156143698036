import React, { Component } from 'react'
import countryList from 'react-select-country-list'
import WSSelect from './WSSelect'

interface Props {
  setValue: any
  value: string
  disabled?: boolean
}

interface State {}

export default class CountrySelector extends Component<Props, State> {
  options: any = countryList().getData()

  changeHandler = (value: any) => {
    localStorage.setItem('country', value)
    const { setValue } = this.props
    setValue(value)
  }

  render() {
    const { value } = this.props

    return (
      <WSSelect
        fieldIS="country"
        onChangeText={this.changeHandler}
        {...this.props}
        value={value}
        listOptions="label"
        passed="label"
        arrayList={this.options}
      />
    )
  }
}
