import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getAllBoatShow, changeBoatShowStatus, deleteBoatShow } from './Schema'
import {
  getBoatShowInput,
  GET_ALL_BOAT_SHOWS,
  ActionType,
  GET_ALL_BOAT_SHOWS_SUCCESS,
  GET_ALL_BOAT_SHOWS_FAILURE,
  CHANGE_BOAT_SHOW_STATUS,
  CHANGE_BOAT_SHOW_STATUS_SUCCESS,
  CHANGE_BOAT_SHOW_STATUS_FAILURE,
  BoatShowStatusInput,
  deleteBoatShowInput,
  DELETE_BOAT_SHOW_SUCCESS,
  DELETE_BOAT_SHOW_FAILURE,
  DELETE_BOAT_SHOW,
} from './Types'

function getBoatShowsApi(input: getBoatShowInput) {
  return client
    .query({
      query: getAllBoatShow,
      variables: input,
      fetchPolicy: 'no-cache',
    })
}

function toggleBoatShowStatusApi(input: BoatShowStatusInput) {
  return client
    .mutate({
      mutation: changeBoatShowStatus,
      variables: input,
    })
}

function deleteBoatShowApi(input: deleteBoatShowInput) {
  return client
    .mutate({
      mutation: deleteBoatShow,
      variables: input,
    })
}

function* getBoatShows(action: ActionType) {
  try {
    const res = yield getBoatShowsApi(action.payload)
    yield put({
      type: GET_ALL_BOAT_SHOWS_SUCCESS,
      payload: res.data.getAllBoatShow,
    })
  } catch (error) {
    yield put({ type: GET_ALL_BOAT_SHOWS_FAILURE, error })
  }
}

function* toggleBoatShowStatus(action: ActionType) {
  try {
    const res = yield toggleBoatShowStatusApi(action.payload)
    yield put({
      type: CHANGE_BOAT_SHOW_STATUS_SUCCESS,
      payload: res.data.changeStatusBoatShow,
      column: action.payload.column,
    })
  } catch (error) {
    yield put({ type: CHANGE_BOAT_SHOW_STATUS_FAILURE, error })
  }
}

function* rejectBoatShow(action: ActionType) {
  try {
    const res = yield deleteBoatShowApi(action.payload)
    yield put({
      type: DELETE_BOAT_SHOW_SUCCESS,
      payload: { id: res.data.deleteBoatShow.id, record: action.payload },
    })
  } catch (error) {
    yield put({ type: DELETE_BOAT_SHOW_FAILURE, error })
  }
}

function* getBoatShowsSaga() {
  yield takeLatest(GET_ALL_BOAT_SHOWS, getBoatShows)
}

function* toggleBoatShowStatusSaga() {
  yield takeLatest(CHANGE_BOAT_SHOW_STATUS, toggleBoatShowStatus)
}

function* deleteBoatShowSaga() {
  yield takeLatest(DELETE_BOAT_SHOW, rejectBoatShow)
}

export default function* boatShowSaga() {
  yield all([getBoatShowsSaga(), toggleBoatShowStatusSaga(), deleteBoatShowSaga()])
}
