import React, { Component } from 'react'

import CreateBannerIndex from '../CreateBannerIndex'
import History from '../../../History'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  location: any
  history: typeof History
}

class CreateSignUpBanner extends Component<Props> {
  render() {
    const { location, history } = this.props
    return (
      <CreateBannerIndex
        history={history}
        backLink="/manage-signup-banners"
        location={location}
        pageTitle="Sign-up Banner"
        titlePage="Sign-up"
        type={mediaCategory.signUp.type}
        isSignUpBanner
      />
    )
  }
}

export default CreateSignUpBanner
