import gql from 'graphql-tag'
import { AddressType } from '../schemaTypes'

const User = `{
  id
  firstName
  email
  lastName
  role {
    id
    aliasName
    role
    type
  }
}`

export const auctionWinnerForAuction = `{
  auctionWinner {
    id
    firstName
    lastName
  }
  boat {
    id
    boatName
    price
  }
  id
  startTime
  endTime
  startPrice
  minimumRaisedAmount
  status
  createdAt
}`

const Boat = `{
    id
    adId
    adStatus    
    placeName
    country
    address
    postalCode
    city   
    boatOwnershipId
    ownershipAttachment {
      id
      url
    }
    listedBy
    boatType {
      id
      name
      icon
      createdAt
      updatedAt
    }
    boatStatus {
      id
      alias
    }
    boatName    
    trailer
    yearBuilt
    manufacturedBy {
      typeId {
        type
        id
      }
      alias
      id
      lookUpName
      isActive
    }
    price
    description
    images
    layout
    video
    status
    seller ${User}
    createdAt
}`

let AuctionBid = `{
  id
  price
  status
  createdAt
  auctionRoom ${auctionWinnerForAuction}
  user {
    id
    firstName
    lastName
  }
}`

let AuctionDeposit = `{
  id
  isDepositAdded
  createdAt
  user {
    id
    firstName
    lastName
  }
  auctionRoom {
    id
    auctionWinner {
      id
    }
  }
}`

let AuctionTransaction = `{
  id
  createdAt
  status
  country
  category_id
  sub_category_id
  externalModuleType
  externalId
  transactionType
  paymentId
  senderId {
    id
    firstName
    lastName
  }
  receiverId {
    id
    firstName
    lastName
  }
  amount
}`

const AuctionRoom = `{
  auctionWinner {
    id
    firstName
    lastName
  }
  auctionBids {
    id
  }
    boat {
      id
      adId
      yearBuilt
      address ${AddressType}
      boatType{
        name
      }
      lengthInFt
      boatName
      price
      images {
        id
        url
      }
      seller ${User}
    }
    id
    startPrice
    startTime
    endTime
    status
    createdAt
    auctionStartingBid
    minimumRaisedAmount
    auctionCurrentBid
    inspectionCost
    bidDuration
    # auctionBids { id }
    depositAmount
    auctionDepositors {
      id
    }
}`

AuctionBid = `{
  auctionRoom ${auctionWinnerForAuction}
  id
  price
  status
  user {
    id
    firstName
    lastName
  }
  createdAt
}`

export const getAllAuctionRoom = gql`
  query getAllAuctionRoom($page: Int, $limit: Int, $isAdmin: Boolean = true, $status: AuctionStatusType = null, $startPriceSort: SORTTPE = null, $searchTerm: String) {
      getAllAuctionRoom(page: $page, limit: $limit, isAdmin: $isAdmin, status: $status, startPriceSort: $startPriceSort, searchTerm: $searchTerm) {
        items ${AuctionRoom}
        total
      }
    }
`

export const changeAuctionRoomStatus = gql`
  mutation changeAuctionRoomStatus($id: String!, $column: String!, $value: String!) {
    changeAuctionRoomStatus(id: $id, column: $column, value: $value) 
      ${AuctionRoom}
  }
`

export const deleteAuctionRoom = gql`
  mutation deleteAuctionRoom($id: String!) {
    deleteAuctionRoom(id: $id) 
      ${AuctionRoom}
  }
`

export const getAuctionBids = gql`
  query getAuctionBids($page: Int, $limit: Int, $auctionId: String!) {
      getAuctionBids(page: $page, limit: $limit, auctionId: $auctionId) {
        items ${AuctionBid}
        total
      }
    }
`

export const getAuctionDepositorsById = gql`
  query getAuctionDepositorsById($page: Int, $limit: Int, $auctionId: String!) {
      getAuctionDepositorsById(page: $page, limit: $limit, auctionId: $auctionId) {
        items ${AuctionDeposit}
        total
      }
    }
`

export const getAuctionTransactionsById = gql`
  query getTransactions($page: Int, $limit: Int, $auction_id: String!, $sales_engine_id: String!, $sub_category_id: String! = "") {
    getTransactions(page: $page, limit: $limit, auction_id: $auction_id, sales_engine_id: $sales_engine_id, sub_category_id: $sub_category_id) {
        items ${AuctionTransaction}
        total
      }
    }
`

export const changeAuctionBidStatus = gql`
  mutation changeAuctionBidStatus($id: String!, $column: String!, $value: Boolean!) {
    changeAuctionBidStatus(id: $id, column: $column, value: $value) {
      id
      message
      statusCode
    }
  }
`
