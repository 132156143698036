import { GET_USER_DOCUMENTS, CHANGE_DOCUMENT_STATUS, CLEAR_DOCUMENT_STATUS, GET_DOCUMENT_URL } from './Type'

export function getUserDocumentAction(data: any) {
  return {
    type: GET_USER_DOCUMENTS,
    payload: data,
  }
}

export function changeDocumentStatus(data: any) {
  return {
    type: CHANGE_DOCUMENT_STATUS,
    payload: data,
  }
}

export function clearDocumentStatus() {
  return {
    type: CLEAR_DOCUMENT_STATUS,
  }
}

export function getDocumentUrlAction(data: any) {
  return {
    type: GET_DOCUMENT_URL,
    payload: data,
  }
}
