import { all, put, takeLatest } from "redux-saga/effects";
import { client } from "../../..";
import { GET_MANAGE_SALES_ENGINE_COUNT, GET_MANAGE_SALES_ENGINE_COUNT_FAILURE, GET_MANAGE_SALES_ENGINE_COUNT_SUCCESS } from "./Type";
import { manageSalesEngineCountQuery } from "./Schema";

function getManageSalesEngineCountApi(){
    return client.query({
        query: manageSalesEngineCountQuery,
        fetchPolicy:'no-cache'
    })
}

function* getManageSalesEngineCountData(){
    try {
        const res = yield getManageSalesEngineCountApi()
        yield put({type: GET_MANAGE_SALES_ENGINE_COUNT_SUCCESS, payload:res.data})
    } catch (error) {
        yield put({type: GET_MANAGE_SALES_ENGINE_COUNT_FAILURE, error})
    }
}

function* getManageSalesEngineSaga(){
    yield takeLatest(GET_MANAGE_SALES_ENGINE_COUNT, getManageSalesEngineCountData)
}

export default function* getManageSalesEngineCountSaga(){
    yield all([getManageSalesEngineSaga()])
}