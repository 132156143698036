import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import { formateDate } from '../../helpers/helper'
import ManageBoatTable, { tableRecords } from '../../component/ManageBoatTable'
import { Switch } from 'antd'
import { getLiveExperienceRentAction, changeRentStatus } from '../../store/pendingAds/Action'
import { getAllBoatPagination } from '../../enums/enums'
import ThreeToggleSwitch from '../../component/ThreeToggleSwitch'

interface Props {
  getLiveExperienceRentAction: typeof getLiveExperienceRentAction
  liveExperienceRent: any[]
  totalLiveExperienceRentRow: number
  changeRentStatus: typeof changeRentStatus
}

class LiveExperienceRent extends Component<Props> {
  changeRentStatus = (columnName: any, record: any) => {
    const { changeRentStatus, liveExperienceRent } = this.props
    changeRentStatus({
      id: record.id,
      columnName,
      value: !record[columnName],
      fieldName: 'liveExperienceRent',
      fieldArray: liveExperienceRent,
    })
  }

  columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text: any, record: tableRecords) => <div>{`${text.firstName} ${text.lastName}`}</div>,
    },
    {
      title: 'Company Name',
      dataIndex: 'user',
      key: 'user1',
      render: (text: any) => <div>{text.companyName}</div>,
    },
    {
      title: 'Ad ID',
      dataIndex: 'adId',
      key: 'adId',
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <> {formateDate(text)} </>,
    },
    {
      title: 'Live experience Rent',
      dataIndex: 'liveExperience',
      key: 'liveExperience',
      render: (text: boolean, record: tableRecords) => (
        <ThreeToggleSwitch toggleMarketStatus={(e: any) => this.changeRentStatus('liveExperience', record)} status={text} />
      ),
    },
    {
      title: 'Ad Approval',
      dataIndex: 'adStatus',
      key: 'adStatus',
      render: (text: boolean, record: tableRecords) => (
        <Switch
          checked={text}
          onChange={() => this.changeRentStatus('adStatus', record)}
          checkedChildren="Approved"
          unCheckedChildren="Unapproved"
        />
      ),
    },
  ]

  componentDidMount() {
    const { getLiveExperienceRentAction } = this.props

    getLiveExperienceRentAction(getAllBoatPagination)
  }

  render() {
    const { liveExperienceRent, totalLiveExperienceRentRow, getLiveExperienceRentAction } = this.props
    return (
      <ManageBoatTable
        tableTitle="Manage Live Experience Rent Boats"
        renderColumn={this.columns}
        tableList={liveExperienceRent}
        totalUsersRow={totalLiveExperienceRentRow}
        getListAction={getLiveExperienceRentAction}
        view="rent"
      />
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    liveExperienceRent: state.pendingAdsState.liveExperienceRent,
    totalLiveExperienceRentRow: state.pendingAdsState.totalLiveExperienceRentRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getLiveExperienceRentAction: (data: any) => dispatch(getLiveExperienceRentAction(data)),
    changeRentStatus: (data: any) => dispatch(changeRentStatus(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveExperienceRent)
