import { put, takeLatest, all } from 'redux-saga/effects'
import { client } from '../../../'
import { getAdminUsersCount } from './Schema'
import { GET_ADMIN_USERS_COUNT_SUCCESS, GET_ADMIN_USERS_COUNT_FAILURE, GET_ADMIN_USERS_COUNT } from './Type'

function getAdminUserCountApi() {
  return client
    .query({
      query: getAdminUsersCount,
      fetchPolicy: 'no-cache',
    })
    
}

function* getAdminUserCountData() {
  try {
    const res = yield getAdminUserCountApi()
    yield put({ type: GET_ADMIN_USERS_COUNT_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: GET_ADMIN_USERS_COUNT_FAILURE, error })
  }
}

function* getAdminUserCountSaga() {
  yield takeLatest(GET_ADMIN_USERS_COUNT, getAdminUserCountData)
}

export default function* getManageAdminUserCountSaga() {
  yield all([getAdminUserCountSaga()])
}
