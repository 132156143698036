import {
  GET_ALL_BOAT_SHOWS,
  getBoatShowInput,
  BoatShowStatusInput,
  CHANGE_BOAT_SHOW_STATUS,
  deleteBoatShowInput,
  DELETE_BOAT_SHOW,
  CLEAR_BOAT_SHOW_FLAG,
} from './Types'

export const getAllBoatShows = (data: getBoatShowInput) => ({
  type: GET_ALL_BOAT_SHOWS,
  payload: data,
})

export const changeBoatShowStatus = (data: BoatShowStatusInput) => ({
  type: CHANGE_BOAT_SHOW_STATUS,
  payload: data,
})

export const deleteBoatShow = (data: deleteBoatShowInput) => ({
  type: DELETE_BOAT_SHOW,
  payload: data,
})

export const clearBoatShowFlag = () => {
  return {
    type: CLEAR_BOAT_SHOW_FLAG,
  }
}
