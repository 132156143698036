import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './assets/css/common.scss'
import App from './App'
import * as serviceWorker from './ServiceWorker'
import { ApolloProvider } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { Provider } from 'react-redux'
import configureStore from './store'
import { appUrl, websiteAppUrl } from './constants/GlobalConstants'
const store = configureStore()
const cache = new InMemoryCache()

const token = localStorage.getItem('auth_token')
const httpLink = new HttpLink({
  uri: appUrl,
  headers: {
    authorization: `${token}`,
  },
})

const webisteHttpLink = new HttpLink({
  uri: websiteAppUrl,
  headers: {
    authorization: `${token}`,
  },
})

export const client = new ApolloClient({
  link: httpLink,
  cache,
})
export const websiteClient = new ApolloClient({
  link: webisteHttpLink,
  cache,
})
ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
