import { GET_SURVEY_DOCUMENT, CREATE_SURVEY_DOCUMENT, SurveyDocument } from './Types'

export const getSurveyDocumentData = () => ({
  type: GET_SURVEY_DOCUMENT,
})

export const createSurveyDocumentData = (data: SurveyDocument) => ({
  type: CREATE_SURVEY_DOCUMENT,
  payload: data,
})
