import React from 'react'
import ExpandTable from '../../component/ExpandTable'
import { Card } from 'antd'
import {getAdminActivityLogs} from '../../store/pendingAds/Action'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { getAllBoatPagination, adminLogsSections, nullableFilters, paginationPage, adminLogsMethodTypes } from '../../enums/enums'
import { dateStringFormate, displayDefaultValue, formateDateTime } from '../../helpers/helper'
import Loader from '../../component/Loading'
import { TitleSearch } from '../../component/SearchBar'
import FilterButton from '../filters/FilterButton'

let pageFilterArray: any = []

export interface ActivityLogProps {
  getAdminActivityLogs: typeof getAdminActivityLogs
  adminActivityLog: any[]
  totalActivityLogRow: number
  isLoading: boolean
}

interface AdminActivityLogsState {
  searchedValue: string;
  page: number;
  limit: number;
  methodType: number;
  moduleName: number;
}


class AdminActivityLogs extends React.Component<ActivityLogProps, AdminActivityLogsState> {
  constructor(props: ActivityLogProps){
    super(props)
    this.state = {
      searchedValue: "",
      page:getAllBoatPagination.page,
      limit:getAllBoatPagination.limit,
      methodType:0,
      moduleName:0
    }
  }
  columns = [
    {
      title: 'Logged At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <> {formateDateTime(text)} </>,
    },
    {
      title: 'Admin Name',
      dataIndex: 'user',
      key: 'user',
      render: (text: any) => (
        <>
          {' '}
          {text && displayDefaultValue(text.firstName)} {text && text.lastName}{' '}
        </>
      ),
    },
    {
      title: 'Admin Title',
      dataIndex: 'user',
      key: 'user',
      render: (text: any) => (
        <>
          {' '}
          {text && displayDefaultValue(text.role.role)}
        </>
      ),
    },
    {
      title: 'Section',
      align:'center',
      dataIndex: 'moduleName',
      render: (text: string) => <> {text?.replaceAll('_',' ')} </>,
    },
    {
      title: 'Footage',
      align:'center',
      dataIndex: 'methodType',
      key: 'methodType',
      render: (text: string) => <> {text?.replaceAll('_',' ')} </>,
    },
    {
      title: 'Reference',
      dataIndex: 'moduleId',
      key: 'moduleId',
    },
  ]

  // componentDidMount() {
  //   const { getAdminActivityLogs } = this.props
  //   getAdminActivityLogs(getAllBoatPagination)
  // }

  componentDidMount() {
    this.props.getAdminActivityLogs({})
    pageFilterArray = [
      {
        title: 'Section',
        filedArray: adminLogsSections,
        listOptions: 'label',
        passed: 'value',
        actionField: 'moduleName',
      },
      {
        title: 'Footage',
        filedArray: adminLogsMethodTypes,
        listOptions: 'label',
        passed: 'value',
        actionField: 'methodType',
      },
    ]
  }

  onSearch = (value: any) => {
    const {getAdminActivityLogs} = this.props
    const input = {
      ...getAllBoatPagination
    }

    if(value){
      this.setState({searchedValue: value})
      getAdminActivityLogs({...input, searchTerm: value})
    } else {
      this.setState({searchedValue: ""})
      getAdminActivityLogs(input)
    }
  }

  onExpandTableChange = (value: any) => {
    this.setState({page: value.page, limit: value.limit })
  }

  onFilterButtonChange = (value: any) => {
    this.setState({
      methodType: value.methodType, 
      moduleName:value.moduleName,
      page:getAllBoatPagination.page,
      limit:getAllBoatPagination.limit
    })
  }

  onActionChange = (value: any) => {
    const {methodType,moduleName} = this.state
    if(value.methodType === undefined){
      this.onExpandTableChange(value)
      this.props.getAdminActivityLogs({
        page: value.page, 
        limit: value.limit,
        methodType:methodType,
        moduleName:moduleName
      })
    }else{
      this.onFilterButtonChange(value)
      this.props.getAdminActivityLogs({
        page:getAllBoatPagination.page,
        limit:getAllBoatPagination.limit,
        methodType: value.methodType,
        moduleName:value.moduleName
      })
    }
  }
  
  render() {
    const { adminActivityLog, totalActivityLogRow, isLoading } = this.props
    return (
      <Card title="Activity Logs" extra={<TitleSearch onSearch={this.onSearch} />}>
        <FilterButton filterArray={pageFilterArray} triggerAction={this.onActionChange} />
        <ExpandTable
          columns={this.columns}
          tableData={adminActivityLog}
          expandHtml={false}
          isExpand={false}
          totalUsersRow={totalActivityLogRow}
          pageAction={this.onActionChange}
          page={paginationPage.boat}
          tableLoading={isLoading}
        />
      </Card>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    adminActivityLog: state.pendingAdsState && state.pendingAdsState.adminActivityLog,
    isLoading: state.pendingAdsState && state.pendingAdsState.isLoading,
    totalActivityLogRow: state.pendingAdsState && state.pendingAdsState.totalActivityLogRow,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAdminActivityLogs: (data: any) => dispatch(getAdminActivityLogs(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminActivityLogs)
