import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Sort, getSearchedData } from '../../helpers/helper'
import { Button, Card } from 'antd'
import { TitleSearch } from '../../component/SearchBar'
import ExpandTable from '../../component/ExpandTable'
import NotificationWithIcon from '../../component/notification/Notification'
import { client } from '../..'
import gql from 'graphql-tag'
import { useHistory } from 'react-router-dom'
import { AddressType } from '../../store/schemaTypes'
import { redirectUrl } from '../../constants/GlobalConstants'
import FilterButton from '../filters/FilterButton'

const filterArray = [
  {
    title: 'Type',
    filedArray: [
      { label: 'New reports', value: 1 },
      { label: 'Blocked posts', value: 2 },
    ],
    listOptions: 'label',
    passed: 'value',
    actionField: 'type',
  },
]

export default function PostsWithReports() {
  const history = useHistory()

  const dispatch = useDispatch()

  const [type, setType] = useState(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([] as any[])
  const [total, setTotal] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [blocked, setBlocked] = useState([] as any[])

  const paginationRef = useRef({ page: 1, limit: 10 })

  const setTypeFromFilter = (args: any) => {
    setType(args?.type || 0)
  }

  const getReportedPosts = useCallback(async (pagination, type) => {
    setLoading(true)

    try {
      const res = await client.query({
        query: gql`
          query sm_getReportedPosts($pagination: PaginationInput!, $type: Float) {
            sm_getReportedPosts(pagination: $pagination, type: $type) {
              page
              limit
              total
              items {
                _id
                user {
                  _id
                  firstName
                  lastName
                  address ${AddressType}
                }
                totalReports
                blocked
              }
            }
          }
        `,
        variables: { pagination, type },
      })

      paginationRef.current.page = res.data.sm_getReportedPosts.page
      paginationRef.current.limit = res.data.sm_getReportedPosts.limit

      setTotal(res.data.sm_getReportedPosts.total)
      setData(res.data.sm_getReportedPosts.items)
    } catch (err) {
      console.log(err)
      NotificationWithIcon('error', 'Something went wrong')
    }

    setLoading(false)
  }, [])

  const blockPost = useCallback(async postId => {
    setLoading(true)

    try {
      await client.mutate({
        mutation: gql`
          mutation sm_blockPost($postId: String!) {
            sm_blockPost(postId: $postId)
          }
        `,
        variables: { postId },
      })
      NotificationWithIcon('success', 'Post blocked successfully')
      setBlocked(blocked => [...blocked, postId])
    } catch (err) {
      console.log(err)
      NotificationWithIcon('error', 'Something went wrong')
    }

    setLoading(false)
  }, [])

  useEffect(() => {
    getReportedPosts({}, type)
  }, [getReportedPosts, type])

  const tableCols = () => [
    {
      title: 'Post Id',
      dataIndex: '_id',
      key: '_id',
      render: (v: any) => (
        <a href={`${redirectUrl}/boat-media/post/${v}`} target="_blank">
          {v}
        </a>
      ),
    },
    {
      title: 'Posted By',
      dataIndex: 'user',
      key: 'user',
      render: (v: any) => v.firstName + ' ' + v.lastName,
    },
    {
      title: 'User Country',
      dataIndex: 'user',
      key: 'userAddress',
      render: (v: any) => v.address?.[0]?.country,
    },
    {
      title: 'Total Reports',
      dataIndex: 'totalReports',
      key: 'totalReports',
      sorter: (a: any, b: any) => Sort.StrOrNum(a.totalReports, b.totalReports),
    },
    {
      title: 'Actions',
      dataIndex: '_id',
      key: 'actions',
      render: (v: any, r: any) => (
        <div>
          <Button className="btn-view" onClick={() => history.push(`/social-media/post-reports/${v}`)}>
            View Reports
          </Button>
          {console.log(r)}
          <Button className="btn-danger" onClick={() => blockPost(v)} disabled={r.blocked || blocked.includes(v)}>
            Block Post
          </Button>
        </div>
      ),
    },
  ]

  return (
    <Card
      title="Manage Post Reports"
      extra={
        <div className="d-flex align-items-center" style={{ gap: 40 }}>
          <div style={{ marginBottom: -4 }}>
            <FilterButton filterArray={filterArray} triggerAction={setTypeFromFilter} hideDivider />
          </div>
          <TitleSearch onSearch={setSearchTerm} />
        </div>
      }
    >
      <ExpandTable
        columns={tableCols()}
        tableData={data}
        expandHtml={false}
        isExpand={false}
        totalUsersRow={total}
        tableLoading={loading}
        pageAction={(args: any) => getReportedPosts(args, type)}
      />
    </Card>
  )
}
