import {
  pageData,
  GET_ALL_PAGES,
  CREATE_PAGES,
  UPDATE_PAGES,
  DELETE_PAGES,
  CLEAR_PAGES_FLAG,
  // GET_PAGES_BY_TITLE,
  // GET_SINGLE_PAGE
} from './Type'

export function getAllPages(data: any) {
  return {
    type: GET_ALL_PAGES,
    payload: data,
  }
}

export function createPages(data: pageData) {
  return {
    type: CREATE_PAGES,
    payload: data,
  }
}

export function updatePages(data: pageData) {
  return {
    type: UPDATE_PAGES,
    payload: data,
  }
}

export function deletePages(data: string) {
  return {
    type: DELETE_PAGES,
    payload: data,
  }
}

export function clearPagesFlag() {
  return {
    type: CLEAR_PAGES_FLAG,
  }
}

// export function getSinglePage(data:any) {
//     return {
//         type: GET_SINGLE_PAGE,
//         payload: data
//     }
// }

// export function getPagesByTitle(data:any) {
//     return {
//         type: GET_PAGES_BY_TITLE,
//         payload: data
//     }
// }
