import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Card } from 'antd'

import { defaultCircularProps } from '../../helpers/ChartHelpers'

interface Props {
  title: any
  labels: any
  data: any
}

export const CircularGraph = (props: any) => {
  const customizedLabel = props.labels && props.labels.map((label: any,index: number) =>`${props.data[index]} ${label} `)
  const data = defaultCircularProps(customizedLabel, props.data)

  const options = {
    legend: {
      display: true,
      position: props.alignLabels || 'top'
    }
  };
  return (
    <Card bordered={false} {...props}>
      <h3>{props.chartTitle}</h3>
      <Doughnut options={options}  data={data} width={100} height={50} />
    </Card>
  )
}
