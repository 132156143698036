import { string } from 'prop-types'

// Describing the shape of the chat's slice of state
export interface addBannerState {
  adminUserRoleReducer?: any
  type: string
  mediaOrder?: string
  metatype: string
  url: string
  isHomeBannerCreated?: boolean
  isHomeBannerError?: boolean
  homeBanner?: any
  deleteHomeBannerSuccess?: boolean
  deleteHomeBannerError?: boolean
  isHomeBannerUpdateSuccess?: boolean
  isHomeBannerUpdateError?: boolean
  fieldName?: string
  bannerLoader: boolean
  deleteAdminUserSuccess?: boolean
}
export interface bannerType {
  type: string
  fieldName: string
  metatype?: string
  page?: any
  limit?: any
}

// Describing the different ACTION NAMES available
export const ADD_BANNER = 'ADD_BANNER'
export const GET_CATEGORIES_WISE_BANNER_SUCCESS = 'GET_CATEGORIES_WISE_BANNER_SUCCESS'
export const GET_CATEGORIES_WISE_BANNER_FAILURE = 'GET_CATEGORIES_WISE_BANNER_FAILURE'
export const GET_CATEGORIES_WISE_BANNER = 'GET_CATEGORIES_WISE_BANNER'
export const DELETE_CATEGORY_ID_BANNER = 'DELETE_CATEGORY_ID_BANNER'
export const DELETE_CATEGORY_ID_BANNER_SUCCESS = 'DELETE_CATEGORY_ID_BANNER_SUCCESS'
export const DELETE_CATEGORY_ID_BANNER_FAILURE = 'DELETE_CATEGORY_ID_BANNER_FAILURE'
export const UPDATE_BANNER = 'UPDATE_BANNER'
export const CLEAR_RECORD = 'CLEAR_RECORD'
export const SUCCESS_BANNER = 'SUCCESS_BANNER'
export const ERROR_BANNER = 'ERROR_BANNER'
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS'
export const UPDATE_BANNER_FAILURE = 'UPDATE_BANNER_FAILURE'

interface addBanner {
  type: typeof ADD_BANNER
  payload: addBannerState
}

interface successBanner {
  type: typeof SUCCESS_BANNER
  payload: string
}
interface getHomeBanner {
  type: typeof GET_CATEGORIES_WISE_BANNER
  payload: any
}
interface getHomeBannerError {
  type: typeof GET_CATEGORIES_WISE_BANNER_FAILURE
  payload: any
}

interface clearMessage {
  type: typeof CLEAR_RECORD
  payload: string
}
interface errorBanner {
  type: typeof ERROR_BANNER
  payload: string
}
interface updateBanner {
  type: typeof UPDATE_BANNER
  payload: addBannerState
}
interface updateBoatTypesSuccess {
  type: typeof UPDATE_BANNER_SUCCESS
  payload: addBannerState
}

interface getHomeSuccess {
  type: typeof GET_CATEGORIES_WISE_BANNER_SUCCESS
  payload: any
}
interface deleteCategoryIdBanner {
  type: typeof DELETE_CATEGORY_ID_BANNER
  payload: string
}
interface deleteHomeBannerSuccess {
  type: typeof DELETE_CATEGORY_ID_BANNER_SUCCESS
  payload: any
}

interface deleteHomeBannerFailure {
  type: typeof DELETE_CATEGORY_ID_BANNER_FAILURE
  payload: string
}
interface updateHomeBannerFailure {
  type: typeof UPDATE_BANNER_FAILURE
  payload: string
}
export type ActionType =
  | addBanner
  | clearMessage
  | successBanner
  | errorBanner
  | getHomeBanner
  | getHomeSuccess
  | updateBanner
  | updateBoatTypesSuccess
  | deleteCategoryIdBanner
  | deleteHomeBannerSuccess
  | deleteHomeBannerFailure
  | getHomeBannerError
  | updateHomeBannerFailure
