import React, { Component } from 'react'

import CreateBannerIndex from '../CreateBannerIndex'
import History from '../../../History'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  location: any
  history: typeof History
}

class CreateUserGuideBanner extends Component<Props> {
  render() {
    const { location, history } = this.props
    return (
      <CreateBannerIndex
        history={history}
        backLink="/manage-user-guide-banners"
        location={location}
        pageTitle="User Guide Banner"
        titlePage="User Guide"
        type={mediaCategory.userGuide.type}
      />
    )
  }
}

export default CreateUserGuideBanner
