import React from 'react'
import { Spin } from 'antd'

const Loader = () => {
  return (
    <div className="is-loading">
      <Spin className="loading" />
    </div>
  )
}
export default Loader
