import * as React from 'react'
import { Card, Row, Col } from 'antd'

const UserCountCard = (props: any) => {
  const { activeUsersCount, blockedUsersCount, deActiveAccountCount, verifiedUsersCount, unVerifiedUsersCount, title } = props
  return (
    <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: 25 }}>
      <Col span={4}>
        <Card className="w-130" hoverable>
          <p className="user-count">{activeUsersCount}</p>
          <p className="user-title">Active Users</p>
        </Card>
      </Col>
      <Col span={4}>
        <Card className="w-130" hoverable>
          <p className="user-count">{blockedUsersCount}</p>
          <p className="user-title">Blocked Users</p>
        </Card>
      </Col>
      <Col span={4}>
        <Card className="w-130" hoverable>
          <p className="user-count">{deActiveAccountCount}</p>
          <p className="user-title">Deactivate Users</p>
        </Card>
      </Col>
      <Col span={4}>
        <Card className="w-130" hoverable>
          <p className="user-count">{verifiedUsersCount}</p>
          <p className="user-title">Verified Users</p>
        </Card>
      </Col>
      <Col span={4}>
        <Card className="w-130" hoverable>
          <p className="user-count">{unVerifiedUsersCount}</p>
          <p className="user-title">Unverified Users</p>
        </Card>
      </Col>
    </Row>
  )
}
export default UserCountCard
