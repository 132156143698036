import React, { Component } from 'react'
import { connect } from 'react-redux'

import History from '../../../History'
import BannerListIndex from '../BannerListIndex'
import { mediaCategory } from '../../../enums/enums'

export interface Props {
  history: typeof History
  homeBannersTotalRow: number
  homeBanners: any[]
}

class ManageLogInBanner extends Component<Props> {
  render() {
    const { history, homeBannersTotalRow, homeBanners } = this.props

    return (
      <BannerListIndex
        history={history}
        tableTitle="Manage Login Banner"
        createLink="/create-login-banner"
        createBtnText="Add Banner"
        category="logIn"
        homeBannersTotalRow={homeBannersTotalRow}
        homeBanners={homeBanners}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  homeBanners: state.bannerReducer && state.bannerReducer[mediaCategory.logIn.fieldName],
  homeBannersTotalRow: state.bannerReducer && state.bannerReducer[`${mediaCategory.logIn.fieldName}TotalRow`],
})

export default connect(mapStateToProps, null)(ManageLogInBanner)
