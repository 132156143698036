import React, { Fragment, GetDerivedStateFromProps } from 'react'
import 'antd/dist/antd.css'
import { Layout, Menu, Dropdown, Button, Row, Col, Tag } from 'antd'
import XMenu from './Menu'
import '../App.css'
import '../assets/css/icon.scss'
import AppRouter from '../routes/AppRouter'
import { BrowserRouter, Link } from 'react-router-dom'
import LoginRoute from '../routes/LoginRouter'
import { getAllRoles, clearRoleFlag } from '../store/role/Action'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import WSIcon from '../component/WSIcon'
import { UserOutlined, MenuOutlined } from '@ant-design/icons'
import SearchWithDropdown from '../component/SearchWithDropdown'
import { Divider } from 'rc-menu'
import { getAdminPermissions } from '../store/permissions/Action'
import { AdminPermissions, AdminPermissionGroups } from '../enums/enums'
import { clearLoginFlag } from '../store/login/Actions'
import { getDashboardVerificationAlertCount } from '../store/dashboard/others/Action'
import { logOut } from '../helpers/helper'

const { Header, Content } = Layout

type ValueOf<T> = T[keyof T]

interface Props {
  getAllRoles: typeof getAllRoles
  clearRoleFlag: typeof clearLoginFlag
  roleSuccess: Boolean
  roles: any[]
  getAdminPermissions: typeof getAdminPermissions
  isSuperAdmin: boolean
  userRole: string
  permissions: string[]
  getDashboardVerificationAlertCount: typeof getDashboardVerificationAlertCount
}

interface State {
  collapsed: boolean
  openKeys: any[]
  key: string
}

class MainLayout extends React.PureComponent<Props, State> {
  rootSubmenuKeys = [
    'sub1',
    'sub2',
    'sub3',
    'sub4',
    'sub5',
    'sub6',
    'sub7',
    'sub8',
    'sub9',
    'sub10',
    'sub11',
    'sub12',
    'sub13',
    'sub14',
    'feedback',
    'rentBoat',
    'boatService',
    'marina',
    'email',
    'category',
  ]

  state = {
    collapsed: false,
    openKeys: ['sub1'],
    key: '',
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  componentDidMount() {
    const Auth_token = localStorage.getItem('auth_token')

    if (Auth_token) {
      this.props.getAllRoles()
      this.props.getAdminPermissions()
      this.props.getDashboardVerificationAlertCount()
    }
  }

  public static getDerivedStateFromProps: GetDerivedStateFromProps<Props, State> = (props, state) => {
    const { roleSuccess, clearRoleFlag } = props

    if (roleSuccess) {
      clearRoleFlag()
    }

    return null
  }

  onOpenChange = (openKeys: any) => {
    const latestOpenKey = openKeys.find((key: any) => this.state.openKeys.indexOf(key) === -1)

    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys })
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] })
    }
  }

  hasPermission = (target: ValueOf<typeof AdminPermissions>) =>
    this.props.isSuperAdmin || this.props.permissions?.includes(target.value)

  haveAnyPermissionInGroup = (group: ValueOf<typeof AdminPermissionGroups>) =>
    this.props.isSuperAdmin || group.permissions.some((grpPerm: any) => this.props.permissions?.includes(grpPerm.value))

  render() {
    const Auth_token = localStorage.getItem('auth_token') ? true : false
    const { collapsed, key, openKeys } = this.state
    const { isSuperAdmin } = this.props

    const userSetting = (
      <Menu>
        {isSuperAdmin && (
          <Menu.Item>
            <Link to="/add-admin-users">
              <b>Add admin user</b>
            </Link>
          </Menu.Item>
        )}

        <Menu.Item>
          <Link to="/change-password">
            <b>Change Password</b>
          </Link>
        </Menu.Item>

        <Divider />

        <Menu.Item onClick={() => logOut()}>
          <b>Log Out</b>
        </Menu.Item>
      </Menu>
    )

    return (
      <Fragment>
        {Auth_token && (
          <BrowserRouter>
            <Layout>
              <XMenu
                collapsed={collapsed}
                key={key}
                openKeys={openKeys}
                onOpenChange={this.onOpenChange}
                haveAnyPermissionInGroup={this.haveAnyPermissionInGroup}
                hasPermission={this.hasPermission}
              />

              <Layout>
                <Header style={{ background: '#fff', padding: 0 }}>
                  <Row>
                    <Col>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button className="trigger ml-15" onClick={this.toggle}>
                          <WSIcon type={<MenuOutlined />} />
                        </Button>
                        <span style={{ marginLeft: '20px', fontWeight: 'bold' }}>{localStorage.getItem('userName')}</span>
                        <Tag color="blue" style={{ marginLeft: '20px' }}>
                          {localStorage.getItem('role')}
                        </Tag>
                      </div>
                    </Col>
                    <Col flex="auto" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <SearchWithDropdown {...this.props} />
                    </Col>
                    <Col flex="100px">
                      <Dropdown overlay={userSetting} placement="bottomCenter">
                        <Button className="user-setting">
                          <WSIcon type={<UserOutlined />} />
                        </Button>
                      </Dropdown>
                    </Col>
                  </Row>
                </Header>
                <Content className="layout-content">
                  <AppRouter
                    allProps={{
                      ...this.props,
                      hasPermission: this.hasPermission,
                      haveAnyPermissionInGroup: this.haveAnyPermissionInGroup,
                    }}
                  />
                </Content>
              </Layout>
            </Layout>
          </BrowserRouter>
        )}
        {!Auth_token && <LoginRoute />}
      </Fragment>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    roles: state.roleState.roles,
    roleSuccess: state.roleState.success,
    isSuperAdmin: state.permissionsState.isSuperAdmin,
    userRole: state.permissionsState.role,
    permissions: state.permissionsState.permissions,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllRoles: () => dispatch(getAllRoles()),
    clearRoleFlag: () => dispatch(clearRoleFlag()),
    getAdminPermissions: () => dispatch(getAdminPermissions()),
    getDashboardVerificationAlertCount: () => dispatch(getDashboardVerificationAlertCount()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
