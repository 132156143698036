import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  ADD_BANNER,
  SUCCESS_BANNER,
  ERROR_BANNER,
  ActionType,
  GET_CATEGORIES_WISE_BANNER_SUCCESS,
  GET_CATEGORIES_WISE_BANNER,
  UPDATE_BANNER,
  UPDATE_BANNER_SUCCESS,
  DELETE_CATEGORY_ID_BANNER,
  DELETE_CATEGORY_ID_BANNER_SUCCESS,
  DELETE_CATEGORY_ID_BANNER_FAILURE,
  GET_CATEGORIES_WISE_BANNER_FAILURE,
  UPDATE_BANNER_FAILURE,
} from './types'
import { client, websiteClient } from '../../..'
import {
  createManageBanner,
  getBannerHome,
  updateHomeBannerQuery,
  deleteHomeBannerQuery,
  getBannerByModuleQuery,
} from './Schema'

/**
 * Add Banner.
 */

function* addHomeBannerSaga() {
  yield takeLatest(ADD_BANNER, addSiteConfiguration)
}
function* addSiteConfiguration(action: ActionType) {
  try {
    const res = yield addHomeBannerApi(action.payload)
    yield put({ type: SUCCESS_BANNER, payload: res.data })
  } catch (error) {
    yield put({ type: ERROR_BANNER, error })
  }
}
function addHomeBannerApi(input: any) {
  input.isBanner = true
  return client
    .mutate({
      mutation: createManageBanner,
      variables: { input },
    })
}
// get Home Banner

export function getHomeBannersApi(input: any) {
  const { data } = input

  return websiteClient
    .query({
      query: getBannerByModuleQuery,
      variables: {
        input: { type: data.type, metatype: data.metatype, isBanner: true },
        page: input.page,
        limit: input.limit,
        searchTerm: input.searchTerm,
      },
      fetchPolicy: 'no-cache',
    })
}

export function* getHomeBannersData(action: ActionType) {
  try {
    const data = yield getHomeBannersApi(action.payload)

    data.fieldName = action.payload.data.fieldName

    yield put({ type: GET_CATEGORIES_WISE_BANNER_SUCCESS, payload: data })
  } catch (error) {
    yield put({
      type: GET_CATEGORIES_WISE_BANNER_FAILURE,
      error,
      payload: action.payload.fieldName,
    })
  }
}

export function* getHomeBannerSaga() {
  yield takeLatest(GET_CATEGORIES_WISE_BANNER, getHomeBannersData)
}

// update home banner

export function updateHomeBannerApi(data: any) {
  return client
    .mutate({
      mutation: updateHomeBannerQuery,
      variables: { input: data },
    })
}

export function* updateHomeBanner(action: ActionType) {
  try {
    const data = yield updateHomeBannerApi(action.payload)
    yield put({ type: UPDATE_BANNER_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_BANNER_FAILURE, error })
  }
}

export function* updateHomeBannerSaga() {
  yield takeLatest(UPDATE_BANNER, updateHomeBanner)
}

// Delete home banner

function deleteBoatTypesApi(id: string) {
  return client
    .mutate({
      mutation: deleteHomeBannerQuery,
      variables: id,
    })
}

function* deleteHomeBannerData(action: ActionType) {
  try {
    const res = yield deleteBoatTypesApi(action.payload)
    yield put({ type: DELETE_CATEGORY_ID_BANNER_SUCCESS, payload: res })
  } catch (error) {
    yield put({ type: DELETE_CATEGORY_ID_BANNER_FAILURE, error })
  }
}

function* deleteHomeBannerSaga() {
  yield takeLatest(DELETE_CATEGORY_ID_BANNER, deleteHomeBannerData)
}
/**
 *  Sagas default export
 */

export default function* manageBanner() {
  yield all([fork(addHomeBannerSaga), fork(getHomeBannerSaga), fork(updateHomeBannerSaga), fork(deleteHomeBannerSaga)])
}
