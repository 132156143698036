import gql from 'graphql-tag'

export const getAgreementsCounts = gql`
  {
    getAgreementsCounts {
      total
      agreementCounts {
        all
        pending
      }
    }
  }
`
