import { put, takeLatest, all } from 'redux-saga/effects'
import {
  CREATE_CATEGORY_WISE_VIDEO,
  ActionType,
  CREATE_CATEGORY_WISE_VIDEO_SUCCESS,
  CREATE_CATEGORY_WISE_VIDEO_FAILURE,
  GET_CATEGORY_WISE_VIDEOS,
  GET_CATEGORY_WISE_VIDEOS_SUCCESS,
  GET_CATEGORY_WISE_VIDEOS_FAILURE,
  UPDATE_CATEGORY_WISE_VIDEO,
  UPDATE_CATEGORY_WISE_VIDEO_SUCCESS,
  UPDATE_CATEGORY_WISE_VIDEO_FAILURE,
  DELETE_CATEGORY_WISE_VIDEO,
  DELETE_CATEGORY_WISE_VIDEO_SUCCESS,
  DELETE_CATEGORY_WISE_VIDEO_FAILURE,
  GET_ALL_VIDEOS_LIST,
  GET_ALL_VIDEOS_LIST_SUCCESS,
  GET_ALL_VIDEOS_LIST_FAILURE,
} from './Types'
import { client } from '../../../'
import { createVideo, getAllVideos, updateVideo, deleteVideo, getAllVideosList } from './Schema'

export function createCategoryWiseVideoApi(input: any) {
  return client
    .mutate({
      mutation: createVideo,
      variables: { input },
    })
}

function deleteCategoryWiseVideoApi(data: any) {
  const id = data.payload
  return client
    .mutate({
      mutation: deleteVideo,
      variables: { id },
    })
}

export function* createCategoryWiseVideoData(action: ActionType) {
  try {
    const data = yield createCategoryWiseVideoApi(action.payload)
    yield put({ type: CREATE_CATEGORY_WISE_VIDEO_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: CREATE_CATEGORY_WISE_VIDEO_FAILURE, error })
  }
}

function* deleteCategoryWiseVideoData(action: ActionType) {
  try {
    const res = yield deleteCategoryWiseVideoApi(action)
    yield put({ type: DELETE_CATEGORY_WISE_VIDEO_SUCCESS, payload: res.data })
  } catch (error) {
    yield put({ type: DELETE_CATEGORY_WISE_VIDEO_FAILURE, error })
  }
}

export function* createCategoryWiseVideoSaga() {
  yield takeLatest(CREATE_CATEGORY_WISE_VIDEO, createCategoryWiseVideoData)
}

export function getAllVideosListApi() {
  return client
    .query({
      query: getAllVideosList,
      variables: {
        input: {},
      },
      fetchPolicy: 'no-cache',
    })
}

export function* getAllVideoListData() {
  try {
    const data = yield getAllVideosListApi()
    yield put({ type: GET_ALL_VIDEOS_LIST_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: GET_ALL_VIDEOS_LIST_FAILURE, error })
  }
}

export function* getAllVideoListSaga() {
  yield takeLatest(GET_ALL_VIDEOS_LIST, getAllVideoListData)
}

export function getCategoryWiseVideosApi(payload: any) {
  return client
    .query({
      query: getAllVideos,
      variables: payload,
      fetchPolicy: 'no-cache',
    })
}

export function* getCategoryWiseVideosData(action: ActionType) {
  try {
    const data = yield getCategoryWiseVideosApi(action.payload)
    yield put({ type: GET_CATEGORY_WISE_VIDEOS_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: GET_CATEGORY_WISE_VIDEOS_FAILURE, error })
  }
}

export function* getCategoryWiseVideosSaga() {
  yield takeLatest(GET_CATEGORY_WISE_VIDEOS, getCategoryWiseVideosData)
}

export function updateCategoryWiseVideoApi(data: any) {
  const input = { ...data }
  return client
    .mutate({
      mutation: updateVideo,
      variables: { input },
    })
}

export function* updateCategoryWiseVideoData(action: ActionType) {
  try {
    const data = yield updateCategoryWiseVideoApi(action.payload)
    yield put({ type: UPDATE_CATEGORY_WISE_VIDEO_SUCCESS, payload: data.data })
  } catch (error) {
    yield put({ type: UPDATE_CATEGORY_WISE_VIDEO_FAILURE, error })
  }
}

function* deleteCategoryWiseVideoSaga() {
  yield takeLatest(DELETE_CATEGORY_WISE_VIDEO, deleteCategoryWiseVideoData)
}

export function* updateCategoryWiseVideoSaga() {
  yield takeLatest(UPDATE_CATEGORY_WISE_VIDEO, updateCategoryWiseVideoData)
}

export default function* homeVideoSaga() {
  yield all([
    createCategoryWiseVideoSaga(),
    getCategoryWiseVideosSaga(),
    updateCategoryWiseVideoSaga(),
    deleteCategoryWiseVideoSaga(),
    getAllVideoListSaga(),
  ])
}
