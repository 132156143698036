import {
  adminUserRoleState,
  ActionType,
  GET_FRONT_USERS,
  GET_FRONT_USERS_SUCCESS,
  GET_FRONT_USERS_FAILURE,
  CHANGE_ACCOUNT_STATUS,
  CHANGE_ACCOUNT_STATUS_SUCCESS,
  CHANGE_ACCOUNT_STATUS_FAILURE,
  GET_ADMIN_USER_ROLE,
  GET_ADMIN_USER_ROLE_SUCCESS,
  GET_ADMIN_USER_ROLE_FAILURE,
  CLEAR_ADMIN_USER_ROLE_FLAG,
  CREATE_ADMIN_USER_ROLE,
  CREATE_ADMIN_USER_ROLE_SUCCESS,
  CREATE_ADMIN_USER_ROLE_FAILURE,
  DELETE_ADMIN_USER_ROLE,
  DELETE_ADMIN_USER_ROLE_SUCCESS,
  DELETE_ADMIN_USER_ROLE_FAILURE,
  GET_FLAG_COMPLAINT,
  DELETE_COMPLAINT,
  DELETE_COMPLAINT_SUCCESS,
  DELETE_COMPLAINT_FAILURE,
  GET_FLAG_COMPLAINT_SUCCESS,
  GET_FLAG_COMPLAINT_FAILURE,
  SEARCH_USER,
  SEARCH_USER_SUCCESS,
} from './Type'
import NotificationWithIcon from '../../component/notification/Notification'

const initialState: adminUserRoleState = {
  adminUserRole: [],
  adminUserRoleSuccess: false,
  adminUserRoleFailure: false,
  deleteAdminUserSuccess: false,
  totalUserRow: 0,
  deleteAdminUserFailure: false,
  success: false,
  accountStatusSuccess: false,
  error: false,
  accountStatusError: false,
  users: [],
  totalUsersRow: 0,
  usersReview: [],
  totalUsersReviewRow: 0,
  adminLoad: false,
  frontLoad: false,
  isLoading: false,
  deleteComplaintLoad: false,
  complaintDeleteSuccess: false,
  complaintDeleteFail: false,
}

export function adminUserRoleReducer(state = initialState, action: ActionType): adminUserRoleState {
  switch (action.type) {
    case GET_ADMIN_USER_ROLE:
      return {
        ...state,
        adminLoad: true,
        adminUserRoleFailure: false,
        adminUserRole: [],
      }

    case GET_ADMIN_USER_ROLE_SUCCESS:
      return {
        ...state,
        adminUserRoleFailure: false,
        adminLoad: false,
        adminUserRole: action.payload && action.payload.items,
        totalUserRow: action.payload && action.payload.total,
      }

    case GET_ADMIN_USER_ROLE_FAILURE:
      return {
        ...state,
        adminUserRoleFailure: true,
        adminLoad: false,
      }

    case CREATE_ADMIN_USER_ROLE:
      return {
        ...state,
        isLoading: true,
        adminUserRoleSuccess: false,
        adminUserRoleFailure: false,
      }

    case CREATE_ADMIN_USER_ROLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminUserRoleSuccess: true,
        adminUserRoleFailure: false,
      }

    case CREATE_ADMIN_USER_ROLE_FAILURE:
      NotificationWithIcon('error', 'Unknown error occured')

      return {
        ...state,
        isLoading: false,
        adminUserRoleSuccess: false,
        adminUserRoleFailure: true,
      }

    case CLEAR_ADMIN_USER_ROLE_FLAG:
      return {
        ...state,
        adminUserRoleSuccess: false,
        accountStatusSuccess: false,
        adminUserRoleFailure: false,
        deleteAdminUserSuccess: false,
      }

    case DELETE_ADMIN_USER_ROLE:
      return {
        ...state,
        deleteAdminUserSuccess: false,
        deleteAdminUserFailure: false,
      }

    case DELETE_ADMIN_USER_ROLE_SUCCESS:
      const deletedUserFilter = state.adminUserRole.filter((item: any) => action.payload.data.deleteUser.id !== item.id)
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        deleteAdminUserSuccess: true,
        deleteAdminUserFailure: false,
        adminUserRole: [...deletedUserFilter],
      }

    case DELETE_ADMIN_USER_ROLE_FAILURE:
      return {
        ...state,
        deleteAdminUserSuccess: false,
        deleteAdminUserFailure: true,
      }

    case GET_FRONT_USERS:
      return {
        ...state,
        success: false,
        frontLoad: true,
        error: false,
        users: [],
        totalUsersRow: 0,
      }
    case GET_FRONT_USERS_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        frontLoad: false,
        users: action.payload.items,
        totalUsersRow: action.payload.total,
      }
    case GET_FRONT_USERS_FAILURE:
      return {
        ...state,
        frontLoad: false,
        success: false,
        error: true,
      }

    case SEARCH_USER:
      return {
        ...state,
      }

    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        users: action.payload.items,
        totalUsersRow: action.payload.total,
      }

    case GET_FLAG_COMPLAINT:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      }
    case GET_FLAG_COMPLAINT_SUCCESS:
      return {
        ...state,
        success: true,
        isLoading: false,
        usersReview: action.payload.items,
        totalUsersReviewRow: action.payload.total,
      }
    case GET_FLAG_COMPLAINT_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: true,
      }
    case DELETE_COMPLAINT:
      return {
        ...state,
        deleteComplaintLoad: true,
        complaintDeleteSuccess: false,
        complaintDeleteFail: false,
      }
    case DELETE_COMPLAINT_SUCCESS:
      console.log(action.payload, 'action.payload')

      const changedComplaintData = state.usersReview.filter(value => action.payload.id !== value.id)
      NotificationWithIcon('success', 'Record Deleted Successfully')
      return {
        ...state,
        usersReview: changedComplaintData,
        deleteComplaintLoad: false,
        complaintDeleteSuccess: true,
        complaintDeleteFail: false,
      }
    case DELETE_COMPLAINT_FAILURE:
      NotificationWithIcon('error', 'Something Went Wrong!')
      return {
        ...state,
        deleteComplaintLoad: false,
        complaintDeleteSuccess: false,
        complaintDeleteFail: true,
      }
    case CHANGE_ACCOUNT_STATUS: {
      return {
        ...state,
        accountStatusSuccess: false,
        accountStatusError: false,
      }
    }
    case CHANGE_ACCOUNT_STATUS_SUCCESS: {
      const checkPage = action.payload.fromPage === 'admin' ? state.adminUserRole : state.users

      const changedStatusData = checkPage.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            userStatus: !item.userStatus,
          }
        }
        return item
      })
      NotificationWithIcon('success', 'Status Changed Successfully')
      return {
        ...state,
        accountStatusSuccess: true,
        accountStatusError: false,
        adminUserRole: changedStatusData,
        users: changedStatusData,
      }
    }
    case CHANGE_ACCOUNT_STATUS_FAILURE: {
      NotificationWithIcon('error', 'Problem While Updating Status.')
      return {
        ...state,
        accountStatusSuccess: false,
        accountStatusError: true,
      }
    }

    default:
      return state
  }
}
